import { actions } from "../constants/actions";
import { endPoints } from "..//constants/endPoints";
import * as HTTPClient from "..//helper/httpClient";
import { styles } from "..//constants/styles";
import store from "../../../src/services/store";
import { getUserList } from "..//actions/UserListAction";
import { isValidParam, getObjectParam } from "..//helper/parameterVerifier";
import { constants } from "..//constants/constants";

function setUserContextData(token) {
	return {
		type: actions.APP_ME_GET,
		payload: HTTPClient.get(endPoints.ME.ME_GET, null),
	};
}

function updateShowInformationDialog(status) {
	return {
		type: actions.APP_INFORMATION_STATUS_UPDATE,
		payload: status,
	};
}

function updateReAuthenticateAurinkoAll(status) {
	return {
		type: actions.APP_REAUTHENTICATE_AURINKO_UPDATE_ALL,
		payload: status,
	};
}

function updateReAuthenticateAurinko(status) {
	return {
		type: actions.APP_REAUTHENTICATE_AURINKO_UPDATE,
		payload: status,
	};
}

function updateIsSendMailUsingAurinko(status) {
	return {
		type: actions.APP_IS_SEND_MAIL_USING_AURINKO_UPDATE,
		payload: status,
	};
}

function updateAurinkoCasesMailBox(email) {
	return {
		type: actions.APP_AURINKO_CASE_MAILBOX_UPDATE,
		payload: email,
	};
}

function initAppContextData() {
	let themeColor = "#fe5722";
	try {
		let promise = Promise.resolve(HTTPClient.get(endPoints.ME.ME_GET, null));
		if (promise !== null && promise !== undefined) {
			promise.then((response) => {
				let sfFavicon = document.getElementById("sf-favicon");
				if (response != null && response.me != null && response.me[0] != null) {
					let favicon = response?.me[0]?.favicon;
					if (sfFavicon !== null) {
						if (favicon === null || favicon === undefined || favicon === "") {
							favicon = "https://s3.amazonaws.com/app.soffront.com/favicon.ico";
						}
						sfFavicon.href = favicon;
					}
					themeColor = response.me[0]?.background;
				}

				localStorage.setItem("background", themeColor);
				store.dispatch({
					type: actions.APP_ME_GET_FULFILLED,
					payload: response,
				});
				var sheet = document.body.style;
				sheet.setProperty("--background-color", themeColor, "important");

				store.dispatch(getHome());
				store.dispatch(getLinkObjectInfo());
				store.dispatch(getUserList());
				store.dispatch(getDashBoardInfo());
				store.dispatch(getObjectList());
				store.dispatch(getSFFormFieldsPromise("accounts"));
				store.dispatch(getSFFormFieldsPromise("contacts"));
				store.dispatch(getSchedulerSlug());
				store.dispatch(getHomeSetup());

				let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${response.me[0]?.projectId}_${response.me[0]?.id}`));
                if(!shouldReturnToInitialPosition){
					if (!response.me) return;
					localStorage.removeItem('type_'+response.me[0].projectId+"_"+response.me[0]?.id)
					localStorage.removeItem('returnUrl_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('selectedTab_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('selectedModuleInfo_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('selectedModuleItems_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('selectedModuleName_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('selectedTabObject_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('activeTabIndex_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('rememberedTab_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('listselectedTab_'+response.me[0].projectId+"_"+response.me[0].id);
					localStorage.removeItem('listrememberedTab_'+response.me[0].projectId+"_"+response.me[0].id);
                }

				styles.primaryButton.backgroundColor = themeColor;
				styles.listViewPrimaryButton.backgroundColor = themeColor;
				styles.emailSendBtn.backgroundColor = themeColor;
				styles.emailSendBtn1.backgroundColor =
					themeColor + " url(/asset/images/button_seperator.jpg)";
			});
		}
	} catch (error) {
		console.error("Error in 'appActions.js --> initAppContextData()':" + error);
	}
}

function initAppContextSendMailDataMobile() {
	try {
		let promise = Promise.resolve(HTTPClient.get(endPoints.ME.ME_GET, null));
		if (promise !== null && promise !== undefined) {
			promise.then((response) => {
				store.dispatch({
					type: actions.APP_ME_GET_FULFILLED,
					payload: response,
				});
				store.dispatch(getSchedulerSlug());
			});
		}
	} catch (error) {
		console.error("Error in 'appActions.js --> initAppContextData()':" + error);
	}
}

function getHome() {
	return {
		type: actions.APP_HOME_GET,
		payload: HTTPClient.get(endPoints.HOME.HOME_GET, null),
	};
}

function updateHomeItems(home) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_HOME_UPDATE,
			payload: home,
		});
	};
}

function updateNotificationItems(data) {
	console.log(data);
	return function (dispatch) {
		dispatch({
			type: actions.APP_NOTIFICATION_UPDATE,
			payload: data,
		});
	};
}

function setSelectedModuleItems(selectedModule) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_HOME_SET_MODULE_ITEMS,
			payload: selectedModule,
		});
	};
}
function setFranchisorIndustry(data) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_FRANCHISOR_INDUSTRY_UPDATE,
			payload: data,
		});
	};
}
function openFilterMenu(objFilterMenu) {
	return function (dispatch) {
		dispatch({
			type: actions.OPEN_FILTER_MENU,
			payload: objFilterMenu,
		});
	};
}

function openCloseSearchPopover(isOpenPopover) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_SEARCH_POPOVER_OPEN_CLOSE,
			payload: isOpenPopover,
		});
	};
}

function openLeftSideMenu(isOpenMenu) {
	return function (dispatch) {
		dispatch({
			type: actions.OPEN_LEFT_MENU_DRAWER,
			payload: isOpenMenu,
		});
	};
}

function setSearchCriteria(searchCriteria) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_SEARCH_CRITERIA_SET,
			payload: searchCriteria,
		});
	};
}

function setAdvanceSearchCriteria(searchCriteria) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_ADVANCE_SEARCH_CRITERIA_SET,
			payload: searchCriteria,
		});
	};
}

function getLinkObjectInfo() {
	return {
		type: actions.APP_LINK_OBJECT_INFO,
		payload: HTTPClient.get(endPoints.COMMON.LINK_OBJECT_INFO, null),
	};
}

function getLinkObjectInfoPromise() {
	return HTTPClient.get(endPoints.COMMON.LINK_OBJECT_INFO, null);
}

function setLinkObjectInfo(linkChildInfo) {
	return {
		type: actions.SET_APP_LINK_OBJECT_INFO,
		payload: linkChildInfo,
	};
}

function appRedirect(isRedirect, redirectUrl) {
	return {
		type: actions.APP_REDIRECT,
		payload: { isRedirect: isRedirect, redirectUrl: redirectUrl },
	};
}

function getQueryDetails(params) {
	return Promise.resolve(
		HTTPClient.get(endPoints.COMMON.QUERY_DETAILS_GET, params)
	);
}

function updateUserLanguage(language) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_UPDATE_USER_LANGUAGE,
			payload: language,
		});
	};
}

function getDashBoardInfo() {
	return {
		type: actions.DASHBOARD_INFO_GET,
		payload: HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_INFO_GET),
	};
}

function getSetupPageInfo(params) {
	let state = store.getState();
	params = getObjectParam(params);
	params.price_id = state.app.me.price_id;
	return Promise.resolve(
		HTTPClient.get(endPoints.COMMON.GET_SETUP_PAGE_INFO, params)
	);
}

function getObjectList() {
	return {
		type: actions.APP_OBJECT_LIST_GET,
		payload: HTTPClient.get(endPoints.SEARCH.OBJECTS_GET, null),
	};
}

function getSFFormFieldsPromise(objectName) {
	let url = null;
	if (objectName === constants.ACCOUNTS_OBJECT) {
		url = endPoints.ACCOUNTS.FIELDS;
	} else if (objectName === constants.CONTACTS_OBJECT) {
		url = endPoints.CONTACTS.FIELDS;
	}
	return {
		type: actions.FIELDS_GET,
		payload: HTTPClient.get(url),
	};
}

function getSchedulerSlug() {
	return {
		type: actions.SCHEDULER_SLUG_GET,
		payload: HTTPClient.get(endPoints.SCHEDULER.GET_SLUG, null),
	};
}

function updateCurrency(symbol) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_UPDATE_CURRENCY,
			payload: symbol,
		});
	};
}

function updateUserQbSetup(isSetup) {
	return function (dispatch) {
		dispatch({
			type: actions.UPDATE_QB_SETUP_FLAG,
			payload: isSetup,
		});
	};
}

function updateUserXeroSetup(data) {
	return function (dispatch) {
		dispatch({
			type: actions.UPDATE_XERO_SETUP_FLAG,
			payload: data,
		});
	};
}

function getHomeSetup() {
	return {
		type: actions.GET_APP_HOME_SETUP,
		payload: HTTPClient.get(endPoints.HOME.GET_HOME_SETUP, {}),
	};
}
function setCalendarSetup(calenderSetup) {
	try {
		store.dispatch({
			type: actions.SET_CALENDAR_SET_UP,
			payload: calenderSetup,
		});
	} catch (e) {
		console.error("Error in 'appAction.js -> SET_CALENDAR_SET_UP':" + e);
	}
}

function saveCalendarSetup(calenderSetup) {
	let url = endPoints.APPOINTMENT.SAVE_CALENDAR_SET_UP;
	let promise = Promise.resolve(HTTPClient.post(url, calenderSetup));
	if (isValidParam(promise)) {
		promise.then((response) => {
			if (isValidParam(response)) {
				return response.data;
			}
		});
	}
}

function updateExternalMenuLink(value) {
	return {
		type: actions.APP_EXTERNAL_LINK_MENU_UPDATE,
		payload: value
	};
}

function updateAppRedirectBackFRLogin(data) {
	return {
		type: actions.APP_REDIRECT_BACK_FR_LOGIN,
		payload: {
			selectedModuleName: data.selectedModuleName,
			selectedModuleItems: data.selectedModuleItems, 
			selectedModuleInfo: data.selectedModuleInfo,
			redirectUrl: data.redirectUrl,
		}
	};
}
function setNotifyTextForSalesRep(data) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_NOTIFY_TEXT_SALESREP_UPDATE,
			payload: data,
		});
	};
}

function setPhoneTextProvider(data) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_PHONE_TEXT_PROVIDER_UPDATE,
			payload: data,
		});
	};
}

function setLmsNode(data) {
	return function (dispatch) {
		dispatch({
			type: actions.APP_LMS_NODE_UPDATE,
			payload: data,
		});
	};
}

function updateManageUnitPhoneChange(value) {
    return {
        type: actions.APP_PHONE_TEXT_MANAGE_UNIT_PHONE_UPDATE,
        payload: value,
    };
}

export {
	initAppContextData,
	getHome,
	updateHomeItems,
	updateNotificationItems,
	setSelectedModuleItems,
	updateShowInformationDialog,
	setUserContextData,
	openFilterMenu,
	openLeftSideMenu,
	setSearchCriteria,
	setAdvanceSearchCriteria,
	getLinkObjectInfo,
	appRedirect,
	getQueryDetails,
	getLinkObjectInfoPromise,
	setLinkObjectInfo,
	updateUserLanguage,
	setCalendarSetup,
	saveCalendarSetup,
	getDashBoardInfo,
	getSetupPageInfo,
	getObjectList,
	getSFFormFieldsPromise,
	getSchedulerSlug,
	updateCurrency,
	updateUserQbSetup,
	updateUserXeroSetup,
	getHomeSetup,
	openCloseSearchPopover,
	initAppContextSendMailDataMobile,
	updateExternalMenuLink,
	setFranchisorIndustry,
	updateAppRedirectBackFRLogin,
	setNotifyTextForSalesRep,
	setPhoneTextProvider,
	setLmsNode,
	updateManageUnitPhoneChange,
	updateReAuthenticateAurinkoAll,
	updateReAuthenticateAurinko,
	updateIsSendMailUsingAurinko,
	updateAurinkoCasesMailBox
};
