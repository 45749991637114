import { actions } from '..//constants/actions'

const headerInitialState = {
    selectedTabObject: "",
    selectedHeader: "",
    kanban: {
        currentView: "list",
        show: false
    },
    showAddIcon: false,
    showHeader: true,
    refreshAssets: false,
    refreshOnboardingUnits: false,
    //onBoarding new state
    workflowData: [],
    workflow: '',
    onboardingReportData:[],
    initialLoad: true,
    //onBoarding sorting state
    onboardingRowSortData:{
    enableAllColumn: true,
    sortFieldName: 'company',
    isDescending: true,
    records: []
  }
}



const headerReducer = (state = headerInitialState, action) => {
    switch (action.type) {
        case actions.SET_HEADER:
            return {
                ...state,
                selectedTabObject: action.payload.selectedTabObject,
                selectedHeader: action.payload.selectedHeader,
                kanban: action.payload.kanban,
                showAddIcon: action.payload.showAddIcon,
                showHeader: action.payload.showHeader
            }
        case actions.REFRESH_ASSETS: 
            return {
                ...state,
                refreshAssets: action.payload.refresh
            }
        case actions.REFRESH_ONBOARDING_UNITS:
            return {
                ...state,
                refreshOnboardingUnits: action.payload.isRefresh,
            }
        case actions.SET_WORKFLOW_DATA:
            const sortedWorkflowData = action.payload?.sort((a, b) => a?.workflow_name.localeCompare(b?.workflow_name))
            return {
                ...state,
                workflowData: sortedWorkflowData,
            }
        case actions.SET_ONBOARDING_WORKFLOW:
            return {
                ...state,
                workflow: action.payload
            }
        case actions.SET_ONBOARDING_REPORT_DATA:

            return {
                ...state,
                onboardingReportData: action.payload
            }

        case actions.SET_ONBOARDING_INITIAL_LOAD:
            return {
                ...state,
                initialLoad: action.payload
            }

        case actions.SET_ONBOARDING_SORT:
            const { sortFieldName, isDescending, records} = action.payload;
            return {
                ...state,
                onboardingRowSortData: {
                    ...state.onboardingRowSortData,
                    sortFieldName : sortFieldName,
                    isDescending : isDescending,
                    records : records
                }
            }
        default:
            return state;
    }
}

export default headerReducer;
