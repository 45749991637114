import React,{ useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { TextField, FormControl, MenuItem, Button} from '@mui/material';
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { useDispatch, useSelector} from "react-redux";
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const DashboardAddWigetDialog = ({data}) =>{
    const { widgetOptions } = data
    const dispatch = useDispatch();
    const appContainer = useSelector((state) => state.appContainer);
    const [selectWidgetName,setSelectWidgetName] = useState('')
    const [selectedwidgetItem,setSelectedwidgetItem] = useState()

    const handleSelectWidgetChange = (widget) => {
        setSelectedwidgetItem(widget)
        setSelectWidgetName(widget);
    };

    return(
        <div style={{ width: '100%', height: '100%', display: 'inline-block' }}>


        {/* Select Widget*/}
        <div className="col-sm-12 col-two" style={{ marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    variant="outlined"
                    name="selectReport"
                    id="selectReport"
                    label={'Available widgets'}
                    fullWidth
                    value={selectWidgetName}
                    onChange={(event) => handleSelectWidgetChange(event.target.value)}
                    margin="dense"
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    className="sf-fields-bg"
                >
                    {widgetOptions?.map((widget) => (
                        <MenuItem key={widget.id} value={widget}>
                            {widget.widget_name}
                        </MenuItem>
                    ))}


                </TextField>
            </FormControl>
        </div>

        {/* Action Buttons */}
        <div style={{ float: 'right', marginTop: '25px' }}>
            <Button
                primary={true}
                onClick={() => {
                    if(!selectedwidgetItem) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.SELECT_AVAILABLEWIDGET, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else {
                        appContainer.dialogProps.eventName(selectedwidgetItem);
                        dispatch(getAppDialog(false, null, null, null, null, null));
                    }
                }}
                style={styles.primaryButton}
            > {getLocalizedStrings().label.COMMON.ADD}</Button>
            <Button
                primary={true}
                onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,
        </div>
    </div>
    )
} 

export default DashboardAddWigetDialog;