import React, { useEffect, useState, useRef } from 'react';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import PopOver from '../components/PopOver';
import ShowCircularProgress from '../components/circularProgress';
import { constants } from '../../../services/constants/constants';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { Button, Box, Card, CardActions, CardMedia, CardActionArea, Chip, Select, MenuItem, TextField, Icon, FormControl, IconButton } from '@mui/material';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { setHeader } from '../../../services/actions/headerActions';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { getAssetsTree, getAssetsTreeItem } from '../../../services/helper/common';
import { makeTiteleCase } from '../../../services/helper/utils';
import { getIntParam, getStringParam, getObjectParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { doRefreshAssetsTree, updateisAssetsUpdated, updateDocumentLibrary, updateInBodyAction, updateExpandedNode } from '../../../services/actions/documentLibraryActions';
import { getObjectLabelByObject } from '../../../services/helper/common';
import Autocomplete from '@mui/material/Autocomplete';
import ComputerIcon from '@mui/icons-material/Computer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Popover from '@mui/material/Popover';
import { findDataFromBinaryTree } from './assetsHome';


const crdStyle = {
    cardWrapper: {
        display: 'inline-block',
        position: 'relative',
        cursor: ' pointer',
        width: '100%',
        height: '100%'
    }
    ,
    layer: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'none',
        zIndex: '1100'
    }
}
var search_by_options = [
    {
        label: 'Name',
        value: 'Name',
    },
    {
        label: 'Type',
        value: 'Type',
    },
    {
        label: 'Owner',
        value: 'Owner',
    },
    {
        label: 'All',
        value: 'All',
    }
];

const iconStyles = {
    marginLeft: 5,
};

const inlinestyle = {
    button: {
        marginRight: 20,
    },
    exampleImageInput: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    }
};


function getDocumentImage(ext, document_id, project_id, isShared, shareId) {
    let imgName = "";
    if (ext == 'file' && (isShared || shareId > 0)) {
        imgName = "https://s3.amazonaws.com/" + constants.DOCUMENT_BUCKET + document_id + "_" + project_id;
    } else
        if (ext == "pdf") {
            imgName = "pdf";
        } else if (ext == "jpg" || ext == "jpeg" || ext == "gif" || ext == "png") {
            return "https://s3.amazonaws.com/" + constants.DOCUMENT_BUCKET + document_id + "_" + project_id;
        } else if (ext == "doc" || ext == "rtf" || ext == "docx") {
            imgName = "doc";
        } else if (ext == "xls" || ext == "csv" || ext == "xlsx") {
            imgName = "xls";
        } else if (ext == "ppt" || ext == "pptx") {
            imgName = "ppt";
        } else if (ext == "zip") {
            imgName = "zip";
        } else if (ext == "rar") {
            imgName = "rar";
        } else if (ext == "txt") {
            imgName = "txt";
        } else if (ext == "java" || ext == "jar") {
            imgName = "java";
        } else if (ext == "html" || ext == "htm") {
            imgName = "html";
        } else if (ext == "js") {
            imgName = "js";
        } else if (ext == "css") {
            imgName = "css";
        } else if (ext == "swf") {
            imgName = "flash";
        } else if (ext == "sql") {
            imgName = "db";
        } else if (ext == "mp4" || ext == "mpg" || ext == "mpeg" || ext == "mov") {
            imgName = "film";
        } else if (ext == "mp3" || ext == "ogg") {
            imgName = "music";
        } else if (ext == "jsp" || ext == "php" || ext == "c" || ext == "cpp") {
            imgName = "code";
        } else if (ext == 'external link') {
            imgName = 'file-link';
        } else if (ext == 'folder') {
            if (isShared || shareId > 0) {
                imgName = "foldershare";
            } else {
                imgName = "folder";
            }
        } else {
            imgName = "file";
        }
    return '../../asset/images/externalPage/largeIcons/' + imgName + '.png';
}
const AssetsBody = ({ selectedLibrary, callFrom, treeData, isAddDocument, addFolder, isAddFolder, isRenameFolder, files, callBack, menuData }) => {
    const [documentList, setDocumentList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [addDocument, setAddDocument] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [category, setCategory] = useState('');

    const [categories, setCategories] = useState([]);
    const [push_cnt, setPush_cnt] = useState({});
    const [error, setError] = useState({
        upload: ''
    });
    const [isFolderAdd, setIsFolderAdd] = useState(false);

    const [isFolderRename, setIsFolderRename] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [searchBy, setSearchBy] = useState('');

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [localSelectedLibrary, setLocalSelectedLibrary] = useState(null);
    const [displyType, setDisplayType] = useState('grid'); // list
    const [sortBy, setSortBy] = useState(null); // 'name', 'size', 't_type', 'creator','date',
    const [sortOrder, setSortOrder] = useState(null); // 'asc' / 'desc'

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);
    let { actionOn, actionType, actionsParam } = documentLibrary.inBodyAction;

    const dispatch = useDispatch();

    const handleClose = (from) => {
        if (from == 'all') {
            dispatch(updateInBodyAction(null, null, null))
            dispatch(getAppDialog(false, null, null, null, null, null));
        } else {
            dispatch(updateInBodyAction(null, null, null))
        }
    }
    useEffect(() => {
        if (isValidParam(callFrom) && callFrom === 'menu') {
            let tab = getActiveTab();
            let label = getObjectLabelByObject(constants.ASSETS_OBJECT);
            dispatch(setHeader(constants.ASSETS_OBJECT, label, null, false, true));
        }

        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "asset-body-view" + projectId + '-' + userId;
        const assetBodyView = getCookie(cookieName);
        if (assetBodyView) {
            setDisplayType(assetBodyView);
        }
    }, []);
    useEffect(() => {
        if (documentLibrary.inBodyAction.actionOn == 'folder') {

            if (documentLibrary.inBodyAction.actionType == 'add') {
                setIsFolderAdd(true);
                setIsFolderRename(false);
                setAddDocument(false);
                dispatch(setAppDialogActions(getActions()));
            } else {
                setIsFolderRename(true);
                setIsFolderAdd(false);
                setAddDocument(false);
                dispatch(setAppDialogActions(getActions()));
            }
        }
        else if (documentLibrary.inBodyAction.actionOn === 'file') {
            setAddDocument(true);
            setIsFolderAdd(false);
            setIsFolderRename(false);
            dispatch(setAppDialogActions(getActions()));
        } else {
            setIsFolderAdd(false);
            setIsFolderRename(false);
            setAddDocument(false);
            dispatch(setAppDialogActions(getActions()));
        }
    }, [documentLibrary.inBodyAction]);


    useEffect(() => {
        setLocalSelectedLibrary(selectedLibrary)
        getDocumentList(selectedLibrary);
    }, [selectedLibrary, treeData]);


    const sortData = (__sortBy) => {
        let __order = sortBy == __sortBy ? sortOrder == 'asc' ? 'desc' : 'asc' : 'asc'
        let __documentList = [...documentList];
        __documentList.sort((a, b) => {
            let comparison = 0;
            let valueA, valueB;

            switch (__sortBy) {
                case 'date':
                    valueA = new Date(a.date || 0);
                    valueB = new Date(b.date || 0);
                    comparison = valueA - valueB;
                    break;

                case 'name':
                    valueA = a.name ? a.name.toLowerCase() : '';
                    valueB = b.name ? b.name.toLowerCase() : '';
                    comparison = valueA.localeCompare(valueB);
                    break;

                case 'size':
                    valueA = parseInt(a.size) || 0;
                    valueB = parseInt(b.size) || 0;
                    comparison = valueA - valueB;
                    break;

                case 't_type':
                    valueA = a.t_type ? a.t_type.toLowerCase() : '';
                    valueB = b.t_type ? b.t_type.toLowerCase() : '';
                    comparison = valueA.localeCompare(valueB);
                    break;

                case 'creator':
                    valueA = a.creator ? a.creator.toLowerCase() : '';
                    valueB = b.creator ? b.creator.toLowerCase() : '';
                    comparison = valueA.localeCompare(valueB);
                    break;

                default:
                    return comparison;
            }

            return __order === 'asc' ? comparison : -comparison;
        });
        setSortBy(__sortBy);
        setSortOrder(__order);
        setDocumentList([...__documentList]);
    }

    const set_Selected_Category = (val) => {
        setSelectedCategory(val);

    }



    const uploadImage = () => {
        if (uploadedFiles == null || error.upload != '') {
            let tempError = error;
            tempError.upload = getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR;
            setError({ ...tempError });
            return false;
        } else {
            for (let i = 0; i < uploadedFiles.length; i++) {
                let isDuplicate = false;
                let uploadFileName = uploadedFiles[i].fileName;
                for (let j = 0; j < documentList.length; j++) {
                    let docName = documentList[j].name;
                    if (docName == uploadFileName) {
                        isDuplicate = true;
                    }
                }
                if (isDuplicate) {
                    setUploadedFiles([]);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_IMAGE_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (uploadedFiles[i].file.size >= 1024) {
                    let kb = (uploadedFiles[i].file.size / 1024).toFixed(2);
                    if (kb > 500) {
                        setUploadedFiles([]);
                        decodeURIComponent(getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE_IMAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        return false;
                    }
                }
            }
        }
        setInProgress(true);
        setAddDocument(true);
        let promise = Promise.resolve(fileUpload(uploadedFiles));

        promise.then((response) => {
            if (response.data.status == 0) {
                setMounted(false);
                setAddDocument(false);
                setInProgress(false);
                setUploadedFiles([]);
                getDocumentList(localSelectedLibrary);
                dispatch(setAppDialogActions(getActions()));

                let _maindata = documentLibrary.mainData;

                for (let i = 0; i < response.data.fileDataList.length; i++) {
                    let newItem = {};
                    newItem.key = response.data.fileDataList[i].key;
                    newItem.name = response.data.fileDataList[i].name;
                    newItem.title = response.data.fileDataList[i].name;
                    newItem.t_type = response.data.fileDataList[i].t_type;
                    newItem.creator = response.data.fileDataList[i].creator;
                    newItem.date = response.data.fileDataList[i].date;
                    newItem.parentId = appContainer.dialogProps.data.parentId;
                    newItem.childId = appContainer.dialogProps.data.childId;
                    newItem.isLeaf = true;
                    newItem.userid = app.me.id;
                    newItem.id = response.data.fileDataList[i].id;
                    _maindata.push({ ...newItem });
                }
                dispatch(updateDocumentLibrary([..._maindata]));
                dispatch(updateisAssetsUpdated(true));
                let uploadMsg = getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD_IMAGE;
                dispatch(showCustomSnackBar(uploadMsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
            else if (response.data.status == -1 && response.data.error.message != "") {
                let tempError = error;

                tempError.upload = response.data.error.message;
                setError({ ...tempError });
                setInProgress(false);
                setUploadedFiles([]);
            }

        });
    }

    const uploadDocument = () => {
        let _uploadedFiles = actionsParam.files;
        let _maindata = [...documentLibrary.mainData];
        if (_uploadedFiles == null || _uploadedFiles.length == 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.DOCUMENT_FILE_EMPTY_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        } else {
            for (let i = 0; i < _uploadedFiles.length; i++) {
                let isDuplicate = false;
                let uploadFileName = _uploadedFiles[i].fileName;
                _maindata.forEach((item) => {
                    if (item.name == uploadFileName) {
                        isDuplicate = true;
                    }
                });

                if (isDuplicate) {
                    let _actionsParam = actionsParam;
                    _actionsParam.files = [];

                    dispatch(updateInBodyAction(actionOn, actionType, _actionsParam))
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_DOCUMENT_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                }
                let fileSize = getIntParam(_uploadedFiles[i].file.size);
                if (fileSize >= 1024) {
                    let kb = (fileSize / 1024).toFixed(2);
                    if (kb > (1024 * 50)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.OVER_MAX_SIZE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

                        return false;
                    }
                }

            }
        }
        setInProgress(true);
        setAddDocument(true);
        let promise = Promise.resolve(documentUpload(_uploadedFiles, actionsParam));

        promise.then((response) => {
            if (response.data.status == 0) {
                for (let i = 0; i < response.data.fileDataList.length; i++) {
                    let newItem = {};
                    newItem.accessType = "Write";
                    newItem.key = response.data.fileDataList[i].key;
                    newItem.bucketId = response.data.fileDataList[i].bucketId;
                    newItem.name = response.data.fileDataList[i].name;
                    newItem.title = response.data.fileDataList[i].name;
                    newItem.t_type = response.data.fileDataList[i].t_type;
                    newItem.size = response.data.fileDataList[i].size;
                    newItem.creator = response.data.fileDataList[i].creator;
                    newItem.url = response.data.fileDataList[i].src;
                    newItem.date = response.data.fileDataList[i].date;
                    newItem.parentId = appContainer.dialogProps.data.parentId || actionsParam.parentId;
                    newItem.childId = appContainer.dialogProps.data.childId || actionsParam.childId;
                    newItem.shareId = appContainer.dialogProps.data.shareId || actionsParam.shareId;
                    newItem.isShared = false;
                    newItem.isLeaf = true;
                    newItem.userid = app.me.id;
                    newItem.id = response.data.fileDataList[i].id;
                    _maindata.push(newItem);
                }
                dispatch(updateDocumentLibrary([..._maindata]));
                setInProgress(false);
                dispatch(updateisAssetsUpdated(true));
                dispatch(updateInBodyAction(null, null, null));
                let uploadMsgs = getLocalizedStrings().message.ATTACHMENT.ATTACHMENT_UPLOAD;
                dispatch(showCustomSnackBar(uploadMsgs, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            } else if (response.data.status == -1 && response.data.error.message != "") {
                dispatch(showCustomSnackBar(response.data.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                setInProgress(false);
                let _actionsParam = actionsParam;
                _actionsParam.files = [];
                dispatch(updateInBodyAction(actionOn, actionType, _actionsParam))
            }
        });
    }

    const handleUpload = () => {
        if (localSelectedLibrary == 'images') {
            uploadImage();
        } else if (localSelectedLibrary == 'documents') {
            uploadDocument();
        }

    }

    const documentUpload = (file, params) => {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append('file', file[i].file);
        }
        formData.append('input_param', JSON.stringify({
            documentId: 0,
            parentId: params.parentId,
            childId: params.childId,
        }));
        let url = endPoints.DOCUMENT_LIBRARY.UPLOAD_DOCUMENT;
        return HTTPClient.postFormData(url, formData);
    }

    const fileUpload = (file) => {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append('file', file[i].file);
        }
        formData.append('input_param', JSON.stringify({
            documentId: 0,
            parentId: appContainer.dialogProps.data.parentId,
            childId: appContainer.dialogProps.data.childId,
        }));
        let url = endPoints.DOCUMENT_LIBRARY.UPLOAD;
        return HTTPClient.postFormData(url, formData);
    }

    const handleAdd = () => {
        let selectedKey = documentLibrary.selectedNode;
        let _mainData = [...documentLibrary.mainData];
        let _mainDataObj = _mainData.find((item) => Number(item.key) === Number(selectedKey))
        let childId = _mainDataObj?.maxChildId + 1 || documentLibrary.maxChildId;
        let parentId = _mainDataObj?.isLeaf ? _mainDataObj?.parentId || 1 : _mainDataObj?.childId || 1;
        let shareId = _mainDataObj?.shareId || '';

        let data = {
            parentId: parentId,
            childId: childId,
            selectedLibrary: localSelectedLibrary,
            shareId: getStringParam(shareId),
        };
        if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL || callFrom == constants.SEND_SMS) {
            dispatch(updateInBodyAction('file', 'add', data))
        } else {
            let dialogTitle = getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE;
            if (selectedLibrary == constants.IMAGES_LIBRARY) {
                dialogTitle = getLocalizedStrings().message.DOCUMENT_LIBRARY.UPLOAD_IMAGE;
            }
            dispatch(getAppDialog(true, constants.DOCUMENT_UPLOAD_DIALOG, dialogTitle, getDocumentList, data, callFrom));
        }
    }

    const handleAssetsFolderAdd = () => {
        let title = getLocalizedStrings().label.DOCUMENT_LIBRARY.NEW_FOLDER;
        var treeArr = documentLibrary.mainData;
        let count = 0;
        var childTree = documentLibrary?.selectedTree || [];

        let selectedKey = documentLibrary.selectedNode;
        let _mainData = [...documentLibrary.mainData];
        let _mainDataObj = _mainData.find((item) => item.key == selectedKey)
        let childId = _mainDataObj.maxChildId + 1;
        let parentId = _mainDataObj?.isLeaf ? _mainDataObj?.parentId || 1 : _mainDataObj?.childId || 1;
        let shareId = _mainDataObj.shareId;
        getAssetsTreeItem(childTree, (item) => {
            if ((parentId == item.parentId) && item.name.toLowerCase().startsWith(title.toLowerCase())) {
                count = count + 1;
            }
        });
        if (count > 0) {
            title = title + ' ' + count;
        };

        let params = {};
        params.title = title;
        params.key = 'NewFolder';
        params.treeArray = treeArr;
        params.selectedKey = selectedKey;
        params.parentId = parentId;
        params.shareId = getStringParam(shareId);
        params.childId = childId;
        dispatch(getAppDialog(true, constants.ASSETS_FOLDER_ADD_DIALOG, getLocalizedStrings().label.COMMON.ADD_FOLDER, null, params, callFrom));
    }

    const handleCategoryChange = (value) => {
        loadStockLibrary(value);
    }

    const loadStockLibrary = (value) => {
        setMounted(false)
        let params = { type: 222, 'category': value }
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.LIST, params));

        promise.then((response) => {
            if (Array.isArray(response)) {
                setMounted(true);

            } else {

            }
        });
    }

    const getActions = () => {
        let actions = null;
        if (documentLibrary.inBodyAction.actionOn == 'folder') {

            if (documentLibrary.inBodyAction.actionType == 'add') {
                actions = [
                    <Button
                        primary={true}
                        onClick={() => handleAddFolder()}
                        style={styles.primaryButton}
                    >{getLocalizedStrings().label.COMMON.CREATE}</Button>,
                    <Button
                        primary={true}
                        onClick={() => handleClose('folder-add')}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,
                ]
            } else {
                actions = [
                    <Button
                        primary={true}
                        onClick={() => renameFolder()}
                        style={styles.primaryButton}
                    >{getLocalizedStrings().label.COMMON.UPDATE}</Button>,
                    <Button
                        primary={true}
                        onClick={() => handleClose('folder-rename')}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,
                ]
            }
        }
        else if (documentLibrary.inBodyAction.actionOn === 'file') {
            if (documentLibrary.inBodyAction.actionType == 'add') {
                actions = [
                    <Button
                        primary={true}
                        onClick={() => handleUpload()}
                        style={styles.primaryButton}
                    >{getLocalizedStrings().label.ATTACHMENT.UPLOAD}</Button>,
                    <Button
                        primary={true}
                        onClick={() => handleClose('file-add')}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,
                ]
            }
        } else {
            actions = [
                <Button
                    primary={true}
                    onClick={() => handleClose('all')}
                    style={styles.secondaryButton}
                >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

            ];
        }

        return actions;
    }

    const getDocumentList = (selectedLibraryValue) => {
        let params = null;
        try {
            setMounted(false);

            if (selectedLibraryValue == 'images') {
                setMounted(true);
                setDocumentList(treeData);
            } else if (selectedLibraryValue == 'stock_images') {
                var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.CATEGORY_LIST, null));
                let _categories = [];
                promise.then((response) => {
                    if (Array.isArray(response)) {
                        response.forEach(function (record) {
                            _categories.push({
                                label: record.category,
                                value: record.category
                            });
                        });
                    }
                    setCategories(_categories);
                    if (_categories.length > 0) {
                        setCategory(_categories[0]);
                        setSelectedCategory(_categories[0].value);

                    }
                    loadStockLibrary(selectedCategory);
                });

            } else if (selectedLibraryValue == 'documents') {
                params = {
                    fields: ["document_name", "t_type", "creator"],
                    "search_field_name": "document_type", "search_text": "through_library", "push_count": true
                }

                var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.DOCUMENT_LIST, params));
                promise.then((response) => {
                    if (Array.isArray(response.records)) {
                        setMounted(true);
                        if (isValidParam(response.push_count)) {
                            let cntArr = {};
                            response.push_count.forEach(function (el) {
                                cntArr[el[0]] = el[1];
                            });
                            setPush_cnt(cntArr);
                        }
                        let dataTree = treeData;
                        let selectedKey = documentLibrary.selectedNode;
                        getAssetsTree(dataTree, selectedKey, (item) => {
                            item.title = item.name;
                        })
                        setDocumentList(treeData);

                    } else {

                    }
                });
            }
        } catch (error) {
            console.error("Error on assetsBody.js >> getDocumentList: " + error);
        }
    }

    const setLoading = (mountedObject) => {
        setMounted(...mountedObject);

    }

    const changeDocumentToSMSBucketAndgetLink = (documentId, documentName, documentType) => {
        let fileName = null;
        let fileext = null;
        try {
            let params = { documentId: documentId, documentType: documentType };
            fileName = documentName;
            fileName = fileName.replace(/'/g, "");
            fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            let duplicateFile = false;
            let fileArr = [];
            if (isValidParam(files) && getArrayParam(files).length > 0) {
                fileArr = files.filter(f => f.name.replace(/'/g, "") == fileName);
                if (isValidParam(fileArr) && getArrayParam(fileArr).length > 0) {
                    duplicateFile = true;
                }
            }
            let isMaxLength = true;
            if (isValidParam(files) && callFrom == constants.SEND_SMS) {
                isMaxLength = getArrayParam(files).length < 10 ? true : false;
            }
            if (fileext.toLowerCase() == 'jpg' || fileext.toLowerCase() == 'jpeg' || fileext.toLowerCase() == 'gif' || fileext.toLowerCase() == 'png') {
                if (isMaxLength) {
                    if (!duplicateFile) {
                        setLoading({ mounted: false });
                        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ATTACHMENT_CHANGE_BUCKET_FOR_SMS, params));
                        promise.then((response) => {
                            setLoading({ mounted: true });
                            if (response.src != '') {
                                let src = response.src;
                                insertDocument(src, documentName);
                            } else if (response.msg != undefined && response.msg != "") {
                                let msg = isValidParam(getLocalizedStrings().message.SMS_INTEGRATION[response.msg.trim().split(' ').join('_').toUpperCase()])
                                    ? getLocalizedStrings().message.SMS_INTEGRATION[response.msg.trim().split(' ').join('_').toUpperCase()] : response.msg;
                                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        });
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.DUPLICATE_FILE_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.MAX_ATTACHMENT_FILE_FOR_SMS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.SMS_INTEGRATION.INSERT_IMAGE_FILE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        } catch (error) {
            console.error("Error in 'AssetsBody.js'-> 'Document -> changeDocumentToSMSBucketAndgetLink()':" + error);
        }
    }

    const generateDocumentList = () => {
        if (documentList.length > 0) {
            let docClass = localSelectedLibrary + '-' + callFrom;
            let showFolder = false;
            if (callFrom == 'menu' || callFrom == 'drawer') {
                showFolder = true;
            }
            let showExternal = true;
            if (appContainer.dialogProps.data) {
                showExternal = isValidParam(appContainer.dialogProps.data.show_external) ? appContainer.dialogProps.data.show_external : true;
            }
            let _documentList = documentList;
            if (!showExternal) {
                _documentList = _documentList.filter((item) => item.t_type !== "external link");
            }

            return _documentList.map((document, index) => {
                let pushCnt = push_cnt[document.id] ? push_cnt[document.id] : 0;
                let docToolTip = '';
                let typeFolder = false;
                if (document.t_type == 'folder') {
                    typeFolder = true;
                    if (getBooleanParam(document.isShared) && localSelectedLibrary == constants.IMAGES_LIBRARY) {
                        document.src = '../../asset/images/externalPage/largeIcons/foldershare.png';
                    }
                }
                let isShowFolder = true;
                if (typeFolder && !showFolder) {
                    isShowFolder = false;
                }
                if (app.me.is_franchisor && !typeFolder && app.me.is_my_clientid) {
                    if (localSelectedLibrary == constants.DOCUMENT_LIBRARY) {
                        if (pushCnt > 1) {
                            docToolTip = document.name + ' ' + getLocalizedStrings().label.DOCUMENT_LIBRARY.SHARED_WITH + ' ' + pushCnt + ' ' + getLocalizedStrings().label.DOCUMENT_LIBRARY.UNITS;
                        } else {
                            docToolTip = document.name + ' ' + getLocalizedStrings().label.DOCUMENT_LIBRARY.SHARED_WITH + ' ' + pushCnt + ' ' + getLocalizedStrings().label.DOCUMENT_LIBRARY.UNIT;
                        }
                    } else {
                        docToolTip = document.name
                    }
                }
                if (isShowFolder) {
                    return (
                        <div class={displyType == 'grid' ? `col-sm-2 ${docClass}` : docClass} style={displyType == 'grid' ? { marginTop: '20px', width: '130px', marginRight: '6%' } : { ...styles.row }}>
                            <div title={displyType == 'grid' ? docToolTip : null} className={displyType == 'grid' ? docClass : ''} key={index} style={{ margin: '2', borderRadius: 10, width: '100%' }}>
                                <Document
                                    style={{ borderRadius: 10 }}
                                    docToolTip={docToolTip}
                                    insertDocument={(src, documentName, id, key, type) => insertDocument(src, documentName, id, key, type)}
                                    deleteDocument={(id, key) => deleteDocument(id, key)}
                                    document={document}
                                    selectedLibrary={localSelectedLibrary}
                                    displyType={displyType}
                                    callFrom={callFrom}
                                    setLoading={(mountedObject) => setLoading(mountedObject)}
                                    showCustomSnackBar={dispatch(showCustomSnackBar)}
                                    getAppDialog={dispatch(getAppDialog)}
                                    app={app}
                                    pushCount={push_cnt[document.id]}
                                    getDocumentList={(selectedLibraryValue) => getDocumentList(selectedLibraryValue)}
                                    changeDocumentToSMSBucketAndgetLink={(documentId, documentName, documentType) => changeDocumentToSMSBucketAndgetLink(documentId, documentName, documentType)}
                                    appContainer={appContainer}
                                    callBack={callBack}
                                />
                            </div>
                        </div>

                    )
                }
            });
        } else {
            let msg = getLocalizedStrings().label.DOCUMENT_LIBRARY.ASSETS_NOT_FOUND;
            if (localSelectedLibrary == constants.IMAGES_LIBRARY) {
                msg = getLocalizedStrings().label.DOCUMENT_LIBRARY.NO_IMAGE_FOUND;
            } else {
                if (callFrom == 'editor') {
                    msg = getLocalizedStrings().label.DOCUMENT_PUSH.DOCUMENT_NOT_FOUND;
                }
            }
            return (<div id={"no-record-div-" + callFrom} style={{ textAlign: 'center', margin: 'auto', marginTop: 150, }}>{msg}</div>)
        }
    }

    const insertDocument = (src, documentName, id, key, type) => {

        let obj = {
            src: src,
            editor: appContainer.dialogProps.data.editor,
            type: appContainer.dialogProps.data.type,
            itemType: type,
            name: documentName,
            id: id,
            key: key
        }
        appContainer.dialogProps.eventName(obj);
    }

    const deleteDocument = (id, key) => {
        const data = [...documentLibrary.mainData];
        let selectedKey = [];
        let delParentId = '';
        getAssetsTree(data, key, (item) => {
            delParentId = item.parentId;
        });
        getAssetsTreeItem(data, (item) => {
            if (item.childId == delParentId) {
                selectedKey = getStringParam(item.key);
                item.title = item.name;
            }
        })
        let params = {
            id: id,
            key: key,
        }
        var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.DELETE, params));
        promise.then((response) => {
            if (response.status == 0) {
                if (response.data.is_deleted) {

                    let _maindata = [...documentLibrary.mainData];
                    let _finalMmaindata = _maindata.filter((item) => {
                        if (item?.key != key) {
                            return item;
                        }
                    });
                    dispatch(updateDocumentLibrary([..._finalMmaindata]))
                    dispatch(updateisAssetsUpdated(true));
                }
                search(searchText, searchBy);
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.IMAGE_DELETE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            }
            else if (response.status == -1 && response.error.message != "") {
                dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        });
    }

    const search = (search_text, search_by) => {
        search_text = search_text.trim().toLowerCase();
        $("#no-record-div-" + callFrom).hide();
        $("#record-container-div-" + callFrom).show();
        $("#record-header-div-" + callFrom).show();
        var matcher = new RegExp(search_text, "gi");
        $('.' + selectedLibrary + '-' + callFrom).each(function () {
            if (search_by == 'All') {
                if (
                    $(this).find('.doc-name-' + callFrom).text().search(matcher) < 0 &&
                    $(this).find('.doc-owner-' + callFrom).text().search(matcher) < 0 &&
                    $(this).find('.doc-ext-' + callFrom).text().search(matcher) < 0
                ) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            } else if (search_by == 'Name' || search_by == 'Title') {
                if ($(this).find('.doc-name-' + callFrom).text().search(matcher) < 0) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            } else if (search_by == 'Type') {
                if ($(this).find('.doc-ext-' + callFrom).text().search(matcher) < 0) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            } else if (search_by == 'Owner') {
                if ($(this).find('.doc-owner-' + callFrom).text().search(matcher) < 0) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            }

        });
        if ($("." + selectedLibrary + "-" + callFrom + ":visible").length == 0) {
            $("#record-container-div-" + callFrom).hide();
            $("#record-header-div-" + callFrom).hide();
            $("#no-record-div-" + callFrom).show();
        }
        setSearchBy(search_by);
        setSearchText(search_text);
    }



    const handleAddFolder = () => {
        let _title = getStringParam(actionsParam.title).trim();
        let key = '';
        try {
            if (_title.length == 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                let params = {};
                params.title = _title;
                params.parentId = actionsParam.parentId;
                //params.childId = actionsParam.childId;

                var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ASSETS_FOLDER_SAVE, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status == 0) {
                            key = response.data.folderId;
                            let _childId = response.data.childId;
                            let _maxChildId = response.data.childId;
                            let _bucketId = response.data.bucketId;
                            let _maindata = [...documentLibrary.mainData];
                            _maindata.push({
                                accessType: "Write",
                                bucketId: _bucketId,
                                childId: _childId,
                                children: [],
                                isLeaf: false,
                                isShared: false,
                                key: key,
                                maxChildId: _maxChildId,
                                name: _title,
                                parentId: actionsParam.parentId,
                                size: null,
                                t_type: "folder",
                                title: _title,
                                uniqueKeys: key,
                                url: null,
                                userid: app.me.id
                            });
                            dispatch(updateDocumentLibrary([..._maindata]))
                            dispatch(updateisAssetsUpdated(true));
                            dispatch(updateInBodyAction(null, null, null));
                            dispatch(showCustomSnackBar('"' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ADDED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        else {
                            if (response.status == -1 && response.error.message == "Duplicate") {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' "' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ALREADY_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            } else {
                                dispatch(showCustomSnackBar('Somthing went wrong', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }

                    })
                }
            }
        } catch (error) {
            console.error("Error on assetsBody.js >> handleAddFolder: " + error);
        }
    }

    const renameFolder = () => {
        let _title = getStringParam(actionsParam.title).trim();
        let type = "folder";
        try {
            let _data = getObjectParam(actionsParam);
            if (_title.length == 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' ' + getLocalizedStrings().message.COMMON.VALIDATE_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                let params = {};
                params.title = _title;
                params.key = _data.key;
                params.type = type;
                var promise = Promise.resolve(HTTPClient.post(endPoints.DOCUMENT_LIBRARY.ASSETS_FOLDER_RENAME, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status == 0) {
                            getDocumentList(selectedLibrary);
                            dispatch(setAppDialogActions(getActions()));
                            let msg = getLocalizedStrings().message.ATTACHMENT.RENAME_SUCCESSFULLY;
                            let _maindata = [...documentLibrary.mainData];
                            let _finalMmaindata = [];
                            _finalMmaindata = _maindata.map((item) => {
                                if (item?.key == _data.key) {
                                    item.title = _title;
                                    item.name = _title;
                                }
                                return item;
                            });

                            dispatch(updateDocumentLibrary([..._finalMmaindata]));
                            dispatch(updateisAssetsUpdated(true));
                            dispatch(updateInBodyAction(null, null, null))
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        else {
                            if (response.status == -1 && response.error.message == "Duplicate") {

                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME + ' "' + _title + '" ' + getLocalizedStrings().message.ATTACHMENT.ALREADY_EXIST, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            } else {
                                dispatch(showCustomSnackBar('Somthing went wrong', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    })
                }
            }
        } catch (error) {
            console.error("Error on assetsBody.js >> renameFolder: " + error);
        }
    }



    let documentListHtml = generateDocumentList();

    let w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        clientHeight = w.innerHeight - 53;
    let assetsContainerHeight = clientHeight - 377;
    if (isValidParam(callFrom) && callFrom == 'menu') {
        assetsContainerHeight = clientHeight - 258;
    } else if (isValidParam(callFrom) && callFrom == 'drawer') {
        assetsContainerHeight = clientHeight - 215;
    } else if (isValidParam(callFrom) && callFrom == constants.SEND_SMS) {
        assetsContainerHeight = clientHeight - 387;
    }

    return (

        <div style={{ ...styles.row, padding: '0px' }}>

            <div style={{ ...styles.sf_12, border: 'solid 1px #d9d9d9', paddingRight: 0, marginRight: 0 }}>
                <div style={{ padding: '14px 12px', borderBottom: '1px solid #d9d9d9' }}>
                    <HeaderSection
                        app={app}
                        selectedLibrary={selectedLibrary}
                        handleAdd={() => handleAdd()}
                        handleCategoryChange={() => handleCategoryChange()}
                        setSelectedCategory={() => set_Selected_Category()}
                        category={selectedCategory}
                        categories={categories}
                        addDocument={addDocument}
                        search={(search_text, search_by) => search(search_text, search_by)}
                        callFrom={callFrom}
                        isFolderAdd={isFolderAdd}
                        isFolderRename={isFolderRename}
                        handleAssetsFolderAdd={() => handleAssetsFolderAdd()}
                        dataTree={documentLibrary.mainData}
                        selectedKey={documentLibrary.selectedNode}
                        displyType={displyType}
                        setDisplayType={setDisplayType}
                        callBack={callBack}
                        menuData={menuData}
                    />
                </div>
                <div style={{ padding: '10px', paddingRight: 0, marginRight: '0px', paddingTop: 0, paddingLeft: '10px', marginLeft: 0 }}>
                    <div style={{
                        ...styles.sf_12, marginTop: '0px', height: 'auto',
                        maxHeight: assetsContainerHeight, overflowY: 'auto',
                        minHeight: assetsContainerHeight, paddingLeft: '7px'
                    }}>
                        <RightSection
                            inProgress={inProgress}
                            error={error}
                            addDocument={addDocument}
                            mounted={mounted}
                            documentList={documentList}
                            documentListHtml={documentListHtml}
                            sortBy={sortBy}
                            sortOrder={sortOrder}
                            onSorting={sortData}
                            selectedLibrary={selectedLibrary}
                            callFrom={callFrom}
                            isFolderAdd={isFolderAdd}
                            height={assetsContainerHeight}
                            isFolderRename={isFolderRename}
                            callBack={callBack}
                            menuData={menuData}
                            displyType={displyType}
                            setDisplayType={setDisplayType}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}



const RightSection = ({ height, mounted, addDocument, selectedLibrary, error, inProgress, isFolderAdd, isFolderRename, documentList, documentListHtml, onSorting, sortBy, sortOrder, callFrom, displyType }) => {
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const documentLibrary = useSelector((state) => state.documentLibrary);
    const textInput = useRef(null);

    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [folderName, setFolderName] = useState(null);
    let { actionOn, actionType, actionsParam } = documentLibrary.inBodyAction;

    useEffect(() => {
        if (actionsParam && actionsParam.files) {
            setUploadedFiles(actionsParam.files);
        } else {
            setUploadedFiles([]);
        }

        if (actionsParam && actionsParam.title) {
            setFolderName(actionsParam.title);
            textInput?.current?.focus();
        } else {
            setFolderName('');
        }

    }, [actionsParam]);
    useEffect(() => {
        if (isFolderAdd) {
            let title = getLocalizedStrings().label.DOCUMENT_LIBRARY.NEW_FOLDER;
            setFolderName(title);
            textInput?.current?.focus();
        } else {
            setFolderName('');
        }
    }, [isFolderAdd]);
    useEffect(() => {
        if (isFolderRename) {
            let title = getLocalizedStrings().label.DOCUMENT_LIBRARY.NEW_FOLDER;
            let key = documentLibrary.inBodyAction.actionsParam.key
            let _maindata = [...documentLibrary.mainData];

            _maindata.forEach((item) => {
                if (item?.key == key) {
                    title = item?.title;
                }
            });
            setFolderName(title);
            textInput?.current?.focus();
        } else {
            setFolderName('');
        }
    }, [isFolderRename]);

    const setName = (event) => {
        if (event) {
            actionsParam.title = event.target.value;
            setFolderName(event.target.value);
        }
    }

    const getFilesForRender = () => {
        let ul = null;
        let li = uploadedFiles.map((f, index) => {
            return <li key={f.fileName + index}><div key={'div' + index} style={{ width: '100%' }}><span key={'span' + index} style={{
                maxWidth: '82%', float: 'left', textAlign: 'left', overflow: 'hidden',
                textOverflow: 'ellipsis', whiteSpace: 'nowrap'
            }} title={f.fileName}> {f.fileName} </span> <span onClick={(e) => { e.preventDefault(); handleFileRemove(index); }}><Icon key={f.fileName + index} style={iconStyles}>close</Icon> </span></div></li>
        });
        if (li != null && li.length > 0) {
            ul = <ul style={{ paddingLeft: '22px' }}>{li}</ul>;
        }
        return ul;
    }

    const handleFileRemove = (indexValue) => {
        let newFiles = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            if (i != indexValue) {
                newFiles.push(uploadedFiles[i]);
            }
        }
        if (newFiles.length == 0) {
            let temp = [];
            setUploadedFiles(temp);
        } else {
            setUploadedFiles(newFiles);
        }
    }




    const fileOpen = (e) => {
        let _treeData = documentLibrary.mainData;
        let totalFileCount = e.target.files.length + uploadedFiles.length;
        if (totalFileCount > 5) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.ATTACHMENT.VALIDATE_MAX_ATTACHMENTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            e.target.value = null;
            return false;
        } else if (totalFileCount <= 5) {
            let _uploadedFiles = [];
            let filesName = null;
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                let fileName = file.name;
                fileName = fileName.replace(/'/g, "");
                let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
                if (appContainer.dialogProps.data.selectedLibrary == constants.IMAGES_LIBRARY) {
                    if (fileext.toLowerCase() == 'jpg' || fileext.toLowerCase() == 'jpeg' || fileext.toLowerCase() == 'gif' || fileext.toLowerCase() == 'png') {
                        let isDuplicate = false;
                        let uploadFileName = file.name;
                        getAssetsTreeItem(_treeData, (item) => {
                            if (item.name == uploadFileName) {
                                isDuplicate = true;
                            }
                        });
                        if (isDuplicate) {
                            if (filesName == null) {
                                filesName = uploadFileName;
                            } else {
                                filesName += ", " + uploadFileName;
                            }
                        } else {
                            let fileDetails = new Object();
                            fileDetails.fileName = file.name;
                            fileDetails.file = e.target.files[i];
                            _uploadedFiles.push(fileDetails);
                        }
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                } else {
                    if (fileext.toLowerCase() == 'exe' || fileext.toLowerCase() == 'bat' || fileext.toLowerCase() == 'sh') {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.DOCUMENT_LIBRARY.INVALID_DOCUMENT_UPLOAD_ERROR, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    } else {
                        let isDuplicate = false;
                        let uploadFileName = file.name;
                        getAssetsTreeItem(_treeData, (item) => {
                            if (item.name == uploadFileName) {
                                isDuplicate = true;
                            }
                        });
                        if (isDuplicate) {
                            if (filesName == null) {
                                filesName = uploadFileName;
                            } else {
                                filesName += ", " + uploadFileName;
                            }
                        } else {
                            let fileDetails = {};
                            fileDetails.fileName = file.name;
                            fileDetails.file = e.target.files[i];
                            _uploadedFiles.push(fileDetails);
                        }
                    }
                }
            }
            let _actionsParam = actionsParam;
            _actionsParam.files = [...uploadedFiles, ..._uploadedFiles];

            dispatch(updateInBodyAction(actionOn, actionType, _actionsParam))
            setUploadedFiles([...uploadedFiles, ..._uploadedFiles])
            if (filesName != null) {
                let errorMsg = '';
                if (appContainer.dialogProps.data.selectedLibrary == constants.IMAGES_LIBRARY) {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_IMAGE_NAME;
                } else {
                    errorMsg = filesName + " " + getLocalizedStrings().message.DOCUMENT_LIBRARY.DUPLICATE_DOCUMENT_NAME;
                }
                dispatch(showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            e.target.value = null;
        }
    }



    let top = (height - 60) / 2
    if (!mounted) {
        return (
            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        )
    } else if (addDocument && selectedLibrary == 'images') {
        return (
            <div>
                <div>
                    <div style={{ ...styles.sf_12, border: '1px solid #ccc' }}>
                        <div id="attachmentUpload">
                            <div style={{ ...styles.row, padding: '0px' }}>
                                <div style={{ ...styles.sf_3, padding: 15, backgroundColor: '#fff', width: '210px' }}>
                                    <h3>{getLocalizedStrings().label.ATTACHMENT.MY_COMPUTER}</h3>
                                    <Button
                                        variant="contained"
                                        backgroundColor={'gray'}
                                        labelStyle={{ color: 'white' }}
                                        style={{ ...styles.button, width: '180px' }}
                                        endIcon={<Icon style={iconStyles} color={'primary'}>computer</Icon>}
                                    >
                                        {getLocalizedStrings().label.ATTACHMENT.BROWSE}
                                        <input type="file" style={inlinestyle.exampleImageInput} onChange={(e) => fileOpen(e)} multiple />
                                    </Button>

                                </div>
                                <div style={{ width: '100%', overflow: 'auto', verticalAlign: 'middle', backgroundColor: '#efefef' }}>
                                    {getFilesForRender()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>&nbsp;</div>
                <div>
                    <label>{getLocalizedStrings().label.ATTACHMENT.ATTACHMENT_LOGO_TEXT}</label>
                </div>
                <div>
                    <label>{getLocalizedStrings().label.ATTACHMENT.ATTACHMENT_LOGO_SIZE_TEXT}</label>
                </div>
                {error.upload != '' &&
                    <div style={{ color: '#FF0000', fontSize: '14px' }}>{error.upload}</div>
                }

                {inProgress &&
                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{getLocalizedStrings().label.ATTACHMENT.UPLOADING}...</div>
                }
            </div>
        )
    } else if (selectedLibrary == 'documents' && (addDocument || isFolderAdd || isFolderRename)) {
        let labelName = '';
        if (isFolderAdd) {
            labelName = getLocalizedStrings().label.DOCUMENT_LIBRARY.CREATE_FOLDER;
        }
        if (isFolderRename) {
            labelName = getLocalizedStrings().label.DOCUMENT_LIBRARY.RENAME_FOLDER;
        }
        return (
            <div style={{ marginLeft: '21px', marginTop: '20px', maxWidth: '709px', paddingRight: '40px' }}>
                {addDocument &&
                    <div>
                        <label style={{ marginLeft: '8px' }}>{getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE}</label>
                        <div style={{ ...styles.sf_12, border: '1px solid #ccc' }}>
                            <div id="attachmentUpload">
                                <div style={{ ...styles.row, padding: '0px' }}>
                                    <div style={{ ...styles.sf_3, padding: 15, backgroundColor: '#fff', width: '210px' }}>
                                        <h3>{getLocalizedStrings().label.ATTACHMENT.MY_COMPUTER}</h3>

                                        <Button
                                            variant="contained"
                                            labelStyle={{ color: 'white' }}
                                            style={{ width: '180px', color: "black", backgroundColor: 'rgb(239, 239, 239)' }}
                                            endIcon={<ComputerIcon style={iconStyles} color={'primary'} />}
                                        >
                                            {getLocalizedStrings().label.ATTACHMENT.BROWSE}
                                            <input type="file" style={inlinestyle.exampleImageInput} onChange={(e) => fileOpen(e)} multiple />
                                        </Button>

                                    </div>
                                    <div style={{ width: '100%', overflow: 'auto', verticalAlign: 'middle', backgroundColor: '#efefef' }}>
                                        {getFilesForRender()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        {error.upload != '' &&
                            <div style={{ color: '#FF0000', fontSize: '14px' }}>{error.upload}</div>
                        }

                        {inProgress &&
                            <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{getLocalizedStrings().label.ATTACHMENT.UPLOADING}...</div>
                        }
                    </div>
                }
                {(isFolderAdd || isFolderRename) &&
                    <div>
                        <div style={{ paddingBottom: '8px', fontWeight: 'bold' }}>{labelName}</div>
                        <div className="col-sm-8 col-two" style={{ paddingLeft: '0px' }}>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    key='title'
                                    name='title'
                                    autoFocus={true}
                                    ref={textInput}
                                    value={folderName}
                                    onChange={setName}
                                    label={getLocalizedStrings().message.ATTACHMENT.FOLDER_NAME}
                                    fullWidth={true}
                                    autoComplete="new-password"
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                    </div>
                }
            </div>
        )
    }

    else {
        let msg = getLocalizedStrings().label.DOCUMENT_LIBRARY.ASSETS_NOT_FOUND;
        if (selectedLibrary == constants.IMAGES_LIBRARY) {
            msg = getLocalizedStrings().label.DOCUMENT_LIBRARY.NO_IMAGE_FOUND;
        } else {
            if (callFrom == 'editor') {
                msg = getLocalizedStrings().label.DOCUMENT_PUSH.DOCUMENT_NOT_FOUND;
            }
        }
        return (
            <div style={{ paddingTop: 10 }}>
                <div class="container">
                    {documentList.length > 0 && displyType == 'list' && <div id={"record-header-div-" + callFrom} style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'center', padding: '5px 15px', fontWeight: '900' }}>
                        <div onClick={() => onSorting('name')} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                            <div>
                                {'Name'}
                            </div>
                            {sortBy == 'name' && <div style={{ display: 'grid' }}>
                                {sortOrder == 'asc' && <i class="fa-solid fa-sort-up" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                {sortOrder == 'desc' && <i class="fas fa-sort-down" style={{ fontSize: '22px', marginTop: '-10px' }}></i>}
                            </div>}
                        </div>
                        <div onClick={() => onSorting('t_type')} style={{ width: '200px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                            <div>  {'Type'}
                            </div>
                            {sortBy == 't_type' && <div style={{ display: 'grid' }}>
                                {sortOrder == 'asc' && <i class="fa-solid fa-sort-up" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                {sortOrder == 'desc' && <i class="fas fa-sort-down" style={{ fontSize: '22px', marginTop: '-10px' }}></i>}
                            </div>}
                        </div>
                        <div onClick={() => onSorting('creator')} style={{ width: '250px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                            <div> {'Creator'}
                            </div>
                            {sortBy == 'creator' && <div style={{ display: 'grid' }}>
                                {sortOrder == 'asc' && <i class="fa-solid fa-sort-up" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                {sortOrder == 'desc' && <i class="fas fa-sort-down" style={{ fontSize: '22px', marginTop: '-10px' }}></i>}
                            </div>}
                        </div>
                        <div onClick={() => onSorting('date')} style={{ width: '285px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                            <div> {' Create date '}
                            </div>
                            {sortBy == 'date' && <div style={{ display: 'grid' }}>
                                {sortOrder == 'asc' && <i class="fa-solid fa-sort-up" style={{ fontSize: '22px', marginTop: '5px' }}></i>}
                                {sortOrder == 'desc' && <i class="fas fa-sort-down" style={{ fontSize: '22px', marginTop: '-10px' }}></i>}
                            </div>}
                        </div>
                        <div style={{ width: '50px' }}>
                            <div style={{ width: '100%', display: 'flex', gap: '10px' }}></div>
                        </div>

                    </div>}
                    <div id={"record-container-div-" + callFrom} style={displyType == 'list' ? { height: height - 50, overflow: 'scroll' } : { display: 'flex', flexWrap: 'wrap' }}>
                        {documentListHtml}
                    </div>
                </div>
                <div id={"no-record-div-" + callFrom} style={{ display: 'none', textAlign: 'center', margin: 'auto', marginTop: 150, float: 'initial' }}>{msg}</div>
            </div>
        )
    }

}


const Document = ({ document, docToolTip, setDocument, setLoading, callFrom, selectedLibrary, changeDocumentToSMSBucketAndgetLink, app, pushCount, getDocumentList, style, insertDocument, deleteDocument, appContainer, callBack, displyType }) => {
    const [shadow, setShadow] = useState(1);
    const [display, setDisplay] = useState('none');
    const [edit, setEdit] = useState(false);
    const [imgError, setimgError] = useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);

    const [_document, set_document] = useState(document);


    const documentLibrary = useSelector((state) => state.documentLibrary);
    const cardRef = useRef(null);
    const dispatch = useDispatch();
    const onMouseOver = () => {
        if (cardRef.current != undefined && cardRef.current != null) {
            cardRef.current.style.display = "block";
        }
        setShadow(3);
        setDisplay('block');
    }
    const onMouseOut = () => {
        if (cardRef.current != undefined && cardRef.current != null) {
            cardRef.current.style.display = "none";
        }
        setShadow(1);
        setDisplay('none');
    }


    const insert_Document = (doc) => {
        const { src, url, name, id, key, t_type } = doc;
        insertDocument(doc.t_type == 'external link' ? url : src, name, id, key, t_type);
    }

    const getExternalLinkAndInsert = (doc) => {
        const { src, url, name, id, key, t_type } = doc;
        if (doc.t_type == 'external link') {
            insertDocument(url, name, id, key, t_type);
        } else {
            let isExternal = appContainer.dialogProps.data.is_library
                && !appContainer.dialogProps.data.is_attachment;
            let params = { documentId: id, is_external: isExternal }
            var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.GET_DOCUMENT_EXTERNAL_LINK, params));
            promise.then((response) => {
                if (response.src != '') {
                    let src = response.src;
                    insertDocument(src, name, id, key, t_type);
                }
            });
        }

    }


    const changeDocumentToEmailBucketAndgetLink = (id, documentName, selectedLibrary, bucketId, isShared) => {
        let params = { documentId: id, documentType: selectedLibrary, bucketId: getIntParam(bucketId), isShared: getBooleanParam(isShared) };
        if (appContainer.hasOwnProperty('dialogProps') && appContainer.dialogProps.hasOwnProperty('data') &&
            appContainer.dialogProps.data.hasOwnProperty('isMailAttachment') && appContainer.dialogProps.data.isMailAttachment) {
            params.isMailAttachment = appContainer.dialogProps.data.isMailAttachment;
        }
        setLoading({ mounted: false });
        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.ATTACHMENT_CHANGE_BUCKET, params));
        promise.then((response) => {
            setLoading({ mounted: true });
            if (response.src != '') {
                let src = response.src;
                insertDocument(src, documentName);
            } else if (response.msg != undefined && response.msg != "") {
                dispatch(showCustomSnackBar(response.msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }


    const handleDeleteDocument = (id, key) => {
        if (callFrom === 'drawer' || callFrom === 'menu') {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_DELETE_MSG, () => handleSfDialogConfirm(id, key), null);
        } else {
            if (sfDialogs.confirm(getLocalizedStrings().message.DOCUMENT_LIBRARY.CONFIRM_DELETE_MSG)) {
                deleteDocument(id, key);
            }
        }
    }

    const handleSfDialogConfirm = (id, key) => {
        deleteDocument(id, key);
    }

    const handleImageErr = (e) => {
        setimgError(true);
        let innerHTML = getLocalizedStrings().label.DOCUMENT_LIBRARY.NO_IMAGE_FOUND; //'No image found'
        e.target.replaceWith(<div className='noimage-div'>{innerHTML}</div>)
    }

    const openShareDialog = (documentKey) => {
        try {
            let treeData = [document];
            let documentID = '';
            let folderName = '';
            let folderType = false;
            if (document.t_type == 'folder') {
                folderType = true;
                folderName = document.name;
            }
            let rootShareId = 0;
            let shareChildId = 0;
            let sharedId = 0;
            let shareChildByRecord = new Object();


            getAssetsTree(treeData, documentKey, (returnItem) => {
                if (returnItem.children) {
                    folderName = returnItem.name;
                    let childArray = returnItem.children || [];
                    rootShareId = documentKey;
                    shareChildId = returnItem.childId;
                    sharedId = returnItem.shareId;
                    if (documentID != '') {
                        documentID = documentID + ',' + documentKey;
                    } else {
                        documentID = documentKey;
                    }
                    shareChildByRecord[documentKey] = getStringParam(sharedId);
                    getAssetsTreeItem(childArray, (item) => {
                        if (documentID != '') {
                            documentID = documentID + ',' + item.key;
                        } else {
                            documentID = item.key;
                        }
                        shareChildByRecord[item.key] = getStringParam(item.shareId);
                    });
                } else {
                    if (selectedLibrary == 'images') {
                        documentID = returnItem.id;
                        rootShareId = returnItem.id;
                    } else {
                        documentID = returnItem.key;
                        rootShareId = documentKey;
                    }
                    shareChildId = returnItem.childId;
                    sharedId = returnItem.shareId;
                    folderName = returnItem.name;
                    shareChildByRecord[documentKey] = getStringParam(returnItem.shareId);
                }
            });


            if (documentID != '' && documentID != null) {
                let data = {
                    documentID: documentID,
                    folderName: folderName,
                    folderType: folderType,
                    isShare: true,
                    rootShareId: getIntParam(rootShareId),
                    shareChildId: getIntParam(shareChildId),
                    sharedId: getIntParam(sharedId),
                    selectedLibrary: selectedLibrary,
                    shareChildByRecord: shareChildByRecord,
                }
                let label = getLocalizedStrings().label.COMMON.SHARE_WITH;
                dispatch(getAppDialog(true, constants.DOCUMENT_SHARE_DIALOG, label, getDocumentList, data, null));
            } else {
                let alertMsg = getLocalizedStrings().message.ATTACHMENT.EMPTY_FOLDER_ERROR_PUSH;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
            }
        } catch (error) {
            console.error("Error on assetsBody.js >> Document >> openShareDialog(): " + error);
        }
    }



    const handleOpenLink = (doc) => {
        let __url = doc.url;
        if (!__url.startsWith("https://") && !__url.startsWith("http://")) {
            __url = "https://" + __url;
        }
        window.open(`${__url}`, `${doc.name}`);
    }

    const handleCardDoubleClick = (e) => {
        if (document.t_type == 'folder') {
            let _expandedKeys = [...documentLibrary.expandedNode];
            const _selectedKey = documentLibrary.selectedNode;
            _expandedKeys = [..._expandedKeys, _selectedKey.toString()];
            dispatch(updateExpandedNode(_expandedKeys))
            callBack('nodeSelecte', document.uniqueKeys);

        }
    }

    const downloadDocument = (item) => {
        let documentName = item.name;
        let url = null;
        let payload = null;
        if (item.t_type == 'folder') {
            documentName = documentName + '.zip'
            payload = { 'id': item.key, child_id: item.childId, documentName: documentName };
            url = endPoints.DOCUMENT_LIBRARY.DOWNLOAD_FOLDER;
        } else {
            payload = { 'id': item.id, isShared: item.isShared }
            url = endPoints.ATTACHMENT.DOWNLOAD;
        }
        if (url != '' && url != null) {
            var formData = new FormData();
            formData.append('input_param', JSON.stringify(payload));
            HTTPClient.postFormDataDownloadFile(url, formData, documentName);
        } else {
            let alertMsg = getLocalizedStrings().message.ATTACHMENT.EMPTY_FOLDER_ERROR_DOWNLOAD;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
        }
    }

    const handelOnmore = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleCloseMoreAction = () => {
        setMoreAnchorEl(null);
    };
    let project_id = document.bucketId != undefined && document.bucketId != null ? document.bucketId : app.me.projectId;

    if (document.isShared) {
        project_id = document.hasOwnProperty('sharedFromProjectId') && document.sharedFromProjectId > 0 ? document.sharedFromProjectId : 0
    }

    let _display = 'block';
    let fileName = document.name;
    let t_type = document.t_type
    let file_name = null;
    if (t_type != 'folder' && t_type !== 'external link' && fileName.lastIndexOf('.') > -1) {
        file_name = fileName.substring(0, fileName.lastIndexOf('.'));
    } else {
        file_name = fileName;
    }
    let themeColor = app.me.background;
    let hasPermission = false;
    if (selectedLibrary == constants.IMAGES_LIBRARY) {
        if (app.me.id == document.userid || app.me.is_tenant_admin) {
            hasPermission = true;
        }
        if (getBooleanParam(document.isShared)) {
            hasPermission = true;
        }
    }
    let _ext = t_type.toLowerCase()
    if (selectedLibrary == 'stock_images') {
        _display = 'none';
    }
    if (selectedLibrary == 'documents') {
        let src = '';
        if (_ext != null) {
            src = getDocumentImage(_ext, document.id, project_id, getBooleanParam(document.isShared), getIntParam(document.shareId));
        } else {
            if (getBooleanParam(document.isShared) || getIntParam(document.shareId) > 0) {
                src = '../../asset/images/externalPage/largeIcons/foldershare.png';
            } else {
                src = '../../asset/images/externalPage/largeIcons/file.png';

            }
        }
        let imageStyle = {};
        if (_ext == "jpg" || _ext == "jpeg" || _ext == "gif" || _ext == "png") {
            imageStyle = { paddingLeft: 7, paddingRight: 7 }
        }

        let overLayShow = false;
        if (callFrom == 'menu' || callFrom == 'drawer') {
            overLayShow = true;
        }
        let pushMarginTop = 12;
        let typeFolder = false;
        if (document.t_type == 'folder') {
            typeFolder = true;
        }
        let hideShare = false;
        if ((document.isShared && app.me.is_franchisor)) {
            hideShare = true;
        }
        let downloadBtnStyle = {};
        if (app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid) {
            downloadBtnStyle = { marginTop: 20 };
        } else {
            downloadBtnStyle = { marginTop: 28 };
        }
        let dlIcon = <i className="fa fa-arrow-down fa-2x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i>;
        if (app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid && overLayShow) {
            dlIcon = <i className="fa fa-arrow-down fa-1x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i>;
        }

        if (hideShare) {
            downloadBtnStyle = { marginTop: 25 };
            dlIcon = <i className="fa fa-arrow-down fa-2x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i>;
        }

        return (

            <div>
                {displyType == 'grid' ? <div>
                    {overLayShow &&
                        <div style={{ ...styles.sf_12, marginTop: '0px', borderRadius: '7px', cursor: 'pointer', textAlign: 'center', margin: 'auto' }}
                        >
                            {/* working here */}
                            <div style={{ ...crdStyle.cardWrapper, display: 'inline-block', width: "70px", height: '90px' }}>
                                <Box className="sf-box-sfbody-card" boxShadow={shadow} p={0} m={0}>
                                    <Card
                                        onMouseOver={() => onMouseOver()}
                                        onMouseOut={() => onMouseOut()}
                                        onDoubleClick={handleCardDoubleClick}
                                    >
                                        <CardActionArea
                                            className='dlcard'>
                                            <CardMedia
                                                component="img"
                                                image={src}
                                                alt="No Image Found"
                                                style={{ alt: "No Image Found", verticalAlign: 'top', maxWidth: '100%', minWidth: '100%', width: '100%', top: '16px' }}
                                                onError={(e) => handleImageErr(e)}
                                                className='dlimg'
                                            >

                                            </CardMedia>
                                            {!edit && <div>
                                                <div style={{ ...crdStyle.layer }} ref={cardRef}>
                                                    {_ext !== 'external link' && <div className="sf-card-btn-overlay" style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                                        <div style={downloadBtnStyle}>
                                                            <span title={getLocalizedStrings().label.COMMON.DOWNLOAD}
                                                                onClick={
                                                                    () => {
                                                                        downloadDocument(document);
                                                                    }
                                                                }
                                                            >{dlIcon}</span>

                                                        </div>
                                                        {(!hideShare && app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid && overLayShow) &&
                                                            <div>
                                                                <div style={{ marginTop: pushMarginTop, display: _display }}>
                                                                    <span title={getLocalizedStrings().label.COMMON.SHARE}
                                                                        onClick={
                                                                            () => {
                                                                                openShareDialog(document.key);
                                                                            }
                                                                        }
                                                                    ><i className="fa fa-share  fa-1x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i></span>
                                                                </div>

                                                            </div>
                                                        }
                                                    </div>}
                                                    {
                                                        <div className="sf-card-btn-overlay" style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                                            <div style={{ marginTop: 25 }}>
                                                                <span title={getLocalizedStrings().label.COMMON.OPEN}
                                                                    onClick={
                                                                        () => {
                                                                            handleOpenLink(document);
                                                                        }
                                                                    }
                                                                > <i className="fa fa-expand fa-2x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            </div>
                        </div>
                    }
                    {!overLayShow &&
                        <div style={{ ...styles.sf_12, marginTop: '0px', borderRadius: '7px', cursor: 'pointer', textAlign: 'center', margin: 'auto' }}
                            onClick={
                                () => {
                                    if (callFrom != undefined && callFrom == constants.SALES_EMAIL) {
                                        changeDocumentToEmailBucketAndgetLink(document.id, document.name, selectedLibrary, project_id, document.isShared);
                                    } else if (isValidParam(callFrom) && callFrom == constants.SEND_SMS) {
                                        changeDocumentToSMSBucketAndgetLink(document.id, document.name, selectedLibrary);
                                    } else {
                                        getExternalLinkAndInsert(document);
                                    }
                                }
                            }
                        >
                            <Box className="sf-box-sfbody-card" boxShadow={3} p={0} m={0}>
                                <Card
                                    onMouseOver={() => onMouseOver()}
                                    onMouseOut={() => onMouseOut()}
                                >
                                    <CardActionArea
                                        className=''
                                        zDepth={shadow} style={{ width: '100%', height: 65, margin: 'auto' }}>
                                        {!edit &&
                                            <CardMedia
                                                component="img"
                                                image={src}
                                                style={{ alt: "No Image Found", width: "100%", height: "100%" }}
                                                onError={(e) => handleImageErr(e)}
                                                className='dlimg'
                                            >
                                            </CardMedia>
                                        }
                                    </CardActionArea>
                                </Card>
                            </Box>
                        </div>
                    }

                    <div style={{ color: '#000000', fontSize: '12px', paddingTop: '5px' }}>
                        <span title={document.name} className="name" style={{ color: '#000000', fontSize: '12px', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 'auto', width: '100%', textAlign: 'center' }}>{document.name}</span>
                        <br />
                        {(app.me.is_franchisor && app.me.is_my_clientid && !typeFolder && overLayShow && _ext !== 'external link') &&
                            <span style={{ margin: 'auto', width: '100%', textAlign: 'center', display: 'inline-block' }}>({pushCount ? pushCount : 0})</span>
                        }
                    </div>
                </div> : <div style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <div style={{ width: '100%', display: "flex", alignItems: 'center', gap: '10px', cursor: 'pointer', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} onClick={handleCardDoubleClick} >
                        <div title={docToolTip}>
                            <img style={{ height: '50px', width: '50px', display: 'flex', alignItems: 'center' }} src={src} />
                        </div>
                        <div style={{ width: '100%', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={docToolTip} >
                            {file_name}
                        </div>
                    </div>
                    <div style={{ width: '200px' }}>
                        <span title={document.t_type !== 'folder' && document.t_type !== 'external link' ? _ext.toUpperCase() : makeTiteleCase(_ext)}>{document.t_type !== 'folder' && document.t_type !== 'external link' ? _ext.toUpperCase() : makeTiteleCase(_ext)}</span>
                    </div>
                    <div style={{ width: '250px' }}>
                        <span title={document.creator}>{document.creator}</span>
                    </div>
                    <div style={{ width: '250px' }}>
                        <span title={document.date}>{document.date}</span>
                    </div>
                    <div style={{ width: '50px' }}>
                        <IconButton title={'Actions'} style={{ padding: '5px', borderRadius: '12px', cursor: 'pointer' }} onClick={handelOnmore}>
                            <Icon>more_vert</Icon>
                        </IconButton >
                        <Popover
                            open={Boolean(moreAnchorEl)}
                            anchorEl={moreAnchorEl}
                            onClose={handleCloseMoreAction}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div style={{ padding: '10px' }}>
                                {_ext !== 'external link' ? <>
                                    <div style={{ cursor: 'pointer', padding: '10px 5px' }} onClick={
                                        () => {
                                            handleCloseMoreAction();
                                            downloadDocument(document);
                                        }
                                    }
                                    > <i className="fa fa-arrow-down fa-1x" aria-hidden="true" style={{ marginRight: 5 }}></i> {getLocalizedStrings().label.COMMON.DOWNLOAD}</div>


                                    {(!hideShare && app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid && overLayShow) && <>

                                        <div style={{ display: _display, cursor: 'pointer', padding: '10px 5px' }} onClick={
                                            () => {
                                                handleCloseMoreAction();
                                                openShareDialog(document.key);
                                            }
                                        }
                                        ><i className="fa fa-share  fa-1x" aria-hidden="true" style={{ marginRight: 5 }}></i> {getLocalizedStrings().label.COMMON.SHARE}</div>


                                    </>
                                    }
                                </> :
                                    <div onClick={
                                        () => {
                                            handleCloseMoreAction();
                                            handleOpenLink(document);
                                        }
                                    }
                                        style={{ cursor: 'pointer' }}>
                                        <i className="fa fa-expand fa-1x" aria-hidden="true" style={{ marginRight: 5 }}></i> {getLocalizedStrings().label.COMMON.OPEN}
                                    </div>}
                            </div>
                        </Popover>
                    </div>

                </div>}

                <span style={{ display: 'none' }} className={"doc-name-" + callFrom}>{file_name}</span>
                <span style={{ display: 'none' }} className={"doc-ext-" + callFrom}>{_ext}</span>
                <span style={{ display: 'none' }} className={"doc-owner-" + callFrom}>{document.creator}</span>
            </div>
        );
    }

    let delTop = 30;
    if (app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid) {
        delTop = 15;
    }
    let imgSrc = document.src;
    return (
        <div>
            {displyType == 'grid' ? <div>
                <Box className="sf-box-sfbody-card" boxShadow={3} p={0} m={0}>
                    <Card>
                        <CardActionArea
                            onMouseOver={() => onMouseOver()}
                            onMouseOut={() => onMouseOut()} className='sf-assetbodychangeht'
                            zDepth={shadow} style={{}}>

                            {hasPermission &&
                                <div style={{ ...crdStyle.cardWrapper, height: '100px', display: 'inline-block' }}>
                                    <CardMedia
                                        style={{ alt: "No Image Found", height: '100%', width: '100%' }}
                                        component="img"
                                        image={imgSrc}
                                        onError={(e) => handleImageErr(e)}
                                        className='dlimg-card'
                                    >
                                    </CardMedia>
                                    <CardActions>
                                        <div style={{ ...crdStyle.layer }} ref={cardRef}>
                                            <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                                                {(callFrom == constants.SEND_SMS) ?
                                                    <div style={{ marginTop: 50 }}>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    if (callFrom != undefined && callFrom == constants.SALES_EMAIL) {
                                                                        changeDocumentToEmailBucketAndgetLink(document.id, document.name, selectedLibrary, project_id, document.isShared);
                                                                    } else if (isValidParam(callFrom) && callFrom == constants.SEND_SMS) {
                                                                        changeDocumentToSMSBucketAndgetLink(document.id, document.name, selectedLibrary);
                                                                    } else {
                                                                        insert_Document(document);
                                                                    }
                                                                }
                                                            }
                                                            style={{ margin: '5px auto', backgroundColor: themeColor, color: '#ffffff', border: 'solid 1px #d3d6d6', zIndex: '0', height: '28px', lineHeight: '28px' }}
                                                        >{getLocalizedStrings().label.COMMON.INSERT}</Button>
                                                    </div> :
                                                    <div style={{ marginTop: delTop, display: display }}>
                                                        {(app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid) &&
                                                            <div style={{ marginBottom: 15, display: 'block' }}>
                                                                <span title={getLocalizedStrings().label.COMMON.SHARE}
                                                                    onClick={
                                                                        () => {
                                                                            openShareDialog(document.key);
                                                                        }
                                                                    }
                                                                ><i className="fa fa-share  fa-2x" aria-hidden="true" style={{ color: '#FAFAFA', cursor: 'pointer', margin: 'auto', }} ></i></span>
                                                            </div>}
                                                        <Button
                                                            onClick={() => handleDeleteDocument(document.id, document.key)}
                                                            title={getLocalizedStrings().label.COMMON.DELETE}
                                                            style={{ marginBottom: '5px', backgroundColor: '#ffffff', color: '#717171', border: 'solid 1px #e0e0e0', zIndex: '0', height: '28px', lineHeight: '28px' }}
                                                        >{getLocalizedStrings().label.COMMON.DELETE}</Button>

                                                    </div>}
                                            </div>
                                        </div>
                                    </CardActions>
                                </div>
                            }
                        </CardActionArea>
                        {!hasPermission &&
                            <CardActionArea
                                // onMouseOver={onMouseOver}
                                // onMouseOut={onMouseOut} className=''
                                zDepth={shadow} style={{ width: 50, height: 50, margin: 'auto' }}>
                                {!edit &&
                                    <CardMedia
                                        mediaStyle={{}}
                                        overlayContentStyle={{ display: display }}
                                        style={{ height: '100%' }}
                                        component="img"
                                        image={document.src}
                                        alt={getLocalizedStrings().label.ATTACHMENT.NO_IMAGE_FOUND}
                                        onError={(e) => handleImageErr(e)}
                                        className='dlimg'
                                    >
                                    </CardMedia>
                                }
                            </CardActionArea>

                        }
                    </Card>
                </Box>

                <div style={{ color: '#000000', fontSize: '12px', paddingTop: '5px' }}>
                    <span title={document.name} className="name" style={{ color: '#000000', fontSize: '12px', overflow: 'hidden', display: 'inline-block', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 'auto', width: '100%', textAlign: 'center' }}>{document.name}</span>
                </div>
            </div> : <div style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'center' }}>

                <div style={{ width: '100%', display: "flex", alignItems: 'center', gap: '10px', cursor: 'pointer', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} onClick={handleCardDoubleClick} onDoubleClick={handleCardDoubleClick}>
                    <div title={docToolTip}>
                        <img style={{ height: '50px', width: '50px', display: 'flex' }} src={document.src} />
                    </div>
                    <div style={{ width: '100%', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={docToolTip}>
                        {file_name}
                    </div>
                </div>
                <div style={{ width: '200px' }} title={_ext}>
                    <span title={document.t_type !== 'folder' && document.t_type !== 'external link' ? _ext.toUpperCase() : makeTiteleCase(_ext)}></span>{document.t_type !== 'folder' && document.t_type !== 'external link' ? _ext.toUpperCase() : makeTiteleCase(_ext)}
                </div>
                <div style={{ width: '250px' }}>
                    <span title={document.creator}>{document.creator}</span>
                </div>
                <div style={{ width: '250px' }} >
                    <span title={document.date}>{document.date}</span>
                </div>
                <div style={{ width: '50px' }}>
                    {hasPermission && <IconButton title={'Actions'} style={{ padding: '5px', borderRadius: '12px', cursor: 'pointer' }} onClick={handelOnmore}>
                        <Icon>more_vert</Icon>
                    </IconButton >}
                    <Popover
                        open={Boolean(moreAnchorEl)}
                        anchorEl={moreAnchorEl}
                        onClose={handleCloseMoreAction}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div style={{ padding: 10 }}>

                            {(callFrom == constants.SEND_SMS) ? <div style={{ cursor: 'pointer', padding: '10px 5px' }}
                                onClick={
                                    () => {
                                        if (callFrom != undefined && callFrom == constants.SALES_EMAIL) {
                                            changeDocumentToEmailBucketAndgetLink(document.id, document.name, selectedLibrary, project_id, document.isShared);
                                        } else if (isValidParam(callFrom) && callFrom == constants.SEND_SMS) {
                                            changeDocumentToSMSBucketAndgetLink(document.id, document.name, selectedLibrary);
                                        } else {
                                            insert_Document(document);
                                        }
                                    }
                                }
                            ><i className="fa fa-arrow-down fa-1x" aria-hidden="true" style={{ marginRight: 5 }}></i> {getLocalizedStrings().label.COMMON.INSERT}</div> :
                                <>
                                    {(app.me.is_franchisor && app.me.is_tenant && app.me.is_my_clientid) &&
                                        <div
                                            style={{ cursor: 'pointer', padding: '10px 5px' }}
                                            onClick={
                                                () => {
                                                    openShareDialog(document.key);
                                                }
                                            }
                                        ><i className="fa fa-share  fa-1x" aria-hidden="true" style={{ marginRight: 5 }}></i> {getLocalizedStrings().label.COMMON.SHARE}</div>}
                                    <div
                                        onClick={() => handleDeleteDocument(document.id, document.key)}
                                        style={{ cursor: 'pointer', padding: '10px 5px' }} ><i className="fa fa-trash" aria-hidden="true" style={{ marginRight: 5 }}></i>  {getLocalizedStrings().label.COMMON.DELETE}</div>
                                </>
                            }

                        </div>
                    </Popover>
                </div>

            </div>}
            <span style={{ display: 'none' }} className={"doc-name-" + callFrom}>{file_name}</span>
            <span style={{ display: 'none' }} className={"doc-ext-" + callFrom}>{_ext}</span>
            <span style={{ display: 'none' }} className={"doc-owner-" + callFrom}>{document.creator}</span>
        </div>
    );
}




const HeaderSection = ({ app, selectedKey, setSelectedCategory, handleAdd, handleAssetsFolderAdd, handleCategoryChange, selectedLibrary, searchText, callFrom, isFolderRename, search, addDocument, isFolderAdd, displyType, setDisplayType, callBack, menuData }) => {
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [searchBy, setSearchBy] = useState('Name');
    const [isShareBySelected, setIsShareBySelected] = useState(false);
    const [isWriteAccess, setIsWriteAccess] = useState(true);
    const [searchOptions, setSearchOptions] = useState([]);
    const [searchByVar, setSearchByVar] = useState(getLocalizedStrings().label.DOCUMENT_LIBRARY.NAME);

    const documentLibrary = useSelector((state) => state.documentLibrary);

    useEffect(() => {
        let _mainData = [...documentLibrary.mainData];
        let _mainDataObj = _mainData.find((item) => item.key == selectedKey);
        let _isReadAccessOnly = isReadAccessOnly(_mainDataObj);
        if (_isReadAccessOnly || selectedKey == -99) {
            setIsWriteAccess(false);
        } else {
            setIsWriteAccess(true);
        }
        let _isSharedFolder = isSharedFolder(_mainDataObj);
        if (_isSharedFolder) {
            setIsShareBySelected(true);
        } else {
            setIsShareBySelected(false);
        }
    }, [documentLibrary, selectedKey]);

    const isReadAccessOnly = (selectedItem) => {
        let _mainDataArr = [...documentLibrary.mainData];
        let _selectedPId = 0;
        if (selectedItem) {
            _selectedPId = selectedItem?.parentId || 0;
        }
        let _mainDataObj = _mainDataArr.find((item) => item.childId === _selectedPId)
        if (selectedItem?.accessType === 'Read') {
            return true;
        }
        if (selectedItem && selectedItem?.parentId !== 0) {
            return isReadAccessOnly(_mainDataObj);
        }
        return false;
    }

    const isSharedFolder = (selectedItem) => {

        let _mainDataArr = [...documentLibrary.mainData];
        let _selectedPId = 0;
        if (selectedItem) {
            _selectedPId = selectedItem?.parentId || 0;
        }
        let _mainDataObj = _mainDataArr.find((item) => item.childId === _selectedPId)
        if (selectedItem?.key == -99) {
            return true;
        }
        if (selectedItem && selectedItem?.parentId !== 0) {
            return isSharedFolder(_mainDataObj);
        }
        return false;
    };

    const handleChangeCategoryList = (value) => {
        setCategory(value);
        handleCategoryChange(category);
    }


    const loadCategoryList = () => {

        var promise = Promise.resolve(HTTPClient.get(endPoints.DOCUMENT_LIBRARY.CATEGORY_LIST, null));
        let _categories = [];
        promise.then((response) => {
            if (Array.isArray(response)) {
                response.forEach(function (record) {
                    _categories.push({
                        label: record.category,
                        value: record.category
                    });
                });
            }
            setCategories(_categories)
            if (_categories.length > 0) {
                setCategory(_categories[0]);
                setSelectedCategory(_categories[0]);
            }
        });
    }

    const loadSearchByOptionList = () => {
        let _searchOptions = [
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.NAME,
                value: 'Name'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.TITLE,
                value: 'Title'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.TYPE,
                value: 'Type'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.OWNER,
                value: 'Owner'
            },
            {
                label: getLocalizedStrings().label.DOCUMENT_LIBRARY.ALL,
                value: 'All'
            }
        ]
        setSearchOptions(_searchOptions)
    }

    useEffect(() => {
        loadCategoryList();
        loadSearchByOptionList();
    }, []);

    const handleKeyUp = (event) => {
        search(event.target.value, searchBy);
    }


    const getMenuList = () => {
        return searchOptions.map((fieldObject, i) => {
            return <MenuItem autowidth={false} value={fieldObject.value} style={{ ...styles.popoverMenuItem, width: 135 }} >{fieldObject.label}</MenuItem>;
        })
    }

    const handleSearchByChange = (event) => {
        let value = event.target.value;
        let searchByVarlabel = searchOptions.filter(f => { return (f.value == value) });
        setSearchBy(value);
        setSearchByVar(searchByVarlabel[0].label);
    };



    const generateAddActions = () => {
        let items = [];
        let itemsArr = [];

        if (!isShareBySelected) {
            itemsArr.push({ value: 'Folder', text: 'Folder', rightIconClassName: 'fa fa-plus' });
        };
        itemsArr.push({ value: 'File', text: 'File', rightIconClassName: 'fa fa-upload' });
        itemsArr.map((item, i) => {
            let data = {};
            data.label = item.text;
            data.value = item.value;
            data.leftIcon = <i className={item.rightIconClassName} />;
            items.push(data);
        });
        return items;
    }

    const addActions = (value) => {
        if (value == "Folder") {
            handleAssetsFolderAdd();
        } else if (value == "File") {
            handleAdd();
        }
    }
    const handleViewTypeChange = (event, nextView) => {
        let userId = (app.me.id * 19000) + 7;
        let projectId = (app.me.projectId * 19000) + 7;
        let cookieName = "asset-body-view" + projectId + '-' + userId;
        if (nextView) {
            setCookie(cookieName, nextView);
            setDisplayType(nextView);
        }
    };


    let searchByMenus = getMenuList();
    let addAction = generateAddActions();
    let btnleft = '25px';
    let language = getLocalizedStrings()._language;

    if (language == 'de-DE') {
        btnleft = '5px';
    }
    let selectStyle = { width: '120px', padding: '0px 3px', height: '30px', fontSize: '14px', cursor: "pointer" };
    if (selectedLibrary == 'images' || selectedLibrary == 'documents') {
        let fieldDisabled = false;
        let buttonStyle = { ...styles.listViewPrimaryButton, marginTop: "0px" };
        if (selectedLibrary == 'documents' && (addDocument || isFolderAdd || isFolderRename)) {
            fieldDisabled = true;
            selectStyle = { ...selectStyle, cursor: 'default' };
            buttonStyle = { ...styles.secondaryButton, cursor: 'not-allowed', pointerEvents: 'none', color: '#c0c0c0' };
        }
        let isCombineBtn = true;
        if (callFrom == 'editor' || callFrom == constants.SALES_EMAIL || callFrom == constants.SEND_SMS
            || selectedLibrary == constants.IMAGES_LIBRARY) {
            isCombineBtn = false;
        }
        const handleBack = () => {
            let _selectedPId = 0;
            let _mainDataArr = [...documentLibrary.mainData];
            let _mainDataObj = _mainDataArr.find((item) => item.key == selectedKey);
            if (_mainDataObj) {
                _selectedPId = _mainDataObj?.parentId || 0;
            }
            let __lastData = _mainDataArr.find((item) => item.childId === _selectedPId)
            callBack('nodeSelecte', parseInt(__lastData.uniqueKeys));
            if (_selectedPId == 1) {
                scrollToItem('menu-wrap')
            }
        }
        const scrollToItem = (__id) => {
            const element = document.getElementById(__id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };
        let _mainDataArr = [...documentLibrary.mainData];
        let _mainDataObj = _mainDataArr.find((item) => item.key == selectedKey);
        return (
            <div style={{ ...styles.row, alignItems: 'center' }}>

                <div style={{ ...styles.sf_10 }}>

                    <div style={{ ...styles.row, padding: 0 }}>
                        {(_mainDataObj?.parentId > 0 || _mainDataObj?.parentId == -999) &&
                            <div style={{ width: '50px', height: '30px', }}>
                                <IconButton title={getLocalizedStrings().label.COMMON.BACK} style={{ padding: '2px', borderRadius: '5px', cursor: 'pointer', backgroundColor: styles.primaryButton.backgroundColor }} onClick={handleBack}>
                                    <ArrowBackIcon style={{ color: styles.primaryButton.color }} />
                                </IconButton >
                            </ div>
                        }
                        <div style={{ width: '130px', height: '30px' }}>
                            <FormControl sx={{ m: 0, minWidth: 130 }} style={{ padding: '0px', margin: '0px' }} size="small">
                                <Select name='searhby' variant="outlined"
                                    value={searchBy}
                                    onChange={(e) => handleSearchByChange(e)}
                                    style={{ width: '130px', height: '30px' }}
                                    autowidth={false}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {searchByMenus}
                                </Select>
                            </FormControl>

                        </div>
                        <div style={{ ...styles.sf_10, paddingLeft: '4.4%' }}>
                            <div style={{ border: '1px solid #ccc', background: '#fff', borderRadius: '4px', height: '30px', }}>
                                <input className='assets-search' type='text' value={searchText} onKeyUp={(e) => handleKeyUp(e)} placeholder={getLocalizedStrings().label.DOCUMENT_LIBRARY.SEARCH} style={{ height: '27px', color: '#888', border: 'none', paddingLeft: '10px', width: '85%' }} disabled={fieldDisabled} />
                                <i className="fa fa-search" style={{ paddingTop: '5px', paddingLeft: '7px', color: '#ccc', float: 'right', paddingRight: '6px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.sf_2 }}>
                    {isWriteAccess && <div style={{ cursor: 'pointer', marginLeft: btnleft }}>
                        {!isCombineBtn &&
                            <Button
                                primary={true}
                                onClick={() => handleAdd()}
                                style={buttonStyle}
                                disabled={fieldDisabled}
                            >{getLocalizedStrings().label.COMMON.ADD}</Button>
                        }{isCombineBtn && <div>
                            {fieldDisabled ?
                                <Button
                                    primary={true}
                                    style={{ ...buttonStyle, paddingTop: '2px' }}
                                    endIcon={<div >
                                        <i className="material-icons">keyboard_arrow_down</i>
                                    </div>}
                                    disabled={fieldDisabled}
                                >{getLocalizedStrings().label.COMMON.ADD}</Button>
                                :
                                <PopOver
                                    id={"PopOver"}
                                    key={"PopOver"}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={styles.listViewPrimaryButton}
                                    labelposition={"before"}
                                    buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                                    options={addAction}
                                    onclickAction={(value) => addActions(value)}
                                    buttonLineOrigin={[28, 23]}

                                />
                            }
                        </div>}
                    </div>
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ToggleButtonGroup
                        size="small"
                        value={displyType}
                        exclusive
                        onChange={handleViewTypeChange}
                        style={{ padding: '0px' }}
                    >
                        <ToggleButton value="grid" aria-label="grid" style={{ padding: '6px' }} title='Grid View'>
                            <ViewModuleIcon sx={{ fontSize: 17 }} />
                        </ToggleButton>
                        <ToggleButton value="list" aria-label="list" style={{ padding: '6px' }} title='List View'>
                            <ViewListIcon sx={{ fontSize: 17 }} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        );
    } else if (selectedLibrary == 'stock_images') {
        return (
            <div style={{ ...styles.row }}>
                <div style={{ ...styles.sf_2 }}>
                    <span style={{ fontSize: '14px' }}>{getLocalizedStrings().label.COMMON.CATEGORIES}:</span>
                </div>
                <div style={{ ...styles.sf_4 }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <Autocomplete
                            value={category}
                            name="category"
                            options={categories}
                            onChange={(value) => handleChangeCategoryList(value)}
                            getOptionLabel={(option) => option.label}
                            renderOption={(option) => {
                                return (
                                    <div title={option.label}>
                                        {option.label}
                                    </div>
                                );
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.label}
                                        title={option.label}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            disableClearable={false}
                            className="search-by"
                            style={selectStyle}
                            renderInput={(params) => <TextField {...params}
                                variant="outlined" margin="dense" className={"sf-fields-bg"} />}
                        />
                    </FormControl>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}


export default AssetsBody;