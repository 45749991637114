import { actions } from '../constants/actions';

export const updateMenuItemCopy = () => {
    return {
        type: actions.UPDATE_MENU_ITEMS_COPY,
        payload: true
    }
}

export const updateMenuItemCancel = () => {
    return {
        type: actions.UPDATE_MENU_ITEMS_CANCEL,
        payload: false
    }
}

export const updateMenuItemName = (value) => {
    return {
        type: actions.UPDATE_MENU_ITEMS_NAME,
        payload: value
    }
}

export const createMenuItemName = (value) => {
    return {
        type: actions.CREATE_MENU_ITEMS_NAME,
        payload: value
    }
}

export const updateMenuItemSaveName = (data) => {
    return {
        type: actions.UPDATE_MENU_ITEMS_SAVE_NAME,
        payload: data
    }
}

export const updateMenuItemDelete = () => {
    return {
        type: actions.UPDATE_MENU_ITEMS_DELETE,
        payload: true
    }
}

export const getAllMenuItemsData = (data) => {
    return {
        type: actions.GET_ALL_MENU_ITEMS,
        payload: data
    }
}

export const resetMenuItem = () => {
    return {
        type: actions.RESET_MENU_ITEMS,
    }
}   

export const isMenuNameSaving = (value) => {
    return {
        type: actions.DESKTOP_MENU_NAME_SAVING,
        payload: value
    }
}

