
import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { drillDownMatrixReport } from '../../../services/actions/dashboardAcions';
import map from 'lodash/map';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const styleTd = {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
   }
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F5F5F5',
       padding: '8px 16px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        padding: '8px 16px'
    },
    [`&.${tableCellClasses.footer}`]: {
        backgroundColor: '#F5F5F5',
        color: '#000000',
        fontSize: 16,
        padding: '8px 16px'
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: '#e8eff7',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export const SFTable = (props) => {
    const generateHeaders = (cols) => {
        return map(cols, function (colData) {
            let colomHeaderLabel = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(colData.label) ? getLocalizedStrings().label.DASHBOARD[colData.label] : colData.label;
            if (!colData.is_hidden) {
                return <th key={colData.name} style={styles.styleThead}> {/*colData.label*/}{colomHeaderLabel} </th>;
            }
        });
    }

    const generateRows = (cols, data, widgetId) => {
        return map(data, function (item) {
            let cells = cols.map((colData) => {
                if (!colData.is_hidden) {
                    if (colData.is_link) {
                        if (item[colData.name].length > 9) {
                            return (
                                <StyledTableCell style={styleTd}>
                                    <Link
                                        data={JSON.stringify(item)}
                                        columnValue={item[colData.name]}
                                        widgetId={widgetId}
                                        status={colData.name}
                                    />
                                </StyledTableCell>
                            );

                        } else {
                            return (
                                <StyledTableCell>
                                    <Link
                                        data={JSON.stringify(item)}
                                        columnValue={item[colData.name]}
                                        widgetId={widgetId}
                                        status={colData.name}
                                    />
                                </StyledTableCell>
                            );
                        }
                    } else {
                        return <StyledTableCell>{item[colData.name]}</StyledTableCell>;
                    }
                }
            });
            return <StyledTableRow key={item.id}>{cells}</StyledTableRow>;
        });

    }

    let headerComponents = generateHeaders(props.cols);
    let rowComponents = generateRows(props.cols, props.data, props.widgetId);
    let header = props.tableHeader;

    if ((props?.data && props.data?.length > 0)) {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ maxWidth: 800 }} aria-label="customized table">
                    <TableHead align="left">
                        {headerComponents}
                    </TableHead>
                    <TableBody>
                        {rowComponents}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {

        let msgForNoData = getLocalizedStrings().message.CHARTS.NOTHING_TO_SHOW;
        if (header === "Today's Appointments") {
            msgForNoData = getLocalizedStrings().message.APPOINTMENTS_TASKS.NO_APPOINTMENT_FOR_TODAY;
        } else if (header === "Today's Tasks") {
            msgForNoData = getLocalizedStrings().message.APPOINTMENTS_TASKS.NO_PENDING_TASK_FOR_TODAY;
        }
        return (
            <TableContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <StyledTableRow>
                    <StyledTableCell>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{color: '#868686', fontSize: '16px'}}>
                                {msgForNoData} 
                            </span>
                        </div>
                    </StyledTableCell>
                </StyledTableRow>
            </TableContainer>
        );
    }
}


class Link extends React.Component {
    constructor(props) {
        super(props);
        this.onItemClick = this.onItemClick.bind(this);
    }
    onItemClick() {
        drillDownMatrixReport(this.props.data, this.props.columnValue, this.props.widgetId, this.props.status);
    }
    render() {
        return (
            <a href="/#" onClick={this.onItemClick} title={this.props.columnValue}> {this.props.columnValue}</a>
        );
    }
}

SFTable.propTypes = {
    generateHeaders: PropTypes.func,
    generateRows: PropTypes.func
};