import React from "react";
import * as sfDialogs from "../components/sfDialogs";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import {
    getNoteTypeList,
    updateNoteTypeListAll,
    updateSelectedNoteTypeList
} from "../../../services/actions/noteActions";
import {
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isValidParam,
} from "../../../services/helper/parameterVerifier";
import { styles } from "../../../services/constants/styles";
import ShowCircularProgress from "../components/circularProgress";
import { constants, } from "../../../services/constants/constants";
import { deleteAction } from "../../../services/actions/batchActions";
import NoRecords from "../components/noRecord";
import { getAppCustomDrawer, getAppDialog, getAppDrawer } from "../../../services/actions/appContainerActions";
import Pagination from "../components/pagination";
import { getListViewData } from "../../../services/actions/listViewAcions";
import PopOver from "../components/PopOver";
import { hasAccessPermission } from "../../../services/helper/common";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { Icon } from '@mui/material';
import { getDetailViewPinnedNote, refreshQueueDetailViewNote, refreshDetailViewData } from "../../../services/actions/detailViewActions";
import { updateChildNoteFunc, updatePinnedNotesSelectedField, updateisAllSelected } from "../../../services/actions/noteActions";
import Cookies from "universal-cookie";
import { sortArrayObjectByProperty } from "../../../services/helper/utils";
import { getActiveTabInfo, updateTabInfo } from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getEmailPromise } from "../../../services/actions/emailActions";
import { LinkToType } from "../../../services/constants/link";
import NoteBody, { NotesFilterPromptMessage } from "../components/noteBody";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowDropDown } from "@mui/icons-material";
import { connect } from "react-redux";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SFCheckbox from '../../sfPlugins/sfCheckBox';

class DetailViewChildNote extends React.Component {
    constructor(props) {
        super(props);
        this.cookies = new Cookies();
        this.state = {
            detailViewParams: {
                parent_object: '',
                parent_record_id: '',
            },
            typesListValues: [],
            selectedNotesTypes: [],
            moreActionOpen: false,
            noteTypeAuthorsList:
                [{ "name": "All", value: getLocalizedStrings().label.NOTE.ALL_NOTES },
                { "name": "Starred", value: "Starred" },
                { "name": "User", value: getLocalizedStrings().label.COMMON.USER },
                { "name": "System", value: getLocalizedStrings().label.COMMON.SYSTEM },
                { "name": "My", value: getLocalizedStrings().label.COMMON.MY },
                { "name": "Archived", value: "Archived" }
                ],

            selectedNoteAuthor: {},
            mounted: false,
            searchText: "",
            isOpenSearchedList: false,
            searchedList: [],
            isOpenSearchField: false,
            localParam: null,
            notesFilterPromptElement: null,
            open: false,
            isWork: false,
            isAllSelected: false,
        }
    }
    componentDidMount() {
        this.getNoteTypeValues();
        this.getNoteTypes();
        this.props.updateisAllSelected(false);
    }

    componentDidUpdate() {
        if (!this.state.isWork) {
            try {
                let searchTextField = document.getElementById('searchText');
                if (isValidParam(searchTextField)) {
                    setTimeout(function () { searchTextField.focus() }, 100);
                }
            } catch (error) {
                console.log("Error in 'DetailViewChildNote.js -> componentDidUpdate()':" + error);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let tabInfo = getActiveTabInfo();
        let isCallfromQueue = false;
        if (tabInfo.hasOwnProperty('is_callFrom_queue') && getBooleanParam(tabInfo.is_callFrom_queue)) {
            isCallfromQueue = true;
        }

        if ((getIntParam(this.state.detailViewParams.parent_record_id) && nextProps.parentRecordId !== this.state.detailViewParams.parent_record_id && !isCallfromQueue)
            || (nextProps.detailView.isQueueNoteRefresh !== this.props.detailView.isQueueNoteRefresh && getBooleanParam(nextProps.detailView.isQueueNoteRefresh))) {
            this.props.refreshQueueDetailViewNote(false);
            this.populateSearchField();
            this.populateSelectedNoteTypeValues();
            this.populateSearchedItems(nextProps);
        }
    }
    componentWillUnmount() {
        this.props.listView.mounted = false;
    }
    handleNoteAddClick = () => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        let hasPermission = false;
        let labelName = getLocalizedStrings().label.COMMON.ADD_NOTES;
        let value = "new";
        let data = {
            "object": constants.NOTE_OBJECT,
            isDetailView: true,
            mode: constants.SF_FORM_OPEN_MODE_QUICK
        }
        if (getBooleanParam(this.props.isQueueView)) {
            data.isQueueView = getBooleanParam(this.props.isQueueView);
            data.linkInfo = this.props.linkInfo;
        }
        if (isValidParam(this.props.appContainer.customDrawerProps.minimizeList.new) && this.props.appContainer.customDrawerProps.minimizeList.new  && this.props.appContainer.customDrawerProps.isMinimize) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Note drawer is already opened.', null)

        } else {
            hasPermission = hasAccessPermission(constants.NOTES, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
            if ((hasPermission && !isInXpress && (!isUnitsAccount || this.props.app.me.is_franchisee)) || (hasPermission && tabInfo.isUnitsAccount && tabInfo.callFrom === "mini_edit" && this.props.app.me.is_franchisee)) {
                data = { ...data, ...this.state.detailViewParams }
                    ;
                if (!this.props.appContainer.customDrawerProps.isOpen && !this.props.appContainer.customDrawerProps.isMinimize) {
                    this.props.getAppCustomDrawer(true, labelName, value, { width: '83%' }, data, null, true);
                } else {
                    this.props.getAppDrawer(true, labelName, value, data, constants.LIST_VIEW);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
            }
        }
    }

    populateSelectedNoteTypeValues = () => {
        let noteParams = this.generateNoteParams();
        this.state.detailViewParams = { ...noteParams };
        let searchText = "";
        let tabInfo = getActiveTabInfo();
        if (isValidParam(tabInfo)) {
            if (tabInfo.hasOwnProperty('note_search') && isValidParam(tabInfo.note_search)) {
                searchText = tabInfo.note_search;
            }
        }
        this.props.getListViewData(constants.NOTES, { ...noteParams });

        if (searchText.trim() != "") {
            this.createSearch(searchText.trim(), this.state.localParam);
            delete tabInfo.note_search;
            updateTabInfo(tabInfo)
            this.setState({searchText:''})
        }
        getDetailViewPinnedNote();
    }
    createSearch = (search_text, localParam) => {
        search_text = search_text.trim();
        let searchArray = []
        let searchFieldName = null;
        let searchText = null;

        let tabInfo = getActiveTabInfo();
        if (isValidParam(tabInfo)) {
            let searchText = search_text;
            tabInfo['note_search'] = searchText;
            if (searchText == "") {
                delete tabInfo.note_search;
            }
            updateTabInfo(tabInfo);
        }
        try {
            if (search_text.includes(":")) {
                let arrSearchText = search_text.split(':');
                if (arrSearchText[0].trim().toLowerCase() == 'type' || arrSearchText[0].trim().toLowerCase() == 'text' ||
                    arrSearchText[0].trim().toLowerCase() == 'author') {
                    searchFieldName = arrSearchText[0].trim().toLowerCase();
                    searchText = arrSearchText[1].trim();

                    if ((arrSearchText[0].trim().toLowerCase() === 'type' || arrSearchText[0].trim().toLowerCase() === 'author') &&
                        searchText.includes(',')) {
                        let arraySearchText = searchText.split(',');
                        for (var i = 0; i < arraySearchText.length; i++) {
                            if (searchFieldName === "type") {
                                searchFieldName = 'note_type';
                            }
                            if (searchFieldName === "text") {
                                searchFieldName = 'note_text';
                            }
                            let searchObject = {}
                            searchObject[searchFieldName] = arraySearchText[i].trim();
                            searchArray.push(searchObject);
                        }
                    } else {
                        if (searchFieldName === "type") {
                            searchFieldName = 'note_type';
                        }
                        if (searchFieldName === "text") {
                            searchFieldName = 'note_text';
                        }
                        let searchObject = {}
                        searchObject[searchFieldName] = searchText;
                        searchArray.push(searchObject);
                    }
                } else {
                    searchArray = this.populateSearchArray(search_text);
                }
            } else {
                searchArray = this.populateSearchArray(search_text);
            }
            let noteParams = this.generateNoteParams();
            if (isValidParam(noteParams)) {
                if (noteParams.hasOwnProperty('search_field_name') && noteParams.hasOwnProperty('search_text')) {
                    let noteParamSearchFieldName = noteParams.search_field_name;
                    let noteParamSearchText = noteParams.search_text;
                    let filterSearchArray = []
                    let filterSearchObject = {}
                    filterSearchObject[noteParamSearchFieldName] = noteParamSearchText;
                    filterSearchArray.push(filterSearchObject);
                    noteParams['filter_search'] = filterSearchArray;
                    noteParams.search = searchArray;
                    delete noteParams.search_field_name;
                    delete noteParams.search_text;
                } else if (noteParams.hasOwnProperty('search')) {
                    if (searchArray.length > 0) {
                        noteParams['filter_search'] = noteParams['search'];
                        noteParams.search = searchArray;
                    }
                } else {
                    noteParams.search = searchArray;
                }
            }
            noteParams.search_type = constants.SEARCH_TYPE_ANYWHERE;
            this.state.detailViewParams = { ...noteParams };
            if (isValidParam(localParam)) {
                if (localParam.hasOwnProperty('group_by_condition') && isValidParam(localParam.group_by_condition)) {
                    noteParams['group_by_condition'] = localParam['group_by_condition'];
                }
                if (localParam.hasOwnProperty('group_by_field_name') && isValidParam(localParam.group_by_field_name)) {
                    noteParams['group_by_field_name'] = localParam['group_by_field_name'];
                }
                if (localParam.hasOwnProperty('is_list_view_update') && isValidParam(localParam.is_list_view_update)) {
                    noteParams['is_list_view_update'] = localParam['is_list_view_update'];
                }
                if (localParam.hasOwnProperty('page_size') && isValidParam(localParam.page_size)) {
                    noteParams['page_size'] = localParam['page_size'];
                }
                if (localParam.hasOwnProperty('start_index') && isValidParam(localParam.start_index)) {
                    noteParams['start_index'] = localParam['start_index'];
                }
                this.state.localParam = { ...localParam }
            }
            this.props.getListViewData(constants.NOTES, { ...noteParams });

            if (search_text != null && search_text != undefined && search_text != "") {
                search_text = search_text.trim();
                let parentObject = this.props.parentObject
                let cookiesName = this.props.app.me.id + '_' + parentObject + '_' + 'note_search_items';
                let arraySearchItems = []
                arraySearchItems = this.cookies.get(cookiesName);
                if (!isValidParam(arraySearchItems)) {
                    arraySearchItems = []
                }
                let isArrayContains = (arraySearchItems.indexOf(search_text) > -1);
                if (isArrayContains) {
                    arraySearchItems.splice(arraySearchItems.indexOf(search_text), 1);
                }
                arraySearchItems.push(search_text);
                if (arraySearchItems.length > 5) {
                    arraySearchItems.splice(0, 1);
                }
                this.cookies.set(cookiesName, arraySearchItems, { path: "/" });
                this.populateSearchedItems(this.props);
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> createSearch()':" + error);
        }
    }
    generateNoteParams = () => {

        let parentObject = this.props.parentObject;
        let cookiesName = this.props.app.me.id + '_' + 'note';
        let selectedNotetype = this.cookies.get(cookiesName) || 'All';
        let parentRecordId = this.props.parentRecordId;
        let selectedNotesTypes = ['All'];
        if (selectedNotetype == "Starred Notes") {
            selectedNotetype = "Starred";
        }
        let params = {
            parent_object: parentObject,
            note_types: selectedNotesTypes,
            parent_record_id: parentRecordId,
            sort_field_name: "note_date",
            sort_type: "desc"
        }
        let searchParam = {};
        if (selectedNotetype == "All") {

        } else if (selectedNotetype == "User") {
            let searchParamList = [];
            let userList = this.props.userlist.data
            for (let i = 0; i < userList.length; i++) {
                let searchParam = { "author": userList[i].text };
                searchParamList.push(searchParam);
            }
            searchParam["search"] = searchParamList;
            searchParam["is_archive"] = false;

        } else if (selectedNotetype == "System") {
            searchParam = { "search_field_name": "author", "search_text": "System", 'is_archive': false };
        } else if (selectedNotetype == "My") {
            searchParam = { "search_field_name": "author", "search_text": this.props.app.me.name, 'is_archive': false };
        } else if (selectedNotetype == "Starred") {
            searchParam = { "search_field_name": "t_important_note", "search_text": "Yes", 'is_archive': false };
        } else if (selectedNotetype == "Archived") {
            searchParam = { 'is_archive': true }
        }
        return { ...params, ...searchParam };
    }
    openSearchField = () => {
        try {
            if (this.state.searchText == null || this.state.searchText == undefined || this.state.searchText == "") {
                if (this.state.isOpenSearchField && this.state.searchText == "") {
                    this.setState({
                        isOpenSearchField: false,
                    })
                } else if (this.state.isOpenSearchField == false && this.state.searchText == "") {
                    this.setState({
                        isOpenSearchField: !this.state.isOpenSearchField,
                    })
                    this.createSearch(this.state.searchText, this.state.localParam);
                }

            } else {
                this.createSearch(this.state.searchText, this.state.localParam);

            }
            // }
            if (this.props.object == 'notes') {
                let _info = getActiveTabInfo();
                if (_info.selectedPageNo > 1) {
                    _info.selectedPageNo = 1;
                    updateTabInfo(_info);
                }   }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> openSearchField()':" + error);
        }
    }
    closeSearchField = () => {
        try {
            if (this.state.searchText == null || this.state.searchText == undefined || this.state.searchText == "") {
                this.setState({
                    isOpenSearchField: false,
                });
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> openSearchField()':" + error);
        }
    }
    openSearchedList = (event) => {
        event.preventDefault();
        this.setState({
            isOpenSearchedList: true,
            anchorSearchedList: event.currentTarget,
        });

    }
    handleMoreActionOpen = (event) => {
        event.preventDefault();
        this.setState({ moreActionOpen: true, anchorMoreActionEl: event.currentTarget });
    }
    handleMoreActionClose = (event) => {
        this.setState({
            moreActionOpen: false
        });
    }
    handleMoreActionChange = (value) => {
        try {
            let tempObject = {};
            let noteTypesList = this.state.noteTypeAuthorsList;
            for (let i = 0; i < noteTypesList.length; i++) {
                if (noteTypesList[i].value == value) {
                    value = noteTypesList[i].name;
                    tempObject["selectedNoteAuthor"] = noteTypesList[i];
                }
            }
            this.setState({
                moreActionOpen: false,
                ...tempObject
            });
            let parentObject = this.props.parentObject
            let cookiesName = this.props.app.me.id + '_' + 'note';
            this.cookies.set(cookiesName, value, { path: "/" });

            let tabInfo = getActiveTabInfo();

            if (isValidParam(tabInfo)) {
                delete tabInfo.selectedPageNo;
                delete tabInfo.startPageNo;
                delete tabInfo.startIndex;
                updateTabInfo(tabInfo);
            }

            if (value == "All") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    this.getListView();
                }
            } else if (value == "User") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    let searchParam = {};
                    let searchParamList = [];
                    let userList = this.props.userlist.data
                    for (let i = 0; i < userList.length; i++) {
                        let searchParam = { "author": userList[i].text };
                        searchParamList.push(searchParam);
                    }
                    searchParam["search"] = searchParamList;
                    searchParam["is_archive"] = false;
                    this.getListView(searchParam);
                }
            } else if (value == "System") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    this.getListView({ "search_field_name": "author", "search_text": "System", 'is_archive': false });
                }
            } else if (value == "My") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    this.getListView({ "search_field_name": "author", "search_text": this.props.app.me.name, 'is_archive': false });
                }
            } else if (value == "Starred") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    this.getListView({ "search_field_name": "t_important_note", "search_text": "Yes", 'is_archive': false });
                }
            } else if (value == "Archived") {
                if (this.state.searchText != null && this.state.searchText != undefined && this.state.searchText != "") {
                    this.createSearch(this.state.searchText, this.state.localParam);

                } else {
                    this.getListView({ 'is_archive': true });
                }; // defined param for archived
            }
            this.props.updateisAllSelected(false)
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> handleMoreActionChange()':" + error);
        }
    }
    handleCloseSearchedList = () => {
        this.setState({
            isOpenSearchedList: false,
        })
    }
    changeSearchValue = (value) => {
        try {
            if (isValidParam(value)) {
                this.setState({
                    searchText: value,
                    isOpenSearchedList: false,
                })
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> changeSearchValue()':" + error);
        }
    }

    openEmail = (noteData, isPinned) => {
        try {
            let params = { parent_object: this.props.object, parent_record_id: noteData.id };
            let promise = getEmailPromise(params);
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let fields = getArrayParam(response.fields);
                        let records = getArrayParam(response.records);
                        let record = records.length > 0 ? records[0] : null;

                        if (isValidParam(record)) {
                            let subject = getStringParam(record.t_subject);
                            let label = subject.length > 55 ? subject.substring(0, 55) + '...' : subject;
                            let type = constants.EMAIL_DETAILS;
                            let data = {}
                            data.parentObject = getStringParam(this.props.parentObject);
                            data.parentRecordId = getIntParam(this.props.parentRecordId);
                            data.record = record;
                            data.getListView = this.getListView;
                            data.noteData = noteData
                            data.selectedNoteAuthor = this.state.selectedNoteAuthor.value
                            data.isPinned = isPinned
                            this.props.getAppDrawer(true, label, type, data, this.props.object);
                        }
                    }
                });
            }

        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> openEmail()':" + error);
        }
    }
    sendEmail = (actionType, id) => {
        try {
            id = getIntParam(id);
            let minimizeList = this.props.appContainer.customDrawerProps.minimizeList

             if ( (actionType == 'REPLY' && actionType == "REPLY-ALL") && minimizeList.hasOwnProperty('email') || (minimizeList.hasOwnProperty('email') && minimizeList.email)) {
                this.props.showCustomSnackBar("Send mail popup is already opened for different record", styles.snackbarBodyStyleError, styles.snackBarStyleTop);
    
            }
           else if ( (actionType == 'REPLY' || actionType == "REPLY-ALL" || actionType == "FORWARD")  && (minimizeList.hasOwnProperty('new') && minimizeList.new)) {
                this.props.showCustomSnackBar("Note drawer is already opened", styles.snackbarBodyStyleError, styles.snackBarStyleTop);
    
            }
            else{
                if (id > 0) {
                    let params = { parent_object: this.props.object, parent_record_id: id };
                    let promise = getEmailPromise(params);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            if (isValidParam(response)) {
                                let fields = getArrayParam(response.fields);
                                let records = getArrayParam(response.records);
                                let record = records.length > 0 ? records[0] : null;
    
                                if (isValidParam(record)) {
                                    let label = getLocalizedStrings().label.MAIL.SEND_MAIL;
                                    let type = LinkToType.TYPE_EMAIL;
    
                                    let data = {}
                                    data.parentObject = getStringParam(this.props.parentObject);
                                    data.parentRecordId = getIntParam(this.props.parentRecordId);
                                    data.type = constants.EMAIL_OBJECT;
                                    data.actionType = getStringParam(actionType);
                                    data.record = record;
                                    this.props.getAppDrawer(true, label, type, data, null);
                                }
                            }
                        });
                    }
                }
            }
          
        } catch (error) {
            console.error("Error in 'detailViewChildNote.js -> sendEmail()':" + error);
        }
    }
    getNoteTypes = () => {
        let data = this.props.note.allNoteTypes;
        if (data.length == 0) {
            this.getFormData();
        } else {
            this.processNoteTypes(data);
        }
    }
    getFormData = () => {
        try {
            if (this.props.note.allNoteTypes.length == 0) {
                let url = endPoints.NOTES.NOTE_TYPE_GET_ALL;
                var promise = Promise.resolve(HTTPClient.get(url, null));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let noteTypes = response.noteTypes
                        sortArrayObjectByProperty(noteTypes, 'note_type');
                        this.props.updateNoteTypeListAll(noteTypes);
                        this.processNoteTypes(noteTypes);
                    }
                });
            } else {
                this.processNoteTypes(this.props.note.allNoteTypes);
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> getFormData()':" + error);
        }
    }
    processNoteTypes = (data) => {
        let tempData = data.filter(f => { return f.is_active == true });
        let allObject = {
            "value": "All Types",
            "id": -999
        }
        let typesList = [];
        tempData.forEach(f => {
            let tempObj = {}
            tempObj.value = f.note_type;
            tempObj.id = f.note_type;
            typesList.push(tempObj);
        })
        let indx = typesList.findIndex((obj) => obj.id == -999)
        if (indx == -1) {
            typesList.unshift(allObject)
        }
        this.setState({ typesListValues: typesList }, () => this.getSelectedNoteTypesValue());
    }
    getSelectedNoteTypesValue = () => {
        if (this.props.note.selectedNoteTypes.length > 0) {
            this.setNoteTypeSelectValue(this.props.note.selectedNoteTypes, 'gateValue')
        } else {
            let url = endPoints.NOTES.SELECTED_NOTE_TYPE_GET;
            let parentObject = this.props.parentObject;
            let typesListValues = getArrayParam(this.state.typesListValues);
            let params = {}
            params.parent_object = parentObject;
            var response = Promise.resolve(HTTPClient.get(url, params));
            response.then((response) => {
                if (response.selectedNoteType.length > 0) {
                    let tempNoteType = response.selectedNoteType;
                    response.selectedNoteType.forEach((item, index) => {
                        let valueIndex = typesListValues.findIndex((f) => f.value == item);
                        if (valueIndex == -1) {
                            tempNoteType.splice(index, 1);
                        }
                    })
                    if (tempNoteType.length == 0) {
                        tempNoteType = ['All Types']
                    }
                    this.props.updateSelectedNoteTypeList(tempNoteType);
                    this.setNoteTypeSelectValue(tempNoteType, 'gateValue')
                }
            })
        }
    }
    setNoteTypeSelectValue = (value, callFrom) => {
        let _state = this.state;
        try {
            let allIndx = value.findIndex((f) => f == 'All');
            if (allIndx > -1) {
                value.splice(allIndx, 1);
                value.unshift('All Types');
            }
            let selectedNotesTypes = getArrayParam(this.state.selectedNotesTypes);
            let typesListValues = getArrayParam(this.state.typesListValues);
            let finalTypesListValues = typesListValues.filter(f => { return f.id != '-999' });
            let indx = value.findIndex((f) => f == 'All Types');
            let valueIndex = selectedNotesTypes.findIndex((f) => f == 'All Types');
            if (indx > -1 && valueIndex == -1) {
                value = typesListValues.map((user) => {
                    return user.value;
                });
                let val = value.findIndex((f) => f == 'All Types');
                if (val == -1) {
                    value.unshift('All Types')
                }
            }
            else if (indx == -1 && valueIndex > -1) {
                value = [];
            }
            else if (indx > -1 && value.length != typesListValues.length) {
                value.splice(indx, 1);
            }
            else if (indx == -1 && value.length == finalTypesListValues.length) {
                value.unshift('All Types');
            }
            _state.selectedNotesTypes = value;
            this.setState(_state, () => this.processNoteTypesData(callFrom));
        }
        catch (error) {
            console.error("Error in 'detailViewChildNote.js -> setNoteTypeSelectValue()':" + error);
        }
    }

    processNoteTypesData = (callFrom) => {
        if (callFrom == 'gateValue') {
            this.populateSearchField();
            let parentObject = this.props.parentObject;
            let cookiesName = this.props.app.me.id + '_' + 'note';
            let selectedNoteAuthor = this.cookies.get(cookiesName) || 'All';
            if (selectedNoteAuthor == "Starred Notes") {
                selectedNoteAuthor = "Starred";
            }
            let noteTypeAuthorsList = this.state.noteTypeAuthorsList;
            for (let i = 0; i < noteTypeAuthorsList.length; i++) {
                if (noteTypeAuthorsList[i].name == selectedNoteAuthor) {
                    this.setState({ selectedNoteAuthor: noteTypeAuthorsList[i] });
                    this.populateSelectedNoteTypeValues();
                }
            }
            this.populateSearchedItems(this.props);
            let notesFilterPromptElement1 = this.getNotesFilterPromptElement(selectedNoteAuthor);
            this.setState({ notesFilterPromptElement: notesFilterPromptElement1 })

        }

    }
    processOnBlurActin = () => {
        this.populateSelectedNoteTypeValues()
    }
    getNotesFilterPromptElement = (selectedNoteAuthor) => {
        let notesFilterPromptMsg = null;
        let layoutMsg = "";
        if (isValidParam(selectedNoteAuthor)) {
            if (selectedNoteAuthor != 'All' && selectedNoteAuthor != '') {
                layoutMsg = "You are seeing " + selectedNoteAuthor + " notes. Select All to see all notes";
            }
        }
        if (!this.state.isOpenSearchField && selectedNoteAuthor != 'All' && selectedNoteAuthor != '') {
            notesFilterPromptMsg = <NotesFilterPromptMessage notesFilterPromptMsg={layoutMsg} />
        }
        return notesFilterPromptMsg
    }
    populateSearchedItems = (props) => {
        try {
            let parentObject = props.parentObject
            let cookiesName = props.app.me.id + '_' + parentObject + '_' + 'note_search_items';
            let arraySearchItems = []
            arraySearchItems = this.cookies.get(cookiesName);
            if (isValidParam(arraySearchItems)) {
                arraySearchItems.reverse();
                let searchedList = []
                arraySearchItems.map((objSearchItem, i) => {
                    let data = {}
                    data.label = objSearchItem;
                    data.value = objSearchItem;
                    searchedList.push(data);
                });
                this.setState({
                    searchedList: searchedList,
                })
            } else {
                this.setState({
                    searchedList: [],
                })
            }
            if (props.parentRecordId != this.state.detailViewParams.parent_record_id) {
                this.setState({
                    searchText: "",
                })
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> populateSearchedItems()':" + error);
        }
    }
    populateSearchField = () => {
        try {
            let activeTab = getActiveTabInfo();
            if (isValidParam(activeTab)) {
                if (activeTab.hasOwnProperty('note_search') && isValidParam(activeTab.note_search)) {
                    this.setState({
                        searchText: activeTab.note_search,
                        isOpenSearchField: true,
                    })
                }
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> populateSearchField()':" + error);
        }
    }
    populateSearchArray = (search_text) => {
        let searchArray = []
        try {
            let searchObject = {}
            searchObject['note_type'] = search_text.trim();
            searchArray.push(searchObject);
            searchObject = null;
            searchObject = {}
            searchObject['note_text'] = search_text.trim();
            searchArray.push(searchObject);
            searchObject = null;
            searchObject = {}
            searchObject['author'] = search_text.trim();
            searchArray.push(searchObject);
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> changeSearchText()':" + error);
        }
        return searchArray;
    }
    changeSearchText = (event, value) => {
        try {
            value = event.target.value;
            let tabInfo = getActiveTabInfo();
            if (isValidParam(tabInfo) && tabInfo.hasOwnProperty('note_search') && value == "") {
                delete tabInfo.note_search;
                updateTabInfo(tabInfo);
            }
            this.setState({
                searchText: value,
            });
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> changeSearchText()':" + error);
        }
    }
    getNoteTypeValues = () => {
        if (this.props.note.noteNameTypeMap != undefined && Object.keys(this.props.note.noteNameTypeMap).length == 0) {
            this.props.getNoteTypeList();
        }
    }
    handleKeyUp = (event) => {
        try {
            let search_text = event.target.value.trim();
            if (event.keyCode == 13) {
                this.createSearch(search_text, this.state.localParam);
            }
        } catch (error) {
            console.log("Error in 'DetailViewChildNote.js -> handleKeyUp()':" + error);
        }
    }
    handelDelete = (id, isPinned) => {
        this.state.isWork = true;
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if (!isInXpress && !isUnitsAccount) {
            var recordIds = []
            var objectName = constants.NOTES;
            recordIds.push(id);
            let msg = "";
            if (id > 0) {
                msg = "1 " + getLocalizedStrings().message.BATCH_ACTION.RECORD + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_REMOVE_MSG;

            }
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => this.handelDeleteOnConfirm(this, recordIds, objectName, isPinned), null);
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
        }
    }
    handelDeleteOnConfirm = (event, recordIds, objectName, isPinned) => {
        let param = {
            "id": recordIds
        }
        param['parent_object'] = this.props.parentObject;
        param["parent_record_id"] = this.props.detailView.record.id;
        param["isUnlink"] = true;
        param["isPinned"] = getBooleanParam(isPinned);
        var response = deleteAction(objectName, param);
        response.then((response) => {
            if (response.status == 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);

                let info = getActiveTabInfo();
                if (info.selectedPageNo > 1) {
                    info.selectedPageNo = 1;
                    updateTabInfo(info);
                    this.state.isWork = false;
                }
                this.getListView();
            }
        });
    }
    handleEdit = (id, isPinnedNote, starredNote) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        let objectName = constants.NOTE_OBJECT;
        let labelName = getLocalizedStrings().label.COMMON.EDIT_NOTES;
        let value = "new";
       let minimizeList= this.props.appContainer.customDrawerProps.minimizeList;
        if ((!isInXpress && !isUnitsAccount) || (tabInfo.isUnitsAccount && tabInfo.callFrom === "mini_edit" && this.props.app.me.is_franchisee)) {
            let data = {
                "object": objectName,
                isDetailView: true,
                id: id,
                mode: constants.SF_FORM_OPEN_MODE_QUICK,
                isPinnedNote: getBooleanParam(isPinnedNote),
                isImportantNote: starredNote == 'Yes' ? true : false
            }

            data = { ...data, ...this.state.detailViewParams }
            if((minimizeList.hasOwnProperty('agreement') && minimizeList.agreement)){
                //bypass
            }else{
                this.props.getAppCustomDrawer(true, labelName, value, { width: '83%' }, data, null, true);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
        }
    }
    handleNoteAttachmentDelete = (id, name, attachmentlength) => {
        let params = {};
        params['file_name'] = name;
        params['id'] = id;
        if (attachmentlength > 1) {
            params['isMultiple'] = true;
            return
        }
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.ATTACHMENT.THE_ATTACHMENT + " " + name + " " + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG, () => this.handleNoteAttachmentDeleteOnConfirm(this, params), null);
    }
    handleNoteAttachmentDeleteOnConfirm = (event, params) => {
        let promise = Promise.resolve(HTTPClient.post(endPoints.ATTACHMENT.NOTE_ATTACHMENT_DELETE, params));
        promise.then((response) => {
            this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
            this.getListView();
        });
    }
    handleNoteAttachemtUpload = (param, isPinned, event) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if ((!isInXpress && !isUnitsAccount) || (isUnitsAccount && tabInfo.callFrom === "mini_edit" && this.props.app.me.is_franchisee)) {
            let detailViewParams = {};
            detailViewParams['parent_object'] = 'notes';
            detailViewParams['parent_record_id'] = param;
            detailViewParams['isPinned'] = getBooleanParam(isPinned);
            let objectName = 'attachments';
            let dialogData = {}
            dialogData.fullWidth = true;
            dialogData.maxWidth = 'md';
            dialogData.callFrom = 'detailviewAttachments';
            this.props.getAppDialog(true, constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, null, { object: objectName, ...detailViewParams, ...dialogData }, null);
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
        }
        return;
    }
    getListView = () => {
        this.populateSelectedNoteTypeValues();
    }
    getLocalListViewData = (object, params) => {
        let localParam = this.generateNoteParams();
        localParam = { ...params, ...localParam }

        let searchText = "";
        let tabInfo = getActiveTabInfo();
        if (isValidParam(tabInfo)) {
            if (tabInfo.hasOwnProperty('note_search') && isValidParam(tabInfo.note_search)) {
                searchText = tabInfo.note_search;
            }
        }
        if (searchText.trim() != "") {
            this.createSearch(searchText.trim(), localParam);
        } else {
            this.props.getListViewData(object, localParam);
        }
    }
    unescapeHTML = (html) => {
        var escapeEl = document.createElement('textarea');
        escapeEl.innerHTML = html;
        return escapeEl.textContent;
    }
    generateNoteRecords = () => {
        let noteRecords = null;
        let notesData = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : []
        if (isValidParam(notesData)) {
            if (notesData.length != 0) {
                let noteobject = this.props.listView.data.object;
                if (noteobject.toLowerCase() != "notes") {
                    return
                }
                let hasPinnedNote = false;
                if (isValidParam(this.props.detailView.pinnedNote)) {
                    var pinNoteObj = getObjectParam(this.props.detailView.pinnedNote);
                    if (pinNoteObj.hasOwnProperty("hasPinnedNotes") && getBooleanParam(pinNoteObj.hasPinnedNotes)) {
                        hasPinnedNote = true;
                    }
                }
                let tabInfo = getActiveTabInfo();
                let searchText = '';
                if (isValidParam(tabInfo)) {
                    if (tabInfo.hasOwnProperty('note_search') && isValidParam(tabInfo.note_search)) {
                        searchText = tabInfo.note_search;
                    }
                }
                noteRecords = notesData.map((noteRecord, i) => {
                    if (isValidParam(noteRecord)) {
                        let props = {
                            key: "detail-view-note-" + i + "-" + getIntParam(noteRecord.id),
                            noteIndex: i + 1,
                            id: getIntParam(noteRecord.id),
                            data: noteRecord,
                            parentRecordId: this.props.parentRecordId,
                            parentObject: this.props.parentObject,
                            sendEmail: this.sendEmail,
                            handleNoteAttachemtUpload: this.handleNoteAttachemtUpload,
                            handleEdit: this.handleEdit,
                            handelDelete: this.handelDelete,
                            openEmail: this.openEmail,
                            handleNoteAttachmentDelete: this.handleNoteAttachmentDelete,
                            getListView: this.getListView,
                            hasPinnedNote: hasPinnedNote,
                            style: {},
                            headerStyle: {},
                            starredNote: noteRecord.t_important_note,
                            handleArchive: this.handleArchive,
                            selectedNoteAuthor: this.state.selectedNoteAuthor,
                            getLocalListViewData: this.getLocalListViewData,
                        }
                        return <NoteBody {...props} />;
                    }
                });
            } else {
                return !this.props.detailView.pinnedNote?.hasPinnedNotes ? <NoRecords object={constants.NOTES} /> : null;
            }
        }
        return noteRecords;
    }
    getPinnedNoteElement = () => {
        let pinnedNoteElement = null;
        let notesData = null;
        let style = {};
        try {
            style = {
                visibility: 'hidden',
                opacity: 0,
                transition: 'visibility 0s 2s, opacity 2s linear'
            }
            var pinnedNoteObj = getObjectParam(this.props.detailView.pinnedNote);
            notesData = getArrayParam(pinnedNoteObj.pinnedNotes);
            if (isValidParam(notesData) && this.state.selectedNoteAuthor.value !== 'Archived') {
                pinnedNoteElement = []
                notesData.map((note, index) => {
                    let props = {
                        key: "detail-view-note-pinned-" + getIntParam(note.id),
                        noteIndex: index,
                        id: getIntParam(note.id),
                        data: note,
                        parentRecordId: this.props.parentRecordId,
                        parentObject: this.props.parentObject,
                        sendEmail: this.sendEmail,
                        handleNoteAttachemtUpload: this.handleNoteAttachemtUpload,
                        handleEdit: this.handleEdit,
                        handelDelete: this.handelDelete,
                        openEmail: this.openEmail,
                        handleNoteAttachmentDelete: this.handleNoteAttachmentDelete,
                        getListView: this.getListView,
                        style: {},
                        headerStyle: {},
                        isPinned: true,
                        starredNote: note.t_important_note,
                        handleArchive: this.handleArchive,
                        selectedNoteAuthor: this.state.selectedNoteAuthor,
                        getLocalListViewData: this.getLocalListViewData,
                    }
                    pinnedNoteElement.push(<NoteBody {...props} />);

                });
                style = {
                    visibility: 'visible',
                    opacity: 1,
                    transition: 'opacity 2s linear'
                }
            }
        } catch (error) {
            console.error("Error in 'detailViewChildNote.js -> getPinnedNoteElement()':" + error);
        }
        return <div style={style}>{pinnedNoteElement}</div>;
    }

    OnClose = () => {
        this.setState({ open: false }, () => this.handelOnClose());
    }
    handelOnClose = () => {
        if (this.state.selectedNotesTypes.length > 0) {
            updateSelectedNoteTypeList(this.state.selectedNotesTypes);
            this.processNoteTypesData('gateValue');
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'One Note type must be selected at least', () => this.OnOpen());
        }
    }
    OnOpen = () => {
        this.setState({ open: true });
    };
    handleAllForNotes = (event) => {
        let isChecked = event.target.checked;
        this.setState({ isAllSelected: isChecked });
        let notesData = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : []
        notesData = notesData?.map((ele) => {
            ele.isChecked = isChecked;
            return ele;
        })
        this.props.updateisAllSelected(isChecked);
        this.props.updateChildNoteFunc(notesData);

        let PinnedNotesData = isValidParam(this.props.detailView) && isValidParam(this.props.detailView.pinnedNote) ?
            getArrayParam(this.props.detailView.pinnedNote.pinnedNotes) : [];
        PinnedNotesData = PinnedNotesData?.map((ele) => {
            ele.isChecked = isChecked;
            return ele;
        })
        this.props.updatePinnedNotesSelectedField(PinnedNotesData);
        this.forceUpdate();

    }

    handleDeleteForNotes = () => {
        this.state.isWork = true;
        let NoteList = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : [];
        let PinnedNoteList = isValidParam(this.props.detailView) && isValidParam(this.props.detailView.pinnedNote) ?
            getArrayParam(this.props.detailView.pinnedNote.pinnedNotes) : [];
        let _NoteList = [];
        let _pinnedNoteList = [];
        let NotesIds = [];
        NoteList?.map((ele) => {
            if (ele.isChecked) {
                _NoteList.push(ele.id);
            }
        })
        PinnedNoteList.map((ele) => {
            if (ele.isChecked) {
                _pinnedNoteList.push(ele.id);
            }
        })
        NotesIds = [..._NoteList, ..._pinnedNoteList];
        if (NotesIds.length > 0) {
            let params = {};
            params.id = NotesIds;
            params.parent_record_id = this.props.parentRecordId;
            params.parent_object = this.props.parentObject;
            const hasDeletePermission = hasAccessPermission(
                constants.NOTES,
                constants.SECURITY_LEVEL_TYPE_ACCESS,
                constants.ACCESS_TYPE_DELETE
            );
            if (hasDeletePermission) {
                let msg = "";
                msg = getLocalizedStrings().message.COMMON.NOTES_REMOVE_MESSAGE;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => this.handelDeleteOnConfirmFunc(this, params), null);
            }
            else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECTED_NOTE_MESSAGE);
        }
    }

    handelDeleteOnConfirmFunc = (event, params) => {
        const promise = Promise.resolve(HTTPClient.post(endPoints.NOTES.DELETE, params));
        if (promise) {
            promise.then(res => {
                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                let info = getActiveTabInfo();
                if (info.note_search) {
                    updateTabInfo(info);
                    this.state.isWork = false;
                }
                this.props.updateisAllSelected(false);
                this.getListView();
            }
            )
        }
    }

    handleArchiveForAll = () => {
        let NoteList = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : [];
        let PinnedNoteList = isValidParam(this.props.detailView) && isValidParam(this.props.detailView.pinnedNote) ?
            getArrayParam(this.props.detailView.pinnedNote.pinnedNotes) : [];
        let isPinnedNoteArchived = false;
        let NotesIds = [];
        NoteList?.map((ele) => {
            if (ele.isChecked) {
                NotesIds.push(ele.id);
            }
        })

        if (this.state.selectedNoteAuthor.value !== 'Archived') {
            PinnedNoteList.map((ele) => {
                if (ele.isChecked) {
                    isPinnedNoteArchived = true;
                }
            })
        }

        if (NotesIds.length > 0 || isPinnedNoteArchived) {
            if (!isPinnedNoteArchived) {
                this.handleAllArchiveConfirm(NotesIds)
            } else {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ARCHIVE_CONFIRMATION_MESSAGE, () => this.handleAllArchiveConfirm(NotesIds), null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECTED_NOTE_MESSAGE);
        }
    }

    handleAllArchiveConfirm = (NotesIds) => {
        let noteList = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : [];
        let tabInfo = getActiveTabInfo();
        if (NotesIds?.length > 0) {
            let params = {};
            params.id = NotesIds;
            params.is_archive = this.state.selectedNoteAuthor.value === 'Archived' ? false : true;
            const promise = Promise.resolve(HTTPClient.post(endPoints.NOTES.ARCHIVE, params));
            if (promise) {
                promise.then(res => {
                    if (this.state.selectedNoteAuthor.value === 'Archived') {
                        this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UNARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    } else {
                        this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.ARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                    }

                    if(noteList.length === 1){
                        this.getListView()
                        setTimeout(() => {
                            this.props.updateisAllSelected(false);
                        }, 500)
                    }else{
                        let listViewParams = {
                            start_index: tabInfo.startIndex,
                            page_size: tabInfo.pageSize,
                            is_list_view_update: false
                        };
                        this.getLocalListViewData(constants.NOTES, listViewParams)
                        setTimeout(() => {
                            this.props.updateisAllSelected(false);
                        }, 500)
                    }
                })
            }
        } 
    }

    handleArchive = (noteRecord) => {
        let noteList = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
            getArrayParam(this.props.listView.data.records) : [];
        const { id, note_type } = noteRecord;
        let tabInfo = getActiveTabInfo();

        let params = {
            "id": [id],
            "is_archive": this.state.selectedNoteAuthor.value === 'Archived' ? false : true,
            'note_type': note_type,
            "is_email": false
        }
        const promise = Promise.resolve(HTTPClient.post(endPoints.NOTES.ARCHIVE, params));
        if (promise) {
            promise.then(res => {
                if (this.state.selectedNoteAuthor.value === 'Archived') {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.UNARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                } else {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.ARCHIVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                }
                
                if(noteList.length === 1){
                    this.getListView()
                    setTimeout(() => {
                        this.props.updateisAllSelected(false);
                    }, 500)
                }else{
                    let listViewParams = {
                        start_index: tabInfo.startIndex,
                        page_size: tabInfo.pageSize,
                        is_list_view_update: false
                    };
                    this.getLocalListViewData(constants.NOTES, listViewParams)
                    setTimeout(() => {
                        this.props.updateisAllSelected(false);
                    }, 500)
                }
            })
        }
    }

    render() {
        let listViewData = null;
        let totalRecordCount = 0;
        let showPaginetion = false;
        let noteRecords = null;
        let pinnedNoteElement = null;
        let showNoteFilter = true;
        if (this.props.listView.mounted) {

            listViewData = getObjectParam(this.props.listView);
            totalRecordCount = isValidParam(listViewData.data) ? getIntParam(listViewData.data.total_count) : 0;
            showPaginetion = totalRecordCount > 0 ? true : false;
            noteRecords = this.generateNoteRecords();
            pinnedNoteElement = this.getPinnedNoteElement();
            let menuItems = [];
            this.state.noteTypeAuthorsList.map((item, index) => {
                menuItems.push(<MenuItem
                    key={item.name}
                    value={item.value}
                    style={styles.popoverMenuItem} >{item.value}</MenuItem>
                );
            });
            let inputStyle = null;
            let searchRowWidth = '0%';
            if (this.state.isOpenSearchField) {
                inputStyle = { backgroundColor: '#fff', width: '196px', verticalAlign: 'top', paddingLeft: '4px', paddingRight: '4px', border: 'none', height: '23px', outline: '0 none', color: '#888' }
            } else {
                inputStyle = { backgroundColor: '#fff', width: '0px', verticalAlign: 'top', paddingLeft: '4px', paddingRight: '4px', border: 'none', height: '23px', outline: '0 none', color: '#888' }
            }
            if (!this.props.app.menuDrawerIsOpen && !this.state.isOpenSearchField) {
                searchRowWidth = '10%';
            } else if (!this.props.app.menuDrawerIsOpen && this.state.isOpenSearchField) {
                searchRowWidth = '475px';
            } else if (this.props.app.menuDrawerIsOpen && !this.state.isOpenSearchField) {
                searchRowWidth = '12%';
            } else if (this.props.app.menuDrawerIsOpen && this.state.isOpenSearchField) {
                searchRowWidth = '363px';
            }
            let language = getLocalizedStrings()._language;
            let lefCol = 120;
            if (language == 'pt-PT' || language == 'fr-FR') {
                lefCol = 150;
            } else if (language == 'es-ES') {
                lefCol = 125;
            } else if (language == 'de-DE' && this.props.detailView.isDetailView) {
                lefCol = 167;
            } else {
                lefCol = 120;
            }
            let middleCol = 154;
            let rightCol = 390;
            let pageCol = 380;
            if (this.state.isOpenSearchField)
                middleCol = 120;

            if (this.props.app.menuDrawerIsOpen) {
                rightCol = 275;
                pageCol = 340;
                if (language == 'pt-PT') {
                    pageCol = 360;
                } else if (language == 'es-ES') {
                    pageCol = 382;
                } else if (language == 'vi-VN') {
                    pageCol = 358;
                } else {
                    pageCol = 340;
                }
            }

            if (this.props.app.menuDrawerIsOpen && this.state.isOpenSearchField) {
                inputStyle.width = '310px';
            } else if (!this.props.app.menuDrawerIsOpen && this.state.isOpenSearchField) {
                inputStyle.width = '422px';
            }
            if (this.state.isOpenSearchField) {
                rightCol = showNoteFilter ? 'calc(100% -  240px)' : 'calc(100% -  120px)';
                if (language == 'pt-PT' || language == 'fr-FR') {
                    rightCol = 'calc(100% -  270px)';
                } else if (language == 'es-ES') {
                    rightCol = 'calc(100% -  245px)';
                } else if (language == 'de-DE') {
                    rightCol = 'calc(100% -  290px)';
                } else {
                    rightCol = 'calc(100% -  240px)';
                }
                rightCol = showNoteFilter ? rightCol : 'calc(100% -  120px)';
            }

            let showSearchPagination = true;
            let noteHeaderStyle = { display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", alignContent: "center",gap: '5px',overflow: 'auto',height: 50 };
            let noteBodyStyle = {};
            let showHeaderLabel = false;
            let btnWidth = '95%';
            if (getBooleanParam(this.props.isQueueView) && (this.props.parentObject == constants.TASKS || this.props.parentObject == constants.APPOINTMENTS)) {
                showNoteFilter = false;
                showSearchPagination = false;
                noteBodyStyle = { height: '250px', maxHeight: '250px', overflow: 'auto', border: '2px solid #eee', backgroundColor: '#fff' };
                showHeaderLabel = true;
                lefCol = 300;
                btnWidth = this.props.app.me.language === "de-DE" || this.props.app.me.language === "fr-FR" ? '50%' : '19%';
            }
            let labelType = this.state.selectedNoteAuthor.value;
            if (labelType == undefined) {
                labelType = "All Notes";
            }
            let NoteList = isValidParam(this.props.listView) && isValidParam(this.props.listView.data) ?
                getArrayParam(this.props.listView.data.records) : [];
            let PinnedNoteList = isValidParam(this.props.detailView) && isValidParam(this.props.detailView.pinnedNote) ?
                getArrayParam(this.props.detailView.pinnedNote.pinnedNotes) : [];
            let width = { left: 12, right: 12 };
            let emptyButtonOpacity = 1;
            return (

                <div className="actionHome" style={{ paddingTop: '2px', fontSize: '14px' }}>
                    <div style={{...noteHeaderStyle}}>
                        <div style={{ width: '100%', display: "flex", flexWrap: "nowrap", alignItems: "center", justifyContent: "flex-start",gap: '5px' }}>
                            {showHeaderLabel &&
                                <Button
                                    style={{ ...styles.secondaryButton, marginRight:'0px', width: btnWidth, cursor: 'auto', backgroundColor: '#eee' }}
                                >Notes</Button>
                            }
                            {this.state.selectedNoteAuthor.value !== 'Archived' &&
                                <Button
                                    startIcon={<i className="fa fa-plus" style={{ color: '#717171', fontSize: '14px' }}></i>}
                                    style={{ ...styles.secondaryButton, marginRight:'0px', width: '94px' }}
                                    onClick={() => {
                                        this.handleNoteAddClick(this, constants.NEW);
                                    }}
                                >{getLocalizedStrings().label.COMMON.ADD}</Button>
                            }
                            {showNoteFilter && menuItems.length > 0 &&
                                <FormControl variant="filled" sx={{ m: 0, minWidth: 120, '& .MuiSelect-select': { paddingTop: '3px', paddingBottom: '3px' } }}>
                                    <Select
                                        variant="outlined"
                                        id={"user"}
                                        key={"user"}
                                        name={"user"}
                                        value={labelType}
                                        IconComponent={() => (
                                            <ArrowDropDown
                                                style={{
                                                    position: 'absolute',
                                                    top: '2',
                                                    right: '10',
                                                    width: '24px',
                                                    height: '24px',
                                                    pointerEvents: 'none'
                                                }}
                                            />
                                        )}
                                        onChange={(e) => this.handleMoreActionChange(e.target.value)}
                                        sx={{ p: 0 }}
                                    >
                                        {menuItems}
                                    </Select>
                                </FormControl>
                            }


                            <div
                                style={{
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    height: "28px",
                                    borderRadius: "4px",
                                    display: 'flex',
                                    padding: '2px 4px',
                                    alignItems: 'center'
                                }}
                            >
                                <InputBase
                                    value={this.state.searchText}
                                    onKeyUp={this.handleKeyUp}
                                    autoComplete="new-password"
                                    onChange={this.changeSearchText}
                                    placeholder={
                                        getLocalizedStrings().label.COMMON.SEARCH_PLACEHOLDER
                                    }
                                    inputprops={{
                                        "aria-label":
                                            getLocalizedStrings().label.COMMON
                                                .SEARCH_PLACEHOLDER,
                                    }}
                                    style={{
                                        flex: 1,
                                        marginLeft: '0.5rem',
                                        fontSize: 13
                                    }}
                                />
                                <IconButton
                                    type="submit"
                                    aria-label={getLocalizedStrings().label.COMMON.FIND}
                                    title={getLocalizedStrings().label.COMMON.FIND}
                                    onClick={this.openSearchField}
                                >
                                    <SearchIcon />
                                </IconButton>
                                {
                                    (this.state.searchedList.length > 0 && this.state.isOpenSearchField) &&
                                    <PopOver
                                        containerStyle={{ lineHeight: 1 }}
                                        id={'user'}
                                        key={'user'}
                                        name={'user'}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={{ fontSize: '20px', paddingTop: '0px', verticalAlign: 'top', paddingRight: '0px', cursor: 'pointer', color: 'rgb(113, 113, 113)', paddingLeft: '0px', float: 'right' }}
                                        labelposition={"before"}
                                        buttonLabel={""}
                                        options={this.state.searchedList}
                                        onclickAction={this.changeSearchValue}
                                        isShowIcon={true}
                                    />

                                }
                            </div>
                        </div>
                        <div>
                            {
                                (showPaginetion && this.props.listView.data.object.toLowerCase() == "notes") &&
                                <div>
                                    <Pagination
                                        object={this.props.object}
                                        listView={listViewData}
                                        totalRecordCount={totalRecordCount}
                                        isLookup={this.props.isLookup}
                                        listViewCallBack={this.getLocalListViewData}
                                        callFrom='top'
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ ...noteBodyStyle }}>
                        {(NoteList.length > 0) && <div style={{ display: 'flex' }}>
                            <SFCheckbox checked={this.props.note.isAllSelected} onChange={(e) => this.handleAllForNotes(e)} />
                            <div title={this.state.selectedNoteAuthor.value === 'Archived' ? getLocalizedStrings().label.COMMON.UNARCHIVE : getLocalizedStrings().label.COMMON.ARCHIVE}
                                style={{
                                    ...styles.togButton,
                                    backgroundColor: '#ffffff',
                                    height: '24px',
                                    width: '28px',
                                    border: '1px solid rgb(224,224,224)',
                                    marginTop: '5px',
                                    marginLeft: '-3px'
                                }}
                                onClick={this.handleArchiveForAll}>
                                <Icon style={{
                                    fontSize: '16px',
                                    color: '#717171',
                                    cursor: 'pointer',
                                    paddingTop: '4px',
                                    // paddingLeft: '2px',
                                    opacity: emptyButtonOpacity,
                                    width: '25px'
                                }}>{this.state.selectedNoteAuthor.value === 'Archived' ? 'unarchive' : 'archive'}</Icon>
                            </div>
                        </div>}
                        <div>
                            {this.state.notesFilterPromptElement}
                            {pinnedNoteElement}
                            {noteRecords}
                        </div>
                    </div>
                    {showSearchPagination &&
                        <div className="row">
                            <div style={{ width: '100%', float: 'left', paddingTop: '7px'}}>
                                {
                                    (showPaginetion && this.props.listView.data.object.toLowerCase() == "notes") &&

                                    <Pagination
                                        //object={constants.NOTES}
                                        object={this.props.object}
                                        listView={listViewData}
                                        totalRecordCount={totalRecordCount}
                                        isLookup={this.props.isLookup}
                                        //  listViewCallBack={this.props.getListViewData}
                                        listViewCallBack={this.getLocalListViewData}
                                        callFrom='Bottom'
                                    />
                                }
                            </div>
                        </div>}
                </div>
            );
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        note: state.note,
        detailView: state.detailView,
        app: state.app,
        tab: state.tab,
        sfForm: state.sfForm,
        listView: state.childListView,
        userlist: state.userlist,
        appContainer: state.appContainer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNoteTypeList: (projectId, object, recordId) => {
            dispatch(getNoteTypeList(projectId, object, recordId));
        },
        updateSelectedNoteTypeList: (selectedNoteTypes) => {
            dispatch(updateSelectedNoteTypeList(selectedNoteTypes));
        },
        updateNoteTypeListAll: (noteTypes) => {
            dispatch(updateNoteTypeListAll(noteTypes));
        },

        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getListViewData: (object, params) => {
            if (isValidParam(params)) {
                params = { ...params, isDetailView: true }
            }
            dispatch(getListViewData(object, params));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        refreshQueueDetailViewNote: (isRefresh) => {
            dispatch(refreshQueueDetailViewNote(isRefresh));
        },
        updateChildNoteFunc: (notesData) => {
            dispatch(updateChildNoteFunc(notesData));
        },
        updateisAllSelected: (isAllSelected) => {
            dispatch(updateisAllSelected(isAllSelected));
        },
        updatePinnedNotesSelectedField: (PinnedNotesData) => {
            dispatch(updatePinnedNotesSelectedField(PinnedNotesData))
        },
        getAppCustomDrawer: (isOpen, dialogName, actionType, style, data, headerProps, minimizable) => {
            dispatch(getAppCustomDrawer(isOpen, dialogName, actionType, style, data, headerProps, minimizable));
        },
        refreshDetailViewData: (isRefresh) => {
            dispatch(refreshDetailViewData(isRefresh));
        },
    }
};

DetailViewChildNote = connect(mapStateToProps, mapDispatchToProps)(DetailViewChildNote);
export default DetailViewChildNote;