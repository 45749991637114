import { useEffect, memo } from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styles } from '../../../services/constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { endPoints } from '../../../services/constants/endPoints';
import { updateMenuItemCopy, updateMenuItemCancel, updateMenuItemName, createMenuItemName, updateMenuItemSaveName, updateMenuItemDelete, getAllMenuItemsData, resetMenuItem, isMenuNameSaving } from '../../../services/actions/menuItemActions';


const MenuItemSelection = (props) => {
    const { isCopy, data, selectedMenu, menuName, requiredNewMenuId, isSaving } = useSelector((state) => state.appContainer.menuItems);
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const isFirstIndex = data?.findIndex(item => item.name === selectedMenu) === 0;

    useEffect(() => {
        getAllMenuItems();

        return () => {
            dispatch(resetMenuItem());
        }
    }, []);



    const handleCopy = () => {
        dispatch(updateMenuItemCopy());
    }

    const handleCancel = () => {
        dispatch(updateMenuItemCancel());
    }

    const handleMenuChange = (event) => {
        dispatch(updateMenuItemName(event.target.value));
    }

    const handleMenuNameChange = (event) => {
        dispatch(createMenuItemName(event.target.value.trim()));
    }

    const handleSave = async () => {
        if (isSaving) return;
        const duplicateCheck = data.some(item => item.name === menuName);
        const hasSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?0-9]+/.test(menuName);

        if (menuName.trim().length > 20) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU_DESIGN.MENU_NAME_LESS_THAN_20_CHARACTERS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (!menuName.trim()) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU_DESIGN.MENU_NAME_CANNOT_BE_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
        else if (hasSpecialChars) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU_DESIGN.SPECIAL_CHARACTERS_OR_NUMBERS_NOT_ALLOWED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (duplicateCheck) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU_DESIGN.MENU_NAME_ALREADY_EXISTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else {
            try {
                dispatch(isMenuNameSaving(true));
                const response = await HTTPClient.post(endPoints.MENU_DESIGN.SAVE_MENU_ITEMS, { name: menuName, oldmenu_id: requiredNewMenuId,objectList: app.home.modules.map(item => ({homeMenuId:item.homeMenuId,module:item.module,homeId:item.homeId,objectList:item.objectList})) });

                dispatch(updateMenuItemSaveName(response?.data));
                dispatch(showCustomSnackBar(getLocalizedStrings().message.MENU_DESIGN.MENU_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                return response;
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(isMenuNameSaving(false));
            }
        }
    };

    

    const handleDeleteConfirmation = () => {
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, `<b>${selectedMenu}</b> ${getLocalizedStrings().message.BATCH_ACTION.PERMANENT_DELETE_MSG}`, ()=>{handleDelete()});
    }

    const handleDelete = async () => {
        const selectedMenuId = data.find(item => item.name === selectedMenu)?.homeMenuId;
        {
            const response = await HTTPClient.get(endPoints.MENU_DESIGN.DELETE_MENU_ITEMS, { homemenu_id: selectedMenuId });
            const deletedMenuName = data.find(item => item.homeMenuId === selectedMenuId)?.name;
            const names = response?.tNames?.join(', ');

            if (!response.isDeleted) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,getLocalizedStrings().message.MENU_DESIGN.UNABLE_TO_DELETE_MENU(deletedMenuName, names), null);
                return;
            }
        }
        dispatch(updateMenuItemDelete());
        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
    }

    const getAllMenuItems = async () => {
        const response = await HTTPClient.get(endPoints.MENU_DESIGN.GET_MENU_DETAILS, {});

        dispatch(getAllMenuItemsData(response?.allMenus));
    }

    return (
        <Box sx={{ marginLeft: '1.5rem', display: !app.me.is_tenant ? 'none' : 'flex', flexDirection: 'column',gap:'0.5rem' }}>
            <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: '400' }}>{getLocalizedStrings().label.CUSTOMIZATION_SETTING.DESIGN_MENUS}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>

                {!isCopy ? <FormControl sx={{ width: 320 }} size="small">
                    <InputLabel id="demo-select-small-label">{getLocalizedStrings().label.CUSTOMIZATION_SETTING.MENU_NAME}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedMenu}
                        label={getLocalizedStrings().label.CUSTOMIZATION_SETTING.MENU_NAME}
                        defaultValue={''}
                        onChange={handleMenuChange}
                    >
                        {data?.map(({ name, menu_id }) => (
                            <MenuItem value={name} key={menu_id}>{name}</MenuItem>
                        ))}

                    </Select>
                </FormControl> : <TextField
                    sx={{ width: 320 }}
                    label={getLocalizedStrings().label.CUSTOMIZATION_SETTING.MENU_NAME}
                    id="outlined-size-small"
                    defaultValue={menuName}
                    size="small"
                    variant="outlined"
                    onBlur={handleMenuNameChange}
                    autoFocus={true}
                />
                }

                {!isCopy && <Box>
                    <IconButton aria-label="" size="small" sx={{
                        '&:hover': {
                            color: 'black', backgroundColor: 'transparent'
                        },
                    }} title={getLocalizedStrings().label.COMMON.COPY} onClick={handleCopy}>
                        <ContentCopyIcon fontSize='small' />
                    </IconButton >
                    {!isFirstIndex && data.length !== 1 && <IconButton aria-label="" size="small" sx={{
                        '&:hover': {
                            color: 'red', backgroundColor: 'transparent'
                        },
                    }} title={getLocalizedStrings().label.COMMON.DELETE} onClick={handleDeleteConfirmation} >
                        <DeleteIcon fontSize='small' />

                    </IconButton>}
                </Box>
                }
                {isCopy &&
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
                        <Button
                            onClick={handleSave}
                            style={{
                                ...styles.primaryButton,
                                minWidth: '56px',
                                width: '19%'
                            }}
                            title={getLocalizedStrings().label.COMMON.SAVE}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>

                        <Button
                            onClick={handleCancel}
                            style={{
                                ...styles.primaryButton,
                            }}
                            title={getLocalizedStrings().label.COMMON.CANCEL}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </Box>
                }

            </Box>
        </Box>
    );
}


export default memo(MenuItemSelection);
