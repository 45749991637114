import React from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getIntParam, getObjectParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { PivotViewComponent, Inject, PivotChart } from '@syncfusion/ej2-react-pivotview';
import { constants, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { getActiveTabInfo, TYPE_DETAIL_VIEW,addTab} from '../../../services/helper/sfTabManager';
import { reportFormatDate, reportToDate } from '../../../services/helper/common';
import { setHeaderByDispatch } from '../../../services/actions/headerActions';
import ReportFilter from '../components/reportFilter';
import ShowCircularProgress from '../components/circularProgress';
import { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as sfDialogs from "../components/sfDialogs";

const PivotReport =({object,data, widgetId})=>{
    let localizedStrings = getLocalizedStrings();
    let _selectedReport = getObjectParam(getActiveTabInfo()).selectedReport;
    const appState = useSelector((state) => state.app);
    const userlist = useSelector((state) => state.userlist.data);
    const pivotObj = useRef(null);
   
  const [report, setReport] = useState({
                    id: 0,
                    name: null,
                    type: null,
                    dueBy: null,
                    fromDate: null,
                    toDate: null,
                    subType: null
                },);
    const [config, setConfig] = useState({
                    rows: null,
                    columns: null,
                    values: null,
                    showGrandTotals: true,
                    showSubTotals: true,
                    formatSettings: [{}],
                    dataSource: null,
                },);
    const [objectId, setObjectId] = useState(0);
    const [chartType, setChartType] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(()=>{
        if(widgetId > 0 ){
            setConfig(data.config)
            setIsMounted(true);
        }else{
            loadReport();
        }
    },[]);

    useEffect(()=>{
         if(_selectedReport) {
                    let tempSelectedReport = getObjectParam(getActiveTabInfo().selectedReport);
                    _selectedReport = tempSelectedReport;
                    loadReport();
        }
    },[_selectedReport]);

    const loadReport = () => {
        updateState(_selectedReport);
        setHeader();
        getPivotReport(_selectedReport);
    }

    const setHeader = () => {
        setHeaderByDispatch(constants.SALES_OBJECT, _selectedReport.item_name, null, false, true);
    }

    const updateState = (reportParams) => {
        let tempReport = getObjectParam(report);
        try {
            let tempReportParams = getObjectParam(reportParams);
            tempReport.id = tempReportParams.hasOwnProperty("parameter_id") ? getIntParam(tempReportParams.parameter_id) : getIntParam(tempReport.id);
            tempReport.name = tempReportParams.hasOwnProperty("item_name") ? getStringParam(tempReportParams.item_name) : getStringParam(tempReport.name);
            tempReport.type = tempReportParams.hasOwnProperty("item_type") ? getStringParam(tempReportParams.item_type) : getStringParam(tempReport.type);
            tempReport.subType = tempReportParams.hasOwnProperty("report_subtype") ? getStringParam(tempReportParams.report_subtype) : getStringParam(tempReport.subType);

            tempReport.dueBy = tempReportParams.hasOwnProperty("dueBy") ? getStringParam(tempReportParams.dueBy) : getStringParam(tempReport.dueBy);
            tempReport.fromDate = tempReportParams.hasOwnProperty("fromDate") ? reportToDate(getStringParam(tempReportParams.fromDate)) : getStringParam(tempReport.fromDate);
            tempReport.toDate = tempReportParams.hasOwnProperty("toDate") ? reportToDate(getStringParam(tempReportParams.toDate)) : getStringParam(tempReport.toDate);
           
            let tempObjectId = tempReportParams.hasOwnProperty("object") ? getIntParam(tempReportParams.object) : objectId;
            let tempChartType = tempReportParams.hasOwnProperty("chartType") ? getStringParam(tempReportParams.chartType) : chartType;
            let tempConfig = tempReportParams.hasOwnProperty("config") ? getObjectParam(tempReportParams.config) : getObjectParam(config);
            setReport({...tempReport});
            setObjectId(tempObjectId);
            setChartType(tempChartType);
            setConfig({...tempConfig});
        } catch (error) {
            console.error("Error in 'pivotReport.js -> updateState()':" + error);
        }
    }

    const getReportParams = () => {
        let reportParams = {};
       let tempReport = report;
       try {
           reportParams.id = getIntParam(tempReport.id);
           reportParams.type = getStringParam(tempReport.subType);
           reportParams.due_by = getStringParam(tempReport.dueBy);
           if (dueBy === 'cust' && isValidParam(tempReport.fromDate) && isValidParam(tempReport.toDate)) {
               reportParams.from_date = reportFormatDate(tempReport.fromDate);
               reportParams.to_date = reportFormatDate(tempReport.toDate);
               setReport({...tempReport})
           }
       } catch (error) {
           console.error("Error in 'pivotReport.js -> getReportParams()':" + error);
       }
       return reportParams;
   }

    const getPivotReport = (reportData) => {
        let params = getReportParams();
        let url = endPoints.USERREPORT.GET_TABULAR_REPORT;
        if (isValidParam(reportData)) {
            if (getBooleanParam(isMounted) === true) {
                setIsMounted(false);
            }
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (TABLEID_OBJECT_MAP[getIntParam(response.object)] === constants.APPOINTMENTS) {
                        let dataSources = [];
                        dataSources = replaceUserIdByNameForAppointments(response.config.dataSource);
                        response.config.dataSource = dataSources;
                    }
                    replaceBlankHeaders(response);
                    updateState(response);
                    setIsMounted(true);
                } else {
                    setReport(reportData);
                    setIsMounted(true);
                }
            });
        }
    }

    const replaceUserIdByNameForAppointments = (response) => {
        let usersData = [...userlist];
        let dataSources = [];
        let users = usersData.filter((f) => {
            if (f.id == 'CURRENT_USER') { return f; }
        });
        if (users.length == 0) {
            let currentUser = {
                id: "CURRENT_USER",
                email: "",
                phone: "",
                text: appState.me.name,
                value: appState.me.name,
                colorCode: "",
            };
            usersData.push(currentUser);
        }
        usersData.map((m, i) => {
            response.map((d) => {
                if (d.user_id === m.id) {
                    d.user_id = m.text;
                    dataSources.push(d);
                }else if (d.assign === m.value) {
                    d.user_id = m.text;
                    dataSources.push(d);
                }
            });
        });
        return dataSources;
    }

    // const getReportConfig = (config) => {
    //     let rows = null;
    //     let columns = null;
    //     let values = null;
    //     let showGrandTotal = 0;
    //     let showSubTotal = 0;
    //     let chartType = null;

    //     if (isValidParam(config)) {
    //         config = getObjectParam(config);
    //         showGrandTotal = config.hasOwnProperty("showGrandTotal") ? getIntParam(config.showGrandTotal) : 0;
    //         showSubTotal = config.hasOwnProperty("showSubTotal") ? getIntParam(config.showGrandTotal) : 0;
    //         chartType = config.hasOwnProperty("chartType") ? getStringParam(config.chartType) : null;
    //         rows = config.hasOwnProperty("rows") ? getArrayParam(JSON.parse(config.rows)) : null;
    //         columns = config.hasOwnProperty("columns") ? getArrayParam(JSON.parse(config.columns)) : null;
    //         values = config.hasOwnProperty("values") ? getArrayParam(JSON.parse(config.values)) : null;
    //     }
    //     return config;
    // }


     /* handleDropDownChange = (name, event, value) => {

        try {
            let _state = this.state;
            _state.report[name] = value;
            _state.isScroll = false;
            if (name === "dueBy") {
                if (event === 'fromDate' || event === 'toDate') {
                    _state.report[name] = 'cust';
                    if (event === 'fromDate') {
                        _state.report.fromDate = value;
                    } else if (event === 'toDate') {
                        _state.report.toDate = value;
                    }
                    this.setState(_state);
                } else if (value === "cust") {
                    _state.report.fromDate = this.getDate();
                    _state.report.toDate = this.getDate();
                    this.setState(_state);
                } else {
                    _state.report.fromDate = null;
                    _state.report.toDate = null;
                    this.setState(_state);
                }
            } else {
                this.setState(_state);
            }
        }
        catch (error) {
            console.error("Error in 'pivotReport.js -> handleDropDownChange()':" + error);
        }
    } */
   
    const getDate = (date) => {
        let dateValue = isValidParam(date) ? new Date(date) : new Date();
        dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
        return dateValue;
    }

    const openTab = (label, url) => {
        let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true };
        addTab(tab, true);
    }

    const getChartType = () => {
        let type = getStringParam(chartType);
        return type;
    }

    const getChartTitle = () => {
        let chartTitle = getStringParam(report.name);
        return chartTitle;
    }


    const reloadReport = () => {
        let _report = report;
        getPivotReport(_report);
    }

    const print = () => {
        if (pivotObj.current) {
            if (getStringParam(chartType).length > 0) {
                pivotObj.current.printChart();
            } else {
                pivotObj.current.grid.print();
            }
        }else {
            sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.COMMON.NO_RECORD,
                null
              );
        }
    }

    const getMinDate = () => {
        let minDateVal = null;
        if (isValidParam(report.fromDate)) {
            let fromDateVal = new Date(report.fromDate);
            minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
        }
        return minDateVal;
    }

    const getFilterProps = () => {
        let fieldProps = [];

        fieldProps.push({
            date: {
                onChange: (event,value)=>handleDropDownChange('dueBy',event,value),
                defaultValue: report.dueBy,
                fromDateOptions: { maxDate: new Date() },
                toDateOptions: { minDate: getMinDate() },
                profile: appState.me,
                fromDate: report.fromDate,
                toDate: report.toDate
            }
        });
        return fieldProps;
    }

    const handleDropDownChange = (name, event, value) => {
        try {
            let tempReport = report;
            tempReport[name] = value;
            if (name === "dueBy") {
                if (event === 'fromDate' || event === 'toDate') {
                    tempReport[name] = 'cust';
                    if (event === 'fromDate') {
                        tempReport.fromDate = value;
                    } else if (event === 'toDate') {
                        tempReport.toDate = value;
                    }
                } else if (value === "cust") {
                    tempReport.fromDate = getDate();
                    tempReport.toDate = getDate();
                } else {
                    tempReport.fromDate = null;
                    tempReport.toDate = null;
                }
            }
            setReport({...tempReport});
        }
        catch (error) {
            console.error("Error in 'pivotReport.js -> handleDropDownChange()':" + error);
        }
    }

    const replaceBlankHeaders = (response) => {
        if(response &&  response.config  && response.config.rows){
        let cols = getArrayParam(response.config.columns);
        let dataSource = getArrayParam(response.config.dataSource);
        dataSource.map((m, i) => {
            response.config.rows.map((r) => {
                if (getStringParam(m[r.name]).trim().length === 0) {
                    m[r.name] = constants.EMPTY;
                }
            });
            cols.map((c) => {
                if (getStringParam(m[c.name]).trim().length === 0) {
                    m[c.name] = constants.EMPTY;
                }
            })
        });
    }
    }

    let style = { margin: 10, width: '98%', paddingTop: 0, paddingLeft: 11 };
        let dataSourceSettings = null;
        let minDateVal = null;
        let dueBy = getStringParam(report.dueBy);
        let hasRecord = false;
        if (isValidParam(report.fromDate)) {
            let fromDateVal = new Date(report.fromDate);
            minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
        }
        let _chartType = null;
        let chartTitle = null;
        let showChart = false;
        if (isMounted) {
            dataSourceSettings = config;
            if (getArrayParam(dataSourceSettings.dataSource).length > 0) {
                hasRecord = true;
            }
            _chartType = getStringParam(getChartType());
            if (_chartType.length > 0) {
                showChart = true;
                chartTitle = getChartTitle();
            }
        }

        return (
            <div style={{ width: '97%', marginLeft: 20 }}>
                {(isMounted && !widgetId)  && <ReportFilter reportType={"Pivot Report"} fieldProps={getFilterProps()} reload={()=>reloadReport()} printEvent={()=>print()} />}
                {isMounted ?
                    <div id="pivotReportContents" style={{ width: "100%", float: 'left',marginTop: (widgetId > 0) ?  "10px" : "" }}>
                        <div className='control-section' style={{ overflow: 'auto' }}>

                            {hasRecord ?
                                <div>
                                    {showChart ? <PivotViewComponent id='PivotView' dataSourceSettings={dataSourceSettings} width={'100%'}
                                        height={'300'} gridSettings={{ columnWidth: 140 }}
                                        displayOption={{ view: 'Chart' }} showFieldList={false}
                                        chartSettings={{ title: chartTitle, chartSeries: { type: _chartType } }}
                                        ref={pivotObj} >
                                        <Inject services={[PivotChart]} />
                                    </PivotViewComponent> :

                                        <PivotViewComponent id='PivotView' dataSourceSettings={dataSourceSettings} width={'100%'}
                                            height={'300'} gridSettings={{ columnWidth: 140 }}
                                            ref={pivotObj} >
                                        </PivotViewComponent>
                                    }
                                </div>
                                :
                                <NoRecordContent label={localizedStrings.label.INXPRESS_REPORT.NO_RECORDS_FOUND} />
                            }
                        </div>
                    </div> : <Loader />
                }
            </div>)
    }

 export default PivotReport; 

const NoRecordContent = (label) => {
    return (<table className="layout_maintable" style={{ width: '100%' }}><tr><td><span style={{ color: 'red' }}>{isValidParam(label) ? label.label : null}</span></td></tr></table>);
}
const Loader = () => {
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return <div style={{ width: '100%', height: contentHeight }}>
    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
        </div>
    </div>
</div>
}
