import * as React from 'react';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { styles } from '../../services/constants/styles';
import Popper from '@mui/material/Popper';
import { isValidParam } from '../../services/helper/parameterVerifier';

const divStyle = { color: 'rgb(97, 97, 97)', height: 'auto', width: '100%', position: 'relative', marginBottom: '20px', border: '1px solid #b9b2b2', background: 'white', borderRadius: '4px',cursor:'text' }
const buttonOuterLabelStyle = { backgroundColor: '#fff', lineHeight: '0.2', marginTop: '-1px', fontSize: 11, fontWeight: 500, color: '#717171', position: 'absolute', left: 12, zIndex: 999999, padding: '0px 5px' };
const buttonInnerLabelStyle = {  fontSize: 15, fontWeight: 500, color: '#717171', padding: '4px 7px', position: 'absolute' };
const email = /^[A-Za-z0-9_.]{1,60}@[A-Za-z0-9]{1,60}[.]{1}[A-Za-z0-9.]{1,60}$/;
const mobile = /^[6789][0-9]{9}$/;
const TagAutocomplete = ({ label, rule, name, isClearable, clearAllTitle, selected, suggestions, onInputClick, autoFocus, onDelete, onAdd, allowNew, onInput, isDisabled, placeholderText, labelText, tagListLabelText, ariaAddedText, minSearchLength, mobileSreen }) => {
    const [cards, setCards] = React.useState([]);
    const [optionList, setOptionList] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [isFocused, setIsFocused] = React.useState(false);
    const inputRef = React.useRef(null);
    const cell = React.useRef(null);
    const _autoFocus = isValidParam(autoFocus) ? autoFocus : true

    React.useEffect(() => {
      const handleFocusChange = () => {
        setIsFocused(document.activeElement === inputRef.current);
      };
  
      inputRef.current?.addEventListener("focus", handleFocusChange);
      inputRef.current?.addEventListener("blur", handleFocusChange);
  
      return () => {
        inputRef.current?.removeEventListener("focus", handleFocusChange);
        inputRef.current?.removeEventListener("blur", handleFocusChange);
      };
    }, []);

    React.useEffect(() => {
        let _selected = getArrayValue(selected);
        setCards([..._selected]);
        setOptionList([]);
        console.log(_autoFocus)
        if (_autoFocus) {
            setInputFocused();
        }
    }, []);

    React.useEffect(() => {
        let _selected = getArrayValue(selected);
        if (_selected.length > 0) {
            setCards([..._selected]);
        }
        setOptionList([])
    }, [selected]);


    React.useEffect(() => {
        setOptionList([...suggestions])
    }, [suggestions]);

    const handleFocus = () => {
        setOptionList([])
        inputRef?.current?.focus();
        if (onInputClick) {
            onInputClick();
        }
    }
    const onChangeHandler = (value) => {
        setInputValue(value)
        if (value.length >= minSearchLength || 3) {
            onInput(value)
        } else {
            setOptionList([])
        }

    }

    const keyDownHandler = (e) => {
         let _cardes = cards;
        if (allowNew && inputValue?.trim() && (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32)) {
            
            let _cardObj = {};
            _cardObj.label = inputValue;
            _cardObj.value = inputValue;
            _cardes.push(_cardObj);
            setInputValue('');
            setOptionList([])
            setCards([..._cardes]);
            onAdd(getStingValue(_cardes), inputValue);
        }
        if(_cardes.length > 0 && e.keyCode === 8 && e.key != "Backspace"){
            const idx = _cardes.length - 1;
            const result = [];
            cards.map((item, index) => {
                if (index != idx) {
                    result.push(item);
                }
            });
            setCards([...result])
            onDelete(getStingValue(result), cards[idx])
        }
    };
    const onBlurHandler = (e) => {
        if (optionList.length == 0 && e != undefined && allowNew && inputValue && inputValue?.trim()) {
            let _cardes = cards;
            let _cardObj = {};
            _cardObj.label = inputValue;
            _cardObj.value = inputValue;
            _cardes.push(_cardObj);
            setInputValue('')
            setOptionList([])
            setCards([..._cardes])
            onAdd(getStingValue(_cardes), inputValue);
        }
    };
    const onHandleOptionSelect = (newValue) => {
        let _cardes = cards;
        _cardes.push(newValue);
        setInputValue('')
        setOptionList([])
        setCards([..._cardes])
        onAdd(getStingValue(_cardes), newValue.value)
    }

    const delCard = (idx) => {
        const result = [];
        cards.map((item, index) => {
            if (index != idx) {
                result.push(item);
            }
        });
        setCards([...result])
        onDelete(getStingValue(result), cards[idx])
    }
    const getStingValue = (arr) => {
        let _arr = [];
        let _str = "";
        arr.map((item) => {
            _arr.push(item.value);
        })
        _str = _arr.join(",");
        return _str;
    }
    const getArrayValue = (str) => {
        let _arr = [];
        if (str?.indexOf(',') > -1) {
            let _atrArr = str.split(",");
            _atrArr.map((item) => {
                let _cardObj = {};
                _cardObj.label = item;
                _cardObj.value = item;
                _arr.push(_cardObj);
            })

        } else {
            if (str != null && str != undefined && str.length > 0) {
                let _cardObj = {};
                _cardObj.label = str;
                _cardObj.value = str;
                _arr.push(_cardObj);
            };
        }
        return _arr;
    }
    const getColor = (val) => {
        let color = 'default'
        if (rule == 'email' && !email.test(val)) {
            color = 'error';
        }
        if (rule == 'mobile' && !mobile.test(val)) {
            color = 'error';
        }
        return color;
    }
    const setInputFocused = () => {
        inputRef.current?.focus();
    }
    const getOptionLabelHtml = (label) => {
        let labelHhml = null;
        let themeColor = localStorage.getItem("background");
        labelHhml = label.replace(inputValue, `<span style="color:${themeColor};">${inputValue}</span>`);
        return labelHhml;
    }
    const clearAll = () => {
        let _cardes = [];
        setInputValue('')
        setOptionList([])
        setCards([..._cardes])
        if (onDelete) {
            onDelete('', selected);
        }
    }

    let width = 100;
    if (document.getElementById("sf-tag-autocomplete_id__root") != null) {
        width = document.getElementById("sf-tag-autocomplete_id__root")?.offsetWidth;
    };
    let charWidth = inputValue.length * 10;
    charWidth = charWidth > 0 ? charWidth + 10 : 2;
    return (
        <div ref={cell} className="sf-tag-autocomplete__root" id="sf-tag-autocomplete_id__root" style={{ width: '100%' }}>
            <div style={divStyle} onClick={() => handleFocus()}>
            {label && (inputValue || cards.length > 0 || isFocused) && <label style={buttonOuterLabelStyle}>{label}</label>}
                <div style={{ padding: label !== undefined && (label == 'Cc'  || label == 'Bcc')?"":'5 0 0 0' }}>
                    <span style={{ padding: label !== undefined && (label == 'Cc'||label == 'Bcc') ?"3px": '5px', width: '100%', display: isClearable?'flex':'inline', justifyContent: 'space-between' }}>
                        <span style={{ width: '100%' }}>
                        {label && inputValue.length == 0 && cards.length == 0 && !isFocused && <span style={buttonInnerLabelStyle}>{label}</span>}
                            {cards.length > 0 && <span>
                                {cards.map((item, index) => (
                                    <span style={{ margin: 1 }}  >
                                        <Chip
                                            color={getColor(item.value)}
                                            disabled={isDisabled}
                                            label={item.label}
                                            size="small"
                                            style={{ margin: 2 }}
                                            onDelete={() => delCard(index)}
                                        />
                                    </span>
                                ))}
                            </span>}

                            <span style={{ display: 'inline-flex',height: '32px', alignItems: 'center' }}>
                                <input
                                    ref={inputRef}
                                    value={inputValue}
                                    autoCapitalize="none" autoComplete="off" autoCorrect="off"
                                    spellCheck="false" tabIndex={0}
                                    style={{ border: '0px', padding: '6px', height: mobileSreen ? 22 : 35, boxSizing: 'content-box', width: charWidth, maxWidth: width, background: '0px center', fontSize: 'inherit', opacity: 1, outline: 0, color: 'inherit' }}
                                    className='sf-tag-autocomplete__inputfield'
                                    id='sf-tag-autocomplete__inputfield_id'
                                    onChange={(e) => onChangeHandler(e.target.value?.trim())}
                                    onKeyDown={(e) => keyDownHandler(e)}
                                    onBlur={(e) => onBlurHandler(e)}
                                />
                                {cards.length == 0 && inputValue.length == 0 && <span style={{ color: 'rgb(128, 128, 128)', alignItems: 'center', display: 'flex' }}>
                                    {placeholderText}</span>}
                            </span> </span>
                        {isClearable && <span style={{ width: '20px', display: 'flex' }}>
                            <span onClick={clearAll} title={clearAllTitle ? clearAllTitle : 'Clear All'} style={{ cursor: 'pointer', display: 'flex', marginTop: '-3px', alignItems: "center",fontWeight:600, fontSize: '20px' }}>
                                <i
                                    className="fa-solid fa-eraser"
                                    aria-hidden="true"
                                    style={{ cursor: "pointer", marginLeft: '-4px', fontSize:16 }}
                                />
                            </span>
                        </span>}
                    </span>
                </div>
            </div>
            <Popper
                open={optionList.length > 0 && inputValue.length > 0}
                anchorEl={inputRef?.current}
                placement="bottom-start"
                sx={{ ...styles.popperStyle, width: 'auto', maxWidth: width, borderRadius: '4px', }} >
                <div id="draggable-select-container-root" className="draggable-select-container" style={{ margin: '0' }}>
                    <div className="draggable-select" style={{ background: '#fff', overflow: 'auto', maxHeight: 100, borderRadius: '4px', }}>
                        <div>
                            <div id="draggable-select-items">
                                {optionList.map((item) => (
                                    <MenuItem
                                        key={item.label}
                                        onClick={() => onHandleOptionSelect(item)}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: getOptionLabelHtml(item.label) }} />
                                    </MenuItem>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Popper>
        </div>


    );
}

export default TagAutocomplete;