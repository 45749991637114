import { actions } from '..//constants/actions';

const settingsInitialState = {
    unitCrm : null,
}

const settingsReducer  = (state = settingsInitialState, action) => {

    switch (action.type) {
        case actions.UPADTE_UNIT_CRM_DATA:
            return {
                ...state,
                unitCrm: action.payload
            }
        default:
            return state;
    }


}
export default settingsReducer;