import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { isValidParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import {Button, FormControl, TextField,MenuItem} from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { refreshListView } from '../../../services/actions/listViewAcions';
import * as sfDialogs from '../components/sfDialogs';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import {getCalendarRefresh} from '../../../services/actions/calendarActions';
import {updateSfRecentData} from "../../../services/actions/sfFormActions";
import { array } from 'prop-types';

const SourceForm =({object,id,type,isDetailView,data})=> {
    const dispatch = useDispatch();
    const [Id, setId] = useState(-1);    
    const [mounted, setMounted] = useState(false);
    const [name, setName] = useState('');
    const [corporate_currency, setCorporate_currency] = useState([]);
    const [currencyId, setCurrencyId] = useState(null);
    const [userCurrencyId, setUserCurrencyId] = useState(null);    
    const [autoFocus, setAutoFocus] = useState(true);
    const [oldValue, setOldValue] = useState(null);
    const [currencyName, setCurrencyName] = useState(null); 
    const [isSavedDisabled, setIsSavedDisabled] = useState(false); 
    const [taskDesc, setTaskDesc] = useState(''); 
    const [sourceList,setSourceList] = useState([]);
    const [secondarySourceList,setSecondarySourceList] = useState([]);
    const [selectedPrimarySource,setSelectedPrimarySource] = useState('');
    const [formType,setformType] = useState('');
    const [isPrimarySourceEmpty,setIsPrimarySourceEmpty] = useState(false);
    const [isSecondarySourceEmpty,setIsSecondarySourceEmpty] = useState(false);
    const [selectedSecondarySource,setSelectedSecondarySource] = useState(null);
    const [categoryShrink,setCategoryShrink] = useState(false);
    const [searchText,setSearchText] = useState('');
    const [tempSecondarySourceList,settempSecondarySourceList] = useState([]);
    const [newAddedSecondarySource,setNewAddedSecondarySource] = useState([]);

    const sfForm = useSelector((state) => state.sfForm);    
    const calendar = useSelector((state) => state.calendar);
    const CampaignTypes = useSelector((state) => state.campaignTypes); 
    const appState = useSelector((state) => state.app);



    useEffect(()=>{
        if(data?.sourceType === "Secondary Source"){
            getPrimarySourceName();
            setformType(data?.sourceType);
        }
    },[])

    const getPrimarySourceName = () =>{
        setMounted(false);
        const promise = Promise.resolve(HTTPClient.get(endPoints.SOURCE.GET_PRIMARY_SOURCE));
        if (promise) {
            promise.then(res => {
                setSourceList( res ? [...res] : []);
                setMounted(true);
            })
        }
    }

    useEffect(() => {
        let url = null;
        var promise = null;
        let userCurrencyId = '';
        let userCurrencyName = '';
        let currencyId = '';
        let currencyItemList = [];
        if (type === 'edit') {
            getFormData();
        } else {
            setMounted(true);
        }
        if (object === constants.CORPORATE_CURRENCY_OBJECT) {
            url = endPoints.CORPORATE_CURRENCY.GET_CURRENCY_LIST;
            promise = Promise.resolve(HTTPClient.get(url));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response.currencyList)) {
                        response.currencyList.forEach((listItem, index) => {
                            if (isValidParam(response.userSavedCurrency) && isValidParam(response.userSavedCurrency.currencyId)) {
                                if (listItem.id === response.userSavedCurrency.currencyId) {
                                    userCurrencyName = listItem.name + '(' + listItem.symbol + ')';
                                    currencyId = response.userSavedCurrency.currencyId;
                                }
                            }
                            let obj = {};
                            obj.id = listItem.id;
                            obj.value = listItem.id;
                            obj.label = listItem.name + '(' + listItem.symbol + ')';
                            obj.title = listItem.name + '(' + listItem.symbol + ')';
                            currencyItemList.push(obj);
                        });
                    } if (isValidParam(response.userSavedCurrency)) {
                        userCurrencyId = response.userSavedCurrency.id;
                    }
                    setMounted(true);
                    setCorporate_currency(currencyItemList);
                    setCurrencyName(userCurrencyName);
                    setName(currencyId);
                    setUserCurrencyId(userCurrencyId);
                    setCurrencyId(currencyId)
                    
                });
            }

        }
      }, [object]);


    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const getFormData = () => {
        // let tempObject = object;
        let name = '';
        let params = null;
        var promise = null;
        let url = null;
        if (object === constants.SOURCE_OBJECT) {
            url = endPoints.SOURCE.EDIT;
        }  else if (object == constants.LOCATION_OBJECT) {
            url = endPoints.LOCATION.EDIT;
        }else if (object == constants.TASKSLIST_OBJECT) {
            url = endPoints.TASKS_INFO.EDIT;
        } else if (object === constants.RELATIONS_OBJECT) {
            url = endPoints.RELATIONS.EDIT;
        } else if (object === constants.CAMPAIGN_TYPES_OBJECT) {
            url = endPoints.CAMPAIGN_TYPES.EDIT;
        } else if (object === constants.NOTE_TYPES_OBJECT) {
            url = endPoints.NOTE_TYPE.EDIT;
        }else if (object === constants.APPOINTMENT_TYPES_OBJECT) {
            url = endPoints.NOTE_TYPE.EDIT;
        }

        params = {
            recordId: id
        }
        promise = Promise.resolve(HTTPClient.get(url, params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (object === constants.SOURCE_OBJECT) {
                    name = response.t_source_name;
                    setformType(response?.t_type);
                    setSelectedPrimarySource(response?.t_primary);
                    getPrimarySourceName();
                } else if (object == constants.LOCATION_OBJECT) {
                    name = response.t_location;
                }
                else if (object == constants.TASKSLIST_OBJECT) {
                    name = response.t_taskname;
                    setTaskDesc(response.t_description)
                }
                else if (object === constants.RELATIONS_OBJECT) {
                    name = response.t_relation_name;
                } else if (object === constants.NOTE_TYPES_OBJECT) {
                    if (response.t_system_type === 1 || response.t_default === 1) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.SYSTEM_NOTE_TYPE_EDIT_ALERT,null);
                        closeDrawer();
                        return false;
                    } else {
                        name = response.t_note_type;
                    }
                }else if (object === constants.APPOINTMENT_TYPES_OBJECT) {
                    if (response.t_system_type === 1 || response.t_default === 1) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.SYSTEM_APPOINTMENT_TYPE_EDIT_ALERT,null);
                        closeDrawer();
                        return false;
                    } else {
                        name = response.t_note_type;
                    }
                } else if (object === constants.CAMPAIGN_TYPES_OBJECT) {
                    name = response.t_campaign_name;
                }
                setMounted(true);
                setName(name);
                setId(id);
                setOldValue(name);
            });
        }
    }

    const saveForm = () => {
        try {
            let url = null;
            let params = null;
            var promise = null;
            let snackmsg = '';
            // let object = object;
            let msg = null;
            let isValid = true;
            let alertMsg = getLocalizedStrings().message.SETTINGS.EMPTY_NAME + ' ' + getLocalizedStrings().label.SETTINGS[object.toUpperCase()] + ' ' + getLocalizedStrings().label.COMMON.NAME;
            let dplMsg = null;
            if (object === constants.CORPORATE_CURRENCY_OBJECT) {
                if (isValidParam(currencyId) && currencyId > 0) {
                    url = endPoints.CORPORATE_CURRENCY.SAVE;
                    params = {
                        'currencyId': currencyId,
                        'userCurrencyId': userCurrencyId,
                    }
                  
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.CORPORATE_CURRENCY_CONFIRM,()=>SavedCurrency(url,params),null);
                  
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.CORPORATE_CURRENCY_ALERT,null);
                }
            } else {
                let tempName = name.trim();
                if(object !== constants.SOURCE_OBJECT){
                    if (isValidParam(tempName) && tempName.length > 0 && tempName.length < 256) {
                        if (type === 'edit') {
                            snackmsg = getLocalizedStrings().label.SETTINGS[getStringParam(object).toUpperCase()] + ' ' + getLocalizedStrings().label.SETTINGS.EDIT + ' ' + getLocalizedStrings().label.SETTINGS.SUCCESSFULL;
                        }
                         else if (object === constants.TASKSLIST_OBJECT) {
                            snackmsg = 'Task' + ' ' + getLocalizedStrings().label.SETTINGS.ADDED + ' ' + getLocalizedStrings().label.SETTINGS.SUCCESSFULL;
                        }
                        
                        else {
                            snackmsg = getLocalizedStrings().label.SETTINGS[getStringParam(object).toUpperCase()] + ' ' + getLocalizedStrings().label.SETTINGS.ADDED + ' ' + getLocalizedStrings().label.SETTINGS.SUCCESSFULL;
                        }
                        if (object === constants.NOTE_TYPES_OBJECT) {
                            url = endPoints.NOTE_TYPE.SAVE;
                            params = {
                                'note_types': tempName,
                                'id': id,
                                'type':"Note Types",
                            }
                        } else if (object === constants.LOCATION_OBJECT) {
                            url = endPoints.LOCATION.SAVE;
                            dplMsg = getLocalizedStrings().message.SETTINGS.DUPLICATES_SOURCE_NAME;
                            params = {
                                't_location': name,
                                't_pk': id,
                            }
    
                        }
                        else if (object === constants.TASKSLIST_OBJECT) {
                            url = endPoints.TASKS_INFO.SAVE;
                            dplMsg = getLocalizedStrings().message.SETTINGS.DUPLICATES_SOURCE_NAME;
                            params = {
                                't_taskname': name,
                                't_description': taskDesc,
                                't_pk': id,
                            }}
                        else if (object === constants.APPOINTMENT_TYPES_OBJECT) {
                            url = endPoints.NOTE_TYPE.SAVE;
                            params = {
                                'note_types': tempName,
                                'id': id,
                                'type':"Appointment Types",
                            }
                        } else if (object === constants.CAMPAIGN_TYPES_OBJECT) {
                            url = endPoints.CAMPAIGN_TYPES.SAVE;
                            params = {
                                'campaign_types': tempName,
                                'record_id': id,
                            }
                        } else if (object === constants.RELATIONS_OBJECT) {
                            url = endPoints.RELATIONS.SAVE;
                            params = {
                                't_pk': id,
                                't_relation_name': tempName,
                            }
                        }
                        if(isValid){
                            promise = Promise.resolve(HTTPClient.post(url, params));
        
                            if (isValidParam(promise)) {
                                promise.then((response) => {
                                    if (response.status === -1) {
                                        if (isValidParam(response.error)) {
                                            msg = response.error.message.split(' ').join('_').toUpperCase();
                                            if (response.error.message.toLowerCase() === constants.DUPLICATES_SOURCE_NAME) {
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, dplMsg);
                                            } else {
                                                if (object === constants.LOCATION_OBJECT) {
                                                    sfDialogs.alert(getLocalizedStrings().label.COMMON.LOCATION_ALERT, getLocalizedStrings().message.SETTINGS[msg], null);
                                                }
                                                else {
                                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS[msg], null);
                                                }
                                            }
                                        }
                                    } else {
                                        if (isValidParam(response.data)) {
                                            closeDrawer();
                                            refreshListView(object);
                                            if (object === constants.SOURCE_OBJECT) {
                                                if(formType !== "Secondary Source" && formType !=="Secondary"){
                                                    addSourceInForms(tempName);
                                                }
                                            }
                                            if (object === constants.LOCATION_OBJECT) {
                                                addSourceInForms(tempName);
                                            }
                                            if (object === constants.TASKSLIST_OBJECT) {
                                                addTaskInForms(tempName);
                                                }
                                            if (object === constants.NOTE_TYPES_OBJECT) {
                                                addNoteTypeInForms(tempName);
                                            }if (object === constants.APPOINTMENT_TYPES_OBJECT) {
                                                addAppointmentTypeInForms(tempName);
                                            }
                                            if (object === constants.CAMPAIGN_TYPES_OBJECT) {
                                                setId(response.data.id);
                                                addCampaignTypes(tempName, id);
                                            }
                                            dispatch(showCustomSnackBar(snackmsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        }
                                    }
                                });
                            }
                        }
                    }
                    else if (name.length > 256 && object== constants.NOTE_TYPES_OBJECT) {
                        dispatch(showCustomSnackBar("Task & Note Types should not more then 256 character", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        setName('');
                    }
                    else if (name.length > 256) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg,null);
                        setName(tempName.trim());
                    }
                    else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
                        this.setState({ name: name.trim() })
                    }
                }else {
                    if (object === constants.SOURCE_OBJECT) {
                        //primary source validation 
                        let snackmsg = '';
                         if (type === 'edit') {
                            snackmsg = getLocalizedStrings().label.SETTINGS[getStringParam(object).toUpperCase()] + ' ' + getLocalizedStrings().label.SETTINGS.EDIT + ' ' + getLocalizedStrings().label.SETTINGS.SUCCESSFULL;
                        }else {
                            snackmsg = getLocalizedStrings().label.SETTINGS[getStringParam(object).toUpperCase()] + ' ' + getLocalizedStrings().label.SETTINGS.ADDED + ' ' + getLocalizedStrings().label.SETTINGS.SUCCESSFULL;
                        }
                        if(tempName.length > 256){
                            isValid = false;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Source Name should not more then 256 character", null);
                            setName(tempName.trim());
                        }else if(!tempName && (formType !== "Secondary Source" && formType !=="Secondary")){
                            let message = getLocalizedStrings().message.SOURCE.PRIMARY_SOURCE_EMPTY_VALIDATION;
                            isValid = false;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message,null);
                            setName(tempName.trim());
                        }
                        // secondary source validation handle
                        else if(!tempName && (formType === "Secondary Source" || formType ==="Secondary") && type === 'edit'){
                            let inputFieldElement = null;
                            let message = getLocalizedStrings().message.SOURCE.SECONDARY_SOURCE_EMPTY_VALIDATION;
                            isValid = false;
                            setIsSecondarySourceEmpty(true);
                            inputFieldElement = document.getElementById('secondary_source');
                            if (isValidParam(inputFieldElement) && inputFieldElement !== null) {
                                inputFieldElement.focus();
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message,null);
                            setName(tempName.trim());
                        }
                        else if(!selectedPrimarySource && (formType === "Secondary Source" || formType ==="Secondary") && type === 'new'){
                            let inputFieldElement = null;
                            isValid = false;
                            setIsPrimarySourceEmpty(true);
                            inputFieldElement = document.getElementById('Primary_source');
                            if (isValidParam(inputFieldElement) && inputFieldElement !== null) {
                                inputFieldElement.focus();
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,"Please Select Primary source.",null);
                        } else if(!selectedSecondarySource && (formType === "Secondary Source" || formType ==="Secondary") && type === 'new'){
                            let inputFieldElement = null;
                            isValid = false;
                            setIsSecondarySourceEmpty(true);
                            inputFieldElement = document.getElementById('secondary_source');
                            if (isValidParam(inputFieldElement) && inputFieldElement !== null) {
                                inputFieldElement.focus();
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,"Please Select Secondary source.",null);
                        } else{
                            url = endPoints.SOURCE.SAVE;
                            dplMsg = getLocalizedStrings().message.SETTINGS.DUPLICATES_SOURCE_NAME;
                            if((formType === "Secondary Source" || formType ==="Secondary") && type === 'new'){
                                params = {
                                    't_primary': selectedPrimarySource,
                                    't_source_name':selectedSecondarySource ? selectedSecondarySource.name : selectedSecondarySource,
                                    't_pk': id,
                                    t_type : 'Secondary',
                                    t_secondary_list : newAddedSecondarySource,
                                }
                            } else if((formType === "Secondary Source" || formType ==="Secondary") && type === 'edit'){
                                params = {
                                    't_primary': selectedPrimarySource,
                                    't_source_name': tempName,
                                    't_pk': id,
                                    t_type : 'Secondary',
                                }
                            }
                            else{
                                params = {
                                    't_source_name': tempName,
                                    't_pk': id,
                                    t_type : 'Primary',
                                }
                            }
                         if(isValid){
                            setIsSavedDisabled(true);
                            promise = Promise.resolve(HTTPClient.post(url, params));
                            if (isValidParam(promise)) {
                                promise.then((response) => {
                                    if (response.status === -1) {
                                        if (isValidParam(response.error)) {
                                            setIsSavedDisabled(false);
                                            msg = response.error.message.split(' ').join('_').toUpperCase();
                                            if (response.error.message.toLowerCase() === constants.DUPLICATES_SOURCE_NAME) {
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS[msg]);
                                            } else {
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,response.error.message , null);
                                            }
                                        }
                                    } else {
                                        if (isValidParam(response)) {
                                            closeDrawer();
                                            refreshListView(object);
                                            if (object === constants.SOURCE_OBJECT) {
                                                if(formType !== "Secondary Source" && formType !=="Secondary"){
                                                    addSourceInForms(tempName);
                                                }
                                            }
                                            dispatch(showCustomSnackBar(snackmsg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        }
                                    }
                                });
                            }
                        }
                        }

                    }
                }
            }
        } catch (error) {
            console.error("Error in 'SourceForm.js -> saveForm()':" + error);
        }
    }
const SavedCurrency = (url,params)=>{
    var promise = null;
    promise = Promise.resolve(HTTPClient.post(url, params));
    if (isValidParam(promise)) {
        promise.then((response) => {
            if (isValidParam(response.data) && isValidParam(response.data.savedUserCurrencyId)) {
                closeDrawer();
                dispatch(showCustomSnackBar(getLocalizedStrings().message.SETTINGS.CORPORATE_CURRENCY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.SETTINGS.INVALID_CURRENCY_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        });
    }
}
   const addSourceInForms = (value) => {
        let accountsFormData = Object.assign({}, sfForm.data['accounts']);
        let contactsFormData = Object.assign({}, sfForm.data['contacts']);
        if (accountsFormData !== null) {
            let sourceField = accountsFormData.fields.filter(f => { return f.name === 'source' });
            if (sourceField !== null && sourceField.length > 0) {
                let sourceItems = sourceField[0].list_values;
                if (sourceItems !== null && sourceItems.length > 0) {
                    if (type === 'edit') {
                        let tempOldValue = oldValue;
                        let items = sourceItems.filter(f => { return f.id === tempOldValue });
                        if (items.length > 0) {
                            items[0].id = value;
                            items[0].value = value;
                        }
                    } else {
                        let sourceObject = {};
                        sourceObject.id = value;
                        sourceObject.value = value;
                        sourceItems.push(sourceObject);
                    }
                }
            }
        }

        if (contactsFormData !== null) {
            let sourceField = contactsFormData.fields.filter(f => { return f.name === 'source' })
            if (sourceField !== null && sourceField.length > 0) {
                let sourceItems = sourceField[0].list_values;
                if (sourceItems !== null && sourceItems.length > 0) {
                    if (type === 'edit') {
                        let tempOldValue = oldValue;
                        let items = sourceItems.filter(f => { return f.id === tempOldValue });
                        if (items.length > 0) {
                            items[0].id = value;
                            items[0].value = value;
                        }
                    } else {
                        let sourceObject = {};
                        sourceObject.id = value;
                        sourceObject.value = value;
                        sourceItems.push(sourceObject);
                    }
                }
            }
        }
    }
    const addTaskInForms = (value) => {
        let TaskFormData = Object.assign({}, sfForm.data['tasks']);
        if (TaskFormData !== null) {
            let TaskNameField = TaskFormData?.fields?.filter(f => { return f.name === "t_name" });
            if (TaskNameField !== null && TaskNameField?.length > 0) {
                let TaskItems = TaskNameField[0]?.list_values;
                if (TaskItems !== null && TaskItems.length > 0) {
                    if (type === 'edit') {
                        let tempOldValue = oldValue;
                        let items = TaskItems.filter(f => { return f.id === tempOldValue });
                        if (items.length > 0) {
                            items[0].id = value;
                            items[0].value = value;
                        }
                        if(oldValue === sfForm.sfRecentData.tasks){
                            updateSfRecentData("tasks", value);

                        }
                    } else {
                        let TaskObj = {};
                        TaskObj.id = value;
                        TaskObj.value = value;
                        TaskItems.push(TaskObj);
                    }
                }
            }
        }

    }
    const addNoteTypeInForms = (value) => {
        let notesFormData = Object.assign({}, sfForm.data['note']);
        if (isValidParam(notesFormData) && Object.keys(notesFormData).length !== 0) {
            let noteTypeField = notesFormData.fields.filter(f => { return f.name === 'note_type' })
            if (noteTypeField !== null && noteTypeField.length > 0) {
                let noteTypeItems = noteTypeField[0].list_values;
                if (noteTypeItems !== null && noteTypeItems.length > 0) {
                    if (type === 'edit') {
                        let tempOldValue = oldValue;
                        let items = noteTypeItems.filter(f => { return f.id === tempOldValue });
                        if (items.length > 0) {
                            items[0].id = value;
                            items[0].value = value;
                        }
                    } else {
                        let noteTypeObject = {};
                        noteTypeObject.id = value;
                        noteTypeObject.value = value;
                        noteTypeItems.push(noteTypeObject);
                    }

                }
            }
        }
    }
    const addAppointmentTypeInForms = (value) => {
        let appointmentFormData = Object.assign({}, sfForm.data['appointments']);
        if (isValidParam(appointmentFormData) && Object.keys(appointmentFormData).length !== 0) {
            let appointmentTypeField = appointmentFormData.fields.filter(f => { return f.name === 'event_type' })
            if (appointmentTypeField !== null && appointmentTypeField.length > 0) {
                let appointmentTypeItems = appointmentTypeField[0].list_values;
                if (appointmentTypeItems !== null && appointmentTypeItems.length > 0) {
                    if (type === 'edit') {
                        let tempOldValue = oldValue;
                        let items = appointmentTypeItems.filter(f => { return f.id === tempOldValue });
                        if (items.length > 0) {
                            items[0].id = value;
                            items[0].value = value;
                        }
                       
                    } else {
                        let appointmentTypeObject = {};
                        appointmentTypeObject.id = value;
                        appointmentTypeObject.value = value;
                        appointmentTypeItems.push(appointmentTypeObject);
                       
                    }
                    if (appointmentTypeItems.length > 0) {
                        let param = {};
                         param.object = constants.APPOINTMENTS;
                         param.name = 'event_type';
                         param.list_type = 'external';
             
                         let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));
             
                         promise.then((response) => {
                            if (isValidParam(response)) {
                            let type = getArrayParam(response.record);
                            type.sort(function (a, b) {
                                if (a.value.toLowerCase() > b.value.toLowerCase()) { return 1; };
                                if (a.value.toLowerCase() < b.value.toLowerCase()) { return -1; };
                                return 0;
                            });
                            let appTypes = type.map((f, i) => {
                                let typeObj = {};
                                typeObj.value = f.value;
                                typeObj.label = f.value;
                                typeObj.color = f.color;
                                return typeObj;
                            });
                            appTypes.unshift({ value: -9999, label: 'All' });
                            sortArrayObjectByProperty(appTypes, 'label');
                            calendar.calendarInfo.appTypes = appTypes;
                            dispatch(getCalendarRefresh(true));
                        }
                        });
                }
            }
        }
        }else{
            let appointmentTypeItems = calendar.calendarInfo.appTypes;
            appointmentTypeItems = appointmentTypeItems.filter(f=>f.label !== 'All')
            
            if (appointmentTypeItems !== null && appointmentTypeItems.length > 0) {
                if (type === 'edit') {
                    let tempOldValue = oldValue;
                    let items = appointmentTypeItems.filter(f => { return f.value === tempOldValue });
                    if (items.length > 0) {
                        items[0].label = value;
                        items[0].value = value;
                    }
                   
                } else {
                    let appointmentTypeObject = {};
                    appointmentTypeObject.label = value;
                    appointmentTypeObject.value = value;
                    appointmentTypeItems.push(appointmentTypeObject);
                   
                }
                if (appointmentTypeItems.length > 0) {
                    let param = {};
                     param.object = constants.APPOINTMENTS;
                     param.name = 'event_type';
                     param.list_type = 'external';
         
                     let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));
         
                     promise.then((response) => {
                        if (isValidParam(response)) {
                        let type = getArrayParam(response.record);
                        type.sort(function (a, b) {
                            if (a.value.toLowerCase() > b.value.toLowerCase()) { return 1; };
                            if (a.value.toLowerCase() < b.value.toLowerCase()) { return -1; };
                            return 0;
                        });
                        let appTypes = type.map((f, i) => {
                            let typeObj = {};
                            typeObj.value = f.value;
                            typeObj.label = f.value;
                            typeObj.color = f.color;
                            return typeObj;
                        });
                        sortArrayObjectByProperty(appTypes, 'label');
                        calendar.calendarInfo.appTypes = appTypes;
                        dispatch(getCalendarRefresh(true));
                    }
                    });
              
            }
        }
    }
        
    }


    const addCampaignTypes = (value, id) => {
        let campaignTypes = CampaignTypes.data;
        if (campaignTypes !== null) {
            if (type === 'edit') {
                delete campaignTypes[id];
                campaignTypes[id] = value;
            } else {
                campaignTypes[id] = value;
            }
        }
    }

    const handleChange = (event,value) => {
        let data = null;
        let types = corporate_currency;
        if (object === constants.CORPORATE_CURRENCY_OBJECT) {
            data = types.filter(f => { return f.id === value.value });
            if (data.length > 0) {
                data = data[0];
            }
            setName(data.value);
            setCurrencyId(data.id);
            setCurrencyName(data.title);
        } else {
            setName(event.target.value)
        }
    }

    const handleChangePrimarychange = (event) =>{
        let primarySource  = event.target.value;
        if(newAddedSecondarySource.length <= 0){
            let params = {};
            params.primary_source =  primarySource;
            const promise = Promise.resolve(HTTPClient.get(endPoints.SOURCE.GET_PRIMARY_SOURCE, params));
            if (promise) {
                promise.then(response => {
                    setSecondarySourceList([...response]);
                    settempSecondarySourceList([...response]);
                })
            }
            setSelectedPrimarySource(primarySource);
            setSelectedSecondarySource(null);
            setIsPrimarySourceEmpty(false);
        }else{
            let message = getLocalizedStrings().message.SOURCE.PRIMARY_SOURCE_CHANGE_VALIDATION;
            sfDialogs.alert(getLocalizedStrings().message.AUDIT_QUESTIONS.ALERT, message, () => UpdateSecondaryData(primarySource));
        }
    }

    const UpdateSecondaryData = (primarySource) =>{
        let params = {};
        params.primary_source =  primarySource;
        const promise = Promise.resolve(HTTPClient.get(endPoints.SOURCE.GET_PRIMARY_SOURCE, params));
        if (promise) {
            promise.then(response => {
                setSecondarySourceList([...response]);
                settempSecondarySourceList([...response]);
            })
        }
        setNewAddedSecondarySource([]);
        setSelectedPrimarySource(primarySource);
        setSelectedSecondarySource(null);
        setIsPrimarySourceEmpty(false);
    }

    const handleChangeSecondarySource = (event,newValue) =>{
        if(newValue?.id === -99999){
            if(selectedPrimarySource){
                let obj = {};
                obj.id = Math.floor(Math.random() * 9000 + 1000);
                obj.name = searchText;
                let _tempSecondarySource = tempSecondarySourceList;
                tempSecondarySourceList.push(obj);
                setSecondarySourceList([..._tempSecondarySource]);
                settempSecondarySourceList([..._tempSecondarySource]);
                let _newAddedSecondary = newAddedSecondarySource;
                _newAddedSecondary.push(searchText);
                setNewAddedSecondarySource([..._newAddedSecondary]);
                setSelectedSecondarySource(obj);
                setSearchText('');
                setIsSecondarySourceEmpty(false);
            }else{
                let msg = getLocalizedStrings().message.SOURCE.PRIMARY_SOURCE_EMPTY;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,msg, null);
            }
        }else{
            setSelectedSecondarySource(newValue);
            setSecondarySourceList([...tempSecondarySourceList]);
            setIsSecondarySourceEmpty(false);
        }
    }

    const handlecategoryOnFocus = (event) => {
        setCategoryShrink(true);
    }

    const handlecategoryOnBlur = () =>{
        setCategoryShrink(false);
        setSecondarySourceList([...tempSecondarySourceList]);
    }

    const SearchForObjectsWithName = (arrayOfVariable, sortValue) => {
        let ansArray = [];
        arrayOfVariable.map((data) => {
            let toAdd = false
            Object.values(data).forEach((value) => {
                if (value != null) {
                    let mainString = value.toString().replace(/\s/g, '').toLowerCase()
                    let subString = sortValue.replace(/\s/g, '').toLowerCase()
                    if (mainString.includes(subString)) {
                        toAdd = true
                    }
                }
            })
            if (toAdd) {
                ansArray.push(data)
            }
        })
        return (ansArray);
    }

    const  getAddOptonObject = (length, Id, Name) => {
        let temObj = new Object;
        temObj.id = Id;
        temObj.name = Name;
        return temObj;
    }

    const setCategoryChange = (value) =>{
        let _secondarySourceList = secondarySourceList;
        setSearchText(value);
        if (value != undefined && value.length > 0) {
            let searchString = value;
            if (searchString.replace(/\s/g, '') != '') {
                _secondarySourceList = SearchForObjectsWithName(tempSecondarySourceList, searchString);
            }
            if (_secondarySourceList.length === 0) {
                let tempList = [];
                let optionName = 'Add a New Secondary Source (' + value + ')';
                tempList.push(getAddOptonObject(_secondarySourceList.length,-99999, optionName));
                setSecondarySourceList([...tempList]);
            }else{
                setSecondarySourceList([..._secondarySourceList]);
            }
        }else{
            setSecondarySourceList([...tempSecondarySourceList]);
        }
    }

    const onChangeDesc =(event)=>{
        let value = event.target.value;
        setTaskDesc(value);
    }

    const getCustomFieldTypes = () => {
        let showNameField = true;
        let _corporate_currency = corporate_currency;
        let shrink = selectedSecondarySource != null ? true : categoryShrink;
        let label;
        if(object === constants.SOURCE_OBJECT && (formType === "Secondary Source" || formType ==="Secondary") && type === 'new'){
            showNameField = false;
        }
       if(object=== constants.TASKSLIST_OBJECT){
        label = getLocalizedStrings().label.COMMON.TASK_NAME;
       }
       else{
         label = getLocalizedStrings().label.SETTINGS[object.toUpperCase()] + ' ' + getLocalizedStrings().label.COMMON.NAME;
       }
        let textFieldWidth = appState.me.language ==='fr-FR'? '75%' :'80%';

        if (object === constants.CORPORATE_CURRENCY_OBJECT) {
            return (
                <div style={{ float: 'left', width: textFieldWidth }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        {_corporate_currency.length > 0 && <Autocomplete
                            // inputValue={currencyName}
                            defaultValue={_corporate_currency.find(v => v.value === name)}
                            options={_corporate_currency}
                            onChange={(e)=>handleChange(e)}
                            getOptionLabel={(option) => option.label}
                            disableClearable={false}
                            renderInput={(params) => <TextField {...params} label={getLocalizedStrings().label.CORPORATE_CURRENCY.SELECT_CURRENCY_NAME} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                        />
                        }
                    </FormControl>
                </div>
            );
        } else {
            return (
                <div style={{display:'inline'}}>
                    {object === constants.SOURCE_OBJECT && (formType === "Secondary Source" || formType ==="Secondary") && type === 'new' && <div>
                        <div className = "primary Source">
                            <TextField
                                id="Primary_source"
                                select
                                label="Primary Source"
                                size='small'
                                style={{width:'100%'}}
                                className={"sf-fields-bg"}
                                error ={isPrimarySourceEmpty}
                                onChange={(e)=>handleChangePrimarychange(e)}
                                value={selectedPrimarySource}
                                >
                                {sourceList?.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        {/* secondary source for source */}
                        <div className='Secondary Source' style={{marginTop:'10px'}}>
                            <Autocomplete
                                id="secondary_source"
                                sx={{ width: '100%' }}
                                size='small'
                                // inputValue={searchText}
                                options={secondarySourceList}
                                onChange={(e,newValue)=>handleChangeSecondarySource(e,newValue)}
                                onFocus={(event) => handlecategoryOnFocus(event)}
                                onBlur={() => handlecategoryOnBlur()}
                                value={selectedSecondarySource}
                                autoHighlight
                                autoFocus={isSecondarySourceEmpty}
                                error = {isSecondarySourceEmpty}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                    <Box
                                        key={key}
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...optionProps}
                                    >
                                        {option.name}
                                    </Box>
                                    );
                                }}
                               renderInput={(params) => <TextField  {...params}
                                    InputLabelProps={{
                                        shrink: shrink,
                                    }}
                                    autoFocus={isSecondarySourceEmpty} 
                                    error={isSecondarySourceEmpty} 
                                    onChange={(e) => setCategoryChange(e.target.value)} 
                                    label={'Secondary Source'} variant="outlined" margin="dense" />}
                            />
                        </div>
                    </div>}

                    {showNameField && <div style={{marginTop:'10px'}}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                key={id}
                                label={label}
                                fullWidth={true}
                                onChange={(e)=>handleChange(e)}
                                value={name}
                                autoFocus={autoFocus}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                inputprops={{ maxlength: '256' }}
                                className={"sf-fields-bg"}
                            /></FormControl>
                    </div>}
                </div>
            );
        }
    }
    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    let style = {};
    if (object === constants.CORPORATE_CURRENCY_OBJECT) {
        style.float = 'right';
        style.marginTop = -15;
    }else{
        style.marginTop = -15; 
    }
    if (mounted) {
        return (
            <div style={{ paddingLeft: '25px', paddingRight: '25px', marginTop: '10px' }}>
                <div className="row">
                    {getCustomFieldTypes()}

                  { object === constants.TASKSLIST_OBJECT && <div className='row'>
                    <div>
                        <FormControl style={{ width: '100%', paddingTop: '10px' , paddingBottom:'20px'}} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                type='textarea'
                                multiline={true}
                                label= {getLocalizedStrings().label.COMMON.DESC}
                                fullWidth={true}
                                name="description"
                                value={taskDesc}
                                minRows={3}
                                onChange={(event)=> onChangeDesc(event)}
                                textareastyle={{ marginTop: '10px'}}
                                style={{ }}
                                inputProps={{maxLength :2048}}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                </div>}
                    <div style={style}>
                        <Button
                            key='save'
                            onClick={()=>saveForm()}
                            style={{ ...styles.primaryButton, marginRight: '0px', verticalAlign: 'top',marginTop: "28px" }}
                            disabled={isSavedDisabled}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        {object !== constants.CORPORATE_CURRENCY_OBJECT && <Button
                            onClick={()=>closeDrawer()}
                            style={{ ...styles.secondaryButton, marginLeft: '6px', marginRight: '0px',marginTop: "28px" }}
                        ><span style={{ marginTop: object == constants.LOCATION_OBJECT?'-2px': ''}}> {getLocalizedStrings().label.COMMON.CANCEL}</span></Button>}

                    </div>
                </div>
            </div>
        );
    } else {
        return (<div style={{ width: '100%', height: pageHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>);
    }
}
export default SourceForm;