import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { FormControl, TextField, MenuItem, InputLabel, Select, Box } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, isEmpty, getStringParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';

export default function TemplateCategories({ object, data }) {
    const app = useSelector((state) => state.app)
    const dispatch = useDispatch();

    const [error, setError] = useState({})
    const [id, setId] = useState(0)
    const inputRef = useRef();
    const [mode, setMode] = useState("create");
    const [template, setTemplate] = useState({ category: '', status: 'Active', subcategory: '' })
    const [value, setValue] = useState([])
    let value1 = []

    useEffect(() => {
        if (data.id > 0) {
            getTemplateCategoryInfo();
        }
    }, [])
    const validateForm = () => {
        try {
            let valid = true;
            let newErrors = {};
            if (!isValidParam(template.category) || isEmpty(getStringParam(template.category))) {
                newErrors.category = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.TEMPLATE_CATEGORIES.TEMPLATE_CATEGORY_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (!isValidParam(template.status)) {
                newErrors.status = true;
                valid = false;
            } else if (!isValidParam(template.subcategory.trim()) || isEmpty(getStringParam(template.subcategory.trim()))) {
                console.log(value1,value)
                if (value1.indexOf(template.subcategory) > -1) {
                    newErrors.subcategory = true;
                    valid = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().label.TEMPLATE_CATEGORIES.TEMPLATE_SUBCATEGORY_EXISTS, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                newErrors.subcategory = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.TEMPLATE_CATEGORIES.TEMPLATE_SUBCATEGORY_REQUIRED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if(template.subcategory.length > 256) {
                newErrors.subcategory = true;
                valid = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().label.TEMPLATE_CATEGORIES.TEMPLATE_SUBCATEGORY_LENGTH, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            setError(newErrors);
            return valid;
        }
        catch (error) {
            console.error("Error in 'TemplateCategories.js' -> validateForm()':" + error);
        }
    }

    const getTemplateCategoryInfo = () => {
        let params = {};
        if (data.id > 0) {
            params = { "id": data.id }
        }
        let url = endPoints.TEMPLATE_CATEGORIES.GET + data.id
        let promise = Promise.resolve(HTTPClient.get(url, null));
        promise.then((response) => {
            if (response) {
                setValue(p => [...p, response.subcategory.trim()])
                value1.push(response.subcategory.trim())
                setTemplate({ category: response.category, status: response.status, subcategory: response.subcategory.trim() });
                setId(response.id);
            }
        });
    }
    const handleSubmit = () => {
        let isValidForm = validateForm();
        let newErrors = {};
        if (isValidForm) {
            let params;
            if (data.id > 0) {
                params = { "category": template.category, "status": template.status, "subcategory": template.subcategory.trim(), "id": data.id }
            } else {
                params = { "category": template.category, "status": template.status, "subcategory": template.subcategory.trim(), "id": 0 }
                setValue(p => [...p, template.subcategory.trim()]);
                value1.push(template.subcategory.trim());
                console.log(template.subcategory.length)
            }
            let promise = Promise.resolve(HTTPClient.post(endPoints.TEMPLATE_CATEGORIES.SAVE, params));
            console.log(params, value1, value)
            promise.then((response) => {
                console.log(response)
                if (response.status === 0) {
                     if (response.data) {
                        if (data.id > 0) {
                            dispatch(showCustomSnackBar("Template category edited successfully.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        } else {
                            dispatch(showCustomSnackBar("Template category created successfully.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                        dispatch(getAppDrawer(false, null, null, null, null));
                        refreshListView(object);
                        setTemplate({ category: '', status: 'Active', subcategory: '' });
                    } else {
                        dispatch(showCustomSnackBar("Subcategory name already exists", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            });
            setError(newErrors)
        }
    }

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const focusOntextfield = (element) => {
        inputRef.current = element;
        inputRef.current?.focus();
    }
    return (
        <div>
            <div className="row">
                <div className='col-sm-6'>
                    <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                    <FormControl style={{ width: '100%' }} size="small" className="test" noValidate autoComplete="off">
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={template.category}
                            label="Category"
                            onChange={(e) => setTemplate(p => ({ ...p, category: e.target.value }))}
                            fullWidth={true}
                            className={"sf-fields-bg"}
                            disabled={data.id > 0}
                        >
                            <MenuItem value='Sales'>Sales</MenuItem>
                            <MenuItem value='Marketing'>Marketing</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='col-sm-6'>
                    <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                    <FormControl style={{ width: '100%' }} size="small" className="test" noValidate autoComplete="off">
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select1"
                            value={template.status}
                            label="Status"
                            onChange={(e) => setTemplate(p => ({ ...p, status: e.target.value }))}
                            fullWidth={true}
                            className={"sf-fields-bg1"}
                        >
                            <MenuItem value='Active'>Active</MenuItem>
                            <MenuItem value='Inactive'>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='col-sm-12'>
                    <div style={{ fontSize: '12px', paddingBottom: '5px' }}></div>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            style={{ width: '100%' }}
                            variant="outlined"
                            name="subcategory"
                            id="subcategory"
                            label='Sub Category'
                            fullWidth={true}
                            value={template.subcategory}
                            onChange={(e) => setTemplate(p => ({ ...p, subcategory: e.target.value }))}
                            inputprops={{ maxlength: '256' }}
                            autoComplete="new-password"
                            margin='dense'
                            className={"sf-fields-bg"}
                            size="small"
                            error={Boolean(error.last_name)}
                            inputRef={Boolean(error.last_name) ? focusOntextfield : ''}
                        />
                    </FormControl>
                </div>

                <div style={{ float: 'right', alignItems: 'right', justifyContent: 'right', display: 'flex', paddingTop: '20px' }}>
                    <Button
                        onClick={handleSubmit}
                        style={{
                            ...styles.primaryButton,
                            marginRight: '8px',
                            height: '32px',
                        }}
                        title={getLocalizedStrings().label.COMMON.SAVE}>
                        {getLocalizedStrings().label.COMMON.SAVE}
                    </Button>
                    <Button
                        onClick={() => closeDrawer()}
                        style={{
                            ...styles.secondaryButton,
                            marginRight: '8px',
                            height: '32px',
                        }}
                        title={getLocalizedStrings().label.COMMON.CANCEL}>
                        {getLocalizedStrings().label.COMMON.CANCEL}
                    </Button>
                </div>
            </div>
        </div>
    )
}