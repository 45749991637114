import React,{ useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { TextField, FormControl, MenuItem, Button} from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { useDispatch, useSelector} from "react-redux";
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { refreshDashboard } from '../../../services/actions/dashboardAcions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';

const DashboardNewWidgetdDialog = ({data}) => {
    const { widget, isEditWidget, dashBoardId } = data;
    const dispatch = useDispatch();
    const [widgetName,setWidgetName] = useState("")
    const [reportOptions,setReportOptions] = useState([])
    const [width, setWidth] = useState('')
    const [selectedReport, setSelectedReport] = useState('');
    const dashboard = useSelector((state)=>state.dashboard)

    useEffect(()=>{
		getReportList()
        if (isEditWidget) {
            setWidgetName(widget.widgetName)
            setWidth(widget.w === 1 ? 50 : 100)
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
    
     //function get reportlist--
     const getReportList = () =>{     
        let params = { 
            dashboard_type: selectedReport.dashboard_type
         };

        let promise = Promise.resolve(HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_REPORTLIST_GET, params));
        promise.then((response) => {
            if (isEditWidget && response.length > 0) {
                const getReport = response.find((r) => r.reportid === String(widget.typeId))
                setSelectedReport(getReport) 
            }
            setReportOptions(response);
        })  
    }

    const handleWidgetName = (value) =>{
        setWidgetName(value);
    }

    const handleSelectReportChange = (value) => {
        setSelectedReport(value)
    };

    const handleWidthChange = (value) => {
        setWidth(value);
    };
    
    const addNewWidget = () => {
        if(widgetName === ""){
            dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.PROVIDE_WIDGETNAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;          
        } else if(selectedReport === '') {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.SELECT_REPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;     
        } else if(width === ""){
            dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.SELECT_WIDTH, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
        const params = isEditWidget ? {
            dashboard_id: widget.dashBoardId,
            id: widget.i,
            w: width === 50 ? 1 : 2,
            timeline: widget.timeline,
            status: "Active",
            widget_name: widgetName
        } : {
            widgetInfo: [{
                    i: 0,
                    id: 0,
                    dId: dashBoardId,
                    wId: 0,
                    x: 0,
                    y: 0,
                    h: 1,
                    w: width === 50 ? 1 : 2,
                    moved: false,
                    static: false,
                    isResizable: false,
                    isDraggable: false,
                    dashBoardId: dashBoardId,
                    order: 0,
                    timeline: 'tw',
                    objectId: selectedReport.formid,
                    status: "Active",
                    typeId: selectedReport.reportid,
                    type: "report",
                    widgetName: widgetName
                }
            ]
        }
        let url = isEditWidget ? endPoints.DASHBOARD.DASHBOARD_AVAILABLEWIDGETS_SAVE : endPoints.DASHBOARD.DASHBOARD_CHANGE_LAYOUT;
        
        const promise = Promise.resolve(HTTPClient.post(url,
            params
        ));
        promise.then((res) => {
            if(res.status === -1) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.WIDGET_NAME_DUPLICATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(refreshDashboard(true))
                dispatch(getAppDialog(false,null,null,null,null,null))
                if(isEditWidget) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.WIDGET_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.WIDGET_ADDED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            } 
        })
        .catch((err) => console.log('err==>', err))
    }

    

    const getActionButtons = () =>{
        const actions = [
            <Button
                primary={true}
                onClick={() => {
                    addNewWidget()
                }}
                style={styles.primaryButton}
            > {isEditWidget ? getLocalizedStrings().label.COMMON.SAVE : getLocalizedStrings().label.COMMON.ADD}</Button>,
            <Button
                primary={true}
                onClick={() => dispatch(getAppDialog(false, null, null, null, null, null))}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }

    return(
        <div style={{ width: '100%', height: '100%', display: 'inline-block' }}>
            {/* Widget Name */}
            <div className="col-sm-12 col-two" style={{ marginBottom: '15px' }}>
                <FormControl
                    style={{ width: '100%' }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        variant="outlined"
                        value={widgetName}
                        onChange={(event) => handleWidgetName(event.target.value)}
                        label={getLocalizedStrings().label.DASHBOARD.WIDGET_NAME}
                        fullWidth
                        size="small"
                        margin="dense"
                        className="sf-fields-bg"
                        inputProps={{ maxLength: 128 }}  // Set maxLength here
                    />
                </FormControl>
            </div>

        {/* Select Report */}
        <div className="col-sm-12 col-two" style={{ marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    variant="outlined"
                    name="selectReport"
                    id="selectReport"
                    label={getLocalizedStrings().label.DASHBOARD.SELECT_REPORT}
                    fullWidth
                    value = {selectedReport}
                    onChange={(event) => handleSelectReportChange(event.target.value)}
                    margin="dense"
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: { 
                                    maxHeight: 400, 
                                    overflowY: 'auto', 
                                    scrollBehavior: 'smooth',  
                                    overscrollBehaviorY: 'contain',  
                                    '-webkit-overflow-scrolling': 'touch',
                                },
                            },
                        },
                    }}
                    disabled={isEditWidget}
                    
                >
                    {reportOptions?.map((report) => (
                        <MenuItem key={report.reportid} value={report}>
                            {report.name}
                        </MenuItem>
                    ))}

                </TextField>
            </FormControl>
        </div>

        {/* Width */}
        <div className="col-sm-12 col-two" style={{ marginBottom: '15px' }}>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    variant="outlined"
                    name="width"
                    id="width"
                    label={getLocalizedStrings().label.DASHBOARD.WIDTH}
                    fullWidth
                    value={width}
                    onChange={(event) => handleWidthChange(event.target.value)}
                    margin="dense"
                    size="small"
                    select
                    InputLabelProps={{ shrink: true }} 
                    className="sf-fields-bg"
                >
                    <MenuItem value={50}>50%</MenuItem>
                    <MenuItem value={100}>100%</MenuItem>
                </TextField>
            </FormControl>
        </div>

        {/* Action Buttons */}
        <div style={{ float: 'right', marginTop: '25px' }}>
            {getActionButtons()}
        </div>
    </div>
    )
} 

export default DashboardNewWidgetdDialog;