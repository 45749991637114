import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Step, Stepper, StepLabel, StepButton } from '@mui/material';
import { getArrayParam } from '../../../services/helper/parameterVerifier';
import Button from '@mui/material/Button';
import { getImportFormInfo } from '../../../services/actions/importAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import ImportFileDetails from '../components/importFileDetails';
import ImportMappingFields from '../components/importMappingFields';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { styles } from '../../../services/constants/styles';
import { Opacity } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

const StyledStep = styled('div')(({ theme, status }) => ({
	zIndex: 1,
	color: '#fff',
	backgroundColor:'#717171',
	width: 22,
	height: 22,
	display: 'flex',
	borderRadius: '50%',
    fontSize:12,
	justifyContent: 'center',
	alignItems: 'center',
	...(status.active && {
	  color:'white',
	  backgroundColor: localStorage.getItem('background'),
	}),
  }));

function ColoredStep(props) {
	const { active, completed, className } = props;
	return (
	  <StyledStep status={{ completed, active }} className={className}>
		{props.icon}
	  </StyledStep>
	);
  }
const Import = ({ object }) => {
    const dispatch = useDispatch();
    const sfForm = useSelector((state) => state.sfForm);
    const app = useSelector((state) => state.app);
    const [objectName, setobjectName] = useState(null);
    const [steps, setsteps] = useState(null);
    const [tempStepsArr, settempStepsArr] = useState(['Select Source', 'Map Fields & Import']);
    const [stepIndex, setstepIndex] = useState(0);
    const [isImportMounted, setisImportMounted] = useState(false);
    const [finished, setfinished] = useState(false);
    const [file, setfile] = useState({});
    const [importData, setimportData] = useState(null);
    const [checkBox, setcheckBox] = useState(true);
    const [importInfo, setimportInfo] = useState({
        groupId: 0,
        status: null,
        groupName: null,
        workflowId: 0,
        workflowName: null,
        groupLink: 0,
        source: null,
        secondarySource:'',
        tableList: [],
        checkBox: false,
        statusId: 0,
        assignRule: "0"
    });
    const [errorMsg, seterrorMsg] = useState('');
    // Import.propTypes = {
    //     data: PropTypes.object,
    //     object: PropTypes.string,
    //     call_from: PropTypes.string
    // };      
    useEffect(() => {
        if (object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
            || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT) {
            setcheckBox(false);
        }
    }, []);
    useEffect(() => {
        generateStepper();
    }, [stepIndex]);


    const generateStepper = () => {
        let importSteps = null;
        let themeColor = app.me.background;
        //multilingual
        let stepsMap = {};
        stepsMap['Select Source'] = getLocalizedStrings().label.IMPORT.STEP_1;
        stepsMap['Map Fields & Import'] = getLocalizedStrings().label.IMPORT.STEP_2
        if (getArrayParam(tempStepsArr)) {
            let localStepIndex = stepIndex;
            importSteps = tempStepsArr.map((stage, index) => {

                //multilingual
                stage = stepsMap[stage];

                if (index === localStepIndex) {
                    localStepIndex = index;
                    return (
                        <Step>
                            <StepLabel
							StepIconComponent={ColoredStep}
                            >
                                {<div id={index} style={{ color: themeColor, fontWeight: 'bold' }}>{stage}</div>}
                            </StepLabel>
                        </Step>
                    );
                } else {
                    if (index > localStepIndex && localStepIndex > 0) {
                        return (
                            <Step>
                                <StepButton value={stage}>
                                    {<div id={index} style={{ color: '#000' }}>{stage}</div>}
                                </StepButton>
                            </Step>
                        );
                    } else {
                        return (
                            <Step>
                                <StepButton value={stage}>
                                    {<div id={index} style={{ color: '#717171' }}>{stage}</div>}
                                </StepButton>
                            </Step>
                        );
                    }
                }
            });
            setstepIndex(localStepIndex);
            setsteps(importSteps);
            setisImportMounted(true);
        }
    }

    const getStepContent = (stepIndexValue) => {
        switch (stepIndexValue) {
            case 0:
                return (
                    <ImportFileDetails object={object} setImportFileDetail={(file,importInfoValue)=>setImportFileDetail(file,importInfoValue)} />
                );
            case 1:
                return (
                    <ImportMappingFields object={object} importInfo={importInfo} importData={importData} />
                );

            default:
                return 'Soffront Import Module';
        }
    }

    const setImportFileDetail = (file, importInfoValue) => {
        setfile(file);
        if (importInfoValue !== null && importInfoValue !== undefined) {
            let tempInfo = importInfo;
            tempInfo.groupId = importInfoValue.groupId;
            tempInfo.workflowId = importInfoValue.workflowId;
            tempInfo.workflowName = importInfoValue.workflowName;
            tempInfo.status = importInfoValue.status;
            tempInfo.source = importInfoValue.source;
            tempInfo.secondarySource = importInfoValue.secondarySource;
            tempInfo.checkBox = importInfoValue.checkBox;
            tempInfo.statusId = importInfoValue.statusId;
            tempInfo.assignRule = importInfoValue.assignRule;
            if(object === constants.SALES_LEADS_OBJECT){
                tempInfo.importTag = "import_" + new Date().valueOf().toString();
            }
            setimportInfo(tempInfo);
        }
        if (object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.SALES_LEADS_OBJECT) {
            if (importInfo.checkBox === true) {
                setcheckBox(false);
            } else if (importInfo.checkBox === false) {
                setcheckBox(true);
            }
        }
    }

    const handleNext = () => {
        if (stepIndex === 0) {
            if (validateImport()) {
                const formData = new FormData();
                formData.append("file", file.file);
                formData.append("input_param", JSON.stringify({ "object_name": object }));
                let promise = Promise.resolve(getImportFormInfo(formData));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response.data.status === 0) {

                                setimportData(response);
                                let tempStepIndex = stepIndex + 1
                                setstepIndex(tempStepIndex);
                                setfinished(tempStepIndex >= 2);
                                seterrorMsg(response.data.error.message)

                            } else if (response.data.status === -1) {
                                seterrorMsg(response.data.error.message);
                            }
                        }
                    });
                }
            }
        }

    };

    const validateImport = () => {
        let fileName = file.fileName;
        let groupId = importInfo.groupId;
        let workflowId = importInfo.workflowId;
        let status = importInfo.status;
        let source = importInfo.source;

        let isValid = false;
        isValid = validateFile(fileName);

        if (isValid) {
            if ((object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT) && (groupId === 0 || groupId === -1)) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_GROUP, null);
                return false;
            } else if ((object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT
                || object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT
                || object === constants.CUSTOM_TABLE4_OBJECT) && workflowId === 0) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_WORKFLOW, null);
                return false;
            } else if (status === null || status === undefined) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_STATUS, null);
                return false;
            } else if ((object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.SALES_LEADS_OBJECT) && (source === null || source === undefined || source === "-1" || source === "-222")) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_SOURCE, null);
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    const validateFile = (fileName) => {
        let isValid = false;
        if (fileName === null || fileName === undefined) {
            isValid = false;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.SELECT_FILE, null);
        } else if (fileName !== null) {
            if (fileName.lastIndexOf(".csv") !== -1 || fileName.lastIndexOf(".xls") !== -1 || fileName.lastIndexOf(".xlsx") !== -1 || fileName.lastIndexOf(".XLSX") !== -1 || fileName.lastIndexOf(".XLS") !== -1) {
                isValid = true;
            } else {
                isValid = false;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.IMPORT.IMPORT_FILE_EXT, null);
            }
        }
        return isValid;
    }

    if (!isImportMounted) {
        generateStepper();
    }
    let opacity = 1
    if (checkBox) {
        opacity = 0.4
    }
    return (
        <div >
                <div style={{ width: '97%', overflow: 'hidden', paddingLeft:'35px',marginTop:'24px' }} id="importStepper">
                <Stepper nonLinear activeStep={stepIndex} style={{ backgroundColor: '#f5f5f5' }} >
                    {steps}
                </Stepper>
            </div>
            <div>{getStepContent(stepIndex)}</div>
            <div style={{ marginBottom: "7%" }}>
                {finished ? (
                    <p>
                        <a
                            href="#"
                            onClick={(event) => {
                                event.preventDefault();
                                setstepIndex(0);
                                setfinished(false);
                            }}
                        >
                            Click here
                        </a> to reset the example.
                    </p>
                ) : (
                    <div>
                        <div style={{paddingLeft: 28 , marginTop: object === constants.CONTACTS_OBJECT ? '30px': object === "customobjectthree" || object === "customobjecttwo" || object === "customobjectone"? '15px': ''}}>
                            {
                                stepIndex === 0 ?
                                    <Button
                                        id="Select"
                                        primary={true}
                                        onClick={()=>handleNext()}
                                        disabled={
                                            checkBox}

                                        style={{ ...styles.primaryButton, opacity: opacity , }}
                                    >{
                                            stepIndex === 2 ? getLocalizedStrings().label.IMPORT.FINISH : getLocalizedStrings().label.IMPORT.NEXT}</Button>
                                    : null}
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginLeft: '20px', color: 'red' }}>{
                errorMsg}</div>
        </div>
    );

}

// Import.propTypes = {
//     data: PropTypes.object,
//     object: PropTypes.string,
//     call_from: PropTypes.string
// };

export default Import;


