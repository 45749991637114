 const message = {
    COMMON: {
        NO_ACTIONS_AVAILABLE: 'Kein Objekt für die Aktion "Schnell hinzufügen" verfügbar',
        NOTES_REMOVE_MESSAGE:'Ausgewählte Notiz(en) werden gelöscht. Bist du sicher?',
        SELECTED_NOTE_MESSAGE:'Um diese Aktion auszuführen, muss mindestens eine Notiz ausgewählt sein.',
        BR_WINDOW_OPEN_CONFORMATION: 'Wir unterstützen keine Drag & Drop-Funktion für Kampagnen. Möchten Sie die Kampagne eröffnen?',
        CONFIRM_DIALOG_TITLE: 'Bestätigungsdialog',
        ALERT_DIALOG_TITLE: 'Alarmdialog',
        XMS_SUCCESS_ALERT_DIALOG_TITLE: 'Erfolg',
        XMS_ERROR_ALERT_DIALOG_TITLE: 'Fehler',
        SAVE: 'Erfolgreich gespeichert.',
        MERGE: 'Erfolgreich zusammengeführt.',
        UPDATE: 'Erfolgreich geupdated.',
        DELETE: 'Erfolgreich gelöscht.',
        REMOVE: 'Erfolgreich entfernt.',
        UNLINK: 'Erfolgreich getrennt.',
        COPY_EMAIL:'Die E-Mail-ID wurde in Ihre Zwischenablage kopiert',
        COPY_MOBILE:'Die Handynummer wurde in Ihre Zwischenablage kopiert.',
        COPY_PHONE:'Die Telefonnummer wurde in Ihre Zwischenablage kopiert.',
        EMAIL_VALIDATION:'Bitte geben Sie eine gültige Email-Adresse ein.',
        EMPTY_EMAIL:'Es ist keine E-Mail zum Kopieren vorhanden',
        EMPTY_MOBILE:'Es ist kein Mobiltelefon zum Kopieren vorhanden',
        EMPTY_PHONE:'Es ist kein Telefon zum Kopieren vorhanden',
        ERROR: 'System reagiert nicht.',
        ACCESS_DENIED: 'Leider haben Sie nicht die Berechtigung, diesen Vorgang auszuführen. Bitte wenden Sie sich an Ihren Administrator.',
        NOTIFICATION_INFO_EMPTY: 'Keine Benachrichtigung verfügbar',
        /** START:  Validation message */
        BLANK: 'leer',
        EMAIL: 'ist eine ungültige E-Mail-Adresse.',
        VALID_EMAIL: 'Die von Ihnen eingegebene E-Mail-Adresse scheint keine gültige Adresse zu sein. Bitte versuche es erneut. Hinweis: Stellen Sie sicher, dass die E-Mail-Adresse das E-Mail-Format \\ @ domain.com hat.',
        URL: 'ist eine ungültige URL.',
        VALID_URL: 'Bitte geben Sie eine gültige URL ein.',
        INTEGER: 'ist keine ganze Zahl.',
        VALID_INTEGER: 'Bitte geben Sie eine gültige Ganzzahl ein.',
        FLOAT: 'ist kein Schwimmer.',
        VALID_FLOAT: 'Bitte geben Sie einen gültigen Float ein.',
        VALID_PHONE: 'Telefonnummern müssen numerisch sein !!',
        VALID_LIST: 'Bitte wählen Sie einen Wert.',
        TOO_BIG_SIZE: 'Der eingegebene Wert ist zu groß für dieses Feld. Geben Sie einen niedrigeren Wert ein.',
        NO_RECORD: 'Kein Eintrag gefunden.',
        VALIDATE_BLANK: 'darf nicht leer sein.',
        VALIDATE_INTEGER: 'muss eine ganze Zahl sein.',
        VALIDATE_DECIMAL: 'muss dezimal sein.',
        VALIDATE_POSITIVE_DECIMAL: 'muss größer als 0 und in Dezimalheit sein.',
        VALIDATE_URL: 'Ungültiges URL-Format. Beispiel - www.soffront.com',
        VALIDATE_EMAIL: 'Ungültiges Email-Format.',
        VALUE: 'Wert',
        LIBRARY_DIALOG_TITLE: 'Soffront Online-Bibliothek',
        BLANK_EMAIL: 'E-Mail ist leer',
        INVALID_EMAIL: 'Bitte geben Sie eine gültige E-Mail an!',
        BLANK_NUMBER : 'Die Nummer ist leer',
        INVALID_NUMBER :'Bitte geben Sie eine gültige Nummer an',
        EMPTY_SEARCH_CRITERIA: 'Bitte geben Sie Suchkriterien ein',
        VALIDATE_MORE_CHECKBOX_SELECTION: 'Sie können die Suche nicht mit <*> durchführen, wenn mehr als ein Kontrollkästchen aktiviert ist!',
        VALIDATE_NO_CHECKBOX_SELECTION: 'Sie können die Suche nicht mit <*> durchführen, wenn mehr als kein Kontrollkästchen aktiviert ist!',
        NO_DATA: 'Keine Daten gefunden',
        DISCONNECT_ALERT_MSG: 'Sind Sie sicher, diesen Client zu trennen?',
        DISCONNECT_SUCCESS: 'Sie haben Ihren Client erfolgreich getrennt.',
        CONFIRM_DELETE_RECORD: 'Datensatz wird gelöscht. Bist du sicher?',
        DELETE_SUCCESS: 'wurde erfolgreich gelöscht.',
        COULDNOT_LOCATE: "Wir konnten nicht finden",
        DELETED_OR_ACCESS_DENIED: "Es wurde möglicherweise gelöscht oder Sie haben möglicherweise keinen Zugriff darauf oder Sie haben es bereits geöffnet.",
        CLICK: 'Klicken',
        HERE: 'Hier',
        MERGE_ALERT_1: 'Sind Sie sicher, zusammenzuführen?',
        WITH: 'mit',
        MERGE_ALERT_2: 'und löschen',
        DATA_ACCESS_REQUEST_SEND_SUCCESS: 'Datenzugriffsanfrage gesendet an',
        DATA_ACCESS_REQUEST_SEND_FAILURE: 'Datenzugriffsanforderung kann nicht an gesendet werden',
        REQUEST_DATA_ACCESS: 'Datenzugriff anfordern von',
        WE_WILL_EMAIL: 'Wir werden eine E-Mail senden',
        AT: 'beim',
        TO_APPROVE_REQUEST: 'um Ihre Anfrage zu genehmigen',
        HAVE_BEEN: 'gewesen sein',
        REMINDER_VALUE: 'Der Erinnerungswert darf nicht leer sein',
        DUPLICATE_EMAIL: 'Sie haben bereits Datensätze mit derselben E-Mail-ID. Klicken Sie auf OK, um fortzufahren, oder auf Abbrechen, um die E-Mail-ID zu ändern.',
        DUPLICATE_CONTACT_EMAIL: 'Sie haben bereits Datensätze mit derselben E-Mail-ID. Bitte geben Sie eine andere E-Mail-ID ein.',
        CLIENT_CONNECT_MESSAGE: 'Dieser Kunde hat kein Konto mit dieser E-Mail in Soffront Online. Bitten Sie den Kunden zunächst, ein Konto mit derselben E-Mail-Adresse zu erstellen.',
        BLANK_CAMPAIGN_TYPE: 'Der Kampagnentyp darf nicht leer sein.',
        DUPLICATE_CAMPAIGN_TYPE: 'Der Kampagnentyp ist bereits vorhanden.',
        CAMPAIGN_TYPE_SAVE_SUCCESS: 'Kampagnentyp erfolgreich gespeichert.',
        /** END:  Validation message */
        STEPPER_BACK_MESSAGE: 'Möchten Sie zur Vorlageninfoseite gehen? Nicht gespeicherte Änderungen gehen verloren.',
        CENCEL_MOSAICO_MESSAGE: 'Möchten Sie wirklich abbrechen, ohne zu speichern?',
        NO_RESULTS_FOUND: 'keine Ergebnisse gefunden',
        FOR: 'zum',
        ENABLE_MAILING_SUCCESS: 'E-Mail-Versand erfolgreich aktiviert',
        DISABLE_MAILING_SUCCESS: 'Das Versenden von E-Mails wurde erfolgreich deaktiviert',
        MAILING_FAILURE: 'Dieses Konto ist kein CRM-Mandant',
        TEST: 'Prüfung',
        SELECT_RECORD_FOR_DELETE: 'Bitte wählen Sie einen zu löschenden Datensatz aus.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Bitte wählen Sie einen Datensatz zum Bearbeiten aus.',
        SELECT_RECORD_FOR_DELETE_ONE_RECORD: 'Bitte wählen Sie einen Datensatz zum Löschen aus.',
        ASSETMENU_DIALOG_TITLE: 'Wählen Sie ein Asset aus',
        EMPTY_RECYCLE_BIN_MSG: 'Bitte deaktivieren Sie die Datensätze, um einen leeren Recyle-Bin auszuführen.',
        UNIT_SELECT_ALERT_MESSAGE: 'Sie können jeweils eine Einheit auswählen.',
        LOOKUP_MULTI_SELECT: 'Bitte wählen Sie nur einen Datensatz aus.',
        LOOKUP_SELECT_A_RECORD: 'Bitte wählen Sie einen Datensatz aus.',
        TITLE_FIELD_BLANK: 'Das Titelfeld darf nicht leer sein',
        BOTH_COMPANY_AND_WORKFLOW_SELECTED: 'Sie haben sowohl das Unternehmen als auch den Workflow ausgewählt.',
        VALIDATE_ZERO: 'kann nicht Null sein.',
        GREENLIGHT_EXPORT_NO_RECORD: 'Für diesen Zeitraum gibt es im Greenlight-Workflow keine Konten.',
        WITHOUT_SAVE_MESSAGE: 'Möchten Sie weitergehen, ohne die Änderungen zu speichern?',
        DELETE_API_KEY: 'Dadurch wird Ihr API-Schlüssel in CRM gelöscht. Möchten Sie fortfahren ?',
        API_KEY_MSG_1: 'Die api Schlüssel ist ein eindeutiger verschlüsselten String zugewiesen',
        API_KEY_MSG_2: 'identifiziert, der Benutzer macht einen API-Aufruf. Dies ersetzt Grund Benutzername / Passwort-Authentifizierung.',
        API_KEY_MSG_3: 'Generieren API Key!',
        API_KEY_MSG_4: 'Generiert API Key',
        SOFFRONT: 'Soffront',
        UNIT_ALERT: 'Bitte wählen Sie einen Datensatz aus, um sich anzumelden.',
        THIS_FIELD_CANNOT_BLANK: 'Dieses Feld darf nicht leer sein.',
        CANNOT_DELETE_ALL_ITEM: 'Sie müssen mindestens ein Element in jedem Modul im Desktop-Menü haben.',
        DUPLICATE_MSG_1: 'Sie haben bereits Eintragung (en) mit der gleichen ',
        DUPLICATE_MSG_2: '. Klicken Sie auf OK, um fortzufahren, oder auf Abbrechen, um die Änderung ',
        DUPLICATE_MSG_3: ' und ',
        DUPLICATE_CONTACT_EMAIL_1: 'Sie haben bereits Eintragung (en) mit der gleichen ',
        DUPLICATE_CONTACT_EMAIL_2: '. Bitte geben Sie unterschiedliche ',
        INVALID_TIME_ERROR_MSG: 'Bitte geben Sie eine gültige Zeitintervall.',
        DOESNT_HAVE_ASSOCIATED_1: 'Diese ',
        DOESNT_HAVE_ASSOCIATED_2: " hat keinen zugeordneten Kontakt.",
        PHONE_CALL_NOT_ENABLED: 'Telefonanruf ist nicht aktiviert.',
        NOT_CONFG_CALL_SETUP: 'Sie haben den Anrufaufbau nicht konfiguriert.',
        NOT_CONFG_CALL_SMS_SETUP: 'Sie haben das Anruf- / SMS-Setup nicht konfiguriert.',
        SEARCH_NO_CHECKBOX_SELECTION: 'Sie können keine Suche durchführen, wenn kein Kontrollkästchen aktiviert ist!',
        VALID_SOURCE_LIST: 'Bitte wählen Sie das Quellfeld aus.',
        LMS_USER_NOT_AVAILABLE: 'LMS ist noch nicht aktiviert. Bitte wenden Sie sich an Ihren Administrator.',   
        ADD_BRANCH_BILLINGMASSAGE:'Sie haben derzeit keine Lizenzen. Sie erwerben eine neue Lizenz für dieses Gerät zum Preis von 40,00 $/Monat. Zusätzlich wird eine Einrichtungsgebühr von 100,00 $ für die Einrichtung und Schulung des neuen Mieters erhoben.',
        NO_RECORDS_FOR_PRINT:'Kein Datensatz zum Drucken verfügbar.',
    },
    SALES_TEMPLATE: {
        NAME_BLANK: 'Bitte geben Sie einen Namen ein, um diese Vorlage zu identifizieren.',
        SUBJECT_BLANK: 'Bitte geben Sie einen Betreff für diese Vorlage ein.',
        DUPLICATE_NAME: 'Sie haben bereits eine Vorlage mit dem Namen %%. Doppelte Vorlagen werden nicht unterstützt. Bitte geben Sie einen anderen Namen ein und versuchen Sie es erneut.',
        DELETE: 'Vorlage wird gelöscht. Bist du sicher?',
        TEMPLATE_DELETED: 'Vorlage erfolgreich gelöscht',
        TEMPLATE_NAME_ERROR: 'Sie haben bereits eine Vorlage mit diesem Namen. Bitte geben Sie einen anderen Namen ein und versuchen Sie es erneut.',
    },
    AGREEMENT_TEMPLATE: {
        NAME_BLANK: 'Bitte geben Sie einen Namen ein, um diese Vorlage zu identifizieren.',
        // SUBJECT_BLANK: 'Please enter a subject for this template.',
        DUPLICATE_NAME: 'Sie haben bereits eine Vorlage mit dem Namen %%. Doppelte Vorlagen werden nicht unterstützt. Bitte geben Sie einen anderen Namen ein und versuchen Sie es erneut.',
        DELETE: 'Vorlage wird gelöscht. Bist du sicher?',
        TEMPLATE_DELETED: 'Vorlage erfolgreich gelöscht',
        TEMPLATE_NAME_ERROR: 'Sie haben bereits eine Vorlage mit diesem Namen. Bitte geben Sie einen anderen Namen ein und versuchen Sie es erneut.',
        DUPLICATE_TEMPLAE_EXIST: 'Dieser Vorlagenname ist bereits vorhanden.',
        RECORD_NOT_EXIST: 'Dieser Datensatz ist nicht vorhanden.',
        SAVE_TO_DOWNLOAD: 'Sie haben die Vertragsvorlage noch nicht gespeichert. Bitte speichern Sie zuerst die Vertragsvorlage, um sie herunterzuladen.',
        TEMPLATE_CAN_NOT_EMPTY: 'Der Name der Vertragsvorlage darf nicht leer sein.',
        SELECT_TEMPLATE: 'Bitte wählen Sie eine Vorlage.',
        SENT_OR_SIGNED: 'Sie haben diese Vereinbarung bereits einmal gesendet. Sie können es nicht erneut senden.',
        SIGNATURE_TAGS_NOT_SEQUENCED_1: 'Bitte fügen Sie die Signatur-Tags der Reihe nach hinzu.',
        SIGNATURE_TAGS_NOT_SEQUENCED_2: 'Anscheinend haben Sie beim Hinzufügen des Signatur-Tags die Reihenfolge übersprungen.',
        SIGNATURE_TAGS_MISSING: 'Im Vertragstext ist kein Signatur-Tag verfügbar. Der Vertragstext sollte mindestens ein Signatur-Tag haben.',
        SIGNATURE_TAGS_CONTACT_FIELDS_COMBO_MISSING: 'Anscheinend haben Sie Briefvorlagenfelder für eine Signatur verwendet, ohne das Signatur-Tag zu verwenden. Bitte fügen Sie das Signatur-Tag hinzu, um fortzufahren.',
        AGREEMENT_EDIT: 'Sie können die Bearbeitung nur an Vereinbarungsdatensätzen durchführen, nicht an anderen Anhangsdatensätzen oder bereits gesendeten Vereinbarungsdatensätzen.',
        AGREEMENT_EXPIRED_1: 'Diese Vereinbarung ist am abgelaufen ',
        AGREEMENT_EXPIRED_2: 'Bitte verlängern Sie das Ablaufdatum der Vereinbarung, bevor Sie sie absenden.',
    },
    SMS_TEMPLATE: {
        BLANK_MESSAGE: 'Bitte geben Sie eine Nachricht ein, um diese Vorlage zu speichern.',
        SAVE_MESSAGE: 'SMS erfolgreich gespeichert.'
    },
    MARKETING_TEMPLATE: {
        NAME_BLANK: 'Bitte geben Sie einen Namen ein, um diese Vorlage zu identifizieren.',
        SUBJECT_BLANK: 'Bitte geben Sie einen Betreff für diese Vorlage ein.',
        DUPLICATE_NAME: 'Sie haben bereits eine Vorlage mit dem Namen %%. Doppelte Vorlagen werden nicht unterstützt. Bitte geben Sie einen anderen Namen ein und versuchen Sie es erneut.',
        DELETE: 'Vorlage wird gelöscht. Bist du sicher?',
        BACK: 'Wenn Sie zurückkehren, verlieren Sie Ihre nicht gespeicherten Änderungen. Möchten Sie fortfahren?',
        SELECT_DESIGN: 'Wählen Sie aus einem Design',
        TEMPLATE_UPDATE: 'Marketingvorlage (n) aktualisiert',
        CONFIRM1: 'von',
        CONFIRM2: 'Datensätze werden auf aktualisiert',
        CONFIRM3: 'Bist du sicher?',
        ALERT1: 'aus',
        FROM_NAME_BLANK: 'Bitte geben Sie einen from-Namen ein.',
        FROM_EMAIL_BLANK: 'Bitte wählen Sie eine aus E-Mail.',
        DUPLICATE_TEMPLATE_NAME: 'Der Vorlagenname ist bereits vorhanden.'
    },
    BROADCAST_CAMPAIGNS: {
        NAME_BLANK: 'Bitte geben Sie einen Namen ein, um diese Broadcast-Kampagne zu identifizieren.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Bitte auswählen',
        FILTER_TYPE_GROUP: 'eine Gruppe',
        FILTER_TYPE_CONTACT: 'ein Kontaktfilter',
        FILTER_TYPE_ACCOUNT: 'ein Kontofilter',
        SUBJECT_BLANK: 'Bitte geben Sie einen Betreff ein',
        FROM_NAME_BLANK: 'Bitte geben Sie einen Von-Namen ein',
        FROM_EMAIL_BLANK: 'Bitte wählen Sie eine Von E-Mail',
        GROUP_NAME_BLANK: 'Gruppenname darf nicht leer sein!',
        SAVE: 'Sie haben diese Kampagne erfolgreich erstellt.',
        UPDATE: 'Sie haben diese Kampagne erfolgreich aktualisiert.',
        GROUP_NAME_SIZE_LIMIT: 'Der Gruppenname darf nicht länger als 128 Zeichen sein.',
        EXECUTION_CANNOT_CANCEL_1: 'Die Ausführung kann jetzt nicht abgebrochen werden.',
        EXECUTION_CANCEL_MSG: 'Die Anwendung wird versuchen, die Ausführung sofort abzubrechen.',
        CONFIRM: 'Bitte bestätigen.',
        EXECUTION_CANNOT_CANCEL_2: 'Eine Kampagne, die bereits in Bearbeitung ist, kann nicht abgebrochen werden.',
        PURCHASE_MORE: 'um mehr zu kaufen.',
        CREDITS_REMAINING: 'E-Mail-Credits verbleiben.',
        VERIFY_BLANK_EMAIL: 'Sie müssen eine E-Mail-Adresse eingeben, um dies zu überprüfen.',
        VERIFY_INVALID_EMAIL: 'ist eine ungültige E-Mail-Adresse.',
        SEND_VERIFICATION_EMAIL_SUCCESS: 'Wir haben die Bestätigungs-E-Mail gesendet. Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf Diese E-Mail-Adresse überprüfen. Kommen Sie dann zurück und klicken Sie auf das Aktualisierungssymbol, um Ihre neue E-Mail-Adresse anzuzeigen.',
        EMAIL_INTEGRATION_NEW: 'Diese E-Mail-Adresse wurde bereits zur Überprüfung übermittelt! Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf Diese E-Mail-Adresse überprüfen. Kommen Sie dann zurück und klicken Sie auf das Aktualisierungssymbol, um Ihre neue E-Mail-Adresse anzuzeigen.',
        EMAIL_INTEGRATION_VERIFIED: 'Diese E-Mail-Adresse wurde bereits verifiziert!',
        BD_NAME_COPY_ERROR: 'Sie haben eine Kampagne mit diesem Namen. Bitte geben Sie einen anderen Namen für diese Kampagne ein',
        STEPPER_BACK_MESSAGE: 'Möchten Sie zur Broadcast-Infoseite gehen? Nicht gespeicherte Änderungen gehen verloren.'
    },
    SPACE: ' ',
    LIST_VIEW: {
        SELECT_RECORD: 'Bitte wählen Sie einen Datensatz aus, um weitere Aktionen auszuführen.',
        SELECT_RECORD_FOR_CONVERT: 'Bitte wählen Sie einen Datensatz aus, der konvertiert werden soll.',
        SELECT_RECORD_FOR_EDIT: 'Bitte wählen Sie einen Datensatz zum Bearbeiten aus.',
        SELECT_RECORD_FOR_COPY: 'Bitte wählen Sie einen zu kopierenden Datensatz aus.',
        SELECT_RECORD_FOR_SEND_EMAIL: 'Bitte wählen Sie einen Datensatz aus, um eine E-Mail zu senden.',
        MULTIPLE_RECORD_FOR_EDIT: 'Bitte wählen Sie einen Datensatz zum Bearbeiten aus.',
        MULTIPLE_RECORD_FOR_COPY: 'Bitte wählen Sie einen Datensatz zum Kopieren aus.',
        MULTIPLE_RECORD_FOR_MORE: 'Bitte wählen Sie einen Datensatz für MEHR aus.',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Bitte wählen Sie einen Datensatz zum Kopieren aus.',
        CANNOT_COPY_APPOINTMENT_WORKFLOW: 'Sie können diesen Workflow nicht kopieren.',
        SELECT_RECORD_FOR_ATTACH_ONE_RECORD: 'Bitte wählen Sie einen einzelnen Anhang.',
        SELECT_MAXIMUM_FIELD_FOR_SETUP: 'Sie haben die maximale Anzahl von insgesamt 15 ausgewählten Feldern erreicht.',
        CONVERT_CONTACT_TO_ACCOUNT_CONFIRM: 'Dieser Kontakt ist bereits einem Konto zugeordnet. Klicken Sie auf OK, um die Details des Kontos anzuzeigen.',
        SHARE_PERMISSION: 'Sie haben nicht die Berechtigung, diesen Datensatz zu teilen.',
        NOT_ADDED: 'Sie haben nicht hinzugefügt',
        FIELDS_TO_VIEW: 'Felder zum Anzeigen.',
        SETUP_MINIMUM_FIELD_ALERT: 'Bitte fügen Sie ein weiteres Feld hinzu. Für die Ansicht benötigen wir mindestens zwei Felder.',
        SELECT_ANOTHER_TABLE: 'Bitte wählen Sie eine andere Tabelle und versuchen Sie es erneut.',
        WEB_FORM_ALERT1: 'aus',
        WEB_FORM_ALERT2: 'Ausgewählte Webformulare werden nicht gelöscht, da sie im Automatisierungsdesigner verwendet werden.',
        SETUP_WORKFLOWSTAGE_MINIMUM_FIELD_ALERT: 'Bitte fügen Sie ein Feld für die Spalte hinzu. Wir benötigen mindestens 1 Feld für die Ansicht.',
        SELECT_RECORD_FOR_SEND_SMS: 'Bitte wählen Sie einen Datensatz zum Senden einer Textnachricht aus.',
        SELECT_RECORD_FOR_CALL: 'Bitte wählen Sie einen Datensatz aus, um einen Anruf zu tätigen.',
        SELECT_MORE_RECORD_ACTION_ALERT: 'Bitte wählen Sie einen Datensatz aus, um weitere Aktionen auszuführen.',
        SELECT_RECORD_FOR_DOWNLOAD: 'Bitte wählen Sie einen Datensatz zum Herunterladen von Konten aus.',
        CHECKBOX_COLOR_GREEN_TITLE: 'Letzte Aktualisierung innerhalb von 30 Tagen',
        CHECKBOX_COLOR_YELLOW_TITLE: 'Letzte Aktualisierung innerhalb von 30-60 Tagen',
        CHECKBOX_COLOR_RED_TITLE: 'Letzte Aktualisierung vor 60 Tagen',
        STATUS_FIELD_NOT_ADDED_FOR_VIEW: 'Sie haben kein Statusfeld zum Anzeigen hinzugefügt.',
        SELECT_MAXIMUM_FIELD_FOR_COLUMN_SETUP: 'Sie haben die maximale Anzahl von insgesamt 5 ausgewählten Feldern erreicht.',
        NO_EMAIL_TO_SEND_MAIL: 'Es ist keine E-Mail-Adresse verfügbar, um eine E-Mail zu senden.',
        REQUIRED_FIELD_CONVERT_FAILED: 'Konvertierung fehlgeschlagen. Ein oder mehrere Pflichtfelder fehlen.',
        SELECT_RECORD_FOR_To_EXCEL: 'Bitte wählen Sie einen Datensatz in Excel aus.',
        SELECT_RECORD_FOR_ADD_TASK: 'Bitte Wählen Sie einfach einen Datensatz aus, um eine Aufgabe hinzuzufügen',
        UNIT_TASK_MESSAGE: 'Sie können Aufgaben nur den Einheiten hinzufügen, die direkt mit Ihnen verbunden sind.',
        UNIT_SEND_EMAIL: 'Sie können E-Mails nur an die Einheiten senden, die direkt mit Ihnen verbunden sind',
        UNIT_PHONE_NUMBER: 'Sie können „Telefonanruf“ nur für die Einheiten öffnen, die direkt mit Ihnen verbunden sind',
        FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED: 'Sie dürfen den Anhangdatensatz vom Typ FDD oder FA nicht löschen.',
        MULTIPLE_SELECT_FDD_FA_MSG_1: 'Aus dem ',
        MULTIPLE_SELECT_FDD_FA_MSG_2: ' Ausgewählte Anhangdatensätze ',
        MULTIPLE_SELECT_FDD_FA_MSG_3: ' aAnhangdatensätze werden nicht gelöscht, da es sich um Anhangdatensätze vom Typ FDD oder FA handelt. Bist du sicher?',
        RECORD_ALREADY_LINKED : "Ausgewählte Datensätze sind bereits mit diesem Datensatz verknüpft.",
        DUPLICATE_WITH_UNIQUE_RECORDS : "Einige der von Ihnen überprüften Datensätze sind bereits mit diesem Datensatz verknüpft! Bitte deaktivieren Sie diese Datensätze und versuchen Sie es erneut.",
        RECORDS_LINKED : "Datensätze erfolgreich verknüpft.",
        CANNOT_OPEN_EMAIL_AND_SMS_TASK: "Sie können aus dieser Liste keine E-Mail- oder Textaufgaben öffnen.",
    },
    AB_CAMPAIGNS: {
        NAME_BLANK: 'Geben Sie einen Namen für die Kampagne ein.',
        GROUP_FILTER_BLANK_COMMON_MSG: 'Bitte auswählen',
        FILTER_TYPE_GROUP: 'eine Gruppe',
        FILTER_TYPE_CONTACT: 'ein Kontaktfilter',
        FILTER_TYPE_ACCOUNT: 'ein Kontofilter',
        GROUP_FILTER_BLANK: 'Wählen Sie eine Gruppe aus.',
        CONTACT_FILTER_BLANK: 'Bitte wählen Sie einen Kontaktfilter.',
        ACCOUNT_FILTER_BLANK: 'Bitte wählen Sie einen Kontofilter.',
        SUBJECT_BLANK: 'Geben Sie einen Betreff für diese Nachricht ein.',
        FROM_NAME_BLANK: 'Geben Sie den Absendernamen ein.',
        FROM_EMAIL_BLANK: 'Geben Sie die E-Mail-Adresse des Absenders ein.',
        GROUP_NAME_BLANK: 'Gruppenname darf nicht leer sein!',
        AB_DISTRIBUTION: 'Geben Sie die A / B-Verteilung ein.',
        INVALID_AB_DISTRIBUTION: 'Ungültige A / B-Verteilung.',
        INVALID_WINNING_MARGIN: 'Die Gewinnspanne ist ungültig.',
        ZERO_WINNING_MARGIN: 'Geben Sie den Spielraum für den Gewinner ein.',
        MESSAGE_B_NOT_FILLED: 'Bitte geben Sie die Details zu Nachricht B ein.',
        AB_DISTRIBUTION_OUT_OF_RANGE: 'Geben Sie zum Testen einen Wert zwischen 1 und 99 ein',
        DAYS_BLANK: 'Bitte geben Sie an, wie viele Tage Sie warten möchten, bevor Sie die Gewinnnachricht an die verbleibende Liste senden.',
        INVALID_DAYS: 'Ungültiger Eintrag. Bitte geben Sie an, wie viele Tage Sie warten möchten, bevor Sie die Gewinnnachricht an die verbleibende Liste senden.',
        SAVE: 'Sie haben diese Kampagne erfolgreich erstellt.',
        UPDATE: 'Sie haben diese Kampagne erfolgreich aktualisiert.',
        EXECUTION_CANNOT_CANCEL_1: 'Die Ausführung kann jetzt nicht abgebrochen werden.',
        EXECUTION_CANCEL_MSG: 'Die Anwendung wird versuchen, die Ausführung sofort abzubrechen.',
        CONFIRM: 'Bitte bestätigen.',
        EXECUTION_CANNOT_CANCEL_2: 'Eine Kampagne, die bereits in Bearbeitung ist, kann nicht abgebrochen werden.',
        CAMPAIGN_EXECUTE_IN_60_MIN: 'Diese Kampagne wird in 60 Minuten ausgeführt.',
        OK_TO_CONFIRM: 'Klicken Sie zur Bestätigung auf OK',
        ERROR_MSG_1: 'Bitte wählen Sie die Vorlage aus.',
    },
    REPEAT_DIALOG: {
        END_DATE_BLANK: 'Das Enddatum darf nicht leer sein.',
        INVALID_END_DATE: 'Das Startdatum darf nicht größer oder gleich dem Enddatum sein.',
        DAY_CHECKBOX_BLANK: 'Mindestens einen Tag überprüfen.',
        DELETE_RECUR_TITLE: 'Wiederkehrende löschen',
        EDIT_RECUR_TITLE: 'Wiederkehrende bearbeiten',
        REPEAT: 'Wiederholen'
    },
    BATCH_ACTION: {
        CONVERT_SUCCESSFULL: 'wurden erfolgreich konvertiert.',
        SINGLE_CONVERT_SUCCESSFULL: 'wurde erfolgreich konvertiert.',
        CONVERT_UNSUCCESSFULL: 'Aktion kann nicht ausgeführt werden',
        CONVERT_SAME_STAGE: 'Datensatz ist bereits in ',
        MULTI_SHARE_SUCCESSFULL: ' wurden erfolgreich geteilt.',
        SINGLE_SHARE_SUCCESSFULL: 'wurde erfolgreich geteilt.',
        SHARE_UNSUCCESSFULL: 'Aktion kann nicht ausgeführt werden',
        MULTI_ASSIGN_SUCCESSFULLY: ' wurden erfolgreich zugewiesen.',
        SINGLE_ASSIGN_SUCCESSFULLY: ' wurde / wurden erfolgreich zugewiesen.',
        ASSIGN_UNSUCCESSFULL: 'Aktion kann nicht ausgeführt werden',
        COMPLETE_SUCCESSFULL: ' wurde erfolgreich abgeschlossen.',
        BATCH_COMPLETE_SUCCESSFULL: 'wurden erfolgreich abgeschlossen.',
        COMPLETE_UNSUCCESSFULL: 'Aktion kann nicht ausgeführt werden',
        GROUP_ADDED_SUCCESSFULLY: 'Erfolgreich zu Gruppe (n) hinzugefügt',
        GROUP_REMOVED_SUCCESSFULLY: 'Erfolgreich aus allen Gruppen entfernt.',
        GROUP_CLICK_TO_CONFIRM: ' wird zu den ausgewählten Gruppen hinzugefügt. Klicken Sie zur Bestätigung auf "OK".',
        GROUP_BLANK_ADDED_BATCH: 'Bitte wählen Sie mindestens eine Gruppe aus.',
        NOTE_TXT_CANNOT_BE_BLANK: 'Text darf nicht leer sein.',
        WORKFLOW_CONFIRMATION: 'Der Workflow wird für diesen Datensatz geändert. Klicken Sie zur Bestätigung auf OK.',
        BATCH_WORKFLOW_CONFIRMATION: 'Der Workflow wird für diese Datensätze geändert. Klicken Sie zur Bestätigung auf OK.',
        WORKFLOW_APPLIED: 'Workflow erfolgreich angewendet.',
        RECORDS_WITH_DIFFERENT_WORKFLOW: 'Sie haben Datensätze mit unterschiedlichen Workflows ausgewählt. Klicken Sie auf OK, um fortzufahren und den Vorgang zum Anwenden des Workflows auszuführen, oder klicken Sie auf Abbrechen, um die Datensätze erneut auszuwählen.',
        RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS: 'Sie haben Datensätze mit unterschiedlichem Status ausgewählt. Klicken Sie auf OK, um fortzufahren und den Vorgang zum Anwenden des Workflows auszuführen, oder klicken Sie auf Abbrechen, um die Datensätze erneut auszuwählen.',
        ACTIVE_SUCCESSFULL: 'Der Status wurde in Aktiv geändert.',
        INACTIVE_SUCCESSFULL: 'Der Status wurde in Inaktiv geändert.',
        BATCH_EDIT_BLANK: 'Wertfeld darf nicht leer sein!',
        INVALID_WORKFLOW_STAGE: 'Sie können den Datensatz nicht konvertieren. Der Status des ausgewählten Datensatzes ist im aktuellen Workflow nicht verfügbar. Drücken Sie OK, um den Status oder den Workflow zu ändern',
        CONFIRMATION_DELETE_MSG: ' wird gelöscht. Bist du sicher?',
        CONFIRMATION_REMOVE_MSG: ' wird entfernt. Bist du sicher?',
        CONFIRMATION_UNLINK_MSG: 'wird nicht verbunden. Bist du sicher?',
        CONVERT_ALERT_MSG: 'wird konvertiert zu',
        CLICK_TO_CONFIRM: 'Klicken Sie zur Bestätigung auf OK.',
        ASSIGN_ALERT_MSG: 'Datensatz (e) werden zugewiesen ',
        ROUND_ROBIN_BASIS: 'auf Round-Robin-Basis. Bist du sicher?',
        CONFIRM_MSG: 'Bist du sicher?',
        RECORDS: 'Aufzeichnungen',
        RECORD: 'Aufzeichnung',
        ALL_RECORDS: 'Alle Datensätze',
        RECORD_WILL_BE_UPDATED: 'Datensätze werden auf aktualisiert',
        CONFIRM_UNSUBSCRIBE_RECORD: 'wird abgemeldet. Bist du sicher?',
        UNSUBSCRIBE: 'Sie haben die ausgewählten Kontakte erfolgreich abgemeldet.',
        USE_ASSIGN_RULE_ALERT_MSG: ' Datensatz (e) werden den Vertriebsmitarbeitern gemäß zugewiesen',
        EMPTY_RECYCLE_BIN: 'Alle Datensätze werden dauerhaft aus dem Papierkorb gelöscht. Bist du sicher?',
        PERMANENT_DELETE_MSG: ' wird dauerhaft gelöscht. Bist du sicher?',
        INVALID_STATUS_FOR_WORKFLOW_STAGE: 'Der Status des ausgewählten Datensatzes ist im aktuellen Workflow nicht verfügbar. Drücken Sie OK, um den Status des Datensatzes zu ändern.',
        CONTACT_STATUS_UPDATE_SUCCESS: ' Status wurde erfolgreich aktualisiert.',
        PUBLISH_SUCCESSFULL: 'Der Status wurde in Veröffentlicht geändert.',
        UNPUBLISH_SUCCESSFULL: 'Der Status wurde in Unveröffentlicht geändert.',
        RECORDS_NOT_IN_WORKFLOW: 'Alle ausgewählten Datensätze befinden sich nicht im Kontaktworkflow.',
        RECORD_NOT_IN_WORKFLOW: 'Sie können nicht konvertieren, da sich der ausgewählte Datensatz nicht im Kontaktworkflow befindet.',
    },
    BATCH_ACTION_VALIDATION: {
        ADDTOGROUP_BLANK: 'Der ausgewählte Datensatz wird aus allen Gruppen entfernt. Klicken Sie zur Bestätigung auf "OK".',
        ADDTOGROUP_NAMEBLANK: 'Der Gruppenname darf nicht leer sein.',
        SHARE_WITH_BLANKUSER: 'Bitte wählen Sie mindestens einen Benutzer aus.',
        ASSIGN_BLANKUSER: 'Bitte wählen Sie einen Benutzer aus.',
        CONVERT_SELECT_STATUS: 'Bitte wählen Sie einen Status für ',
        MAP_INSUFFICIENT_INFO: 'Unzureichende Informationen zum Anzeigen der Karte',
        GROUP_ALREADY_EXIST: 'Gruppenname existiert bereits!',
        ALREADY_ACTIVE: 'Aufzeichnung bereits im aktiven Status.',
        ALREADY_INACTIVE: 'Aufzeichnung bereits im Status Inaktiv.',
        ALREADY_PUBLISH: 'Datensatz bereits im Status Veröffentlicht.',
        ALREADY_UNPUBLISH: 'Datensatz bereits im unveröffentlichten Status.',
    },
    ATTACHMENT: {
        ATTACHMENT_UPLOAD: 'Anhang erfolgreich hochgeladen',
        ATTACHMENT_LINK_ADDED: 'Dokumentlink wurde erfolgreich hinzugefügt!',
        ATTACHMENT_UPLOAD_IMAGE: 'Bild erfolgreich hochgeladen',
        ATTACHMENTS_UPLOAD: ' Anhänge erfolgreich hochgeladen',
        VALIDATE_ATTACHMENT: 'Bitte wählen Sie eine Datei zum Anhängen aus  ',
        ATTACHMENT_UPLOAD_FAILURE: 'Diese Datei existiert bereits.',
        VALIDATE_EMPTY_FILE: ' Die Upload-Dateigröße darf nicht 0 sein',
        VALIDATE_FILE_SIZE: 'Die Upload-Dateigröße darf nicht mehr als 5 MB betragen',
        VALIDATE_LOGO_SIZE: 'Das ausgewählte Foto ist zu groß für die Verwendung. Die maximal zulässige Dateigröße beträgt 500 KB',
        VALIDATE_BLANK_FILE: 'Sie können keine leere Datei hochladen',
        VALIDATE_LOGO_EXTENSION: 'Bitte wählen Sie eine JPG-, GIF- oder PNG-Datei!',
        ATTACHMENT_LOGO_TEXT: 'Sie können eine JPG-, GIF- oder PNG-Datei hochladen.',
        ATTACHMENT_LOGO_SIZE_TEXT: ' Die maximal zulässige Dateigröße beträgt 500 KB.',
        UPLOAD_IMAGE: 'Lade ein Bild hoch',
        UPLOAD_FILE: 'Wählen Sie die hochzuladenden Dateien aus (bis zu fünf Dateien gleichzeitig).',
        ADD_LINK: 'Fügen Sie einen externen Link hinzu',
        VALIDATE_MAX_ATTACHMENTS: 'Sie können nicht mehr als fünf Dateien hochladen',
        SELECT_IMAGE_TO_UPLOAD: 'Wählen Sie ein Bild zum Hochladen aus',
        MAX_SIZE_2_MB_ALERT: 'Die Upload-Dateigröße darf nicht mehr als 2 MB betragen',
        VALID_FILE_UPLOAD: 'Sie können eine JPG-, GIF- oder PNG-Datei vom Computer hochladen.',
        MAX_SIZE_2_MB: 'Die maximal zulässige Dateigröße beträgt 2 MB.',
        CANNOT_UPLOAD_EXECUTABLE_FILE: 'Sie können keine ausführbare Datei hochladen',
        THE_ATTACHMENT: 'Der Anhang',
        DOCUMENT_LINK: 'Dokumentlink', 
        FOLDER_NAME: 'Ordnernamen',
        FILE_NAME:'Dateinamen',
        ALREADY_EXIST: 'ist bereits vorhanden.',
        CANNOT_BE_DELETED: 'kann nicht gelöscht werden.',
        CLICK_TO_DELETE: 'Klicken Sie zum Löschen auf OK ',
        EMPTY_FOLDER_ERROR_DOWNLOAD: 'Kann nicht heruntergeladen werden, da der Ordner leer ist.',
        EMPTY_FOLDER_ERROR_PUSH: 'Kann nicht pushen, da der Ordner leer ist.',
        RENAME_SUCCESSFULLY: 'Ordner erfolgreich umbenannt.',
        DELETE_SUCCESSFULLY: 'Ordner erfolgreich gelöscht.',
        ADDED_SUCCESSFULLY: 'erfolgreich hinzugefügt.',
        DOCUMENT_LINK_ADDED_SUCCESSFULLY: 'Dokumentlink erfolgreich hinzugefügt.',
        DOCUMENT_LINK_UPDATE_SUCCESSFULLY: 'Dokumentlink wurde erfolgreich aktualisiert.',
        DOCUMENT_LINK_DELETE_SUCCESSFULLY: 'Dokumentlink erfolgreich gelöscht.',
        FILE_ADDED_SUCCESSFULLY: 'Datei erfolgreich hinzugefügt.',
        FILE_RENAME_SUCCESSFULLY: 'Datei erfolgreich umbenannt.',
        FILE_DELETE_SUCCESSFULLY: 'Datei erfolgreich gelöscht.',
        IMAGE_DELETE_SUCCESSFULLY: 'Bild erfolgreich gelöscht.',
        IMAGE_RENAME_SUCCESSFULLY: 'Bild erfolgreich umbenannt.',
        IMAGE_NAME: 'Bildname',
        MAX_SIZE_50_MB: 'Die maximal zulässige Dateigröße beträgt 50 MB.',

    },
    AUTOMATION_DESIGNER: {
        STEPPER_BACK_MESSAGE: 'Möchten Sie zur Vorlageninfoseite gehen? Nicht gespeicherte Änderungen gehen verloren. ',
        MAX_CONNECTION: "Maximal Nr. von Verbindungen wurde erreicht.",
        TOUCH_CAMPAIGN_NAME: "Bitte wählen Sie einen Namen für diese Touch-Kampagne",
        SELECT_FILTERS: "Bitte wählen Sie eine",
        SELECT_FILTERS_A: "Bitte wählen Sie eine",
        START_DATE: "Bitte wählen Sie ein Startdatum für",
        EMPTY_WEBSITE_LINK: "Sie können keinen leeren Website-Link hinzufügen.",
        CANNOT_START_WITH_DECISSION: 'Sie können nicht mit einer Entscheidung beginnen. Bitte beginnen Sie mit einer der Optionen im Menü "Beginnen mit" auf der linken Seite.',
        CANNOT_START_WITH_ACTION: 'Sie können nicht mit einer Aktion beginnen. Bitte beginnen Sie mit einer der Optionen im Menü "Beginnen mit" auf der linken Seite.',
        CONNECT_WITH_PROPER_NODE: 'Verbinden Sie sich mit dem richtigen Knoten',
        FROM_NAME: 'Bitte geben Sie einen Von-Namen für ein',
        FROM_EMAIL: 'Bitte wählen Sie eine Von E-Mail',
        RESEND_AFTER: 'Bitte geben Sie Resend After in Tag (e) ein.',
        SELECT_WEB_FORM: 'Bitte wählen Sie ein Webformular',
        SELECT_GROUP: 'Bitte wählen Sie eine Gruppe aus.',
        SELECT_GROUP_OPERATION: 'Bitte wählen Sie eine Gruppenoperation.',
        ENTER_NOTE: 'Bitte geben Sie Ihre Notiz ein.',
        SELECT_ONE_RECIPIENT: 'Bitte wählen Sie mindestens einen Empfänger aus',
        SELECT_USER: 'Bitte wählen Sie einen Benutzer aus.',
        ENTER_TASK_NAME: 'Bitte geben Sie einen Aufgabennamen ein',
        PROVIDE_DUEBY_FIELD: 'Bitte geben Sie einen Wert in das Feld Fällig nach!',
        ENTER_LINK: 'Bitte geben Sie einen Link ein.',
        PROVIDE_VALID_URL: 'Bitte geben Sie eine gültige Website-URL an!',
        CATCH_ALL_LINK_CONNECTION: 'Sie haben bereits eine Link-Klick-Bedingung "Alle abfangen"',
        ENTER_SCORE: 'Bitte geben Sie eine Punktzahl ein',
        INVALID_SCORE: 'Sie haben eine ungültige Punktzahl eingegeben',
        ENTER_SUBJECT: 'Bitte geben Sie ein Thema ein',
        ENTER_BODY: 'Bitte geben Sie den Körperinhalt ein',
        ENTER_INTERVAL_VALUE: 'Bitte geben Sie einen Wert für das Intervall ein',
        SELECT_ONE_HOUR: 'Bitte wählen Sie 1 Stunde oder mehr für das stündliche Intervall.',
        BLANK_CAMPAIGN_NAME: 'Der Kampagnenname darf nicht leer sein.',
        CAMPAIGN_SAVED_SUCCESSFULLY: 'Diese Kampagne wurde erfolgreich gespeichert',
        BLANK_CANVAS: 'Sie können keine leere Leinwand speichern.',
        NOT_ROOT_NODE: 'kann kein Wurzelknoten sein.',
        START_DATE_MUST_BE_GREATER: 'Das Startdatum muss größer sein als die vorherige Touch-Kampagne.',
        EDIT_AND_COMPLETE_DETAILS: 'Bitte bearbeiten und vervollständigen Sie die Details der',
        PROVIDE_WAIT_INTERVAL: 'Bitte geben Sie ein Warteintervall von mehr als Null an.',
        CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN: 'Bitte überprüfen Sie das Intervall mit den vorherigen Sendetagen der Touch-Kampagne.',
        CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC: 'Bitte überprüfen Sie das Startdatum oder warten Sie, bevor Sie die Touch-Kampagne speichern.',
        PROVIDE_START_DATE: 'Bitte geben Sie ein Startdatum an.',
        INTERVAL_EXPEXT_ZERO: 'Bitte geben Sie ein Intervall außer Null an.',
        EMPTY_LINK_NODE: 'Sie können keinen leeren Linkknoten hinzufügen.',
        DELETE_NODE_CONFIRMATION: 'Knoten wird gelöscht. Wollen Sie fortfahren?',
        MSG_CANT_BE_DELETED: 'Diese Nachricht wurde gesendet und kann nicht gelöscht werden.',
        FIRST_MSG_CANT_BE_DELETED: 'Sie können die erste Nachricht einer Touch-Kampagne nicht löschen',
        FIRST_WAIT_CANT_BE_DELETED: 'Sie können das erste Warten einer Touch-Kampagne nicht löschen',
        BLANK_INTERVAL: 'Das Intervall darf nicht leer sein',
        PROVIDE_POSITIVE_INTERVAL: 'Bitte geben Sie ein Intervall größer als 0 an',
        WONT_BE_ABLE_TO_DELETE: 'Sie können es nicht löschen.',
        URL_NOT_EXIST: 'Die von Ihnen angegebene URL ist im E-Mail-Text nicht verfügbar.',
        GROUP_NAME_BLANK: 'Gruppenname darf nicht leer sein!',
        SELECT_LANDING_PAGE: 'Bitte wählen Sie eine Landing Page',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Sie haben bereits einen geöffneten Automation Designer. Schließen oder speichern Sie ihn, um einen neuen Automation Designer zu öffnen.',
        SAVE_DIAGRAM_BEFORE_COPY: 'Bitte speichern Sie das Diagramm, bevor Sie den Kopiervorgang ausführen.',
        NO_TOUCH_FOUND: 'Keine Touch-Kampagne gefunden.',
        ADD_ONE_FIELD: 'Bitte fügen Sie mindestens ein Feld hinzu',
        SELECT_FIELD_VALUE: 'Bitte wählen Sie einen Feldwert',
        INVALID_URL: 'ist eine ungültige URL.',
        INVALID_INTEGER: 'ist keine ganze Zahl.',
        INVALID_EMAIL: 'ist eine ungültige E-Mail-Adresse.',
        ALLOWED_UPTO_FOUR_FIELDS: 'Aktualisierungen sind bis zu 4 Felder zulässig. Sie können keine weitere Zeile eines Feldwertpaars hinzufügen.',
        WILL_BE_UPDATED: 'wird geupdated. Bist du sicher?',
        DUPLICATE_TOUCH_NAME: 'Sie haben bereits eine Send Touch-Kampagne mit dem Namen',
        TOOLTIP_MESSAGE_SEND_MAIL: 'Beginnen Sie mit einer Touch-Kampagne, die eine Reihe von E-Mails sendet. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_WEB_FORM: 'Definieren Sie zunächst, was nach dem Absenden eines Webformulars geschieht. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_LANDING_PAGE: 'Definieren Sie zunächst, was nach dem Senden einer Zielseite geschieht. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_WEBSITE_VISIT: 'Definieren Sie zunächst, was passiert, wenn ein Kontakt eine bestimmte Seite Ihrer Website besucht. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_LINK_CLICK: 'Definieren Sie, was passiert, nachdem ein Kontakt auf einen Link in Ihrer E-Mail geklickt hat. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand rechts und verbinden Sie es mit einer Touch-Nachricht.',
        TOOLTIP_MESSAGE_EMAIL_OPEN: 'Definieren Sie, was passiert, nachdem eine Kontaktnachricht von einem Kontakt geöffnet wurde. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand rechts und verbinden Sie es mit einer Touch-Nachricht.',
        TOOLTIP_MESSAGE_UNSUBSCRIBE: 'Definieren Sie, was passiert, nachdem sich ein Kontakt von einer Touch-Nachricht abgemeldet hat. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand rechts und verbinden Sie es mit einer Touch-Nachricht.',
        TOOLTIP_MESSAGE_SCORE_REACHED: 'Definieren Sie, was passiert, wenn ein Kontakt einen Bewertungsschwellenwert erreicht. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_SMS_REPLY: 'Definieren Sie, was passiert, nachdem ein Kontakt eine SMS beantwortet hat. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_AUTO_MAIL: 'Die Sende-E-Mail wird verwendet, um sowohl einmalige als auch Touch-E-Mails zu senden. Diese Aktion kann nach einer Touch-Kampagne oder einer Entscheidung oder Aktion hinzugefügt werden.',
        TOOLTIP_MESSAGE_CHANGE_GROUP: 'Hinzufügen oder Entfernen von Konten und Kontakten zu Gruppen. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_ADD_NOTE: 'Fügen Sie dem Konto eine Notiz hinzu. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_NOTIFY: 'Benachrichtigen Sie einen CRM-Benutzer. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_ADD_TASK: 'Weisen Sie einem anderen Benutzer eine Aufgabe zu. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_UPDATE_FIELD: 'Aktualisieren Sie den Wert eines Felds für Konto oder Kontakt. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_ASSIGN: 'Weisen Sie das Konto einem anderen Benutzer zu. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_CONVERT: 'Konvertieren Sie das Konto in einen neuen Status. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOOLTIP_MESSAGE_SEND_SMS: 'Senden Sie SMS an eine Gruppe, einen Kontofilter oder einen Kontaktfilter. Um dies zu verwenden, ziehen Sie es per Drag & Drop auf die Leinwand nach rechts.',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Sie haben bereits eine Touch-Kampagne mit dem Namen',
        TOUCH_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Bitte geben Sie einen anderen Namen ein. ',
        INTERVAL_MODE_ADD_MSG_1: 'Bitte geben Sie eine',
        INTERVAL_MODE_ADD_MSG_2: 'nach vorherigem Wert außer Null',
        AUTOMATED_CAMPAIGN_TITLE: 'Automatisierte Kampagnen',
        CANNOT_CONNECT_MULTIPLE: 'Mehrere können nicht verbunden werden ',
        CONNECTION_EXIST: 'Sie haben bereits eine aktive Verbindung von',
        REMOVE_CONNECTION_ALERT: 'Wenn Sie die Verbindung entfernen, verlieren Sie alle folgenden Schritte',
        CONTINUE_ALERT: 'Wollen Sie fortfahren?',
        CANNOT_ADD: 'Sie können nicht hinzufügen',
        DIRECT_UNDER: 'direkt unter a',
        DIRECT_UNDER_AN: 'direkt unter einem',
        MULTIPLE_TIME_SELECTED_MSG_1: 'Sie haben die ausgewählt',
        MULTIPLE_TIME_SELECTED_MSG_2: 'Feld (e) mehrmals zur Aktualisierung. Bitte entfernen Sie die doppelten Zeilen.',
        CANNOT_BE_LEAF_NODE: 'kann kein Blattknoten sein.',
        CLICK: 'Klicken',
        HERE: 'Hier',
        PURCHASE_MORE: 'um mehr zu kaufen.',
        CREDITS_REMAINING: 'E-Mail-Credits verbleiben.',
        STOP_CAMPAIGN: 'Beenden Sie die Kampagne, um Änderungen vorzunehmen.',
        SELECT_EMAIL_TEMPLATE: ' Wählen Sie eine E-Mail-Vorlage aus',
        NO_INFO_EXISTS: 'Es sind keine Informationen vorhanden',
        SELECT_RECORD_FOR_COPY_ONE_RECORD: 'Bitte wählen Sie einen Datensatz zum Kopieren aus.',
        SELECT_RECORD_FOR_EDIT_ONE_RECORD: 'Bitte wählen Sie einen Datensatz zum Bearbeiten aus.',
        CANNOT_SAVE_CAMPAIGN: "Kann diese Kampagne nicht speichern. Siehe die roten Blöcke in der Kampagne für Fehler.",
        ADD_GROUP_CLICK: 'Klicken',
        ADD_GROUP_CLICK_HERE: 'Hier',
        CLICK_TO_ADD_NEW_GROUP: 'um eine neue Gruppe hinzuzufügen',
        MESSAGE_SAVED_SUCCESSFULLY: 'Sie haben diese Nachricht erfolgreich gespeichert',
        MESSAGE_NOT_SAVED_SUCCESSFULLY: 'Nachricht wurde nicht erfolgreich gespeichert',
        CONTACT_FILTER_BLANK: 'Bitte wählen Sie einen Kontaktfilter.',
        ACCOUNT_FILTER_BLANK: 'Bitte wählen Sie einen Kontofilter.',
        QUERY_FILTER_BLANK: 'Bitte wählen Sie eine Abfrage',
        SAVE_AUTOMATION_FOR_TEST: 'Bitte speichern Sie die Automatisierungskampagne.',
        BIRTHDAY_CAMPAIGN_NAME: 'Bitte wählen Sie einen Namen für diese Geburtstagskampagne',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_1: 'Sie haben bereits eine Geburtstagskampagne mit dem Namen',
        BIRTHDAY_CAMPAIGN_DUPLICATE_NAME_TEXT_2: 'Bitte geben Sie einen anderen Namen ein.',
        BIRTHDAY_FIRST_MSG_CANT_BE_DELETED: 'You cannot delete the first message of a birthday campaign',
        MIGRATION: 'Diese automatisierte Kampagne wird auf den neuen Kampagnen-Builder aktualisiert. Vielen Dank für Ihre Geduld.',
        MIGRATION_DIALOG_TITLE: 'Upgrade läuft ...',
        MIGRATION_NOTE: 'Hinweis: Nach dem Update wird die Automatisierungskampagne an dem Tag ausgeführt, an dem Sie den Startvorgang ausführen.',
        BIRTHDAY_CAMPAIGN_NO_REPORT: 'Geburtstagskampagne muss noch an den / die Empfänger gesendet werden.',
        RUNNING_CAMPAIGN_CANNOT_CANCEL: 'Eine laufende Kampagne kann nicht abgebrochen werden.',
        CONFIRM_CANCEL_SCHEDULE: 'Möchten Sie die geplante Kampagne abbrechen?',
        CAMPAIGN_SCHEDULE_SUCCESSFULLY: 'Diese Kampagne wurde erfolgreich geplant.',
        SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY: 'Diese Kampagne wurde erfolgreich abgebrochen.',
        CAMPAIGN_START_IMMEDIATELY: 'Diese Kampagne wurde erfolgreich so eingestellt, dass sie sofort startet.',
        ENTER_FROM_NAME: 'Bitte geben Sie den Namen ein',
        ENTER_FROM_EMAIL: 'Bitte geben Sie per E-Mail ein.'
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME_BLANK: 'Bitte geben Sie einen Domainnamen an, um den Tracking-Code zu generieren.',
        DOMAIN_NAME_INVALID: 'Bitte geben Sie einen gültigen Domainnamen an.',
        TRACKING_CODE_BLANK: 'Es ist kein Tracking-Code zum Kopieren vorhanden.',
        TRACKING_CODE_ALREADY_GENERATED: 'Tracking-Code ist bereits generiert.',
        DONE: 'Ihr Domainname wurde registriert.',
        SET_UP_INFO: 'Kopieren Sie diesen Code und fügen Sie ihn in jede Seite Ihrer Site ein, kurz vor dem End-Body-Tag. Wenn Sie fertig sind, kehren Sie zu dieser Seite zurück und klicken Sie auf die Schaltfläche Fertig.'

    },
    MAIL: {
        SUCCESS: 'Ihre E-Mail wurde gesendet.',
        BLANK_FROM_EMAIL: 'Von E-Mail ist leer.',
        INVALID_FROM_EMAIL: 'Sie haben eine ungültige E-Mail-Adresse im Feld Von.',
        INVALID_CC_EMAIL: 'Sie haben eine ungültige CC-E-Mail-Adresse im CC-Feld.',
        INVALID_BCC_EMAIL: 'Sie haben eine ungültige BCC-E-Mail-Adresse im Feld BCC.',
        BLANK_SUBJECT: 'Betreff ist leer.',
        BLANK_EMAIL_BODY: 'Der E-Mail-Text ist leer.',
        BATCH_SEND_MAIL_MSG_1: 'Aus dem',
        BATCH_SEND_MAIL_MSG_2: 'Sie haben nur ausgewählt',
        BATCH_SEND_MAIL_MSG_3: 'haben sich für den Empfang Ihrer E-Mails entschieden. Wir würden die E-Mail nur an diese senden',
        BATCH_SEND_MAIL_MSG_4: 'Keine von',
        BATCH_SEND_MAIL_MSG_5: 'haben sich für den Empfang Ihrer E-Mails entschieden. Wir werden diese E-Mail nicht senden. Bitte schließen Sie diese Registerkarte',
        NO_RECIPIENT: 'Sie haben keinen Empfänger für diese E-Mail eingegeben',
        NO_RECIPIENT_FOUND: 'Für diese E-Mail wurde kein Empfänger gefunden.',
        BLANK_TO_EMAIL: 'Keine E-Mail-Adresse im Feld An.',
        INVALID_TO_EMAIL: 'Ungültige E-Mail-Adresse im Feld An.',
        SCHEDULE_SUCCESS: 'Ihre E-Mail wurde erfolgreich geplant.',
        RESCHEDULE_SUCCESS: 'E-Mail wurde erfolgreich verschoben',
        TEMPLATE_NOT_SELECTED: 'Bitte wählen Sie eine Vorlage',
        NO_ACTIVE_AGREEMENT_TEMPLATE_MSG: "Sie haben keine aktive Vertragsvorlage.",
        SELECT_DIFFERENT_SIGNER: "Please select a different signer.",
        SELECT_ALL_SIGNERS: 'Please select all the signer(s) to send the agreement email.',
    },
    APPOINTMENTS_TASKS: {
        DELETE: 'Dieser Datensatz wird gelöscht. Bist du sicher?',
        INVALID_START_END_DATE: 'Das Startdatum darf nicht größer oder gleich dem Enddatum sein.',
        RECURR_DELETE_DIALOG_ONLY_BTN_INFO: 'Alle anderen Datensätze in der Serie bleiben erhalten.',
        RECURR_DELETE_DIALOG_FOLLOWING_BTN_INFO: 'Dieser und alle folgenden Datensätze werden gelöscht.',
        RECURR_DELETE_DIALOG_ALL_BTN_INFO: 'Alle Datensätze in der Serie werden gelöscht.',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_1: 'Alle Anderen',
        RECURR_EDIT_DIALOG_ONLY_BTN_INFO_2: 'in der Serie bleibt gleich.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_1: 'Dies und all das Folgende',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_2: 'wird geändert.',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_3: 'Änderungen in der Zukunft',
        RECURR_EDIT_DIALOG_FOLLOWING_BTN_INFO_4: 'wird verloren sein.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_1: 'Alle',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_2: 'in der Serie wird geändert.',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_3: 'Alle Änderungen, die an anderen vorgenommen wurden',
        RECURR_EDIT_DIALOG_ALL_BTN_INFO_4: 'wird beibehalten.',
        RESCHEDULE_TASK_SUCCESS: 'Die Aufgabe wurde erfolgreich verschoben',
        RESCHEDULE_APPOINTMENT_SUCCESS: 'Termin wurde erfolgreich verschoben',
        NO_TASK_FOUND: 'Keine Aufgaben gefunden',
        NO_APPOINTMENT_FOR_TODAY: 'Es sind heute keine geplanten Termine verfügbar',
        NO_PENDING_TASK_FOR_TODAY: 'Es ist heute keine ausstehende Aufgabe verfügbar',
        NO_TASK_APPOINTMENT_FOUND: 'Nichts steht noch aus.',
        NO_TASK_APPOINTMENT_FOUND_FOR_COMPLETED: 'Nichts abgeschlossen.',
        BLANK_START_END_DATE: 'Start- und Enddatum dürfen nicht leer sein',
        BLANK_START_DATE: 'Das Startdatum darf nicht leer sein',
        BLANK_END_DATE: 'Das Enddatum darf nicht leer sein',
        BLANK_NO_OF_DAYS: "Anzahl der Tage kann nicht leer sein.",
        ZERO_NO_OF_DAYS: "Anzahl der Tage kann nicht 0 sein.",
        BLANK_SPECIFIC_DATE: "Datum kann nicht leer sein.",
        INVALID_DUEBY_END_DATE: 'Das Fälligkeitsdatum darf nicht größer oder gleich dem Enddatum der Wiederholung sein.',
        SEND_EMAIL_PROGRESS_MSG: 'Das Senden von E-Mails ist im Gange, es wird einen Moment dauern.',
        INVALID_DUEBY_START_DATE: 'Das Fälligkeitsdatum darf nicht größer oder gleich mit dem Startdatum sein.',
        SYNC_STARTED_MSG: 'Die Synchronisierung wurde gestartet. Aktualisieren Sie den Kalender, um neue Termine anzuzeigen.',
        SYNC_STARTED_MSG_FOR_SETUP: 'Die Synchronisierung wurde gestartet. Ihr Kalender wird in Kürze aktualisiert.',
        ENABLE_SYNC_CAL: 'Bitte aktivieren Sie die Option Synchronisierungskalender unter Integration, bevor Sie die Synchronisierung durchzuführen.',
        PRIVATE_APPOINTMENT_ALERT: "Dies ist ein privater Termin; Sie können dies nicht öffnen.",
        START_END_DATE_VALIDATE: 'cannot be greater than or same as',
        CONVERT_CONFIRMATION_MESSAGE: 'Eine oder mehrere Aufgaben werden nicht überprüft. Möchten Sie mit dem Konvertierungsvorgang fortfahren, ohne diese Aufgaben zu überprüfen?'
    },
    TASKS:{
        DELETE_CANCELLED_STATAUS_RESTRICT_MSG:'Sie dürfen keine Korrekturmaßnahmen abbrechen.',
    },
    HOME: {
        FILTER_ADD: 'Filter erfolgreich hinzugefügt',
        FILTER_NOT_FOUND: 'Sie haben keinen Filter konfiguriert',
        FILTER_DELETE_RECORD: 'Filter wird gelöscht. Bist du sicher?',
        FILTER_DELETE: 'Filter erfolgreich entfernt',
        FILTER_ON_MAX_FIELD: 'Sie haben die maximale Feldbedingung erreicht. Wir erlauben bis zu 10 Felder.',
        ASSIGNMENT_RULES_ON_MAX_FIELD: 'Sie haben die maximale Feldbedingung erreicht. Wir erlauben bis zu 20 Felder.',
        // not found in doc
        FILTER_NAME_BLANK: 'Bitte geben Sie einen Filternamen an.',
        FILTER_NAME_MAX_LENGTH: 'Der Filtername darf nicht länger als 64 Zeichen sein.',
        FILTER_CRITERIA_EMPTY: 'Bitte geben Sie mindestens ein Kriterium an.',
        FILTER_SQL_ERROR_NO_VALUE: ' darf nicht leer sein.',
        FILTER_SQL_ERROR_INTEGER: ' ist keine ganze Zahl.',
        FILTER_SQL_ERROR_FLOAT: 'ist kein Schwimmer.  ',
        FILTER_SQL_ERROE_ALREADY_EXISTS: 'Filtername existiert bereits!',
        FILTER_DELETE_MSG_TC: 'die folgende Touch-Kampagne (n) ',
        FILTER_DELETE_MSG_BC: ' die folgende Broadcast-Kampagne (n) ',
        FILTER_DELETE_MSG_AB: ' die folgende A / B Kampagne (n)',
        FILTER_DELETE_MSG_AND: 'und ',
        FILTER_DELETE_MSG_BEING_USED: ' kann nicht gelöscht werden, da sie in verwendet wird ',
        ADD_GROUP_SUCCESS: 'Gruppe wurde hinzugefügt.'
    },
    DOCUMENT_LIBRARY: {
        INVALID_UPLOAD_ERROR: 'Wählen Sie eine Bilddatei!',
        CONFIRM_DELETE_MSG: 'Sind Sie sicher, dass Sie um das Bild zu löschen?',
        OVER_MAX_SIZE: 'Die ausgewählte Datei ist zu groß für die Verwendung. Die maximal zulässige Dateigröße beträgt 50 MB',
        OVER_MAX_SIZE_IMAGE: 'Die Datei, die Sie ausgewählt haben, ist zu groß, um zu verwenden. Die maximale Dateigröße ist 500 KB',
        INVALID_DOCUMENT_UPLOAD_ERROR: 'exe bat sh Dokumente werden nicht hochgeladen werden dürfen!',
        DOCUMENT_FILE_EMPTY_ERROR: 'Bitte wählen Sie ein Dokument!',
        ASSETS_ALREADY_OPEN_ALERT: 'Sie haben bereits Registerkarte Bibliothek offen unter Operations-Modul.',
        DUPLICATE_IMAGE_NAME: 'Bild existiert bereits mit diesem Namen. Bitte wählen Sie ein anderes Bild!',
        DUPLICATE_DOCUMENT_NAME: 'Dokument existiert bereits mit diesem Namen. Bitte wählen Sie ein anderes Dokument!',
        UPLOAD_IMAGE: 'Wählen Sie zum Hochladen von Bildern (bis zu fünf Dateien auf einmal).',
        ASSETS_ALREADY_OPEN_ALERT_HUB: 'Unter dem Hub-Modul ist die Registerkarte "Bibliothek" bereits geöffnet.',
        BLANK_TITLE_ERROR : 'Titel darf nicht leer sein',
        BLANK_URL_ERROR : 'Der Link darf nicht leer sein',
        INVALID_URL_ERROR : 'Bitte geben Sie eine gültige URL an',
        CONFIRM_FILE_TYPE_CHANGE: {
            LN1: 'Möchten Sie den aktuellen Abschnitt verlassen? Alle nicht gespeicherten Änderungen würden verloren gehen.',
            LN2: 'Klicken Sie zum Bestätigen und Beenden auf OK.',
            LN3: 'Klicken Sie auf Abbrechen, um im aktuellen Abschnitt zu bleiben.',
        },
    },
    MERGE: {
        NOT_URL: "ist eine ungültige URL.",
        NOT_FLOAT: "ist kein Schwimmer.",
        NOT_INTEGER: "keine ganze Zahl ist.",
        NOT_EMAIL: "ist eine ungültige E-Mail-Adresse.",
        MERGE_TENANT_EMAIL_ALERT_MESSAGE: 'Sie können diese Aufzeichnungen nicht fusionieren. Wie Sie ein bezahltes Konto gewählt haben, zu löschen!',
    },
    SCHEDULE: {
        EMPTY: 'Bitte wählen Sie einen Zeitplan Datum',
        CAMPAIGN_SCHEDULE: 'Diese Kampagne läuft auf',
        CONFIRM: 'Bestätigen Sie mit OK.',
        SMS_SCHEDULE: 'Diese SMS-Nachricht sendet auf'
    },
    RELATIONSHIP: {
        BLANK_RECORD: "Bitte wählen Sie die Beziehung zu",
        BLANK_PARENT_RELATION: "Bitte wählen Sie atleast ein Elternteil Beziehung.",
        BLANK_CHILD_RELATION: "Bitte wählen Sie atleast ein Kind-Beziehung",
        NO_PARENT_RELATION: "Sie brauchen nicht irgendeine Beziehung haben. Bitte erstellen Beziehung unter Anpassung von Allen Einstellungen",
        NO_PARENT_RELATION_FOR_USER: "Leider gibt es keine Beziehung zur Verfügung. Bitte kontaktieren Sie Ihren Administrator",
        NO_RELATIONSHIP_SETUP: "Sie haben noch keine Setup-Beziehungstypen noch",
        PLACEHOLDERTEXT_COMPANY: "Geben Sie einen Firmennamen und drücken Sie die Eingabetaste",
        PLACEHOLDERTEXT_NAME: "Geben Sie einen vollständigen Namen und drücken Sie die Eingabetaste",
        PLACEHOLDERTEXT_EMAIL: "Geben Sie eine E-Mail und drücken Sie die Eingabetaste",
    },
    GROUP: {
        NO_TOUCH_CAMPAIGN: "Nicht in jeder Touch-Kampagne verwendet.",
        NO_BROADCAST_CAMPAIGN: "Nicht in jeder Broadcast-Kampagne verwendet.",
        NO_AB_CAMPAIGN: "Nicht in jedem A / B-Kampagne verwendet.",
        NO_WEBFORM: "Nicht in jedem Web-Formular verwendet.",
        NO_LANDINGPAGE: "Nicht in jeder Zielseite verwendet.",
        DELETE_MSG: 'Die folgende Gruppe (n) kann nicht gelöscht werden',
        USED_IN_TOUCH_CAMPAIGN: 'wird in der folgenden Touch-Kampagne (n) verwendet',
        USED_IN_BROADCAST_CAMPAIGN: 'wird in der folgenden Broadcast-Kampagne (n) verwendet',
        USED_IN_AB_CAMPAIGN: 'wird in der folgenden A / B Kampagne (n) verwendet,',
        USED_IN_WEBFORM: 'wird in der folgenden Web-Formular (e) verwendet ',
        USED_IN_LANDINGPAGE: 'wird in der folgenden Landung Seite (n) verwendet',
        SELECT_GROUP: 'Wählen Sie eine oder mehrere Gruppen',
        NO_GROUP_AVAILABLE: 'Keine Gruppe zur Verfügung. Klicken',
        SELECT_RECORDS: 'ausgewählte Datensätze',
        ALL_RECORDS: 'Alle Einträge',
        SELECT_OPTIONS: 'wähle Optionen',
        ADD_NEW_GROUP: 'eine neue Gruppe hinzufügen',
        GROUP_DELETE_RECORD: 'Gruppe werden gelöscht. Bist du sicher?',
        COMMON_DELETE_MSG:" kann nicht gelöscht werden, da es in den folgenden Kampagnen verwendet wird:",
    },
    LOGIN: {
        VALIDATE_EMPTY_USER_NAME: 'Bitte geben Sie Ihre E-Mail und Passwort',
        VALIDATE_EMPTY_PASSWORD: 'Bitte geben Sie Ihr Passwort ein',
        VALIDATE_USERNAME_PASSWORD: 'Sie haben eine falsche E-Mail oder Passwort eingegeben',
        INVALID_RESPONSE: 'Ungültige Antwort',
        LOGIN_COPYRIGHTS_TEXT_1: 'Urheberrechte ©',
        LOGIN_COPYRIGHTS_TEXT_2: 'Soffront Software Inc. Alle Rechte vorbehalten.',
        BEST_VIEWED: 'Best in Google Chrome Mozilla Firefox und Safari angesehen.'
    },
    AUDIT_LEADSCORE: {
        NO_AUDIT_DATA: 'Kein Audit-Trail-Eintrag gefunden',
        NO_SCORE_HISTORY: 'Keine Geschichte anzuzeigen.'
    },
    DETAIL_VIEW: {
        CHANGE_PRIMARY_CONTACT_ALERT_1: 'Ihre Auswahl',
        CHANGE_PRIMARY_CONTACT_ALERT_2: 'als Hauptkontakt für',
        CLICK_TO_CONFIRM: 'Klicken Sie auf OK, um zu bestätigen.',
        MAX_SELECTED_FIELD_LIMIT: 'Sie erreichen die Höchstgrenze von 50 ausgewählten Feldern',
        SELECT_ATLEAST_ONE_FIELD: 'Bitte wählen Sie mindestens ein Feld ',
        SELECT_ATLEAST_ONE_CHILD_OBJECT: 'Bitte wählen Sie mindestens ein Kind-Objekt.',
        MAX_SELECTED_CHILD_OBJECT_LIMIT: 'Sie haben die Höchstgrenze von 10 ausgewählten Objekten.',
        NO_ASSOCIATED_GROUPS_FOUND: 'Keine zugeordneten Gruppen sind verfügbar',
        CONFIRM_UNSUBSCRIBE_RECORD: 'Die Bilanz wird abgemeldet. Bist du sicher?',
        BLANK_SPECIFIC_DATE: 'Datum kann nicht leer sein.',
        CONFIGURE_CHILD_SETUP: 'Bitte configure Kind-Setup.',
        CHILD_SETUP_SELECT_ATLEAST_ONE_FIELD: 'Bitte wählen Sie mindestens ein Feld für Kind-Setup.',
        EMAIL_FORMAT_MSG: "- Ungültiges Email-Format.",
        URL_FORMAT_MSG: "- Ungültiges URL-Format.",
        FLOAT_FORMAT_MSG: "- Ungültiges Float-Format.",
        INTEGER_FORMAT_MSG: "- Ungültiges Integer-Format.",
        ERROR_MSG: "Möchten Sie gehen, ohne die Änderungen zu speichern?",
    },
    CHARTS: {
        NOTHING_TO_SHOW: 'Nichts zu zeigen für diesen Zeitrahmen'
    },
    ADVANCE_SEARCH: {
        SEARCH_FIELDS_NOT_FOUND: 'Nichts zu suchen. Bitte einen Wert eingeben.',
        MAX_ADD_FIELD_ALERT:'Sie können nicht mit mehr als 10 Feldern suchen',
        MIN_ADD_FIELD_ALERT:'Für die Suche ist mindestens ein Feld erforderlich.',
        SELECT_FIELD_ALERT:'Bitte wählen Sie das Feld aus, bevor Sie den Wert eingeben.',
        ADVANCE_SEARCH: 'Erweiterte Suche',
        EMPTY_DATE: 'Bitte geben Sie Datum.',
        INVALID_DATE_RANGE: 'Ab Datum kann nicht mehr als bisher',
        EMPTY_FROM_TO_DATE: 'Bitte geben Sie Von Datum und Bis heute.',
        EMPTY_FROM_DATE: 'Bitte geben Sie Von Datum.',
        EMPTY_TO_DATE: 'Bitte geben Sie auf dem Laufenden.'
    },
    PUSH: {
        //PUSH_SUCCESS_MSG_1: 'This document will be pushed',
        PUSH_SUCCESS_MSG_1: 'Dokument wird gedrückt',
        //PUSH_SUCCESS_MSG_1: 'You have successfully pushed',
        PUSH_SUCCESS_MSG_2: 'an die ausgewählten Einheiten.',
        PUSH_SUCCESS_MSG_2_1: 'wird gedrückt',

        PUSH_SUCCESS_MSG_3: 'Eine E-Mail-Benachrichtigung wurde an die ausgewählten Einheiten gesendet.',

        PUSH_SUCCESS_MSG_4: 'Sie haben mehr als 20 Datensätze ausgewählt. Sie können maximal 20 drücken',
        PUSH_SUCCESS_MSG_4_1: 'zu einem Zeitpunkt.',
        PUSH_SUCCESS_MSG_5: 'Bitte wählen Sie eine Einheit zu drücken',
        PUSH_SUCCESS_MSG_6: 'Es sind keine verbundenen Clients zur Verfügung Push ',
        PUSH_SUCCESS_MSG_7: 'erfolgreich gedrückt.',
        PUSH_ALERT_MSG_8: 'Bitte wählen Sie mindestens eine Einheit zu Push-E-Mail-Vorlagen',
        PUSH_ALERT_MSG_9: 'Bitte wählen Sie mindestens eine Einheit Automation Kampagnen drücken',
        PUSH_ALERT_MSG_10: 'Bitte wählen Sie mindestens eine Einheit Broadcast-Kampagnen drücken',
        PUSH_SUCCESS_MSG_11: 'an die ausgewählte Einheit.',
        //Added by Anindya
        PUSH_SUCCESS_MSG_11_2: 'Bist du sicher?',
        //End
        PUSH_SUCCESS_MSG_12: 'Eine E-Mail-Benachrichtigung wurde an die ausgewählten Einheit gesendet.',
        PUSH_SUCCESS_MSG_13: 'Bitte wählen Sie mindestens eine Einheit Sales Leads drücken',
        PUSH_SUCCESS_MSG_14: 'Bitte wählen Sie mindestens eine Einheit Workflows drücken',
        PUSH_FOLDER: 'Mappe',
        FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS: 'Keine Zuordnung Regel erfüllt Sales Leads drücken',
        PUSH_ALERT_MSG_12: 'Bitte wählen Sie mindestens eine Einheit aus, um Vertragsvorlagen zu veröffentlichen.',
        LANDING_PAGES: 'Startseiten',
    },
    PROFILE_DETAILS: {
        UNMATCH_NEW_CONFIRM_PASSWORD: 'Bestätigt Passwort mit dem neuen Passwort stimmt nicht überein.',
        NO_NEW_PASSWORD: 'Geben Sie ein gültiges Passwort ein und bestätigen Sie es.',
        NO_CONFIRM_PASSWORD: 'Bestätigen Sie das neue Kennwort ein.',
        VALIDATE_PROFILE_IMAGE: 'Wählen Sie eine Bilddatei.',
        PROFILE_IMAGE_UPLOAD: 'Profil-Bild erfolgreich gespeichert.',
        PROFILE_IMAGE_UPLOAD_FAILURE: 'Profil-Bild kann nicht gespeichert werden.',
        PROFILE_UPDATE: 'Profil erfolgreich aktualisiert.',
        VALIDATE_PROFILE_IMAGE_SIZE: 'Das Foto, das Sie ausgewählt haben, ist zu groß, zu verwenden. Die maximale Dateigröße erlaubt ist 500 KB.',
        PROFILE_IMAGE_ATTACHMENT_LOGO_TEXT: 'Sie können eine jpg gif oder png-Datei hochladen.',
        PROFILE_IMAGE_SIZE_TEXT: ' Die maximale Dateigröße erlaubt ist 500 KB.',
        PROFILE_SIGNATURE_AUTOSAVED: 'Unterschrift erfolgreich gespeichert.'
    },
    CUSTOMIZATION: {
        LOCATION_VAL_NOT_MATCH: 'Standortwert stimmt nicht überein.',
        LOCATION_VAL_BLANK: 'Es gibt keinen Wert in dem Standortfeld.'
    },
    IMPORT: {
        SELECT_FILE: 'Bitte wählen Sie eine Datei aus',
        SELECT_GROUP: 'Bitte wählen Sie eine Gruppe',
        SELECT_WORKFLOW: 'Bitte wählen Sie einen Workflow',
        SELECT_STATUS: 'Bitte wählen Sie einen Status',
        SELECT_SOURCE: 'Bitte wählen Sie eine Quelle',
        SELECT_SECONDARY_SOURCE:'Bitte wählen Sie eine sekundäre Quelle',
        SOURCR_NAME_EXISTS: 'Wert existiert bereits in List',
        SOURCR_NAME_BLANK: 'Quellenname darf nicht leer sein!',
        IMPORT_FILE_EXT: 'Bitte wählen Sie Datei mit .csv oder .xls / xlsx-Erweiterung',
        NO_RECORD_TO_IMPORT: 'Kein Eintrag für den Import!',
        NO_MAPPING: 'Es gibt keine Zuordnung für ',
        NOT_ALL_MAPPED: 'Eine oder mehrere Spalten sind nicht auf CRM abgebildet. Möchten Sie ohne sie importieren diese Spalten fortsetzen?',
        IMPORT_CONFIRM_MSG: 'Datensätze in importiert werden',
        IMPORT_CONFIRM_MSG_1: 'Der Datensatz wird in importiert',
        CONFIRM: 'Bitte bestätigen.',
        SELECT_LINK_USING: 'Bitte wählen Sie eine Option für Link verwenden',
        SELECT_LINK_WITH: 'Bitte wählen Sie eine Option für die Verknüpfung mit',
        SELECT_DUPLICATE_FIELD: 'Bitte wählen Sie atleast eine Option für doppeltes Feld',
        SOURCE_ALERT_MSG_1: 'Bitte ordnen Sie das Konto Feld „Quelle“ zu einer Spalte, die Ihre Marketing-Quellen enthält.',
        SOURCE_ALERT_MSG_2: 'Sie haben Quelle aus der Dropdown-Liste gewählt sowie aus dem Kartierungsabschnitt. Sie können Quellfeldwert an einem Ort nicht an zwei Orten wählen.',
        NO_MAPPING_AVAILABLE: 'Es gibt keine Zuordnung zur Verfügung.',
        BLANK_MAP_NAME: 'Bitte geben Sie eine Karte Name!',
        VALID_MAP_NAME_LENGTH: 'Karte Name sollte nicht größer sein als 32 Zeichen!',
        DUPLICATE_MAP_NAME: 'Karte Name bereits vorhanden ist !!',
        MAP_SAVE_SUCCESS: 'Ihre Feldzuordnung wurde erfolgreich gespeichert!',
        NO_PREVIOUS_MAPPING: 'No Previous Mapping vorhanden!',
        MAP_NAME: 'Karte Name',
        DELETE_MAP_ALERT: 'Sind Sie sicher, dass Sie löschen möchten',
        DELETE_MAP_SUCCESS: 'Mapping erfolgreich gelöscht!',
        IMPORT_SUCCESS: 'Erfolgreich importiert.',
        WARNING: 'Warnung!',
        WARNING_TEXT: 'Bitte schließen Sie nicht diese Seite, bis der Import abgeschlossen ist.',
        IMPORTING_RECORDS: 'Importieren Sie Ihre Eintragung (en)',
        SUCCESS_IMPORT_COUNT: 'Eintragung (en) wurden importiert',
        FAIL_IMPORT_COUNT: 'Eintragung (en) zu importieren fehlgeschlagen',
        FOUND: 'Wir fanden',
        DUPLICATES: 'duplizieren (n)',
        APPLY_WORKFLOW_TEXT: 'zu den importierten Datensatz',
        APPLYING_WORKFLOW: 'Anwenden von Workflow',
        APPLIED_WORKFLOW: 'Abgeschlossen Anwendung Workflow',
        APPLIED_WORKFLOW_TO: 'Angewandter Workflow auf',
        MAX_IMPORT_RECORDS_MSG_1: 'Es gibt',
        MAX_IMPORT_RECORDS_MSG_2: 'Datensätze in der file.Presently können Sie 10000 Datensätze gleichzeitig importieren!',

        // not found in doc
        VERIFY_CONTACTS_MSG_1: 'Bitte überprüfen Sie, dass diese Kontakte, die Sie gegeben haben, ihre',
        VERIFY_CONTACTS_MSG_2: 'die Erlaubnis, sie zu mailen. Wir erlauben nicht vermietet oder ',
        VERIFY_CONTACTS_MSG_3: 'gekaufter Listen. Diese Kontakte müssen entweder gefragt',
        VERIFY_CONTACTS_MSG_4: 'von Ihnen E-Mails zu empfangen oder zu enge Beziehung haben mit',
        VERIFY_CONTACTS_MSG_5: 'deine Organisation.',

        FILE_TYPE_MSG: 'Die erste Zeile des CSV oder XLS / XLSX Datei muss die Feldnamen enthält.',
        DATE_FORMAT_MSG: 'Das Datum muss in MM / DD / YYYY-Format ',
        ACCOUNTS_AND_CONTACTS: 'Accounts und Kontakte',
        PARTNERS_AND_CONTACTS: 'Partner und Kontakte',
        IMPORT_CSV_FILE: 'Bitte wählen Sie Datei mit Erweiterung .csv.',
        RECORDS_IMPORT_SUCCESS: 'Aufzeichnungen wurden erfolgreich eingeführt.',
        GREEN_LIGHT_STATUS_ERROR: 'Es wird bereits ein Import ausgeführt, daher können Sie einen neuen Import erst nach Abschluss des aktuellen Importvorgangs starten.',
        GREEN_LIGHT_STATUS_SUCCESS: 'Ihr aktueller Import wird ausgeführt. Wir senden Ihnen eine Bestätigungs-E-Mail an die registrierte E-Mail der betroffenen Franchisenehmer, sobald dies erledigt ist.',
    },
    YOUTUBE_VIDEO: {
        EMPTY_VIDEO_LINK: 'Bitte fügen Sie eine Video-URL für YouTube',
        INVALID_VIDEO_LINK: 'Die eingegebene URL ist ungültig',
        INSERT_TITLE: 'Legen Sie Videos von YouTube',
        VIDEO_LINK_PLACEHOLDER: 'Fügen Sie eine Video-URL von YouTube'
    },
    WISTIA_VIDEO: {
        EMPTY_VIDEO_LINK: 'Bitte fügen Sie eine Video-URL für Wistia',
        INVALID_VIDEO_LINK: 'Die eingegebene URL ist ungültig',
        INSERT_TITLE: 'Legen Sie Video von Wistia',
        VIDEO_LINK_PLACEHOLDER: 'Fügen Sie eine Video-URL von Wistia'
    },
    INXPRESS_REPORT: {
        VALIDATE_EMPTY_FROM_DATE: 'Bitte wählen Sie aus Datum.',
        VALIDATE_EMPTY_TO_DATE: 'Bitte wählen Sie auf dem Laufenden.',
        VALIDATE_EMPTY_COUNTRY: 'Bitte wählen Sie Land.',
        VALIDATE_EMPTY_FRANCHISE: 'Bitte wählen Sie Franchise.',
        VALIDATE_EMPTY_WORKFLOW: 'Bitte wählen Sie Workflow.',
        VALIDATE_EMPTY_SALES_REP: 'Bitte wählen Sie Sales Rep.',
        VALIDATE_EMPTY_OBJECT_NAME: 'Bitte wählen Sie Bericht über.',
        NO_RECORD_EXPORT: 'Kein Eintrag für den Export zur Verfügung.',
        REPORT_NOT_READY_FOR_EXPORT: 'Bitte warten Sie, während der Bericht geladen Sie jetzt nicht exportieren können.',
        BLANK_FROM_TO_DATE: 'Datum kann nicht leer sein.',
        VALIDATE_EMPTY_COUNTRY_INXPRESS_REPORT16: 'Bitte wählen Sie ein Land, um fortzufahren.',
        VALIDATE_EMPTY_WORKFLOW_INXPRESS_REPORT: 'Bitte wählen Sie einen Workflow aus, um fortzufahren.',
        VALIDATE_EMPTY_REGION_INXPRESS_REPORT: 'Bitte wählen Sie eine Region aus, um fortzufahren.',
        VALIDATE_EMPTY_DATE_RANGE: 'Bitte wählen Sie einen Datumsbereich aus, um fortzufahren.',
        VALIDATE_EMPTY_WORKFLOW_STAGES: 'Bitte wählen Sie eine Workflow -Stufe aus, um fortzufahren.',
        VALIDATE_EMPTY_CHART_TYPE: 'Bitte wählen Sie einen Diagrammtyp aus, um fortzufahren.',
        VALIDATE_EMPTY_SHOW_TYPE: 'Bitte wählen Sie einen Showtyp aus, um fortzufahren.',
        VALIDATE_EMPTY_ACTIVITY_TYPE: 'Bitte wählen Sie einen Aktivitätstyp, um fortzufahren.',
    },

    OTHER_VIEW: {
        TENANT_MESSAGE: 'Ihr Abonnement nicht enthalten $$ @@ ## PRODUCT_NAME ## @@ $$',
        USER_MESSAGE: 'Bitte kontaktieren Sie Ihren Administrator $$ @@ ## PRODUCT_NAME ## @@ $$ Ihr Abonnement hinzufügen',
        AFTER_SUBSCRIPTION_VENDASTA_NOT_CONFIGURED: "Wir haben Ihr Konto für $$@@##PRODUCT_NAME##@@$$ noch nicht eingerichtet. Wir werden Sie benachrichtigen, sobald es verfügbar ist.Wir haben Ihr Konto für $$@@##PRODUCT_NAME##@@$$ noch nicht eingerichtet. Wir werden Sie benachrichtigen, sobald es verfügbar ist."
    },

    MY_INTEGRATION: {
        SAVE_SUCCESS: 'Ihre Einstellung erfolgreich gespeichert.',
        APPEND_INFO: 'Anfügen Firma und Kontaktinformationen zu Terminen von Soffront hinzugefügt',
        DISCONNECT_CONNECTION: 'Verbindung wird entfernt. Bist du sicher?',
        SAVE_FAIL: 'Ihre Einstellung wird nicht gespeichert.',
        NO_CALENDAR: 'Kalender ist nicht ausgewählt.',
        NO_SYNC_CHECKED: 'Sie haben keine der Synchronisierungsoptionen ausgewählt. Sie können Ihre E-Mails Kalender oder beide synchronisieren.',
        SYNC_EMAIL_INFO_1: 'Wählen Sie diese Option, um automatisch speichert die E-Mails von Ihrem G Suite / Office 365-Postfach in CRM.',
        SYNC_EMAIL_INFO_2: 'Die E-Mails werden gegen eine entsprechende Konto oder Kontaktdatensatz gespeichert werden.',
        SYNC_EMAIL_INFO_3: 'Die E-Mails werden alle 5 Minuten synchronisiert werden.',
        SYNC_CONTACT_INFO_1: 'Wählen Sie diese Option, um automatisch Ihre CRM-Kontakte zu G Suite / Office 365 hinzuzufügen.',
        SYNC_CONTACT_INFO_2: 'Änderungen, die Sie an die Kontakte in CRM machen wird automatisch in G Suite / Office 365 aktualisiert werden.',
        SYNC_CONTACT_INFO_3: 'Die Kontakte werden alle 5 Minuten synchronisiert werden.',
        SYNC_CALENDAR_INFO_1: 'Wählen Sie diese Option, um automatisch die Termine zwischen dem Soffront Kalender mit einem Kalender von Ihrem G Suite / Office 365-Konto synchronisieren.',
        SYNC_CALENDAR_INFO_2: 'Wenn Sie mehrere G Suite / Office 365 Kalender haben, müssen Sie eine auswählen, die Sie synchronisieren möchten.',
        SYNC_CALENDAR_INFO_3: 'Die Kalender werden alle 5 Minuten synchronisiert werden.',
        NO_CALENDAR_MESSAGE: 'Wir warten auf die Details Ihres Kalenders. Bitte schließen Sie diesen Bildschirm und kommen Sie in wenigen Minuten zurück. Wenn diese Meldung weiterhin angezeigt wird, trennen Sie Ihr Konto und verbinden Sie es erneut.',
        NO_PERMISSION: 'Die Integration ist in Ihrem aktuellen Plan nicht zur Verfügung. Bitte melden Sie sich einen geeigneten Plan oder Kontaktträger bei smbsupport@soffront.com.',
        SELECTED_CALENDAR_NOT_FOUND: 'Wir können Ihre prevoiusly ausgewählt calendar.Please wählen Sie ein anderes ein, und speichern Sie nicht finden.',
        INVALID_ACCOUNT_SYNC_STATE1: 'Ihre',
        INVALID_ACCOUNT_SYNC_STATE2: 'Verbindung ist nicht mehr gültig. Bitte trennen und erneut verbinden.',
        SEND_SALES_EMAILS_VALIDATION1: 'Um E-Mails versenden zu können, verwenden Sie',
        SEND_SALES_EMAILS_VALIDATION2: 'Sie müssen sich mit verbinden',
        SEND_SALES_EMAILS_VALIDATION3: 'Bitte trennen Sie die Verbindung und verbinden Sie sich erneut mit',
    },

    DOCUSIGN: {
        PROVIDE_USERNAME: 'Bitte geben Sie einen gültigen Benutzernamen.',
        PROVIDE_PASSWORD: 'Bitte geben Sie ein gültiges Passwort ein.',
        PROVIDE_INTEGRATOR_KEY: 'Bitte geben Sie einen gültigen Integrator Schlüssel.',
        SUCCESSFULLY_SAVED: 'Sie haben erfolgreich Ihr DocuSign-Konto konfiguriert.',
        FAILED_TO_SAVED: 'Kann Ihr DocuSign Konto konfigurieren.',
        DOCUSIGN_SETUP_TITLE_1: 'Wählen Sie ein Dokument und senden',
        DOCUSIGN_SETUP_TITLE_2: 'zu',
        DOCUSIGN_SETUP_TITLE_3: ' zur Unterschrift',
        PROVIDE_RECIPIENT_NAME: 'Bitte geben Sie Empfänger vollständigen Namen',
        PROVIDE_RECIPIENT_EMAIL: 'Bitte geben Sie E-Mail-Empfänger',
        PLEASE_SELECT_TEMPLATE: 'Bitte wählen Sie eine Vorlage.',
        NO_TEMPLATES_FOUND: 'Keine Vorlagen gefunden.',
        UNABLE_TO_SEND: "Unfähig Vorlage zu senden, da Ihr DocuSign Konto ist abgelaufen ist.",
        TEMPLATE_NAME_LENTH: 'Vorlagennamen nicht überschreiten 65 Zeichen müssen.',
        PLEASE_SELECT_CONTACT: 'Bitte wählen Sie mindestens einen Kontakt.',
        DOCUSIGN_CONTACT_MESSAGE_1: 'Für folgende Kontakte ist keine E-Mail verfügbar: ',
        DOCUSIGN_CONTACT_MESSAGE_2: 'E-Mail nicht an diesen Kontakt (n) gesendet werden.',
        DOCUSIGN_CONNECTED_MESSAGE_1: 'Leider haben Sie nicht die Erlaubnis, diesen Vorgang auszuführen. Bitte wenden Sie sich an Soffront.',
    },

    TWILIO: {
        CALL_OVER_SECURED_NETWORK: 'Sie können nur über ein gesichertes Netzwerk einen Anruf tätigen',
        SAME_TO_AND_FROM_NUMBER: 'Um Anzahl und Von-Nummer ist gleich. Der Anruf kann nicht gestartet werden.',
        CALLING_SUPPORT: 'Anrufe werden in Ihrem Land derzeit nicht unterstützt.',
        OUTBOUND_PHONE_NOT_VERIFIED: 'Ihre Outbound-Telefonnummer wird nicht überprüft. Bitte überprüfen Sie, bevor Sie einen Anruf tätigen.',
        CALL_RECORDING_SAVE: 'Erfolgreich gespeichert. Bitte melden Sie sich ab und melden Sie sich zurück in die Änderung zu sehen.',
        CALL_RECORDING_LAWS_AND_REGULATION_ALERT:'Bitte stellen Sie sicher, dass Ihre Anrufaufzeichnungspraktiken den geltenden Gesetzen und Vorschriften entsprechen.',
        NOTE_SAVE: 'Hinweis erfolgreich gespeichert',
        PHONE_LOW_BALANCE1: 'Ihr Telefon Waage ist jetzt ',
        PHONE_LOW_BALANCE2: ' Protokoll).',
        PHONE_LOW_BALANCE3: 'Sie brauchen mehr als 5 Minuten balancieren einen Anruf zu tätigen.',

        PHONE_BLANK_MESSAGE: 'Bitte geben Sie eine Telefonnummer ein.',
        PHONE_ALREADY_CONFIGURE: 'Diese Telefonnummer ist bereits konfiguriert.',
        DELETE_PHONE_NUMBER: 'Sind Sie sicher, diese Nummer löschen?',
        VERIFY_PHONE_NUMBER: 'Bitte überprüfen, bevor eine weitere Nummer hinzufügen.',
        PHONE_LIMIT: 'Sie können nicht mehr als 3 Telefonnummern hinzuzufügen.',
        VERIFICATION_CODE: 'An der Eingabeaufforderung bitte den Bestätigungscode einzugeben.',
        VERIFICATION_MESSAGE_1: 'Wir werden anrufen',
        VERIFICATION_MESSAGE_2: 'die Nummer zu überprüfen.',
        FIELDS_ADD_LIMIT: 'Sie können nicht mehr als 3 Felder hinzufügen.',
        SETUP_UPDATED_SUCCESSFULLY: 'Erfolgreich geupdated.',
        SETUP_UPDATED_FIELDS: 'Update fehlgeschlagen.'
    },
    CALL_SCRIPT: {
        BLANK_NAME: 'Bitte geben Sie einen Namen für dieses Skript.',
        BLANK_DETAILS: 'Bitte geben Sie die Skript Details.',
        SAVE_SUCCESS: 'Sie haben erfolgreich dieses Skript gespeichert.',
        UPDATE: 'Sie haben dieses Skript erfolgreich aktualisiert.',
        DELETE: 'Sie haben dieses Skript erfolgreich gelöscht.',
        NO_RECORD: 'Kein Eintrag vorhanden',
        'DUPLICATE_SCRIPT_NAME': 'Dieses Skript Name ist bereits vorhanden. Doppelte Skriptname ist nicht erlaubt.',
        'EMPTY_SCRIPT_NAME_DETAILS': 'Skriptnamen und Skript Details kann nicht leer sein.',
        'RECORD_NOT_FOUND_UPDATE': 'Nehmen Sie nicht zu Update gefunden.',
        DELETE_CONFIRMATION: 'Sind Sie sicher, dieses Skript zu löschen?'
    },
    SMS_INTEGRATION: {
        PURCHASED_NUMBER: 'Sie haben erfolgreich die Anzahl gekauft',
        SELECT_USER: 'Bitte wählen Sie mindestens einen Vertriebsmitarbeiter aus, um fortzufahren.',
        ENTER_ZIP_OR_AREA: 'Bitte geben Sie eine Postleitzahl oder Vorwahl.',
        ENTER_AREA: 'Bitte geben Sie die Vorwahl.',
        ENTER_VALID_ZIP: 'Tragen Sie eine gueltige Postleitzahl ein.',
        ENTER_VALID_AREA_CODE: 'Bitte geben Sie eine gültige Vorwahl.',
        NO_AVAILABLE_PHONE_NUMBERS: 'Es gibt keine Telefonnummer für die eingegebene Postleitzahl / Postleitzahlen.',
        NO_AVAILABLE_PHONE_NUMBERS_FOR_ZIP: 'Es gibt keine Telefonnummer für die eingegebenen Postleitzahlen.',
        REMOVE_NUMBER_CONFIRMATION: 'Sind Sie sicher, diese Nummer zu entfernen?',
        SMS_SUPPORT: 'Das Senden von Textnachrichten wird in Ihrem Land nicht unterstützt.',
        SELECT_NUMBER: 'Bitte wählen Sie eine Nummer.',
        SELECT_AT_LEAST_ONE_NUMBER:'Bitte wählen Sie mindestens eine Nummer zum Importieren aus',
        TWILIO_NUMBER_IMPORTED_SUCCESS:'Ihre Twilio-Nummer(n) wurden erfolgreich importiert',
        TWILIO_NUMBER_IMPORTED_ERROR:'Ihre Twilio-Nummer(n) konnten nicht importiert werden.',
        PHONE_NUMBER_CANNOT_BE_PURCHASED:'Telefonnummer kann nicht gekauft werden. Für weitere Informationen wenden Sie sich bitte an den Soffront-Support.',
        ENTER_SMS_TEXT: 'Bitte geben Sie SMS-Text.',
        SMS_LOW_BALANCE_1: 'Ihre SMS-Waage ist jetzt ',
        SMS_LOW_BALANCE_2: 'Sie müssen SMS-Guthaben zu senden SMS kaufen.',
        TWILIO_NUMBER_PURCHASE_MSG_1: 'Ihr Konto wird in Rechnung gestellt',
        TWILIO_NUMBER_PURCHASE_MSG_2: 'Monat',
        TWILIO_NUMBER_PURCHASE_MSG_3: 'für diese Telefonnummer. Sie können die Anzahl von Ihrem Konto jederzeit entfernen Sie die Abrechnung zu stoppen.',
        SMS_TOOLTIP_MESSAGE: 'Suchen Sie nach PLZ oder Vorwahl',
        SMS_TOOLTIP_MESSAGE_AREA_CODE: 'Suchen Sie nach Vorwahl',
        INSERT_IMAGE_FILE: 'Bitte wählen Sie nur jpg jpeg gif oder png-Datei',
        BATCH_SEND_SMS_MESSAGE: 'Nachricht erfolgreich gesendet',
        'INSUFFICIENT_BALANCE': 'Ungenügende SMS Gleichgewicht',
        'BATCH_SMS_SENT': 'Nachricht erfolgreich gesendet',
        'BATCH_SMS_FAILS': 'Fehlgeschlagene Textnachricht senden',
        NO_FROM_NUMBER_MESSAGE: 'Sie müssen eine Telefonnummer erwerben Textnachricht zu senden. Bitte kaufen Sie die Telefonnummer aus dem Integrations-Menü.',
        MAX_ATTACHMENT_FILE_FOR_SMS: 'Maximal 10 Attachment-Dateien erlaubt.',
        'NO_INPUT_STREAM_FOUND': 'Kein Eingang Stream ',
        'NO_ATTACHMENT_FOUND': 'Keine Befestigung GEFUNDEN ',
        'FILE_SIZE_ERROR': 'Die Dateigröße sollte weniger als 5 MB sein.',
        IMAGE_SELECT: 'Wählen Sie ein Bild',
        MERGE_FIELD: 'Wählen Sie Druckfelder',
        DUPLICATE_FILE_NAME: 'Diese Datei existiert bereits. Bitte wählen Sie eine andere Datei aus.',
        RECORD_UNSUBSCRIBE_ALERT: 'Ausgewählte Datensatz wird aus Texten abgemeldet. Sie können keine Textnachricht senden.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG: 'aus ausgewählten Datensätze wird von Texten abgemeldet. Text der Nachricht kann nicht an den unsubscribed Datensatz gesendet werden (s). Drücken Sie auf OK, um fortzufahren.',
        UNSUBSCRIBE_LIST_CONFIRM_MSG_1: 'aus ausgewählten Datensätze werden von Texten abgemeldet. Text der Nachricht kann nicht an den unsubscribed Datensatz gesendet werden (s). Drücken Sie auf OK, um fortzufahren.',
        'BATCH_SMS_SCHEDULE': 'Textnachricht wurde erfolgreich geplant',
        'BATCH_SMS_SCHEDULE_FAIL': 'Fehlgeschlagene Textnachricht planen',
        BATCH_SEND_SMS_MSG_1: 'Aus der',
        BATCH_SEND_SMS_MSG_2: 'Sie haben nur ausgewählte',
        BATCH_SEND_SMS_MSG_3: 'haben ausgewaehlt, um Ihre Textnachricht zu empfangen und hat das Handy-Nr. und gehört zur',
        BATCH_SEND_SMS_MSG_4: '. Wir würden die SMS-Nachricht an diese nur senden',
        BATCH_SEND_SMS_MSG_5: 'Drücken Sie auf OK, um fortzufahren.',
        SINGLE_SEND_SMS_MSG_1: 'Überprüfen Sie, ob ausgewählt',
        SINGLE_SEND_SMS_MSG_2: 'in hat sich entschieden, Ihre Textnachrichten oder den Mobil nicht zu erhalten. oder gehört',
        RESCHEDULE_SUCCESS: 'SMS wurde erfolgreich neu geplant',
        AUTOMATION_SMS_ADD_MSG: 'Sie haben erfolgreich diese SMS-Nachricht gespeichert',
        WORKFLOW_STATUS_MSG: 'Sie haben einen oder mehrere aktive Workflows, die diese Nummer zum Senden von Textnachrichten verwenden. Bitte aktualisieren Sie diese Workflows, um eine andere Nummer zu verwenden, bevor Sie diese Nummer entfernen.',
        SCHEDULED_STATUS_MSG: 'Sie haben eine oder mehrere inszenierte SMS, die diese Nummer verwenden. Klicken Sie auf OK, um alle Ihre bereitgestellten SMS abzubrechen und die Nummer zu entfernen.',
        REMOVE_SUCCESS_MSG: 'Die Nummer wurde erfolgreich entfernt.',
        NUMBER_SAVED_SUCCESS_MSG: 'Nummer erfolgreich gespeichert.',
        NO_LINKED_MOBILE_NUMBER_MSG: 'Der zugehörige Datensatz hat keine Handynummer. Sie können keine Textnachricht senden.',
        NUMBER_INVALID_MSG: "Die Handynummer des Kontakts ist falsch!",
        CANNOT_DELETE_UNITS_NUMBER: 'Sie dürfen diese Nummer nicht entfernen, da sie einer Einheit zugewiesen wurde.',
    },
    EVENTBRITE: {
        NO_ORGANISATION_SELECTED: 'Bitte wählen Sie atleast eine Organisation',
        NO_MAPPING_FOUND: 'Es gibt keine Zuordnung für CRM Kontakt und Eventbrite Felder.'
    },
    QUICKBOOKS: {
        NO_MAPPING_FOUND: 'Es gibt keine Zuordnung für CRM-Konten und Quick Books Kundenfeld.',
        COONECT_QB_MSG: 'Sie haben Ihr Konto verbunden Quickbooks. Klicken Sie auf OK, Ihr Konto zu verbinden.'
    },
    XERO: {
        SELECT_ONE_INTEGRATION_ITEM_AND_TYPE: 'Bitte wählen Sie ein Element und einen Integrationstyp zum Integrieren aus.',
        SELECT_ONE_INTEGRATION_ITEM: 'Bitte wählen Sie ein Element zum Integrieren aus.',
        SELECT_INTEGRATION_TYPE: 'Bitte wählen Sie den Integrationstyp aus.',
    },
    REPORT_SCHEDULE_DIALOG: {
        START_DATE_BLANK: 'Das Startdatum darf nicht leer sein.',
        INVALID_END_DATE: 'Das Startdatum darf nicht größer sein als oder gleich wie Enddatum.',
        DAY_CHECKBOX_BLANK: 'Zumindest einen Tag überprüfen.',
        SEND_TO_BLANK: ' Zumindest einen Benutzer auswählen.',
        SAVE_SUCCESS: "Erfolgreich gespeichert."
    },
    DOCUMENT_PUSH: {
        ALERT_MSG_1: 'Bitte wählen Sie mindestens ein Unternehmen spezialisiert auf Push-Dokumente',
        DOCUMENTS: 'Unterlagen)',
        FAIL: 'Fehler beim Push'
    },
    OPERATIONS_REPORT: {
        NO_CUSTOM_REPORT_MESSAGE: 'Sie haben keine benutzerdefinierten Berichte.'
    },
    PINNED_NOTES: {
        CONFIRM_MESSAGE: 'Dadurch wird die bestehende Abonnenten Notiz ersetzen. Klicken Sie auf OK, um fortzufahren oder zu verwerfen Abbrechen.'
    },
    SETTINGS: {
        EMPTY_NAME: 'Bitte kommen Sie herein',
        DUPLICATES_SOURCE_NAME: 'Quellenname existiert bereits!',
        DUPLICATE_SOURCE_NAME:'Die ausgewählte(n) Sekundärquelle(n) ist/sind bereits vorhanden. Bitte fügen Sie eine neue Sekundärquelle hinzu und speichern Sie diese.',
        CORPORATE_CURRENCY: 'Die gewählte Währung wurde erfolgreich aktualisiert.',
        INVALID_CURRENCY_NAME: 'Bitte wählen Sie eine gültige Firmenname Währung',
        SYSTEM_NOTE_TYPE_EDIT_ALERT: 'Sie können diesen Hinweis Typ nicht bearbeiten.',
        SYSTEM_APPOINTMENT_TYPE_EDIT_ALERT: 'Sie können diesen Termin Typ nicht bearbeiten',
        SYSTEM_NOTE_TYPE_DELETE_ALERT: 'Sie können diesen Hinweis Typ nicht löschen.',
        SYSTEM_APPOINTMENT_TYPE_DELETE_ALERT: 'Sie können diesen Termin Typ löschen.',
        SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT: 'Sie können diesen Kampagnentyp nicht löschen.',
        CORPORATE_CURRENCY_ALERT: 'Bitte wählen Sie eine Unternehmenswährung.',
        CORPORATE_CURRENCY_CONFIRM: 'Die gewählte Währung wird gespeichert. Bist du sicher?',
        'CORPORATECURRENCY': 'Wählen Sie eine Corporate-Währung',
        'DUPLICATE_NOTE_TYPES': 'Dieser Hinweis Typ bereits exists.Duplicate Hinweis Typen Schöpfung ist nicht erlaubt.',
        'DUPLICATE_APPOINTMENT_TYPES': 'Dieser Termin Art bereits exists.Duplicate Terminarten Schöpfung ist nicht erlaubt.',
        'INCORRECT_RECORD_ID': 'Falscher Rekord Id.Update fehlgeschlagen.',
        'RECORD_DOES_NOT_EXISTS': 'Nehmen Sie nicht exist.Delete Vorgang fehlgeschlagen.',
        'RECORD_CANNOT_DELETED': 'System oder Standard NoteTypes kann nicht gelöscht werden.',
        'DUPLICATE_CAMPAIGN_TYPES': 'Diese Kampagne Typ bereits exists.Duplicate Kampagnentypen Schöpfung ist nicht erlaubt.',
        'DUPLICATE_RELATIONS': 'Beziehungsname existiert bereits!',
        RELATION_LINK_INFO_ALERT_MSG: 'Es gibt Aufzeichnungen, die diese Beziehung folgen. Aufheben der Verknüpfung der jeweiligen Aufzeichnungen ersten, die diese Beziehung folgt und dann werden Sie in der Lage sein, diese Beziehung zu löschen.'
    },
    SOURCE:{
        PRIMARY_SOURCE_EMPTY:'Bitte wählen Sie eine Primärquelle aus, bevor Sie eine Sekundärquelle hinzufügen.',
        PRIMARY_SOURCE_EMPTY_VALIDATION:'Bitte geben Sie den Namen der Primärquelle ein',
        SECONDARY_SOURCE_EMPTY_VALIDATION:'Bitte geben Sie den Namen der Sekundärquelle ein',
        PRIMARY_SOURCE_CHANGE_VALIDATION:'Möchten Sie das Hinzufügen dieser Quelle verwerfen? Alle nicht gespeicherten Änderungen würden verloren gehen.',
    },
    RECYCLE_BIN: {
        SELECT_WORKFLOW: 'Bitte wählen Sie einen Workflow',
        SELECT_STAGE: 'Bitte wählen Sie eine Stufe',
        NOTE: 'Hinweis: Verknüpfte Datensätze des ursprünglichen Datensatzes wird nicht wiederhergestellt werden.',
        RESTORE: 'Nehmen Sie wiederherstellen erfolgreich'
    },
    UNSUBSCRIBE: {
        SELECTED_RECORD: 'Dieser Datensatz wird abgemeldet von ',
        ARE_YOU_SURE: '. Bist du sicher?',
        SELECTED_BOTH_RECORD: 'Dieser Datensatz wird von E-Mails und Texte unsubscribed sein. Bist du sicher?',
        'EMAILS_UNSUBSCRIBE': ' wird von E-Mails abbestellt werden. Bist du sicher?',
        'TEXTS_UNSUBSCRIBE': ' wird von Texten unsubscribed sein. Bist du sicher?',
        'BOTHS_UNSUBSCRIBE': ' wird von E-Mails und Texte unsubscribed sein. Bist du sicher?',
    },
    WORKFLOWS: {
        STATUS_CHANGED: 'Der Status wurde geändert, um ',
        'SAME_STATUS': 'Nehmen Sie bereits in',
        'FAILED_TO_CHANGE_WORKFLOW_STATUS': 'fehlgeschlagen Workflow-Status zu ändern.',
        ONLY_ONE_ACTIVE_WORKFLOW: 'Sie können diesen Workflow inaktiv machen. Dies ist der einzige aktive Workflow zur Verfügung. Eine Tabelle muss mindestens einen aktiven Workflow haben.',
        ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE: 'Sie können diesen Workflow nicht löschen. Dies ist der einzige aktive Workflow zur Verfügung. Eine Tabelle muss mindestens einen aktiven Workflow haben.',
        DELETED_WORKFLOW: 'Der Workflow nicht exist. Select Ok einen neuen Workflow zu wählen.',
        STATUS_BLANK_ALERT:'Status darf nicht leer sein',
        SALES_REP_BLANK_ALERT : 'Sales_rep darf nicht leer sein',
    },
    WORKFLOW_DESIGNER: {
        AUTOMATED_WORKFLOW_TITLE: 'Workflow Designer',
    },
    CHANGE_LOGO: {
        SELECT_IMAGE_TO_UPLOAD: 'Wählen Sie ein Bild zu ändern Logo',
        LOGO_IMAGE_UPLOAD_FAILURE: 'Company Logo konnte nicht gespeichert werden.',
        LOGO_IMAGE_UPLOAD: 'Company Logo wurde erfolgreich gespeichert.',
        VALIDATE_LOGO_IMAGE: 'Wählen Sie eine Bilddatei.',
        VALIDATE_LOGO_IMAGE_SIZE: 'Das Foto, das Sie ausgewählt haben, ist zu groß, zu verwenden. Die maximale Dateigröße erlaubt ist 500 KB.',
        LOGO_IMAGE_ATTACHMENT_LOGO_TEXT: 'Sie können eine jpg gif oder png-Datei hochladen.',
        LOGO_IMAGE_SIZE_TEXT: ' Die maximale Dateigröße erlaubt ist 500 KB.',
        WEBSITE_URL: 'Webseite',
        VALIDATE_LOGO_WEBSITE_URL: 'Möchten Sie Logo ohne gültige URL laden?'
    },
    ACCOUNTS: {
        DUPLICATE_MAIL_DOMAIN: 'Es besteht bereits ein Konto für dieses Unternehmen - möchten Sie stattdessen einen Kontakt hinzufügen?',
        DUPLICATE_FRANCHISE: 'Dies ist doppelte Franchisenehmer id Bitte ändern',
    },
    ASSIGNMENT_RULES: {
        NO_ASSIGNMENT_RULES: 'Keine Regeln zur Verfügung.',
        NO_NAME: 'Bitte geben Sie Zuweisungsrule Namen.',
        NAME_MAX_LENGTH: 'Zuordnungsregel Name darf nicht mehr als 64 Zeichen lang sein.',
        NO_CRITERIA: 'Bitte geben Sie mindestens ein Kriterium.',
        NO_USER: 'Bitte geben Sie mindestens einen Benutzer.',
        RULE_SAVE_SUCCESS: 'Zuordnungsregel erfolgreich gespeichert.',
        RULE_NAME_EXISTS: 'Name existiert bereits!',
        ACTIVE_RULE_EXCEEDS: 'Sie haben bereits 60 aktive Zuordnungsregeln.',
        SELECT_UNIT_MAXIMUM_FIELD: 'Sie können nur eine Einheit auswählen',
        SELECT_A_UNIT_WORKFLOW: 'Bitte wählen Sie eine Einheit Workflow.',
        NO_UNIT_USER: 'Bitte geben Sie mindestens eine Einheit.'

    },
    COUPONS: {
        CREATE_COUPON_NAME: 'Erstellen Sie einen internen Namen für Ihren Gutschein',
        CREATE_DISCOUNT_TYPE: 'Welche Art von Rabatt möchten Sie erstellen?',
        FIXED_AMOUNT: 'Fester Betrag',
        PERCENTAGE: 'Prozentsatz',
        PREFIX_COUPON_CODE: 'Geben Sie einen Präfix für den Gutscheincode',
        CAN_CLAIM_MORE_THAN_ONCE: 'Kann mehr als einmal durch das gleiche Konto verwendet?',
        CALIM_LIMIT_ONE: 'Nur einmal',
        CLAIM_LIMIT_UNLIMITED: 'Unbegrenzt',
        WHYEN_CLAIM_CODE: 'Coupon Ablaufdatum',
        CLAIM_ANYTIME: 'Läuft nie ab',
        CLAIM_BEFORE_DATE: 'Haltbarkeitsdatum',
        SAVE_SUCCESSFULLY: 'Coupon gespeichert erfolgreich',
        ACTIVE_SUCCESSFULL: 'Der Status wurde auf Aktiv geändert.',
        INACTIVE_SUCCESSFULL: 'Der Status wurde auf Inaktiv geändert.',
        DELETE_CONFIRMATION: 'Gutscheine werden gelöscht. Bist du sicher?',
        INVALID: 'ist ein ungültiges',
        DUPLICATE_COUPONS: 'Coupon Namen existiert bereits.',
        NEGATIVE_COUPONS: 'kann nicht negativ sein.',
        SPECIFY_DATE: 'Bitte geben Sie ein Datum.',
        PODUCT_SERVICE: 'Produkt / Dienstleistung',
    },
    NOTIFICATION_REMINDERS: {
        SAVE_SUCCESS: 'Zur Erinnerung erfolgreich hinzugefügt',
        INVALID_DAY: 'Geben Sie einen gültigen Tag (e)',
        DUPLICATE_REMINDER: 'Dies ist doppelte Erinnerung Bitte ändern',
        INVALID_DATE_FIELD: 'Bitte wählen Sie ein Datumsfeld', // changed
        DELETE_SUCCESS: 'Zur Erinnerung erfolgreich gelöscht',
        UPDATE_SUCCESS: 'Erinnerung erfolgreich aktualisiert',
    },
    EMAIL_INTEGRATION: {
        DUPLICATE_EMAIL: 'Diese Emailadresse existiert bereits!',
        EMAIL_SAVE_SUCCESS: 'E-Mail-Adresse erfolgreich gespeichert',
        SEND_VERIFICATION_EMAIL_SUCCESS_1: 'Wir haben die Überprüfung E-Mail gesendet. Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf',
        SEND_VERIFICATION_EMAIL_SUCCESS_2: 'Überprüfen Sie diese E-Mail-Adresse. ',
        SEND_VERIFICATION_EMAIL_SUCCESS_3: 'Dann kommen Sie zurück, und klicken Sie auf das Aktualisierungssymbol den Status Ihrer E-Mail-Adresse zu sehen.',
        EMAIL_INTEGRATION_VERIFIED: 'Diese E-Mail-Adresse wurde bereits überprüft!',
        CONFIRM_DELETE_EMAIL: 'Die ausgewählte(n) E-Mail-Adresse(n) wird/werden gelöscht. Bist du sicher?',
        INVALID_EMAIL: 'Ungültige E-Mail-Adresse',
        EMAIL_CANT_BLANK: 'E-Mail-Adresse darf nicht leer sein!',
        EDIT_EMAIL_SAVE_SUCCESS: 'E-Mail erfolgreich gespeichert',
    },
    NEWS: {
        SAVE_ERROR: 'Speichern nicht möglich.',
        VALIDATE_CATEGORY: 'Diese Kategorie kann nicht leer sein.',
        VALIDATE_STATUS: 'Der Status kann nicht leer sein.',
        VALIDATE_TITLE: 'Der Titel kann nicht leer sein.',
        VALIDATE_SUMMARY: 'Zusammenfassung kann nicht leer sein.',
        VALIDATE_EXTERNAL_LINK: 'Externer Link kann nicht leer sein.',
        VALIDATE_DETAILS: 'Details können nicht leer sein.',
        CATEGORY_NAME_EXISTS: 'Wert existiert bereits in List.',
        DUPLICATE_TITLE: "Dieser Titel existiert bereits!",
    },
    USEFUL_LINKS: {
        CATEGORY: 'Kategorie',
        LINK: 'Verknüpfung',
        TITLE: 'Titel',
        VALID_CATEGORY: 'Bitte wählen Sie eine gültige Kategorie',
        DUPLICATE_CATEGORY: 'Dies ist doppelt Kategorie Bitte ändern',
        DUPLICATE_TITLE: 'Dies ist ein doppelter Titel in derselben Kategorie. Bitte ändern.',
        DUPLICATE_CATEGORY_MSG:'existiert bereits eine Kategorie mit demselben Titel. Bitte ändern Sie den Kategorietitel.',
        CONFIRM_DELETE_CATEGORY: 'Kategorie werden gelöscht. Bist du sicher?',
        CONFIRM_DELETE_LINK: 'Link wird gelöscht. Bist du sicher?',
        VALID_URL: 'Bitte geben Sie eine gültige URL ein.',
        EXISTING_LINKS_CATGORY: 'Sie haben bestehende Links unter dieser Kategorie. ',
        PLEASE_MOVE_LINKS: 'Bitte bewegen Sie die Links',
        CLICK_TO_CONFIRM: ' Bestätigen Sie mit OK.',
    },
    HUB_SETUP: {
        VALID_URL: 'Bitte geben Sie eine gültige',
        URL: 'URL.',
        COMMUNITY: 'Community-URL.',
        MANUAL: 'Manuelle URL.',
        TRAINING: 'Training URL.',
        INVALID_HUB_NAME: 'Plaese geben Sie einen gültigen Hub Namen',
    },
    CASE: {
        CASE_CLOSED_SUCCESS: 'Fall geschlossen erfolgreich'
    },
    ORTHOTIC_REPORT: {
        VALIDATE_EMPTY_PRODUCT: 'Bitte wählen Sie ein Produkt.'
    },
    USER_REPORT: {
        REPORT_DELETE_RECORD: 'Bericht wird gelöscht. Bist du sicher?',
        REPORT_DELETE: 'Bericht wurde erfolgreich gelöscht',
        INVALID_START_END_DATE: 'Das Startdatum darf nicht größer sein als Enddatum.'
    },
    AUTO_FILL_VALUES: {
        CHNAGE_PARENT_OBJECT: 'Wollen Sie wirklich das übergeordnete Objekt ändern?',
        CHANGE_CHILD_OBJECT: 'Wollen Sie wirklich das Kind Objekt ändern?',
        SELECT_PARENT_OBJECT: 'Wählen Sie ein übergeordnetes Objekt.',
        SELECT_CHILD_OBJECT: 'Wählen Sie ein Kind Objekt.',
        NO_MAPPING_FIELDS: 'Keine Zuordnung Felder zur Verfügung zwischen ',
        NO_MAPPING_FIELDS_AND: 'und',
        CHILD_FIELD_SIZESHOULD_GREATER: 'Kinderfeldgröße sollte größer sein als Eltern Feldgröße sein.',
        MAPPING_FROM: 'Zuordnen von ',
        MAPPING_TO: ' zu ',
        NO_AVAILBLE_FIELDS_FOR_MAPPING: '. Es gibt kein verfügbares gemeinsames Feld für Standard-Mapping.',
        NO_AVAILABLE_PARENT_CHILD_MAPPING: 'Es gibt keine verfügbaren Eltern-Kind-Feldzuordnung.',
        FIELD_MAPPING_DONE_SUCCESS: 'Feldzuordnung erfolgreich getan.',

        MAP_FIELDS_TO_AUTOFILL: 'Ordnen Sie die Felder zu auto-fill ',
        MAP_FIELDS_TO_AUTOFILL_FROM: ' von ',
        SELECT_PARENT_FIELD: 'Wählen Sie ein Elternteil Feld.',
        SELECT_CHILD_FIELD: 'Wählen Sie ein Kind Feld.',
        MAPPING_NOT_POSSIBLE: ' Ist nicht möglich.'
    },
    LANDING_PAGE: {
        INVALID_NAME: 'Bitte geben Sie Zielseite Namen.',
        INVALID_URL_WARNING: 'Bitte geben Sie die URL-Namen.',
        SAVE: 'Zielseite erfolgreich gespeichert.',
        UPDATE: 'Zielseite erfolgreich aktualisiert.',
        INVALID_FORM_BLOCK_EDIT_MSG: 'Aktuelle aktiver Block hat keine Formularfelder zu bearbeiten wählen Sie bitte Formularfelder zu bearbeiten blockieren.',
        DUPLICATE_NAME: 'Zielseite existiert bereits mit diesem Namen. Bitte geben Sie einen anderen Namen und versuchen Sie es erneut.',
        DUPLICATE_URL: 'Zielseite existiert bereits mit dieser URL. Bitte geben Sie eine andere URL und versuchen Sie es erneut.',
        INPUT_DATA_LOSS_MSG: 'Alle eingegebenen Daten werden gelöscht. Bist du sicher?',
        EMAIL_REQUIRED_MSG: 'E-Mail ist erforderlich.',
        ATLEAST_ONE_BLOCK_ADD_MSG: 'Bitte fügen Sie mindestens ein Design Block Diese Seite zu speichern.',
        PREVIEW_ALERT_MSG: 'Bitte speichern, bevor die Zielseite der Vorschau.',
        OPEN_URL_ALERT_MSG: 'Bitte speichern Sie die Änderungen vor, die Aktion durchzuführen.',
        COPY_TO_CLIPBOARD: 'Landing Seitenvorlage Link in die Zwischenablage kopiert.',
        FORM_ADDED_MSG: 'Form wurde hinzugefügt.',
        FORM_UPDATED_MSG: 'Form erfolgreich aktualisiert.',
        INVALID_URL: 'Ungültige URL.',
        LABEL_FIELD_CANNOT_BLANK: 'Beschriftungsfeld kann nicht leer sein.',
        FIELD_IS_ALREADY_ADDED: 'Feld ist bereits hinzugefügt.',
        MINIMUM_ONE_FIELD: 'Mindestens ein Feld ist erforderlich.',
        NO_MORE_FIELD_AVAILABEL: 'Keine weitere Felder zur Verfügung.'
    },
    DOWNLOAD_LEADS: {
        SELECT_CRITERIA: 'Bitte wählen Sie atleast ein Kriterium.',
        SELECT_WORKFLOW: 'Bitte wählen Sie einen Workflow.',
        SELECT_GROUP: 'Bitte wählen Sie eine Gruppe.',
        SELECT_STATUS: 'Bitte wählen Sie Status.',
        SELECT_SALESREP: 'Bitte wählen Sie einen salesrep.',
        LEAD_EXIST_MSG_1: 'Sie haben bereits diese Leitungen in Ihrem Konto - ',
        LEAD_EXIST_MSG_2: '.Click Ok den Download und entfernen Sie diese Leads aus Ihrer Auswahl zu stoppen.'
    },
    SCHEDULER: {
        BLANK_APPOINTMENT_NAME: 'Bitte geben Sie Vorname, Name.',
        BLANK_LOCATION: 'Bitte geben Sie Ort.',
        SAVE_SUCCESS: 'Scheduler Setup erfolgreich gespeichert.',
        SAVE_FAIL: 'Scheduler Setup couldnot gespeichert werden.',
        NO_SCHEDULER: 'Bitte erstellen Sie einen Terminplaner vor Scheduler Link.',
        VALIDATE_TIME: 'Startzeit kann nicht größer sein als oder gleich wie Endzeit.',
        NO_START_TIME: 'Bitte wählen Sie Startzeit.',
        NO_END_TIME: 'Bitte wählen Sie die Endzeit.',
        SCHEDULER_LINK_COPIED_SUCCESS: 'Scheduler Link in die Zwischenablage kopiert.',
        BLANK_SUBMIT_BUTTON: 'Button-Beschriftung kann nicht leer sein.',
        CALENDAR_SYNC_MSG: 'Ihr CRM-Kalender ist nicht mit Ihrem GSuite oder Office 365 Kalender.',
        CALENDAR_SYNC_LINK: 'Klicken Sie hier, um es einzurichten.',
        SCHEDULER_UNSAVE_msg: 'Wollen Sie dieses Formular verlassen? Vorgenommenen Änderungen werden nicht gespeichert.',
    },
    RELATION_REPORT: {
        RANGE_FIELD_BLANK: 'Bitte wählen Sie ein Datumsfeld.',
        ROW_FIELD_BLANK: 'Bitte wählen Sie ein Zeilenfeld.',
        COL_FIELD_BLANK: 'Bitte wählen Sie eine Spalte Feld.',
        REPORT_NAME_BLANK: 'Bitte geben Sie Berichtsnamen.',
        REPORT_SAVE_SUCCESS: 'Bericht wurde erfolgreich gespeichert.',
        DUE_BY_BLANK: 'Bitte wählen Sie einen Datumsbereich.',
        NO_ROW_FIELD_VALUE: 'Bitte wählen Sie einen Wert für die Zeilenbeschriftung.',
        NO_COLUMN_VALUE: 'Bitte wählen Sie einen Wert für die Spaltenüberschrift.',
        SAME_ROW_COLUMN_FIELD: 'Zeilenfeld und Spaltenfelder können nicht gleich sein.',
        INVALID_END_DATE: 'Das Startdatum darf nicht größer sein als Enddatum.',
        'Report Name already exists': 'Berichtsname ist bereits vorhanden.',
        VALID_REPORT_NAME: 'Bitte geben Sie einen gültigen Berichtsnamen ein'
    },
    PAYMENTS: {
        NOT_ADDED_QB: 'Das Angebot / Rechnung wird nicht dennoch auf die hinzugefügt Quickbook!',
        NO_LINKED_AVAILABLE: 'Es gibt keine verknüpfte Angebot / Rechnung verfügbar!',
        PAYMENT_VALUE_ALERT_MSG: 'Zahlungs Wert darf nicht Null sein.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD: 'Sie haben eine offene Angebots-/Rechnungsregisterkarte. Bitte schließen Sie diese Registerkarte, bevor Sie ein neues Angebot/eine neue Rechnung öffnen oder hinzufügen.',
        AMOUNT_EXCEED_BALANCE_DUE: 'Die von Ihnen eingegebene Zahlung übersteigt den für diese Rechnung fälligen Saldo. Klicken Sie auf OK, um die Zahlung fortzusetzen. Klicken Sie auf Abbrechen, um den Zahlungsbetrag zu ändern.'

    },
    PRODUCTS: {
        DUPLICATE_PRODUCT: 'Produktname ist bereits vorhanden.',
        ADDED_TO_QUICKBOOK: 'Erfolgreich in dem Quickbooks.',
        PULLED_FROM_QUICKBOOKS: 'Erfolgreich von Quickbooks gezogen.',
        PRODUCT_PRICE_VALUE_ALERT_MSG: 'Preiswert kann nicht Null sein.',
        PRODUCT_NAME_COULDNOT_CONTAIN_COLON_MSG: 'kann nicht Doppelpunkt (:) enthalten.',
        NEGATIVE_PRICE: 'Preis kann nicht negativ für den Bestandstyp sein.'
    },
    COMPANY_PROFILE: {
        BLANK_BUSINESS_NAME: 'Bitte geben Sie den Firmennamen.',
        BLANK_BUSINESS_ADDRESS: 'Bitte geben Sie die Geschäftsadresse.',
        BLANK_BUSINESS_PHONE: 'Bitte geben Sie Business-Telefon.'
    },
    EXPORT_SETTINGS: {
        CONSIDERATION: 'Erwägung:',
        CONSIDERATION_MSG1: 'Wir unterstützen EXCEL & CSV-Dateien formatiert.',
        CONSIDERATION_MSG2: 'Eine Zelle in Excel kann 32.767 Zeichen daher in der exportierten Excel-Datei zeigen wir maximal 32.767 Zeichen für eine Zelle enthalten maximal und verstehen sich inklusive der Charaktere ruhen.',
        NO_RECORDS_FOUND: 'Keine Aufzeichnungen gefunden!',
        FILE_NAME_IS_EMPTY: 'Dateiname ist leer.',
        PLEASE_SELECT_THE_FILE_TYPE: 'Bitte wählen Sie den Dateityp.',
        PLEASE_SELECT_THE_PARENT_OBJECT: 'Bitte wählen Sie das übergeordnete Objekt.',
        PLEASE_SELECT_THE_QUERY_NAME: 'Bitte wählen Sie den Namen der Abfrage.',
        PLEASE_SELECT_THE_PARENT_FIELDS: 'Bitte wählen Sie die übergeordneten Felder aus.',
    },
    DATA_BASE: {
        FAILURE_MSG: 'Fehler beim Speichern.',
        SUCCESS_MSG: 'Ihre Einstellung wurde erfolgreich gespeichert. Bitte melden Sie sich ab und melden Sie sich zurück in die Änderungen zu sehen.',
        VALIDATION_MSG: 'Bitte wählen Sie mindestens ein untergeordnetes Objekt für aus ',
        VALIDATION_MSG1: "Sie haben die maximale Anzahl von 15 ausgewählten Objekten erreicht.",
        SUCCESS_MSG1: 'Ihre Einstellung wurde erfolgreich gespeichert.',
    },
    UNITS: {
        BLANK_FIRST_NAME: 'Geben Sie Ihren Vornamen.',
        BLANK_LAST_NAME: 'Bitte geben Sie Nachnamen ein.',
        BLANK_PASSWORD: 'Bitte Passwort eingeben.',
        UNMATCH_RETYPE_PASSWORD: 'Passwort erneut eingeben nicht mit dem Kennwort übereinstimmen.',
        BLANK_RETYPE_PASSWORD: 'Bitte geben Sie Passwort wiederholen.',
        BLANK_EMAIL: 'Bitte geben Sie E-Mail.',
        BLANK_PHONE: 'Bitte geben Sie Telefon.',
        BLANK_COMPANY: 'Bitte geben Sie Unternehmen.',
        UNIT_SAVED: 'Erfolgreich gespeichert.',
        UNIT_NOT_SAVED: 'Einheit kann nicht gespeichert werden.',
        DUPLICATE_EMAIL: 'Sie haben bereits Eintragung (en) mit der gleichen E-Mail-ID.',
        DUPLICATE_COMPANY: 'Firmenname bereits vorhanden ist.',
        SUBSCRIPTION_NOT_VALID: 'Das Abonnement ist für das ausgewählte Gerät nicht gültig. Bitte kontaktieren Sie den Soffront-Support für weitere Informationen.',
        NO_UNIT_TASKS: 'Für den Einheitenbesitzer ist keine Aufgabe verfügbar.',
        NO_ACCOUNT_TASKS: 'Für das Konto ist keine Aufgabe verfügbar.',
        BLANK_FAMILY_INDUSTRY: 'Bitte wählen Sie eine Familienbranche',
        NO_FAMILY_INDUSTRY: 'Sie benötigen eine Basisvorlage, bevor Sie neue Einheiten hinzufügen können. Bitte wenden Sie sich an den BrandWide-Support, um eine Basisvorlage einzurichten.',
        BLANK_PLAN_NAME: 'Bitte wählen Sie einen Plannamen aus.',
        NO_AVAILABLE_PLANS: 'Für diese Einheit sind keine Pläne verfügbar, die registriert werden können.',
        NO_BRANCH_WORKFLOW: 'Es ist kein Zweig-Workflow verfügbar.',
        PLEASE_SELECT_TYPE: 'Bitte wählen Sie einen Typ aus, um fortzufahren.',
        NO_BASE_LOGIN: 'Basis existiert nicht oder ist nicht konfiguriert! Beheben Sie das Problem und versuchen Sie es erneut.',
        NO_ONBOARDING_WORKFLOW: 'Es ist kein Onboarding-Workflow verfügbar.',
        TENANT_PASSWORD_NOT_SET_YET: 'Password is not set for the selected unit.',
    },
    SHARE: {
        SHARE_SUCCESS_MSG_1: 'Aktieninformationen erfolgreich aktualisiert.',
        SHARE_SUCCESS_MSG_SL: 'Die Freigabeinformationen werden für die ausgewählten Sicherheitsstufen erfolgreich aktualisiert, sofern vorhanden.',
        SHARE_SUCCESS_MSG_2: 'E-Mail-Benachrichtigung wird an die neu gewählten Einheiten gesendet werden, wenn es irgendein.',
        SHARE_SUCCESS_MSG_3: 'E-Mail-Benachrichtigung wird an die neu gewählte Einheit gesendet werden, wenn es irgendeine.',
        SHARE_SUCCESS_MSG_4: 'Bist du sicher?',
        SHARE_SUCCESS_MSG_5: 'wird auf die gewählte Einheit geteilt werden.',
        SHARE_SUCCESS_MSG_6: 'wird an die ausgewählten Einheiten geteilt werden.',
        SHARE_FAILED_MSG: 'Fehler beim Aktie.',
        SHARE_SELECT_CORPORATE_DOCUMENT: 'Bitte wählen Sie mindestens einen Unternehmensbenutzer aus, um Dokumente freizugeben.',
        SHARE_SELECT_CORPORATE_IMAGE: 'Bitte wählen Sie mindestens einen Unternehmensbenutzer aus, um das Bild zu teilen.',
        SHARE_SELECT_UNIT_DOCUMENT: 'Bitte wählen Sie mindestens eine Einheit zur gemeinsamen Nutzung von Dokumenten.',
        SHARE_SELECT_UNIT_IMAGE: 'Bitte wählen Sie mindestens eine Einheit zu teilen Bild.',
        NO_CONNECTED_CLIENT: 'Es sind keine verbundenen Clients zu teilen Dokumente zur Verfügung.',
    },
    SECURITY_LEVEL: {
        ERROR_MSG_1: 'Hoppla! Etwas ist schief gelaufen. Bitte versuch es später.',
        ERROR_MSG_2: 'Beschreibung kann nicht leer sein.',
        SUCCESS_MSG_1: 'Ihre Einstellung wurde erfolgreich gespeichert.',
    },
    WHITE_LABEL: {
        LINK_SAVE: 'Die Verbindung wurde erfolgreich gespeichert.',
        NOT_VALID_URL: 'ist keine gültige URL'
    },
    ROLL_UP: {
        SELECT_A_CHILD_OBJECT: 'Bitte wählen Sie ein Child-Objekt',
        SELECT_A_PARENT_OBJECT: 'Bitte wählen Sie ein übergeordnetes Objekt',
        NO_CHILD_FIELD_SELECTED: 'Kein Kind Feld selcted',
        NO_PARENT_FIELD_SELECTED: 'Kein Elternteil Feld selcted',
        SAME_TYPE_FIELD: 'Bitte wählen Sie gleiche Art von Feldern für Kind und Eltern-Objekte',
        ROLL_UP_EXIST: 'Gleiche aufrollen Feldkombination bereits vorhanden',
        PARENT_FIELD_EXIST: 'Bereits ein Roll up wurde das ausgewählten Eltern-Objektfeld konfiguriert.',
    },
    ROYALTY_REPORT: {
        BLANK_ROYALTY_PERCENT: 'Bitte geben Sie lizenzgebührende Prozentsätze ein.',
        REPORT_SAVE_SUCCESS: 'Bericht wurde erfolgreich gespeichert.',
        SETUP_SAVE_SUCCESS: 'Lizenzgebühren-Setup erfolgreich gespeichert.'
    },
    MENU: {
        ATLEAST_ONE_MODULE_ACTIVE: 'Entschuldigung, mindestens ein Modul sollte aktiv sein.',
        ATLEAST_ONE_MODULE_ITEM_ACTIVE: 'Leider sollte mindestens ein Modulelement aktiv sein.',
        MODULE_LABEl_CHAR_LIMIT_MSG: 'Der Name darf nicht länger als 32 Zeichen sein.',
        DUPLICATE_NAME: 'Modulname ist bereits vorhanden.'
    },
    SALES_PURCHASE_ORDER: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD: 'Sie haben eine offene Bestellregisterkarte. Bitte schließen Sie diese Registerkarte, bevor Sie eine neue Bestellung hinzufügen.',
        RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD: 'Sie haben eine offene Registerkarte für Kundenaufträge. Bitte schließen Sie diese Registerkarte, bevor Sie einen neuen Kundenauftrag hinzufügen.',
        SAVE_PURCHASE_SUCCESS: 'Bestellung erfolgreich gespeichert.',
        SAVE_SALES_SUCCESS: 'Kundenauftrag erfolgreich gespeichert.',
        SALES_REP_NAME_CANT_BLANK: 'Der Name des Vertriebsmitarbeiters kann nicht ausgeblendet werden.',
        SELECT_ONE_ITEM: 'Bitte wählen Sie eine Artikelinformation.',
        DISCOUNT_AMOUNT_CANT_GREATER: "Der Rabattbetrag darf die Zwischensumme nicht überschreiten.",
        SPECIFY_TRANSC_AMOUNT: 'Sie müssen einen Transaktionsbetrag von 0 oder mehr angeben.',
        SETUP_COMPANY_PROFILE: 'Bitte richten Sie Ihr Firmenprofil ein, bevor Sie Angebote hinzufügen. Bitte wenden Sie sich an Ihren CRM-Administrator, um das Profil einzurichten.'


    },
    JOBS: {
        RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD: 'Sie haben eine offene Jobregisterkarte. Bitte schließen Sie diese Registerkarte, bevor Sie einen neuen Job hinzufügen.',
        FIELDS_CANNOT_BLANK: ' Feld darf nicht leer sein.',
        PRODUCT_NAME_NOT_CONTAIN: 'Der Produktname darf keinen Doppelpunkt (:) enthalten.',
        JOB_SAVE_SUCCESS: 'Auftrag erfolgreich gespeichert.',
        PRODUCT: 'Produkt',
        ALREADY_EXIST: 'ist bereits vorhanden.',
    },
    POWER_CALLING: {
        YOU_DONT_HAVE_CALL_SCRIPT: "Sie haben keine Anrufskripte.",
        ALERT_MSG_1: 'Aus dem ',
        ALERT_MSG_2: ' Sie haben nur ausgewählt ',
        ALERT_MSG_3: ' gültige lokale Nummern haben. Sie können diese anrufen ',
        ALERT_MSG_4: 'Sprach mit ',
        ALERT_MSG_5: "Es gibt keine mehr",
        ALERT_MSG_6: "in der Warteschlange, um einen Anruf zu tätigen.",
        ALERT_MSG_7: 'Sie haben keine aktive Verbindung.',
        ALERT_MSG_8: 'Sie haben ausgewählt, keiner von ihnen hat eine Handy- oder Telefonnummer.',
        ALERT_MSG_9: 'Sie haben bereits einen geöffneten Power Dialer. Schließen Sie ihn, um einen neuen Power Dialer zu öffnen.',
        ALERT_MSG_10 :'Um die Registerkarte zu schließen, klicken Sie oben auf der Registerkarte auf das Symbol „Schließen“.'
    },
    LEAD_SCORE_SETUP: {
        ALREDY_DEFINED_SCORE: 'Sie haben bereits eine Punktzahl für diese Aktion definiert.',
        SAVE_SUCCESSFULLY: 'Die Punktzahl wurde erfolgreich gespeichert.',
        CAN_NOT_UPDATE: 'Die Score-Einstellungen können nicht aktualisiert werden. Bitte ändern Sie die Score-Einstellungen korrekt. ',
        SELECT_ONE_ACTION: 'Bitte wählen Sie mindestens eine Aktion aus',
        SELECT_ACTION: 'Bitte wählen Sie eine Aktion aus',
        SELECT_OBJECT: 'Bitte wählen Sie ein Objekt aus',
        SELECT_WORKFLOW: 'Bitte wählen Sie einen Workflow aus',
        CONVERT_FROM_CANT_BLANK: 'Konvertiert von kann nicht leer sein',
        CONVERT_TO_CANT_BLANK: 'Konvertiert in kann nicht leer sein',
        CHOOSE_DIFF_STATUS: 'Sie können nicht denselben Status konvertieren. Bitte wählen Sie einen anderen Status. ',
        SELECT_GROUP: 'Bitte wählen Sie eine Gruppe aus',
        SELECT_FIELD: 'Bitte wählen Sie ein Feld',
        PROVIDE_VALUE_TO_UPDATE: 'Bitte geben Sie einen Wert an, den Sie aktualisieren möchten',
        ENTER_SCORE: 'Bitte geben Sie eine Punktzahl zwischen 1-99 ein',
        EXIST_IN_LIST: 'Wert existiert bereits in Liste',
        SCORE_DELETED: 'Diese Punktzahl wird gelöscht. Bist du sicher?',
        REMOVE_MSG: 'erfolgreich entfernen',
        UPDATE_MSG: 'Update erfolgreich',

    },
    DUPLICATE_RULE: {
        ERROR_MSG_DEFAULT: 'Sie können keine Standardregel deaktivieren.',
        ERROR_MSG_Active: 'Sie können keine Standardregel deaktiv.',
        ERROR_MSG_FOR_FIELDS: 'Wählen Sie alle Felder aus einem bestimmten Block die Regel zu speichern.',
        ERROR_MSG_1: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
        SUCCESS_MSG_1: 'Ihre doppelte Regel (s) wurde erfolgreich gespeichert.',
        ERROR_MSG_SAME_FIELD: ' ist bereits ausgewählt. Bitte wählen Sie ein anderes Feld.',
    },
    REQUIRED_FORM: {
        FIELD_FILL_MESG: 'Diese Felder müssen vorher ausgefüllt werden ',
        CAN_BE_SUBMITTED: ' kann eingereicht werden.'
    },
    GREENLIGHT: {
        COMPANY_NAME_BLANK: 'Bitte geben Sie einen Firmennamen ein.',
        COMPANY_NAME_REQUIRED: 'Der Firmenname darf nicht länger als 30 Zeichen sein.',
        ADDRESS1_REQUIRED: 'Adresse 1 ist erforderlich.',
        ADDRESS1_ADDRESS2_LIMIT: 'Adresse1 + Adresse2 dürfen zusammen nicht länger als 30 Zeichen sein.',
        PHONE_NUMBER_REQUIRED: 'Bitte geben Sie eine gültige Telefonnummer ein.',
        PHONE_NUMBER_LIMIT: 'Telefonnummern dürfen nicht mehr als 10 Ziffern haben.',
        NET_ANNUAL_LIMIT: 'Netto jährlich darf nicht leer sein und darf nicht null sein.',
        NET_ANNUAL_INVALID: 'Bitte geben Sie einen gültigen Betrag in Netto jährlich ein.'
    },
    AUDIT_QUESTIONS: {
        QUESTION_REQUIRED: 'Frage ist erforderlich',
        ENTER_VALID_SCORE: 'Bitte geben Sie eine gültige Punktzahl ein',
        ENTER_VALID_CHOICES: 'Bitte geben Sie gültige Auswahlmöglichkeiten ein',
        SUCCESS: 'Erfolg',
        ERROR: 'Fehler',
        NO_QUESTIONS: "Keine Fragen gefunden",
        THANK_YOU: "Danke schön!",
        SOMETHING_WRONG: "Etwas ging schief, während Sie Ihr Feedback einreichen, bitte versuchen Sie es erneut!",
        FEATURE_NOT_AVAILABLE: 'Diese Funktion ist nicht verfügbar.',
        RECEIVED_FEEDBACK_1: "Gut gemacht! Sie haben die Antworten für erfolgreich gespeichert ",
        RECEIVED_FEEDBACK_2:"Kategorie.",
        RECEIVED_FEEDBACK_3:"Denken Sie daran, dass Sie Ihre Antworten für diese Kategorie vor der endgültigen Einreichung jederzeit aktualisieren können.",
        MAIL_SENT_MESSAGE:'E-Mail gesendet.',
        SUBMIT_AUDIT_1:'In den folgenden Kategorien gibt es unbeantwortete Pflichtfragen: ',
        SUBMIT_AUDIT_2:'Bitte beantworten Sie die erforderlichen Fragen und reichen Sie dann das Audit ein.',
        SUBMIT_AUDIT_3:'Sie haben Ihr Audit erfolgreich eingereicht!',
        SUBMIT_AUDIT_4:'Sie können Ihre eingereichten Audit-Antworten und Ihre Ergebnisse im CRM unter HUB > Audits einsehen.',
        SUBMIT_AUDIT_5:'Sie erhalten eine E-Mail-Benachrichtigung, wenn der Franchisegeber Feedback zu Ihrer Prüfung gibt.',
        SUBMIT_AUDIT_6:'Sie können auch das Feedback des Franchisegebers einsehen und über HUB > Audits Korrekturmaßnahmen ergreifen.',
        SAVE_ALERT_1:'Aufmerksamkeit! Du bist dabei, dies zu verlassen',
        SAVE_ALERT_2:'Kategorie, ohne die Änderungen zu speichern.',
        SAVE_ALERT_3:'Bitte klicken Sie nach dem Speichern auf „OK“, um fortzufahren, oder schließen Sie diese Warnung, um hier zu bleiben und mit dieser Kategorie fortzufahren.',
        REQUEST_CORRECTIVE_SUCCESS:'Danke! Ihr Feedback wurde erfolgreich per E-Mail gesendet.',
        SUBMIT_CORRECTIVE_ACTION:'Danke! Ihre Informationen zur Korrekturmaßnahme wurden erfolgreich per E-Mail versendet.',
        EMPTY_REQUEST_CORRECTIVE_MESSAGE:'Sie haben zu keiner der Fragen Ihr Feedback hinzugefügt.'+'\n'+'Bitte fügen Sie zuerst das Feedback hinzu und klicken Sie dann auf „Korrekturmaßnahmen anfordern“.',
        EMPTY_SUBMIT_CORRECTIVE_MESSAGE:'Sie haben zu keinem der Rückmeldungen Korrekturmaßnahmen hinzugefügt.' + '\n' + 'Bitte fügen Sie zuerst die Korrekturmaßnahme hinzu und klicken Sie dann auf „Korrekturmaßnahmen übermitteln“.',
        EMPTY_AUDIT_BY_NAME_AND_EMAIL:'Die Felder „Ihr Name“ und „Ihre E-Mail-Adresse“ sind leer. Bitte geben Sie Werte in diese Felder ein.',
        EMPTY_AUDIT_BY_NAME:'Das Feld „Ihr Name“ ist leer. Bitte geben Sie in diese Felder Werte ein.',
        EMPTY_AUDIT_BY_EMAIL:'Ihr E-Mail-Adressfeld ist leer. Bitte geben Sie in diese Felder Werte ein.',
        AUDIT_TYPE_SELECT_VALIDATION:'Bitte wählen Sie einen Audit-Typ aus, um den Link zu generieren.',
        INVALID_AUDIT_EMAIL:'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        INVALID_AUDIT_NAME:'Bitte geben Sie einen gültigen Namen ein.',
        EMPTY_NAME:"Das Namensfeld darf nicht leer sein. Bitte geben Sie einen gültigen Namen ein.",
    },
    AUDIT_ANSWERS:{
        NO_RECORD_FOUND:'Keine Antwort mit Korrekturfragen gefunden',
    },
    AUDIT_QUESTION_TYPES:{
        MAX_EXCEED:'Max. Überschreitung',
    },
    AUDIT_CATEGORY:{
        CATEGORY_BALNK_MESSAGE:"Die Kategorie darf nicht leer sein.",
        CATEGORY_CHANGES_UPDATED:'Kategorieänderungen aktualisiert.',
        CATEGORY_ALREADY_EXIST:'Diese Kategorie existiert bereits. Das Erstellen doppelter Kategorien ist nicht zulässig.',
        SAVED:'Kategorie gespeichert',
        MAX_EXCEED:'Sie haben das maximale Limit für das Hinzufügen von Kategorien erreicht. Wir erlauben bis zu 50 Kategorien.',
        NO_AUDIT_CATEGORY_QUESTION_FOUND:'In dieser Kategorie stehen keine Fragen zur Auswahl.',
    }, 
    AUDIT_CHECKLIST:{
        ALREADY_SELECTED_CTEAGORY_VALIDATION:'Ausgewählte Kategorie bereits hinzugefügt. Bitte wählen Sie eine andere aus.',
        TYPE:'Typ',
        STATUS:'Status',
        SAVED_SUCCESSFULLY:'Erfolgreich gespeichert',
        UPDATED_SUCCESSFULLY:'Erfolgreich aktualisiert..',
        INACTIVE_AUDIT_ALERT_MESSAGE:'Kategorie nicht gefunden.',
        EMPTY_AUDIT_TYPE:"El tipo de auditoría no puede estar en blanco",
        EMPTY_AUDIT_STATUS:"Der Prüfstatus darf nicht leer sein.",
    },
    COMPLIANCE: {
        SINGLE_RECORD_EDIT: 'Bitte wählen Sie 1 Datensatz, um zu bearbeiten.',
    },
    SALES_AND_REFUND: {
        ALL_OPTIONS_REQUIRED: 'Bitte wählen Sie alle verfügbaren Filter aus und versuchen Sie es erneut.',
    },
    INTAKE_FORM : {
        RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM:'You have an open Intake form tab. Please close that tab before opening or adding a new Intake form.',
        INTAKE_FORM_SUCCESSFULLY_SAVE: 'Saved Successfully.',
        THANK_YOU: "Thank You!",
    },
    EXCEPTION: {
        EXCEPTION_SAVE_SUCESS: 'Saved Successfully.',
    },
    PHONE_NUMBERS: {
        USE_FOR_NOTIFICATION_SAVE: 'Absendernummer für Benachrichtigungen aktualisiert.',
        USE_NOTIFICATION: 'Diese gemietete Nummer wird für die Benachrichtigung verwendet. Bitte klicken Sie zur Bestätigung auf OK.',
        REMOVE_NOTIFICATION: 'Diese gemietete Nummer wird zur Benachrichtigung entfernt. Bitte klicken Sie zur Bestätigung auf OK.',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        SAVE_SUCCESS: 'Ihre Einstellung wurde erfolgreich gespeichert.',
        SAVE_FAIL: 'Ihre Einstellung wird nicht gespeichert.',
        NO_PROVIDER: 'Es wurde noch kein Anbieter für die Telefon- und SMS-Funktion ausgewählt.\n Bitte wählen Sie zuerst einen Anbieter aus, um die Telefon- und SMS-Funktionen nutzen zu können.',
        NO_TWILIO_NUMBERS_FOUND: 'In Ihrem verbundenen Twilio-Konto ist keine Telefonnummer verfügbar.\n Bitte fügen Sie die Nummer zuerst in Ihrem Twilio-Konto hinzu und führen Sie dann den Importvorgang durch.',
        NONE_SELECT_CONFIRM: 'Dadurch werden die Telefon- und Textoptionen in CRM an allen Stellen deaktiviert.\n Wenn Sie einen Workflow oder eine Automatisierungskampagne haben, bei der Text bereits konfiguriert ist, ändern Sie diese bitte entsprechend, da sie nicht mehr funktioniert.'
    },
    MENU_DESIGN:{
        UNABLE_TO_DELETE_MENU:(deletedMenuName,names)=>`Das Menü <b>${deletedMenuName}</b> kann nicht gelöscht werden. Dieses Menü wird von den Sicherheitsstufen <b>${names}</b> verwendet. Entfernen Sie dieses Menü aus allen diesen Sicherheitsgruppen und versuchen Sie es erneut.`,
        MENU_NAME_LESS_THAN_20_CHARACTERS:'Menüname sollte weniger als 20 Zeichen lang sein',
        MENU_NAME_CANNOT_BE_BLANK:'Menüname darf nicht leer sein',
        SPECIAL_CHARACTERS_OR_NUMBERS_NOT_ALLOWED:'Sonderzeichen oder Zahlen sind nicht erlaubt',
        MENU_NAME_ALREADY_EXISTS:'Menüname bereits vorhanden',
        MENU_DELETED:'Menü gelöscht',
    }
    
}
export default message