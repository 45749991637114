import React, { useState, useEffect,forwardRef, useImperativeHandle, useRef } from 'react';
import { connect } from 'react-redux';
import PopOver from '../components/PopOver';
import { styles } from '../../../services/constants/styles';
import { saveListViewSetupData } from '../../../services/actions/listViewAcions';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import find from 'lodash/find';
import concat from 'lodash/concat';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { makeTiteleCase } from '../../../services/helper/utils';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getSelectedObjectLabel } from '../../../services/helper/common';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';
import ShowCircularProgress from '../components/circularProgress';
import { useDispatch, useSelector } from "react-redux";
import { getActiveTab } from '../../../services/helper/sfTabManager';
import {updateActiveTab, updateActiveTabProps} from '../../../services/helper/sfTabManager';
import { getObjectParam} from '../../../services/helper/parameterVerifier';
import {
    refreshListView
} from '../../../services/actions/listViewAcions';
const FilterSetup = forwardRef(({ handleSaveOrRun,filterFieldData,filterSetup,setParentStateLoader,setSaveButton ,object,filter,setParentSteperFilterSetupState },ref) => {
    const dispatch = useDispatch();
    const fields = useSelector((state) => state.sfForm.data[object].fields);
    const maxLimit = 30;
    const [filterSetupState, setFilterSetupState] = useState({
        isSetupSelectorLoading: true,
        setupSelectedFieldOptions: [],
        setupAvailableFieldOptions: [],
        sortFieldName: '',
        sortFieldLabel: '',
        isOpenSortFieldMenu: false,
        isDescendingChecked: false,
        isShowPictureChecked: false,
        isShowTotalChecked: false,
        selectedSetupFieldsCount: 0,
        groupByFieldName: '',
        groupByFieldLabel: '',
        isOpenGroupByFieldMenu: false,
        groupByFieldList: null,
        isSetupMounted: false,
    });

    useEffect(() => {
        populateGroupByFieldList();
        if (Object.keys(filterSetup).length > 0) {
            setFilterSetupState(filterSetup);
            setSaveButton();
        }
    }, [filterSetup]);
    useEffect(() => {
        if (Object.keys(filterFieldData).length > 0) {
            processSetupData(filterFieldData);
        } 
    }, [filterFieldData]);
    
   
    useImperativeHandle(ref, () => ({
        setFilterSetUpInParentState,setupViewValidation,saveFilterSetup
      }));
    
   const populateGroupByFieldList = () => {
    
       let tempfields = fields.filter(f => {
            return f.field_type === 'list' && (f.list_type_id === 10 ||
                f.list_type_id === 11 || f.list_type_id === 14) || (f.name === 'workflow_name')
        });
        let options = [];
        tempfields.forEach(function (element) {
            options.push({
                label: element.label,
                value: element.name
            });
        }, this);

        sortArrayObjectByProperty(options, 'label');
        let tempfilterSetupState=filterSetupState;
        tempfilterSetupState.groupByFieldList = options;
        //tempfilterSetupState.groupByFieldLabel = options[0].label;
       // tempfilterSetupState.groupByFieldName = options[0].value;
        setFilterSetupState(tempfilterSetupState);
    }

   const handleSelectChange = (options) => {
        let totalSelectedFieldCount = filterSetupState.selectedSetupFieldsCount;
        let isValid = false;
        try {
            if (isValidParam(options) && options.length <= totalSelectedFieldCount) {
                isValid = true;
            } else {
                if (isValidParam(options) && options.length <= 30) {
                    isValid = true;
                }
            }

            if (isValid) {
                let tempfilterSetupState=filterSetupState;
                let tempOptions = options.filter(f => { return f.name === filterSetupState.sortFieldName });
                if (tempOptions.length === 0 && options.length > 0) {
                   
                   tempfilterSetupState.sortFieldName = options[0].name;
                   tempfilterSetupState.sortFieldLabel = options[0].label;
                   setFilterSetupState(tempfilterSetupState);

                }
                let arrSlectedObj = options.map((obj) => {
                    return {
                        name: obj.name,
                        label: obj.label,
                        value: obj.value,
                        width: obj.width,
                        title: obj.label,
                    }
                });
                setFilterSetupState({...tempfilterSetupState, setupSelectedFieldOptions: arrSlectedObj,selectedSetupFieldsCount:options.length})
               
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_SETUP, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (e) { console.error("Error in 'filterSetup.js -> handleSelectChange()':" + e); }
    }

   const processSetupData = (data) => {
        try {
            let availableFields = data.available_fields;
            let selectedFields = data.selected_fields;
            let tempfilterSetupState = filterSetupState;
                   tempfilterSetupState.selectedSetupFieldsCount = selectedFields.length;
                   setFilterSetupState(tempfilterSetupState);

            availableFields.map((field) => {
                field.value = field.name;
            });

            selectedFields.map((field) => {
                field.value = field.name;
                field.title = field.label;
            });

            availableFields = concat(availableFields, selectedFields);
            let sortFieldObj = find(availableFields, function (f) { return f.name === data.sort_field_name });
            let sortFieldLabel = sortFieldObj ? sortFieldObj?.label : '';

            let groupByFieldLabel = null;
            let groupByFieldName = null;
            if (data.group_by_field_name !== null && data.group_by_field_name !== "" &&
                data.group_by_field_name !== undefined) {
                groupByFieldLabel = find(filterSetupState.groupByFieldList, function (f) {
                    return f.value === data.group_by_field_name
                });
            }
            if (groupByFieldLabel === null || groupByFieldLabel === undefined) {
                groupByFieldLabel = filterSetupState.groupByFieldList[0].label;
                groupByFieldName = filterSetupState.groupByFieldList[0].value;
            } else {
                groupByFieldName = data.group_by_field_name;
                groupByFieldLabel = groupByFieldLabel.label;
            }
           
            setFilterSetupState({...filterSetupState,
                setupAvailableFieldOptions: availableFields,
                setupSelectedFieldOptions: selectedFields,
                isSetupSelectorLoading: false,
                sortFieldName: data.sort_field_name,
                sortFieldLabel: sortFieldLabel,
                isDescendingChecked: data.sort_type === 'desc' ? true : false,
                isShowPictureChecked: data.is_show_pic,
                isShowTotalChecked: data.is_show_total,
                groupByFieldName: groupByFieldName,
                groupByFieldLabel: groupByFieldLabel
                
            } )
            setFilterSetUpInParentState('getSetup')
            setSaveButton();
        } catch (e) { console.error("Error in 'filterSetup.js -> processSetupData()':" + e); }
    }

    const sortFieldMenuChange = (value) => {
        try {
            let sortFieldLabel = find(filterSetupState.setupSelectedFieldOptions, function (f) { return f.name === value }).label;
            let tempfilterSetupState = filterSetupState;
            setFilterSetupState({ ...tempfilterSetupState, sortFieldName: value, sortFieldLabel: sortFieldLabel, isOpenSortFieldMenu: false });

        } catch (e) { console.error("Error in 'filterSetup.js -> sortFieldMenuChange()':" + e); }
    }

    const groupByFieldMenuChange = (value) => {
        try {
            let groupByFieldLabel = find(filterSetupState.groupByFieldList, function (f) { return f.value === value }).label;
            let tempfilterSetupState = filterSetupState;
            setFilterSetupState({ ...tempfilterSetupState, groupByFieldName: value, groupByFieldLabel: groupByFieldLabel, isOpenGroupByFieldMenu: false });
        } catch (e) { console.error("Error in 'filterSetup.js -> groupByFieldMenuChange()':" + e); }
    }

   const handleDescendingCheckBox = (event, isChecked) => {               
       setFilterSetupState({  ...filterSetupState, isDescendingChecked: isChecked });
    }

   const handleShowPictureCheckBox = (event, isChecked) => {
        setFilterSetupState({  ...filterSetupState, isShowPictureChecked: isChecked  });
    }

   const handleShowTotalCheckBox = (event, isChecked) => {
    setFilterSetupState({ ...filterSetupState, isShowTotalChecked: isChecked });
    }

   const setFilterSetUpInParentState = (callFrom) => {
        try {
            if (setParentSteperFilterSetupState !== undefined) {
                let setUp = callFrom == 'getSetup' ? { previousFilterSetup: filterSetupState } : { filterSetup: filterSetupState };
               setParentSteperFilterSetupState(setUp);
               
            }
        } catch (error) {
            console.error("Error in 'filterSetup.js -> setFilterSetUpInParentState()':" + error);
        }
    }

   const setupViewValidation = () => {
        let isValid = false;
        if (isValidParam(filterSetupState.setupSelectedFieldOptions) && filterSetupState.setupSelectedFieldOptions.length === 0) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.NOT_ADDED + ' ' + makeTiteleCase(object) + ' ' + getLocalizedStrings().message.LIST_VIEW.FIELDS_TO_VIEW, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (isValidParam(filterSetupState.setupSelectedFieldOptions) && filterSetupState.setupSelectedFieldOptions.length === 1) {
            dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.SETUP_MINIMUM_FIELD_ALERT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        } else if (isValidParam(filterSetupState.setupSelectedFieldOptions) && filterSetupState.setupSelectedFieldOptions.length > 1) {
            isValid = true;
        }
        return isValid;
    }

   const saveFilterSetup = (isRun,data) => {
        try {
            let _activeTab = getObjectParam(getActiveTab());
            let params = {};
            params.query_name = filter.t_name;// 'Default Filter View';
            params.query_type = 'filter';
            params.parent_object = object;
            params.query_id = filter.id;
            params.sort_field_name = filterSetupState.sortFieldName;
            params.sort_type = filterSetupState.isDescendingChecked ? 'desc' : 'asc';
            params.is_show_pic = filterSetupState.isShowPictureChecked;
            params.is_show_total = filterSetupState.isShowTotalChecked;
            params.group_by_field_name = filterSetupState.groupByFieldName;
            params.group_by_field_name_sort = _activeTab.info?.groupByFieldNameSort;

            params.callFrom = "filter_setup_view";
            params.field_width_info = filterSetupState.setupSelectedFieldOptions;
            setFilterSetUpInParentState('getSetup');
            var promise = Promise.resolve(saveListViewSetupData(object, params));
            promise.then((response) => {
                if (response.status === 0) {
                    let activeTab = getObjectParam(getActiveTab());
                    activeTab.info.groupByFieldName = filterSetupState.groupByFieldName;
                    updateActiveTab(activeTab);
                    handleSaveOrRun(isRun,data.data.id);
                   dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                   
                    let _params = {};
                    _params.group_by_field_name_sort = activeTab.info?.groupByFieldNameSort;
					_params.callFrom = "filter_setup";
                    refreshListView(object, _params);

                } else {
                   dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                   setParentStateLoader();
                }

            });
        } catch (e) { console.error("Error in 'filterSetup.js -> saveFilterSetup()':" + e); }
    }

    const onSortEnd = (selectedOptions) => {

        let tempfilterSetupState=filterSetupState;           
        setFilterSetupState({...tempfilterSetupState,setupSelectedFieldOptions:selectedOptions});
    }

       
    let popOverOptionsStatus = [];
    let popOverOptionsModuleStatus = [];
    let checkLabelStyle={};
    
    if(  filterSetupState?.setupSelectedFieldOptions != null){
        filterSetupState?.setupSelectedFieldOptions?.map(m => {
            let data = {};
            data.label = m.label;
            data.value = m.name;
            popOverOptionsStatus.push(data);
        })
    }
   
    if (filterSetupState?.groupByFieldList !== null) {
        filterSetupState?.groupByFieldList?.map(m => {
            let data = {};
            data.label = m.label;
            data.value = m.value;
            popOverOptionsModuleStatus.push(data);
        })
    }   
            
    let labelName = getSelectedObjectLabel();
    labelName = isValidParam(getLocalizedStrings().label.FILTER[labelName]) ? getLocalizedStrings().label.FILTER[labelName] : labelName;
    checkLabelStyle = { whiteSpace: 'nowrap' };
    if (object === constants.OPPORTUNITIES_OBJECT) {
        checkLabelStyle = { maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
    }
    let setupAvailableFieldOptions = isValidParam(filterSetupState.setupAvailableFieldOptions) ? getArrayParam(filterSetupState.setupAvailableFieldOptions) : [];
        return (
            <div style={{ paddingLeft: '26px', paddingRight: '26px' }}>
                <div style={styles.customizedFieldsHeaderDiv}>
                    <span style={styles.customizedFieldsHeader}>
                        {getLocalizedStrings().label.LIST_VIEW.SELECT_COLUMN_FOR_FILTER_VIEW}
                    </span>
                </div>
                <div style={{ ...styles.customizedFieldsNotify, fontWeight: 'bold' }}>
                    {getLocalizedStrings().label.LIST_VIEW.ADD_AND_REORDER} {labelName} {getLocalizedStrings().label.LIST_VIEW.FIELDS}: {getLocalizedStrings().label.LIST_VIEW.CLICK_TO_ADD_MORE_FIELDS}
                </div>
                <div id="selChildObject">
                
                    {setupAvailableFieldOptions.length === 0 &&
                        <div style={{ border: '1px solid #aeaeae', borderRadius: ' 4px', padding: '10px', paddingLeft: '45%', }}>
                            <ShowCircularProgress size={25} style={{ marginTop: '0px', width: '100%' }} />
                        </div>
                    }
                    {setupAvailableFieldOptions.length > 0 && <DraggableSelect
                        key={'select-selChildObject'}
                        id={'select-selChildObject'}
                        isClearable
                        onSortEnd={(selectedOptions) => onSortEnd(selectedOptions)}
                        closeMenuOnSelect={false}
                        disabled={false}
                        value={getArrayParam(filterSetupState.setupSelectedFieldOptions)}
                        placeholder={getLocalizedStrings().label.LIST_VIEW.SELECT_FIELDS}
                        options={filterSetupState.setupAvailableFieldOptions}
                        onChange={(options) => handleSelectChange(options)}
                        clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                        className='my-cool-select-top'
                        limitMessage={getLocalizedStrings().message.LIST_VIEW.SELECT_MAXIMUM_FIELD_FOR_LISTVIEW_SETUP}
                        selectLimit={maxLimit}

                    />}
                </div>
                <div style={{ display: 'flex', marginTop: '20px', width: '100%', overflow: 'hidden' }}>
                    <div style={{ marginRight: '5px' }}>
                        <div style={{ display: 'flex', maxWidth: '200px', marginRight: '10px', paddingTop: '2px' }}>
                            <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{getLocalizedStrings().label.LIST_VIEW.GROUP_BY}:</div>
                            <div style={{ maxWidth: '60%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',width:'195%' }} title={filterSetupState.groupByFieldLabel} >
                                    {filterSetupState.groupByFieldLabel}
                                </div>
                                <PopOver
                                    id={'moduleStatus'}
                                    key={'moduleStatus'}
                                    name={'moduleStatus'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                    labelposition={"before"}
                                    buttonLabel={""}
                                    options={popOverOptionsModuleStatus}
                                    onclickAction={(v)=> groupByFieldMenuChange(v)}
                                    isShowIcon={true}
                                    title={""}
                                    value={filterSetupState.groupByFieldName}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', maxWidth: '200px', marginRight: '10px', paddingTop: '2px' }}>
                        <div style={{ float: 'left', color: '#717171', padding: '0px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{getLocalizedStrings().label.LIST_VIEW.SORT_BY}:</div>
                        <div style={{ maxWidth: '60%', display: 'flex', float: 'right', padding: '0px', textAlign: 'left', paddingLeft: '6px' }}>
                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',width:'195%' }} title={filterSetupState.sortFieldLabel} >
                                {filterSetupState.sortFieldLabel}
                            </div>
                            <PopOver
                                id={'status'}
                                key={'status'}
                                name={'status'}
                                buttonEndIcon={"keyboard_arrow_down"}
                                buttonStyle={{ float: 'left', maxWidth: '60px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'inline-block' }}
                                labelposition={"before"}
                                buttonLabel={""}
                                options={popOverOptionsStatus}
                                onclickAction={(v)=> sortFieldMenuChange (v)}
                                isShowIcon={true}
                                title={""}
                                value={filterSetupState.sortFieldName}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '10px', marginTop: '-0.7%' }}>
                        <FormControlLabel
                            control={<Checkbox
                                color="default"
                                value={filterSetupState.isDescendingChecked}
                                checked={filterSetupState.isDescendingChecked}
                                onChange={handleDescendingCheckBox}
                                defaultChecked={filterSetupState.isDescendingChecked} />}
                            label={<div style={{ color: '#717171', ...checkLabelStyle }}>{getLocalizedStrings().label.LIST_VIEW.DESCENDING}</div>}
                            labelposition="right"
                            labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'bold' }}
                            style={{ height: 'auto' }}
                            title={getLocalizedStrings().label.LIST_VIEW.DESCENDING} />

                    </div>
                    <div style={{ marginRight: '10px', marginTop: '-0.7%' }}>
                        {(object === constants.ACCOUNTS_OBJECT
                            || object === constants.CONTACTS_OBJECT || object === constants.SALES_OBJECT
                            || object === constants.OPPORTUNITIES_OBJECT) &&
                            <FormControlLabel
                                control={<Checkbox
                                    color="default"
                                    value={filterSetupState.isShowPictureChecked}
                                    onChange={handleShowPictureCheckBox}
                                    checked={filterSetupState.isShowPictureChecked}
                                    defaultChecked={filterSetupState.isShowPictureChecked} />}
                                label={<div style={{ color: '#717171', ...checkLabelStyle }}>{getLocalizedStrings().label.LIST_VIEW.SHOW_PICTURE}</div>}
                                labelposition="right"
                                labelstyle={{ color: '#717171', padding: '0px', fontWeight: 'bold' }}
                                style={{ height: 'auto' }}
                                title={getLocalizedStrings().label.LIST_VIEW.SHOW_PICTURE} />

                        }
                    </div>
                    {object === constants.OPPORTUNITIES_OBJECT && <div style={{ marginRight: '10px', whiteSpace: 'nowrap', marginTop: '-0.7%' }}>
                        <FormControlLabel
                            control={<Checkbox
                                color="default"
                                value={filterSetupState.isShowTotalChecked}
                                onChange={handleShowTotalCheckBox}
                                checked={filterSetupState.isShowTotalChecked}
                                defaultChecked={filterSetupState.isShowTotalChecked} />}
                            label={<div style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{getLocalizedStrings().label.LIST_VIEW.SHOW_TOTAL}</div>}
                            labelposition="right"
                            labelstyle={{ color: '#717171', padding: '0px', fontWeight: 'bold' }}
                            style={{ height: 'auto' }}
                            title={getLocalizedStrings().label.LIST_VIEW.SHOW_TOTAL} />

                    </div>}
                </div>
            </div>
        );
    
});
export default FilterSetup;