import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getDashboard,
    customDrillDown,
    editDashboard,
    updateWidgetContent,
    refreshDashboard,
} from "../../../services/actions/dashboardAcions";
import {  Button, Icon } from '@mui/material';
import { Chart } from "../components/chart";
import { Query } from "../components/query";
import { styles } from "../../../services/constants/styles";
import { constants } from "../../../services/constants/constants";
import ShowCircularProgress from "../components/circularProgress";
import { setHeader } from "../../../services/actions/headerActions";
import map from "lodash/map";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { CustomReport } from "../components/customReport";
import {
    getBooleanParam,
    getStringParam,
} from "../../../services/helper/parameterVerifier";
import {
    DashboardLayoutComponent,
    PanelsDirective,
    PanelDirective,
} from "@syncfusion/ej2-react-layouts";
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from "../../../services/constants/endPoints";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import * as sfDialogs from '../components/sfDialogs';
import { Close, Edit } from "@mui/icons-material";
import PivotReport from "../components/pivotReport";
import TabularReport from "../components/tabularReport"
import { sortArrayObjectByProperty } from "../../../services/helper/utils";

const exportingDataConstant = (reportName) => {
    return {
        exporting: {
            buttons: {
                contextButton: {
                    _titleKey: "mytoolTip",
                    menuItems: [
                        {
                            text: "Print Report",
                            onclick: function () {
                                this.print();
                            },
                        },
                        {
                            separator: true,
                        },
                        {
                            text: "Download PNG Image",
                            onclick: function () {
                                this.exportChart({ type: "image/png", filename: reportName });
                            },
                        },
                        {
                            text: "Download JPEG Image",
                            onclick: function () {
                                this.exportChart({ type: "image/jpeg", filename: reportName });
                            },
                        },
                        {
                            text: "Download PDF Image",
                            onclick: function () {
                                this.exportChart({
                                    type: "application/pdf",
                                    filename: reportName,
                                });
                            },
                        },
                        {
                            text: "Download SVG Image",
                            onclick: function () {
                                this.exportChart({
                                    type: "image/svg+xml",
                                    filename: reportName,
                                });
                            },
                        },
                    ],
                },
            },
        },
        lang: {
            mytoolTip: "Print/Download Report",
        },
    };
};

let options = [
    { value: "td", label: getLocalizedStrings().label.DASHBOARD.TODAY },
    { value: "yd", label: getLocalizedStrings().label.DASHBOARD.YESTERDAY },
    { value: "tw", label: getLocalizedStrings().label.DASHBOARD.THIS_WEEK },
    { value: "tm", label: getLocalizedStrings().label.DASHBOARD.THIS_MONTH },
    { value: "tq", label: getLocalizedStrings().label.DASHBOARD.THIS_QUARTER },
    { value: "ty", label: getLocalizedStrings().label.DASHBOARD.THIS_YEAR },
    { value: "lw", label: getLocalizedStrings().label.DASHBOARD.LAST_WEEK },
    { value: "lm", label: getLocalizedStrings().label.DASHBOARD.LAST_MONTH },
    { value: "lq", label: getLocalizedStrings().label.DASHBOARD.LAST_QUARTER },
    { value: "ly", label: getLocalizedStrings().label.DASHBOARD.LAST_YEAR },
];

const Dashboard = ({id, object}) => {
    let dashboardObj = useRef(); 
    const scrollPosition = useRef(0);
    const dispatch = useDispatch()
    const { dashboard, app } = useSelector((state)=> state)
    const {isRefresh, isEditModeSales, isEditModeMarketing, isEditModeManagement, mounted} = dashboard;
    const [type, setType] = useState()
    const [moduleType, setModuleType] = useState()
    const [dashboardId, setDashboardId] = useState()
    const isInxpress = getBooleanParam(app.me.is_inxpress);

    useEffect(()=>{
        if(isRefresh) {
            getIntialData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isRefresh])

    useEffect(()=>{
        getIntialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        // Save the scroll position when the component updates
        scrollPosition.current = window.scrollY;
    }, [dashboard]); // Add relevant dependencies

    useEffect(() => {
        // Restore the scroll position after Redux update
        window.scrollTo(0, scrollPosition.current);
    }, [dashboard]); // Add relevant dependencies

    
    const getIntialData = () =>{
        
        let timeLine = "tw";
        let label = "";
        let type = "";
        let moduleType = "";
        let dashboardId;
        if (app.selectedModuleName === constants.MODULE_SALES) {
            if (isInxpress) {
                let industryType = getStringParam(app.me.industry_type);
                if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
                    type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
                    dashboardId = 3;
                } else if (
                    industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
                    industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE 
                ) {
                    type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
                    dashboardId = 4;
                }
            } else if (app.me.projectId === constants.INDUSTRY_TYPE_INXPRESS_GLOBAL) {
                type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
                dashboardId = 3
            } else {
                type = app.selectedModuleName;
                dashboardId = 1
            }
            moduleType = constants.MODULE_ID_SALES;
            label = getLocalizedStrings().label.DASHBOARD.SALES_DASHBOARD;
        } else if (
            app.selectedModuleName === constants.MODULE_MARKETING
        ) { 
            if (isInxpress) {
                let industryType = getStringParam(app.me.industry_type);
                if (industryType === constants.INDUSTRY_TYPE_INXPRESS_COUNTRY) {
                    type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
                    dashboardId = 5;
                } else if (
                    industryType === constants.INDUSTRY_TYPE_INXPRESS_FRANCHISE ||
                    industryType === constants.INDUSTRY_TYPE_INXPRESS_US_FRANCHISE 
                ) {
                    type = constants.TYPE_DASHBOARD_INXPRESS_FRANCHISE;
                    dashboardId = 6;
                }
            } else if (app.me.projectId === constants.INDUSTRY_TYPE_INXPRESS_GLOBAL) {
                type = constants.TYPE_DASHBOARD_INXPRESS_COUNTRY;
                dashboardId = 5
            } else {
                type = app.selectedModuleName;
                dashboardId = 2
            }
            moduleType = constants.MODULE_ID_MARKETING;
            label = getLocalizedStrings().label.DASHBOARD.MARKETING_DASHBOARD;
        }  else if (
            app.selectedModuleName === constants.MODULE_OPERATIONS
        ) {
            moduleType = constants.MODULE_ID_OPERATIONS;
            type= 3 // this needs to check and verify 
            dashboardId = 3
            label = getLocalizedStrings().label.DASHBOARD.OPERATIONS_DASHBOARD;

        }
        
        setModuleType(moduleType)
        setType(type)
        setDashboardId(dashboardId)

        let params = {
            projectId: app.me.projectId,
            userId: app.me.id,
            dashboardId: dashboardId,
            timeLine: timeLine,
            type: type,
            moduleType: moduleType
        }
        
        const promise = Promise.resolve(HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_GET_NEW, params));
        promise.then((res)=>{
            if(res.widgets.length > 0) {
                const isWidgetsNew = res.widgets.filter((w)=> w.i !== 0)
                if(isWidgetsNew.length > 0) {
                    dispatch(getDashboard(
                        app.me.projectId,
                        app.me.id,
                        dashboardId,
                        timeLine,
                        type,
                        moduleType
                    ));
                } else {
                    let apiToObj = {
                        widgetInfo:  res.widgets
                    };
                    const promise = Promise.resolve(HTTPClient.post(
                        endPoints.DASHBOARD.DASHBOARD_CHANGE_LAYOUT,
                        apiToObj
                    ));
                    promise.then((res) => {
                        dispatch(getDashboard(
                            app.me.projectId,
                            app.me.id,
                            dashboardId,
                            timeLine,
                            type,
                            moduleType
                        ));
                    })
                }

            }
        })
        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;

        dispatch(setHeader(object, label, kanbanObject, false, true));
    }
    
    const defineGadgetPosition = (dashboardData) => {
        let h = 8;
        let x = 0;
        let y = 0;
        let c = 0;
        let k = 0;
        let r = 1;
        if (dashboardData !== undefined && dashboardData !== null) {
            dashboardData.sort(function (a, b) {
                return a.i - b.i;
            });
            if (app.selectedModuleName === constants.MODULE_SALES) {
                let colSize = 0;
                let isMoreCol = false;
                dashboardData.forEach(function (l, i) {
                    let dataList = l.details.data;
                    if (l.details.column !== null && l.details.column !== undefined) {
                        colSize = JSON.stringify(l.details.column.length);
                        if (!isMoreCol && colSize > 3) {
                            isMoreCol = true;
                        }
                    }
                    try {
                        if (dataList !== null && dataList !== undefined) {
                            dataList.sort(function (a, b) {
                                if (a.Users.toLowerCase() < b.Users.toLowerCase()) {
                                    return -1;
                                }
                                if (a.Users.toLowerCase() > b.Users.toLowerCase()) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                    } catch (error) {
                        console.log("Error on Dashboad.js -> sort function" + error);
                    }
                    dataList = "";
                });
            }

            let isInxpress = getBooleanParam(app.me.is_inxpress);
            if (isInxpress) {
                dashboardData.forEach(function (l, i) {
                    k = "widget_" + (k + 1);
                    l.isDraggable = false;
                    try {
                        if (l.details.chart.type === "pie") {
                            l.details.plotOptions.pie = { size: 200 };
                            l.details.chart.height = "250px";
                        }
                        if (l.details.chart.type === "column") {
                            l.details.chart.height = "260px";
                        }
                        l.details.title.text = "";
                    } catch (ex) {}

                    if (c >= 2) {
                        c = 0;
                    } else {
                        c = c + 1;
                    }
                });
            } else if (
                app.selectedModuleName === constants.MODULE_MARKETING
            ) {
                c = 1;
                dashboardData.forEach(function (l, i) {
                    k = "widget_" + (k + 1);
                    l.isDraggable = false;
                    try {
                        if (l.details.chart.type === "pie") {
                            l.details.plotOptions.pie = { size: 200 };
                            l.details.chart.height = "250px";
                        }
                        if (l.details.chart.type === "column") {
                            l.details.chart.height = "260px";
                        }
                        l.details.title.text = "";
                    } catch (ex) {}
                    if (c % 2 === 0) {
                        c = 1;
                    } else {
                        c = c + 1;
                    }
                });
            } else if (app.selectedModuleName === constants.MODULE_SALES) {
                c = 1;
                dashboardData.forEach(function (l, i) {
                  k = "widget_" + (k + 1);
                  l.x = x;
                  l.y = y;
                  l.h = h;
              
                  l.isDraggable = false;
                  try {
                    if (l.details.chart.type === "pie") {
                      l.details.plotOptions.pie = { size: 200 };
                      l.details.chart.height = "250px";
                    }
                    if (l.details.chart.type === "column") {
                      l.details.chart.height = "260px";
                    }
                    if (!l.headerAssigned) { 
                        l.header = l.details.title.text;
                        l.headerAssigned = true; 
                      }
                      
                      l.details.title.text = ""; 
                  } catch (ex) {}
                  if (r <= 2) {
                    if (c % 2 === 0) {
                      c = 1;
                      r = r + 1;
                    } else {
                      c = c + 1;
                    }
                  } else {
                    r = r + 1;
                  }
                });

              } else {
                dashboardData.forEach(function (l, i) {
                  k = "widget_" + (k + 1);
                  l.x = x;
                  l.y = y;
                  l.h = h;
                  l.isDraggable = false;
                  try {
                    if (l.details.chart.type === "pie") {
                      l.details.plotOptions.pie = { size: 200 };
                      l.details.chart.height = "250px";
                    }
                    if (l.details.chart.type === "column") {
                      l.details.chart.height = "260px";
                    }
                    l.details.title.text = "";
                  } catch (ex) {}
                  c = c + 1;
                });
              }
              return dashboardData;
            }
    };

    const contentfunction = (content) => {
        return () => {
            return content;
        };
    };

    const widgetHeader = (headerTitle, widget, dashboardData) => {
        const { timeline } = widget
        const widgetTimeLine = options.find((option) => { 
            let time_line = '';

            if (option.value === timeline || option.value === widget.details?.timeline || option.value === widget.details?.dueBy ) {
                time_line = option.label
            }
            return time_line;
        })

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '5px',
                    height: '100%',
                    backgroundColor: '#c9d1f7'
                }}
            >
                <div
                    title={headerTitle}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '70%',
                    }}
                >
                    <h3
                    style={{
                        margin: '0px',
                        overflow: 'auto',
                        color: '#000000',
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                    }}
                    >
                        {headerTitle}
                    </h3>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px', // Adjust spacing between elements
                    }}
                >
                    <select
                        value={widgetTimeLine?.value || ''}
                        onChange={(event) => handleWidgetTimeLineChange(event, widget)}
                        style={{ fontSize: '14px', padding: '0px 2px', cursor: 'pointer' }} // Optional: Adjust dropdown styling
                    >
                        {options.map((item, i) => (
                            <option key={i} value={item?.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>

                    {((isEditModeSales && moduleType === 1) || (isEditModeManagement && moduleType === 3)) && !isInxpress &&
                    <>
                        <div
                            title={'Edit'}
                            style={{
                                background: 'none',
                                border: 'none',
                                color: '#999',
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                let data = {}
                                data.widget = widget
                                data.isEditWidget = true
                                data.widgetTimeLine = widgetTimeLine
                                dispatch(getAppDialog(true, constants.DASHBOARD_NEW_WIDGET, 'Edit Widget', (selectedNewWidget) => addWidget(selectedNewWidget), data, constants.DASHBOARD))
                            }}
                        >
                            <Edit fontSize="small" />
                        </div>

                        <div
                            title={'Remove'}
                            style={{
                                background: 'none',
                                border: 'none',
                                color: '#999',
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                if(dashboardData.length > 1) {
                                    e.stopPropagation();
                                    const CONFIRM_DELETE_MSG = 'Are you sure you want to remove this widget?';
                                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, CONFIRM_DELETE_MSG, () => handleRemove(widget), null);
                                } else {
                                    dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.DASHBOARD_WIDGET_REMOVE_LIMIT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }
                            }}
                        >
                            <Close fontSize="small" />
                        </div> 
                    </> }
                </div>
            </div>

        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    const generateSyncFusionDOM = useCallback((dashboard) => {
        let _this = this;
        let reportContent = <div></div>;
        const isSales =
            app.selectedModuleName === constants.MODULE_SALES;
        let dashboardData = defineGadgetPosition(
            dashboard.layouts.lg
        );

        if (isSales) {
            let tempDashboardObj=[]
            dashboardData?.forEach((d,i)=>{
              dashboard?.data?.widgets.forEach((w)=>{
                if(d.i.toString()===w.i.toString()){
                  tempDashboardObj.push({...w, header:d.header});
                }
              })
            })
            dashboardData = tempDashboardObj
        }

        return map(dashboardData, function (l) {
            if (l !== null) {
                //let sync = false;
                if (l.format === "report") {
                    if (
                        l.hasOwnProperty("widgetName") &&
                        (l.widgetName === "CRM Account Summary" ||
                            l.widgetName === "Sales Cycle" ||
                            l.widgetName === "Top Industries" ||
                            l.widgetName === "Top Sources" ||
                            l.widgetName === "Leads By Source" ||
                            l.widgetName === "Leads By Lead Source")
                    ) {
                        if (
                            l.hasOwnProperty("details") &&
                            l.details.hasOwnProperty("plotOptions")
                        ) {
                            let plotOptions = l.details.plotOptions;
                            plotOptions = {
                                series: {
                                    cursor: "pointer",
                                    events: { click: customDrillDown.bind(_this, l) },
                                },
                            };
                            l.details.plotOptions = plotOptions;
                        }
                        reportContent = (
                            <Chart
                                config={{
                                    ...l.details,
                                    ...exportingDataConstant(l.header),
                                }}
                                widgetId={l.i}
                            />
                        );
                    } else if (
                        l.hasOwnProperty("widgetName") &&
                        (l.widgetName === "Accounts Matrix" ||
                            l.widgetName === "Activities" ||
                            l.widgetName === "Opportunities Matrix")
                    ) {
                        reportContent = (
                            <Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
                        );
                    }else if(l.hasOwnProperty("details") && l.details.hasOwnProperty("config")){
                        reportContent = (
                            <PivotReport data={{config: l.details.config}} widgetId={l.i}/>
                        );
                    }else if(l.hasOwnProperty("details") && l.details.hasOwnProperty("fields") && l.details.hasOwnProperty("records") && l.details.hasOwnProperty("sortOptions")){
                        reportContent = (
                            <TabularReport tabularData={l.details} widgetId={l.i} />
                        )
                    }else {
                        reportContent = (
                            <Chart
                                config={{
                                    ...l.details,
                                    ...exportingDataConstant(l.header),
                                }}
                                widgetId={l.i}
                            />
                        );
                    }
                } else if (l.format === "query") {
                    reportContent = (
                        <Query data={{ ...l.details, header: l.header }} widgetId={l.i} />
                    );
                } else if (l.format === "custom") {
                    if (
                        l.hasOwnProperty("widgetName") &&
                        (l.widgetName === "Activity Summary" ||
                            l.widgetName === "Activities by Users" ||
                            l.widgetName === "Call Activity" ||
                            l.widgetName === "Activity Summary By SalesRep" ||
                            l.widgetName === "Call Activity By SalesRep")
                    ) {
                        if (
                            l.hasOwnProperty("details") &&
                            l.details.hasOwnProperty("plotOptions")
                        ) {
                            let plotOptions = l.details.plotOptions;
                            plotOptions = {
                                series: {
                                    cursor: "pointer",
                                    events: { click: customDrillDown.bind(_this, l) },
                                },
                            };
                            l.details.plotOptions = plotOptions;
                        }

                        reportContent = (
                            <Chart
                                config={{
                                    ...l.details,
                                    ...exportingDataConstant(l.header),
                                }}
                                widgetId={l.i}
                            />
                        );
                    } else {
                        reportContent = <CustomReport data={l.details} widgetId={l.i} />;
                    }
                } else {
                    reportContent = <div />;
                }
                let headerTitle = getLocalizedStrings().label.DASHBOARD.hasOwnProperty(l.header)
                    ? getLocalizedStrings().label.DASHBOARD[l.header] : l.header;
                headerTitle = l.header === l.widgetName ? l.header : l.widgetName
                
                return (
                    <PanelDirective
                        id={`panel_${l.i}`}
                        sizeX={l.w === 1 ? 1 : l.w}
                        sizeY={0}
                        row={l.x}
                        col={l.y}
                        content={contentfunction(reportContent)}
                        header={()=> widgetHeader(headerTitle, l, dashboardData)}
                        zIndex={98}
                    />
                );
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isEditModeSales, isEditModeMarketing, isEditModeManagement, dashboardId, moduleType]);

    const widgetComponents = useMemo(() => {
        return generateSyncFusionDOM(dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateSyncFusionDOM, dashboard.data.widgets, dashboard.layouts.lg]);

    const handleRemove = (widget) => {
        let params = {
            id: widget.i,
            status: "Inactive",
            dashboard_id: widget.dashBoardId,
            timeline: widget.timeline,
            widget_name: widget.widgetName
        };  

        const promise = Promise.resolve(HTTPClient.post(
            endPoints.DASHBOARD.DASHBOARD_AVAILABLEWIDGETS_SAVE,
            params
        ));
        promise.then((response)=>{
            if(response.status === -1) {
                dispatch(showCustomSnackBar(response.error.message || `Error: No widget details found for widget.`, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.DELETE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                dispatch(refreshDashboard(true))
            }

        }).catch((err)=>console.log('handleRemove=err==>', err))
    };
    
    const handleWidgetTimeLineChange = (event, widget) => {
        const selectedValue = event.target.value; // This gives the value of the selected option
            const params = { 
                dashboardId: dashboardId,
                timeLine: `${selectedValue}`,
                type: type,
                moduleType: moduleType, 
                widgetId: widget.i
            }
            const promise = Promise.resolve(HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_GET_TIMELINE_WIDGET, params));
            promise.then((res)=>{
                if(res.widgets.length > 0) {
                    dispatch(updateWidgetContent(res.widgets[0]));
                    setTimeout(() => {
                        window.scrollTo(0, scrollPosition.current);
                    }, 50);
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.DASHBOARD.TIMELINE_DETAILS_NOT_AVAILABLE, null);
                }
            })
    }


    const addWidget = (selectedwidget) => {
        let params={ id: selectedwidget.id, dashboard_id: selectedwidget.dashboard_id, status: "Active",  widget_name: selectedwidget.widget_name, timeline: selectedwidget.timeline, x: 0, y: 0};  
        const promise = Promise.resolve(HTTPClient.post(
            endPoints.DASHBOARD.DASHBOARD_AVAILABLEWIDGETS_SAVE,
            params
        ));
        promise.then((response) => {
            if(response.status === 0 && response.data?.widgets?.length > 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.WIDGET_ADDED_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            } else if (response.status === -1) {
                dispatch(showCustomSnackBar(response.error.message || 'Error: details not available for selected widget.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            dispatch(refreshDashboard(true))
        }).catch((error) => console.log('err==>', error))
    };

    const onSaveLayout = () => {
        let dataToAPi = [];
        dashboardObj.current.serialize()?.forEach((item, index) => {
            dashboard.data.widgets.forEach((secondItr, i) => {
                if (secondItr.i.toString() === item.id.split("_")[1]) {
                    dataToAPi.push({
                        ...secondItr,
                        x: item?.row,
                        y: item?.col,
                        h: item?.sizeY,
                        w: item?.sizeX,
                        status: "Active",
                    });
                }
            });
        });

        let apiToObj = {
            widgetInfo:  dataToAPi
        };

        if (isEditModeSales || isEditModeMarketing || isEditModeManagement) {
            const promise = Promise.resolve(HTTPClient.post(
                endPoints.DASHBOARD.DASHBOARD_CHANGE_LAYOUT,
                apiToObj
            ));
            promise.then((res) => {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.WIDGET_SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                if (moduleType === 1) {
                    dispatch(editDashboard(false, constants.MODULE_ID_SALES))
                }
                if (moduleType === 2) {
                    dispatch(editDashboard(false, constants.MODULE_ID_MARKETING))
                }
                if (moduleType === 3) {
                    dispatch(editDashboard(false, constants.MODULE_ID_OPERATIONS)) 
                }
            });
        }
    };

    const getWidgetsList = () =>{        
        let params = { 
            dashboard_id: dashboardId
        };

        const promise = Promise.resolve(HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_AVAILABLEWIDGETS_GET, params));
        promise.then((response) => {
            if(response.length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.DASHBOARD.NO_DATA_IN_AVAILABLEWIDGET, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                sortArrayObjectByProperty(response, 'widget_name');
                let data = {}
                data.widgetOptions = response
                dispatch(getAppDialog(true, constants.DASHBOARD_ADD_WIDGET, getLocalizedStrings().label.DASHBOARD.ADD_WIDGET, (selectedwidget) => addWidget(selectedwidget), data, constants.DASHBOARD))
            }
        })     
    } 

    if (mounted || isRefresh) {
        return (
            <div>
                <div style={{ display: 'block', flexDirection: 'row', gap: '10px', float: "right", textAlign: "right", marginRight:"15px", marginBottom: '15px'}}>
                        {
                            ((isEditModeSales && moduleType === 1) || (isEditModeManagement && moduleType === 3)) && !isInxpress && (
                            <>
                                <Button
                                    startIcon={<Icon style={{ fontWeight: 'small' }}>add_card</Icon>}
                                    onClick={() => getWidgetsList()}
                                    style={styles.listViewPrimaryButton}
                                > 
                                    {getLocalizedStrings().label.COMMON.ADD}
                                </Button>
                                <Button
                                    startIcon={<Icon style={{ fontWeight: 'small' }}>add</Icon>}
                                    onClick={() => {
                                        let data = {}
                                        data.widget = {}
                                        data.isEditWidget = false
                                        data.dashBoardId = dashboardId
                                        dispatch(getAppDialog(true, constants.DASHBOARD_NEW_WIDGET, getLocalizedStrings().label.DASHBOARD.NEW_DASHBOARD, null, data, constants.DASHBOARD))
                                    }}
                                    style={styles.listViewPrimaryButton}
                                > 
                                    {getLocalizedStrings().label.COMMON.NEW}
                                </Button>
                            </>
                            )
                        }
                        {app.me.t_name==="Admin" &&
                            <Button
                                startIcon={ (isEditModeSales || isEditModeMarketing || isEditModeManagement) ? <Icon style={{ fontWeight: 'small' }}>save</Icon> : <Icon style={{ fontWeight: 'small' }}>edit</Icon>}
                                onClick={() => { 
                                    if ((isEditModeSales && moduleType === 1) || (isEditModeMarketing && moduleType === 2) || (isEditModeManagement && moduleType === 3)) {
                                        onSaveLayout()
                                    } else {
                                        if (moduleType === 1) {
                                            dispatch(editDashboard(!isEditModeSales, constants.MODULE_ID_SALES))
                                        }
                                        if (moduleType === 2) {
                                            dispatch(editDashboard(!isEditModeMarketing, constants.MODULE_ID_MARKETING)) 
                                        }
                                        if (moduleType === 3) {
                                            dispatch(editDashboard(!isEditModeManagement, constants.MODULE_ID_OPERATIONS)) 
                                        }
                                    }
                                }}
                                style={styles.listViewPrimaryButton}
                            > 
                                {((isEditModeSales && moduleType === 1)  || (isEditModeMarketing && moduleType === 2) || (isEditModeManagement && moduleType === 3)) ? getLocalizedStrings().label.COMMON.SAVE : getLocalizedStrings().label.COMMON.EDIT}
                            </Button>
                        }
                </div>
                <div style={{ margin: "20px", paddingTop: '40px' }}>
                        <DashboardLayoutComponent
                            key={dashboardId}
                            id='dashboard_layout'
                            ref={dashboardObj}
                            columns={2}
                            cellAspectRatio={2}
                            cellSpacing={[20,20]}
                            allowResizing={false}
                            allowDragging={((isEditModeSales && moduleType === 1)  || (isEditModeMarketing && moduleType === 2) || (isEditModeManagement && moduleType === 3))}
                        >
                            <PanelsDirective>
                                {widgetComponents}
                            </PanelsDirective>
                        </DashboardLayoutComponent>
                </div>
            </div>

        );
    } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2;
            return (
                <div style={{ width: "100%", height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: "45%" }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} />
                        </div>
                    </div>
                </div>
            );
    }
}

export default Dashboard;

