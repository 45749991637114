import * as HTTPClient from '..//helper/httpClient';
import { endPoints } from '..//constants/endPoints';
import { constants } from '..//constants/constants';
import { getObjectSpecificEndpoint } from '..//helper/utils';

function getConvert(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CONVERT_GET;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CONVERT_GET_DETAILS;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CONVERT_GET;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CONVERT_GET;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CONVERT_GET;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CONVERT_GET;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CONVERT_GET;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CONVERT_GET;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CONVERT_GET;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CONVERT_GET;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CONVERT_GET;
    } 
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function saveConvert(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CONVERT;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CONVERT;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CONVERT;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CONVERT;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CONVERT;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CONVERT;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CONVERT;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CONVERT;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CONVERT;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CONVERT;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CONVERT;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}


function saveBatchConvert(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.BATCH_CONVERT;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.BATCH_CONVERT;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.BATCH_CONVERT;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.BATCH_CONVERT;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.BATCH_CONVERT;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.BATCH_CONVERT;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.BATCH_CONVERT;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.BATCH_CONVERT;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.BATCH_CONVERT;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.BATCH_CONVERT;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.BATCH_CONVERT;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
// for link group for object and grouplist
function getGrouplist(object, params) {
    let endPoint = null;
    endPoint = endPoints.GROUP.LIST;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

//for save new group
function saveGroup(object, params) {
    let endPoint = null;
    endPoint = endPoints.GROUP.SAVE;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
// add an object to group
function addToGroupAction(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.ADD_TO_GROUP;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.ADD_TO_GROUP;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
// assign an object
function assignAction(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.ASSIGN;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.ASSIGN;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.ASSIGN;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.ASSIGN;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.ASSIGN;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function shareAction(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.SHARE;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = "";
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
function shareRecordGet(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.MAP_DATA;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = "";
    }
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function pushcompanylist(object, params) {
    let endPoint = null;
    endPoint = endPoints.COMMON.PUSH_COMPANY_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function pushBasecompanylist(object, params) {
    let endPoint = null;
    endPoint = endPoints.COMMON.PUSH_BASE_COMPANY_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function pushwflist(object, params) {
    let endPoint = null;
    endPoint = endPoints.COMMON.PUSH_WF_LIST;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function pushaction(object, params) {
    let endPoint = null;
    endPoint = endPoints.COMMON.SAVE_PUSH_INFO;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function reschedule(object, params) {
    let endPoint = null;
    if (object === constants.APPOINTMENTS) {
        endPoint = endPoints.APPOINTMENT.RESCHEDULE;
    } else if (object === constants.TASKS) {
        endPoint = endPoints.TASK.RESCHEDULE;
    } else if (object === constants.EMAIL_OBJECT || object === constants.SMS_OBJECT) {
        endPoint = endPoints.SEND_MAIL.RESCHEDULE;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}


function deleteAction(object, params) {

    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        
        if (params.relationTableId !== undefined && params.relationTableId !== null) {
            endPoint = endPoints.ACCOUNTS.UNLINK_RELATED_RECORD;
        } else {
            endPoint = endPoints.ACCOUNTS.DELETE;
        }
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.DELETE;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.DELETE;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.PRODUCTS.DELETE;
    }
    else if (object === constants.TASKS|| object === 'task') {
        endPoint = endPoints.TASK.DELETE;
    } else if (object === constants.APPOINTMENTS|| object === 'appointment') {
        endPoint = endPoints.APPOINTMENT.DELETE;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.DELETE;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.DELETE;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.DELETE;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.DELETE;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.DELETE;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.DELETE;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.DELETE;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.DELETE;
    }
    else if (object === constants.SALES_TEMPLATE_OBJECT) {
        endPoint = endPoints.SALES_TEMPLATE.DELETE;
    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
        endPoint = endPoints.AGREEMENT_TEMPLATE.DELETE;
    }
    else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
        endPoint = endPoints.MARKETING_TEMPLATE.DELETE;
    } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
        
        endPoint = endPoints.BROADCAST_CAMPAIGNS.DELETE;
    } else if (object === constants.NOTES) {
        
        endPoint = endPoints.NOTES.DELETE;
    } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
        
        endPoint = endPoints.AB_CAMPAIGNS.DELETE;
    } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
        
        endPoint = endPoints.AB_CAMPAIGNS.DELETE;
    } else if (object === constants.ATTACHMENT_OBJECT) {
        
        endPoint = endPoints.ATTACHMENT.DELETE;
    } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
        endPoint = endPoints.AUTOMATION_DESIGNER.DELETE;
    } else if (object === constants.GROUP_OBJECT) {
        endPoint = endPoints.GROUP.DELETE_GROUP;
    } else if (object === constants.TOUCH_CAMPAIGNS_OBJECT) {
        endPoint = endPoints.TOUCH_CAMPAIGNS.DELETE;
    } else if (object === constants.ASSIGNMENT_RULES_OBJECT) {
        endPoint = endPoints.ASSIGNMENT_RULE.DELETE;
    } else if (object === constants.SOURCE_OBJECT) {
        endPoint = endPoints.SOURCE.DELETE;
    }  else if (object === constants.LOCATION_OBJECT) {
        endPoint = endPoints.LOCATION.DELETE;
    } else if (object === constants.TASKSLIST_OBJECT) {
        endPoint = endPoints.TASKS_INFO.DELETE;
    } else if (object === constants.ROLL_UP_OBJECT) {
        endPoint = endPoints.USERS.DELETE_ROLL_UP;
    } else if (object === constants.NOTE_TYPES_OBJECT) {
        endPoint = endPoints.NOTE_TYPE.DELETE;
    } else if (object === constants.APPOINTMENT_TYPES_OBJECT) {
        endPoint = endPoints.NOTE_TYPE.DELETE;
    } else if (object === constants.CAMPAIGN_TYPES_OBJECT) {
        endPoint = endPoints.CAMPAIGN_TYPES.DELETE;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.DELETE;
    } else if (object === constants.RELATIONS_OBJECT) {
        endPoint = endPoints.RELATIONS.DELETE;
    } else if (object === constants.RECYCLE_BIN_OBJECT) {
        endPoint = endPoints.ACCOUNTS.PERMANENT_DELETE;
    } else if (object === constants.NEWS_OBJECT) {
        endPoint = endPoints.NEWS.DELETE;
    } else if (object === constants.SMS_TEMPLATE_OBJECT) {
        endPoint = endPoints.SMS_TEMPLATE.DELETE;
    } else if (object === constants.EMAIL_OBJECT) {
        if (params.taskType === 'AutoMail' && !params.isDelete) {
            endPoint = endPoints.SEND_MAIL.DELETE_VERIFY;
        } else {
            endPoint = endPoints.SEND_MAIL.DELETE;
        }
    } else if (object === constants.SMS_OBJECT) {
        if (params.taskType === 'Workflow SMS' && !params.isDelete && params.isDetailView) {
            endPoint = endPoints.SMS_TEMPLATE.DELETE_VERIFY;
        } else if (params.isDetailView) {
            endPoint = endPoints.SMS_TEMPLATE.DELETE_SCHEDULESMS;
        } else {
            endPoint = endPoints.SMS_TEMPLATE.DELETE;
        }
    } else if (object === constants.PETS_OBJECT) {
        endPoint = endPoints.PETS.DELETE;
    } else if (object === constants.ORDERS_OBJECTS) {
        endPoint = endPoints.ORDERS.DELETE;
    } else if (object === constants.ORDER_ITEMS_OBJECTS) {
        endPoint = endPoints.ORDER_ITEMS.DELETE;
    } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
        endPoint = endPoints.LANDING_PAGE.DELETE;
    } else if (object === constants.WORKFLOW_OBJECT) {
        endPoint = endPoints.WORKFLOWS.DELETE;
    } else if (object === constants.CALL_SCRIPT_OBJECT) {
        endPoint = endPoints.CALL_SCRIPT.DELETE;
    } else if (object === constants.QUOTATION_OBJECT) {
        endPoint = endPoints.QUOTATIONS.DELETE;
    } else if (object === constants.PAYMENTS_OBJECT) {
        endPoint = endPoints.PAYMENTS.DELETE;
    } else if (object === constants.INVENTORY_OBJECT) {
        endPoint = endPoints.INVENTORY.DELETE;
    } else if (object === constants.PURCHASE_ORDER_OBJECT) {
        endPoint = endPoints.PURCHASE_ORDER.DELETE;
    } else if (object === constants.SALES_ORDER_OBJECT) {
        endPoint = endPoints.SALES_ORDER.DELETE;
    } else if (object === constants.TIME_OBJECT) {
        endPoint = endPoints.TIME.DELETE;
    } else if (object === constants.PHONE_NUMBERS_OBJECT) {
        endPoint = endPoints.PHONE_NUMBERS.DELETE;
    }else if (object === constants.ROYALTY_RUN_OBJECT) {
        endPoint = endPoints.ROYALTY_RUN.DELETE;
    } else if (object === constants.LMS_USERS_OBJECT) {
        endPoint = endPoints.LMS_USERS.DELETE;
    } else if (object === constants.WEBHOOKS_OBJECT) {
        endPoint = endPoints.WEBHOOKS.DELETE;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.DELETE;
    } else if(object === constants.TEMPLATE_CATEGORIES){
        endPoint = endPoints.TEMPLATE_CATEGORIES.DELETE
    }else {
        endPoint = getObjectSpecificEndpoint(object, endPoints.RECORDS.DELETE);
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));


    return response;
}

function detailViewChildObjectSetupGet(object, params) {

    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.PRODUCTS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.COUPONS_OBJECT) {
        endPoint = endPoints.COUPONS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.RECYCLE_BIN_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.PETS_OBJECT) {
        endPoint = endPoints.PETS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.ORDERS_OBJECTS) {
        endPoint = endPoints.ORDERS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.ORDER_ITEMS_OBJECTS) {
        endPoint = endPoints.ORDER_ITEMS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.INVENTORY_OBJECT) {
        endPoint = endPoints.INVENTORY.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.PURCHASE_ORDER_OBJECT) {
        endPoint = endPoints.PURCHASE_ORDER.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.SALES_ORDER_OBJECT) {
        endPoint = endPoints.SALES_ORDER.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.JOBS_OBJECT) {
        endPoint = endPoints.JOBS.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.COMPLIANCE_OBJECT) {
        endPoint = endPoints.COMPLIANCE.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.TASKS) {
        endPoint = endPoints.TASK.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.ROYALTY_RUN_OBJECT) {
        endPoint = endPoints.ROYALTY_RUN.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.REPORTED_SALES_OBJECT) {
        endPoint = endPoints.REPORTED_SALES.CHILD_OBJECT_SETUP_GET;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CHILD_OBJECT_SETUP_GET;
    } 
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function detailViewChildObjectSetupSave(object, params) {

    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.PRODUCTS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.COUPONS_OBJECT) {
        endPoint = endPoints.COUPONS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.RECYCLE_BIN_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.PETS_OBJECT) {
        endPoint = endPoints.PETS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.ORDERS_OBJECTS) {
        endPoint = endPoints.ORDERS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.ORDER_ITEMS_OBJECTS) {
        endPoint = endPoints.ORDER_ITEMS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.INVENTORY_OBJECT) {
        endPoint = endPoints.INVENTORY.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.PURCHASE_ORDER_OBJECT) {
        endPoint = endPoints.PURCHASE_ORDER.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.SALES_ORDER_OBJECT) {
        endPoint = endPoints.SALES_ORDER.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.JOBS_OBJECT) {
        endPoint = endPoints.JOBS.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.COMPLIANCE_OBJECT) {
        endPoint = endPoints.COMPLIANCE.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.TASKS) {
        endPoint = endPoints.TASK.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.ROYALTY_RUN_OBJECT) {
        endPoint = endPoints.ROYALTY_RUN.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.REPORTED_SALES_OBJECT) {
        endPoint = endPoints.REPORTED_SALES.CHILD_OBJECT_SETUP_SAVE;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CHILD_OBJECT_SETUP_SAVE;
    } 
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
function completeAction(object, params) {
    let endPoint = null;
    if (object === constants.APPOINTMENTS || object === "appointment") {
        endPoint = endPoints.APPOINTMENT.COMPLETE;
    } else if (object === constants.TASKS|| object==='task') {
        endPoint = endPoints.TASK.COMPLETE;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getWorkFlowStage(object, params) {
    let endPoint = endPoints.ACCOUNTS.STAGE_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function getWorkFlowTask(object, params) {
    let endPoint = endPoints.ACCOUNTS.TASK_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function applyWorkFlow(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.APPLY_WORKFLOW;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.APPLY_WORKFLOW;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.APPLY_WORKFLOW;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.APPLY_WORKFLOW;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.APPLY_WORKFLOW;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.APPLY_WORKFLOW;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.APPLY_WORKFLOW;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.APPLY_WORKFLOW;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.APPLY_WORKFLOW;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.APPLY_WORKFLOW;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.APPLY_WORKFLOW;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}
function applyWorkFlowBatch(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.APPLY_WORKFLOW_BATCH;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.APPLY_WORKFLOW_BATCH;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getNoteType() {
    let params = null;
    let endPoint = endPoints.NOTES.NOTE_TYPE;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function getAuditTrail(object, params, recordId) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.AUDIT_TRAIL_GET;
        endPoint = endPoint + "/" + recordId + "/atrail";
    }
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function activeAction(object, params) {
    let endPoint = null;
    if (object === constants.SALES_TEMPLATE_OBJECT) {
        endPoint = endPoints.SALES_TEMPLATE.ACTIVE;
    } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
        endPoint = endPoints.MARKETING_TEMPLATE.ACTIVE;
    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
        endPoint = endPoints.AGREEMENT_TEMPLATE.ACTIVE;
    } else if (object === constants.SMS_TEMPLATE_OBJECT) {
        endPoint = endPoints.SMS_TEMPLATE.ACTIVE;
    } else if (object === constants.CALL_SCRIPT_OBJECT) {
        endPoint = endPoints.CALL_SCRIPT.ACTIVE;
    }
    let response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function inActiveAction(object, params) {
    let endPoint = null;
    if (object === constants.SALES_TEMPLATE_OBJECT) {
        endPoint = endPoints.SALES_TEMPLATE.INACTIVE;
    } else if (object === constants.MARKETING_TEMPLATE_OBJECT) {
        endPoint = endPoints.MARKETING_TEMPLATE.INACTIVE;
    } else if (object === constants.SMS_TEMPLATE_OBJECT) {
        endPoint = endPoints.SMS_TEMPLATE.INACTIVE;
    } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
        endPoint = endPoints.AGREEMENT_TEMPLATE.INACTIVE;
    } else if (object === constants.CALL_SCRIPT_OBJECT) {
        endPoint = endPoints.CALL_SCRIPT.INACTIVE;
    }
    let response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function publishWebForm(object, params) {
    let endPoint = null;
    if (object === constants.WEB_FORMS) {
        endPoint = endPoints.WEB_FORMS.PUBLISH;
    } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
        params.status = constants.PUBLISHED;
        endPoint = endPoints.LANDING_PAGE.UPDATE_STATUS;
    }
    let response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}


function unPublishWebForm(object, params) {
    let endPoint = null;
    if (object === constants.WEB_FORMS) {
        endPoint = endPoints.WEB_FORMS.UNPUBLISH;
    } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
        params.status = constants.UNPUBLISHED;
        endPoint = endPoints.LANDING_PAGE.UPDATE_STATUS;
    }
    let response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function checkRecordWFId(object, selectedIds) {
    let endPoint = null;
    let params = {
        selected_ids: selectedIds,
    }
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CHECK_DIFFRENT_WORKFLOW_ID;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CHECK_DIFFRENT_WORKFLOW_ID;
    }
    let response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function checkRecordStatus(object, selectedIds) {
    let endPoint = null;
    let params = {
        selected_ids: selectedIds,
    }
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.CHECK_DIFFRENT_WORKFLOW_STATUS;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.CHECK_DIFFRENT_WORKFLOW_STATUS;
    }
    let response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function batchEditAction(object, params) {

    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {        
        endPoint = endPoints.ACCOUNTS.BATCH_EDIT;
    } else if (object === constants.CONTACTS_OBJECT) {        
        endPoint = endPoints.CONTACTS.BATCH_EDIT;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {        
        endPoint = endPoints.OPPORTUNITIES.BATCH_EDIT;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.BATCH_EDIT;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.BATCH_EDIT;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.BATCH_EDIT;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.BATCH_EDIT;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.BATCH_EDIT;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.BATCH_EDIT;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.BATCH_EDIT;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.BATCH_EDIT;
    } else if (object === constants.GROUP_OBJECT) {
        endPoint = endPoints.GROUP.BATCH_EDIT;
    } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
        endPoint = endPoints.BROADCAST_CAMPAIGNS.BATCH_EDIT;
    } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
        endPoint = endPoints.AB_CAMPAIGNS.BATCH_EDIT;
    } else if (object === constants.AUTOMATION_DESIGNER_OBJECT) {
        endPoint = endPoints.AUTOMATION_DESIGNER.BATCH_EDIT;
    } else if (object === constants.APPOINTMENTS) {
        endPoint = endPoints.APPOINTMENT.BATCH_EDIT;
    } else if (object === constants.TASKS) {
        endPoint = endPoints.TASK.BATCH_EDIT;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.BATCH_EDIT;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.PRODUCTS.BATCH_EDIT;
    } else if (object === constants.INVENTORY_OBJECT) {
        endPoint = endPoints.INVENTORY.BATCH_EDIT;
    } else if (object === constants.TIME_OBJECT) {
        endPoint = endPoints.TIME.BATCH_EDIT;
    } else if (object === constants.ROYALTY_OBJECT) {
        endPoint = endPoints.ROYALITY.BATCH_EDIT;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.BATCH_EDIT;
    }
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function getMergeRecords(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.MERGE_GET;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.MERGE_GET;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.MERGE_GET;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.MERGE_GET;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.MERGE_GET;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.MERGE_GET;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.MERGE_GET;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.MERGE_GET;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.MERGE_GET;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.MERGE_GET;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.MERGE_GET;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.MERGE_GET;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.MERGE_GET;
    }
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function saveMergeRecords(object, params) {
    let endPoint = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.MERGE_SAVE;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.MERGE_SAVE;
    } else if (object === constants.PRODUCTS_OBJECT) {
        endPoint = endPoints.PRODUCTS.MERGE_SAVE;
    } else if (object === constants.CASES_OBJECT) {
        endPoint = endPoints.CASES.MERGE_SAVE;
    } else if (object === constants.PROJECTS_OBJECT) {
        endPoint = endPoints.PROJECTS.MERGE_SAVE;
    } else if (object === constants.ISSUES_OBJECT) {
        endPoint = endPoints.ISSUES.MERGE_SAVE;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        endPoint = endPoints.SOLUTIONS.MERGE_SAVE;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE1.MERGE_SAVE;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE2.MERGE_SAVE;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE3.MERGE_SAVE;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        endPoint = endPoints.CUSTOM_TABLE4.MERGE_SAVE;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        endPoint = endPoints.SALES_LEADS.MERGE_SAVE;
    } else if (object === constants.PARTNERS_OBJECT) {
        endPoint = endPoints.PARTNERS.MERGE_SAVE;
    }
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function uploadPhoto(object, params) {
    let endPoint = null;

    if (object === constants.ACCOUNTS_OBJECT) {
        endPoint = endPoints.ACCOUNTS.ADD_PHOTO;
    } else if (object === constants.CONTACTS_OBJECT) {
        endPoint = endPoints.CONTACTS.ADD_PHOTO;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        endPoint = endPoints.OPPORTUNITIES.ADD_PHOTO;
    } else if (object === constants.PETS_OBJECT) {
        endPoint = endPoints.PETS.ADD_PHOTO;
    }
    var response = Promise.resolve(HTTPClient.postFormData(endPoint, params));
    return response;
}
function removeLeadAndContactsFromGroup(params) {
    var response = Promise.resolve(HTTPClient.post(endPoints.GROUP.REMOVE_LEADS_CONTACTS, params));
    return response
}

//for save new source
function saveSource(params) {
    let endPoint = null;
    endPoint = endPoints.SOURCE.SAVE;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function deleteWebForms(params) {
    let promise = Promise.resolve(HTTPClient.post(endPoints.WEB_FORMS.DELETE, params));
    return promise;
}

function updateMarketingTemplate(params) {
    let endPoint = null;
    endPoint = endPoints.MARKETING_TEMPLATE.BATCH_EDIT;
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function isOpenOpportunityExits(params) {
    let endPoint = null;
    endPoint = endPoints.ACCOUNTS.IS_OPEN_OPPORTUNITY_EXITS_GET;
    var response = Promise.resolve(HTTPClient.get(endPoint, params));
    return response;
}

function updateFields(object, params) {
    let endPoint = endPoints.RECORDS.UPDATE_FIELDS;
    endPoint = getObjectSpecificEndpoint(object, endPoint);
    var response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

function leasedNumberNotification(object, params) {
    let endPoint = endPoints.PHONE_NUMBERS.USE_NOTIFICATION;
    let response = Promise.resolve(HTTPClient.post(endPoint, params));
    return response;
}

export {
    getConvert,
    saveConvert,
    saveBatchConvert,
    getGrouplist,
    addToGroupAction,
    saveGroup,
    assignAction,
    shareAction,
    pushcompanylist,
    pushBasecompanylist,
    reschedule,
    deleteAction,
    completeAction,
    getWorkFlowStage,
    getWorkFlowTask,
    applyWorkFlow,
    applyWorkFlowBatch,
    getNoteType,
    getAuditTrail,
    activeAction,
    inActiveAction,
    shareRecordGet,
    detailViewChildObjectSetupGet,
    detailViewChildObjectSetupSave,
    checkRecordWFId,
    checkRecordStatus,
    batchEditAction,
    getMergeRecords,
    saveMergeRecords,
    uploadPhoto,
    removeLeadAndContactsFromGroup,
    pushwflist,
    pushaction,
    saveSource,
    deleteWebForms,
    updateMarketingTemplate,
    isOpenOpportunityExits,
    publishWebForm,
    unPublishWebForm,
    updateFields,
    leasedNumberNotification,
}
