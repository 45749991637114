import * as ParameterVerifier from '..//helper/parameterVerifier';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import toNumber from 'lodash/toNumber';
import store from '../../../src/services/store';
import { sortArrayObjectByProperty } from '..//helper/utils';
import { actions } from '..//constants/actions';
import { getActiveTabInfo } from '../../services/helper/sfTabManager';


function getEndPointObject(object) {
    let linkObject = null;
    try {
        if (ParameterVerifier.isValidParam(object)) {
            switch (object) {
                case constants.ACCOUNTS_OBJECT:
                    linkObject = endPoints.ACCOUNTS;
                    break;
                case constants.CONTACTS_OBJECT:
                    linkObject = endPoints.CONTACTS;
                    break;
                case constants.OPPORTUNITIES_OBJECT:
                    linkObject = endPoints.OPPORTUNITIES;
                    break;
                case constants.PROJECTS_OBJECT:
                    linkObject = endPoints.PROJECTS;
                    break;
                case constants.CASES_OBJECT:
                    linkObject = endPoints.CASES;
                    break;
                case constants.SOLUTIONS_OBJECT:
                    linkObject = endPoints.SOLUTIONS;
                    break;
                case constants.ISSUES_OBJECT:
                    linkObject = endPoints.ISSUES;
                    break;
                case constants.NOTE_OBJECT:
                    linkObject = endPoints.NOTES;
                    break;
                case constants.APPOINTMENTS:
                    linkObject = endPoints.APPOINTMENT;
                    break;
                case constants.TASKS:
                    linkObject = endPoints.TASK;
                    break;
                case constants.GROUP_OBJECT:
                    linkObject = endPoints.GROUP;
                    break;
                case constants.AB_CAMPAIGNS_OBJECT:
                    linkObject = endPoints.AB_CAMPAIGNS;
                    break;
                case constants.AUTOMATION_DESIGNER_OBJECT:
                    linkObject = endPoints.AUTOMATION_DESIGNER;
                    break;
                case constants.BROADCAST_CAMPAIGNS_OBJECT:
                    linkObject = endPoints.BROADCAST_CAMPAIGNS;
                    break;
                case constants.CUSTOM_TABLE1_OBJECT:
                    linkObject = endPoints.CUSTOM_TABLE1;
                    break;
                case constants.CUSTOM_TABLE2_OBJECT:
                    linkObject = endPoints.CUSTOM_TABLE2;
                    break;
                case constants.CUSTOM_TABLE3_OBJECT:
                    linkObject = endPoints.CUSTOM_TABLE3;
                    break;
                case constants.CUSTOM_TABLE4_OBJECT:
                    linkObject = endPoints.CUSTOM_TABLE4;
                    break;
                case constants.EMAIL_OBJECT:
                    linkObject = endPoints.EMAIL;
                    break;
                case constants.COUPONS_OBJECT:
                    linkObject = endPoints.COUPONS;
                    break;
                case constants.SALES_LEADS_OBJECT:
                    linkObject = endPoints.SALES_LEADS;
                    break;
                case constants.RECYCLE_BIN_OBJECT:
                    linkObject = endPoints.ACCOUNTS;
                    break;
                case constants.PETS_OBJECT:
                    linkObject = endPoints.PETS;
                    break;
                case constants.ORDERS_OBJECTS:
                    linkObject = endPoints.ORDERS;
                    break;
                case constants.ORDER_ITEMS_OBJECTS:
                    linkObject = endPoints.ORDER_ITEMS;
                    break;
                case constants.ATTACHMENT_OBJECT:
                    linkObject = endPoints.ATTACHMENT;
                    break;
                case constants.NOTES:
                    linkObject = endPoints.NOTES;
                    break;
                case constants.PRODUCTS_OBJECT:
                    linkObject = endPoints.PRODUCTS;
                    break;
                case constants.CLAIMS_OBJECT:
                    linkObject = endPoints.CLAIMS;
                    break;
                case constants.EVENT_BRITE_OBJECT:
                    linkObject = endPoints.EVENTBRITE_INTEGRATION;
                    break;
                case constants.QUOTATION_OBJECT:
                    linkObject = endPoints.QUOTATIONS;
                    break;
                case constants.PAYMENTS_OBJECT:
                    linkObject = endPoints.PAYMENTS;
                    break;
                case constants.INVENTORY_OBJECT:
                    linkObject = endPoints.INVENTORY;
                    break;
                case constants.PURCHASE_ORDER_OBJECT:
                    linkObject = endPoints.PURCHASE_ORDER;
                    break;
                case constants.SALES_ORDER_OBJECT:
                    linkObject = endPoints.SALES_ORDER;
                    break;
                case constants.TIME_OBJECT:
                    linkObject = endPoints.TIME;
                    break;
                case constants.JOBS_OBJECT:
                    linkObject = endPoints.JOBS;
                    break;
                case constants.LINE_ITEMS_OBJECT:
                    linkObject = endPoints.lINE_ITEMS;
                    break;
                case constants.ROYALTY_OBJECT:
                    linkObject = endPoints.ROYALITY;
                    break;
                case constants.AUDITS_OBJECT:
                    linkObject = endPoints.AUDITS;
                    break;
                case constants.COMPLIANCE_OBJECT:
                    linkObject = endPoints.COMPLIANCE;
                    break;
                case constants.REPORTED_SALES_OBJECT:
                    linkObject = endPoints.REPORTED_SALES;
                    break;
                case constants.REFUND_OBJECT:
                    linkObject = endPoints.REFUND;
                    break;
                case constants.ROYALTY_PLANS_OBJECT:
                    linkObject = endPoints.ROYALTY_PLANS;
                    break;
                case constants.ROYALTY_RUN_OBJECT:
                    linkObject = endPoints.ROYALTY_RUN;
                    break;
                    case constants.PARTNERS_OBJECT:
                        linkObject = endPoints.PARTNERS;
                        break;
                default:
                    break;
            }
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getEndPointObject()':" + error);
    }
    return linkObject;
}

function getSFFormFieldsPromise(object, params) {
    let linkObject = null;
    let url = null;
    var promise = null;

    try {

        linkObject = getEndPointObject(object);
        if (ParameterVerifier.isValidParam(linkObject)) {
            params = ParameterVerifier.getObjectParam(params);
            let state = store.getState();
            if (state.app.selectedModuleName === constants.MODULE_HUB && (object === constants.CASES_OBJECT || object === constants.NOTE_OBJECT)) {
                params.call_from = constants.MODULE_HUB;
            }
            url = linkObject.FIELDS;
            let info = getActiveTabInfo();

            if (ParameterVerifier.isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
                params.isUnitsAccount = info.isUnitsAccount;
                params.UnitsAccountProjectId = info.UnitsAccountProjectId;
            }
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getSFFormFieldsPromise()':" + error);
    }
    return promise;
}

function getCacheFromFieldsWithPromise(object) {
    let linkObject = null;
    let url = null;
    var promise = null;
    let params = {};
    try {
        linkObject = getEndPointObject(object);
        if (ParameterVerifier.isValidParam(linkObject)) {
            let state = store.getState();
            if (state.app.selectedModuleName === constants.MODULE_HUB && (object === constants.CASES_OBJECT || object === constants.NOTE_OBJECT)) {
                params.call_from = constants.MODULE_HUB;
            }
            url = linkObject.FIELDS;
            // promise = Promise.resolve(HTTPClient.get(url, {}));
            promise = new Promise((resolve, reject) => {
                var response = Promise.resolve(HTTPClient.get(url, params));
                response.then((response) => {
                    let _state = store.getState();
                    let sfForm = _state.sfForm;
                    sfForm.data[object] = response;
                    resolve(response);
                });
            });
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getSFFormFieldsWithPromise()':" + error);
    }
    return promise;
}

function getRecordPromise(object, id, params) {
    let linkObject = null;
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(id)) {
            linkObject = getEndPointObject(object);
            if (ParameterVerifier.isValidParam(linkObject)) {
                url = linkObject.EDIT_COPY + '/' + toNumber(id);
                promise = Promise.resolve(HTTPClient.get(url, params));
            }
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getRecordPromise()':" + error);
    }
    return promise;
}

function getPrimaryContactPromise(id) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(id)) {
            url = endPoints.ACCOUNTS.PRIMARY_CONTACT + '/' + toNumber(id);
            promise = Promise.resolve(HTTPClient.get(url, null));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getPrimaryContactPromise()':" + error);
    }
    return promise;
}

function saveRecordPromise(object, recordObj) {
    let linkObject = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(recordObj)) {
            linkObject = getEndPointObject(object);
            if (ParameterVerifier.isValidParam(linkObject)) {
                if (object === constants.GROUP_OBJECT) {
                    promise = Promise.resolve(HTTPClient.post(linkObject.SAVE_GROUP, recordObj));
                } else {
                    promise = Promise.resolve(HTTPClient.post(linkObject.SAVE, recordObj));
                }
            }
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> saveRecordPromise()':" + error);
    }
    return promise;
}

function saveCustomizeFieldsSetupPromise(object, recordObj) {
    let linkObject = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(recordObj)) {
            linkObject = getEndPointObject(object);
            if (ParameterVerifier.isValidParam(linkObject)) {
                promise = Promise.resolve(HTTPClient.post(linkObject.CUSTOMIZE_FIELDS_SETUP_SAVE, recordObj));
            }
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> saveRecordPromise()':" + error);
    }
    return promise;
}

function getFieldListValuesPromise(param) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.FORM.LIST_VALUES;
            promise = Promise.resolve(HTTPClient.get(url, param));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getFieldListValuesPromise()':" + error);
    }
    return promise;
}

function getLinkRecords(object, param) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            if (object === constants.ACCOUNTS_OBJECT) {
                url = endPoints.ACCOUNTS.LINK_CHILD_RECORD_GET;
            }
            promise = Promise.resolve(HTTPClient.get(url, param));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getLinkRecords()':" + error);
    }
    return promise;
}

function getWorkflowStatusDetailsList(param) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.WORKFLOWS.LIST_STAGES_DETAILS_GET;
            promise = Promise.resolve(HTTPClient.get(url, param));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getWorkflowStatusDetailsList()':" + error);
    }
    return promise;
}

function getContactConvertRecord(params) {
    let linkObject = null;
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(params)) {
            linkObject = getEndPointObject(constants.CONTACTS_OBJECT);
            url = linkObject.CONVERT_GET;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getContactConvertRecord()':" + error);
    }
    return promise;
}


function getLookupAutocompleteInfo(params) {
    let url = null;
    try {
        if (params !== null && params !== undefined && params !== '') {
            url = endPoints.COMMON.LOOKUP_AUTOCOMPLETE_INFO_GET;
            return HTTPClient.get(url, params);
        }

    } catch (e) { console.error("Error in 'sfFormActions.js -> getLookupAutocompleteInfo()':" + e); }
}


function getContactInfoForLookup(params) {

    let url = null;
    try {
        if (params !== null && params !== undefined && params !== '') {
            url = endPoints.COMMON.LOOKUP_CONTACT_INFO_GET;
            return HTTPClient.get(url, params);
        }

    } catch (e) { console.error("Error in 'sfFormActions.js -> getContactInfoForLookup()':" + e); }
}

function checkDuplicateGroup(params) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(params)) {
            url = endPoints.GROUP.CHECK_DUPLICATE_GROUP;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> checkDuplicateGroup()':" + error);
    }
    return promise;
}
function getContactRecordsInfo(params) {
    let promise = null, url = null;
    try {
        if (ParameterVerifier.isValidParam(params)) {
            url = endPoints.ACCOUNTS.LINK_CHILD_RECORD_GET;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getContactRecordsInfo()':" + error);
    }
    return promise;
}

function checkDuplicateSourceName(sourceName) {
    let promise = null;
    try {
        sourceName = ParameterVerifier.getStringParam(sourceName);
        if (sourceName !== '') {
            let params = { source_name: sourceName };
            let url = endPoints.COMMON.CHECK_DUPLICATE_SOURCE_NAME;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> checkDuplicateSourceName()':" + error);
    }
    return promise;
}

function saveSourceName(sourceName) {
    let promise = null;
    try {
        sourceName = ParameterVerifier.getStringParam(sourceName);
        if (sourceName !== '') {
            let params = { source_name: sourceName };
            let url = endPoints.COMMON.SAVE_SOURCE_NAME;
            promise = Promise.resolve(HTTPClient.post(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> saveSourceName()':" + error);
    }
    return promise;
}

function saveListFieldName(fieldName, value) {
    let promise = null;
    let params = null;
    let url = null;
    try {
        value = ParameterVerifier.getStringParam(value);
        if (fieldName === 'source') {
            params = { source_name: value };
            url = endPoints.COMMON.SAVE_SOURCE_NAME;
        } else if (fieldName === 'note_type') {
            params = {
                note_types: value,
                'type': "Note Types"
            };
            url = endPoints.NOTE_TYPE.SAVE;
        } else if (fieldName === 'event_type') {
            params = {
                note_types: value,
                'type': "Appointment Types"
            };
            url = endPoints.NOTE_TYPE.SAVE;
        }
        if (value !== '' && url !== null) {
            promise = Promise.resolve(HTTPClient.post(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> saveListFieldName()':" + error);
    }
    return promise;
}

function addSourceOnRedux(object, fieldName, value) {
    try {
        let state = store.getState();
        let data = ParameterVerifier.getObjectParam(state.sfForm.data);
        let sfForm = data[object];
        if (ParameterVerifier.isValidParam(sfForm)) {
            let fields = ParameterVerifier.getArrayParam(sfForm.fields);
            fields.forEach(field => {
                if (field.name === fieldName) {
                    let values = ParameterVerifier.getArrayParam(field.list_values);
                    values.push({ id: value, value: value });
                    field.list_values = values;
                }
            });

            sfForm.fields = fields;
            data[object] = sfForm;
            state.sfForm.data = data;
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> addSourceOnRedux()':" + error);
    }
}

function updateSFFormReminderFunc (data){
    return (dispatch) => {
        dispatch({
            type: actions.UPDATE_SFFORM_REMINDER_FUNC,
            payload: {
                data: data,
            },
        });
    };
}

function removeObjectListItemFromRedux(object, fieldName, value) {
    try {
        let state = store.getState();
        let data = ParameterVerifier.getObjectParam(state.sfForm.data);
        let sfForm = data[object];
        if (sfForm !== undefined) {
            if (ParameterVerifier.isValidParam(sfForm)) {
                let fields = ParameterVerifier.getArrayParam(sfForm.fields);
                fields.forEach(field => {
                    if (field.name === fieldName) {
                        let values = ParameterVerifier.getArrayParam(field.list_values).filter(f => f.value !== value);
                        field.list_values = values;
                    }
                });

                sfForm.fields = fields;
                data[object] = sfForm;
                state.sfForm.data = data;
                if (fieldName === "event_type") {
                    let appointmentTypeField = sfForm.fields.filter(f => { return f.name === 'event_type' });
                    let appointmentTypeItems = appointmentTypeField[0].list_values;
                    if (appointmentTypeItems.length > 0) {

                        let param = {};
                        param.object = constants.APPOINTMENTS;
                        param.name = 'event_type';
                        param.list_type = 'external';

                        let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

                        promise.then((response) => {
                            if (ParameterVerifier.isValidParam(response)) {
                                let type = ParameterVerifier.getArrayParam(response.record);
                                type.sort(function (a, b) {
                                    if (a.value.toLowerCase() > b.value.toLowerCase()) { return 1; };
                                    if (a.value.toLowerCase() < b.value.toLowerCase()) { return -1; };
                                    return 0;
                                });
                                let appTypes = type.map((f, i) => {
                                    let typeObj = {};
                                    typeObj.value = f.value;
                                    typeObj.label = f.value;
                                    typeObj.color = f.color;
                                    return typeObj;
                                });

                                sortArrayObjectByProperty(appTypes, 'label');
                                state.calendar.calendarInfo.appTypes = appTypes;

                            }
                        });

                    }
                }
            }
        } else {
            let appointmentTypeItems = state.calendar.calendarInfo.appTypes;
            appointmentTypeItems = ParameterVerifier.getArrayParam(appointmentTypeItems).filter(f => f.label !== 'All')
            appointmentTypeItems.forEach(appTypes => {

                let values = ParameterVerifier.getArrayParam(appointmentTypeItems).filter(f => f.value !== value);
                appointmentTypeItems = values;

            });

            if (appointmentTypeItems.length > 0) {
                // let calendarInfo = this.props.calendar.calendarInfo;
                let param = {};
                param.object = constants.APPOINTMENTS;
                param.name = 'event_type';
                param.list_type = 'external';

                let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.APPOINTMENT_TYPE_LIST, param));

                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        let type = ParameterVerifier.getArrayParam(response.record);
                        type.sort(function (a, b) {
                            if (a.value.toLowerCase() > b.value.toLowerCase()) { return 1; };
                            if (a.value.toLowerCase() < b.value.toLowerCase()) { return -1; };
                            return 0;
                        });
                        let appTypes = type.map((f, i) => {
                            let typeObj = {};
                            typeObj.value = f.value;
                            typeObj.label = f.value;
                            typeObj.color = f.color;
                            return typeObj;
                        });

                        sortArrayObjectByProperty(appTypes, 'label');
                        state.calendar.calendarInfo.appTypes = appTypes;

                    }
                });

            }
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> removeObjectListItemFromRedux()':" + error);
    }
}

function saveListValues(object, fieldId, values) {
    let promise = null;
    try {
        object = ParameterVerifier.getStringParam(object);
        fieldId = ParameterVerifier.getIntParam(fieldId);
        values = ParameterVerifier.getArrayParam(values);
        if (object !== '' && fieldId > 0) {
            let params = { object: object, id: fieldId, list_values: values };
            let url = endPoints.COMMON.SAVE_LIST_VALUES;
            promise = Promise.resolve(HTTPClient.post(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> saveListValues()':" + error);
    }
    return promise;
}

function getTasksCompletedPromise(ids) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(ids)) {
            url = endPoints.TASK.TASKS_COMPLETE_STATUS;
            promise = Promise.resolve(HTTPClient.get(url, { ids: ids }));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getTasksCompletedPromise()':" + error);
    }
    return promise;
}

function getProjectDepTasksList(parentObject, params) {
    let url = null;
    var promise = null;
    try {
        if (parentObject === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.GET_DEP_TASKS;
        }
        if (ParameterVerifier.isValidParam(url)) {
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getProjectDepTasksList()':" + error);
    }
    return promise;
}

function getFranchiseInfo(recordId) {
    let url = null;
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(recordId)) {
            url = endPoints.ACCOUNTS.FRANCHISE_INFO_GET + "/" + recordId;
            promise = Promise.resolve(HTTPClient.get(url));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getFranchiseInfo()':" + error);
    }
    return promise;
}
function checkDuplicateFranchise(param) {
    let url = null;
    let promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            url = endPoints.ACCOUNTS.CHECK_DUPLICATE_FRANCISE;
        }
        promise = Promise.resolve(HTTPClient.get(url, param));
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> checkDuplicateFranchise()':" + error);
    }
    return promise;
}

function checkDuplicateProductName(paramObject) {
    let promise = null;
    try {
        if (ParameterVerifier.isValidParam(paramObject)) {
            let params = paramObject;
            let url = endPoints.PRODUCTS.CHECK_DUPLICATE_PRODUCT_NAME;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> checkDuplicateProductName()':" + error);
    }
    return promise;
}


function getProductType(paramObject) {
    let promise = null;
    try {
        if (ParameterVerifier.isValidParam(paramObject)) {
            let params = paramObject;
            let url = endPoints.PRODUCTS.GET_PRODUCT_TYPE;
            promise = Promise.resolve(HTTPClient.get(url, params));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getProductType()':" + error);
    }
    return promise;
}

function getQuoteList(param) {
    let promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
            let url = endPoints.PAYMENTS.GET_QUOTE;
            promise = Promise.resolve(HTTPClient.get(url, param));
        }
    } catch (error) {
        console.error("Error in 'sfFormActions.js -> getQuoteList()':" + error);
    }
    return promise;
}
function updateFromData(data) {
    try {
        store.dispatch({ type: actions.SF_FROM_DATA_UPDATE, payload: data });
    }
    catch (e) { console.error("Error in 'sfFromActions.js -> SF_FROM_DATA_UPDATE':" + e); }
}
function updateSfRecentData(object,data) {
    try {
        store.dispatch({ type: actions.UPDATE_SF_RECENT_DATA, payload: {object,data} });
    }
    catch (e) { console.error("Error in 'sfFromActions.js -> SF_FROM_DATA_UPDATE':" + e); }
}
function clearSfRecentData() {
    try {
        store.dispatch({ type: actions.CLEAR_SF_RECENT_DATA, payload: {} });
    }
    catch (e) { console.error("Error in 'sfFromActions.js -> SF_FROM_DATA_UPDATE':" + e); }
}

const setFieldDetails = (payload) => ({ type: actions.SET_FIELD_DETAILS, payload });

export {
    getSFFormFieldsPromise,
    getRecordPromise,
    saveRecordPromise,
    getFieldListValuesPromise,
    saveCustomizeFieldsSetupPromise,
    getLinkRecords,
    getEndPointObject,
    getCacheFromFieldsWithPromise,
    getWorkflowStatusDetailsList,
    getContactConvertRecord,
    getLookupAutocompleteInfo,
    getContactInfoForLookup,
    checkDuplicateGroup,
    getContactRecordsInfo,
    checkDuplicateSourceName,
    saveSourceName,
    addSourceOnRedux,
    saveListValues,
    getPrimaryContactPromise,
    getTasksCompletedPromise,
    getProjectDepTasksList,
    getFranchiseInfo,
    checkDuplicateFranchise,
    saveListFieldName,
    removeObjectListItemFromRedux,
    updateSFFormReminderFunc,
    checkDuplicateProductName,
    getProductType,
    getQuoteList,
    updateFromData,
    clearSfRecentData,
    updateSfRecentData,
    setFieldDetails
}
