import * as ParameterVerifier from '../helper/parameterVerifier';
import { endPoints } from '../constants/endPoints';
import * as HTTPClient from '../helper/httpClient';
import { actions } from '../constants/actions';

function saveWebhookRule(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.WEBHOOKS.SAVE, param)); 
        }
    } catch (error) {
        console.error("Error in 'webhookRuleActions.js -> saveWebhookRule()':" + error);
    }
    return promise;
}

function editWebhookRule(id) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(id) && id > 0) {
             promise = Promise.resolve(HTTPClient.get(endPoints.WEBHOOKS.EDIT + id, {})); 
        }
    } catch (error) {
        console.error("Error in 'webhookRuleActions.js -> editWebhookRule()':" + error);
    }
    return promise;
}

function isWebhookRuleExists(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.get(endPoints.WEBHOOKS.IS_RULE_EXISTS, param)); 
        }
    } catch (error) {
        console.error("Error in 'webhookRuleActions.js -> isWebhookRuleExists()':" + error);
    }
    return promise;
}

function setMounted(isMounted) {
    return {
        type: actions.ASSIGN_LISTVIEW_SET_MOUNTED,
        payload: isMounted
    };
}

function getWebhookRules(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.get(endPoints.WEBHOOKS.GET_RULES, param)); 
        }
    } catch (error) {
        console.error("Error in 'webhookRuleActions.js -> getWebhookRules()':" + error);
    }
    return promise;
}

function deleteWebhookRule(param) {
    var promise = null;
    try {
        if (ParameterVerifier.isValidParam(param)) {
             promise = Promise.resolve(HTTPClient.post(endPoints.WEBHOOKS.DELETE, param)); 
        }
    } catch (error) {
        console.error("Error in 'webhookRuleActions.js -> deleteWebhookRule()':" + error);
    }
    return promise;
}

export {
    saveWebhookRule,
    editWebhookRule,
    isWebhookRuleExists,
    setMounted,
    getWebhookRules,
    deleteWebhookRule
}