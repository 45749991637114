import React, { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch } from "react-redux";

const UnitCrm = () => {

    const dispatch = useDispatch();

    const [isFormsChecked, setIsFormsChecked] = useState(true);
    const [isWorkflowChecked, setIsWorkflowChecked] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [isStageChecked,setIsStageChecked] = useState(true);
    const [isTaskChecked,setIsTaskChecked] = useState(true);
    const [emailAliases,setEmailAliases] = useState(true);
    const [survey,setsurvey] = useState(true);
    const [userSecurity,setUserSecurity] = useState(true);
    const [workflowsRules,setWorkflowsRules] = useState(true);
    const [customaization,setCustomaization] = useState(true);
    const [msLift,setMsList] = useState(true);
    const [isDataChecked,setIsDataChecked] = useState(true);
    const [companyScheduler,setCompanyScheduler] = useState(true);
    const [franchise,setFranchise] = useState(true);
    const [integration,setIntegration] = useState(true);
    const [subscription,setSubscription] = useState(true);
    const [isBoardCastChecked,setIsBoardCastChecked] = useState(true);
    const [isAutomatedChecked,setIsAutomatedChecked] = useState(true);

    
    useEffect(() => {
        getUnitCrmSetup();
    }, []);


    const getUnitCrmSetup = () => {
        try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.UNIT_CRM.UNIT_CRM_SETUP_GET, null));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        let tempIsFormChecked = response.fromCustomize === 1 ? true : false;
                        let tempIsWorkflowChecked = response.workflowCustomize === 1 ? true : false;
                        let _isStageChecked = response.updateStage === 1 ? true : false;
                        let _isTaskChecked = response.updateTasks === 1 ? true : false;
                        setIsFormsChecked(tempIsFormChecked);
                        setIsWorkflowChecked(tempIsWorkflowChecked);
                        setIsStageChecked(_isStageChecked);
                        setIsTaskChecked(_isTaskChecked);
                        setEmailAliases(response.MS_EMAIL_ALIASES === 1 ? true : false);
                        setsurvey(response.MS_SURVEYS === 1 ? true : false);
                        setUserSecurity(response.MS_USERS_SECURITY === 1 ? true : false);
                        setWorkflowsRules(response.MS_WORKFLOW_RULE === 1 ? true : false);
                        setCustomaization(response.MS_CUSTOMIZATION === 1 ? true : false);
                        setMsList(response.MS_LIST === 1 ? true : false);
                        setIsDataChecked(response.MS_DATA === 1 ? true : false);
                        setCompanyScheduler(response.MS_COMPANY_SCHEDULER === 1 ? true :false);
                        setFranchise(response.MS_FRANCHISE === 1 ? true : false);
                        setIntegration(response.MS_SETTING_INTEGRATION === 1 ? true : false);
                        setSubscription(response.MS_SUBSCRIPTION === 1 ? true : false);
                        setIsBoardCastChecked(response.MS_BROADCAST === 1 ? true : false);
                        setIsAutomatedChecked(response.MS_AUTOMATION === 1 ? true : false);

                        setMounted(true);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'UnitCrm.js -> getUnitCrmSetup()':" + error);
        }

    }

    const handleInputChange = (isChecked, name) => {
        if (name === "isFormsChecked") {
            setIsFormsChecked(isChecked);
        } else if(name === "isWorkflowChecked"){
            setIsWorkflowChecked(isChecked);
            setIsStageChecked(isChecked);
            setIsTaskChecked(isChecked);
        }else if(name === "isStageChecked"){
            if(isChecked){
                setIsWorkflowChecked(isChecked);
            }else if(!isChecked && !isTaskChecked){
                setIsWorkflowChecked(isChecked);
            }
            setIsStageChecked(isChecked);
        }else if(name === 'isTaskChecked'){
            if(isChecked){
                setIsWorkflowChecked(isChecked);
            }else if(!isChecked && !isStageChecked){
                setIsWorkflowChecked(isChecked);
            }
            setIsTaskChecked(isChecked);
        }else if(name === 'Email_Aliases'){
            setEmailAliases(isChecked);
        }else if(name === 'surveys'){
            setsurvey(isChecked);
        }else if(name === 'Users_Security'){
            setUserSecurity(isChecked);
        }else if(name === 'Workflows_Rules_section'){
            setWorkflowsRules(isChecked);
        }else if(name === 'customization'){
            setCustomaization(isChecked);
        }else if(name === 'msLift'){
            setMsList(isChecked);
        }else if(name === 'data'){
            setIsDataChecked(isChecked)
        }else if(name === 'Company_scheduler'){
            setCompanyScheduler(isChecked);
        }else if(name === 'franchise'){
            setFranchise(isChecked);
        }else if(name === 'integration'){
            setIntegration(isChecked);
        }else if(name === 'subscription'){
            setSubscription(isChecked);
        }else if(name === 'isBoardCastChecked'){
            setIsBoardCastChecked(isChecked);
        }else if(name === 'isAutomatedChecked'){
            setIsAutomatedChecked(isChecked);
        }
    }

    
    const saveUnitCrmSetup = () => {
        try {
            //let _state = this.state;
            let params = {};
            params.fromCustomize = isFormsChecked ? 1 : 0;
            params.workflowCustomize = isWorkflowChecked ? 1 : 0;
            params.updateStage = isStageChecked ? 1 : 0;
            params.updateTasks = isTaskChecked ? 1 : 0;
            params.MS_EMAIL_ALIASES = emailAliases ? 1 : 0;
            params.MS_SURVEYS = survey ? 1 : 0;
            params.MS_USERS_SECURITY = userSecurity ? 1 : 0;
            params.MS_WORKFLOW_RULE = workflowsRules ? 1 : 0;
            params.MS_CUSTOMIZATION = customaization ? 1 : 0;
            params.MS_LIST = msLift ? 1 : 0;
            params.MS_DATA = isDataChecked ? 1 : 0;
            params.MS_COMPANY_SCHEDULER = companyScheduler ? 1 : 0;
            params.MS_FRANCHISE = franchise ? 1 : 0;
            params.MS_SETTING_INTEGRATION  = integration ? 1 : 0;
            params.MS_SUBSCRIPTION = subscription ? 1 : 0;
            params.MS_BROADCAST = isBoardCastChecked ? 1 : 0;
            params.MS_AUTOMATION = isAutomatedChecked ? 1 : 0;
            var promise = Promise.resolve(HTTPClient.post(endPoints.UNIT_CRM.UNIT_CRM_SETUP_SAVE, params));
            if (ParameterVerifier.isValidParam(promise)) {
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.REVIEW_WEBSITE.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                    //this.setState(_state);
                });
            }
        } catch (error) {
            console.error("Error in 'UnitCrm.js -> getUnitCrmSetup()':" + error);
        }

    }


    return (
        <>
            {mounted &&
                <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ fontSize: '24px' }}> {getLocalizedStrings().label.COMMON.UNIT_CRM} </div>
                    </div>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ fontSize: '18px', marginTop: '18px' }}> {getLocalizedStrings().label.COMMON.UNIT_CRM_LABEL} </div>
                    </div>
                    <div>
                        <box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>

                            {/* My Settings */}
                            <div style={{ ...styles.sf_12 ,width:'50%',marginTop:'20px'}}>
                                <label title={getLocalizedStrings().label.UNIT_CRM.MY_SETTINGS} style={{fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',fontSize:'15px'}}>{getLocalizedStrings().label.UNIT_CRM.MY_SETTINGS}</label>
                                <div className='Email_Aliases' style={{paddingLeft:'30px'}}>
                                    <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.EMAIL_ALIASES} control={<Checkbox color="default" checked={emailAliases} value={emailAliases} name="Email_Aliases" onChange={(e) => handleInputChange(e.target.checked, "Email_Aliases")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.EMAIL_ALIASES} style={{display:'inherit',cursor:'pointer'}} />
                                </div>
                            </div>

                            {/* Company */}
                            <div style={{ ...styles.sf_12 ,width:'50%'}}>
                                <label title={getLocalizedStrings().label.UNIT_CRM.COMPANY} style={{fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',fontSize:'15px'}}>{getLocalizedStrings().label.UNIT_CRM.COMPANY}</label>
                                <div className=' Surveys' style={{paddingLeft:'30px'}}>
                                    <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.SURVEYS} control={<Checkbox color="default" checked={survey} value={survey} name=" surveys" onChange={(e) => handleInputChange(e.target.checked, "surveys")} defaultChecked={false} />} label= {getLocalizedStrings().label.UNIT_CRM.SURVEYS} style={{display:'inherit',cursor:'pointer'}} />
                                </div>
                            </div>

                            {/* Users and Security */}
                            <div className='Users_and_Security' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title ={getLocalizedStrings().label.UNIT_CRM.USER_AND_SECURITY} control={<Checkbox color="default" checked={userSecurity} value={userSecurity} name=" Users_and_Security" onChange={(e) => handleInputChange(e.target.checked, "Users_Security")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.USER_AND_SECURITY} style={{display:'inherit'}} />
                            </div>

                            {/* Workflows & Rules section */}
                            <div className='Workflows_Rules_section' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.WORKFLOW_RULES_SECTION} control={<Checkbox color="default" checked={workflowsRules} value={workflowsRules} name="Workflows_Rules_section" onChange={(e) => handleInputChange(e.target.checked, "Workflows_Rules_section")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.WORKFLOW_RULES_SECTION} style={{display:'inherit'}} />
                                {workflowsRules && <div style={{ ...styles.sf_12 ,width:'50%',paddingLeft:'30px'}}>
                                    <FormControlLabel title={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_WORKFLOW} label={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_WORKFLOW} control={<Checkbox color="default" checked={isWorkflowChecked} value={isWorkflowChecked} name="isWorkflowChecked" onChange={(e) => handleInputChange(e.target.checked, "isWorkflowChecked")} defaultChecked={false} />} style={{ marginTop: '0px', display:'inherit',cursor:'pointer' }}  />
                                    <div className='WorkflowsubChild' style={{paddingLeft:'30px'}}>
                                        <FormControlLabel title={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_STAGES} control={<Checkbox color="default" checked={isStageChecked} value={isStageChecked} name="isFormsChecked" onChange={(e) => handleInputChange(e.target.checked, "isStageChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_STAGES} style={{display:'inherit'}} />
                                        <FormControlLabel title={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_TASKS} control={<Checkbox color="default" checked={isTaskChecked} value={isTaskChecked} name="isTaskChecked" onChange={(e) => handleInputChange(e.target.checked, "isTaskChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_UPDATE_TASKS} style={{display:'inherit'}}  />
                                    </div>
                                </div>}
                            </div>

                            {/* Customization */}
                            <div className='Customization' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.CUSTOMIZATION} control={<Checkbox color="default" checked={customaization} value={customaization} name="Customization" onChange={(e) => handleInputChange(e.target.checked, "customization")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.CUSTOMIZATION} style={{display:'inherit'}} />
                                     
                                {customaization && <div style={{ ...styles.sf_12, width:'50%' ,paddingLeft:'30px'}}> 
                                    <FormControlLabel title ={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_FORMS} control={<Checkbox color="default" checked={isFormsChecked} value={isFormsChecked} name="isFormsChecked" onChange={(e) => handleInputChange(e.target.checked, "isFormsChecked")} defaultChecked={true} />} label={getLocalizedStrings().label.COMMON.UNIT_CRM_CUSTOMIZE_FORMS} style={{display:'inherit'}} />
                                </div>}
                            </div>

                            {/* Lists */}
                            <div className='Lists' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.LISTS} control={<Checkbox color="default" checked={msLift} value={msLift} name="Lists" onChange={(e) => handleInputChange(e.target.checked, "msLift")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.LISTS} style={{display:'inherit'}} />
                            </div>

                            {/* Data */}
                            <div className='Data' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.DATA} control={<Checkbox color="default" checked={isDataChecked} value={isDataChecked} name="Data" onChange={(e) => handleInputChange(e.target.checked, "data")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.DATA} style={{display:'inherit'}} />
                            </div>
                            
                            {/* Company Scheduler */}
                            <div className='Company_scheduler' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.COMPANY_SCHEDULER} control={<Checkbox color="default" checked={companyScheduler} value={companyScheduler} name="Company_cheduler" onChange={(e) => handleInputChange(e.target.checked, "Company_scheduler")} defaultChecked={false} />} label= {getLocalizedStrings().label.UNIT_CRM.COMPANY_SCHEDULER} style={{display:'inherit'}} />
                            </div>

                            {/* Franchise */}
                            <div className='Franchise' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title ={getLocalizedStrings().label.UNIT_CRM.FRANCHISE} control={<Checkbox color="default" checked={franchise} value={franchise} name="Franchise" onChange={(e) => handleInputChange(e.target.checked, "franchise")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.FRANCHISE} style={{display:'inherit'}} />
                            </div>

                            {/* Integration */}
                            <div className='Franchise' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.INTEGRATION} control={<Checkbox color="default" checked={integration} value={integration} name="Integration" onChange={(e) => handleInputChange(e.target.checked, "integration")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.INTEGRATION} style={{display:'inherit'}} />
                            </div>

                            {/* Subscription */}
                            <div className='Subscription' style={{paddingLeft:'0px'}}>
                                <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.SUBSCRIPTION} control={<Checkbox color="default" checked={subscription} value={subscription} name="Subscription" onChange={(e) => handleInputChange(e.target.checked, "subscription")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.SUBSCRIPTION} style={{display:'inherit'}} />
                            </div>
                                
                            {/* Campaigns */}

                            <div className='campaigns' style={{paddingLeft:'0px'}}>
                                <label title={getLocalizedStrings().label.UNIT_CRM.CAMPAIGNS} style={{fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',fontSize:'15px'}}>{getLocalizedStrings().label.UNIT_CRM.CAMPAIGNS}</label>
                                    <div className='campaigns_childs' style={{paddingLeft:'30px'}}>
                                        <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.BOARDCAST_CAMPAIGNS} control={<Checkbox color="default" checked={isBoardCastChecked} value={isBoardCastChecked} name="isBoardCastChecked" onChange={(e) => handleInputChange(e.target.checked, "isBoardCastChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.BOARDCAST_CAMPAIGNS} style={{display:'inherit'}} />
                                        <FormControlLabel title={getLocalizedStrings().label.UNIT_CRM.AUTOMATED_CAMPAIGNS} control={<Checkbox color="default" checked={isAutomatedChecked} value={isAutomatedChecked} name="isAutomatedChecked" onChange={(e) => handleInputChange(e.target.checked, "isAutomatedChecked")} defaultChecked={false} />} label={getLocalizedStrings().label.UNIT_CRM.AUTOMATED_CAMPAIGNS} style={{display:'inherit'}}  />
                                    </div>
                            </div>
                        </box>
                    </div>
                    <div style={{ marginLeft: '169px' }}>
                        <Button variant="contained" style={{ ...styles.primaryButton, fontSize: 13, marginTop: "20px", }} size="small" onClick={() => saveUnitCrmSetup()} >{getLocalizedStrings().label.COMMON.SAVE} </Button>
                    </div>
                </div>
            }
        </>
    );
};
export default UnitCrm;