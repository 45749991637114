import React from 'react'
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from './circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam, getStringParam, getBooleanParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAurinkoConfigDetails, disconnectAurinko, saveAurinkoSetupForSync } from '../../../services/actions/UserListAction';
import * as sfDialogs from '../components/sfDialogs';
import LoadingButton from '@mui/lab/LoadingButton';
import { getWorkflowList, getWorkflowStageById } from '../../../services/actions/importAction';
import { updateAurinkoCasesMailBox } from "../../../services/actions/appActions";


const EmailToCaseSetup = () => {

    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [integrationSetUpObj, setIntegrationSetUpObj] = useState(null);
    const [connectClicked, setConnectClicked] = useState(false);
    const [attempts, setAttempts] = useState(10);
    const [disconnect, setDisconnect] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [mailboxEmail, setMailboxEmail] = useState(null);
    const [integrationOption, setIntegrationOption] = useState("Microsoft Office 365");
    const [token_flag, setTokenFlag] = useState(0);

    const [isAccountSyncStateValid, setIsAccountSyncStateValid] = useState(true);
    const [syncEmailWebHookId, setSyncEmailWebHookId] = useState(0);
    const [sendEmailWebHookId, setSendEmailWebHookId] = useState(0);

    const [workflowList, setWorkflowList] = useState(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [workflowStatusList, setWorkflowStatusList] = useState([]);
    const [selectedWorkflowStatus, setSelectedWorkflowStatus] = useState(null);
    const [workflowName, setWorkflowName] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const statusList = ['Active', 'Inactive'];
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [userListLoaded, setUserListLoaded] = useState(false);

    useEffect(() => {
        getUserList();
        getWorkflowData();
    }, []);

    useEffect(() => {
        if(userListLoaded == true){
            getIntegrationDetails();
        }
    },[userListLoaded]);

    const getUserList = () => {
        let url = endPoints.USERS.USERLIST_WITH_SECURITY_LEVEL_GET;
        const promise = Promise.resolve(HTTPClient.get(url, null));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let _userList = getArrayParam(response.userListWithSecurityLevel);
                    _userList = _userList.filter((user) => { return (user.securityLevel === 'Support' || user.securityLevel === 'Admin') });
                    setUserList(_userList);
                    setUserListLoaded(true);
                }
            });
        }
    }

    const generateUsersOptions = () => {
        let _userOptions = null;
        let __userList = getArrayParam(userList);
        if (!__userList.find(f => f.userName == "Unassigned")) {
            __userList.unshift({ userName: 'Unassigned', value: 'Unassigned' });
        }
        if (!__userList.find(f => f.userName == "Use Assignment Rule")) {
            __userList.push({ userName: 'Use Assignment Rule', value: 'Use Assignment Rule' });
        }
        if (isValidParam(__userList) && __userList.length > 0) {
            _userOptions = __userList.map((user, i) => {
                return (
                    <MenuItem key={`user-${i}`} value={user.userName}>{user.userName}</MenuItem>
                );
            });
        }
        return _userOptions;
    }

    const getWorkflowData = () => {
        let promise = Promise.resolve(getWorkflowList(constants.CASES_OBJECT));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    let arrayWorkflowList = getArrayParam(response);
                    setWorkflowList(arrayWorkflowList);
                }
            });
        }
    }

    const generateWorkflowOptions = () => {
        let _workflowOptions = [];
        let __workflowList = getArrayParam(workflowList);
        if (isValidParam(__workflowList) && __workflowList.length > 0) {
            _workflowOptions = __workflowList.map((objWorkflow, i) => {
                return (
                    <MenuItem key={`workflow-list-${i}`} value={objWorkflow.id}>{objWorkflow.name}</MenuItem>
                );
            });
            return _workflowOptions;
        }
    }

    const getIntegrationDetails = () => {
        try {
            let param = { onload: 'onload', integration_type: 'Aurinko-Cases' };
            let promise = getAurinkoConfigDetails(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setIntegrationDetails(response);
                    }
                });
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const setIntegrationDetails = (list) => {
        let obj = null;
        let data = {};
        if (list !== null && list.length > 0) {
            setDataLoaded(true);
            data.ConfigDetails = list;
            data.isNylasSetup = true;
            for (var i = 0; i < list.length; i++) {
                obj = list[i];
                if (isValidParam(obj)) {
                    if (obj.type === 'GoogleSuite') {
                        setIntegrationOption("Google Workspace");
                    }
                    else if (obj.type === 'MSE') {
                        setIntegrationOption("Microsoft Office 365");
                    }
                    setMailboxEmail(obj.connected_email);
                    let _caseSetupObj = obj.hasOwnProperty('case_setup_info') ? obj.case_setup_info : null;
                    if (_caseSetupObj) {
                        const _assignedTo = _caseSetupObj.assigned_to;
                        const _userList = userList;
                        setSelectedUser(_assignedTo);
                        setSelectedStatus(_caseSetupObj.status);
                        setSelectedWorkflow(_caseSetupObj.workflow_id);
                        setWorkflowName(_caseSetupObj.workflow_name);
                        setSelectedWorkflowStatus(_caseSetupObj.workflow_status);
                        getWorkStageByWorkflowId(_caseSetupObj.workflow_id, _caseSetupObj.workflow_status);
                        let _isValidAssignedTo = true;
                        if(_userList && (_assignedTo!== "Use Assignment Rule" && _assignedTo !== "Unassigned")) {
                            _isValidAssignedTo = isValidAssignedTo(_userList,_assignedTo);
                        }
                        if(!_isValidAssignedTo){
                             setSelectedUser(null);
                        }
                    }

                    setSendEmailWebHookId(obj.send_email_webhook_id);
                    setSyncEmailWebHookId(obj.sync_email_webhook_id);
                    setConnectClicked(false);

                    let accountSyncStatus = obj.account_sync_status;
                    if (accountSyncStatus !== null && (accountSyncStatus !== 'running' &&
                        accountSyncStatus !== 'partial' && accountSyncStatus !== 'downloading')) {
                        setIsAccountSyncStateValid(false);
                    }
                }
            }
        } else {
            setDataLoaded(true);
        }
        setIntegrationSetUpObj(obj);
    }

    const isValidAssignedTo = (__userList,_assignedTo) => {
        let _isValid = false;
        __userList = getArrayParam(__userList);
        if (__userList.find(f => f.userName == _assignedTo)) {
            _isValid = true;
        }
        return _isValid;
    }

    const connectToAurinkoAPI = () => {
        let provider = integrationOption;
        let serviceType = "Google";
        if (provider != null && provider != "") {
            if (provider == "Microsoft Office 365") {
                serviceType = "Office365";
            }
        }
        setConnectClicked(true);
        setAttempts(10);
        let refreshIntervalId = 0;
        setTokenFlag(0);
        let token = localStorage.getItem('token');
        let _integrationType = "Aurinko-Cases";
        let _state = token + "@@@@" + _integrationType;
        let url = constants.AURINKO_AUTH_API + 'clientId=' + app.aurinko_integration_apikey +
            '&responseType=token&serviceType=' + serviceType + '&scopes=Mail.Read Mail.Send Mail.Drafts Calendar.ReadWrite&returnUrl=' + constants.endPoint + endPoints.AURINKO_INTEGRATION.REDIRECT_URI
            + "&state=" + _state;
        var myWindow = window.open("", "", 'scrollbars=1,width=850,height=550,location=no');
        myWindow.location = url;
        setTimeout(function () {
            fnGetEmailConfigDetails(refreshIntervalId)
        }, 20000);
    }

    const fnGetEmailConfigDetails = (refreshIntervalId) => {
        try {
            let param = { integration_type: 'Aurinko-Cases' };
            let data = {};
            let _attempts = attempts;
            refreshIntervalId = window.setInterval(function () {
                if (token_flag === 0 && _attempts > 0) {
                    let promise = getAurinkoConfigDetails(param);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            setAttempts(_attempts - 1);
                            if (isValidParam(response)) {
                                let list = response;
                                let tokenFlag = 0;
                                if (list !== null && list.length > 0) {
                                    data.ConfigDetails = list;
                                    data.isNylasSetup = true;
                                    for (var i = 0; i < list.length; i++) {
                                        let obj = list[i];
                                        if (obj !== null) {
                                            if (obj.type === 'GoogleSuite') {
                                                setIntegrationOption("Google Workspace");
                                            } else if (obj.type === 'MSE') {
                                                setIntegrationOption("Microsoft Office 365");
                                            }
                                            setConnectClicked(false);
                                            setMailboxEmail(obj.connected_email);
                                        }

                                        tokenFlag = obj.is_token_exist;
                                        if (tokenFlag) {
                                            setAttempts(0);
                                            setTokenFlag(1);
                                            clearInterval(refreshIntervalId);
                                            setIntegrationSetUpObj(obj);
                                        }
                                    }
                                } else {
                                    data.ConfigDetails = [{}];
                                    data.isNylasSetup = false;
                                }
                            } else {
                                data.ConfigDetails = [{}];
                                data.isNylasSetup = false;
                            }
                        });
                    }
                }

            }, 5000);
        } catch (error) {
            console.log(error.message);
        }
    }

    const disconnectMailboxEmail = (event, provider) => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.DISCONNECT_CONNECTION, () => confirmDisconnectMailboxEmail(), null);
        } catch (error) {
            console.log(error.message);
        }

    }

    const confirmDisconnectMailboxEmail = () => {
        try {
            let param = {
                "access_token": integrationSetUpObj['access_token'],
                integration_type: integrationSetUpObj['integration_type'], account_id: integrationSetUpObj['account_id']
            };
            setDisconnect(true);
            let promise = disconnectAurinko(param);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (response.hasOwnProperty('is_revoked') && response.is_revoked) {
                        setIntegrationSetUpObj(null);
                        setSyncEmailWebHookId(0);
                        setSelectedUser(null);
                        setSelectedStatus(null);
                        setSelectedWorkflow(null);
                        setWorkflowName(null);
                        setSelectedWorkflowStatus(null);
                        setConnectClicked(false);
                        setDisconnect(false);
                        setAttempts(10);
                        setTokenFlag(0);
                        setIsAccountSyncStateValid(true);
                        dispatch(updateAurinkoCasesMailBox(null));
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'emailToCaseSetup.js -> confirmDisconnectMailboxEmail()':" + error);
        }
    }

    const handleMailboxChange = (event) => {
        const target = event.target;
        let value = getStringParam(target.value);
        setMailboxEmail(value);
    }

    const getAction = () => {
        if (integrationSetUpObj !== null && integrationSetUpObj['is_token_exist']) {
            return (
                <LoadingButton
                    key='disconnect'
                    size="small"
                    onClick={(e) => disconnectMailboxEmail(e, 'gmail')}
                    loading={disconnect}
                    loadingPosition="start"
                    variant="contained"
                    style={{ ...styles.primaryButton, width: '100%', height: '30px', marginRight: 0, paddingLeft: disconnect ? 35 : 10, border: 0 }}
                >
                    {disconnect ? getLocalizedStrings().label.MY_INTEGRATION.DISCONNECTING : getLocalizedStrings().label.COMMON.DISCONNECT}
                </LoadingButton>
            )
        }
        else {
            return (
                <LoadingButton
                    key='connect'
                    size="small"
                    onClick={() => connectToAurinkoAPI()}
                    loading={connectClicked}
                    loadingPosition="start"
                    variant="contained"
                    style={{ ...styles.primaryButton, height: '30px', marginRight: 0, paddingLeft: connectClicked ? 35 : 10, border: 0 }}
                >
                    {connectClicked ? getLocalizedStrings().label.MY_INTEGRATION.CONNECTING : getLocalizedStrings().label.COMMON.CONNECT}
                </LoadingButton>
            )
        }
    }

    const getSetupSection = () => {
        let arr = [];
        arr.push(
            <div>
                <div style={{ ...styles.row, padding: 0, marginTop: 5, gap: 5 }}>
                    <div style={{ ...styles.sf_12, padding: 0, marginTop: 5, gap: 5 }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="mailbox_email"
                                id="mailbox_email"
                                label={"Mailbox email address"}
                                value={mailboxEmail}
                                disabled
                                onChange={(e) => handleMailboxChange(e)}
                                margin='dense'
                                size='small'
                            />
                        </FormControl>
                    </div>
                    {integrationSetUpObj !== null && integrationSetUpObj['is_token_exist'] && <div style={{ marginTop: '18px' }}>
                        {getAction()}
                    </div>}
                </div>
            </div>
        );

        return arr;
    }

    const changeIntegration = (value) => {
        setIntegrationOption(value);
    }

    const changeWorkflow = (event) => {
        let value = event.target.value;
        setSelectedWorkflow(value);
        let selectedWF = null
        let arrayWorkflowList = getArrayParam(workflowList);
        if (isValidParam(arrayWorkflowList) && arrayWorkflowList.length > 0) {
            selectedWF = arrayWorkflowList.filter(f => { return f.id === value });
        }
        setSelectedWorkflowStatus(null);
        setSelectedWorkflow(value);
        if (isValidParam(selectedWF)) {
            setWorkflowName(selectedWF[0].name)
        }
        if (value > 0) {
            getWorkStageByWorkflowId(value, null);
        } else {
            setSelectedWorkflow(null);
        }
    }


    const getWorkStageByWorkflowId = (value, _savedWorkflowStage) => {
        let params = {
            workflow_id: value
        }
        let promise = Promise.resolve(getWorkflowStageById(params));
        if (isValidParam(promise)) {
            promise.then((response) => {
                if (isValidParam(response)) {
                    setWorkflowStatusList(response.stages);
                    if (!_savedWorkflowStage) {
                        _savedWorkflowStage = response.stages[0].name;
                    }
                    setSelectedWorkflowStatus(_savedWorkflowStage);
                }
            });
        }
    }

    const generateWorkFlowStatusOptions = () => {
        let _workFlowStatusOptions = [];
        let _workFlowStatus = workflowStatusList;
        if (isValidParam(_workFlowStatus)) {
            _workFlowStatusOptions = _workFlowStatus.map((_status, i) => {
                return (
                    <MenuItem key={`workflow-status-list-${i}`} value={_status.name} >{_status.name}</MenuItem>
                );
            });
        }
        return _workFlowStatusOptions;
    }

    const changeWorkflowStatus = (event) => {
        let value = event.target.value;
        setSelectedWorkflowStatus(value);
    }

    const changeStatus = (event) => {
        let value = event.target.value;
        setSelectedStatus(value);
    }

    const changeUser = (event) => {
        let value = event.target.value;
        setSelectedUser(value);
    }

    const generateStatusOptions = () => {
        let _statusOptions = [];
        let __statusList = getArrayParam(statusList);
        if (isValidParam(__statusList) && __statusList.length > 0) {
            _statusOptions = __statusList.map((status, i) => {
                return (
                    <MenuItem key={`status-${i}`} value={status}>{status}</MenuItem>
                );
            });
        }
        return _statusOptions;
    }

    const isValidate = () => {
        let _isValid = true;
        if (!selectedUser || !selectedUser.trim()) {
            _isValid = false;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select Assigned To.", null);
        } else if (!selectedStatus || !selectedStatus.trim()) {
            _isValid = false;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select a Rule Status.", null);
        } else if (!workflowName || !workflowName.trim()) {
            _isValid = false;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select a Workflow.", null);
        } else if (!selectedWorkflowStatus || !selectedWorkflowStatus.trim()) {
            _isValid = false;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select a Status.", null);
        } else {
            _isValid = true;
        }
        return _isValid;
    }

    const doSave = () => {
        try {
            if (!isValidate()) {
                return false;
            }
            setSaveInProgress(true);
            const case_setup_info = {};
            case_setup_info.assigned_to = selectedUser;
            case_setup_info.status = selectedStatus;
            case_setup_info.workflow_status = selectedWorkflowStatus;
            case_setup_info.workflow_name = workflowName;
            case_setup_info.workflow_id = selectedWorkflow;

            let params = {
                sync_email: selectedStatus == "Active" ? true : false,
                send_email: selectedStatus == "Active" ? true : false,
                sync_contact: false,
                sync_calendar: false,
                account_id: integrationSetUpObj['account_id'],
                accessToken: integrationSetUpObj['access_token'],
                syncEmailWebhookId: syncEmailWebHookId,
                sendEmailWebhookId: sendEmailWebHookId,
                integrationType: integrationSetUpObj['integration_type'],
                case_setup_info: case_setup_info,
            };

            let promise = saveAurinkoSetupForSync(params);
            promise.then((response) => {
                if (response.status === 0) {
                    setSyncEmailWebHookId(response.data.sync_email_webhook_id);
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.MY_INTEGRATION.SAVE_SUCCESS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    if(selectedStatus === "Active") {
                        dispatch(updateAurinkoCasesMailBox(mailboxEmail));
                    } else if(selectedStatus === "Inactive") {
                        dispatch(updateAurinkoCasesMailBox(null));
                    }    
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.MY_INTEGRATION.SAVE_FAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                setSaveInProgress(false);
            });
        }
        catch (error) {
            console.error("Error in ' -> doSave()':" + error);
        }
    }


    let disableGsuite = false;
    let disabledOffice = false;

    if (integrationOption === "Google Workspace" && isValidParam(integrationSetUpObj) &&
        getBooleanParam(integrationSetUpObj.is_token_exist) && integrationSetUpObj.type === 'GoogleSuite') {
        disabledOffice = true;
    }

    if (integrationOption === "Microsoft Office 365" && isValidParam(integrationSetUpObj) &&
        getBooleanParam(integrationSetUpObj.is_token_exist) && integrationSetUpObj.type === 'MSE') {
        disableGsuite = true;
    }

    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    return (
        <div style={{ paddingLeft: '3%', width: '97%' }}>
            {!dataLoaded &&
                <div style={{ width: '100%', height: height }} key={integrationOption}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
            {dataLoaded && <div>
                <div style={{ ...styles.row }}>
                    <FormControl component="fieldset">
                        <RadioGroup row name="serviceradio" onChange={(e) => changeIntegration(e.target.value)} defaultValue={integrationOption} style={{ marginTop: '10px', fontSize: '15px' }} >
                            <FormControlLabel value="Microsoft Office 365" control={<Radio color="default" disabled={disabledOffice} />} label="Microsoft Office 365" />
                            <FormControlLabel value="Google Workspace" control={<Radio color="default" disabled={disableGsuite} />} label="Google Workspace" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{ ...styles.row }}>
                    <div style={{ ...styles.sf_6 }}>
                        {integrationSetUpObj == null && <div style={{ ...styles.sf_12, gap: 5, marginTop: 12 }}>
                            {getAction()}
                        </div>}
                        {integrationSetUpObj !== null && integrationSetUpObj['is_token_exist'] && getSetupSection()}
                        {integrationSetUpObj !== null && integrationSetUpObj['is_token_exist'] && <div style={{ ...styles.sf_12, gap: 5 }}>
                            <div style={{ ...styles.row, padding: 0, gap: 5, marginTop: 15 }}>
                                <div style={{ ...styles.sf_6 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Assigned To</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedUser}
                                            label="Assigned To"
                                            className={"sf-fields-bg"}
                                            style={{ height: '42px' }}
                                            onChange={(e) => changeUser(e)}
                                        >
                                            {generateUsersOptions()}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ ...styles.sf_6 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Rule Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedStatus}
                                            label="Rule Status"
                                            className={"sf-fields-bg"}
                                            style={{ height: '42px' }}
                                            onChange={(e) => changeStatus(e)}
                                        >
                                            {generateStatusOptions()}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ ...styles.row, padding: 0, gap: 5, marginTop: 15 }}>
                                <div style={{ ...styles.sf_6 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Workflow</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedWorkflow}
                                            label="Workflow"
                                            className={"sf-fields-bg"}
                                            style={{ height: '42px' }}
                                            onChange={(e) => changeWorkflow(e)}
                                        >
                                            {generateWorkflowOptions()}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ ...styles.sf_6 }}>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel shrink={selectedWorkflowStatus == null ? false : true} id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedWorkflowStatus}
                                            label="Status"
                                            className={"sf-fields-bg"}
                                            style={{ height: '42px' }}
                                            onChange={(e) => changeWorkflowStatus(e)}
                                        >
                                            {generateWorkFlowStatusOptions()}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{ ...styles.sf_12, gap: 5 }}>

                                <div style={{ textAlign: 'right', display: 'table', width: '100%', marginTop: 10 }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <LoadingButton
                                            key='save'
                                            size="small"
                                            onClick={() => doSave()}
                                            loading={saveInProgress}
                                            loadingPosition="start"
                                            variant="contained"
                                            style={{ ...styles.primaryButton, marginRight: 0, paddingLeft: saveInProgress ? 35 : 10, border: 0 }}>
                                            {saveInProgress ? 'Saving' : getLocalizedStrings().label.COMMON.SAVE}
                                        </LoadingButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                        } </div>
                </div>
            </div>}
        </div>
    );
}

export default EmailToCaseSetup;