import { actions } from '..//constants/actions'

const documentLibraryInitialStates = {
    mounted: false,
    maxChildId: 0,
    selectedTree: null,
    callForm: null,
    selectedObject: null,
    mainData: [],
    expandedNode: [],
    selectedNode: '',
    selectedLibraryType: 'documents',
    inBodyAction: { actionOn: null, actionType: null, actionsParam: null },
    isAssetsUpdated: false,
    refreshAssetsTree: false,
}

const documentLibraryReducer = (state = documentLibraryInitialStates, action) => {

    switch (action.type) {
        case actions.ASSETS_DOCUMENT:
            return {
                ...state,
                mounted: true,
                documentsData: action.payload
            }
        case actions.ASSETS_INBODY_ACTION_UPDATE:
            return {
                ...state,
                inBodyAction: action.payload,
            }
        case actions.ASSETS_MAIN_DATA_UPDATE:
            return {
                ...state,
                mainData: action.payload.data,
                maxChildId: action.payload.maxChildId
            }
        case actions.ASSETS_DOCUMENT_IS_UPDATE:
            return {
                ...state,
                isAssetsUpdated: action.payload
            }
        case actions.ASSETS_MAX_CHILD_ID__UPDATE:
            return {
                ...state,
                maxChildId: action.payload
            }
        case actions.ASSETS_CALL_FORM_UPDATE:
            return {
                ...state,
                callForm: action.payload
            }
        case actions.ASSETS_OBJECT_UPDATE:
            return {
                ...state,
                selectedObject: action.payload
            }
        case actions.ASSETS_EXPANDED_NODE_UPDATE:
            return {
                ...state,
                expandedNode: action.payload
            }
        case actions.ASSETS_SELECTED_NODE_UPDATE:
            return {
                ...state,
                selectedNode: action.payload
            }
        case actions.ASSETS_SELECTED_TYPE_UPDATE:
            return {
                ...state,
                selectedLibraryType: action.payload
            }
        case actions.REFRESH_ASSETS_TREE:
            return {
                ...state,
                refreshAssetsTree: action.payload.refresh
            }
        default:
            return state;
    }
}

export default documentLibraryReducer;