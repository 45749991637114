import React from 'react';
import { getFieldListValuesPromise } from '../../../services/actions/sfFormActions';
import { isValidParam, getBooleanParam, getStringParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import store from '../../../../src/services/store';
import {Menu, Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import { constants } from '../../../services/constants/constants';
import replace from 'lodash/replace';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';

// const { editors: { EditorBase } } = require('react-data-grid');

export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: [],
            value: '',
            options: [],
            selectedListIds: '',
            selectedListValues: '',
            isMounted: false
        };
        this._dropdown = null;
        this.getInputNode = this.getInputNode.bind(this);
        this.getValue = this.getValue.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        this.getListValues();
    }

    getInputNode() {
        return this._dropdown;
    }

    getValue() {
        var updated = {};
        updated[this.props.column.key] = this.state.value;
        return updated;
    }

    onValueChanged(event) {
        let isRequired = getBooleanParam(this.props.column.is_required_field);
        let value = getStringParam(event.target.value).trim();
        if (!isRequired || (isRequired && value !== '')) {
            this.setState({ value: value });
        }
    }

    getListValues = () => {
        let fieldName = getStringParam(this.props.column.key);
        let listType = getStringParam(this.props.column.list_type);
        if ((fieldName === 'sales_rep' || fieldName === 'user_list1' ||fieldName === 'user_list2'|| fieldName === 'user_list3' ||fieldName === 'user_list4'  ||
            (fieldName === 'user_id' && this.props.object === constants.APPOINTMENTS) ||
            (fieldName === 't_owner' && this.props.object === constants.TASKS)||
            ( this.props.object === "salesleads")||
            (fieldName === "SalesRep" && this.props.object === "quotations")||
            (fieldName === "custom_field1" && this.props.object === "cases") )
            && (listType === 'user' )) {
            let state = store.getState();
            let userList = getArrayParam(state.userlist.data);
            let userOptions = userList.map(user => {
                return { id: user.value, value: user.text };
            });
            this.updateOptions(userOptions);
        } else {
            let params = {};
            if(fieldName === 'sc_source'){
                params.object = this.props.object;
                params.name = "sc_source";
                params.value = this.props.rowData?.source;
                params.list_type = "external";
                params.query_name = "SecSourceByPrimarySource";
                params.query_type = "customQuery";
            }else{
                params.object = this.props.object == "salesleads" && fieldName === 'country'? "accounts" :this.props.object ;
                params.id = this.props.rowData.id;
                params.query_name = this.props.column.query_name;
                params.query_type = this.props.column.query_type;
                params.name = fieldName;
                params.list_type = listType;
            }
            let promise = getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    this.updateOptions(response);
                }
            });
        }

    }

    updateOptions = (options) => {
        let arrOptions = [];
        let selectedValueArr = null;
        let value = '';
        try {
            if (isValidParam(options)) {
                sortArrayObjectByProperty(options, 'value');
                let fieldName = getStringParam(this.props.column.key);
                let isRequired = getBooleanParam(this.props.column.is_required_field);
                let listType = getStringParam(this.props.column.list_type);

                options.unshift({ id: '', value: '' });

                if (listType === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                    value = getStringParam(this.props.value);
                    this.state.selectedListValues = value !== '' ? value.split(',') : value;
                } else {
                    selectedValueArr = options.filter((option) => {
                        return this.props.value === option.value;
                    });
                    value = selectedValueArr.length > 0 ? selectedValueArr[0].id : '';
                }
            }
            this.setState({ isMounted: true, value: value, options: options });
        } catch (error) {
            console.error("Error in 'gridDropdown.js -> updateOptions()':" + error);
        }
    }

    getMenuItems = (options) => {
        let arrOptions = [];
        let listType = getStringParam(this.props.column.list_type);
        options.map((option) => {
            if (listType === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                arrOptions.push(
                    <MenuItem
                        key={option.id}
                        id={option.id}
                        value={option.value}
                        title={option.value}
                        checked={this.state.selectedListValues && this.state.selectedListValues.indexOf(option.value) > -1 ? true : false}
                        style={styles.popoverMenuItem}
                        insetChildren={true}
                        className='menu-item-ellipsis'>{option.value}</MenuItem>);
            } else {
                let optionValue = getStringParam(option.value).length > 25 ? getStringParam(option.value).substring(0, 25) + '...' : getStringParam(option.value);
                arrOptions.push(<option key={option.id} id={option.id} title={option.value} value={option.value}>{optionValue}</option>);
            }
        });
        return arrOptions;
    }

    getComponent = () => {
        let dropdown = null;
        try {
            let listType = getStringParam(this.props.column.list_type);
            if (listType === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                let listValues = getArrayParam(this.props.column.list_values);
                if (this.state.isMounted) {
                    dropdown = <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-griddropdown-simple-select-outlined-label" className="sf-griddropdown"></InputLabel>
                        <Select
                            ref={(ref) => { this._dropdown = ref; }}
                            key={this.props.column.name}
                            multiple={true}
                            // value={this.state.selectedListValues}
                            title={this.props.p.row[this.props.column.key]}
                            // onChange={this.setMultiSelectValue}
                            value={this.props.p.row[this.props.column.key]}
                            onChange={(e) => this.props.p.onRowChange({ ...this.props.p.row, [this.props.column.key]: e.target.value }, true)}
                            labelstyle={{ left: '7px' }}
                            style={{ maxWidth: `${this.props.column.width}px`,  height: '100%', border: '1px solid #ccc', borderRadius: '4px', boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)' }}
                            menuStyle={{ marginTop: '-10px' }}
                            className={"sf-fields-bg"}
                            autowidth={"true"}
                        >
                            {this.getMenuItems(this.state.options)}
                        </Select>
                    </FormControl>;
                }
            } else {
                let selectDropDown = 
                    <select 
                        ref={(ref) => { this._dropdown = ref; }} 
                        defaultValue={this.props.p.row[this.props.column.key]}
                        value={this.props.p.row[this.props.column.key]}
                        onChange={(e) => this.props.p.onRowChange({ ...this.props.p.row, [this.props.column.key]: e.target.value }, true)}
                        style={{ display: this.state.isMounted ? 'block' : 'none', width: '100%',  height: '100%', }}
                    >
                        {this.getMenuItems(this.state.options)}
                    </select>;
                dropdown = selectDropDown
            }

        } catch (error) {
            console.error("Error in 'gridDropdown.js -> getComponent()':" + error);
        }
        return dropdown;
    }

    setMultiSelectValue = (event, index, values) => {
        let isRequired = getBooleanParam(this.props.column.is_required_field);
        let value = getStringParam(event.target.value);
        //if (!isRequired || (isRequired && value !== '')) {
        if (!isRequired || (isRequired && Array.isArray(values) && values.length !== 0)) { 
            if (this.state.selectedListIds.indexOf(event.currentTarget.id) > -1) {
                this.state.selectedListIds = replace(this.state.selectedListIds, event.currentTarget.id + ',', '');
            } else {
                this.state.selectedListIds = this.state.selectedListIds + event.currentTarget.id + ',';
            }
            this.state.selectedListValues = values;
            this.state.value = getArrayParam(values).join();
            this.setState(this.state);
        }
    }

    render() {
        if (this.props.rowData.id < 0) {
            return null;
        }
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div>
                {this.getComponent()}
                {!this.state.isMounted &&
                    <div style={{ width: '100%', height: contentHeight }}>
                   <span>{this.props.p.row[this.props.column.key]}</span>
                </div>}
            </div>
        );
    }
}