import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { useState } from "react";
import { useParams } from "react-router-dom";

const ChangePassword = ({isPasswordExpired,encodedParams}) => {
    let param = useParams();
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isPassChanged, setIsPassChanged] = useState(false);
    const [password, setPassword] = useState({ newPassword: { value: '', showPassword: false, error: '' }, confirmPassword: { value: '', showPassword: false, error: '' } });
    const [passwordRequirements,setPasswordRequirements] = useState({
		minLength: false,
		uppercase: false,
		lowercase: false,
		digit: false,
		specialCharacter: false,
	  },)

    // extract key from param
    const paramKey = param.eKey?.split('&')
    const submitForgotPassword = () => {
         // Check if all password requirements are met
        const allRequirementsMet = Object.values(passwordRequirements).every(value => value === true);
        const msg = ""

        if (!password.newPassword.value.trim() && !password.confirmPassword.value.trim()) {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Please enter password' }, confirmPassword: { ...password['confirmPassword'], error: 'Please enter confirm password' } });
        }else if (!password.newPassword.value.trim()) {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Please enter password' } });
        } else if (!password.confirmPassword.value.trim() && !allRequirementsMet) {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Please enter password according to guide.'}, confirmPassword: { ...password['confirmPassword'], error: 'Please enter confirm password' } });
        } else if (!password.confirmPassword.value.trim()) {
            setPassword({ ...password, confirmPassword: { ...password['confirmPassword'], error: 'Please enter confirm password' } });
        } else if (hasSequentialChars(password.newPassword.value)){
            msg = "Password contains sequential characters (e.g., '1234', 'abcd')."
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: msg} });
        } else if (hasRepeatedChars(password.newPassword.value)){
            msg = "Password contains repeated characters (e.g., 'aaaa', '1111')."
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: msg} });
        } else {
            setPassword({ ...password, newPassword: { ...password['newPassword'], error: '' }, confirmPassword: { ...password['confirmPassword'], error: '' } });
            if (password.newPassword.value === password.confirmPassword.value && allRequirementsMet) {
                let params = { "encodedParams": isPasswordExpired ? encodedParams :  param.eKey.includes("&q=new") ? paramKey[0] : param.eKey, "password": password.newPassword.value.trim(), "confirmPassword": password.confirmPassword.value.trim() }
                const promise = Promise.resolve(HTTPClient.getFullResponseWithoutToken(endPoints.LOGIN.RESET_PASSWORD, params));
                promise.then((response) => {
                    if (ParameterVerifier.isValidParam(response)) {
                        console.log("response",response)
                        if (response.status === 0) {
                            setIsPassChanged(true);
                            setIsResetPassword(true);
                        } else {
                            setPassword({ newPassword: { ...password['newPassword'], error: 'Enter Password' }, confirmPassword: { ...password['confirmPassword'], error: 'Enter Password' } });
                        }
                    }
                })
            } else if(password.newPassword.value !== password.confirmPassword.value && !allRequirementsMet ){
                setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Please enter password according to guide.' } , confirmPassword: { ...password['confirmPassword'], error: 'Password do not match.' } });
            } else if(password.newPassword.value !== password.confirmPassword.value){
                setPassword({ ...password, confirmPassword: { ...password['confirmPassword'], error: 'Password do not match.' } });
            } else {
                setPassword({ ...password, newPassword: { ...password['newPassword'], error: 'Please enter password according to guide.' } });
            }
        }
    }
    const userLogout = (e) => {
        window.location.replace('/');
    }

     // Function to check for sequential or repeated characters
	const hasSequentialChars = (str) => {
		const seqPattern = /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/ig;
		return seqPattern.test(str);
	  };

	const hasRepeatedChars = (str) => {
		return /(.)\1{2,}/.test(str); // Check for repeated characters (e.g., "aaa", "111")
	  };
	
    const handlePassword = (value,name) =>{
		console.log("value",value)
		let updatedPassword = { ...password };

        updatedPassword[name].value = value;
		// Password validation rules
		const passwordRules = {
			minLength: 12,
			uppercase: /[A-Z]/,
			lowercase: /[a-z]/,
			digit: /[0-9]/,
			specialCharacter: /[!@#$%^&*(),.?":{}|<>]/,
		};
		
		if (name === 'newPassword') {
            updatedPassword["newPassword"].error = "";
            
            // Check if newPassword matches confirm password
            if(value === password.confirmPassword.value){
                updatedPassword["confirmPassword"].error = "";
            }

			// If newPassword field is empty, reset all password requirements
			if (value.trim() === "") {
				setPasswordRequirements({
				  minLength: false,
				  uppercase: false,
				  lowercase: false,
				  digit: false,
				  specialCharacter: false,
				});
			}

			// Check for sequential or repeated characters
			if (hasSequentialChars(value)) {
				updatedPassword["newPassword"].error= "Password contains sequential characters (e.g., '1234', 'abcd').";
				setPasswordRequirements({
				  ...passwordRequirements
				});
			}
		  
			if (hasRepeatedChars(value)) {
				updatedPassword["newPassword"].error = "Password contains repeated characters (e.g., 'aaaa', '1111').";
				setPasswordRequirements({
				  ...passwordRequirements
				});
			}
		  
			let updatedRequirements = { ...passwordRequirements };

			// Update password requirements
			updatedRequirements.minLength = value.length >= passwordRules.minLength;
			updatedRequirements.uppercase = passwordRules.uppercase.test(value);
			updatedRequirements.lowercase = passwordRules.lowercase.test(value);
			updatedRequirements.digit = passwordRules.digit.test(value);
			updatedRequirements.specialCharacter = passwordRules.specialCharacter.test(value);
		
			// Update the state
			setPasswordRequirements(updatedRequirements);
		
			// Also update the password 
			setPassword(updatedPassword);
		} else if(name === "confirmPassword") {
            if(value === ""){
                updatedPassword["confirmPassword"].error = "";
            } else{
                // Check if repeatPassword matches newPassword
                if (value !== password.newPassword.value) {
                    updatedPassword["confirmPassword"].error = "Password do not match.";
                } else {
                    updatedPassword["confirmPassword"].error = "";
                }
            }
	
			// Also update the password 
			setPassword(updatedPassword);
        }

	}

    const handleClickShowPassword = (filedName) => {
        setPassword({ ...password, [filedName]: { ...password[filedName], showPassword: !password[filedName].showPassword } });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const isNewPassword = param?.eKey?.includes('&q=new');
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            {isPassChanged && (
                <div className="abc" style={{ width: "480px" }}>
                <Box boxShadow={3} className="sf-login-box">
                    <Card style={{ height: "320px" }}>
                    <CardContent style={{ marginTop: "40px" }}>
                        <div
                            style={{
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                        <i className="fa fa-check" style={{ fontSize: "4em" }} />
                        </div>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            align="center"
                            style={{ fontWeight: "700", fontSize: 25 }}
                        >
                        Your Password has been changed
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            align="center"
                            style={{ fontWeight: "400", fontSize: 15 }}
                        >
                        Please sign in with your new password
                        </Typography>
                    </CardContent>
                    <div style={{ textAlign: "center" }}>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#3fc7f6",
                                fontSize: 13,
                                marginBottom: "21px",
                            }}
                            size="large"
                            onClick={() => userLogout()}
                        >
                        Sign In
                        </Button>
                    </div>
                    </Card>
                </Box>
                </div>
            )}

            {!isResetPassword && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        padding: "20px",
                        boxSizing: "border-box", 
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                    }}
                >
                    <div className="abc" style={{ width: "480px" }}>
                        <Box boxShadow={3} className="sf-login-box">
                            <Card style={{ height: "320px" }}>
                                <CardContent>
                                    <Typography
                                        className="sf-login-Typography"
                                        color="textSecondary"
                                        gutterBottom
                                        bottommargin="10px"
                                    >
                                        {isNewPassword ? "Set Up Your Password" : "Reset Your Password"}
                                    </Typography>
                                    <div className="sf-email-adr">
                                        <FormControl sx={{ width: "100%" }} variant="standard">
                                        <InputLabel
                                            error={password.newPassword.error !== ''} htmlFor="new-password"> New Password (required)</InputLabel>
                                            <Input
                                                id="new-password"
                                                name="new-password"
                                                label="New Password (required)"
                                                value={password.newPassword.value}
                                                onChange={(e) => handlePassword(e.target.value, "newPassword")}
                                                type={
                                                    password.newPassword.showPassword ? "text" : "password"
                                                }
                                                error={password.newPassword.error !== ""}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => handleClickShowPassword("newPassword")}
                                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                                            edge="end"
                                                        >
                                                            {password.newPassword.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {password.newPassword.error && (
                                                <FormHelperText
                                                    error
                                                    sx={{ m: 0 }}
                                                    id="outlined-weight-helper-text"
                                                >
                                                    {password.newPassword.error}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className="sf-pwd-adr">
                                        <FormControl sx={{ mt: 1, width: "100%" }} variant="standard">
                                            <InputLabel
                                                error={password.confirmPassword.error !== ""}
                                                htmlFor="confirm-password"
                                            >
                                            Confirm New Password (required)
                                            </InputLabel>
                                            <Input
                                                id="confirm-password"
                                                name="confirm-password"
                                                label="Confirm New Password (required)"
                                                value={password.confirmPassword.value}
                                                onChange={(e) =>
                                                    handlePassword(e.target.value, "confirmPassword")
                                                }
                                                type={
                                                    password.confirmPassword.showPassword ? "text" : "password"
                                                }
                                                error={password.confirmPassword.error !== ""}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                handleClickShowPassword("confirmPassword")
                                                            }
                                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                                            edge="end"
                                                        >
                                                            {password.confirmPassword.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {password.confirmPassword.error && (
                                                <FormHelperText
                                                    sx={{ m: 0 }}
                                                    error
                                                    id="confirm-password"
                                                >
                                                    {password.confirmPassword.error}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>
                                </CardContent>
                                <CardActions style={{ float: "right" }}>
                                    <div style={{ textAlign: "center", marginRight: "7px",marginTop:'10px' }}>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#3fc7f6",
                                                fontSize: 13,
                                                marginBottom: "21px",
                                                marginRight: "10px",
                                            }}
                                            size="large"
                                            onClick={() => submitForgotPassword()}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#ffffff",
                                                fontSize: 13,
                                                marginBottom: "21px",
                                                color: "black",
                                            }}
                                            size="large"
                                            onClick={() => userLogout()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        </Box>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginLeft: "20px",
                        }}
                    >
                        <span>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    marginRight: "8px",
                                    color: passwordRequirements.minLength ? "green" : "red",
                                }}
                            ></i>
                            At least 12 characters long
                        </span>
                        <span>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    marginRight: "8px",
                                    color: passwordRequirements.uppercase ? "green" : "red",
                                }}
                            ></i>
                            One uppercase character
                        </span>
                        <span>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    marginRight: "8px",
                                    color: passwordRequirements.lowercase ? "green" : "red",
                                }}
                            ></i>
                            One lowercase character
                        </span>
                        <span>
                            <i
                                className="fa fa-check-circle"
                                style={{
                                    marginRight: "8px",
                                    color:
                                        passwordRequirements.digit &&
                                        passwordRequirements.specialCharacter
                                            ? "green"
                                            : "red",
                                }}
                            ></i>
                            One number and one symbol
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}
export default ChangePassword;