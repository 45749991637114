 const label = {
    MODULE: {
        Sales: 'Ventas',
        Marketing: 'Márketing',
        Service: 'Servicio',
        Operations: 'Operaciones',
        HUB: 'Hub'
    },
    MODULE_ITEMS: {
        Reports: 'Informes',
        Dashboard: 'Tablero',
        'Marketing Templates': 'Plantillas de correo electrónico de marketing',
        'Broadcast Campaigns': 'Campañas de difusión',
        'Automation Designer': 'Diseñador de Automatización',
        'Email Marketing': 'Correo de propaganda',
        'Lead Capture': 'Captura de plomo',
        'Landing Pages': 'Páginas de destino',
        'Web Forms': 'Formularios web',
        'Automated Campaigns': 'Campañas automatizadas',
        'Social Marketing': 'Mercadeo social',
        'Website Tracking': 'Seguimiento de sitios web',
        'Website Analytics Setup': 'Configuración de analítica web',
        'Ab Campaigns': 'Campañas Ab',
        'Opportunities': 'Oportunidades',
        'Marketing Reports': 'Informes',
        'Touch Campaigns': 'Campañas táctiles',
        'A/B Campaigns': 'Campañas A / B',
        'Email Blast': 'Email Blast',
        Activities: 'Ocupaciones',
        Conversion: 'Conversión',
        Campaigns: 'Campañas',
        Custom: 'Personalizado',
        Sales: 'Ventas',
        Marketing: 'Márketing',
        Offers: 'Ofertas',
        Coupons: 'Cupones',
        'Coupons Claim Report': 'Informe', // Report
        ASSETS_DISTRIBUTION: 'Distribución de activos',
        USEFUL_LINKS: 'Enlaces útiles',
        Royalties: 'Regalías - Legado',
        COMPLIANCE_REPORT: "Cumplimiento",
    },
    COMMON: {
        PUSH_NO_CONFIRM: 'Empuje sin confirmación',
        UNIT_CRM_UPDATE_STAGES:'Etapas de actualización',
        UNIT_CRM_UPDATE_TASKS:'Tareas de actualización',
        FRENCHISEE_COMPANY_NAME:'Seleccione el nombre de una empresa franquiciada',
        SELECT_AN_ACCOUNT:'Seleccione una unidad',
        ADD_TO_PLAN:'Agregar al plan',
        LOGIN_TO_BASE:'Iniciar sesión en la base',
        PLUS_BRANCH: '+Sucursal',
        ADD: 'Añadir',
        ADD_NEW_APPOINTMENT: 'Añadir un nuevo',
        SHOW: 'Espectáculo',
        HIDE: 'Esconder',
        REFRESH: 'Refrescar',
        EDIT: 'Editar',
        SAVE: 'Salvar',
        CANCEL: 'Cancelar',
        ENTER_TEXT: 'Ingrese texto',
        APPLY: 'Aplicar',
        TEST: 'Prueba',
        AND: 'y',
        EMPTY: 'Vacío',
        EXPAND: 'Expandir',
        DELETE: 'Borrar',
        REQUIRED: ' (necesario)',
        AUTO_SEARCH_TEXT: 'Mismo texto, diferentes valores',
        NOTE: 'Nota',
        SEARCH: 'Buscar',
        OK: 'De acuerdo',
        SEND_MAIL: 'Enviar correo electrónico',
        ASSIGN: 'Asignar',
        SELECT_A: 'Seleccione un ',
        SELECT_MORE: 'Seleccione uno o más ',
        CLOSE: 'Cerca',
        CREATE: 'Crear',
        WITH: 'con',
        CONVERT: 'Convertir',
        SEARCH_PLACEHOLDER: 'Escribe y presiona enter para buscar',
        COPY: 'Dupdo',
        MORE: 'Más',
        CLICK_TO_SEARCH: 'Haga clic aquí para buscar',
        PREVIEW: 'Avance',
        BACK: 'Espalda',
        CONFIRM_REQUEST: 'Confirmar petición',
        SELECT: 'Seleccionar',
        ADVANCED: 'Avanzado',
        ADVANCED_SEARCH: 'Búsqueda Avanzada',
        RESULTS_FOR: 'resultados para',
        SETTINGS: 'Configuraciones',
        SIGN_OUT: 'desconectar',
        BACK_TO_YOUR_ACCOUNT: 'Volver a su cuenta',
        BACK_TO_LIST: 'Regresa a la lista.',
        BACK_TO_COLUMN_VIEW: 'Volver a la vista de columna.',
        MERGE: 'Unir',
        DISPLAY_DIFFERENT_FIELDS: '* Solo se muestran campos que son diferentes.ha',
        WORKFLOW: 'Flujo de trabajo',
        STATUS: 'Estado',
        RECORD: 'Grabar',
        MERGED_RECORD: 'Registro fusionado',
        EXPORT: 'Exportar',
        CTR_DETAILS_FOR: 'Detalles de CTR para',
        URL: 'URL',
        COUNT: 'Contar',
        CLICK_FOR_KANBAN_VIEW: 'Haga clic aquí para ver Kanban',
        CLICK_FOR_LIST_VIEW: 'Haga clic aquí para ver la lista',
        INSERT: 'Insertar',
        CATEGORIES: 'Categorías',
        REMOVE: 'retirar',
        REPLY: 'Respuesta',
        REPLY_ALL: 'Responder a todos',
        FORWARD: 'Adelante',
        WORKFLOW_NAME: 'Nombre del flujo de trabajo',
        CONVERTING: 'Mudado',
        TO: 'to',
        CURRENT_WORKFLOW: 'Flujo de trabajo actual',
        CHANGE_WORKFLOW: 'Cambiar flujo de trabajo',
        UPDATE: 'Actualizar',
        STATUS_TO_OPTIONAL: 'estado a (opcional)',
        MANAGE: 'Gestionar',
        ADD_PERSONAL_NOTE_OPTIONAL: 'Agregue una nota personal a su solicitud (opcional)',
        CONVERTED: 'Convertido',
        SELECT_USER: 'Seleccione un usuario',
        COMING_SOON: 'Próximamente',
        IMPORT: 'Importar',
        NO: 'No',
        FOUND: 'encontró',
        NOTHING_FOUND: 'No se encontró nada para',
        DOT: '.',
        UNDER_PROCESSING: 'En proceso, próximamente',
        FIND: 'Encontrar',
        LOADING: 'Cargando',
        NAME: 'Nombre',
        CLEAR_ALL: 'Limpiar todo',
        CLEAR: 'Claro',
        SHARE: 'Compartir',
        ADD_TO_GROUP: 'Añadir al grupo',
        MAP: 'Mapa',
        AUDIT: 'Cambiar registro',
        OPEN_AUDIT_FORM: 'Auditoría',
        TRAIL: 'Sendero',
        ADD_NOTES: 'Agregar notas',
        TO_EXCEL: 'Para sobresalir',
        PUSH: 'empujar',
        RESCHEDULE: 'Reprogramar',
        PRINT: 'Impresión',
        COMPLETE: 'Completar',
        COMPLETE_New: 'Completo y nuevo',
        SEND_EMAIL: 'Enviar correo electrónico',
        SEND_SMS: 'Enviar SMS',
        CONVERT_TO_ACCOUNTS: 'Convertir a cuentas',
        CONNECT: 'Conectar',
        DISCONNECT: 'Desconectar',
        DOWNLOAD: 'Descargar',
        BATCH_EDIT: 'Edición por lotes',
        BATCH_CONVERT: 'Conversión por lotes',
        INACTIVE: 'Inactivo',
        ACTIVE: 'Activo',
        RESTORE: 'Restaurar',
        PROFILE: 'Perfil',
        TYPE_TO_SEARCH: 'Escriba aquí para buscar',
        CONVERT_AND_ADD_OPP: 'Convertir y agregar oportunidades',
        SEND_VERIFICATION_EMAIL: 'Envia un correo electronico de verificación',
        THESE: 'estas',
        ALL_APPOINTMENTS: 'Todas las citas',
        ALL_TASKS: 'tareas',
        CONTINUE: 'Continuar',
        ALL_OPPORTUNITIES: 'Todas oportunidades',
        EDIT_NOTES: 'Editar notas',
        RUN: 'correr',
        INTEGRATION_TITLE: 'Integración',
        UNSUBSCRIBE: 'Darse de baja',
        MY_INTEGRATIONS: 'Integraciones',
        NOTIFY: 'Notificar ',
        HELP: 'Ayuda',
        PUBLISH: 'Publicar',
        UNPUBLISH: 'No publicar',
        CALL_SCRIPT: 'Scripts de llamada',
        DOCUSIGN: 'Enviar DocuSign',
        DOCUSIGN_INTEGRATION: 'Enviar DocuSign',
        ALL: 'Todas',
        USER: 'Usuario',
        SYSTEM: 'Sistema',
        MY: 'Mi',
        TODAYS: "Hoy",
        YES: 'Sí',
        DONE: 'Hecho',
        OPEN: 'Abierto',
        FUTURE: 'Futuro',
        OVERDUE: 'Atrasado',
        TOMORROWS: "Mañana",
        TOMORROW: "mañana",
        TODAY: 'Hoy',
        CONNECTED_AS: 'Conectado como',
        SALESREP: 'Representante de ventas',
        EVERYONE: 'Todos',
        NOTE_TYPE: 'Tipo de nota',
        TEXT: 'Texto',
        FOR: 'para',
        MODULE_SETTING: 'Ajustes',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'Plantilla de biblioteca',
            USER_TEMPLATE: 'Plantilla de usuario',
        },
        OF: 'De',
        'System Fields': 'Campos del sistema',
        'Custom Fields': 'Campos Personalizados',
        'Additional Information': 'Información Adicional',
        'Contact Details': 'Detalles de contacto',
        'Phone and Email Information': 'Teléfono y correo electrónico',
        'Sales rep': 'Representante de ventas',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'Yo mapearía mis fuentes desde el archivo CSV',
        ADD_NEW_SOURCE: 'Añadir una nueva fuente',
        ADD_TO_THIS_LIST: 'Añadir a esta lista',
        TYPE_SOMETHING: 'Escribe algo',
        'Opportunity Details': 'Detalles de la oportunidad',
        'Company Details': 'Detalles de la empresa',
        'Contact Information': 'Información de contacto',
        'Case Information': 'Información del caso',
        'Solution Details': 'Detalles de la solución',
        'Project Details': 'Detalles del proyecto',
        'Issue Details': 'Detalles del problema',
        'Assigned to': 'Asignados a',
        'Sales person': 'Persona de ventas',
        EMAIL_TO: 'Email para',
        RECENT_SEARCHES: 'Búsquedas recientes',
        RECENT_RECORDS: 'Registros recientes',
        ASSETS: 'Bienes',
        ENABLE_MAILING: 'Habilitar el envío de correo electrónico',
        DISABLE_MAILING: 'Deshabilitar el envío de correo electrónico',
        CLICK_TO_REMOVE: 'Haga clic aquí para eliminar',
        PIN: 'Alfiler',
        UNPIN: 'Unpin',
        RENAME: 'Renombrar',
        DEACTIVATE: 'Desactivar',
        ACTIVATE: 'Activar',
        COUPONS: 'Cupones',
        ADD_FOLDER: 'Agregar carpeta',
        ADD_FILE: 'Agregar archivo',
        SELECT_CONTACTS: 'Seleccionar Contactos',
        PREFERENCES: 'Preferencias',
        NOTIFICATION_REMINDERS: 'Notificaciones y Recordatorios',
        LOCK_STAGES: 'Etapas de bloqueo',
        LOCK_TASKS: 'Tareas de bloqueo',
        SEND_BATCH_SMS: 'Enviar mensaje de texto por lotes',
        SEND_TEXT_MESSAGE: 'Envía un mensaje de texto',
        VERIFY_EMAIL: 'Verificar correo electrónico',
        SELECT_UNIT_NAME: 'Seleccionar nombre de unidad',
        NEWS: 'Noticias',
        CLOSE_CASE: 'Cerrar caso',
        CUSTOMIZE: 'Personalizar',
        VIEW_MODE: 'MODO DE VISTA',
        LIST: 'Lista',
        COLUMN: 'Columna',
        SETUP_SCHEDULING_PAGES: 'Páginas de programación de configuración',
        SAVE_RUN: 'SAVE & RUN',
        BATCH_CONVERT_TO_ACCOUNTS: 'Converso por lotes en cuentas',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooks',
        ADD_NOTE: 'Añadir nota',
        ADD_NOTE_CONVERT: 'Añadir nota &gt; Convertir',
        OBJECT_NAME: 'Nombre del objeto',
        GREENLIGHT_EXPORT_DOWNLOAD: 'Proceso y descarga',
        PREVIOUS: 'Anterior',
        VIEW: 'Vista',
        MENU: 'Menú',
        NO_DUPLICATE: 'No duplicado',
        ONGOING: 'En marcha',
        ONGOING_ONBOARDING: 'Incorporación continua',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': 'Generar factura',
        'Add Payment': 'Agregar pago',
        'Print' : 'Impresión',
        MOBILE_MENU: 'Mobile Menu',
        PORTAL_MENU: 'Portal Menu',
        TO_QUICKBOOKS:'A Quickbooks',
        CONVERT_INVOICE: 'Convertir a factura',
        RECEIVE : 'Recibir',
        ACCEPT : 'Aceptar',
        CONFIRM: 'Confirmar',
        FULFILL: 'Realizar',
        SHIP: 'Barco',
        ADD_JOBS : 'Agregar trabajos',
        ROYALTY_SETUP: 'Configuración de regalías',
        SAVE_AND_SCHEDULE:'Guardar y programar',
        UNLINK: 'Desconectar',
        TO_XMS: 'a SMS',
        SEQUENTIAL:"Secuencial",
        STAR: 'Estrella',
        PIN :'Alfiler',
        CONVERT: 'convertir',
        SHARE_WITH : 'compartir con',
        FRANCHISOR: 'franquiciador',
        FRANCHISE: 'Franquicia',
        ALL_PHONE_NO: 'Todos los números de teléfono',
        BRING_YOUR_OWN:'Número de BYO (trae tu propio)',
        LEASE_NUMBER:'Número de arrendamiento',
        ADD_NUMBERS:'AÑADIR NÚMEROS',
        ADD_CREDITS:'AGREGAR CRÉDITOS',
        CALL_MINUTES:'minutos de llamada y',
        TEXT_CREDIT:'crédito de texto restante',
        LEASE_A_NO:'Arrendar un número',
        TOLL_FREE:'Número gratuito',
        LOCAL:'Local',
        COUNTRY:'País',
        SURVEY_SETUP : 'Configuración de la encuesta',
        THANKS_SURVEY:'Encuesta de aprobación: formulario de agradecimiento',
        REVIEW_WEBSITE_REQUIRED:'Revisar la URL del sitio web (obligatorio)',
        THUMBS_UP_TITLE:'Título aprobado',
        THUMBS_UP_DESC:'Pulgar arriba descripción',
        YES_BUTTON:'Botón Sí',
        NO_BUTTON:'Sin botón',
        DAYS: 'Días)',
        LOCATION_ALERT:'¡La ubicación ya existe!',
        TASK_NAME:'Nombre de la tarea',
        DESC:'Descripción',
        UNIT_CRM: 'Configuración de CRM de unidad',
        UNIT_CRM_LABEL: 'Los administradores de CRM de la unidad pueden:',
        UNIT_CRM_CUSTOMIZE_FORMS: 'Personalizar formularios',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'Personaliza los flujos de trabajo',
        ACTION: 'Comportamiento:',
        INVOICE_ALL : 'Facturar todo',
        EMAIL_ALL:'Enviar correo electrónico a todos',
        POST_ALL: 'Publicar todo',
        REPORT_FIELDS: 'Campos del informe:',
        COMPLIANCE_NOTIFICATION:'Notificación de cumplimiento',
        COMPLIANCE_LABEL:'Ingrese la dirección de correo electrónico para notificar a FR cuando un franquiciado no cumple',
        ONBOARDING_NOTIFICATION:'Notificación de incorporación',
        EMAIL:'Correo electrónico',
        HELPDESK_NOTIFICATION:'Notificación del servicio de asistencia técnica',
        LMS_SETUP:'Configuración del LMS',
        LMS_LABEL:'Cuenta LMS (obligatoria)',
        PLUS_BASE: '+base',
        UNITBASE:'Base de la unidad'
    },
    NOTE:{
        ALL_NOTES:'Todas las notas',
        ALL_TYPES:'Todos los tipos',        
    },
    HOME: {
        MY_FILTERS: 'Mis filtros',
        GROUPS: 'Grupos',
        ADD_NEW: 'Agregar nuevo',
        RECENT: 'Reciente',
        HOME: 'Casa',
        CLICK_TO_REFRESH: 'Actualizar',
        RECENTLY_VIEWED: 'visto recientemente',
        FILTERS: 'Filtros',
        ADD_GROUP: 'Añadir grupo',
        NOTIFICATIONS: 'Notificaciones',
        WHATS_NEW: "Qué hay de nuevo",
        COLLAPSE: 'Colapso',
        EXPAND: 'Expandir',
        ADD_FILTER: 'Añadir filtro',
        CLICK_TO_FIND_GROUP: 'Haga clic aquí para buscar un grupo',
        CLICK_TO_FILTER_GROUP: 'Filtra tus grupos',
        CLICK_TO_FIND_FILTER: 'Haga clic aquí para buscar un filtro',
        FORECASTS: 'Pronósticos',

        //not found in doc
        ADD_FIELD: 'Haga clic aquí para agregar un campo al filtro',
        FITER_SETUP: 'Configuración del filtro',
        ENTER_BY_HAND: 'Entrar a mano',
        IMPORT: 'Importar',
        VIEW: 'Ver',
        DELETE: 'Borrar',
        CLICK_TO_FIND_REPORT: 'Haga clic aquí para encontrar un informe',

    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'Nombre de la campaña (requerido)',
        GROUP: 'Grupo',
        CONTACT_FILTER: 'Filtro de contacto',
        ACCOUNT_FILTER: 'Filtro de cuenta',
        FROM_NAME_REQUIRED: 'De nombre (requerido)',
        VIEW_AS_WEB_PAGE: 'Ver como una página web',
        SUBJECT_REQUIRED: 'Sujeto (requerido)',
        DAYS: 'Día(s)',
        EXECUTE_NOW: 'Ejecutar ahora',
        TEST: 'Prueba',
        SCHEDULED_FOR: 'Programado para',
        EXECUTED_ON: 'Ejecutado en',
        EXECUTING: 'Ejecutando',
        ABORTED: 'Abortado',
        SCHEDULE: 'Programar',
        CLICK_URL: 'Haga clic en URL',
        SELECT_GROUP: 'Seleccione hasta tres grupos',
        SELECT_CONTACT_FILTER: 'Seleccione un filtro del contacto',
        SELECT_ACCOUNT_FILTER: 'Seleccione un filtro de la cuenta',
        CHANGE_TEMPLATE: 'Cambiar plantilla',
        WITHOUT_AB_TEST: 'Sin prueba a / b',
        WITH_AB_TEST: 'Con una prueba a / b',
        WITH_AUTOMATION: 'Campañas automatizadas',
        WITHOUT_AUTOMATION: 'Campañas táctiles (Legado)',
        SEND_ON_WEEK_DAY: 'No enviar los fines de semana (sábado, domingo)',
        RESEND_AFTER: 'Reenviar después',
        RESEND_ALL: 'Reenviar todo',
        FROM_EMAIL_REQUIRED: 'De correo electrónico (requerido)',
        START_DATE_REQUIRED: 'Fecha de inicio (requerido)',
        CAMPAIGN_TYPE_OPTIONAL: 'Tipo de campaña (opcional)',
        CAMPAIGN_TYPE: 'Tipo de campaña',
        SENT: 'Expedido',
        OPENED: 'Abrió',
        UNOPENED: 'Sin abrir',
        CLICKED: 'Hecho clic',
        BOUNCED: 'Rebotado',
        UNSUBSCRIBED: 'Anulado',
        SPAM: 'Correo no deseado',
        DROPPED: 'Caído',
        TYPE_HERE: 'Escriba aquí.',
        ADD_SIGNATURE: 'Agregar firma',
        INSERT_IMAGE: 'Insertar imagen',
        PERSONALIZE: 'Personalizar',
        TEMPLATE_DIALOG_TITLE: 'Seleccione una plantilla',

        // Campaign Status
        'All': 'Todas',
        'Active': 'Activo',
        'Running': 'Corriendo',
        'Stopped': 'Detenido',

        'New': 'Nuevo',
        'Queued': 'En cola',
        'Executed': 'Ejecutado',
        OPEN: 'Abierto',
        BOUNCE: 'Rebotar',
        SPAMREPORT: 'Spamreport',
        'Halted': 'Detenido',
        'Auto Response': 'Respuesta automática',
        'Marketing Template': 'Plantilla de marketing',
        'Newsletter': 'Hoja informativa',
        'Greetings': 'Saludos',
        'Inactive': 'Inactivo',
        'Executed - No Winner': 'Ejecutado - Sin ganador',
        'Sent to Winner': 'Enviado al ganador',
        'Sending to Winner': 'Enviando al ganador',
        ADD_CAMPAIGN_TYPE: 'Agregar un nuevo tipo de campaña',
        CAMPAIGN_NAME: 'Nombre de la campaña',
        SUBJECT: 'Sujeto',
        MANAGE: 'Gestionar',
        FROM_EMAIL: 'De correo electrónico ',
        SELECT_A_FILTER:'Seleccione un filtro de ',
        BETWEEN: 'Entre',
        WEEKDAYS_ONLY: 'Solo días laborables'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: 'Personalizar Asunto',
        FROM_EMAIL_REQUIRED: 'De correo electrónico (requerido)',
        EMAIL_CAMPAIGN_REPORT: 'Informe de campaña de correo electrónico',
        SCHEDULE_BROADCAST: 'Horario de transmisión',
        RESCHEDULE_BROADCAST: 'Reprogramar la transmisión',
        EXECUTE_BROADCAST: 'Ejecutar transmisión',
        ADD_GROUP: 'Añadir grupo',
        CATEGORY: 'Categoría',
        TEMPLATE: 'Modelo',
        PERFORM_AB_TESTING: 'Realizar pruebas A / B',
        VERIFY_EMAIL: 'Verifique su dirección de correo electrónico',
        VERIFY_EMAIL_ADDRESS: 'Ingrese su dirección de correo electrónico aquí',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar otra dirección de correo electrónico',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'Editar campaña de difusión',
        BATCH_EDIT: 'Edición por lotes',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'Copiar campaña de difusión',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'Crear campaña de difusión',
        All_BROADCASTS: 'Todas las transmisiones',
        BROADCASTS: 'Transmisiones',
        ADD_SIGNATURE: 'Agregar firma',
        BROADCAST_INFO: 'Información de transmisión',
        DESIGN: 'Diseño',
        DRAG_AND_DROP: 'Arrastrar y soltar',
        LEGACY: 'Legado',
        EMAIL_BUILDER: 'Creador de correo electrónico',
        QUALIFIED_CONTACTS: 'Ver la lista de destinatarios',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'Apuntar por',
        AB_TEST_DISTRIBUTION: 'Distribución de prueba A / B',
        MIN_MARGIN_PERCENT: 'Min Ganancia margen%',
        A_PERCENT: 'A%',//'UN%',
        B_PERCENT: 'B%',//'SEGUNDO%',
        AB_CAMPAIGN_REPORT: 'Informe de campaña A / B',
        //not found in doc
        AB_SEND_REST: 'Enviar el resto usando el ganador',
        AB_SELECT_WINNER_BY: 'Seleccionar ganador por',
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar otra dirección de correo electrónico',
        EMAIL_VIEWS: 'Vistas de correo electrónico',
        EMAIL_CLICKS: 'Clics de correo electrónico',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'Editar campaña A/B',
        BATCH_EDIT: 'Edición por lotes',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'Copiar campaña A/B',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'Crear una campaña A/B',
        ALL_AB_CAMPAIGNS: 'Todas las campañas de AB',
        AB_TESTS: 'Pruebas A/B',
        MESSAGE_A: 'Mensaje A',
        MESSAGE_B: 'Mensaje b',
        AFTER: 'después',
        SCHEDULE_AB_CAMPAIGN: 'Programar la campaña A/B',
        WINNER_BASEDON_A: 'Ganador (basado en el Mensaje A)',
        WINNER_BASEDON_B: 'Ganador (basado en el Mensaje B)'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'Calendario / Tareas',
        CALENDAR_ONLY: 'Calendario',
        CALENDARS: 'Calendario para',
        SHOW_OPTION_1: 'Cancelado',
        SHOW_OPTION_2: 'Terminado',
        SHOW_OPTION_3: 'Programado',
        TODAY: 'Hoy',
        BACK: 'Espalda',
        NEXT: 'Siguiente',
        DAY: 'Día',
        WEEK: 'Semana',
        MONTH: 'Mes',
        AGENDA: 'Agenda',
        DATE: 'Fecha',
        TIME: 'Hora',
        EVENT: 'Evento',
        ALL_DAY: 'todo el dia',
        WORK_WEEK: 'semana de trabajo',
        YESTERDAY: 'ayer',
        TOMMOROW: 'mañana',
        SHOW_MORE: '',
        TYPE: 'Tipo',
        MY_CALENDAR_SETTING: 'Configuración de mi calendario',
        DISPLAY:'Monitora',
        ACCOUNT_NAME:'Nombre de la cuenta',
        JOB_NAME : 'Nombre del trabajo',
        COLOR_CODE: 'Codigo de color',

    },
    GROUP: {
        GROUP_NAME: 'Nombre del grupo',
        DELETE_RECORD: 'Eliminar el registro',
        USED_IN: 'Utilizada en',
        REMOVE_FROM: 'Eliminar de',
        SELECT_RECORDS: 'Registros seleccionados',
        ALL_RECORDS: 'Todos los registros',
        SELECT_OPTIONS: 'Seleccione opciones',
        GROUP_FILTER: 'Seleccionar filtro de grupo',
        ALL: 'Todas',
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        DETAILS: 'Detalles',
        'Active': 'Activo',
        'Inactive': 'Inactivo',
        'New': 'Nuevo',
        'scheduled': 'programado',
        'Queued': 'En cola',
        'Executed': 'Ejecutado',
        'Running': 'Corriendo',
        'Halted': 'Detenido',
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'Tareas',
        PERSONALIZE_OPTION_1: 'Atrasado',
        PERSONALIZE_OPTION_2: 'Hoy',
        PERSONALIZE_OPTION_3: 'Futuro',
        PERSONALIZE_OPTION_4: 'mañana',
        PERSONALIZE_SORT_1: 'Fecha',
        PERSONALIZE_SORT_2: 'Prioridad',
        TASKS_FOR: 'Tareas para',
        SORT_BY: 'Ordenar por',
        COMPLETE_THIS_APPOINTMENT: 'Completa esta cita',
        COMPLETE_THIS_TASK: 'Completa esta tarea',
        NEXT_DAY: 'Día siguiente',
        NEXT_WEEK: 'La próxima semana',
        COMPLETE: 'Completar',
        DELETE: 'Borrar',
        TASKS: 'Tareas',
        APPOINTMENTS: 'Citas',
        ADD_NOTE: 'Agregue una nota (opcional):',
        NEXT_MONTH: 'Próximo mes',
        DAYS: 'Dias',
        SPECIFIC_DATE: 'Fecha específica',
        RESCHEDULE: 'Reprogramar',
        RESCHEDULE_FOR: 'Reprogramar para:',
        REPEAT: 'Repetir',
        DAY_OF_THE_MONTH: 'día del mes',
        DAY_OF_THE_WEEK: 'día de la semana',
        REPEAT_TYPE: 'Repetir tipo',
        REPEAT_ON: 'Repita en',
        REPEAT_BY: 'Repita por',
        START_DATE: 'Fecha de inicio',
        END_DATE: 'Fecha final',
        REPEAT_EVERY: 'Repite cada',
        REMOVE_REMINDER: 'Eliminar recordatorio',
        ONLY_THIS: 'Solo esto',
        FOLLOWING: 'Siguiendo',
        ALL: 'Todas',
        THIS:'Esta',
        THIS_AND_FOLLOWING:'Esto y lo siguiente',
        ONLY_THIS_RECORD: 'Solo este registro',
        FOLLOWING_RECORDS: 'Siguiendo registros',
        ALL_RECORDS: 'Todos los registros',
        ADD_A_NOTE: 'Agrega una nota',
        COMPLETE_TASK_OPTIONAL: 'Completar tareas pendientes',
        COMPLETE_TASK_OPTIONAL_OBJ: 'Completa tareas pendientes / correos electrónicos / mensajes de texto',
        CREATE_NEXT_TASKS: 'Crea las siguientes tareas',
        CREATE_NEXT_TASKS_OBJ: 'Crea las siguientes tareas / correos electrónicos / mensajes de texto',
        PENDING_TASKS: 'Tareas pendientes',
        SEARCH_BY_NAME: 'Buscar por nombre',
        INVITE_GUEST: 'Invitar a los huéspedes',
        ATTENDEES: 'Asistentes',
        SALES_REP: 'Representante de ventas',

        //not found in doc
        ADD_REMINDER: 'Agregar un recordatorio',
        'Email': 'Correo electrónico',
        'Pop-up': 'Surgir',
        'minutes': 'minutos',
        'hours': 'horas',
        'days': 'dias',
        'weeks': 'semanas',
        DAILY: 'Diario',
        WEEKLY: 'Semanal',
        MONTHLY: 'Mensual',
        YEARLY: 'Anual',
        DAYS_S: 'dias',
        WEEKS: 'semanas',
        MONTHS: 'meses',
        YEARS: 'años',
        APPOINTMENT: 'Cita',
        TASK: 'Tarea',
        SUBJECT: 'Tema',
        ASSIGN_TO: 'Asignar a',
        COMPANY: 'Empresa',
        CONTACT_NAME: 'Nombre de contacto',
        OUTLOOK_SYNC: 'Sincronización de Outlook',
        GOOGLE_SYNC: 'Google Sincronizar',
        'Scheduled': 'Programado',
        'Completed': 'Terminado',
        'Cancelled': 'Cancelado',
        'Pending': 'Pendiente',
        TASK_NAME: 'Nombre de la tarea',
        DUE_BY: 'Debido por',
        DESCRIPTION: 'DESCRIPCIÓN',
        OWNER: 'Propietario',
        FOLLOWUP: 'Seguimiento',
        'All': 'Todas',
        'Empty': 'Vacío',
        'No': 'No',
        'Yes': 'Sí',
        'Everyone': 'Todo el mundo',
        'Just me': 'Solo yo',
        ALL_TASK_COMPLETED: 'Estas tareas ya se han completado.',
        'duration': 'duración',
        'days_after': 'dias despues',
        DEP_TASK_NAME: 'Nombre de la tarea',
        TASK_COMPLETED: 'Tarea terminada(%)',
        APPOINTMENTS_AND_TASKS: 'Nombramientos y tareas',
        APPOINTMENTS_ONLY: 'Sólo citas',
        TASKS_ONLY: 'Solo tareas',
        EDIT: 'Editar',
        SENDNOW: 'Enviar ahora',
        OPPORTUNITY_NAME: 'Nombre de oportunidad',
        SELECT_USER: 'Seleccionar usuario',
        SELECT_STATUS: 'Seleccionar estado',
        VISITS: 'Visitas',
        UNIT_OWNER_TASKS: 'Tareas de la unidad',
        COMPLETE_UNIT_TASK_OPTIONAL: 'Tareas pendientes del propietario de la unidad completa',
        UNIT_TASKS: 'Tarea de la Unidad',
        DOCUMENTS: 'Documentos',
        LINKS: 'Enlaces',
        TASK_SETTINGS: 'Configuración de tareas',
        APPOINTMENT_SETTINGS: 'Configuración de citas',
    },
    ATTACHMENT: {
        UPLOAD: 'Subir',
        MY_COMPUTER: 'Mi computadora',
        BROWSE: 'Vistazo',
        DOWNLOAD: 'Descarga de archivos',
        ATTACH_FILE: 'Adjuntar un archivo',
        UPLOADING: 'Cargando',
        NO_IMAGE_FOUND: 'No se ha encontrado ninguna imagen',
        DOWNLOAD_RECORDING: 'Descargue la grabación',
        ATTACHMENT: 'Adjunto archivo',
        ADD_AN_ATTACHMENT: 'Agregar un archivo adjunto'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'Haga clic aquí para contraer',
        CLICK_TO_EXPAND: 'Haga clic aquí para expandir',
        SHOW_TOTAL: 'Mostrar total',
        SHOW_PICTURE: 'Mostrar imagen',
        DESCENDING: 'Descendente',
        SORT_BY: 'Ordenar por',
        SELECT_COLUMN_FOR_VIEW: 'Seleccione los campos para esta vista',
        SELECT_COLUMN_FOR_FILTER_VIEW: 'Seleccionar columnas para los resultados del filtro',
        ADD_AND_REORDER: 'Agregar y reordenar',
        FIELDS: 'campos',
        CLICK_TO_ADD_MORE_FIELDS: '(Haga clic en este cuadro para agregar más campos. Arrastre y vuelva a ordenar los campos para cambiar su posición).',
        SELECT_FIELDS: 'Seleccionar campos',
        MODIFY_FIELD: 'Modificar campo',
        EXPORT_SELECTED_RECORDS: 'Exportar registros seleccionados',
        EXPORT_ALL_RECORDS: 'Exportar todos los registros',
        LINK_EXISTING: 'Enlace existente',
        SET_UP: 'Preparar',
        GROUP_BY: 'Agrupar por',
        FIND_BY_FIELD: 'Encontrar por',
        FILTER_TYPE: {
            DEFAULT_FILTERS: 'Filtros predeterminados',
            SHARED_FILTERS: 'Filtros compartidos'
        },
        COLUMN_VIEW_SETUP_INFO: 'Esta vista de columna solo mostrará los primeros cinco campos. La vista de lista muestra todos los campos.',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'Mostrar etiquetas',
        SELECT_OPTIONS: 'Seleccione opciones',
        CLICK_TO_ADD_MORE_FIELDS: 'Haga clic en este cuadro para agregar más campos. Arrastre y reordene los campos para cambiar su orden. Puede seleccionar un máximo de 50 campos.',
        SETUP_FIELDS: 'Campos de configuración',
        ADD_OR_REMOVE_FROM_LIST: 'Agregar / Eliminar campos de esta lista',
        CLICK_TO_KNOW_MORE: 'Haga clic para saber más',
        CLICK_TO_ADD_CHILD_OBJECTS: 'Haga clic en este cuadro para agregar más objetos secundarios. Arrastre y reordene los objetos para cambiar su posición.',
        SETUP_CHILD_TABS: 'Configurar pestañas secundarias',
        SHOW_LABELS_AGAINST_FIELD: 'Seleccione esto para mostrar las etiquetas en los campos.',
        CUSTOMIZE_CHILD: 'Personalizar Niño',
        REFUND_CHILD_LABEL: 'Reembolso',
        //not found in doc 
        GROUPS: 'Grupos',
        MANAGE: 'Gestionar',
        ADD_TO_GROUP: 'Añadir al grupo',
        DATA_TIP_1: 'Esto crearía las tareas para el ',
        DATA_TIP_2: 'Esto crearía las tareas / correos electrónicos / mensajes de texto para el ',
        DATA_TIP_3: ' escenario',

        HIGHLIGHT_LABEL_OPPORTUNITY: 'Oportunidad $',
        HIGHLIGHT_LABEL_FORECAST: 'Pronóstico $',
        HIGHLIGHT_LABEL_CLOSING_ON: 'Cerrando el',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: 'Edad de oportunidad',
        HIGHLIGHT_LABEL_DAYS: 'Dias',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'Haga clic aquí para agregar un objeto secundario a la configuración.',
        NO_SETUP_OPTION: 'Ninguna'
    },
  
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'Definir la relación',
        RELATE: 'Relacionar',
        COMPANY: 'Empresa',
        FULL_NAME: 'Nombre completo',
        EMAIL: 'Email'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'Toca informe de campaña',
        INTERVAL: 'Después',
        NOTE_TYPE: 'Tipo de nota',
        TEXT_REQUIRED: 'Texto de nota (requerido)',
        TASK_NAME_REQUIRED: 'Nombre de la tarea (requerido)',
        OWNER_REQUIRED: 'Propietario (requerido)',
        DUE_BY_REQUIRED: 'Debido por (requerido)',
        DAY_AFTER_REQUIRED: 'Días después (requerido)',
        EMAIL: 'Correo electrónico',
        POP_UP: 'Surgir',
        MINUTES: 'minutos',
        HOURS: 'horas',
        DAYS: 'dias',
        WEEKS: 'semanas',
        REMINDER: 'Recordatorio',
        GROUP_REQUIRED: 'Seleccionar grupo (requerido)',
        ADD_TO_GROUP: 'Añadir al grupo',
        MOVE_TO_GROUP: 'Mover al grupo',
        REMOVE_FROM_GROUP: 'Sacar del grupo',
        STATUS_REQUIRED: 'Estado (requerido)',
        WORKFLOW_REQUIRED: 'Flujo de trabajo (requerido)',
        CHOOSE_ANOTHER_FIELD: 'Elige otro campo',
        CHOOSE: 'Escoger',
        FIELD_FOR: 'un campo para',
        CAMPAIGN_NAME: 'Nombre de la campaña (requerido)',
        START: 'comienzo',
        STOP: 'Detener',
        REPORT: 'Informe',
        RESUME: 'Currículum',
        SELECT_PAGE: 'Seleccione una página',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'Ingrese la URL del enlace (opcional).',
        ENTER_WEB_PAGE_LINK: 'Ingrese al enlace de la página web.',
        CLICK_TO_VIEW: 'Haga clic aquí para ver',
        CLICK_TO_EDIT: 'Haga clic aquí para editar',
        DRAG_TO_MOVE: 'Arrastre para mover',
        DRAG_TO_CONNECT: 'Arrastre para conectar',
        CLICK_TO_REMOVE: 'Haga clic aquí para eliminar',
        CLICK_TO_SEE_REPORT: 'Haga clic aquí para ver el informe',
        CLICK_TO_ADD_MSG: 'Haga clic para agregar un nuevo mensaje',
        NO: 'No',
        YES: 'Sí',
        CLICK_TO_SELECT_USERS: 'Haga clic aquí para seleccionar usuarios',
        SALES_REP: 'Representante de ventas',
        USERS: 'Usuarios',
        CAMPAIGN_SENDER: 'Remitente de campaña',
        SCORE: 'Puntuación',
        START_WITH: 'Añadir',//'Start with'
        DECISIONS: 'Decisiones',
        ACTIONS: 'Comportamiento',
        LINK_CLICKED: 'Enlace hecho clic?',
        SCORE_REACHED: 'Puntuación alcanzado?',
        TOUCH_CAMPAIGN: 'Toca Campaña',
        WEB_FORM: 'Formulario web',
        LANDING_PAGE: 'Página de destino',
        WEBSITE_VISITED: 'Sitio web visitado',
        CHANGE_GROUP: 'Cambiar grupo',
        ADD_NOTE: 'Añadir la nota',
        NOTIFY: 'Notificar',
        ADD_TASK: 'Agregar tarea',
        FIELD_UPDATED: 'Campos de actualización',
        CHOOSE_TEMPLATE: 'Elegir una plantilla',
        USE_THIS_TEMPLATE: 'Usa esta plantilla',
        WAIT: 'Espere',
        NAME: "Nombre",
        TARGET_GROUPS: 'Grupos destinatarios)',
        TARGET_FILTER: 'Filtro de destino',
        RESEND_AFTER: 'Reenviar después',
        START_DATE: 'Fecha de inicio',
        SUBJECT: 'Tema',
        TEXT: 'Texto',
        TASK_NAME: 'Nombre de la tarea',
        ASSIGN_TO: 'Asignar a',
        FIELD_LABEL: 'Etiqueta de campo',
        FIELD_VALUE: 'Valor de campo',
        LINK: 'Enlazar',
        SEND_ON_ANY_TIME: 'Enviar: en cualquier momento',
        SEND_ON_BUSINESS_DAY: 'Enviar: solo en días hábiles',
        CONVERT_TO: 'Convertir a',
        QUALIFIER: 'Índice',
        OBJECT: 'Objeto',
        NOTIFY_SENDER: 'Notificar al remitente',
        NOTIFY_SALES_REP: 'Notificar al representante de ventas',
        NOTIFY_SENDER_SALESREP: 'Notificar al remitente, notificar al representante de ventas',
        NOTIFY_USERS: 'Notificar a los usuarios',
        TEST: 'Prueba',
        VIEW_AS_WEBPAGE_INFO: 'Esta opción agregará un enlace en la parte superior del correo electrónico para abrir el correo electrónico como una página web',
        LINK_CLICK_INFORMATION: 'Si deja esto en blanco, todos los enlaces en el correo electrónico serán rastreados.',
        GROUP_NAME: 'Nuevo nombre de grupo',
        SELECT_USER: 'Asignar a',
        REMIND_BEFORE: 'Recordar antes',
        INTERVAL_REQUIRED: 'Intervalo (requerido)',
        //not found in doc
        VERIFY_EMAIL_ADDRESS_OPTION: 'Verificar otra dirección de correo electrónico',
        'Touch Campaign': 'Toca Campaña',
        'Link Clicked': 'Enlace hecho clic',
        'Link Clicked?': 'Enlace hecho clic?',
        'Email Opened': 'Correo electrónico abierto',
        'Email Opened?': 'Correo electrónico abierto',
        'Unsubscribed': 'Anulado',
        'Score Reached': 'Puntuación alcanzada',
        //'Send One-off Email': 'Enviar correo electrónico único',
        'Send Email': 'Enviar correo electrónico',
        'Change Group': 'Cambiar grupo',
        'Add Note': 'Añadir la nota',
        'Notify': 'Notificar',
        'Add Task': 'Agregar tarea',
        'Field Updated': 'Campo actualizado',
        'Assign': 'Asignar',
        'Converted': 'Convertido',
        'Start': 'Comienzo',
        'Add Touch Message': 'Agregar mensaje táctil',
        'Web Form': 'Formulario web',
        'Website Visit': 'Visita al sitio web',
        'Landing Page': 'Página de destino',
        'Wait': 'Espere',
        WAIT_FOR_DAYS: 'dias)',
        'Simple email nurturing': 'Simple correo electrónico nutriendo',
        'Capture leads using a web form and nurture': 'Capture leads utilizando un formulario web y nurture',
        'Track web page visit and nurture': 'Seguir la visita de la página web y nutrir',
        'Capture leads from a landing page and nurture': 'Capture los leads de una página de inicio y nurture',
        'Start with touch campaign': 'Comience con la campaña táctil',
        'Start with web form': 'Comience con formulario web',
        'Start with tracking website visit': 'Comience con la visita del sitio web de seguimiento',
        'Start with landing page': 'Comience con la página de inicio',
        COPY_OF: 'Copia de',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: 'Nueva campaña automatizada',
        TARGET_BY: 'Objetivo por',
        'Hours': 'Horas',
        'Hour(s)': 'Hora (s)',
        'day(s)': 'día(s)',
        'hour(s)': 'hora(s)',
        ALL_AUTOMATED_CAMPAIGNS: 'Todas las campañas automatizadas',
        MESSAGE: 'Mensaje',
        SELECT_A_CAMPAIGN: 'Por favor seleccione una campaña',
        ADD_SIGNATURE: 'Agregar firma',
        BIRTHDAY_CAMPAIGN: 'Campaña de cumpleaños',
        'Add Birthday Message': 'Añadir mensaje de cumpleaños',
        'Start with Birthday Campaign': 'Comienza con la campaña de cumpleaños',
        'SMS Replied': 'SMS respondido',
        'Send SMS': 'Enviar SMS',

        'Touch Campaign Automation': 'Toque la automatización de la campaña',
        'Birthday Campaign Automation': 'Automatización de la campaña de cumpleaños',
        'Web Form Automation': 'Automatización de formularios web',
        'Landing Page Automation': 'Automatización de la página de destino',

        SEND_SMS: 'Enviar SMS',
        SMS_REPLY: '¿SMS respondido?',
        BIRTHDAY_CAMPAIGN_REPORT: 'Informe de la campaña de cumpleaños',
        START_IMMEDIATELY: 'Inmediatamente',
        START_SCHEDULE: 'Programar',
        SCHEDULE_ON_TEXT: 'Programado para comenzar en',
        SCHEDULE_DIALOG_TITLE: 'Seleccione la fecha y hora del horario',
        FROM_NUMBER: 'De número',
        REPLY: 'Respuesta',
        NOT_CONVERTED: 'No convertido'
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'Todas las campañas táctiles',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizar Asunto',
        NAME_REQUIRED: 'Nombre (requerido)',
        STATUS: 'Estado',
        SUBJECT_REQUIRED: 'Sujeto (requerido)',
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        TEST: 'Prueba',
        SELECT_TEMPLATE: 'Comienza a escribir para seleccionar una plantilla',
        TYPE_HERE: 'Escriba aquí.',
        ADD_SIGNATURE: 'Agregar firma',
        INSERT_IMAGE: 'Insertar imagen',
        PERSONALIZE: 'Personalizar',
        SELECT_FROM_LIBRARY: 'Seleccionar de la biblioteca',
        USE_THIS_DESIGN: 'Use este diseño',
        DEAR: 'querido',
        EXPAND_EDITOR: 'Pantalla completa',
        SHARE: 'Uso para correos electrónicos de marketing y ventas',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'Crear plantilla de correo electrónico de marketing',
        COPY_OF: 'Copia_de',
        BATCH_EDIT: 'Edición por lotes',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'Editar plantilla de correo electrónico de marketing',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'Copiar plantilla de correo electrónico de marketing',
        CATEGORY: 'Categoría',
        LAYOUT: 'Diseño',
        'Auto Response': 'Respuesta automática',
        'Marketing Template': 'Plantilla de marketing',
        'Newsletter': 'Hoja informativa',
        'Greetings': 'Saludos',
        CHOOSE_EMAIL_BUILDER: 'Elija un creador de correo electrónico',
        DRAG_AND_DROP_BUILDER: 'Utilice el nuevo constructor de arrastrar y soltar',
        LEGACY_BUILDER: 'Usar el constructor heredado',
        TEMPLATE_INFO: 'Información de la plantilla',
        DESIGN: 'Diseño',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizar Asunto',
        NAME_REQUIRED: 'Nombre (requerido)',
        STATUS: 'Estado',
        SUBJECT_REQUIRED: 'Sujeto (requerido)',
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        TEST: 'Prueba',
        TYPE_HERE: 'Escriba aquí.',
        ADD_SIGNATURE: 'Agregar firma',
        INSERT_IMAGE: 'Insertar imagen',
        PERSONALIZE: 'Personalizar',
        DEAR: 'querido',
        SALES_TEMPLATE_CREATE_TAB_LABEL: 'Crear plantilla de correo electrónico de ventas',
        SALES_TEMPLATE_EDIT_TAB_LABEL: 'Editar plantilla de correo electrónico de ventas',
        SALES_TEMPLATE_COPY_TAB_LABEL: 'Copiar plantilla de correo electrónico de ventas',
        COPY_OF: 'Copia_de',
        SALES_TEMPLATES: 'Plantillas de correo electrónico de ventas',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: 'Personalizar Asunto',
        NAME_REQUIRED: 'Nombre (requerido)',
        STATUS: 'Estado',
        SUBJECT_REQUIRED: 'Sujeto (requerido)',
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        TEST: 'Prueba',
        TYPE_HERE: 'Escriba aquí.',
        ADD_SIGNATURE: 'Agregar firma',
        INSERT_IMAGE: 'Insertar imagen',
        PERSONALIZE: 'Personalizar',
        DEAR: 'querido',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: 'Crear plantilla de acuerdo',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: 'Editar plantilla de acuerdo',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: 'Copiar plantilla de acuerdo',
        COPY_OF: 'Copia_de',
        AGREEMENT_TEMPLATES: 'Plantillas de acuerdo',
        ADD_SIGNATURE_1: 'Firma 1',
        ADD_SIGNATURE_2: 'Firma 2',
        ADD_SIGNATURE_3: 'Firma 3',
        ADD_SIGNATURE_4: 'Firma 4',
        ADD_SIGNATURE_5: 'Firma 5',
        COVER_LETTER: 'Carta de presentación',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'Editar plantilla de SMS',
        SMS_TEMPLATE_COPY_TAB_LABEL: 'Copiar plantillas de suma',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'Ingrese su nombre de dominio (excluya http o www)',
        GENERATE_TRACKING_CODE: 'Generar código de seguimiento',
        TRACKING_CODE: 'Tu código de seguimiento',
        COPY_TO_CLIPBOARD: 'Copiar al portapapeles',
        DONE: 'Hecho'

    },
    WEB_FORM: {
        SELECT_FORM: 'Seleccione un formulario',
        WEB_FORM_CREATE_TAB_LABEL: 'Crear formularios web',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'Ingrese el correo electrónico para probar',
        SEND_A_TEST_EMAIL: 'Enviar un correo de prueba',
        TYPE_HERE: 'Escriba aquí.',
        INSERT_IMAGE: 'Insertar imagen',
        ADD_SIGNATURE: 'Agregar firma',
        PERSONALIZE: 'Personalizar',
        FROM: 'De',
        SEND_MAIL: 'Enviar correo electrónico',
        PERSONALIZE_SUBJECT: 'Personalizar Asunto',
        SUBJECT: 'Tema',
        COMPUTER: 'De computadora',
        LIBRARY: 'De la biblioteca',
        RESCHEDULE: 'Reprogramar',
        TO: 'Para',
        ATTACH: 'Adjuntar un archivo',
        CC: 'CC',
        WHEN: 'Cuando',
        SENDING_TEST_MAIL: 'Enviando correo electrónico de prueba ...',
        SCHEDULE: 'Calendario',
        SCHEDULE_DIALOG_TITLE: 'Seleccione la fecha y hora del horario',
        SEND_LATER: 'ENVÍALO DESPUÉS',
        SEND: 'Enviar',
        RECIPIENTS: 'destinatarios',
        SENDING: 'Enviando...'
    },
    SMS: {
        TO: 'Para',
        WHEN: 'Cuando',
        FROM: 'De',
        FROM_NUMBER: 'De número',
        SEND_A_TEST_SMS: 'Enviar un sms de prueba',
        TO_NUMBER: 'Al numero',
        STATUS: 'Estado',
        ENTER_NUMBER :'Ingrese el número para la prueba'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'Historial de puntaje de plomo',
        SCORE: 'Puntuación',
        DATE: 'Fecha',
        ACTION: 'Acción',
        USER: 'Usuario',
        DETAILS: 'Detalles',
        ACTION_NAME: 'Nombre de acción',
        SCORE_DETAILS: 'Detalles de puntuación',
        BY: 'por',
        FROM: 'de',
        LINK: 'Enlazar',
        CLICKED: 'clickeado',
        EMAIL: 'Correo electrónico',
        OPENED: 'abrió',
        UNSUBSCRIBED: 'Anulado',
        CONVERTED_FROM: 'Convertido de',
        TO: 'a',
        IN_OPPORTUNITIES: 'en Oportunidades',
        ADDED_TO: 'Añadido a',
        REMOVED_FROM: 'Retirado de',
        UPDATED_TO: 'actualizado a',
        WEBFORM: 'Formulario web',
        SUBMITTED: 'presentada',
        VISITED: 'Visitó',
        EXPIRED: 'Muerto',
        'Imported': 'Importado',
        'Created': 'Creado',
        'Converted to Account': 'Convertido a Cuenta',
        'Created from Web form': 'Creado desde el formulario web',
        'Created from Landing page': 'Creado desde la página de destino',
        'Created from Zapier': 'Creado a partir de Zapier',
        'Created from Outlook': 'Creado desde Outlook',
        'Created from Outlook (Client)': 'Creado desde Outlook (Cliente)',
        'Created from G Suite': 'Creado desde G Suite',
        'Created from Office 365': 'Creado desde Office 365',
        'Created from Facebook/twitter etc': 'Creado desde Facebook / Twitter, etc.',
        'Pushed': 'Empujado',
        'Created from API': 'Creado desde la API',
        'Inline/Quick updated': 'Inline / Quick actualizado',
        'Updated': 'Actualizado',
        'Batch Updated': 'Lote actualizado',
        'Workflow Applied': 'Flujo de trabajo aplicado',
        'Converted': 'Convertido',
        'Assigned': 'Asignado',
        'Deleted': 'Eliminado',
        'Restored': 'Restaurado',
        'Merged': 'Fusionado',
        'Main Contact updated': 'Contacto principal actualizado',
        'Shared': 'Compartido',

        'Link Clicked': 'Enlace pulsado',
        'Email Opened': 'Correo electrónico abierto',
        'Unsubscribed': 'Desuscrito',
        'Web Form Submitted': 'Formulario web enviado',
        'Removed from Group': 'Eliminado de grupo',
        'Field Updated': 'Campo actualizado',
        'Added to Group': 'Añadido al grupo',
        'Website Visited': 'Sitio web visitado',
        'Has Business Email': 'Tiene correo electrónico de negocios',
        'Clicked Link': 'Haga clic en el enlace',
        'Opened Email': 'Correo abierto',
        ACCOUNT_SCORE: 'Su puntuación perfecta de Cuentas es',
        CONTACT_SCORE: 'Su puntuación perfecta para los contactos es'
    },
    DASHBOARD: {
        TODAY: 'Hoy',
        YESTERDAY: 'Ayer',
        THIS_WEEK: 'Esta semana',
        THIS_MONTH: 'Este mes',
        THIS_QUARTER: 'Este cuarto',
        THIS_YEAR: 'Este año',
        LAST_WEEK: 'La semana pasada',
        LAST_MONTH: 'El mes pasado',
        LAST_QUARTER: 'Último cuarto',
        LAST_YEAR: 'El año pasado',
        CUSTOM: 'Personalizado',

        //Kanban View
        NEXT_MONTH: 'Próximo mes',
        NEXT_QUARTER: 'Siguiente cuarto',
        NEXT_THREE_MONTHS: 'Próximos tres meses',
        NEXT_TWO_MONTHS: 'Los siguientes dos meses',

        SALES_DASHBOARD: 'Panel de ventas',
        MARKETING_DASHBOARD: 'Panel de marketing',
        // SALES_MARKETING_DASHBOARD: 'Tablero de Ventas y Marketing',
        REPOSITION_GADGET: 'Haz clic y arrastra para reposicionar este gadget',
        'Top Sources By Lead Volume': 'Principales fuentes por volumen de plomo',
        'Top Sources By Lead Conversion': 'Principales fuentes por conversión de leads',
        'Marketing Email Summary': 'Resumen de email de marketing',
        'Top Marketing Emails by CTR': 'Los mejores correos electrónicos de marketing de CTR',
        'Top Web Pages': 'Top páginas web',
        'Lead Pipeline': 'Tubería de plomo',
        'Opportunity Pipeline': 'Oleoducto de oportunidad',
        'Sales Leaderboard': 'Tabla de clasificación de ventas',

        'Status': 'Estado',
        'Count': 'Contar',
        'Subject': 'Tema',
        // 'Click-Through Rate(CTR)': '',
        'Website Name': 'Nombre del sitio web',
        'Forecast': 'Previsión',
        'Sales person': 'Persona de ventas',
        'Closed': 'Cerrado',

        'Activities': 'Actividades',
        'Accounts': 'Matriz de cuentas',
        'Opportunities': 'Matriz de Oportunidades',
        'Users': 'Usuarios',
        'Appointment': 'Cita',
        'Dialed': 'Marcado',
        'Email': 'Correo electrónico',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Habló',
        'Task': 'Tarea',
        'Transferred': 'Transferido',

        'Qualified': 'Calificado',
        'Presentation': 'Presentación',
        'Proposal': 'Propuesta',
        'Negotiation': 'Negociación',
        'Close': 'Cerca',

        'Lead': 'Conducir',
        'Prospect': 'Perspectiva',
        'Customer': 'Cliente',
    },
    LOGIN: {
        LOG_IN: 'Iniciar sesión',
        EMAIL_ADDRESS: 'Dirección de correo electrónico',
        PASSWORD: 'Contraseña',
        REMEMBER_ME: 'Recuérdame'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: 'Guardar como borrador',
        POST_NOW: 'Publicar ahora',
        CONNECT: 'Conectar'
    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'Computadora',
        LIBRARY: 'Biblioteca',
        IMAGES: 'Imágenes',
        STOCK_IMAGES: 'Imagenes de archivo',
        DOCUMENTS: 'Documentos',
        SEARCH: 'Buscar...',
        UPLOAD_DOCUMENT: 'Subir documento',
        NAME: 'Nombre',
        TITLE: 'Título',
        TITLE_REQUIRED: 'Título (requerido)',
        LINK_REQUIRED: 'Enlace (requerido)',
        TYPE: 'Tipo',
        OWNER: 'Propietario',
        ALL: 'Todas',
        NO_IMAGE_FOUND: 'No se encontró imagen',
        UPLOAD_DIALOG_TITLE: 'Seleccione un documento para subir',
        SHARED_WITH: 'compartido con',
        UNITS: 'unidades',
        UNIT: 'unidad',
        UNIT_PUSH: 'unidad:',
        RENAME_FOLDER: 'Renombrar carpeta',
        CREATE_FOLDER: 'Crear carpeta',
        NEW_FOLDER: 'Nueva carpeta',
        ASSETS_NOT_FOUND: 'No se encontraron carpetas ni archivos.',
        'Folder': 'Carpeta',
        'File': 'Archivo',
        IMAGE: 'Imagen',
        DOCUMENT_AND_FILE: 'Documentos & Archivos',
    },
    HEADER: {
        SALES_TEMPLATE: 'Plantilla de correo electrónico de ventas',
        CALENDAR_TASKS: 'Calendario / Tareas',
        ACCOUNT: 'Cuenta',
        SALES_REPORT: 'Reporte de ventas',
        ACCOUNTS: 'Cuentas',
        ACCOUNTS_WITHOUT_GROUP: 'Cuentas sin grupos',
        MY_ACCOUNT: 'Mis cuentas',
        TODAYS_ACCCOUNTS: 'Cuentas de hoy',
        CONTACTS: 'Contactos',
        CONTACTS_WITHOUT_ACCOUNT: 'Contactos sin cuenta',
        CONTACTS_WITHOUT_ACCOUNTS: 'Contactos sin cuentas',
        CONTACTS_WITHOUT_GROUPS: 'Contactos sin grupos',
        MY_CONTACTS: 'Mis contactos',
        TODAYS_CONTACTS: 'Contactos de hoy',

        ALL_APPOINMENTS: 'Todas las citas',
        MY_APPOINMENTS: 'Mis citas',
        MY_COMPLETED_APPOINMENTS: 'Mis citas completadas',
        MY_MISSED_APPOINMENTS: 'Mis citas perdidas',
        TODAYS_APPOINMENTS: 'Citas de hoy',
        ALL_TASKS: 'Todas las tareas',
        MY_COMPLETED_TASKS: 'Mis tareas completadas',
        MY_FUTURE_TASKS: 'Mis tareas futuras',
        MY_PENDING_TASKS: 'Mis tareas pendientes',
        MY_TODAYS_TASKS: 'Mis tareas de hoy',
        ONBOARDING: 'A bordo',
        SALES_PIPELINE: 'Informe de canalización de ventas',
        COUPONS_CLAIM_REPORTS: 'Informes de reclamos de cupones',
        'Cases': 'Casos',
        'Projects': 'Proyectos',
        'Issues': 'Cuestiones',
        'Solutions': 'Soluciones',
        'Contacts': 'Contactos',
        'Accounts': 'Cuentas',
        'Opportunities': 'Oportunidades',
        'Appointments': 'Citas',
        'Tasks': 'Tareas',
        'Onboarding': 'A bordo',
        'Units': 'Unidades',
        'Assets': 'Bienes',
        'Activities': 'Ocupaciones',
        'Conversions': 'Conversiones',
        'Campaigns': 'Campañas',
        'Reports': 'Informes',
        'Sales_templates ': 'Plantillas de ventas',
        'Dashboard': 'Tablero',
        'Sales Leads': 'Líder en ventas',
        'Recycle Bin': 'Cuentas',
        REPORT: 'Informe',
        TASKS_PAGE: 'Tareas',
        'Products': 'Productos',
    },
    ADVANCE_SEARCH: {
        ADVANCE_SEARCH: 'Búsqueda Avanzada',
        ADD_FIELD:'Buscar otro campo'
    },
    IMPORT: {
        GROUP: 'Grupo (obligatorio)',
        WORKFLOW: 'Flujo de trabajo (obligatorio)',
        STATUS: 'Estado (requerido)',
        SOURCE: 'Fuente (obligatorio)',
        PRIMARY_SOURCE:'Fuente primaria',
        SECONDARY_SOURCE:'Fuente secundaria',
        IMPORT: 'Importar',
        LINK_USING: 'Enlace usando',
        LINK_WITH: 'Enlace con',
        APPLY_WORKFLOW: 'Aplicar flujo de trabajo',
        AUTO_MAP: 'Mapa automático',
        USE_SAVED_MAPPING: 'Usar la asignación guardada',
        SAVE_MAPPING: 'Guardar mapeo',
        DELETE_MAPPING: 'Guardar mapeo',
        DONOT_CHECK_DUPLICATE: 'No verifique duplicados',
        CHECK_DUPLICATE: 'Verificar duplicados',
        CHECK_DUPLICATE_BY: 'Verifique los duplicados por',
        DONOT_IMPORT: 'No importar',
        MARK_DUPLICATE: 'Importar y marcar como duplicado',
        UPDATE: 'Actualizar',
        //not found in doc
        STEP_1: 'Seleccionar fuente',
        STEP_2: 'Campos de mapas e importación',
        FINISH: 'Terminar',
        NEXT: 'Siguiente',
        MAP_CSV_COLUMNS: 'Asignar columnas de CSV a CRM',
        MAP_XLS_COLUMNS: 'Asigne columnas XLS a CRM',
        MAP_XLSX_COLUMNS: 'Asigne columnas XLSX a CRM',
        MAP_TO_CRM_FIELD: 'Mapa al campo CRM',
        COLUMN: 'Columna',
        ADD_NEW_GROUP: 'Agregar un nuevo grupo',
        WORKFLOW_OPTIONAL: 'Workflow ( Optional )',
        FILE_MUST_CSV: 'El archivo debe estar en formato CSV.',
        SKIP: 'Saltar',
        MERGE: 'Unir',
        CHECK_DUPLICATE_USING: 'Verificar duplicados utilizando',
    },
    REPORT: {
        START_DATE: 'Fecha de inicio',
        END_DATE: 'Fecha final',
        REPORT_ON: 'Informar sobre',
        REFRESH: 'Refrescar',
        PRINT: 'Impresión',
        SALES_REP: 'Representante de ventas',
        DATE_RANGE: 'Rango de fechas',
        SALES_REPORT: 'Informes de ventas',
        SERVICE_REPORT: 'Informes de servicio',
        'Activity Report': 'Informe de actividades',
        'Conversion Report': 'Informe de conversión',
        'Call Report': 'Informe de llamadas',
        'Pipeline Report': 'Informe de oleoductos',
        'Customer Activation Report': 'Informe de activación del cliente',
        'Customer Service Type Report': 'Informe de tipo de servicio al cliente',
        'Customer Assessment Report': 'Informe de evaluación del cliente',
        'Customer Leads Assessment Report': 'Informe de evaluación de clientes potenciales',
        'Customer Status Report': 'Informe de estado del cliente',
        'Sales Pipeline Report': 'Informe de canalización de ventas',
        'Days in Franchise Workflow Report': 'Días en informe de flujo de trabajo de franquicia',
        'Franchise Workflow Report': 'Informe de flujo de trabajo de franquicia',
        'Franchise Sales Pipeline Report': 'Informe de canalización de ventas de franquicias',
        'Franchise Employees Report': 'Informe de empleados de franquicia',
        'Franchise Last Contact Report': 'Informe de último contacto de franquicia',
        'InXpress User Baseline Report': 'Informe de referencia del usuario InXpress',
        'Franchise User Report': 'Informe de usuario de franquicia',
        'Sales Summary Report': 'Informe de resumen de ventas',
        ACTIVITY_MATRIX: 'Matriz de actividad',
        'ACTIVITY_TYPE': 'Tipo de actividad',
        'Service Reports': 'Informes de servicio',
        'Project Reports': 'Informes del proyecto',
        'CRM Activity by Account': 'Actividad de CRM por cuenta',
        'Activity by Franchise': 'Actividad por franquicia',
        'Franchise Activity Report': 'Informe de la actividad de la franquicia',
        'Issue Reports': 'Emitir Informes',
        'Customer Service and Sales Rep Listing': 'Servicio al cliente y lista de representantes de ventas',
        GO: 'Ir',
        'CRM Account Details': 'Detalles de la cuenta CRM',
        'Activity by Coach': 'Actividad en entrenador',
        'Franchise Conversion Report': 'Informe de Conversión de Franquicias',
        'Tasks Report': 'Informe de tareas',
        'Email Report': 'Informe de correo electrónico',
        'SMS Report': 'Informe SMS',
        'Orthotic Report': 'Informe el dinero',
        'Greenlight Export': 'Exportación Greenlight',

        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'Cuentas',
            OPPORTUNITIES: 'Oportunidades'
        },
        'Count': 'Contar',
        'Grand Total': 'Gran total',
        ACTIVITIES_BY_TYPE: 'Actividades por tipo',
        ACTIVITIES_BY_SALES_REP: 'Actividades por representante de ventas',
        ALL_CONVERSIONS_BY_STATUS: 'Todas las conversiones por estado',
        CUSTOMERS_BY_SOURCE: 'Clientes por fuente',
        DEALS_BY_SALES_PER: 'Tubería por representante de ventas',
        DEALS_BY_STAGES: 'Ofertas por etapas',
        TOTAL_CALLS_BY_SALES_REP: 'Total de llamadas por el representante de ventas',
        TOTAL_CALL_DURATION_BY_SALES_REP: 'Duración total de la llamada por representante de ventas',
        EXCEL: 'Sobresalir',
        MATRIX_BY_STATUS: 'Matriz por estado',
        MATRIX_BY_SOURCE: 'Matriz por fuente',
        'Status': 'Estado',
        'Source': 'Fuente',
        '5 Ways Report': 'Informe de 5 maneras',
        'Forecast Report': 'Informe de previsiones',
        CASES_BY_STATUS: 'Casos por estado',
        CASES_BY_PRIORITY: 'Casos por Prioridad',
        PROJECTS_BY_STATUS: 'Proyectos por estado',
        PROJECTS_BY_TYPE: 'Proyectos por tipo',
        ISSUES_BY_STATUS: 'Problemas por estado',
        ISSUES_BY_PRIORITY: 'Cuestiones por prioridad',
        SUPPORT_MATRIX: 'Matriz de apoyo',
        CUSTOMER_BY_CASES: 'Clientes por Casos',
        PROJECT_MATRIX: 'Matriz de proyectos',
        ISSUE_MATRIX: 'Matriz de problemas',
        SUB_TOTAL: 'Subtotal',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'Asignado a',
            'Total count': 'Cuenta total',
            'Company': 'Empresa',
            'Assigned To': 'Asignado a',
        },
        COUPONS_NAME: 'Seleccionar cupones',
        PRODUCT_SELECT: 'Seleccionar producto',
        AVAILABLE_REPORTS: 'Reportes Disponibles',
        WORKFLOW_STAGES: 'Etapas',
        CHART_TYPE: 'Tipo de gráfico',
        SHOW: 'Espectáculo',
        VALUE: 'Valor',
        COUNT: 'Contar',
        DEALS_BY_SALES_REP: 'Tubería por representante de ventas',
        PIPELINE:'Tubería',
    },
    FILTER: {
        NAME_REQUIRED: 'Nombre del filtro (requerido)',
        REMOVE_CONDITION: 'Eliminar condición',
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'Criterios para objetos asociados',
        //not found in doc
        NEXT_BUTTON_TEXT: 'Siguiente',
        PREVIOUS_BUTTON_TEXT: 'Anterior',
        AND: 'Y',
        OR: 'O',
        ADD_ANOTHER: 'Agrega otro',
        ADD_CONDITION: 'Agregar condición',
        COPY_FILTER: 'Copia de ',
        FROM_DATE: 'Desde la fecha (From Date)',
        TO_DATE: 'Hasta la fecha (To Date)',

        CONTAINS: 'contiene',
        DOES_NOT_CONTAINS: 'no contiene',
        IS_EQUAL_TO: 'es igual a',
        IS_NOT_EQUAL_TO: 'no es igual a',
        IS_EMPTY: 'está vacío',

        IS_GREATER_THAN: 'es mayor que',
        IS_GREATER_THAN_OR_EQUAL_TO: 'es mayor o igual que',
        IS_LESS_THAN: 'es menor que',
        IS_LESS_THAN_OR_EQUAL_TO: 'es menor o igual a',
        'Contacts': 'Contactos',
        'Contacts without Groups': 'Contactos sin grupos',
        'My Contacts': 'Mis contactos',
        'Contacts without Account': 'Contactos sin cuenta',
        'Todays Contacts': 'Contactos de hoy',
        'Contacts without Accounts': 'Contactos sin cuentas',
        'All records': 'Todos los registros',
        'Added this Month': 'Añadido este mes',
        'Added this Week': 'Agregado esta semana',
        'My Opportunities': 'Mis oportunidades',
        'Opportunities': 'Oportunidades',
        'Accounts': 'Cuentas',
        'Accounts without Contact': 'Cuentas sin contacto',
        'Accounts without Groups': 'Cuentas sin grupos',
        'My Accounts': 'Mis cuentas',
        'Todays Accounts': 'Cuentas de hoy',
        'Cases': 'Casos',
        'Projects': 'Proyectos',
        'Issues': 'Cuestiones',
        'Accounts without Contacts': 'Cuentas sin Contactos',
    },
    SOURCE: {
        SOURCE_NAME: 'Nombre de la fuente'
    },
    INXPRESS_REPORT: {
        COUNTRY_SELECT: 'Seleccionar país',
        FRANCHISE_SELECT: 'Seleccionar franquicia',
        WORKFLOW_SELECT: 'Seleccionar flujo de trabajo',
        START_DATE: 'Fecha de inicio',
        NO_RECORDS_FOUND: 'No se encontrarón archivos.',
        'Account': 'Cuenta',
        // 'Company Name': 'nombre de empresa',
        'Sales Rep': 'Representante de ventas',
        'Workflow': 'Flujo de trabajo',
        'Status': 'Estado',
        REGION_SELECT: 'Seleccione región',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'Flujo de trabajo',
        USER: 'Usuario',
        DURATION: 'Duración',
        SORT_BY: 'Ordenar por',
        REPORT_BY: 'Informe por',
        'Forecast amount': 'Cantidad prevista',
        'Close date': 'Cerrar fecha',
        'Sales rep': 'Representante de ventas',
        'Status': 'Estado',
        'Convert': 'Convertir',
        'Edit': 'Editar',
        'Delete': 'Borrar',
        'opportunities': 'Oportunidades',
        'Probability': 'Probabilidad',
    },
    APP: {
        CURRENT: 'Usaría la nueva versión',
        LEGACY: 'Yo usaría la versión heredada',
        HEADING: 'Por favor, elija una versión Soffront',
        SUB_TITLE: 'Su organización tiene acceso a nuestra versión heredada. ¿Le gustaría usarlo en lugar de la nueva versión?'
    },
    OTHER_VIEW: {
        SUBSCRIBE_LABEL: 'Suscribir'
    },
    MY_INTEGRATION: {
        CONNECTING: 'Conectando',
        DISCONNECTING: 'Desconectando',
        SYNC_EMAIL: 'Sincronizar correos electrónicos',
        SYNC_CONTACT: 'Sincronizar contactos',
        SYNC_CALENDAR: 'Sincronizar calendario',
        APPEND_COMPANY: 'Añadir nombre de la empresa',
        APPEND_CONTACT: 'Añadir nombre de contacto',
        CALENDAR: 'Calendario',
        SERVICE: 'Servicio',
        INTEGRATION_HEADER: 'Integración de correo electrónico y calendario',
        PHONE_INTEGRATION: 'Integración telefónica',
        MY_INTEGRATIONS: 'Integraciones',
        CONTACT_FILTER: 'Filtro de contacto',
        RINGCENTRAL_HEADER: 'Ring Central Integration',
        RING_CENTRAL: 'RingCentral',
        SMS_INTEGRATION: 'Integración SMS',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'integrar',
        SELECT_INTEGRATION_ITEMS:'seleccionar los elemento para integrar',
        INVOICES:'Facturas',
        PAYMENTS:'Pagos',
        MANNUALLY:'Manualmente (CRM> Xero)',
        AUTOMATICALLY:'Automáticamente (Xero> CRM)',
        ACCOUNTING_INTEGRATION_LABEL: 'Integrar con',
        ACCOUNTING_INTEGRATION: 'Integración contable',
        QUICKBOOKS_INTEGRATION: 'Integración QuickBooks',
        EVENTBRITE_INTEGRATION: 'Integración Eventbrite',
        SELECT_A: 'Seleccione un',
        CRM_CONTACT_FILTER_SELECT: 'Seleccione un filtro de contacto de CRM',
        CONTACT_GROUP_NOT_FIND: 'No se encuentra el grupo de contacto. Por favor haga clic',
        HERE: 'Aquí',
        TO_TRY_AGAIN: 'para intentarlo de nuevo.',
        CONTACT_FILTER_NOT_FIND: 'No se encuentra ningún filtro de contacto.',
        SEND_SALES_EMAILS_USING: 'Enviar correos electrónicos de ventas usando',
    },
    PROFILE_DETAILS: {
        PROFILE_DETAILS_LABEL: 'Perfil',
        PERSONAL_INFORMATION: 'Informacion personal',
        USERNAME_LABEL: 'Esto se usará como nombre de usuario.',
        CHANGE_PASSWORD: 'Cambia la contraseña',
        MANAGE_NOTIFICATIONS: 'Administrar notificaciones',
        EMAIL_SIGNATURE: 'Firma de email',
        TASKS: 'Tareas',
        APPOINTMENTS: 'Citas',
        TASK_EMAIL: 'Correo electrónico de tarea',
        TASK_POPUP: 'Tarea emergente',
        APPOINTMENT_EMAIL: 'Citas Correo electrónico',
        APPOINTMENT_POPUP: 'Citas emergentes',
        ON: 'En',
        OFF: 'Apagado',
        CHANGE_PHOTO: 'Cambiar Fotografía',
        NEW_PASSWORD: 'Nueva contraseña',
        CONFIRM_PASSWORD: 'Confirmar nueva contraseña',
        NAME_REQUIRED: 'Nombre (requerido)',
        EMAIL_REQUIRED: 'correo electronico (requerido)',
        PHONE: 'Teléfono',
        TIME_ZONE_REQUIRED: 'Huso horario (requerido)',
        DATE_FORMAT: 'Formato de fecha',
        LANGUAGE: 'Idioma',
        USER_COLOR: 'Color de usuario',
    },
    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'Todas',
        USER: 'Usuario',
        SYSTEM: 'Sistema',
        MY: 'Mi',
        ATTACHMENT_INFO: 'Información de datos adjuntos',
    },
    DOCUSIGN: {
        CONNECT_BUTTON: 'Conectar',
        SAVE_BUTTON: 'Salvar',
        CANCEL_BUTTON: 'Cancelar',
        USERNAME: 'Nombre de usuario (requerido)',
        PASSWORD: 'Se requiere contraseña)',
        INTREGATOR_KEY: 'Clave de integrador (obligatorio)',
        SELECT_TEMPLATE: 'Seleccionar plantilla',
        SEND: 'Enviar',
        DISCONNECT_BUTTON: 'Desconectar',
        DOCUSIGN_SETUP_HEADER: 'Integración de DocuSign',
        DOCUSIGN_CONNECT_TITLE: 'Conéctese a su cuenta de DocuSign',
        ADD_NOTE: 'Agregue un comentario (opcional).',
    },
    TWILIO: {
        CALL: 'Llamada',
        VERIFY: 'Verificar',
        DISCONNECT: 'Desconectar',
        SHOW_SCRIPTS: 'Mostrar guiones',
        HIDE_SCRIPTS: 'Ocultar guiones',
        CALL_SETUP: 'Establecimiento de llamada',
        CALL_SCRIPT: 'Guión de llamada',
        CALL_SCRIPT_COPY_TAB_LABEL: 'Copiar secuencia de comandos de llamada',
        COPY_OF: 'Copia de',
        ADD_NUMBERS: 'Agregue sus números de teléfono salientes',
        OUTBOUND_PHONE_NUMBERS: 'Números telefónicos salientes',
        ADD_ANOTHER_NUMBER: 'Agrega otro número',
        CALL_RECORDING: 'Grabacion de llamada',
        PHONE_CREDITS: 'Sus créditos telefónicos',
        PURCHASE_CREDITS_YOU_HAVE: 'Tienes',
        PURCHASE_CREDITS_MINUTES_LEFT: 'minutos restantes.',
        PURCHASE_CREDITS_MORE: 'Compra más créditos',
        CALLING: 'Vocación',
        RECORD_ALL_CALL: 'Grabar todas las llamadas',
        VERIFIED: 'Verificado',
        CLICK_TO_CALL: 'Estoy listo para tomar la llamada',
        ADDITIONAL_INFO: 'Mostrar información adicional',
        ADDITIONAL_INFO_TITLE: 'Información Adicional',
        SELECT_FIELD:'Seleccionar campos (máximo 3 campos)'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'Nombre del script (requerido)',
        ADD_DETAILS: 'Detalles del script (obligatorio)',
        WORKFLOW_NAME: 'Nombre del flujo de trabajo',
        CALL_SCRIPTS_EDIT_TAB_LABEL: 'Editar scripts de llamadas',
    },
    TWILIO_SMS: {
        ZIP_CODE: 'Código postal',
        AREA_CODE: 'Codigo de AREA',
        ADD_TO_ACCOUNT: 'Añadir',
        SMS_CREDITS: 'Sus créditos SMS',
        SMS_CREDITS_LEFT: 'Créditos SMS restantes.',
        SEND: 'Enviar',
        NO_NUMBER_PURCHASED: 'Aún no tienes números de teléfono. Use la opción de búsqueda para comprar números telefónicos. Después de comprar, podrá enviar mensajes usando los números.',
        FIND_PHONE_NUMBER: 'Encuentre los números de teléfono por código postal o código de área de teléfono',
        FIND_PHONE_NUMBER_BY_AREA_CODE: 'Buscar números de teléfono por código de área',
        SEND_SMS: 'Envía un mensaje de texto',
        YOUR_MESSAGE: 'Tu mensaje',
        OR: 'O',
        PHONE_NUMBER: 'Número de teléfono',
        PHONE: 'Teléfono',
        AVAILABLE_TO: 'Disponibles para',
        USER_NAME: 'Nombre de usuario',
        USED_BY: 'Usado por',
        UNIT_NAME: 'Nombre de la unidad',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: 'Conectar',
        LABEL: 'Conéctese a su cuenta de QuickBooks Online'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'Todos los diseños',
        LEFT_SIDEBAR_LAYOUT: 'Diseño de la barra lateral izquierda',
        RIGHT_SIDEBAR_LAYOUT: 'Diseño de la barra lateral derecha',
        HORIZONTAL_LAYOUT: 'Diseño horizontal',
        MULTIPLE_COLUMN_LAYOUT: 'Diseño de columna múltiple'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: 'Conectar',
        SELECT_ORGANISATIONS: 'Sincroniza eventos de esta organización',
        EVENTBRITE_CONNECT_TITLE: 'Conéctese a su cuenta Eventbrite',
        SELECT: 'Seleccionar',
        EVENTBRITE_TOGGLE_TITLE: 'Durante la sincronización, agregue nuevos contactos si no se encuentra en CRM',
        EVENTBRITE_MAP_FIELDS: 'Campos del mapa',
        CRM_FIELDS_TITLE: 'Campos de contacto CRM',
        EVENTBRITE_FIELDS_TITLE: 'Campos Eventbrite',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'Calendario / Tareas',
        DASHBOARD: 'Tablero',
        REPORTS: 'Informes',
        SALES_TEMPLATES: 'Plantillas de correo electrónico de ventas',
        SOCIAL_MARKETING: 'Mercadeo social',
        WEBSITE_TRACKING: 'Analítica del sitio web',
        SOLUTIONS: 'Soluciones',
        CONTACTS: 'Contactos',
        ACCOUNTS: 'Cuentas',
        OPPORTUNITIES: 'Oportunidades',
        CASES: 'Casos',
        PROJECTS: 'Proyectos',
        ISSUES: 'Cuestiones',
        ONBOARDING: 'A bordo',
        UNITS: 'Unidades',
        ASSESTS: 'Bienes',
        OPERATIONS_ACTIVITIES: 'Ocupaciones',
        OPERATIONS_CONVERSIONS: 'Conversiones',
        OPERATIONS_CAMPAIGNS: 'Campañas',
        OPERATIONS_REPORTS: 'Informes',
        LEAD_CAPTURE: 'Captura de plomo',
        EMAIL_MARKETING: 'Correo de propaganda',
        'CALENDAR/TASKS': 'Calendario / Tareas',
        'SALES REPORTS': 'Informes',
        'SALES TEMPLATES': 'Plantillas de ventas',
        'SOCIAL MARKETING': 'Mercadeo social',
        'ONLINE REPUTATION': 'Reputación en línea',
        DASHBOARDS: 'Cuadros de mando',
        'SALES LEADS': 'Líder en ventas',
        'USEFUL LINKS': 'Enlaces útiles',
        HUB: 'HUB',
        LIBRARY: 'Biblioteca',
        COMMUNITY: 'Comunidad',
        TRAINING: 'Formación',
        NEWS: 'Noticias',
        SCROLL_TO_RIGHT: 'Desplazarse hacia la derecha',
        SCROLL_TO_LEFT: 'Desplazarse a la izquierda',
        Royalties: 'Regalías',
        INBOX: 'Bandeja de entrada',
    },
    NO_RECORD: {
        'Solutions': 'Soluciones',
        'Appointments': 'Citas',
        'Tasks': 'Tareas',
        'Groups': 'Los grupos',
        'Notes': 'Notas',
        'Attachments': 'Archivos adjuntos',
        'Marketing-templates': 'Plantillas de marketing',
        'Sales-templates': 'Plantillas de ventas',
        'Web-forms': 'Formularios web',
        'Products': 'Productos',
        'Broadcast-campaigns': 'Campañas de difusión',
        'Ab-campaigns': 'Ab-campañas',
        'Automation-designer': 'Diseñador de automatización',
        'Projects': 'Proyectos',
        'Invoices': 'Facturas',
        'Touch-campaigns': 'Campañas táctiles',
        'Events': 'Eventos',
        'Sms-templates': 'Plantillas SMS',

        'Emails': 'Correos electronicos',
        'Relationships': 'Relaciones',
        'Templates': 'Plantillas',

        'Cases': 'Casos',
        'Contacts': 'Contactos',
        'Accounts': 'Cuentas',
        'Opportunities': 'Oportunidades',
        'Issues': 'Cuestiones',
        'Activities': 'Ocupaciones',
        'Conversions': 'Conversiones',
        'Salesleads': 'Líder en ventas',
        'Source': 'Fuente',
        'Users': 'Usuarios',
        'Securitylevel': 'Niveles de seguridad',
        'Workflows': 'Flujos de trabajo',
        'Relations': 'Relaciones',
        'Notetypes': 'Tipos de notas',
        'Campaigntypes': 'Tipos de campaña',
        'Emailintegration': 'Alias ​​de correo electrónico',
        'Library': 'Biblioteca',
        'Recyclebin': 'Cuentas',
        'Sms': 'SMS',
        'Assignmentrules': 'Reglas de asignación',
        'Units': 'Unidades',
        'Orderitems': 'Encargar artículos',
        'Claims': 'Reclamación (es',
        'Payments': 'Pagos',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'Calendario de informes',
        DAY_OF_THE_MONTH: 'día del mes',
        DAY_OF_THE_WEEK: 'día de la semana',
        REPEAT_TYPE: 'Tipo de repetición (requerido)',
        REPEAT_ON: 'Repita el',
        REPEAT_BY: 'Repita por',
        START_DATE: 'Fecha de inicio (requerido)',
        END_DATE: 'Fecha de finalización',
        REPEAT_EVERY: 'Repita cada (requerido)',
        SEND_TO: 'Enviar a (requerido)',
        SCHEDULE: 'Programar',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': 'Nombre',
            'Status': 'Estado',
            'Created By': 'Creado por',
            'Created On': 'Creado en',
            'Updated By': 'Actualizado por',
            'Updated On': 'Actualizado en',
        },
        APPOINTMENT: {
            'Subject': 'Tema',
            'Company': 'Empresa',
            'Status': 'Estado',
            'Contact': 'Contacto',
            'Contact Name': 'Nombre de contacto',
            'Start date': 'Fecha de inicio',
            'End date': 'Fecha final',
            'Assign to': 'Asignar a',
            'Visible to': 'Visible a',
            'Outlook Sync': 'Sincronización de Outlook',
            'Google Sync': 'Google Sync',
            'Text': 'Texto',
            'Creator': 'Creador',
            'Created on': 'Creado en',
            'Updated by': 'Actualizado por',
            'Updated on': 'Actualizado en',
        },
        TASKS: {
            'Task Name': 'Nombre de la tarea',
            'Company': 'Empresa',
            'Status': 'Estado',
            'Previous Task': 'Tarea previa',
            'Complete Date': 'Fecha completa',
            'Owner': 'Propietario',
            'Due By': 'Debido por',
            'Contact Name': 'Nombre de contacto',
            'Description': 'Descripción',
            'Updated on': 'Actualizado en',
            'Contact': 'Contacto',
            'Start Time': 'Hora de inicio',
            'End Time': 'Hora de finalización',
            'Item': 'ít',
            'Billable': 'Facturable',
        },
        BROADCAST: {
            'Broadcast Name': 'Nombre de transmisión',
            'Status': 'Estado',
            'Run Date': 'Fecha de ejecución',
            'Owner': 'Propietario',
            'Created On': 'Creado en',
            'Updated On': 'Actualizado el',
            'Target': 'Objetivo',
            'From email': 'Desde el correo electrónico',
            'From name': 'De Nombre',
            'Updated By': 'Actualizado por',
            'Created By': 'Creado por'
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'Nombre de la campaña',
            'Created By': 'Creado por',
            'Created On': 'Creado en',
            'Run Date': 'Fecha de ejecución',
            'Status': 'Estado',
            'Target Filter': 'Filtro de destino',
            'Updated By': 'Actualizado por',
            'Updated On': 'Actualizado el'
        },
        SALES_TEMPLATE: {
            'Name': 'Nombre',
            'Category': 'Categoría',
            'Subject': 'Asunto',
            'Status': 'Estado',
            'Owner': 'Propietario',
            'Created On': 'Creado en',
            'Updated By': 'Actualizado por',
            'Updated On': 'Actualizado en',
        },
        MARKETING_TEMPLATE: {
            'Name': 'Nombre',
            'Category': 'Categoría',
            'Subject': 'Asunto',
            'Status': 'Estado',
            'Owner': 'Propietario',
            'Created On': 'Creado en',
            'Updated By': 'Actualizado por',
            'Updated On': 'Actualizado en',
        },
        WEB_FORM: {
            'Name': 'Nombre',
            'Table Name': 'Nombre de la tabla',
            'Group': 'Grupo',
            'Source': 'Fuente',
            'Status': 'Estado',
            'Assign To': 'Asignar a',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'Nombre de la campaña',
            'Status': 'Estado',
            'Owner': 'Propietario',
            'Target': 'Objetivo',
            'From email': 'Desde el correo electrónico',
            'Created On': 'Creado en',
            'Created By': 'Creado por',
            'From name': 'Nombre de',
            'Updated On': 'Actualizado en',
            'Updated By': 'Actualizado por',
            'Resend All': 'Reenviar todos',
            'Resend After': 'Reenviar después de',
        },
        ACCOUNTS: {
            'Status': 'Estado',
        },
        CONTACTS: {
            'Status': 'Estado',
        },
        OPPORTUNITIES: {
            'Status': 'Estado',
        },
        PROJECTS: {
            'Status': 'Estado',
        },
        ISSUES: {
            'Status': 'Estado',
        },
        CASES: {
            'Status': 'Estado',
        },
        SOLUTIONS: {
            'Status': 'Estado',
        },
        SALES_LEADS: {
            'Status': 'Estado',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': 'Cita',
        'Dialed': 'Marcado',
        'Email': 'Correo electrónico',
        'LVM': 'LVM',
        'Note': 'Nota',
        'Spoke': 'Habló',
        'Task': 'Tarea',
        'Transferred': 'Transferido',
    },
    VIEW_TOGGLE: {
        LIST: 'Lista',
        COLUMN: 'Columnas',
        LIST_TITLE: 'Cambiar a vista de lista',
        COLUMN_TITLE: 'Cambiar a vista de columna',
        TIMELINE: 'línea de tiempo',
        TIMELINE_TITLE: 'Cambiar a la vista de línea de tiempo',
        DETAILS: 'Detalles',
        DETAILS_TITLE: 'Cambiar a la vista de detalles',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: 'Informe de marketing por correo electrónico',
        MY_CAMPAIGNS: 'Mis campañas',
        MY_LOCATION_CAMPAIGNS: 'Campañas de mi unidad',
        RECENT_CAMPAIGNS: 'Campañas recientes',
        BROADCAST_REPORT: 'Informe de campaña de difusión',
        TOUCH_REPORT: 'Informe de la campaña táctil'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'Empujar',
        DOCUMENT_NOT_FOUND: 'No se ha encontrado ningún documento.'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: 'Solicitar un nuevo informe'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'Campañas de correo electrónico',
        BROADCAST_REPORT: 'Campañas de difusión',
        TOUCH_REPORT: 'Campañas táctiles'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'Informe de tareas'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'Informe de correo electrónico',
        ERROR_REASON: 'Razón'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'Informe SMS'
    },
    BATCH_SMS: {
        MESSAGE: 'Tu mensaje',
        SEND: 'Enviar',
        CHARS: ' caracteres',
        SCHEDULE: 'Calendario',
        SCHEDULE_SMS: 'Programar mensaje de texto',
        SEND_LATER: 'Envíalo después'
    },
    SETTING_MENU: {
        MARKETING: 'Márketing',
        SECURITY: 'Usuarios y Seguridad',
        DATA: 'Datos',
        CUSTOMIZATION: 'Personalización',
        INTEGRATION: 'Integración',
        COMPANY: 'Empresa',
        WORKFLOW_RULES:'Flujos de trabajo y reglas',
        LISTS: 'Liza',
        FRANCHISE: 'Franquicia',
        COMPANY_SCHEDULER: 'Programador de la empresa'
    },
    MARKETING_SETTING: {
        'SOURCE': 'Fuentes',
        'LIBRARY': 'Biblioteca',
        'LEAD_SCORE': 'Puntajes de plomo',
    },
    SECURITY_SETTING: {
        'USERS': 'Usuarios',
        'SECURITY_LEVELS': 'Niveles de seguridad'
    },
    DATA_SETTING: {
        'EXPORT': 'Exportar',
        'RECYCLE_BIN': 'Papelera de reciclaje',
        'FIND_DUPLICATES': 'Encontrar duplicados'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'Cambiar Logo',
        'DATA_BASE': 'Base de datos',
        'WORKFLOWS': 'Flujos de trabajo',
        'RELATIONS': 'Relaciones',
        'AUTO_FILL_VALUES': 'Valores de Autocompletar',
        'NOTE_TYPES': 'Tipos de tareas y notas',
        'CAMPAIGN_TYPES': 'Tipos de campaña',
        'CORPORATE_CURRENCY': 'Moneda corporativa',
        'HUB_SETTING': 'CENTRO',
        'COMPANY_PROFILE': 'Perfil de la empresa',
        'WHITE_LABEL': 'Etiqueta blanca',
        'MENU': 'Menú',
        'DESKTOP_MENU': 'Menú de escritorio',
        'MOBILE_MENU': 'Menú móvil',
        'PHONE_NUMBERS': 'Números de teléfono',
        'DESIGN_MENUS': 'Menus de diseño',
        'MENU_NAME': 'Nombre del menú',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook (Escritorio)',
        'GENERATE_API': 'Generar API',
        'EMAIL_INTEGRATION': 'Integración de correo electrónico'
    },
    SETTINGS: {
        ADDED: 'adicional',
        EDIT: 'actualizado',
        SUCCESSFULL: 'Exitosamente.',
        'SOURCE': 'Fuente',
        'NOTETYPES': 'Tipos de tareas y notas',
        'CAMPAIGNTYPES': 'Tipo de campaña',
        'CORPORATECURRENCY': 'Moneda corporativa',
        'RELATIONS': 'Relación',
        'LIBRARY': 'Biblioteca',
        'USERS': 'Usuarios',
        'SECURITYLEVEL': 'Niveles de seguridad',
        'WORKFLOWS': 'Flujos de trabajo',
        'EMAILINTEGRATION': 'Alias ​​de correo electrónico',
        'RECYCLEBIN': 'Papelera de reciclaje',
        'ASSIGNMENTRULES': 'Reglas de asignación',
        'APPOINTMENTTYPES': 'Tipo cita',
        'ROLLUP': 'Reglas acumuladas',
        'SUBSCRIPTION': 'Suscripción',
        'UPGRADE': 'suscripción de actualización',
        'BUYPRODUCT': 'Comprar los productos y Formación',
        'BUYPROSERVICE': 'Comprar Servicios profesionales',
        'BILLING': 'Facturación y Detalles de la factura',
        'CANCELSUB': 'Cancelar suscripción',
        'SUBDETAIL': 'Detalles de la suscripción',
        'PURCHESEMAILCREDIT': 'Los créditos de compra por correo electrónico',
        'PURCHESPHONECREDIT': 'Créditos compra del teléfono',
        'PURCHESSMSCREDIT': 'Créditos compra SMS',
        'PURCHESCARDCREDIT': 'Créditos tarjeta de compra de exploración',
        'PURCHESCONTACTCREDIT': 'Compra Más Contactos',
        'PURCHESTRANINGCREDIT': 'Formación de compra',
        'PURCHESMERKETINGSERVICE': 'Comprar Marketing Services',
        'PURCHESPROFESSONALSERVICE': 'Comprar Servicios profesionales',
        'SETUP': 'Mi configuración',
        SHOW_LOGO: 'Mostrar logo',
        SHOW_SIGNOUT_LINK: 'Mostrar enlace de cierre de sesión',        
        THEME_COLOR:'Color del tema',
        LOGO_COLOR :'Fondo del logotipo',
        AUDIT_QUESTIONS: 'Lista de verificación de auditoría',
        LMS_USERS: 'Usuarios de LMS',
        AUDIT_CHECKLISTS:'Listas de verificación de auditoría',
        WEBHOOKS: 'Ganchos web',
    },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: 'Seleccione una moneda corporativa'
    },
    UNSUBSCRIBE: {
        EMAILS: 'Correos electronicos',
        TEXTS: 'Textos',
        BOTHS: 'Ambas'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'Nombre del concentrador',
        USEFUL_LINK: 'Enlaces útiles',
        CASES: 'Casos',
        SETUP_NAME: 'Configuración de HUB',
        NEWS: 'Noticias',
        LIBRARY: 'Biblioteca',
        COMMUNITY: 'Comunidad',
        TRAINING: 'Formación',
        HYPER_LINK: 'hiperenlace',
        SET: 'Conjunto',
    },
    UNITS: {
        TASK_DETAILS: 'Detalles de tareas',
        'Unit': 'unidad',
        'Contact Name': 'Nombre de contacto',
        'Done': 'Hecho',
        'Open': 'Abierto',
        'Tasks Name': 'Nombre de las tareas',
        'Assigned To': 'Asignado a',
        'Due By': 'Debido por',
        'Status': 'Estado',
        UNIT_DETAILS_LABEL: 'Detalles de la unidad',
        FIRST_NAME: 'Primer nombre (requerido)',
        LAST_NAME: 'Apellido Obligatorio)',
        PASSWORD: 'Se requiere contraseña)',
        RETYPE_PASSWORD: 'Vuelva a escribir la contraseña (obligatorio)',
        EMAIL: 'correo electronico (requerido)',
        PHONE: 'Telefono (requerido)',
        COMPANY: 'Empresa (requerido)',
        TIME_ZONE_SELECT: 'Zona horaria',
        COUNTRY_SELECT: 'País',
        STATE_SELECT: 'Estado',
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: 'Configura tu primera regla',
        ASSIGNMENT_RULE: 'Regla de asignación',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: 'Crear regla de asignación',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: 'Editar regla de asignación',
        NAME_REQUIRED: 'Nombre (requerido)',
        ASSIGNMENT_RULES: 'Reglas de asignación',
        STATUS: 'Estado',
        SETUP_RULE: 'Regla de asignación de configuración',
        USE_RULE: 'Usar regla de asignación',
        TABLE_NAME: 'Nombre de la tabla',
        SELECT_UNIT_WORKFLOW: 'seleccione unidad de flujo de trabajo',
        WORKFLOW_NAME: 'Nombre del flujo de trabajo',
    },
    COUPONS: {
        PREFIX: 'Prefijo',
        'Coupon Name': 'Nombre del cupón',
        'Discount Value': 'Valor de descuento',
        EXPIRATION_DATE: 'Fecha de caducidad',
        PODUCT_SERVICE: 'Producto / Servicio (opcional)'
    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'Informe de reclamos de cupón',
        COUPONS_REPORT: 'Informe' // Report              
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'Informe de tuberías',
        SALES_SUMMARY_REPORT: 'Informe de resumen de ventas'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'Alertas y Notificaciones',
        NOTIFY_ME: 'Notificarme cuando...',
        REMIND_ME: 'Recuérdame antes...',
        NOTIFY_EMAIL_OPEN: 'Mi correo electrónico está abierto',
        NOTIFY_EMAIL_CLICK: 'Se hace clic en mi correo electrónico',
        NOTIFY_TEXT_REPLY: 'Mi texto es respondido',
        REMIND_UPCOMING_APPOINTMENT: 'Próximas citas',
        REMIND_UPCOMING_TASK: 'Próximas tareas',
        ADD_NEW_REMINDER: 'Añadir un nuevo recordatorio',
        REMIND: 'Recordar',
        DAY_BEFORE: 'día(s) antes de',
        MY_WEB_FORM_SUBMITTTED: 'Mi formulario web se envía',
        NO_DATE_FIELD: 'No hay campos de fecha disponibles',
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'Alias ​​de correo electrónico',
    },
    NEWS: {
        HEADER: 'Noticias',
        STATUS: {
            DRAFT: 'Borrador',
            PUBLISHED: 'Publicado'
        },
        EDIT_TAB_LABEL: 'Editar noticias',
        ADD_TAB_LABEL: 'Agregar noticias',
        CATEGORY_REQUIRED: 'Categoría (requerida)',
        STATUS_REQUIRED: 'Estado (requerido)',
        TITLE_REQUIRED: 'Título (requerido)',
        SUMMARY_REQUIRED: 'Resumen (requerido)',
        EXTERNAL_LINK_REQUIRED: 'Enlace externo (requerido)',
        UPLOAD_IMAGE: 'Subir una imagen',
        EXTERNAL_LINK: 'Enlace externo',
        ENTER_DETAILS: 'Ingrese detalles'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: 'Enlaces útiles',
        CATEGORY: 'Categoría',
        LINK: 'Enlace',
        TITLE: 'Título',
        NO_RECORD_FOUND: 'No se han encontrado enlaces útiles.',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: 'Informe ortopédico'
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'No se ha encontrado ningún informe.',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: 'Seleccionar un padre',
        SELECT_CHILD: 'Seleccione un niño',
        PARENT_FIELDS: 'Campos de objetos principales',
        CHILD_FIELDS: 'Campos de objetos secundarios',
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'Página de destino',
        LANDING_PAGES: 'Páginas de destino',
        NAME_REQUIRED: 'Nombre (requerido)',
        URL_REQUIRED: 'URL (requerido)',
        LANDING_PAGE_FORMS: 'Generador de formularios',
        EDIT_LANDING_PAGE: 'Editar página de destino',
        COPY_LANDING_PAGE: 'Copiar página de destino'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: 'Buscar duplicados',
        SELECT_OBJECT: 'Seleccionar objeto',
        SELECT_FIELD: 'Seleccionar campo',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: 'Programador de citas',
        APPOINTMENT_INFO: 'Información de la cita',
        AVAILABILITY:'Disponibilidad',
        APPOINTMENT_TYPES:'Tipos de citas',
        MY_AVAILABILITY: 'Mi disponibilidad',
        SCHEDULER_LINK: 'Enlace del programador',
        INTAKE_FORM: 'Formulario de admisión',
        APPOINTMENT_NAME: 'Nombre de la cita (obligatorio)',
        LOCATION: 'Ubicación',
        TASKS:'Tareas',
        UNIT_CRM:'unidad crm',
        NOTIFICATION_SETUP:'Configuración de notificación',
        DURATION: 'Duración',
        MIN_ADVANCE_NOTICE: 'Aviso mínimo de anticipo ',
        DESCRIPTION: 'Descripción',
        COPY_TO_CLIPBOARD: 'Copiar en mi portapapeles',
        SCHEDULER_LINK_TITLE: 'Enlace de mi programador de citas',
        SCHEDULING_PAGE: 'Programador de citas',
        ADD_BUTTON: 'Añadir una nueva programación',
        MY_SCHEDULAR_LINK: 'Mi enlace de programador',
        MY_APPOINTMENT_TYPE: 'Mi tipo de cita',
        APPOINTMENT_TYPE: 'Tipo de cita',
        DEFINE_YOUR_INTAKE_FORM: 'Defina su forma de admisión',
        REQUIRED: 'Necesario',
        ADD_ANOTHER_FIELD: 'Agregar otro campo',
        SUBMIT_BUTTON_LABEL: 'Enviar etiqueta de botón',
        CHOOSE_A_STATUS_FOR_LEAD: 'Elija un estado para los clientes potenciales agregados desde el programador',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'Defina su mensaje de agradecimiento',
        THANK_YOU_MESSAGE: 'Mensaje de agradecimiento',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: 'Matriz de relación',
        REPORT_SAVE_LABEL: 'Guardar personalización',
        REPORT_NAME_LABEL: 'Nombre de informe personalizado',
        REPORT_NAME_TEXTFIELD_LABEL: 'Reportar nombre',
        ROW_FIELD_LABEL: 'Campo de fila',
        COL_FIELD_LABEL: 'Campo de columna',
        DATE_FIELD_LABEL: 'Campo de fecha',
        PDF_LABEL_DURATION: 'Duración',
        RELATION_FIELD_LABEL: 'Relación',
        SAVE_CUSTOMIZATION_LABEL: 'Guardar personalización',
        RUN_BUTTON_LABEL: 'correr'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'Sube tu logotipo',
        WEBSITE: 'Sitio web',
        BUSINESS_NAME: 'Nombre de la empresa',
        BUSINESS_ADDRESS: 'Dirección comercial',
        BUSINESS_PHONE: 'Teléfono de negocios',
        BUSINESS_EMAIL: 'Correo electrónico comercial',
        CORPORATE_CURRENCY: 'Moneda corporativa'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: 'Exportar',
        FILE_NAME_REQUIRED: 'Nombre de archivo (requerido)',
        PARENT_OBJECT_REQUIRED: 'Nombre del objeto principal (obligatorio)',
        FILE_TYPE_REQUIRED: 'Tipo de archivo (requerido)',
        QUERY_NAME_REQUIRED: 'Nombre de la consulta (obligatorio)',
        SELECTED_FIELDS_FOR_PARENT: 'Campos seleccionados para padres',
        AVAILABLE_FIELDS_FOR_PARENT: 'Campos disponibles para padres',
        CHILD_OBJECT_NAME: 'Nombre del objeto secundario',
        AVAILABLE_FIELDS_FOR_CHILD: 'Campos disponibles para niño',
        SELECTED_FIELDS_FOR_CHILD: 'Campos seleccionados para niño',
        EXPORT_BUTTON: 'Exportar',
        DOWNLOAD_BUTTON: 'descargar archivo',
        ADD: 'Añadir',
        ADD_ALL: 'añadir todo',
        REMOVE: 'Eliminar',
        REMOVE_ALL: 'Eliminar todo'
    },
    QUEUE: {
        QUEUE: 'Cola',
        PREVIOUS: 'Anterior en la cola',
        NEXT: 'Siguiente en la cola',
        REMOVE: 'Eliminar de la cola',
        CLOSE: 'Salir de la cola',
    },
    SECURITY_LEVEL: {
        ACCESS: 'Acceso',
        DESCRIPTION: 'Descripción',
        VIEW: 'Ver',
        PERMISSIONS: 'Permisos',
        OPERATIONS: 'Operaciones',
        ADMINISTRATION: 'Administración',
        ASSIGNED_MENU: 'Menu asignado',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: 'objeto niño',
        PARENT_OBJECT_NAME: 'objeto de los padres',
        CHILD_FIELD_NAME: 'Niño Nombre del campo',
        PARENT_FIELD_NAME: 'Padres Nombre del campo',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'Informe de regalías',
        ROYALTY_PERCENT: 'Regalías %',
        SHOW: 'Mostrar',
        ALL_INVOICES: 'Todas las facturas',
        PAID_INVOICE_ONLY: 'Solo facturas pagadas',
        SETUP_TITLE: 'Configuración de regalías',
        PAID_INVOICES: 'Facturas pagadas',
        USE: 'Uso',
        FIXED: '% fijo',
        VARIABLE: '% variable',
        TOTAL_INVOICES: 'Facturas totales',
        TOTAL_PAYMENTS: 'Pagos totales',
        CALCULATE: 'Calcular',
        WEEKLY: 'Semanal',
        MONTHLY: 'Mensual',
        WEEK_TYPE: 'Tipo de semana',
        MON_TO_FRI: 'Lunes - viernes',
        MON_TO_SUN: 'Mon - sol',
        ADD_INVOICES: 'Añadir factura',
        TOTAL_Reported_Sales: 'Ventas totales reportadas',
        YEAR: 'Año',
        MARKETING_FEE: 'Tarifa de marketing',
        TECH_FEE: 'Tarifa tecnológica',
        PERCENT: 'Porcentaje',
        FIXED_VALUE: 'Fijado',
        WITH: 'Con tarifas',
        WITH_OUT: 'Sin tarifas',
        Reported_Sales_Franchisee: 'Ventas reportadas - Franquiciado',
        Reported_Sales_Franchisor: 'Ventas reportadas - Franquiciador',
        Reported_Sales_ThirdParty: 'Ventas informadas: terceros',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: 'Guiones de notas y llamadas',
      SEND: 'ENVIAR',
      LOG_CALL:'Registro de llamada',
      CALLER_ID: 'Identificador de llamadas',
      END_CALL: 'Finalizar llamada',
      MOBILE: 'Móvil',
      PHONE: 'Teléfono',
      HOME_PHONE:'Teléfono de casa',
      NEXT: 'Siguiente',
      COMPLETE: 'completo',
      OF: 'de',
      POWER_DIALER: 'Marcador de energía'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: 'Puntuaciones',
        NO_SCORES_AVAILABLE: 'No hay acciones disponibles.',
        OBJECT: 'Objeto',
        GROUPS:'Grupos',
        WORKFLOW_NAME: 'Nombre del flujo de trabajo',
        CONVERTED_FROM:'Convertido de',
        CONVERTED_TO: 'Convertido a',
        FIELDS_NAME: 'Nombre de los campos',
        POINTS: 'Puntos',
        EXPIRES_BY: 'Caduca por',
        APPLY_TO_EXISTING_SCORE: '* La fecha de vencimiento se aplicará retroactivamente a sus puntajes existentes.',
        GROUP_ALREADY_REMOVE: 'Grupo ya eliminado. Modifíquelo antes de guardar.',
        FIELD_ALREADY_REMOVE: 'Campo ya eliminado. Modifíquelo antes de guardar.',
        FIELD_VALUE_ALREADY_REMOVE:'El valor del campo ya se eliminó. Modifíquelo antes de guardar.',
        AND_ABOVE: 'y por encima',
        LESS_THAN: 'Menos que',

    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: 'Reglas duplicadas',
        DUPLICATE_RULE_HEAD_LABEL: 'Compruebe duplicados Utilizando',
        SELECT_OBJECT_LABEL: 'Seleccionar objeto',
        ACTIVE: 'Activo',
        DEFAULT: 'Defecto',
        ONE_FIELD: 'uno de los campos',
        TWO_FIELD: 'dos campos',
        THREE_FIELD: 'tres campos',
        FIELD_ONE: 'campo 1',
        FIELD_TWO: 'campo 2',
        FIELD_THREE: 'El campo 3',
        SELECT_FIELD_LABEL: 'Seleccionar campo',
        CHECK_DUPLICATES: 'Compruebe Duplicados',
        SELECT_RULES: 'Seleccione una regla',
    },
    JOBS:{
        RESCHEDULE: 'Reprogramar',
        OMW:'omw',
        INVOICE:'Factura',
        PAY:'Pagar'
    },
    TASK_REPORT:{
        TITLE:'Informe de tareas',
        UNITS:'Unidades',
        NO_TASK_FOUND:'No se encontraron tareas',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'Detalles del informe de tareas',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'Hay que al menos 2 opciones deben ser en esta pregunta.',
        SINGLE_RECORD: 'Solo se permite que 1 registro se mueva a la vez.',
        AUDIT_QUESTIONS: 'Lista de verificación de auditoría',
        GIVE_FEEDBACK:'Dar opinion',
        NEW_QUESTION: 'Nueva pregunta',
        TEXT: 'Texto corto',
        COMMENT: 'Texto largo',
        LIST: 'Selección única (abandono)',
        CHECKBOX: 'Opción múltiple (casillas de verificación)',
        QUESTION: 'Pregunta',
        ADD_NEW: 'Añadir nuevo',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: 'Por favor, seleccione 1 Grabar para editar.',
        MESSAGE_DELETE: '¿Estás seguro de que quieres eliminar',
        SELECTED_RECORDS: 'Registro (s) seleccionado (s)?',
        YOUR_ANSWER: "Tu respuesta",
        SUBMITTING: 'Sumisión...',
        SAVE_CATEGORY:'Guardar categoría',
        SUBMIT_AUDIT:'Enviar auditoría',
        SUBMIT: 'Enviar',
        SCORE: 'Puntaje',
        REQUIRED: 'Requerido',
        CHOICE: 'Elección',
        FIELD_REQUIRED: 'Este campo es obligatorio *',
        NEW_CHOICE: 'Nueva elección',
        FREE_FORM: 'Forma libre',
        SCORE_HELP_TOOLTIP: "Asignar puntos o puntajes a las respuestas. Los puntajes se sumarán para calcular la puntuación total para la auditoría.",
        SURVEY_LINK: 'Enlace de auditoría / encuesta',
        REQUIRES_PHOTO: 'Requiere foto',
        ENABLE_NOTE: 'Habilitar nota',
        ADD_PHOTO: 'Foto',
        UPDATE_PHOTO: 'Actualizar',
        NOTE: 'Nota',
        PHOTO_REQUIRED: 'Se requiere foto *',
        NEXT_CATEGORY:'Siguiente categoría',
        GENERATE:'Generar',
        FIELD_AUDIT:'Auditoría de campo (auditada por el franquiciador)',
        SELF_AUDIT:'Autoauditoría (auditada por el franquiciado)',
        GENERATE_AUDIT_LINK:'Elija una lista de verificación',
        REQUIRES_PHOTO: 'Requiere foto',
        ENABLE_NOTE: 'Habilitar nota',
        ADD_PHOTO: 'Foto',
        UPDATE_PHOTO: 'Actualizar',
        NOTE: 'Agregar una nota',
        PHOTO_REQUIRED: 'Se requiere foto *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: 'Respuesta de auditoría',
        QUESTION:'Pregunta',
        SCORE: 'Puntaje',
        ANSWER: 'Respuesta',
        SUBMIT_CORRECTIVE_ACTIONS:'Enviar acciones correctivas',
        REQUEST_CORRECTIVE_ACTIONS:'Solicitar acciones correctivas',
        INSERT_PHOTO:'Insertar foto',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'Categoría',
        MIN_FAILURE_COUNT:'Recuento mínimo de fallos',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'Agregar una nueva categoría',
        MANAGE_CATEGORY: 'Administrar categorías',
        CATEGORIES:'Categorías',
        CATEGORY_QUESTIONS_LABLE:'Preguntas de la categoría',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'Informe de resultados',
    },
    SALES_AND_REFUND: {
        PERIOD:'Período',
        START_DATE:'Fecha de inicio',
        END_DATE :'Fecha final',
        BASED_ON :'Residencia en',
        SALES_AND_REFUND_TITLE:'Ventas y reembolso',
        SALES_AND_REFUND_DETAIL:'Detalles de ventas y reembolsos',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'Detalles del informe de ventas y reembolsos',
        START_DATE:'Fecha de inicio',
        END_DATE:'Fecha final',
        BASED_ON:'Residencia en',
        NO_RECORD_EXPORT: 'ningún record fue encontrado'
    },
    AUDIT_CHECKLISTS:{
        TITLE:'Nueva lista de verificación de auditoría',
    },
    UNIT_CRM:{
        MY_SETTINGS:'Mi configuración',
        COMPANY:'Company',
        USER_AND_SECURITY:'Usuarios y seguridad',
        SURVEYS: 'Encuestas',
        WORKFLOW_RULES_SECTION:'Sección Flujos de trabajo y reglas',
        EMAIL_ALIASES:'Alias ​​de correo electrónico',
        CUSTOMIZATION:'Personalización',
        LISTS:'Lists',
        DATA:'Datos',
        COMPANY_SCHEDULER:'Programador de la empresa',
        FRANCHISE:'Franquicia',
        INTEGRATION:'Integración',
        SUBSCRIPTION:'Suscripción',
        CAMPAIGNS:'Campañas',
        BOARDCAST_CAMPAIGNS :'Campañas de difusión',
        AUTOMATED_CAMPAIGNS:'Campañas automatizadas',
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'Desglose de ventas reportadas por regalías',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: 'Usar para notificación',
        REMOVE_NOTIFICATION_TOOLTIP: 'Eliminar para notificación',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'Administre los números de teléfono, los créditos de mensajes de texto y los minutos de llamadas de la unidad',
    },
}
export default label