import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import store from '../../../src/services/store';
import { addTab } from '..//helper/sfTabManager';
import { getAppDrawer } from '..//actions/appContainerActions';
import { constants } from '..//constants/constants';
import { isValidParam, getStringParam} from '..//helper/parameterVerifier';
import { LinkToType } from '..//constants/link';
import { getLocalizedStrings } from '..//constants/MultiLingual';

function getDashboard(projectId, userId, dashboardId, timeLine, type, moduleType) {
    let params = {
        projectId: projectId,
        userId: userId,
        dashboardId: dashboardId,
        timeLine: timeLine,
        type: type,
        moduleType: moduleType
    }
    return {
        type: actions.DASHBOARD_GET,
        payload: HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_GET_NEW, params)
    }
}

function getDashboardURL(projectId, dashboardId, isFranchisor, franchiseId, timeLine) { 
    let params = {
        projectId: projectId,
        dashboardId: dashboardId,
        is_franchisor: isFranchisor,
        timeline: timeLine
    }
    if(franchiseId !== 'all') {
        params.franchiseId = franchiseId;
    }
    return {
        type: actions.DASHBOARD_URL_GET,
        payload: HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_URL_GET, params)
    }
}

function setDashBoardRedirection() {
    return function (dispatch) {
        dispatch({
            type: actions.DASHBOARD_REDUCER_REDIRECTION,
            payload: {
                isRedirect: false,
                redirectUrl: ''
            }
        })
    }
}


function drillDownChartData() {


}


function drillDownMatrixReport(data, columnValue, widgetId, status) {

    let _state = store.getState();
    let widgets = _state.dashboard.data.widgets !== null ? _state.dashboard.data.widgets : [];
    let widget = widgets.filter(function (obj) {
        return obj !== null && obj !== 'undefined' && obj.i.toString() === widgetId.toString();
    });
    widget = widget[0];
    if (widget && (widget.header === 'Lead Pipeline' || widget.header === 'Opportunity Pipeline' || widget.header === 'Top Web Pages'
        || widget.header === 'Top Marketing Emails by CTR' || widget.header === 'Sales Leaderboard'
        || widget.header === 'Activities' || widget.header === 'Accounts Matrix' || widget.header === 'Opportunities Matrix')) {

        let rowData = JSON.parse(data);
        const timeLine = widget.timeline;
       
        if (widget.header === 'Lead Pipeline') {
            let object_name = "accounts";
            let query_id = 542;

            let params = {

                "group_by_field_name": "t_status",
                "group_by_condition": rowData.status,
                "report_type": "11",
                "report_search_condition": " j_leads.t_status <> 'Customer'",
                "click_form": "DASHBOARD"
            }

            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, 501, rowData.status);
        } else if (widget.header === 'Opportunity Pipeline') {


            let object_name = "opportunities";
            let query_id = widget.details.query_id;;
            let params = {
                "report_type": "501",
                "click_form": "DRILLDOWN",
                "report_search_condition": 't_status=' + rowData.status + constants.RECORDSET_DELIMITER + 'dueBy=' + timeLine
            }
            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, '501', rowData.status);
        } else if (widget.header === 'Top Web Pages') {

            if (columnValue.indexOf('http://') === -1 || columnValue.indexOf('https://') === -1) {
                window.open("http://" + columnValue);
            } else {
                window.open(columnValue);
            }
            return;

        } else if (widget.header === 'Top Marketing Emails by CTR') {

            let data = {};
            data.campStatId = rowData.id;

            store.dispatch(
                getAppDrawer(true, widget.header, constants.DASHBOARD_TOP_MARKETING_EMAIL_BY_CTR, data, constants.DASHBOARD_TOP_MARKETING_EMAIL_BY_CTR)
            )
            return;
        } else if (widget.header === 'Sales Leaderboard') {

            let object_name = "opportunities";
            let query_id = widget.details.query_id;

            let params = {
                "group_by_field_name": "t_status",
                "group_by_condition": "Close",
                "search_field_name": "sales_rep",
                "search_text": rowData.sales_rep,
                "report_type": "11",
            }

            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, "", rowData.sales_rep);
        } else if (widget.header === 'Activities') {
            let object_name = "notes";
            let query_id = widget.details.query_id;
            let params = {
                "group_by_field_name": "note_type",
                "group_by_condition": status,
                "search_field_name": "author",
                "search_text": rowData.Users,

                "report_type": "11",
                "click_form": "DRILLDOWN",
                "report_search_condition": 'note_date=' + timeLine,
                "type": "DASHBOARD",
            }

            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, 502, status);
        } else if (widget.header === 'Opportunities Matrix') {
            let object_name = "opportunities";
            let query_id = widget.details.query_id;
            let params = {
                "group_by_field_name": "sales_rep",
                "group_by_condition": rowData.Users,
                "search_field_name": "t_status",
                "search_text": status,
                "report_type": "11",
                "click_form": "DASHBOARD",
            }
            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, 502, status);
        } else if (widget.header === 'Accounts Matrix') {
            let object_name = "accounts";
            let query_id = widget.details.query_id;

            let params = {
                "group_by_field_name": "sales_rep",
                "group_by_condition": rowData.Users,
                "search_field_name": "t_status",
                "search_text": status,

                "report_type": "11",
                "click_form": "DASHBOARD"
            }
            addRedirectToTab(object_name, query_id, timeLine, params, widget.header, 502, status);
        }

    }

}

function addRedirectToTab(object_name, query_id, dueBy, customInputParamObject, reportName, queryType, clickFieldName) {

    let groupByObject = {};
    if (customInputParamObject.hasOwnProperty('group_by_field_name')) {
        groupByObject.group_by_field_name = customInputParamObject.group_by_field_name
        //  delete customInputParamObject.group_by_field_name;
    }

    if (customInputParamObject.hasOwnProperty('group_by_condition')) {
        groupByObject.group_by_condition = customInputParamObject.group_by_condition
        //delete customInputParamObject.group_by_condition;
    }

    //  let query_type = 11;

    let tabParams = {
        "imgName": "fa fa-users",
        "index": 0,
        item: 'dashboard',

        "info": {
            "filter": {
                "id": query_id,
                "name": "",
                "type": queryType
            },
            "input_param": {
                //   "search_field_name": "author", "search_text": "Buddhasis Mitra"
                "report_due_by": dueBy,
                "report_user_names": "all",
                ...customInputParamObject
            },
           
            isReport: true,
            reportFieldValue: getLocalizedStrings().label.DASHBOARD.hasOwnProperty(clickFieldName) ? getLocalizedStrings().label.DASHBOARD[clickFieldName] : clickFieldName,
            reportLabel: getLocalizedStrings().label.DASHBOARD.hasOwnProperty(reportName) ? getLocalizedStrings().label.DASHBOARD[reportName] : reportName,
        },
        "isActive": false,
        "label": getLocalizedStrings().label.DASHBOARD.hasOwnProperty(reportName) ? getLocalizedStrings().label.DASHBOARD[reportName] : reportName,
        "object": object_name,
        "type": 'TYPE_LIST_VIEW',
        "url": '/' + object_name + '/queries',
        ...groupByObject
    };

    addTab(tabParams, true);
}

function customDrillDown(info, event) {
    let url = "";
    let name = null;
    let promise = null;
    let params = {};
    let drawerHeaderLabel = null;
    try {
        if (isValidParam(info) && info.hasOwnProperty("widgetName")) {
            name = getStringParam(info.widgetName);
            drawerHeaderLabel = name;
            if (name === "Activity Summary" || name === "Activities by Users" || name === "Activity Summary By SalesRep" || name === "Call Activity") {
                url = endPoints.DASHBOARD.CUSTOM_DRILLDOWN_GET;

                params.header = info.widgetName;
                params.timeLine = info.details.timeline;

                if ((name === "Activity Summary By SalesRep" || name === "Call Activity By SalesRep") && info.hasOwnProperty("header")) {
                    params.salesRep = info.details.salesRepName;

                }
                drawerHeaderLabel = info.header;

                if (getStringParam(url) !== '') {
                    promise = Promise.resolve(HTTPClient.get(url, params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            let isOpen = true;
                            let actionDialogName = drawerHeaderLabel;
                            let actionType = LinkToType.TYPE_SF_CUSTOM_DATA_TABLE;
                            store.dispatch(getAppDrawer(isOpen, actionDialogName, actionType, response, null));
                        });

                    }
                }

            }
            else if (name === "CRM Account Summary" || name === "Sales Cycle" || name === "Top Industries"
                || name === "Leads By Source" || name === "Leads By Lead Source" || name === "Top Sources") {
                let option = '';
                let value = '';
                let count = '';
                option = getStringParam(event.point.options.name);
                value = getStringParam(option.split("(", 1)[0]).trim();
                count = getStringParam(option.split(value, 2)[1]).trim();

                if (value.length > 0) {
                    url = endPoints.DASHBOARD.CUSTOM_DRILLDOWN_GET;
                    params.header = info.widgetName;
                    params.timeLine = info.details.timeline;
                    params.value = value;
                    params.url = url;

                    drawerHeaderLabel = getStringParam(drawerHeaderLabel + " - " + value + " " + count);
                    let isOpen = true;
                    let actionDialogName = drawerHeaderLabel;
                    let actionType = LinkToType.TYPE_SF_DASHBOARD_DRILLDOWN_DATA_TABLE;
                    store.dispatch(getAppDrawer(isOpen, actionDialogName, actionType, params, null));
                }
            }
        }
    } catch (error) {
        console.error("Error in 'dashboardActions.js -> customDrillDown()':" + error);
    }
}

const getDashboardWidgets=(dashboardId,timeLine,type,moduleType,callFor)=>{
    const params = {
        dashboardId,timeLine,type,moduleType,callFor
    }
     return {
        type: actions.DASHBOARD_INACTIVE_WIDGET,
        payload: HTTPClient.get(endPoints.DASHBOARD.DASHBOARD_GET, params)
    }
}

const refreshDashboard = (isRefresh) =>{
    return {
        type: actions.DASHBOARD_IS_REFRESH,
        payload: {
            isRefresh: isRefresh,
        }
    }
}

const editDashboard = (flag, moduleType) =>{
    return {
        type: actions.DASHBOARD_IS_EDIT,
        payload: {
            isEditMode: flag,
            editModule: moduleType
        }
    }
}

export const updateWidgetContent = (widget) => {
    return {
        type: actions.UPDATE_WIDGET_CONTENT,
        payload: widget 
    };
}

export {
    getDashboard,
    getDashboardURL,
    setDashBoardRedirection,
    drillDownMatrixReport,
    drillDownChartData,
    addRedirectToTab,
    customDrillDown,
    getDashboardWidgets,
    refreshDashboard,
    editDashboard
}