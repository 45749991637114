import { styles } from '../../../services/constants/styles';
import { TextField, FormControl, Button, Typography, InputAdornment,IconButton } from '@mui/material';
import { getStringParam } from '../../../services/helper/parameterVerifier';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { setHeader } from '../../../services/actions/headerActions';

export default function NewPassword(object){
    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inp, setInp] = useState('')
    const [inp1, setInp1] = useState('')
    const [val, setVal] = useState('')
    const [final,setFinal] = useState('')
    const [data, setData] = useState('')
    const [match, setMatch] = useState(true)
    const [afterSubmit, setAfterSubmit] = useState(true)
    const [afterSubmit1, setAfterSubmit1] = useState(true)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
        length: false,
        sequential: false,
        repeated: false,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.PROFILE_DETAILS.CHANGE_PASSWORD, null, false, true));
    }, []);

    const handleChangePswd = (event) => {
        let target = event.target;
        let value = target.value;
        validatePassword1(value)
    }
    const handleChangeCnfPswd = (event) => {
        let target = event.target;
        let value = target.value;
        validatePassword2(value)    
    }
    // Toggle function for New Password field
    const toggleNewPasswordVisibility = () => setShowNewPassword(prevState => !prevState);

    // Toggle function for Confirm New Password field
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(prevState => !prevState);

        // Check for repeated characters
    const hasRepeatedCharacters = (input) => {
        const repeatedPattern = /(\S)(\1{2,})/ ///(.)\1+/;  // (.) matches any character, \1+ checks if it repeats
        return repeatedPattern.test(input);
    };
    
        // Check for sequential characters
    const hasSequentialCharacters = (input) => {
        const seqNumPattern = /012|123|234|345|456|567|678|789/;  // Patterns for numeric sequences (e.g. 1234)
        const seqAlphaPattern = /abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz/; // Patterns for alphabetic sequences (e.g. abcd)
        return seqNumPattern.test(input) || seqAlphaPattern.test(input);
    };

    const validatePassword1 = (input) => {
        setNewPassword(input);
        setPasswordRequirements({
            uppercase: /[A-Z]/.test(input),
            lowercase: /[a-z]/.test(input),
            number: /\d/.test(input),
            symbol: /[!@#$%^&*(),.?":{}|<>]/.test(input),
            length: input.length >= 12,
            sequential: hasSequentialCharacters(input),
            repeated: hasRepeatedCharacters(input),
        });
        setVal('')
        setInp('')
        setMatch(true)
    };
    const validatePassword2 = (input) => {
        setConfirmPassword(input);
        setVal('')
        setInp1('')
        setMatch(true)
    };
    
    const handleSubmit = (e) =>{
        if(!newPassword.trim() && !confirmPassword.trim()){
            setInp('New password cannot be empty')
            setInp1('Confirm new password cannot be empty')
            setMatch(true)
        }
        if (!newPassword.trim() && confirmPassword.trim()) {
            setInp('New password cannot be empty')
            setInp1('')
            setMatch(true)
            //setAfterSubmit(false)
        }
        if (newPassword.trim() && !confirmPassword.trim()) {
            setInp1('Confirm new password cannot be empty')
            setInp('')
            setMatch(true)
            //setAfterSubmit1(false)
        }
       if(newPassword.trim().length > 0 && confirmPassword.trim().length > 0){
            // setInp('')
            // setInp1('')
       if(newPassword !== confirmPassword){
          setMatch(false)
          setInp('')
          setInp1('')
       }
       else if(passwordRequirements.length===false || passwordRequirements.uppercase===false || passwordRequirements.lowercase===false || passwordRequirements.number===false || passwordRequirements.symbol===false || passwordRequirements.sequential || passwordRequirements.repeated){
        setVal('Validations are not properly followed here according to guidelines')
        setInp('')
        setInp1('')
        setMatch(true)
       }
       else{
        setPasswordRequirements({
            uppercase: false,
            lowercase: false,
            number: false,
            symbol: false,
            length: false,
            sequential: false,
            repeated: false,
        });
        return validateProfileDetailsForm()
       }}
    }

    const validateProfileDetailsForm = () => {
        let errorMessage = null;
        let isNewPasswdValid = false;
        let newPswd = getStringParam(newPassword);
        let cnfPswd = getStringParam(confirmPassword);
        if (newPswd.length === 0 && cnfPswd.length === 0) {
            isNewPasswdValid = true;
        }
        else {
            if (newPswd.length > 0 && cnfPswd.length === 0) {
                errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_CONFIRM_PASSWORD;
                setNewPassword('');
                setInp('')
                setInp1('')
            }
            else if (newPswd.length === 0 && cnfPswd.length > 0) {
                errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_NEW_PASSWORD;
                setConfirmPassword('');
                setInp('')
                setInp1('')
            }
            else if (newPswd.length > 0 && cnfPswd.length > 0) {
                if (!newPswd.replace(/\s/g, '').length || !cnfPswd.replace(/\s/g, '').length) {
                    errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.NO_NEW_PASSWORD;
                    setNewPassword('');
                    setConfirmPassword('');
                    setInp('')
                    setInp1('')
                }
                else if (newPswd !== cnfPswd) {
                    errorMessage = getLocalizedStrings().message.PROFILE_DETAILS.UNMATCH_NEW_CONFIRM_PASSWORD;
                    setNewPassword('');
                    setConfirmPassword('');
                    setInp('')
                    setInp1('')
                }
                else {
                    isNewPasswdValid = true;
                }
            }

        }
        if (isNewPasswdValid) {
            saveProfileDetailsForm();
        }
        else {
            errorMessage = getStringParam(errorMessage);
            if (errorMessage.length > 0) {
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        }
    }


    const saveProfileDetailsForm = () => {   
        if (newPassword === confirmPassword) {
            let params = { "password": newPassword.trim(), "confirmPassword": confirmPassword.trim() }
            var promise = Promise.resolve(HTTPClient.post(endPoints.LOGIN.CHANGE_PASSWORD, params));
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    dispatch(showCustomSnackBar("Password changed successfully.", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
                else {
                    dispatch(showCustomSnackBar("Password did not changed.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            })
        }
        setNewPassword('');
        setConfirmPassword('');
        //setFinal('')
        setMatch(true)
        setInp('')
        setInp1('')
        setVal('')
    }
    return(
    <div>
        <div style={{display:'flex'}}>
        <div className="main"> 
        <div className="row">
            <div id="header" style={{ marginLeft:'25px', paddingLeft: '15px', width: '100%', fontSize: '24px', paddingTop: '20px', paddingBottom: '10px' }}>{getLocalizedStrings().label.PROFILE_DETAILS.CHANGE_PASSWORD}</div>
                <div className="col-sm-6" style={{ marginLeft:'25px', paddingLeft: '15px', width: '51%'}}>
                    <FormControl style={{ width: '100%'}} className="test" noValidate autoComplete="off">
                        <TextField style={{ width: '500px' }}
                                    variant="outlined"
                                    type={showNewPassword ? 'text' : 'password'}
                                    label={getLocalizedStrings().label.PROFILE_DETAILS.NEW_PASSWORD}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(event) => handleChangePswd(event)}
                                    onPaste={function (e) { e.preventDefault(); }}
                                    disabled={false}
                                    className={"sf-fields-bg"}
                                    autoComplete="new-password"
                                    margin="dense"
                                    size="small"
                                    InputProps={{
                                        maxLength: 32,
                                        endAdornment: (
                                        <InputAdornment position="end">
                                           <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleNewPasswordVisibility}
                                            edge="end"
                                            >
                                            {showNewPassword ? <i class="fa fa-eye"></i> : <i class="fa fa-eye-slash"></i>}
                                            </IconButton> 
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                        {afterSubmit && (<Typography style ={{ color:'red'}}
                        variant="caption"
                        gutterBottom sx={{ display: 'block' }}>{inp}</Typography>)}

                        <Typography style ={{ color:'red',width:'500px'}}
                        variant="caption"
                        gutterBottom sx={{ display: 'block' }}>{passwordRequirements.sequential ? 'Password contains sequential numbers/letters (e.g., "1234", "abcd")' : passwordRequirements.repeated ? 'Password contains repeated characters (e.g., "aaaa", "1111")' : '' }</Typography>
                    </FormControl>
                   
            </div>
        </div>
        <div className="row">
            <div className="col-sm-6" style={{marginLeft:'25px', paddingLeft: '15px', width: '51%' }}>
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField  style={{ width: '500px' }}
                                    variant="outlined"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    label={getLocalizedStrings().label.PROFILE_DETAILS.CONFIRM_PASSWORD}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(event) => handleChangeCnfPswd(event)}
                                    onPaste={function (e) { e.preventDefault(); }}
                                    disabled={false}
                                    className={"sf-fields-bg"}
                                    autoComplete="new-password"
                                    margin="dense"
                                    size="small"
                                    InputProps={{
                                        maxLength: 32, 
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleConfirmPasswordVisibility}
                                            edge="end"
                                            >
                                            {showConfirmPassword ? <i class="fa fa-eye"></i> : <i class="fa fa-eye-slash"></i>}
                                            </IconButton>
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                        {afterSubmit1 && (<Typography style ={{ color:'red',margin:'0px'}}
                        variant="caption"
                        gutterBottom sx={{ display: 'block' }}>{inp1}</Typography>)}

                        <Typography style ={{ color:'red',width:'500px',margin:'5px 0px'}}
                        variant="caption"
                        gutterBottom sx={{ display: 'block' }}>{( confirmPassword!=='' &&  newPassword!=='' && (confirmPassword!==newPassword)) || !match ? 'Passwords do not match!' : '' }</Typography>
                    </FormControl>
            </div>
        </div>
        </div>
        <div style={{margin: '60px -150px'}}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
               <li>
                    <i className="fa fa-check-circle" style={{ color: passwordRequirements.length ? 'green' : 'red', marginRight: '8px' }}></i>
                    At least 12 characters long
                </li>
                <li>
                    <i className="fa fa-check-circle" style={{ color: passwordRequirements.uppercase ? 'green' : 'red' , marginRight: '8px' }}></i>
                    One uppercase letter
                </li>
                <li>
                    <i className="fa fa-check-circle" style={{ color: passwordRequirements.lowercase ? 'green' : 'red', marginRight: '8px' }}></i>
                    One lowercase letter
                </li>
                <li style={{margin: '0px -8px'}}>
                    <i className="fa fa-check-circle" style={{ color: passwordRequirements.number && passwordRequirements.symbol ? 'green' : 'red' , margin: '5px 8px' }}></i>
                    One number and symbol
                </li>
            </ul>
        </div>
        </div>
        <div style ={{ color:'red',margin:'-45px 40px'}}>
        <Typography
                        variant="caption"
                        gutterBottom sx={{ display: 'block' }}>{(newPassword ==='' && confirmPassword==='') ? '' : val}</Typography>
        </div>
        <div style={{display:'flex',margin:'50px 390px'}}>
        <Button
                key='save' 
                onClick={(e) => handleSubmit(e)}

                style={{ ...styles.primaryButton, float:'right', marginRight:'6px', verticalAlign: 'top' }}
            >Change Password</Button>
        </div>
    </div>
    )
}