import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts'

export const Chart = ({config}) => {
    const chartComponentRef = useRef(null);

    const options = config;
    if (Array.isArray(options?.series) && options?.series[0]?.data.length > 0) {
        return <HighchartsReact options={options} highcharts={Highcharts} ref={chartComponentRef} />
    } else if (options?.data?.length > 0) {
        return <HighchartsReact options={options} highcharts={Highcharts} ref={chartComponentRef} />
    } else {
        return <div className="data_less">{getLocalizedStrings().message.CHARTS.NOTHING_TO_SHOW}</div>;
    }      
}

Chart.propTypes = {
    config: PropTypes.array,
    widgetId: PropTypes.string
};