import React, {useCallback, useEffect, useState} from 'react';
import {styles} from '../../../services/constants/styles';
import PopOver from '../components/PopOver';
import {removeScrollPosition} from "../../../services/actions/listViewAcions";
import {
    getBooleanParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isValidParam,
    getArrayParam,
} from "../../../services/helper/parameterVerifier";
import {getActiveTab, updateTabInfo,updateActiveTab} from "../../../services/helper/sfTabManager";
import {constants} from "../../../services/constants/constants";
import {FormControl, MenuItem, Select} from "@mui/material";
import {getLocalizedStrings} from "../../../services/constants/MultiLingual";
import {useDispatch, useSelector} from "react-redux";
import { updateisAllSelected,updatePinnedNotesSelectedField } from '../../../services/actions/noteActions';

const style = {display: 'inline', paddingRight: '3px',paddingLeft: '3px', paddingTop: '7px', color: '#70707c', cursor: 'pointer'};
const styleShow = {color: '#717171', overflow: 'hidden', clear: 'both'};


const Pagination = ({isLookup, isFindAndLink, selectedPage, listViewCallBack, callFrom, object, listView}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const activeTab = useSelector((state) => getObjectParam(getActiveTab(state)));
    const detailView = useSelector((state)=> state.detailView);
    const [totalRecord, setTotalRecord] = useState(0);
    const [start, setStart] = useState(2);
    const [end] = useState(5);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [postId, setPostId] = useState('');
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const [disable, setDisable] = useState(false);
    const [paginationDetail,setPaginationDetail]=useState({pageSize:5});


    useEffect(() => {
        try {
            if (isValidParam(listView.data.total_count)) {
                let info;
                let pageNo;
                let startPageNo;
                let disable = false;
                if (!getBooleanParam(isLookup)) {
                    info = getObjectParam(activeTab.info);
                } else {
                    info = getObjectParam(listView.info);
                }
                pageNo = getIntParam(info.selectedPageNo);
                startPageNo = getIntParam(info.startPageNo);

                pageNo = pageNo === 0 ? 1 : pageNo;
                startPageNo = startPageNo === 0 ? 2 : startPageNo;

                let totalCount = parseInt(getIntParam(listView.data.total_count));
                let _pageSize = parseInt(getIntParam(listView.data.page_size));

                if (totalCount !== totalRecord) {
                    pageNo = 1;
                    setTotalRecord(totalCount);
                }
                let pageCount = getIntParam(Math.ceil(totalCount / _pageSize));
                setStart(startPageNo);
                setSelectedPageNo(pageNo);
                if(object != 'notes' && callFrom !='appointmentQueueView'){
                    let _paginationdetail = paginationDetail;
                    _paginationdetail.pageSize= _pageSize;
                    setPaginationDetail({..._paginationdetail});
                }
                if(object === 'notes' && (callFrom =='top'||callFrom == 'Bottom')){
                    let _paginationdetail = paginationDetail;
                    _paginationdetail.pageSize= _pageSize;
                    setPaginationDetail({..._paginationdetail});
                }
                if(object === 'notes'){
                    let _paginationdetail = paginationDetail;
                    _paginationdetail.pageSize= _pageSize;
                    setPaginationDetail({..._paginationdetail});
                }
                setTotalPageCount(pageCount);
                setDisable(disable);
            }
        } catch (error) {
            console.error("Error in 'pagination.js -> updateState()':" + error);
        }
    }, [activeTab.info, isLookup, listView, totalRecord]);

    useEffect(() => {
        try {
            let postId = '-' + getStringParam(object);
            if (getBooleanParam(isLookup)) {
                postId = postId + '-lookup';
            }
            let call_From = getStringParam(callFrom)
            if (isValidParam(call_From)) {
                postId = postId + '-' + call_From;
            }
            if (isValidParam(document.getElementById(selectedPageNo + postId))) {
                let childNodes = document.getElementById(selectedPageNo + postId).parentElement.childNodes;
                if (isValidParam(childNodes)) {
                    for (let i = 0; i < childNodes.length; i++) {
                        if (isValidParam(childNodes[i])) {
                            childNodes[i].style['font-weight'] = '';
                            childNodes[i].style['color'] = '#70707c';
                        }
                    }
                }
            }

            if (isValidParam(document.getElementById(selectedPageNo + postId))) {
                document.getElementById(selectedPageNo + postId).style['color'] = app.me.background;
                document.getElementById(selectedPageNo + postId).style['font-weight'] = 'bold';
            }

            if (isValidParam(document.getElementById('prevDiv' + postId))) {
                if (selectedPageNo === 1) {
                    document.getElementById('prevDiv' + postId).style.cursor = 'not-allowed';
                } else {
                    document.getElementById('prevDiv' + postId).style.cursor = 'pointer';
                }
            }
            if (isValidParam(document.getElementById('nextDiv' + postId))) {
                if (selectedPageNo === totalPageCount) {
                    document.getElementById('nextDiv' + postId).style.cursor = 'not-allowed';
                } else {
                    document.getElementById('nextDiv' + postId).style.cursor = 'pointer';
                }
            }
            setPostId(postId);
        } catch (error) {
            console.error("Error in 'pagination.js -> useEffect()':" + error);
        }
    }, [selectedPageNo, totalPageCount, object, isLookup, callFrom, app.me.background]);

    const changePageSize = (_pageSize) => {
        let _activeTab = getActiveTab();
        _activeTab.info = { ..._activeTab.info, rememberSelection: { ..._activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
        updateActiveTab(_activeTab);
        try {
            const pageNo = 1;
            const startPageNo = 2;
            getListView(pageNo, _pageSize, startPageNo);
            dispatch(removeScrollPosition(object));
            let _paginationdetail = paginationDetail;
            _paginationdetail.pageSize= _pageSize;
            setPaginationDetail({..._paginationdetail});
            setStart(startPageNo);
            setSelectedPageNo(pageNo);
        } catch (error) {
            console.error("Error in 'pagination.js -> changePageSize()':" + error);
        }
    };

    const skippedPageSelectorActionHandle = (pageNo) => {
        getListView(pageNo, paginationDetail.pageSize, pageNo);
        setStart(pageNo);
    }

    const getSkipPageSelector = (id, skipPageNos) => {
        let options = [];
        if (isValidParam(skipPageNos) && skipPageNos.length > 0) {
            skipPageNos.forEach((objData) => {
                if (isValidParam(objData)) {
                    let data = {};
                    data.label = objData;
                    data.value = objData;
                    options.push(data);
                }
            });
        }
        return <div key={id} id={id}>
            <PopOver
                id={'dataFetchType'}
                key={'dataFetchType'}
                name={'dataFetchType'}
                btnType={'div'}
                buttonLabel={"..."}
                buttonEndIcon={"keyboard_arrow_down"}
                buttonStyle={{fontSize: '16px'}}
                containerStyle={{lineHeight: 1}}
                buttonLineOrigin={[46, 15]}
                isShowIcon={false}
                options={options}
                onclickAction={(pageNo) => skippedPageSelectorActionHandle(pageNo)}
                value={null}
                innerIcon={'left'}
                innerLabelStyle={{padding: "0 5", fontSize: '18px', marginTop: '2px', color: '#566f96'}}
                buttonOuterLabelStyle={{display: 'none'}}
                divStyle={{color: 'rgb(97, 97, 97)', border: '0px solid #c4c4c4', background: 'transparent'}}
            />
        </div>;
    }

    const getPageElement = () => {
        try {
            let elements = [];
            if (totalPageCount <= end) {
                let preDivId = 'prevDiv' + postId;
                elements.push(
                    <div style={{...style, cursor: 'not-allowed'}}
                         key={preDivId}
                         id={preDivId}
                         onClick={() => goToPrevPage()}
                    >
                        &nbsp;&nbsp;&#60;&#60;
                    </div>
                );
                for (let i = 0; i < totalPageCount; i++) {
                    let pageNoId = (i + 1) + postId;
                    elements.push(
                        <div style={style}
                             key={pageNoId}
                             id={pageNoId}
                             onClick={() => getListView(i + 1, paginationDetail.pageSize, start)}
                        >
                            {i + 1}
                        </div>
                    );
                }
                let nextDivId = 'nextDiv' + postId;
                elements.push(
                    <div style={style}
                         key={nextDivId}
                         id={nextDivId}
                         onClick={() => goToNextPage()}
                    >
                        &#62;&#62;
                    </div>
                );
            } else {
                let preDivId = 'prevDiv' + postId;
                let startVal = start;
                if (selectedPageNo < end + 1) {
                    startVal = 2;
                }
                let endPage = totalPageCount - startVal >= end ? end : totalPageCount - startVal;

                elements.push(
                    <div style={style}
                         key={preDivId}
                         id={preDivId}
                         onClick={() => goToPrevPage()}
                    >
                        &nbsp;&nbsp;&#60;&#60;
                    </div>
                );

                let pageOneId = '1' + postId;
                elements.push(
                    <div style={style}
                         key={pageOneId}
                         id={pageOneId}
                         onClick={() => getListView(1, paginationDetail.pageSize, startVal)}
                    >
                        1
                    </div>
                );

                if (startVal !== 2) {
                    let tempSkipPageNos = [];
                    let prevMoreId = 'prevMore' + postId;
                    let skipPageLimit = startVal;
                    if (endPage < 0) {
                        skipPageLimit = startVal + endPage;
                    }
                    for (let i = 2; i < skipPageLimit; i++) {
                        tempSkipPageNos.push(i)
                    }
                    elements.push(getSkipPageSelector(prevMoreId, tempSkipPageNos));
                }
                for (let i = 0; i < endPage; i++) {
                    let pageNoId = (startVal + i) + postId;
                    elements.push(
                        <div style={style}
                             key={pageNoId}
                             id={pageNoId}
                             onClick={() => getListView(startVal + i, paginationDetail.pageSize, startVal)}
                        >
                            {startVal + i}
                        </div>);
                }
                if (totalPageCount - 1 > (startVal + (endPage - 1))) {
                    let tempSkipPageNos = [];
                    let nextMoreId = 'nextMore' + postId;
                    for (let i = startVal + endPage; i < totalPageCount; i++) {
                        tempSkipPageNos.push(i);
                    }
                    elements.push(getSkipPageSelector(nextMoreId, tempSkipPageNos));
                }
                let totalPageCountId = totalPageCount + postId;
                elements.push(
                    <div style={style}
                         key={totalPageCountId}
                         id={totalPageCountId}
                         onClick={() => getListView(totalPageCount, paginationDetail.pageSize, startVal)}
                    >
                        {totalPageCount}
                    </div>);

                let nextDivId = 'nextDiv' + postId;
                elements.push(
                    <div style={style}
                         key={nextDivId}
                         id={nextDivId}
                         onClick={() => goToNextPage()}
                    >
                        &#62;&#62;
                    </div>
                );
            }
            return elements;
        } catch (error) {
            console.error("Error in 'pagination.js -> getPageElement()':" + error);
        }
    }

    const goToNextPage = () => {
        try {
            dispatch(removeScrollPosition(object));
            dispatch(updateisAllSelected(false));
            let PinnedNotesData = isValidParam(detailView) && isValidParam(detailView.pinnedNote) ?
            getArrayParam(detailView.pinnedNote.pinnedNotes) : [];
            PinnedNotesData = PinnedNotesData?.map((ele)=>{
                ele.isChecked = false;
                return ele;
            })
            dispatch(updatePinnedNotesSelectedField(PinnedNotesData));
            if (selectedPageNo < totalPageCount) {
                let pageNo = selectedPageNo + 1
                let nextMore = document.getElementById('nextMore' + postId);
                let prevMore = document.getElementById('prevMore' + postId);
                let startVal;
                if (isValidParam(prevMore) && pageNo === 2) {
                    startVal = 2;
                } else if (isValidParam(nextMore) && (totalPageCount > start + end) && (pageNo === start + end)) {
                    startVal = start + 1;
                }
                getListView(pageNo, paginationDetail.pageSize, startVal);
                setSelectedPageNo(pageNo);
                setStart(startVal);
                this.props.updateisAllSelected(false);
                let PinnedNotesData = isValidParam(detailView) && isValidParam(detailView.pinnedNote) ?
                getArrayParam(detailView.pinnedNote.pinnedNotes) : [];
                PinnedNotesData = PinnedNotesData?.map((ele)=>{
                    ele.isChecked = false;
                    return ele;
                })
                dispatch(updatePinnedNotesSelectedField(PinnedNotesData));
            }
        } catch (error) {
            console.error("Error in 'pagination.js -> goToNextPage()':" + error);
        }
    }

    const goToPrevPage = () => {
        try {
            dispatch(removeScrollPosition(object));
            dispatch(updateisAllSelected(false));
            let PinnedNotesData = isValidParam(detailView) && isValidParam(detailView.pinnedNote) ?
            getArrayParam(detailView.pinnedNote.pinnedNotes) : [];
            PinnedNotesData = PinnedNotesData?.map((ele)=>{
                ele.isChecked = false;
                return ele;
            })
            dispatch(updatePinnedNotesSelectedField(PinnedNotesData));
            if (selectedPageNo > 1) {
                let pageNo = selectedPageNo - 1
                let _selectedPage = document.getElementById(pageNo + postId);
                let nextMore = document.getElementById('nextMore' + postId);
                let prevMore = document.getElementById('prevMore' + postId);
                let startVal;
                if (isValidParam(nextMore) && pageNo === totalPageCount - 1) {
                    startVal = pageNo - end + 1;
                } else if (isValidParam(prevMore) && !isValidParam(_selectedPage)) {
                    startVal = start - 1;
                }else{
                    startVal = pageNo - end + 1;
                }
                getListView(pageNo, paginationDetail.pageSize, startVal);
                setSelectedPageNo(pageNo);
                setStart(startVal);
            }
        } catch (error) {
            console.error("Error in 'pagination.js -> goToPrevPage()':" + error);
        }
    }

    const getListView = (pageNo, pageSize, startPageNo) => {
        try {
            let listViewData = listView.data;
            let startIndex = pageSize * (pageNo - 1);
            let currentPageSize = listViewData.page_size;
            let listViewUpdate = false;
            if (currentPageSize !== pageSize) {
                listViewUpdate = true;
            }
            let params = {
                parent_object: listViewData.parent_object,
                parent_record_id: listViewData.parent_record_id,
                start_index: startIndex,
                page_size: pageSize,
                is_list_view_update: listViewUpdate
            };

            let obj = getStringParam(object);

            const objects = [constants.ACCOUNTS_OBJECT, constants.CONTACTS_OBJECT, constants.OPPORTUNITIES_OBJECT,
                constants.PROJECTS_OBJECT, constants.CASES_OBJECT, constants.SOLUTIONS_OBJECT, constants.ISSUES_OBJECT,
                constants.CUSTOM_TABLE1_OBJECT, constants.CUSTOM_TABLE2_OBJECT, constants.CUSTOM_TABLE3_OBJECT,
                constants.CUSTOM_TABLE4_OBJECT, constants.INVOICES_OBJECT, constants.TASKS];
            
            if (objects.includes(obj)) {
                params.query_id = listViewData.query_id;
                params.query_name = listViewData.query_name;
                params.query_type = listViewData.query_type;
            }

            if (obj === constants.ACCOUNTS_OBJECT && listViewData.parent_object === constants.ACCOUNTS_OBJECT) {
                params.relationTableId = 10;
            }

            let is_Lookup = getBooleanParam(isLookup);
            if (is_Lookup) {
                let info = {};
                info.selectedPageNo = pageNo;
                info.startPageNo = startPageNo;
                params.info = info;
            } else {
                params.group_by_field_name = listViewData.group_by_field;
                params.group_by_condition = listViewData.group_by_condition;
                params.group_by_field_name_sort = listViewData.group_by_field_sort;
                let info = getObjectParam(activeTab.info);
                info.selectedPageNo = pageNo;
                info.startIndex = startIndex;
                info.pageSize = pageSize;
                info.startPageNo = startPageNo;
                updateTabInfo(info);
            }
            dispatch(removeScrollPosition(obj));
            dispatch(updateisAllSelected(false));
            let PinnedNotesData = isValidParam(detailView) && isValidParam(detailView.pinnedNote) ?
            getArrayParam(detailView.pinnedNote.pinnedNotes) : [];
            PinnedNotesData = PinnedNotesData?.map((ele)=>{
                ele.isChecked = false;
                return ele;
            })
            dispatch(updatePinnedNotesSelectedField(PinnedNotesData));
            listViewCallBack(obj, params, true);
            if(object != 'notes' && callFrom !='appointmentQueueView'){
                selectedPage(pageNo)
            }
            activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [obj]: new Set()} };
            setSelectedPageNo(pageNo);
        } catch (error) {
            console.error("Error in 'pagination.js -> getListView()':" + error);
        }
    };

    const getMore = () => {
        if (selectedPageNo < totalPageCount) {
            let pageNo = selectedPageNo + 1
            getListView(pageNo, paginationDetail.pageSize, start);
            setSelectedPageNo(pageNo);
        }
    }
    
    let element = null;
    try {
        if (isValidParam(listView.data) && listView.data.hasOwnProperty('statKey')) {
            if (selectedPageNo < totalPageCount) {
                element = <div style={{
                    fontSize: '14px',
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'table',
                    float: 'right'
                }}>
                    <div style={style} key={'more'} id={'more'} onClick={() => getMore()}><a src="#">More</a></div>
                </div>;
            }
        } else {
            let arrPageNos;
            let pageNoItems = [];
            let is_LookUp = getBooleanParam(isLookup);
            let isFindLink = getBooleanParam(isFindAndLink);

            if (is_LookUp || isFindLink) {
                arrPageNos = [5, 10, 20, 30, 40, 50];
            } else {
                arrPageNos = [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200];
                if ((app.me.projectId === constants.CORPORATE_ACCOUNT_PROJECTID)
                    && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT)
                ) {
                    arrPageNos.push(500);
                } else if (paginationDetail.pageSize > 200) {
                    arrPageNos.push(paginationDetail.pageSize);
                }
            }

            arrPageNos.forEach((value, index) => {
                pageNoItems.push(<MenuItem key={index + '-' + value} value={value}>{value}</MenuItem>);
            });
            let isShowSelect = isValidParam(listView.data.is_show_view_count) ? listView.data.is_show_view_count : true;
            let tempPaddingLeft = '0%';
            let tempmarginLeft = '0%';
            if(object === constants.EMAIL_OBJECT){
                tempPaddingLeft = '0%';
                if(paginationDetail.pageSize > 20 && paginationDetail.pageSize !== 30 && paginationDetail.pageSize !== 40){
                    tempmarginLeft = listView.data.parent_object === constants.ACCOUNTS_OBJECT ? "71%" : '80%'
                } else if(paginationDetail.pageSize === 30){
                    tempmarginLeft = '75%'
                }else if(paginationDetail.pageSize === 40){
                    tempmarginLeft = '76%'
                }
                 else{
                    tempmarginLeft = '68%'
                }
                
            }
            element = <div
                style={{fontSize: '14px', position: 'relative', display:'flex',flexDirection:'row', float: 'right',paddingLeft:tempPaddingLeft,paddingTop:isLookup?'12px':'2px',marginLeft:tempmarginLeft}}>
                {isShowSelect && <div style={{...styleShow, display:'flex',marginTop:isLookup?'2px':'0px'}}>
                    <span style={{
                        display: 'inline-block',
                        paddingRight: 4,
                        paddingTop: 5.5
                    }}>{getLocalizedStrings().label.COMMON.SHOW}</span>
                    <FormControl variant="outlined" style={{marginBottom: '1px'}} className='sf-page-item-no'>
                        <Select
                            value={paginationDetail.pageSize}
                            onChange={(e) => changePageSize(e.target.value)}
                            autowidth={"true"}
                            className='sf-pagenation'
                            style={{height:'30px',fontSize:'16px'}}
                            disabled={disable}
                        >
                            {pageNoItems}
                        </Select>
                    </FormControl>
                    
                </div>}
                <div className="pageCount" style={{display:'flex', marginTop:'-1px' }}>{getPageElement()}</div>
            </div>
        }

    } catch (error) {
        console.error("Error in 'pagination.js -> getElement()':" + error);
    }
    return element;
}

export default Pagination;

