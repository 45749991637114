
export const endPoints = {
  LOGIN: {
    SUBMIT_LOGIN_CRED: "/v3/token/get",
    FORGOT_PASSWORD: "/v3/login/forgetpassword",
    FRANCHISE_LOGIN_CRED: "/v3/franchise/token/get",
    EVICT_USER: "/v3/evict/user",
    GENERATE_API_KEY: "/v3/generate/apikey",
    GET_GENERATE_API_KEY: "/v3/get/generate/apikey",
    DELETE_GENERATE_API_KEY: "/v3/delete/generate/apikey",
    RE_GENERATE_API_KEY: "/v3/regenerate/apikey",
    RESET_PASSWORD: "/v3/login/changepassword",
    CHANGE_PASSWORD:"/v3/profile/changepassword" 
  },
  HOME: {
    HOME_GET: "/v3/home/get",
    FILTER_SAVE: "/v3/home/filters/save",
    ALL_OBJECTS_RECENT_RECORDS: "/v3/common/recentrecords/get",
    SAVE_RECENT_SEARCH_TEXT: "/v3/common/recentsearch/save",
    GET_RECENT_SEARCH_TEXT: "/v3/common/recentsearch/get",
    GET_RECENT_DATA_EXISTS: "/v3/common/recentdataexists/get",
    DATABASE_SETUP_GET: "/v3/home/database/setup/get",
    DATABASE_SETUP_SAVE: "/v3/home/database/setup/save",
    UPDATE_MODULE: "/v3/home/update/module",
    UPDATE_MODULE_ITEM: "/v3/home/update/module/item",
    GET_AVAILABEL_MODULE_ITEMS: "/v3/home/get/availabel/module/items",
    SAVE_MODULE_ITEM: "/v3/home/save/module/item",
    GET_HOME_SETUP: "/v3/home/get/home/setup",
    GET_MOBILE_MENU: "/v3/home/get/mobile/menus",
    SAVE_MOBILE_MENU: "/v3/home/save/mobile/menus",
    DELETE_MOBILE_MENU: "/v3/home/delete/mobile/menus",
    GET_AVAILABEL_PORTAL_MODULES: "/v3/home/get/portal/items",
    UPDATE_PORTAL_MODULE: "/v3/home/save/portal/menus",
    GET_EXTERNAL_LINK: "v3/home/save/externallink",
  },
  ME: {
    ME_GET: "/v3/users/me",
  },
  DASHBOARD: {
    DASHBOARD_GET: "/v3/dashboard/get",
    DASHBOARD_CHANGE_LAYOUT: "/v3/dashboard/widgets/save",
    DASHBOARD_EMAIL_SUMMERY: "/v3/dashboard/emailsummary",
    CUSTOM_DRILLDOWN_GET: "/v3/dashboard/customdrilldown",
    DASHBOARD_INFO_GET: "/v3/dashboard/getDashboardInfo",
    DASHBOARD_URL_GET: "/v3/dashboard/getURL",
    FRANCHISE_LIST: "/v3/dashboard/franchise/list/get",
    DASHBOARD_GET_NEW: "/v3/dashboard/new/get",
    DASHBOARD_REPORTLIST_GET:"/v3/dashboard/get/reportlist",
    DASHBOARD_AVAILABLEWIDGETS_GET:"/v3/dashboard/availablewidgets/get",
    DASHBOARD_AVAILABLEWIDGETS_SAVE:"/v3/dashboard/availablewidgets/save",
    DASHBOARD_NEWWIDGET_SAVE:"/v3/dashboard/widgets/new/save",
    DASHBOARD_GET_TIMELINE_WIDGET:"/v3/dashboard/get/specific/widget"
  },
  RECORDS: {
    UPDATE_FIELDS: "/v3/{object}/update/fields",
    DELETE: "/v3/{object}/delete",
    MAP_DATA: "/v3/{object}/record/get",
    RECORD_LINKED_MAP_DATA: "/v3/{object}/get/linked/record/map",
    GET_UNSCHEDULED_JOB: "/v3/{object}/unscheduledjob",
  },
  COMMON: {
    IS_LINKED_RECORD: "/v3/islinkrecord",
    HAS_SECURITY_ROLE_PERMISSION: "/v3/hasSecurityRolePermission",
    LOOKUP_RECORD_INFO_GET: "/v3/common/lookup/get",
    LINK_OBJECT_INFO: "/v3/common/linkobject/info/get",
    LOOKUP_AUTOCOMPLETE_INFO_GET: "/v3/common/lookup/autocomplete/get",
    LOOKUP_CONTACT_INFO_GET: "/v3/common/lookup/contact/get",
    CONTACT_EMAIL_ID_GET: "/v3/common/link/contact/emailId/get",
    CLIENT_CONNECT_GET: "/v3/common/clientconnectget/",
    CONFIRM_REQ_FOR_CLIENT_CONNECT: "/v3/common/confirmrequestforclientconnect",
    LOOKUP_LINK_RECORDS: "/v3/common/linkrecords",
    CLIENT_DISCONNECT_GET: "/v3/common/clientdisconnect/",
    PUSH_WF_LIST: "/v3/push/getworkflowlist",
    SAVE_PUSH_INFO: "/v3/push/savepushinfo",
    PUSH_COMPANY_GET: "/v3/push/company",
    PUSH_BASE_COMPANY_GET: 'v3/push/unitsbyindustry/get',
    SOURDOUGH_DOC_DOWNLODE: "/v3/common/sourdoughdocumentdownload/",
    EMAILS_GET: "/v3/common/{object}/emails/get",
    FIELD_SIZE_GET: "/v3/common/fieldsize/get",
    CHECK_DUPLICATE_SOURCE_NAME: "/v3/common/checkduplicate/sourcename",
    SAVE_SOURCE_NAME: "/v3/common/sourcename/save",
    SAVE_LIST_VALUES: "/v3/common/listvalues/save",
    QUERY_DETAILS_GET: "/v3/common/querydetails",
    SETUP_VIEW_FIELDS_GET: "/v3/common/setup/fields/get",
    SAVE_RINGCENTRAL_SETUP: "/v3/common/saveringcentralsetup",
    GET_PHONE_INTEGRATION_SETUP: "/v3/common/phoneintegration/setup/get",
    GET_QUERY_BY_NAME: "/v3/common/getquerybyname",
    GET_CAMPAIGN_TYPES: "/v3/common/campaigntypes/get",
    SAVE_CAMPAIGN_TYPE: "/v3/common/campaigntypes/save",
    SAVE_SENDMAIL_FLAG: "/v3/common/sendmail/flag/save",
    GET_SETUP_PAGE_INFO: "/v3/common/get/setuppageinfo",
    GET_MYCLIENTID: "/v3/common/get/myclientid",
    GET_MOBILE_NO_VERIFY: "/v3/common/get/verify/mobileno",
    GET_DETAILVIEW_TITLE_FIELD: "/v3/common/get/detailview/titlefield",
    GET_LINKED_RECORD: "/v3/common/linkrecords/get",
    GET_PENDING_TASK_APP: "/v3/common/apptask/get",
    GET_QUEUE_LIST: "/v3/common/queue/get",
    GET_LINKED_RECORD_ID: "/v3/common/get/linked/recordId",
    SEARCH_CLIENT: "/v3/common/clientsearch",
    GET_ACCOUNTS: "/v3/common/get/unitaccount",
    GET_ACCOUNT_TYPE: "/v3/common/get/accounttype/list",
    FETCH_LINKED_RECORD: "/v3/common/linkrecords/fetch",
    GET_BASETENANT_ID: "/v3/common/basetenantid/get",
  },
  MARKETING_TEMPLATE: {
    LIST_VIEW: "/v3/marketingtemplates/get/listview",
    EDIT: "/v3/marketingtemplates",
    SAVE: "/v3/marketingtemplates/save",
    DELETE: "/v3/marketingtemplates/delete",
    NEW: "/v3/marketingtemplates/new",
    LIBRARY_TEMPLATE: "/v3/marketingtemplates/library",
    MARKETINGCATEGORYLIST: "/v3/marketingcategories/get",
    MARKETINGCATEGORYLISTBYTYPE: "/v3/marketingcategories/get/list",
    ACTIVE: "/v3/marketingtemplates/active",
    INACTIVE: "/v3/marketingtemplates/inactive",
    USER_TEMPLATE: "/v3/marketingtemplates/me",
    ALL_TEMPLATE: "/v3/marketingtemplates/all",
    TEMPLATE_GET: "/v3/marketingtemplates/template/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/marketingtemplates/listviewlayout/save",
    BATCH_EDIT: "/v3/marketingtemplates/batchedit",
    GET_DEFAULT_TEMPLATE_NAME: "/v3/marketingtemplates/gettemplatename",
    CHECK_DUPLICATE_TEMPLATE:
      "/v3/marketingtemplates/check/duplicate/templatename",
    LEGACY_TEMPLATE: "/v3/marketingtemplates/legacy/template",
    GET_EDITOR_TYPE: "/v3/marketingtemplates/get/editortype",
    GET_BEE_MESSAGE_HTML: "/v3/marketingtemplates/get/bee/message/html",
  },
  SALES_TEMPLATE: {
    LIST_VIEW: "/v3/salestemplates/get/listview",
    EDIT: "/v3/salestemplates",
    SAVE: "/v3/salestemplates/save",
    DELETE: "/v3/salestemplates/delete",
    NEW: "/v3/salestemplates/new",
    NEWTEMPLATE: "v3/salestemplates/newtemplate/mergefields/get",
    ACTIVE: "/v3/salestemplates/active",
    INACTIVE: "/v3/salestemplates/inactive",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/salestemplates/listviewlayout/save",
  },
  AGREEMENT_TEMPLATE: {
    LIST_VIEW: "/v3/templates/agreement/get/listview",
    EDIT: "/v3/templates",
    SAVE: "/v3/templates/agreement/save",
    DELETE: "/v3/templates/agreement/delete",
    NEW: "/v3/templates/agreement/new",
    ACTIVE: "/v3/templates/agreement/active",
    INACTIVE: "/v3/templates/agreement/inactive",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/templates/agreement/listviewlayout/save",
  },
  SMS_TEMPLATE: {
    LIST_VIEW: "/v3/smstemplates/get/listview",
    SAVE: "/v3/smstemplates/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/smstemplates/listviewlayout/save",
    EDIT: "/v3/smstemplates",
    GET_TEMPLATE: "/v3/smstemplates/template/get",
    DELETE: "/v3/smstemplates/delete",
    ACTIVE: "/v3/smstemplates/active",
    INACTIVE: "/v3/smstemplates/inactive",
    GET_TEMPLATE_DATA: "/v3/smstemplates/get/templatedata",
    DELETE_VERIFY: "/v3/smstemplates/schedulesms/verify/delete",
    DELETE_SCHEDULESMS: "/v3/smstemplates/schedulesms/delete",
    GET_SCHEDULE_SMS: "/v3/smstemplates/get/schedulesms",
    SAVE_SCHEDULE_SMS: "/v3/smstemplates/save/schedulesms",
    TEST_SMS: "/v3/twilio/send/test/sms",
  },
  NOTES: {
    LIST_VIEW: "/v3/notes/get/listview",
    LIST_GET: "/v3/notes/get",
    FIELDS: "/v3/notes/fields",
    NEW: "/v3/notes/new",
    SAVE: "/v3/notes/save",
    DELETE: "/v3/notes/delete",
    NOTE_TYPE: "/v3/notes/get/notetype",
    LIST: "/v3/notes/get",
    EDIT_COPY: "/v3/notes",
    NOTE_TYPE_GET: "/v3/notetypes/get",
    NOTE_TYPE_GET_ALL: "/v3/notetypes/getAllNoteType",
    SELECTED_NOTE_TYPE_GET: "/v3/notetypes/getSelectedNoteType",
    NOTE_TYPE_ACTIVE: "/v3/notetypes/active",
    NOTE_TYPE_INACTIVE: "/v3/notetypes/inactive",
    NOTE_TASK_APP: "/v3/notes/savenote",
    LIST_VIEW_SETUP_GET: "/v3/notes/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/notes/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/notes/listviewlayout/save",
    PIN: "/v3/notes/pin",
    UNPIN: "/v3/notes/unpin",
    PIN_GET: "/v3/notes/pin/get",
    STARRED: "/v3/notes/starred",
    ARCHIVE: "/v3/notes/archive/unarchive",
  },
  APPOINTMENT: {
    QUERY_FILTER: "/v3/appointments/queryfilter/get",
    LIST_VIEW: "/v3/appointments/get/listview",
    LIST_GET: "/v3/appointments/get",
    FIELDS: "/v3/appointments/fields",
    NEW: "/v3/appointments/new",
    SAVE: "/v3/appointments/save",
    EDIT_COPY: "/v3/appointments",
    RESCHEDULE: "/v3/appointments/reschedule",
    COMPLETE: "/v3/appointments/complete",
    DELETE: "/v3/appointments/delete",
    LIST: "/v3/appointments/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/appointments/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/appointments/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/appointments/setup/save",
    LIST_ATTENDEE_GET: "/v3/appointments/attendeelist/get",
    LIST_INVITEE_GET: "/v3/appointments/inviteelist/get",
    APPOINTMENT_DRAG_AND_DROP: "/v3/appointments/appointmentdraganddrop",
    BATCH_EDIT: "/v3/appointments/batchedit",
    DETAIL_VIEW: "/v3/appointments",
    CHILD_DETAILS_GET: "/v3/appointments/childdetails/get",
    CALENDAR_SYNC: "/v3/appointments/calendar/sync",
    SAVE_CALENDAR_SET_UP: "v3/appointments/calendar/setup/save",
    GET__CALENDAR_SET_UP: "v3/appointments/calendar/setup/get",
  },
  TASK: {
    QUERY_FILTER: "/v3/tasks/queryfilter/get",
    LIST_VIEW: "/v3/tasks/get/listview",
    LIST_GET: "/v3/tasks/get",
    FIELDS: "/v3/tasks/fields",
    NEW: "/v3/tasks/new",
    SAVE: "/v3/tasks/save",
    SAVE_BATCH_UNIT_TASK: "/v3/tasks/batchtask/save",
    EDIT_COPY: "/v3/tasks",
    RESCHEDULE: "/v3/tasks/reschedule",
    COMPLETE: "/v3/tasks/complete",
    DELETE: "/v3/tasks/delete",
    LIST: "/v3/tasks/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/tasks/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/tasks/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/tasks/setup/save",
    PORTAL: "/v3/tasks/portal/get",
    POPUP_REMINDER: "/v3/tasks/popupReminder",
    BATCH_EDIT: "/v3/tasks/batchedit",
    TASKS_COMPLETE_STATUS: "/v3/tasks/complete/status",
    ONBOARDING_COUNT: "/v3/tasks/onboardingcount/get",
    ONBOARDING_TASK_GET: "/v3/tasks/onboardingtasks/get",
    TASK_PAGE_GET: "/v3/tasks/taskpage/get",
    SEND_NOW: "/v3/tasks/sendnow",
    DETAIL_VIEW: "/v3/tasks",
    GANTT_CHART: "v3/tasks/ganttchart",
    CHILD_DETAILS_GET: "/v3/tasks/childdetails/get",
    ONBOARDING_PENDING_TASK_GET: "/v3/tasks/onboarding/pendingtasks/get",
    CHILD_OBJECT_SETUP_GET: "/v3/tasks/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/tasks/childobjects/setup/save",
    TASK_TITLE_GET: "/v3/tasks//title/get",
  },
  SALES_REPORT: {
    REPORT_HEADER: "/v3/reports/sales",
    RECENT_REPORT: "/v3/reports/recentreports",
    CALL: "/v3/reports/sales/call",
    PIPELINE: "/v3/reports/sales/pipeline",
    CONVERSION: "/v3/reports/sales/conversion",
    CONVERSION_BY_STATUS: "/v3/reports/sales/conversion/conversionbystatus",
    CONVERSION_BY_SOURCE: "/v3/reports/sales/conversion/conversionbysource",
    CONVERSION_MATRIX_STATUS:
      "/v3/reports/sales/conversion/conversionmatrixbystatus",
    CONVERSION_MATRIX_SOURCE:
      "/v3/reports/sales/conversion/conversionmatrixbysource",
    ACTIVITY: "/v3/reports/sales/activity",
    ACTIVITY_BY_SALES_REP: "/v3/reports/sales/activity/activitybysalesrep",
    ACTIVITY_BY_TYPE: "/v3/reports/sales/activity/activitybytype",
    ACTIVITY_MATRIX: "/v3/reports/sales/activity/activitymatrix",
    DOWNLOAD_ACTIVITY_PDF: "/v3/reports/activity/download",
    DOWNLOAD_CONVERTION_PDF: "/v3/reports/convertion/download",
    SALES_REP_FRANCHISE: "/v3/reports/franchisesalesrep",
    REPORT_SCHEDULE_SETUP: "/v3/reports/scheduleReport",
    REPORT_SCHEDULE_SETUP_GET: "/v3/reports/scheduleReport/get",
    SALESREP_GET: "/v3/reports/get/salesrep",
    SAVE_RECENT_REPORT: "/v3/reports/saverecent",
    GET_REPORT_RECORDS: "/v3/reports/records/get",
  },
  SERVICE_REPORT: {
    REPORT_HEADER: "/v3/reports/service",
    RECENT_REPORT: "/v3/reports/recentreports",
    SERVICE_REPORT: "/v3/reports/service/servicereports",
    PROJECT_REPORT: "/v3/reports/service/projectreports",
    ISSUE_REPORT: "/v3/reports/service/issuereports",
  },
  ACCOUNTS: {
    QUERY_FILTER: "/v3/accounts/queryfilter/get",
    MY_GROUP: "/v3/accounts/mygroup/get",
    MY_GROUP_STATUS_SAVE: "/v3/accounts/mygroup/status/save",
    FIELDS: "/v3/accounts/fields",
    LIST_VIEW: "/v3/accounts/get/listview",
    LIST_GET: "/v3/accounts/get",
    CONVERT_GET: "/v3/accounts/convert/get",
    CONVERT: "/v3/accounts/convert",
    IS_OPEN_OPPORTUNITY_EXITS_GET: "/v3/accounts/isopenopportunityexits/get",
    BATCH_CONVERT: "/v3/accounts/convert/batch",
    ASSIGN: "/v3/accounts/assign",
    ADD_TO_GROUP: "/v3/accounts/addtogroup",
    CAMPAIGN_FILTER: "/v3/accounts/campaignfilter/get",
    SAVE: "/v3/accounts/save",
    DETAIL_VIEW: "/v3/accounts",
    EDIT_COPY: "/v3/accounts",
    COMPANY_GET: "/v3/accounts/company",
    DELETE: "/v3/accounts/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/accounts/listviewlayout/save",
    STAGE_GET: "/v3/accounts/convert/workflowstages",
    TASK_GET: "/v3/accounts/convert/workflowstages/task",
    APPLY_WORKFLOW: "/v3/accounts/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/accounts/applyworkflow/batch",
    AUDIT_TRAIL_GET: "/v3/accounts",
    TODAYS_TASKS: "/v3/accounts/todays/task",
    TODAYS_APPOINTMENT: "/v3/accounts/todays/appointment",
    LIST_VIEW_SETUP_GET: "/v3/accounts/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/accounts/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/accounts/customizefields/setup/save",
    //KANBAN_REPORT_GET: '/v3/accounts/kanbanreport/get',
    COLUMN_VIEW: "/v3/accounts/columnview/get",
    MAP_DATA: "/v3/accounts/record/get",
    SAVE_RECENT_RECORD: "/v3/accounts/recent/save",
    RECENT_RECORD_GET: "/v3/accounts/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/accounts/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/accounts/childobjects/setup/save",
    LINK_CHILD_RECORD_GET: "/v3/accounts/child/record/get",
    USER_FILTER_GET: "/v3/accounts/filter/user/get",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/accounts/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/accounts/checkrecordstatus",
    BATCH_EDIT: "/v3/accounts/batchedit",
    MERGE_GET: "/v3/accounts/merge/get",
    MERGE_SAVE: "/v3/accounts/merge",
    FILTER_DELETE: "/v3/accounts/filters/delete",
    LEAD_SCORE_HISTORY: "/v3/accounts/leadscore/history",
    ADD_PHOTO: "/v3/accounts/add/photo",
    RELATED_RECORD_LIST_GET: "/v3/accounts/relatedrecordlist/get",
    RELATED_RECORD_SAVE: "/v3/accounts/relatedrecord/save",
    RELATIONSHIP_RECORDID_LIST_GET:
      "/v3/accounts/relationshipidlistbyrecordid/get",
    UNLINK_RELATED_RECORD: "/v3/accounts/unlinkrelatedrecord",
    DETAIL_VIEW_TASK_APP: "/v3/accounts/detailview/taskapp/get",
    CONTACTS_GET: "/v3/accounts/{recordid}/contacts",
    PRIMARY_CONTACT: "/v3/accounts/primarycontact",
    UNITS_GET: "/v3/accounts/units/get",
    UNITS_WITH_MYCLIENT_ID_GET: "/v3/accounts/getunits",
    AUTOMATIC_CONVERT: "/v3/accounts/automatic/convert",
    ONBOARDING_UNITS_GET: "/v3/accounts/onboardingunits/get",
    CHECK_DUPLICATE_DOMAIN: "/v3/accounts/checkduplicatedomain",
    RECYCLE_BIN_LIST_VIEW: "/v3/accounts/recyclebin/listview",
    PERMANENT_DELETE: "/v3/accounts/recyclebin/batch/delete",
    RESTORE: "/v3/accounts/recyclebin/restore",
    GET_MERGE_FIELDS: "/v3/accounts/get/mergefields",
    RECYCLE_BIN_DETAIL_VIEW: "/v3/accounts/recylebin",
    FRANCHISE_INFO_GET: "/v3/accounts/franchiseinfo/get",
    CHECK_DUPLICATE_FRANCISE: "/v3/accounts/checkduplicatefranchiseId",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
    DOWNLOAD_LEADS_LIST_VIEW: "/v3/accounts/downloadleads/listview",
    DOWNLOAD_LEADS: "/v3/accounts/downloadleads/download",
    IS_FRANCHISE: "/v3/isfranchise",
    DETAIL_VIEW_UNIT_OWNER_TASKS: "/v3/accounts/detailview/unitownertasks/get",
    INXPRESS_CHECK_LOCATION: "/v3/check/cityzipstate",
    TO_XMS: "/v3/accounts/pushaccounttoxms",
    GET_XMS_FIELD_VALUE: "/v3/accounts/getxmsfields",
  },
  CONTACTS: {
    QUERY_FILTER: "/v3/contacts/queryfilter/get",
    MY_GROUP: "/v3/contacts/mygroup/get",
    MY_GROUP_STATUS_SAVE: "/v3/contacts/mygroup/status/save",
    FIELDS: "/v3/contacts/fields",
    LIST_VIEW: "/v3/contacts/get/listview",
    LIST_GET: "/v3/contacts/get",
    ADD_TO_GROUP: "/v3/contacts/addtogroup",
    CAMPAIGN_FILTER: "/v3/contacts/campaignfilter/get",
    SAVE: "/v3/contacts/save",
    EDIT_COPY: "/v3/contacts",
    CONVERT_GET: "/v3/contacts/convert/get",
    CONVERT: "/v3/contacts/convert",
    CONVERT_TO_ACCOUNT: "/v3/contacts/converttoaccounts",
    DELETE: "/v3/contacts/delete",
    DETAIL_VIEW: "/v3/contacts",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/contacts/listviewlayout/save",
    TODAYS_TASKS: "/v3/contacts/todays/task",
    TODAYS_APPOINTMENT: "/v3/contacts/todays/appointment",
    LIST_VIEW_SETUP_GET: "/v3/contacts/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/contacts/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/contacts/customizefields/setup/save",
    MAP_DATA: "/v3/contacts/record/get",
    SAVE_RECENT_RECORD: "/v3/contacts/recent/save",
    RECENT_RECORD_GET: "/v3/contacts/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/contacts/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/contacts/childobjects/setup/save",
    USER_FILTER_GET: "/v3/contacts/filter/user/get",
    BATCH_EDIT: "/v3/contacts/batchedit",
    MERGE_GET: "/v3/contacts/merge/get",
    MERGE_SAVE: "/v3/contacts/merge",
    FILTER_DELETE: "/v3/contacts/filters/delete",
    AUDIT_TRAIL_GET: "/v3/contacts",
    LEAD_SCORE_HISTORY: "/v3/contacts/leadscore/history",
    ADD_PHOTO: "/v3/contacts/add/photo",
    DETAIL_VIEW_TASK_APP: "/v3/contacts/detailview/taskapp/get",
    UNSUBSCRIBE: "/v3/contacts/unsubscribe",
    PARENT_ACCOUNT_CONTACTS_LIST_GET: "/v3/contacts/parentaccount/contacts/get",
    COLUMN_VIEW: "/v3/contacts/columnview/get",
    CONTACT_BY_EMAIL_GET: "/v3/contacts/byemail/get",
    GET_MERGE_FIELDS: "/v3/contacts/get/mergefields",
    GET_SMS_UNSUBSCRIBE_LIST: "/v3/contacts/sms/get/unsubscribeList",
    AUTOMATIC_CONVERT: "/v3/contacts/automatic/convert",
    APPLY_WORKFLOW: "/v3/contacts/applyworkflow",
    CONVERT_GET_DETAILS: "/v3/contacts/convert/get/details",
    BATCH_CONVERT: "/v3/contacts/convert/batch",
    APPLY_WORKFLOW_BATCH: "/v3/contacts/applyworkflow/batch",
    WORKFLOW_EXISTS: "/v3/contacts/isworkflowexists",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/contacts/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/contacts/checkrecordstatus",
    ASSIGN: "/v3/contacts/assign",
    SHARE_WITH_UNITS: "v3/contacts/shareunits",
  },
  OPPORTUNITIES: {
    QUERY_FILTER: "/v3/opportunities/queryfilter/get",
    FIELDS: "/v3/opportunities/fields",
    LIST_VIEW: "/v3/opportunities/get/listview",
    LIST_GET: "/v3/opportunities/get",
    CONVERT_GET: "/v3/opportunities/convert/get",
    CONVERT: "/v3/opportunities/convert",
    BATCH_CONVERT: "/v3/opportunities/convert/batch",
    DELETE: "/v3/opportunities/delete",
    DETAIL_VIEW: "/v3/opportunities",
    SAVE: "/v3/opportunities/save",
    EDIT_COPY: "/v3/opportunities",
    APPLY_WORKFLOW: "/v3/opportunities/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/opportunities/applyworkflow/batch",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/opportunities/listviewlayout/save",
    TODAYS_TASKS: "/v3/opportunities/todays/task",
    TODAYS_APPOINTMENT: "/v3/opportunities/todays/appointment",
    LIST_VIEW_SETUP_GET: "/v3/opportunities/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/opportunities/setup/save",
    SAVE_RECENT_RECORD: "/v3/opportunities/recent/save",
    RECENT_RECORD_GET: "/v3/opportunities/recent/get",
    COLUMN_VIEW: "/v3/opportunities/columnview/get",
    CHILD_OBJECT_SETUP_GET: "/v3/opportunities/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/opportunities/childobjects/setup/save",
    USER_FILTER_GET: "/v3/opportunities/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/opportunities/customizefields/setup/save",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/opportunities/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/opportunities/checkrecordstatus",
    BATCH_EDIT: "/v3/opportunities/batchedit",
    FILTER_DELETE: "/v3/opportunities/filters/delete",
    AUDIT_TRAIL_GET: "/v3/opportunities",
    ADD_PHOTO: "/v3/opportunities/add/photo",
    DETAIL_VIEW_TASK_APP: "/v3/opportunities/detailview/taskapp/get",
    SALESREP_LIST_GET: "/v3/opportunities/salesreplist",
    FORECAST_REPORT_GET: "/v3/opportunities/forcastreport/get",
    OPPORTUNITIES_DETAILS_GET: "/v3/opportunities/opportunitiesdetails/get",
    AUTOMATIC_CONVERT: "/v3/opportunities/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
    CHILD_CUSTOMIZE_FIELDS_SETUP_GET:
      "/v3/opportunities/detailview/child/setup/get",
  },
  CASES: {
    QUERY_FILTER: "/v3/cases/queryfilter/get",
    FIELDS: "/v3/cases/fields",
    LIST_VIEW: "/v3/cases/get/listview",
    LIST_GET: "/v3/cases/get",
    CONVERT_GET: "/v3/cases/convert/get",
    CONVERT: "/v3/cases/convert",
    BATCH_CONVERT: "/v3/cases/convert/batch",
    APPLY_WORKFLOW: "/v3/cases/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/cases/applyworkflow/batch",
    DETAIL_VIEW: "/v3/cases",
    SAVE: "/v3/cases/save",
    EDIT_COPY: "/v3/cases",
    ASSIGN: "/v3/cases/assign",
    DELETE: "/v3/cases/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/cases/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/cases/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/cases/setup/save",
    SAVE_RECENT_RECORD: "/v3/cases/recent/save",
    RECENT_RECORD_GET: "/v3/cases/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/cases/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/cases/childobjects/setup/save",
    USER_FILTER_GET: "/v3/cases/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/cases/customizefields/setup/save",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/cases/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/cases/checkrecordstatus",
    BATCH_EDIT: "/v3/cases/batchedit",
    MERGE_GET: "/v3/cases/merge/get",
    MERGE_SAVE: "/v3/cases/merge",
    FILTER_DELETE: "/v3/cases/filters/delete",
    AUDIT_TRAIL_GET: "/v3/cases",
    DETAIL_VIEW_TASK_APP: "/v3/cases/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/cases/columnview/get",
    AUTOMATIC_CONVERT: "/v3/cases/automatic/convert",
    CASE_CLOSE: "/v3/cases/hub/cases/close/",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  PROJECTS: {
    QUERY_FILTER: "/v3/projects/queryfilter/get",
    FIELDS: "/v3/projects/fields",
    LIST_VIEW: "/v3/projects/get/listview",
    LIST_GET: "/v3/projects/get",
    CONVERT_GET: "/v3/projects/convert/get",
    CONVERT: "/v3/projects/convert",
    BATCH_CONVERT: "/v3/projects/convert/batch",
    APPLY_WORKFLOW: "/v3/projects/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/projects/applyworkflow/batch",
    DETAIL_VIEW: "/v3/projects",
    SAVE: "/v3/projects/save",
    EDIT_COPY: "/v3/projects",
    DELETE: "/v3/projects/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/projects/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/projects/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/projects/setup/save",
    SAVE_RECENT_RECORD: "/v3/projects/recent/save",
    RECENT_RECORD_GET: "/v3/projects/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/projects/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/projects/childobjects/setup/save",
    USER_FILTER_GET: "/v3/projects/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/projects/customizefields/setup/save",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/projects/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/projects/checkrecordstatus",
    BATCH_EDIT: "/v3/projects/batchedit",
    MERGE_GET: "/v3/projects/merge/get",
    MERGE_SAVE: "/v3/projects/merge",
    FILTER_DELETE: "/v3/projects/filters/delete",
    AUDIT_TRAIL_GET: "/v3/projects",
    DETAIL_VIEW_TASK_APP: "/v3/projects/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/projects/columnview/get",
    AUTOMATIC_CONVERT: "/v3/projects/automatic/convert",
    GET_DEP_TASKS: "/v3/projects/get/dep/tasks",
    TIMELINE_VIEW: "/v3/projects/timelineview/get",
    TIMELINE_VIEW_TASK_APP: "/v3/projects/timelineview/get/tasks",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  ISSUES: {
    QUERY_FILTER: "/v3/issues/queryfilter/get",
    FIELDS: "/v3/issues/fields",
    LIST_VIEW: "/v3/issues/get/listview",
    LIST_GET: "/v3/issues/get",
    CONVERT_GET: "/v3/issues/convert/get",
    CONVERT: "/v3/issues/convert",
    BATCH_CONVERT: "/v3/issues/convert/batch",
    APPLY_WORKFLOW: "/v3/issues/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/issues/applyworkflow/batch",
    ASSIGN: "/v3/issues/assign",
    SAVE: "/v3/issues/save",
    DETAIL_VIEW: "/v3/issues",
    EDIT_COPY: "/v3/issues",
    DELETE: "/v3/issues/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/issues/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/issues/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/issues/setup/save",
    SAVE_RECENT_RECORD: "/v3/issues/recent/save",
    RECENT_RECORD_GET: "/v3/issues/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/issues/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/issues/childobjects/setup/save",
    USER_FILTER_GET: "/v3/issues/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/issues/customizefields/setup/save",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/issues/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/issues/checkrecordstatus",
    BATCH_EDIT: "/v3/issues/batchedit",
    MERGE_GET: "/v3/issues/merge/get",
    MERGE_SAVE: "/v3/issues/merge",
    FILTER_DELETE: "/v3/issues/filters/delete",
    AUDIT_TRAIL_GET: "/v3/issues",
    DETAIL_VIEW_TASK_APP: "/v3/issues/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/issues/columnview/get",
    AUTOMATIC_CONVERT: "/v3/issues/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  SOLUTIONS: {
    FIELDS: "/v3/solutions/fields",
    LIST_VIEW: "/v3/solutions/get/listview",
    LIST_GET: "/v3/solutions/get",
    SAVE: "/v3/solutions/save",
    DETAIL_VIEW: "/v3/solutions",
    EDIT_COPY: "/v3/solutions",
    DELETE: "/v3/solutions/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/solutions/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/solutions/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/solutions/setup/save",
    USER_FILTER_GET: "/v3/solutions/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/solutions/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/solutions/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/solutions/childobjects/setup/save",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/solutions/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/solutions/checkrecordstatus",
    BATCH_EDIT: "/v3/solutions/batchedit",
    MERGE_GET: "/v3/solutions/merge/get",
    MERGE_SAVE: "/v3/solutions/merge",
    AUDIT_TRAIL_GET: "/v3/solutions",
  },
  AUTOMATION_DESIGNER: {
    QUERY_FILTER: "/v3/automationcampaigns/queryfilter/get",
    LIST_VIEW: "/v3/automationcampaigns/get/listview",
    LIST_GET: "/v3/automationcampaigns/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/automationcampaigns/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/automationcampaigns/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/automationcampaigns/setup/save",
    AUTOMATION_CAMPAIGN_GET: "/v3/automationcampaigns",
    TEMPLATES_GET: "/v3/automationcampaigns/templates",
    GET_ACTIVE_WEBFORM: "/v3/automationcampaigns/activewebform",
    GET_ACTIVE_LANDINGPAGE: "/v3/automationcampaigns/activelandingpage",
    GET_VERIFIED_EMAIL_IDS: "/v3/automationcampaigns/verifiedemailids",
    SAVE_CAMPAIGN: "/v3/automationcampaigns/save",
    CAN_MSG_BE_DELETED: "/v3/automationcampaigns/canmsgbedeleted",
    FIELDS: "/v3/automationcampaigns/fields",
    BATCH_EDIT: "/v3/automationcampaigns/batchedit",
    DELETE: "/v3/automationcampaigns/delete",
    CHECK_DUPLICATE_NAME: "/v3/automationcampaigns/checkduplicatename",
    SAVE_MESSAGE: "/v3/automationcampaigns/savemessage",
    CHECK_CAMPAIGN_NUMBER_PRESENT: "/v3/twilio/check/campaignnumber"
  },
  WORKFLOW_DESIGNER: {
    QUERY_FILTER: "/v3/workflow/queryfilter/get",
    LIST_VIEW: "/v3/workflow/get/listview",
    LIST_GET: "/v3/workflow/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/workflow/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/workflow/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/workflow/setup/save",
    WORKFLOW_GET: "/v3/workflow/automation",
    TEMPLATES_GET: "/v3/workflow/templates",
    GET_ACTIVE_WEBFORM: "/v3/workflow/activewebform",
    GET_ACTIVE_LANDINGPAGE: "/v3/workflow/activelandingpage",
    GET_VERIFIED_EMAIL_IDS: "/v3/workflow/verifiedemailids",
    SAVE: "/v3/workflow/automation/save",
    CAN_MSG_BE_DELETED: "/v3/workflow/canmsgbedeleted",
    FIELDS: "/v3/workflow/fields",
    BATCH_EDIT: "/v3/workflow/batchedit",
    DELETE: "/v3/workflow/delete",
    CHECK_DUPLICATE_NAME: "/v3/workflow/checkduplicatename",
    SAVE_MESSAGE: "/v3/automationcampaigns/savemessage",
    GET_WORKFLOW: "/v3/workflow/getworkflow",
  },
  WORKFLOW_SETUP: {
    GET_WORKFLOW: "/v3/workflow/getworkflow",
    WORKFLOW_ALL_TASK_LIST: "/v3/workflow/get/alltaskslist",
    WORKFLOW_EMAIL_TEMPLATE_MERGE_FIELD:
      "/v3/workflow/get/emailtemplatemergefield",
    ACCOUNT_WORKFLOW_MERGE_FIELD: "/v3/workflow/get/accountsworkflowmergefield",
    WORKFLOW_CUSTOM_DATE_FORM_ID: "/v3/workflow/get/customdateformfld",
    SAVE: "/v3/workflow/save",
    GET_WORKFLOW_EMAIL_TEMPLATES: "/v3/mail/template/get",
    GET_WORKFLOW_EMAIL_TEMPLATE_BODY: "/v3/mail/template",
  },
  BROADCAST_CAMPAIGNS: {
    QUERY_FILTER: "/v3/broadcastcampaigns/queryfilter/get",
    LIST_VIEW: "/v3/broadcastcampaigns/get/listview",
    LIST_GET: "/v3/broadcastcampaigns/get",
    NEW: "/v3/broadcastcampaigns/new",
    SAVE: "/v3/broadcastcampaigns/save",
    EDIT: "/v3/broadcastcampaigns",
    DELETE: "/v3/broadcastcampaigns/delete",
    SCHEDULE: "/v3/broadcastcampaigns/schedule",
    RESCHEDULE: "/v3/broadcastcampaigns/reschedule",
    EXECUTE: "/v3/broadcastcampaigns/executenow",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/broadcastcampaigns/listviewlayout/save",
    CANCEL_QUEUED: "/v3/broadcastcampaigns/cancel/queued",
    LIST_VIEW_SETUP_GET: "/v3/broadcastcampaigns/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/broadcastcampaigns/setup/save",
    RESULTS: "/v3/reports/marketings/broadcastcampaign",
    FIELDS: "/v3/broadcastcampaigns/fields",
    BATCH_EDIT: "/v3/broadcastcampaigns/batchedit",
    SEND_VERIFICATION_EMAIL: "/v3/broadcastcampaigns/sendverificationemail",
    GET_VERIFIED_EMAIL_IDS: "/v3/broadcastcampaigns/getverifiedemailIds",
    GET_DEFAULT_CAMPAIGN_NAME: "/v3/broadcastcampaigns/getcampaignname",
    COPY_OF_NAME_GET: "/v3/broadcastcampaigns/copyofname/get",
    GET_EDITOR_TYPE: "/v3/broadcastcampaigns/get/editortype",
    GET_PUSHED_CAMPAIGNS: "/v3/broadcastcampaigns/pushedcampaigns/get",
    CHECK_DUPLICATE_BDCAMPAIGN_NAME:
      "/v3/broadcastcampaigns/checkduplicatebdcampname",
    GET_TARGET_RESULT: "/v3/broadcastcampaigns/targetresult",
    CHECK_DUPLICATE_CAMPAIGN_NAME:
      "/v3/broadcastcampaigns/check/duplicate/name",
  },
  TOUCH_CAMPAIGNS: {
    QUERY_FILTER: "/v3/touchcampaigns/queryfilter/get",
    LIST_VIEW: "/v3/touchcampaigns/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/touchcampaigns/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/touchcampaigns/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/touchcampaigns/setup/save",
    RESULTS: "/v3/reports/marketings/touchcampaign",
    DELETE: "/v3/touchcampaigns/delete",
    GET_PUSHED_CAMPAIGNS: "/v3/touchcampaigns/pushedcampaigns/get",
    REPORT_LIST: "/v3/reports/marketings/smscampaign",
  },
  REPORTS: {
    QUERY_DETAIL: "/v3/reports/marketings/querydetail",
    LINK_CLICK_TOTAL_COUNT: "/v3/reports/marketings/linkrecordcount",
    LINK_CLICK_INFO: "/v3/reports/marketings/linkclickinfo",
    RECENT_REPORTS_GET: "/v3/reports/recent/get",
  },
  AB_CAMPAIGNS: {
    QUERY_FILTER: "/v3/abcampaigns/queryfilter/get",
    LIST_VIEW: "/v3/abcampaigns/get/listview",
    LIST_GET: "/v3/abcampaigns/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/abcampaigns/listviewlayout/save",
    NEW: "/v3/abcampaigns/new",
    SAVE: "/v3/abcampaigns/save",
    EDIT: "/v3/abcampaigns",
    DELETE: "/v3/abcampaigns/delete",
    SCHEDULE: "/v3/abcampaigns/schedule",
    RESCHEDULE: "/v3/abcampaigns/reschedule",
    EXECUTE: "/v3/abcampaigns/executenow",
    CANCEL_QUEUED: "/v3/abcampaigns/cancel/queued",
    LIST_VIEW_SETUP_GET: "/v3/abcampaigns/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/abcampaigns/setup/save",
    RESULTS: "/v3/reports/marketings/abcampaign",
    FIELDS: "/v3/abcampaigns/fields",
    BATCH_EDIT: "/v3/abcampaigns/batchedit",
  },
  CUSTOM_TABLE1: {
    QUERY_FILTER: "/v3/customobjectone/queryfilter/get",
    FIELDS: "/v3/customobjectone/fields",
    LIST_VIEW: "/v3/customobjectone/get/listview",
    LIST_GET: "/v3/customobjectone/get",
    CONVERT_GET: "/v3/customobjectone/convert/get",
    CONVERT: "/v3/customobjectone/convert",
    BATCH_CONVERT: "/v3/customobjectone/convert/batch",
    APPLY_WORKFLOW: "/v3/customobjectone/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/customobjectone/applyworkflow/batch",
    DETAIL_VIEW: "/v3/customobjectone",
    SAVE: "/v3/customobjectone/save",
    EDIT_COPY: "/v3/customobjectone",
    DELETE: "/v3/customobjectone/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/customobjectone/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/customobjectone/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/customobjectone/setup/save",
    SAVE_RECENT_RECORD: "/v3/customobjectone/recent/save",
    RECENT_RECORD_GET: "/v3/customobjectone/recent/get",
    USER_FILTER_GET: "/v3/customobjectone/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE:
      "/v3/customobjectone/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/customobjectone/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/customobjectone/childobjects/setup/save",
    BATCH_EDIT: "/v3/customobjectone/batchedit",
    MERGE_SAVE: "/v3/customobjectone/merge",
    MERGE_GET: "/v3/customobjectone/merge/get",
    FILTER_DELETE: "/v3/customobjectone/filters/delete",
    AUDIT_TRAIL_GET: "/v3/customobjectone",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/customobjectone/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/customobjectone/checkrecordstatus",
    DETAIL_VIEW_TASK_APP: "/v3/customobjectone/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/customobjectone/columnview/get",
    AUTOMATIC_CONVERT: "/v3/customobjectone/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  CUSTOM_TABLE2: {
    QUERY_FILTER: "/v3/customobjecttwo/queryfilter/get",
    FIELDS: "/v3/customobjecttwo/fields",
    LIST_VIEW: "/v3/customobjecttwo/get/listview",
    LIST_GET: "/v3/customobjecttwo/get",
    CONVERT_GET: "/v3/customobjecttwo/convert/get",
    CONVERT: "/v3/customobjecttwo/convert",
    BATCH_CONVERT: "/v3/customobjecttwo/convert/batch",
    APPLY_WORKFLOW: "/v3/customobjecttwo/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/customobjecttwo/applyworkflow/batch",
    DETAIL_VIEW: "/v3/customobjecttwo",
    SAVE: "/v3/customobjecttwo/save",
    EDIT_COPY: "/v3/customobjecttwo",
    DELETE: "/v3/customobjecttwo/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/customobjecttwo/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/customobjecttwo/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/customobjecttwo/setup/save",
    SAVE_RECENT_RECORD: "/v3/customobjecttwo/recent/save",
    RECENT_RECORD_GET: "/v3/customobjecttwo/recent/get",
    USER_FILTER_GET: "/v3/customobjecttwo/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE:
      "/v3/customobjecttwo/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/customobjecttwo/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/customobjecttwo/childobjects/setup/save",
    BATCH_EDIT: "/v3/customobjecttwo/batchedit",
    MERGE_SAVE: "/v3/customobjecttwo/merge",
    MERGE_GET: "/v3/customobjecttwo/merge/get",
    FILTER_DELETE: "/v3/customobjecttwo/filters/delete",
    AUDIT_TRAIL_GET: "/v3/customobjecttwo",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/customobjecttwo/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/customobjecttwo/checkrecordstatus",
    DETAIL_VIEW_TASK_APP: "/v3/customobjecttwo/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/customobjecttwo/columnview/get",
    AUTOMATIC_CONVERT: "/v3/customobjecttwo/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  CUSTOM_TABLE3: {
    QUERY_FILTER: "/v3/customobjectthree/queryfilter/get",
    FIELDS: "/v3/customobjectthree/fields",
    LIST_VIEW: "/v3/customobjectthree/get/listview",
    LIST_GET: "/v3/customobjectthree/get",
    CONVERT_GET: "/v3/customobjectthree/convert/get",
    CONVERT: "/v3/customobjectthree/convert",
    BATCH_CONVERT: "/v3/customobjectthree/convert/batch",
    APPLY_WORKFLOW: "/v3/customobjectthree/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/customobjectthree/applyworkflow/batch",
    DETAIL_VIEW: "/v3/customobjectthree",
    SAVE: "/v3/customobjectthree/save",
    EDIT_COPY: "/v3/customobjectthree",
    DELETE: "/v3/customobjectthree/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/customobjectthree/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/customobjectthree/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/customobjectthree/setup/save",
    SAVE_RECENT_RECORD: "/v3/customobjectthree/recent/save",
    RECENT_RECORD_GET: "/v3/customobjectthree/recent/get",
    USER_FILTER_GET: "/v3/customobjectthree/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE:
      "/v3/customobjectthree/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/customobjectthree/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/customobjectthree/childobjects/setup/save",
    BATCH_EDIT: "/v3/customobjectthree/batchedit",
    MERGE_SAVE: "/v3/customobjectthree/merge",
    MERGE_GET: "/v3/customobjectthree/merge/get",
    FILTER_DELETE: "/v3/customobjectthree/filters/delete",
    AUDIT_TRAIL_GET: "/v3/customobjectthree",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/customobjectthree/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/customobjectthree/checkrecordstatus",
    DETAIL_VIEW_TASK_APP: "/v3/customobjectthree/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/customobjectthree/columnview/get",
    AUTOMATIC_CONVERT: "/v3/customobjectthree/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  CUSTOM_TABLE4: {
    QUERY_FILTER: "/v3/customobjectfour/queryfilter/get",
    FIELDS: "/v3/customobjectfour/fields",
    LIST_VIEW: "/v3/customobjectfour/get/listview",
    LIST_GET: "/v3/customobjectfour/get",
    CONVERT_GET: "/v3/customobjectfour/convert/get",
    CONVERT: "/v3/customobjectfour/convert",
    BATCH_CONVERT: "/v3/customobjectfour/convert/batch",
    APPLY_WORKFLOW: "/v3/customobjectfour/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/customobjectfour/applyworkflow/batch",
    DETAIL_VIEW: "/v3/customobjectfour",
    SAVE: "/v3/customobjectfour/save",
    EDIT_COPY: "/v3/customobjectfour",
    DELETE: "/v3/customobjectfour/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/customobjectfour/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/customobjectfour/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/customobjectfour/setup/save",
    SAVE_RECENT_RECORD: "/v3/customobjectfour/recent/save",
    RECENT_RECORD_GET: "/v3/customobjectfour/recent/get",
    USER_FILTER_GET: "/v3/customobjectfour/filter/user/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE:
      "/v3/customobjectfour/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/customobjectfour/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/customobjectfour/childobjects/setup/save",
    BATCH_EDIT: "/v3/customobjectfour/batchedit",
    MERGE_SAVE: "/v3/customobjectfour/merge",
    MERGE_GET: "/v3/customobjectfour/merge/get",
    FILTER_DELETE: "/v3/customobjectfour/filters/delete",
    AUDIT_TRAIL_GET: "/v3/customobjectfour",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/customobjectfour/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/customobjectfour/checkrecordstatus",
    DETAIL_VIEW_TASK_APP: "/v3/customobjectfour/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/customobjectfour/columnview/get",
    AUTOMATIC_CONVERT: "/v3/customobjectfour/automatic/convert",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
  },
  ATTACHMENT: {
    QUERY_FILTER: "/v3/attachment/queryfilter/get",
    FIELDS: "/v3/attachment/fields",
    LIST_VIEW: "/v3/attachment/get/listview",
    CONVERT_GET: "/v3/attachment/convert/get",
    CONVERT: "/v3/attachment/convert",
    BATCH_CONVERT: "/v3/attachment/convert/batch",
    DETAIL_VIEW: "/v3/attachment",
    SAVE: "/v3/attachments/save",
    EDIT_COPY: "/v3/attachment",
    ASSIGN: "v3/attachment/assign",
    DELETE: "/v3/attachment/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/attachment/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/attachment/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/attachment/setup/save",
    SAVE_RECENT_RECORD: "/v3/attachment/recent/save",
    RECENT_RECORD_GET: "/v3/attachment/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/attachment/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/attachment/childobjects/setup/save",
    UPLOAD_ATTACHMENT: "/v3/attachment/upload",
    DOWNLOAD_ATTACHMENT: "/v3/attachment/download",
    DOWNLOAD: "/v3/library/download",
    NOTE_ATTACHMENT_DELETE: "/v3/note/delete",
    DOWNLOAD_RECORDING: "/v3/download/callrecord",
    DOWNLOAD_ZIP: "/v3/library/download/zip",
    LIBRARY_LIST_VIEW: "/v3/library/get/listview",
    LIBRARY_LIST_VIEW_LAYOUT_CHANGE: "/v3/library/listviewlayout/save",
    GET_CARD_DETAILS: "/v3/scanner/getcarddetails",
    GET_TEMPLATE: "/v3/attachment/get/template",
    SAVE_TEMPLATE: "/v3/attachment/save/template",
    DELETE_TEMPLATE: "/v3/attachment/delete/template",
    DOWNLOAD_PDF: "/v3/attachment/downloadpdf/template",
    GET_ATTACHMENT_AGREEMENT: "/v3/attachment/agreement/get",
    GET_ATTACHMENT_SIGN_STATUS: "/v3/attachment/signstatus/get",
    GET_ATTACHMENT_URL: "/v3/attachment/get/attachmenturl",
    GET_ACCOUNT_CONTACT_ID: "/v3/attachment/get/contactid",
    GET_ATTACHMENT_TYPE: "/v3/attachment/type/get",
    GET_TASK_ATTACHMENT: "/v3/tasks/attachments/get",
    GET_AGREEMENT_TYPE: "/v3/attachment/get/agreementtype",
  },
  PRODUCTS: {
    FIELDS: "/v3/products/fields",
    LIST_VIEW: "/v3/products/get/listview",
    LIST_GET: "/v3/products/get",
    DETAIL_VIEW: "/v3/products",
    SAVE: "/v3/products/save",
    EDIT_COPY: "/v3/products",
    DELETE: "/v3/products/delete",
    ASSIGN: "/v3/products/assign",
    CONVERT_GET: "/v3/products/convert/get",
    CONVERT: "/v3/products/convert",
    BATCH_CONVERT: "/v3/products/convert/batch",
    QUERY_FILTER: "/v3/products/queryfilter/get",
    SAVE_RECENT_RECORD: "/v3/products/recent/save",
    RECENT_RECORD_GET: "/v3/products/recent/get",
    LIST_VIEW_SETUP_GET: "/v3/products/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/products/setup/save",
    FILTER_DELETE: "/v3/products/filters/delete",
    BATCH_EDIT: "/v3/products/batchedit",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/products/listviewlayout/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/products/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/products/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/products/childobjects/setup/save",
    MERGE_GET: "/v3/products/merge/get",
    MERGE_SAVE: "/v3/products/merge",
    PULL_QB_ITEMS: "/v3/products/pull/qb/items",
    PUSH_TO_QB: "/v3/products/push/qb/items",
    CHECK_DUPLICATE_PRODUCT_NAME: "/v3/products/checkduplicate/productname",
    GET_PRODUCT_TYPE: "/v3/products/get/producttype",
    CHECK_DUPLICATE_PRODUCTS: "/v3/products/check/duplicate/products",
  },
  INVOICES: {
    QUERY_FILTER: "/v3/invoices/queryfilter/get",
    FIELDS: "/v3/invoices/fields",
    LIST_VIEW: "/v3/invoices/get/listview",
    LIST_GET: "/v3/invoices/get",
    DETAIL_VIEW: "/v3/invoices",
    SAVE: "/v3/invoices/save",
    EDIT_COPY: "/v3/invoices",
    DELETE: "/v3/invoices/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/invoices/listviewlayout/save",
    DOWNLOAD: "/quickbook/download",
  },
  USERS: {
    USERLIST_GET: "/v3/users",
    VERIFIED_EMAILS_GET: "/v3/users/verifiedemails/get",
    PROFILE_DETAILS_GET: "/v3/users/profile/",
    PROFILE_DETAILS_SAVE: "/v3/users/profile/save",
    ADD_PHOTO: "/v3/users/profile/add/photo",
    SALESREP_LIST_GET: "/v3/users/salesreplist",
    GET_EMAIL_SIGNATURE: "/v3/emailsignatures/get",
    SAVE_EMAIL_SIGNATURE: "/v3/emailsignatures/save",
    LIST_VIEW: "/v3/users/get/listview",
    USER_LIST_VIEW_LAYOUT_CHANGE: "/v3/users/listviewlayout/save",
    SAVE_LOGO: "/v3/setup/logo/save",
    TENANTUSER_GET: "/v3/users/tenantuser/get",
    SAVE_COMPANY_PROFILE: "/v3/setup/save/companyprofile",
    GET_COMPANY_PROFILE: "/v3/setup/get/companyprofile",
    SAVE_ROLLUP: "/v3/setup/rollUp/save",
    GET_ROLLUP: "/v3/setup/rollUp/get",
    GET_ROLLUP_LIST_VIEW: "/v3/setup/rollup/get/listview",
    DELETE_ROLL_UP: "/v3/setup/rollup/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/setup/rollup/listviewlayout/save",
    CALENDAR_SETUP_VIEW: "v3/users/save/calendarviewsetup",
    EDIT_ROLLUP_RULES: "v3/setup/rollup",
    USERLIST_WITH_SECURITY_LEVEL_GET: "/v3/users/securitylevels/get",
  },
  SECURITY_LEVEL: {
    LIST_VIEW: "/v3/securitylevel/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/securitylevel/listviewlayout/save",
    GET_SECURITY_LEVEL_DETAILS: "/v3/securitylevel/getsecurityleveldetails",
    SAVE_PERMISSION: "/v3/securitylevel/saveUserTemplateFilterPermission",
  },
  WHITE_LABEL: {
    SAVE_HELP_URL: "v3/common/helpUrl/save",
    GET_HELP_URL: "v3/common/helpUrl/get",
  },
  GROUP: {
    LIST: "/v3/groups/get",
    SAVE: "/v3/groups/save",
    FIELDS: "/v3/groups/fields",
    LIST_VIEW: "/v3/groups/get/listview",
    LIST_GET: "/v3/groups/get",
    DETAIL_VIEW: "/v3/groups",
    BATCH_EDIT: "/v3/groups/batchedit",
    GROUP_CAMPAIGN_DETAILS: "/v3/groups/groupcampaigndetails",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/groups/customizefields/setup/save",
    EDIT_COPY: "/v3/groups/",
    SAVE_GROUP: "/v3/groups/saveorupdate",
    GROUP_USEDIN_CAMPAIGN: "/v3/groups/groupusedincampaigns",
    DELETE_GROUP: "/v3/groups/detailview/deletegroup",
    GROUP_LINK_OBJECTS: "/v3/groups/linkobjectinfo/get",
    LIST_VIEW_SETUP_GET: "/v3/groups/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/groups/setup/save",
    REMOVE_LEADS_CONTACTS: "/v3/groups/removeleadsandcontactfromgroup",
  },
  FILTERS: {
    LIST: "/v3/filters/get",
  },
  WEB_FORMS: {
    LIST_VIEW: "/v3/webforms/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/webforms/listviewlayout/save",
    DELETE: "/v3/webforms/delete",
    PUBLISH: "/v3/webforms/publish",
    UNPUBLISH: "/v3/webforms/unpublish",
  },
  FORM: {
    LIST_VALUES: "/v3/form/get/listvalues",
    APPOINTMENT_TYPE_LIST: "/v3/form/get/appointmenttype/listvalues",
    NOTE_TYPE_LIST: "/v3/form/get/notetype/listvalues",
  },
  COUPONS: {
    FIELDS: "/v3/coupons/fields",
    LIST_VIEW: "/v3/coupons/get/listview",
    LIST_VIEW_SETUP_GET: "/v3/coupons/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/coupons/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/coupons/listviewlayout/save",
    SAVE: "/v3/coupons/save",
    DETAIL_VIEW: "/v3/coupons",
    SAVE_RECENT_RECORD: "/v3/coupons/recent/save",
    CHILD_OBJECT_SETUP_GET: "/v3/coupons/childobjects/setup/get",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/coupons/customizefields/setup/save",
    CHILD_OBJECT_SETUP_SAVE: "/v3/coupons/childobjects/setup/save",
    GET: "/v3/coupons/get",
    DELETE: "/v3/coupons/{recordid}/delete",
    UPDATE_STATUS: "/v3/coupons/{recordid}/update/status",
    CHECK_DUPLICATE_COUPONS: "/v3/coupons/checkduplicatecoupons",
  },
  CLAIMS: {
    FIELDS: "/v3/claims/fields",
    LIST_VIEW: "/v3/claims/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/coupons/listviewlayout/save",
  },
  LANDING_PAGE: {
    VALID_USER: "/v3/users/landing/isvalid",
    GET: "/v3/landingpage/get",
    SAVE: "/v3/landingpage/save",
    LIST_VIEW: "/v3/landingpage/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/landingpage/listviewlayout/save",
    DELETE: "/v3/landingpage/delete",
    TEMPLATE_LIBRARY: "/v3/landingpage/library",
    UPDATE_STATUS: "/v3/landingpage/update/status",
    CHECK_DUPLICATE: "/v3/landingpage/check/duplicate",
    URL_LIST: "/v3/landingpage/get/page/list",
  },
  WORKFLOWS: {
    LIST_GET: "/v3/workflow/get/list",
    LIST_STAGES_GET: "/v3/workflow/get/list/stages",
    LIST_STAGES_DETAILS_GET: "/v3/workflow/get/list/stages/details",
    LIST_TASKS_GET: "/v3/workflow/get/list/tasks/details",
    LIST_VIEW: "/v3/workflow/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/workflow/listviewlayout/save",
    CHANGE_STATUS: "/v3/workflow/update/record",
    DELETE: "/v3/workflow/delete",
    SHARE: "/v3/workflow/share",
    GET_REQUIRED_FIELDS: "/v3/workflow/get/requiredfields",
    GET_DENIAL_INQUIRY_MAIL_CONTENT: "/v3/workflow/greenlight/email/content",
  },
  SEARCH: {
    OBJECTS_GET: "/v3/home/objectlist",
    RECORD_COUNT_GET: "/v3/search/recordcount/get",
    SAVE_RECENT_ADVANCED_SEARCH: "/v3/search/recentadvancedsearch/save",
    GET_RECENT_ADVANCED_SEARCH: "/v3/search/recentadvancedsearch/get",
    DELETE_RECENT_ADVANCED_SEARCH: "/v3/search/recentadvancedsearch/delete",
  },
  SEND_MAIL: {
    TEST: "/v3/mail/test",
    TEST_AUTOMATION: "/v3/mail/test/automation",
    SEND: "/v3/mail/send",
    ATTACHMENT_UPLOAD: "/v3/mail/attachment/upload",
    TEMPLATE_CATEGORIES_GET: "/v3/mail/template/category/get",
    TEMPLATE_GET: "/v3/mail/template/get",
    LIBRARY_TEMPLATE_GET: "/v3/mail/librarytemplate/get",
    SIGNATURE_GET: "/v3/mail/emailsignature/get",
    MAIL_TEMPLATE_GET: "/v3/mail/template",
    VERIFYSTATUS: "/v3/mail/getEmailStatusVerify",
    AUTOCOMPLETE_GET: "/v3/mail/autocomplete/get",
    AUTOCOMPLETE_GET_TO: "/v3/mail/autocomplete/get/to",
    AUTOCOMPLETE_GET_CC: "/v3/mail/autocomplete/get/cc",
    DIRECT_EMAIL_SIGNATURE: "/v3/mail/getsignature",
    SCHEDULE: "/v3/mail/scheduledEmails",
    DELETE: "/v3/mail/delete",
    DELETE_VERIFY: "/v3/mail/verify/delete",
    GET_SCHEDULE_EMAILS: "/v3/mail/get/scheduledEmails",
    SAVE_SCHEDULE_EMAILS: "/v3/mail/save/scheduledEmails",
    RESCHEDULE: "/v3/mail/reschedule",
  },
  WEBSITE_TRACKING: {
    GENERATE_TRACKING_CODE: "/v3/webanalytics/save",
    UPDATE_STATUS: "/v3/webanalytics/update/status",
    IS_EXISTS: "/v3/webanalytics/is/exits",
  },
  DOCUMENT_LIBRARY: {
    LIST: "/v3/imagelibrary/get",
    UPLOAD: "/v3/imagelibrary/upload",
    DELETE: "/v3/library/image/delete",
    EDIT: "/v3/library/image/edit",
    CATEGORY_LIST: "/v3/imagelibrary/categoryList",
    DOCUMENT_LIST: "/v3/attachment/get",
    UPLOAD_DOCUMENT: "/v3/library/upload",
    EXTERNAL_DOCUMENT: "/v3/common/externaldocument/",
    GET_DOCUMENT_EXTERNAL_LINK: "/v3/library/getexternallink",
    ATTACHMENT_CHANGE_BUCKET: "/v3/library/changebucket",
    DOWNLOAD_FOLDER: "/v3/library/download/folder",
    DOWNLOAD_IMAGE: "/v3/imagelibrary/download",
    DOWNLOAD_DOCUMENT: "/v3/common/downloadexternaldocument",
    ASSETS_GET: "/v3/assetstree/get",
    DUPLICATE_CHECK: "/V3/assetstree/duplicate",
    ASSETS_FOLDER_SAVE: "/v3/assetsfolder/save",
    ASSETS_MENU_UPDATE: "/v3/assetsmenu/update",
    ASSETS_FOLDER_DELETE: "/v3/assetsmenu/folder/delete",
    ASSETS_FOLDER_RENAME: "/v3/assetsmenu/folder/rename",
    ATTACHMENT_DELETE: "/v3/document/delete",
    ATTACHMENT_CHANGE_BUCKET_FOR_SMS: "/v3/library/sms/changebucket",
    LIBRARY_IMAGES_GET: "/v3/images/get",
    SHARE_WITH: "/v3/document/share",
    GET_SHARE_DETAILS: "/v3/sharedetails/get",
  },
  RECENT_FILTER: {
    ACCOUNTS: "/v3/accounts/recent/filter/get",
    CONTACTS: "/v3/contacts/recent/filter/get",
    OPPORTUNITIES: "/v3/opportunities/recent/filter/get",
    PRODUCTS: "/v3/products/recent/filter/get",
    CUSTOM_TABLE1: "/v3/customobjectone/recent/filter/get",
    CUSTOM_TABLE2: "/v3/customobjecttwo/recent/filter/get",
    CUSTOM_TABLE3: "/v3/customobjectthree/recent/filter/get",
    CUSTOM_TABLE4: "/v3/customobjectfour/recent/filter/get",
    CASES: "/v3/cases/recent/filter/get",
    ISSUES: "/v3/issues/recent/filter/get",
    PROJECTS: "/v3/projects/recent/filter/get",
    SALES_LEADS: "/v3/salesleads/recent/filter/get",
    ORDERS: "/v3/orders/recent/filter/get",
    QUOTATIONS: "/v3/quotations/recent/filter/get",
    PURCHASE_ORDER: "/v3/purchaseorder/recent/filter/get",
    SALES_ORDER: "/v3/salesorder/recent/filter/get",
    INVENTORY: "/v3/inventory/recent/filter/get",
    JOBS: "/v3/jobs/recent/filter/get",
    COMPLIANCE: "/v3/compliance/recent/filter/get",
    SALES_EMAIL_TEMPLATE: "v3/templates/sales-templates/recent/get",
    SALES_CALL_SCRIPTS: "v3/templates/call-scripts/recent/get",
    SALES_MARKETING_EMAIL_TEMPLATE:
      "v3/templates/marketing-templates/recent/get",
    SALES_SMS_TEMPLATE: "v3/templates/sms-templates/recent/get",
    SALES_AGREEMENT_TEMPLATE: "v3/templates/agreement-templates/recent/get",
    PARTNERS: "/v3/partners/recent/filter/get",
  },
  RELATIONSHIP: {
    RELATIONSHIP_INFO: "/v3/relations/get",
  },
  SOURCE: {
    LIST_GET: "/v3/sources/get",
    SAVE: "/v3/sources/save",
    LIST_VIEW: "/v3/sources/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/sources/listviewlayout/save",
    DELETE: "/v3/sources/delete",
    EDIT: "/v3/sources/getRecordById",
    GET_PRIMARY_SOURCE:'/v3/sources/get/list',
  },
  LOCATION: {
    LIST_GET: "/v3/locations/get",
    SAVE: "/v3/locations/save",
    LIST_VIEW: "/v3/locations/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/locations/listviewlayout/save",
    DELETE: "/v3/locations/delete",
    EDIT: "/v3/locations/getRecordById",
    GET_AVAILABEL_LOCATIONS: "/v3/locations/availablelocations",
  },
  TASKS_INFO: {
    SAVE: "/v3/tasksinfo/save",
    LIST_VIEW: "/v3/tasksinfo/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/tasksinfo/listviewlayout/save",
    DELETE: "/v3/tasksinfo/delete",
    EDIT: "/v3/tasksinfo/getRecordById",
    FIELDS: "/v3/tasksinfo/fields",
    GET_DESCRIPTION: "/v3/tasksinfo/getdescriptionbyid",
  },
  NOTE_TYPE: {
    LIST_VIEW: "/v3/notetypes/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/notetypes/listviewlayout/save",
    DELETE: "/v3/notetypes/delete",
    SAVE: "/v3/notetypes/save",
    EDIT: "/v3/notetypes/getRecordById",
  },
  COMPANY_SCHEDULER: {
    SAVE_COMPANY_SCHEDULER_AVAILABILITY:
      "/v3/scheduler/company/availablity/save",
    GET_COMPANY_SCHEDULER_AVAILABILITY: "/v3/scheduler/company/availablity/get",
    GET_COMPANY_SCHEDULER_AVAILABILITY_USERID:
      "/v3/scheduler/company/availablity/getbyid",
    GET_COMPANY_SCHEDULER_AVAILABILITY_ZIPCODE:
      "/v3/scheduler/company/availablity/getbyZipcode",
    DELETE_COMPANY_SCHEDULER_AVAILABILITY_BY_USERID:
      "/v3/scheduler/company/availablity/deletebytechnicianid",
    DELETE_COMPANY_SCHEDULER_AVAILABILITY_BY_ZIPCODE:
      "/v3/scheduler/company/availablity/deletebyzipcode",
    SAVE_COMPANY_SCHEDULER_APPOINTMENT_TYPES:
      "/v3/scheduler/company/appointmenttype/save",
    GET_COMPANY_SCHEDULER_APPOINTMENT_TYPES:
      "/v3/scheduler/company/appointmenttype/get",
    GET_COMPANY_SCHEDULER_APPOINTMENT_TYPES_BYNAME:
      "/v3/scheduler/company/appointmenttype/getbyname",
    DELETE_COMPANY_SCHEDULER_APPOINTMENT_TYPES:
      "/v3/scheduler/company/appointmenttype/delete",
    SAVE_COMPANY_SCHEDULER_FIELDS: "/v3/scheduler/company/fields/save",
    GET_COMPANY_SCHEDULER_FIELDS: "/v3/scheduler/company/fields/get",
    GET_COMPANY_SCHEDULER_LINK: "/v3/scheduler/company/slug/get",
    GET_AVAILABLE_USERS: "/v3/scheduler/company/availableuserbylocation/get",
  },
  CAMPAIGN_TYPES: {
    LIST_VIEW: "/v3/campaigntypes/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/campaigntypes/listviewlayout/save",
    SAVE: "/v3/campaigntypes/save",
    DELETE: "/v3/campaigntypes/delete",
    EDIT: "/v3/campaigntypes/getRecordById",
  },
  CORPORATE_CURRENCY: {
    GET_CURRENCY_LIST: "/v3/setup/getCurrencyList",
    SAVE: "/v3/setup/saveCurrency",
  },
  RELATIONS: {
    LIST_VIEW: "/v3/relations/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/relations/listviewlayout/save",
    SAVE: "/v3/relations/save",
    DELETE: "/v3/relations/delete",
    EDIT: "/v3/relations/getRecordById",
  },
  IMPORT: {
    SELECT_FILE: "/v3/imports/select",
    SAVE_MAPPING: "/v3/imports/savemapping",
    GET_SAVE_MAPPING: "/v3/imports/getsavedmapping",
    USE_SAVE_MAPPING: "/v3/imports/usesavedmapping",
    DELETE_MAPPING: "/v3/imports/deletemapping",
    IMPORT_RECORDS: "/v3/imports/saveimport",
    SEND_MAIL: "/v3/imports/sendmail",
    IMPORT_GREEN_FIELD: "/v3/imports/greenfields",
    IMPORT_GREEN_FIELD_STATUS: "/v3/imports/getimportstatus",
    IMPORT_ASSIGN_SAVE :"/v3/imports/salesleads/assign"
  },
  FILTER: {
    SAVE: "/v3/filters/save",
    LIST_VIEW_SETUP_GET: "/v3/filters/setup/get",
    EDIT: "/v3/filters/",
    CHECK_USED_IN_CAMPAIGN: "/v3/filters/checkedcampaign",
    DELETE: "/v3/filters/delete",
  },

  INXPRESS_REPORT: {
    FRANCHISE_LIST: "/v3/reports/franchiseList",
    FRANCHISE_WF_LIST: "/v3/reports/franchiseWFList",
    INXPRESS_REPORT_GET: "/v3/reports/inxpressReport",
  },

  NOTIFICATION: {
    CONFIGURATION_GET: "/v3/managenotifications/get",
  },
  MARKETING_INTEGRATION: {
    GET: "/v3/marketingintegration/get",
    HAS_CONFIGURED: "/v3/marketingintegration/hasconfigured",
    VERIFY_USER: "/v3/marketingintegration/verifyuser",
    SESSION_TRANSFER: "/v3/marketingintegration/session-transfer",
  },
  CALLING_INTEGRATION: {
    GENERATE_CAPABILITY_TOKEN: "/v3/twilio/generate/capabilitytoken",
    OUTBOUND_PHONE_NUMBERS_GET: "/v3/twilio/outbound/phonenumbers/get",
    REMAINING_CALL_MINUTES_GET: "/v3/twilio/remainingcallminutes/get",
    CALL_SCRIPT_LIST_GET: "/v3/twilio/callscript/get",
    CALL_SCRIPT_DETAILS_GET: "/v3/twilio/callscript/details/get",
    NOTE_SAVE_AFTER_CALL: "/v3/twilio/note/save",

    OUTBOUND_PHONE_LIST_GET: "/v3/twilio/outbound/phonenlist/get",
    VERIFY_OUTGOING_CALLER_ID: "/v3/twilio/verify/outgoing/callerid",
    CANCEL_VERIFICATION_CALL: "/v3/twilio/cancel/verification",
    OUTBOUND_CALLERID_SID_GET: "/v3/twilio/outboundcalleridsid/get",
    OUTBOUND_CALLERID_DELETE: "/v3/twilio/outgoingcallerid/delete",
    CHECK_DUPLICATE_OUTBOUND_PHONE: "/v3/twilio/checkduplicate/phonenumber",
    OUTBOUND_PHONE_ENABLE_ONOFF: "/v3/twilio/outboundphone/enable/onoff",
    DELETE_OUTGOING_CALLER_ID: "/v3/twilio/outgoingcallerid/delete",
    CALL_RECORDING_SAVE: "/v3/twilio/callrecording/save",
    CALL_RECORDING_GET: "/v3/twilio/callrecording/get",
    OUTBOUND_PHONE_VERIFICATION_STATUS:
      "/v3/twilio/outboundphone/verification/status",
    SAVE_POWER_CALLING_RECORD_SETUP: "/v3/twilio/powercalling/setup/save",
    DELETE_POWER_CALLING_RECORD_SETUP: "/v3/twilio/powercalling/setup/delete",
  },
  POWER_CALLING: {
    GET_POWER_CALLING_RECORD_DETAIL: "/v3/twilio/multiplerecords/get",
  },
  EMAIL_INTEGRATION: {
    CONFIGURATION_GET: "/v3/emailintegration/get",
    REDIRECT_URI: "/v3/emailintegration/getnylasresponse",
    CONFIG_DATA_GET: "/v3/emailintegration/configdetails",
    DISCONNECT_NYLAS_API: "/v3/emailintegration/disconnect",
    SAVE_CALENDAR: "/v3/emailintegration/savecalendar",
    GET_GOOGLE_SESSION_TOKEN: "/v3/emailintegration/getsessiontoken",
    CONTACT_GROUP_EXISTS: "/v3/emailintegration/checkifcontactgroupexists",
    CALENDAR_LIST: "/v3/emailintegration/calendars",
    CONTACT_FILTERS: "/v3/emailintegration/filters",
    LIST_VIEW: "/v3/emailintegration/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/emailintegration/listviewlayout/save",
    CHECK_DUPLICATE_EMAIL: "/v3/emailintegration/checkduplicatemail",
    SAVE_EMAIL: "/v3/emailintegration/save/email",
    SEND_VERIFICATION_EMAIL: "/v3/emailintegration/sendverificationemail",
    DELETE_EMAIL: "/v3/emailintegration/delete/email",
    EDIT: "/v3/emailintegration/get/email",
  },
  DOCUSIGN: {
    CONFIGURATION_GET: "/v3/docusign/config/get",
    TEMPLATE_LIST_GET: "/v3/docusign/templatelist/get",
    CONFIGURATION_SET: "/v3/docusign/config/save",
    ENVELOP_LIST_GET: "/v3/docusign/envelop/get",
    ENVELOP_SEND: "/v3/docusign/envelop/send",
    DISCONNECT_DOCUSIGN: "/v3/docusign/disconnect",
  },
  EMAIL: {
    FIELDS: "/v3/email/fields",
    LIST_VIEW: "/v3/email/get/listview",
    LIST_GET: "/v3/email/get",
  },
  CALL_SCRIPT: {
    GET_WORKFLOW_LIST: "/v3/callscripts/getWFList",
    SAVE: "/v3/callscripts/save",
    GET_CALL_SCRIPT_LIST: "/v3/callscripts/get/listview",
    GET_CALL_SCRIPT: "/v3/callscripts",
    DELETE: "/v3/callscripts/delete",
    EDIT: "/v3/callscripts",
    ACTIVE: "/v3/callscripts/active",
    INACTIVE: "/v3/callscripts/inactive",
  },
  SMS_INTEGRATION: {
    GET_AVAILABLE_NUMBERS: "/v3/twilio/sms/capabled/availablephonenumbers/get",
    PURCHASE_PHONE_NUMBER: "/v3/twilio/purchase/phonenumber",
    REMOVE_PHONE_NUMBER: "/v3/twilio/remove/phonenumber",
    GET_ALLOCATED_PHONE_NUMBERS: "/v3/twilio/allocated/phonenumber/get",
    GET_SMS: "/v3/twilio/sms/get",
    REMAINING_SMS_COUNT_GET: "/v3/twilio/remainingsmscount/get",
    SEND_SMS: "/v3/twilio/send/sms",
    BATCH_SMS: "v3/twilio/batch/sms",
    DELETE: "v3/twilio/delete/sms",
    UPDATE_REDIRECT_NUMBER: "v3/twilio/update/redirectnumber",
    UPDATE_READ_COUNT: "/v3/twilio/sms/readcount/update",
    CHECK_PHONE_NUMBER_STATUS: "v3/twilio/check/phonenumberstatus",
    GET_TO_NUMBERS: "/v3/twilio/sms/tonumbers/get",
    GET_CONTACT_LIST: "/v3/twilio/contactlist/get",
    TOLL_FREE_VERIFICATION: "/v3/twilio/tollfree/verification",
    GET_IMPORT_TWILIO_NUMBERS: "/v3/twilio/numbers/import/get",
    SAVE_IMPORT_TWILIO_NUMBERS: "/v3/twilio/numbers/import/save",
    GET_FE_DETAILS: "/v3/twilio/get/fedetails",
  },
  EVENTBRITE_INTEGRATION: {
    USER_INFO_GET: "/v3/eventbrite/get/userinfo",
    DISCONNECT: "/v3/eventbrite/disconnect",
    USER_ORGANISATIONS: "/v3/eventbrite/user/organisations",
    UPDATE_DETAILS: "/v3/eventbrite/details/update",
    LIST_VIEW: "/v3/eventbrite/get/listview",
    FIELDS: "/v3/eventbrite/fields",
  },
  QUICKBOOKS_INTEGRATION: {
    CONNECT: "/quickbook/connectToQuickbooks",
    GET: "/quickbook/get",
    IS_CONNECTED: "/quickbook/isconnected",
    SAVE_FIELD_MAPPING: "/quickbook/mapping/save",
    UPDATE_DETAILS: "/quickbook/details/update",
    DISCONNECT: "/quickbook/disconnect",
  },
  PAYMENT_INTEGRATION: {
    CHECK_CONNECT: "/stripe/isPaymentAPIAvailable",
    DISCONNECT_ONBOARD: "/stripe/disconnectPaymentAPI",
    ONBOARD: "/stripe/connectPaymentAPI",
    PREPARE_PAYMENT_DATA: "/stripe/preparePaymentData",
    GET_PAYMENT_STATUS_QUOTATION: "/stripe/getPaymentStatus",
    SAVE_PAYEMENT_METHOD: "/stripe/save/payementmethod",
    MONERIS_PROCESS_PAYMENT: "moneris/processpayment",
    MONERIS_CALLBACK: "moneris/callback",
    MONERIS_CONNECT: "moneris/connect",
    MONERIS_DISCONNECT: 'moneris/disconnectPaymentAPI',
    MONERIS_TESTING_CDN_LINK:'https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js',
    MONERIS_PRODUCTION_CDN_LINK:'https://gateway.moneris.com/chkt/js/chkt_v1.00.js'
  },
  XERO_INTEGRATION: {
    CONNECT: "/xero/connectToXero",
    GET: "/xero/get",
    IS_CONNECTED: "/xero/isconnected",
    //SAVE_FIELD_MAPPING: '/quickbook/mapping/save',
    UPDATE_DETAILS: "/xero/details/update",
    DISCONNECT: "/xero/disconnect",
  },
  EMAIL_MARKETING_REPORT: {
    GET_CONNECTED_CLIENT: "/v3/reports/connectedclient/get",
    RESULTS: "/v3/reports/marketings/campaignreport",
    IS_CONNECT_ENABLED: "/v3/reports/isclientconnected",
  },
  OPERATIONS_REPORT: {
    OPERATION_REPORT_GET: "/v3/reports/operationsreports",
    HEADERS_GET: "/v3/reports/operationsreportsheaders",
  },
  TASKS_REPORT: {
    TASK_REPORT_GET: "/v3/reports/tasksreport",
  },
  ORTHOTIC_REPORT: {
    ORTHOTIC_REPORT_GET: "/v3/reports/orthoticreport",
    ORTHOTIC_PRODUCT_LIST_GET: "/v3/reports/getOrthoticProductList",
  },
  SALES_LEADS: {
    QUERY_FILTER: "/v3/salesleads/queryfilter/get",
    LIST_VIEW: "/v3/salesleads/get/listview",
    LIST_GET: "/v3/salesleads/get",
    SAVE_RECENT_RECORD: "/v3/salesleads/recent/save",
    SAVE: "/v3/salesleads/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/salesleads/listviewlayout/save",
    DELETE: "/v3/salesleads/delete",
    DETAIL_VIEW: "/v3/salesleads",
    FIELDS: "/v3/salesleads/fields",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/salesleads/customizefields/setup/save",
    LIST_VIEW_SETUP_GET: "/v3/salesleads/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/salesleads/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/salesleads/childobjects/setup/get",
    RECENT_RECORD_GET: "/v3/salesleads/recent/get",
    EDIT_COPY: "/v3/salesleads",
    MERGE_GET: "/v3/salesleads/merge/get",
    MERGE_SAVE: "/v3/salesleads/merge",
    CHILD_OBJECT_SETUP_SAVE: "/v3/salesleads/childobjects/setup/save",
    BATCH_EDIT: "/v3/salesleads/batchedit",
  },
  EMAIL_REPORT: {
    EMAIL_REPORT_GET: "/v3/reports/emailreport",
    DRILLDOWN_GET: "/v3/reports/emailreport/drilldown",
  },
  SMS_REPORT: {
    SMS_REPORT_GET: "/v3/reports/smsreport",
    DRILLDOWN_GET: "/v3/reports/smsreport/drilldown",
  },
  ASSIGNMENT_RULE: {
    SAVE: "/v3/assignmentrule/save",
    LIST_VIEW: "/v3/assignmentrule/get/listview",
    EDIT: "/v3/assignmentrule/",
    DELETE: "/v3/assignmentrule/delete",
    IS_RULE_EXISTS: "/v3/assignmentrule/isruleexists",
    GET_RULES: "/v3/assignmentrule/get/rules",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/assignmentrule/listviewlayout/save",
  },
  COUPONS_REPORT: {
    COUPONS_REPORT_GET: "/v3/reports/couponsreport",
    HEADERS_GET: "/v3/reports/couponsreportheaders",
  },

  NOTIFICATIONS_REMINDER: {
    SAVE: "/v3/managenotifications/reminder/save",
    GET: "/v3/managenotifications/reminder/get",
    NOTIFICATION_GET: "/v3/managenotifications/notifications/get",
  },
  PIPELINE_REPORT: {
    PIPELINE_REPORT_GET: "/v3/reports/pipelinereport",
  },
  NEWS: {
    LIST_VIEW: "/v3/news/get/listview",
    LIST_GET: "/v3/news/get",
    SAVE: "/v3/news/save",
    EDIT: "/v3/news/",
    DELETE: "/v3/news/delete",
    FIELDS: "/v3/news/fields",
    LIST_VIEW_SETUP_GET: "/v3/news/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/news/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/news/listviewlayout/save",
    GET: "/v3/news/getnews",
    UPLOAD_IMAGE: "/v3/news/upload/image",
  },
  USEFUL_LINKS: {
    GET_USEFUL_LINKS: "/v3/usefullink/get",
    GET_CATEGORIES: "/v3/usefullink/categories/get",
    DELETE_CATEGORY: "/v3/usefullink/delete",
    SAVE: "/v3/usefullink/save",
  },
  HUB_SETUP: {
    GET: "/v3/setup/hubsetup/get",
    SAVE: "/v3/setup/hubsetup/save",
  },
  PETS: {
    LIST_VIEW: "/v3/pets/get/listview",
    LIST_GET: "/v3/pets/get",
    FIELDS: "/v3/pets/fields",
    EDIT_COPY: "/v3/pets",
    SAVE: "/v3/pets/save",
    DETAIL_VIEW: "/v3/pets",
    SAVE_RECENT_RECORD: "/v3/pets/recent/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/pets/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/pets/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/pets/childobjects/setup/save",
    DELETE: "/v3/pets/delete",
    ADD_PHOTO: "/v3/pets/add/photo",
    LIST_VIEW_SETUP_GET: "/v3/pets/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/pets/setup/save",
  },
  ORDERS: {
    QUERY_FILTER: "/v3/orders/queryfilter/get",
    FIELDS: "/v3/orders/fields",
    LIST_VIEW: "/v3/orders/get/listview",
    LIST_GET: "/v3/orders/get",
    EDIT_COPY: "/v3/orders",
    SAVE: "/v3/orders/save",
    DETAIL_VIEW: "/v3/orders",
    SAVE_RECENT_RECORD: "/v3/orders/recent/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/orders/listviewlayout/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/orders/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/orders/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/orders/childobjects/setup/save",
    DELETE: "/v3/orders/delete",
    LIST_VIEW_SETUP_GET: "/v3/orders/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/orders/setup/save",
    RECENT_RECORD_GET: "/v3/orders/recent/get",
  },
  ORDER_ITEMS: {
    LIST_VIEW: "/v3/orderitems/get/listview",
    LIST_GET: "/v3/orderitems/get",
    FIELDS: "/v3/orderitems/fields",
    EDIT_COPY: "/v3/orderitems",
    SAVE: "/v3/orderitems/save",
    DETAIL_VIEW: "/v3/orderitems",
    SAVE_RECENT_RECORD: "/v3/orderitems/recent/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/orderitems/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/orderitems/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/orderitems/childobjects/setup/save",
    DELETE: "/v3/orderitems/delete",
    LIST_VIEW_SETUP_GET: "/v3/orderitems/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/orderitems/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/orderitems/listviewlayout/save",
  },
  QUOTATIONS: {
    LIST_VIEW: "/v3/quotations/get/listview",
    LIST_GET: "/v3/quotations/get",
    FIELDS: "/v3/quotations/fields",
    EDIT_COPY: "/v3/quotations",
    SAVE: "/v3/quotations/save",
    GET: "/v3/quotations/getquote",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/quotations/listviewlayout/save",
    DELETE: "/v3/quotations/delete",
    LIST_VIEW_SETUP_GET: "/v3/quotations/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/quotations/setup/save",
    RECENT_RECORD_GET: "/v3/quotations/recent/get",
    QUERY_FILTER: "/v3/quotations/queryfilter/get",
    USER_FILTER_GET: "/v3/quotations/filter/user/get",
    FILTER_DELETE: "/v3/quotations/filters/delete",
    SAVE_RECENT_RECORD: "/v3/quotations/recent/save",
    TEMPLATES_GET: "/v3/quotations/templates/get",
    PRODUCTS_GET: "/v3/quotations/products/get",
    UPDATE_FIELDS: "/v3/quotations/update/fields",
    ADD_TO_QUICKBOOKS: "/v3/quotations/addtoquickbook",
    ADD_TO_XERO: "/v3/quotations/addtoxero",
    GET_PRODUCT_ITEMS: "/v3/quotations/prodctitem/get",
    CREATE_PDF: "/v3/quotations/create/pdf",
    DOWNLOAD_PDF: "/v3/quotations/download/pdf",
    GET_NEXT_QUOTE_NO: "/v3/quotations/getNextQuoteNo",
    CHECK_DUPLICATE_QUOTE_NO: "/v3/quotations/checkDuplicateQuoteNo",
  },
  USERREPORT: {
    SAVE: "v3/userreport/save",
    GET: "v3/userreport/get",
    DELETE: "v3/userreport/delete",
    GET_TABULAR_REPORT: "v3/userreport/generatereport",
  },
  AUTOFILL: {
    GET_TABLE_DATA_TYPE_LIST: "v3/setup/getTableAndDataTypeList",
    GET_LINKED_CHILD_TABLE_LIST: "v3/setup/getLinkChildTableList",
    GET_FIELD_MAPPINGS: "v3/setup/getAllFieldMappnings",
    SAVE_MAPPINGS: "v3/setup/saveFieldMappnings",
  },
  FIND_DUPLICATE: {
    LIST_VIEW: "/v3/setup/duplicate/get/listview",
    GET_QUERY_IDS: "/v3/setup/duplicate/get/query",
    GET_RECURLY_TOKEN: "/v3/setup/recurly/get",
  },
  TASKS: {
    AUTOMATIC_CONVERT: "v3/tasks/reschedule/automatic/convert",
    UPDATE_ONBOARDING_TASKS: "v3/tasks/onboarding/update",
  },
  SCHEDULER: {
    SAVE_SETUP: "/v3/scheduler/setup",
    LINK_INFO: "/v3/scheduler/linkinfo",
    SCHEDULER_DETAILS: "/v3/scheduler/details",
    GET_SLUG: "/v3/scheduler/slug/get",
    DELETE: "/v3/scheduler/delete",
  },
  LEAD_SCORES: {
    GET_ACTION_LIST: "/v3/leadscores/get/actions",
    SAVE_LEADSCORE: "/v3/leadscores/save",
    ACTION_LIST_VIEW: "/v3/leadscores/view",
    DELETE_LEADSCORE: "/v3/leadscores/delete",
  },
  EXPORT: {
    GENERATE_DATA_AND_DOWNLOAD: "/v3/common/generatefile/download",
  },
  RELATION_REPORT: {
    RELATION_REPORT_GET: "/v3/reports/relationreport/get",
    REPORT_CUSTOMIZATION_SAVE: "/v3/reports/relation/customization/save",
    REPORT_CUSTOMIZATION_GET: "/v3/reports/relation/customization/get",
    DELETE: "/v3/reports/relation/customization/delete",
    STATUS_LIST_GET: "/v3/reports/status/values/get",
    RELATIONS_GET: "/v3/reports/relations/get",
  },
  PAYMENTS: {
    LIST_VIEW: "/v3/payments/get/listview",
    LIST_GET: "/v3/payments/get",
    SAVE: "/v3/payments/save",
    EDIT: "/v3/payments/",
    DELETE: "/v3/payments/delete",
    FIELDS: "/v3/payments/fields",
    LIST_VIEW_SETUP_GET: "/v3/payments/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/payments/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/payments/listviewlayout/save",
    SAVE_RECENT_RECORD: "/v3/payments/recent/save",
    EDIT_COPY: "/v3/payments/",
    ADD_TO_QUICKBOOKS: "/v3/payments/addquickbook",
    ADD_TO_XERO: "/v3/payments/addxero",
    GET_QUOTE: "/v3/payments/get/invoices",
  },
  COLUMN_VIEW: {
    APP_TASK_PORTAL: "v3/{object}/apptaskportal/get",
    ADDITIONAL_FIELDS_GET: "/v3/{object}/additionalfields/get",
  },
  UNITS: {
    ADD_UNIT_FORM_DETAILS_GET: "/v3/common/units/form/data/get",
    SAVE_UNIT: "/v3/accounts/units/save",
    GET_PHONE_SETUP_INFO: "/v3/common/get/phonesetup/info",
    CHECK_DUPLICATE_TENANT_EMAIL: "v3/users/search/tenant",
    GET_PLAN_NAMES: "v3/units/plannames/get",
    SAVE_PLAN_NAMES: "v3/units/plannames/save",
    GET_AF_LIST: "v3/accounts/units/af/get",
    GET_MU_LIST: "v3/accounts/units/mu/get",
    OPEN_PLAN_NAME: "v3/accounts/units/open/addtoplan",
  },
  BASE_UNIT: {
    SAVE_BASE_UNIT: "/v3/accounts/units/base/save",
  },
  BRANCH_UNIT: {
    SAVE_BRANCH_UNIT: "/v3/accounts/units/branch/save",
  },
  ROYALTY_REPORT: {
    SAVE_REPORT_SETUP: "/v3/reports/setup/save",
    GET_REPORT_CONFIG: "/v3/reports/config/get",
    GET_REPORT_DATA: "/v3/reports/list/get",
    GET_DRILL_DOWN_DATA: "/v3/reports/drilldown/get",
    GET_DRILL_DOWN_ROYALTYV2_GET: "/v3/reports/royalty/drilldown/get",
  },
  PURCHASE_ORDER: {
    FIELDS: "/v3/purchaseorder/fields",
    RECENT_RECORD_GET: "/v3/purchaseorder/recent/get",
    QUERY_FILTER: "/v3/purchaseorder/queryfilter/get",
    LIST_VIEW: "/v3/purchaseorder/get/listview",
    SAVE_RECENT_RECORD: "/v3/purchaseorder/recent/save",
    GET_PURCHASE_ORDER: "v3/purchaseorder/getPurchaseOrder",
    SAVE_PURCHASE_ORDER: "v3/purchaseorder/save",
    CREATE_PDF: "v3/purchaseorder/create/pdf",
    UPDATE_FIELDS: "/v3/purchaseorder/update/fields",
    DELETE: "/v3/purchaseorder/delete",
    LIST_VIEW_SETUP_GET: "/v3/purchaseorder/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/purchaseorder/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/purchaseorder/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/purchaseorder/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/purchaseorder/childobjects/setup/save",
    LIST_GET: "/v3/purchaseorder/get",
  },
  SALES_ORDER: {
    RECENT_RECORD_GET: "/v3/salesorder/recent/get",
    QUERY_FILTER: "/v3/salesorder/queryfilter/get",
    LIST_VIEW: "/v3/salesorder/get/listview",
    SAVE_RECENT_RECORD: "/v3/salesorder/recent/save",
    LIST_VIEW_SETUP_GET: "/v3/salesorder/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/salesorder/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/salesorder/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/salesorder/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/salesorder/childobjects/setup/save",
    FIELDS: "/v3/salesorder/fields",
    DELETE: "/v3/salesorder/delete",
    LIST_GET: "/v3/salesorder/get",
  },
  INVENTORY: {
    RECENT_RECORD_GET: "/v3/inventory/recent/get",
    QUERY_FILTER: "/v3/inventory/queryfilter/get",
    LIST_VIEW: "/v3/inventory/get/listview",
    SAVE_RECENT_RECORD: "/v3/inventory/recent/save",
    FIELDS: "/v3/inventory/fields",
    SAVE: "/v3/inventory/save",
    DETAIL_VIEW: "/v3/inventory",
    DELETE: "/v3/inventory/delete",
    EDIT_COPY: "/v3/inventory",
    LIST_VIEW_SETUP_GET: "/v3/inventory/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/inventory/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/inventory/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/inventory/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/inventory/childobjects/setup/save",
    BATCH_EDIT: "/v3/inventory/batchedit",
  },
  TIME: {
    FIELDS: "/v3/time/fields",
    LIST_VIEW: "/v3/time/get/listview",
    DELETE: "/v3/time/delete",
    EDIT_COPY: "/v3/time",
    BATCH_EDIT: "/v3/time/batchedit",
    LIST_VIEW_SETUP_GET: "/v3/time/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/time/setup/save", //
    SAVE: "/v3/time/save",
    LIST_GET: "/v3/time/get",
    EDIT: "/v3/time",
    SAVE_RECENT_RECORD: "/v3/time/recent/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/time/listviewlayout/save",
  },
  OPERATIONS_DASHBOARD: {
    GET_OPERATION_REPORT_DATA: "/v3/reports/operation/dashboardreport",
    GET_OPERATION_REPORT_TEST: "v3/reports/operation/reportstest",
  },
  JOBS: {
    FIELDS: "/v3/jobs/fields",
    RECENT_RECORD_GET: "/v3/jobs/recent/get",
    QUERY_FILTER: "/v3/jobs/queryfilter/get",
    LIST_VIEW: "/v3/jobs/get/listview",
    SAVE_RECENT_RECORD: "/v3/jobs/recent/save",
    LIST_VIEW_SETUP_GET: "/v3/jobs/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/jobs/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/jobs/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/jobs/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/jobs/childobjects/setup/save",
    LIST_GET: "/v3/jobs/get",
    DETAIL_VIEW: "/v3/jobs",
    GET: "/v3/jobs/get/jobsform",
    SAVE: "/v3/jobs/save",
    EDIT_COPY: "/v3/jobs",
    DETAIL_VIEW_TASK_APP: "/v3/jobs/detailview/taskapp/get",
    COLUMN_VIEW: "/v3/jobs/columnview/get",
    DELETE: "/v3/jobs/delete",
  },
  COMMUNITY: {
    SAVE: "v3/integration/commiunity/save",
  },
  lINE_ITEMS: {
    FIELDS: "/v3/jobs/fields",
    LIST_VIEW: "/v3/lineitems/get/listview",
    LIST_VIEW_SETUP_GET: "/v3/lineitems/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/lineitems/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/lineitems/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/lineitems/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/lineitems/childobjects/setup/save",
    LIST_GET: "/v3/lineitems/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/lineitems/listviewlayout/save",
  },
  DUPLICATE_RULE: {
    GET: "/v3/imports/duplicaterules/get",
    SAVE: "/v3/imports/duplicaterules/save",
  },
  ROYALITY: {
    FIELDS: "/v3/royalties/fields",
    LIST_VIEW: "/v3/royalties/get/listview",
    LIST_VIEW_SETUP_GET: "/v3/royalties/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/royalties/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/royalties/customizefields/setup/save",
    CHILD_OBJECT_SETUP_GET: "/v3/royalties/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/royalties/childobjects/setup/save",
    LIST_GET: "/v3/royalties/get",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/royalties/listviewlayout/save",
    EDIT_COPY: "/v3/royalties",
    SAVE: "/v3/royalties/save",
    BATCH_EDIT: "/v3/royalties/batchedit",
    GET_ROYALTY: "/v3/get/royalties",
    GET_UNIT_ID:'v3/royaltyrun/reportedsales/getAccountId',
    GET_CLIENT_ID:'v3/royaltyrun/reportedsales/myclientId'
  },
  PHONE_NUMBERS: {
    FIELDS: "/v3/phonenumbers/fields",
    LIST_VIEW: "/v3/twilio/phonenumbers/get/listview",
    LIST_GET: "/v3/phonenumbers/get",
    DELETE: "/v3/twilio/phonenumbers/delete",
    GET_CALL_SMS_CREDITS: "/v3/twilio/phonenumbers/credits",
    USE_NOTIFICATION: "/v3/twilio/update/notification/number",
  },
  AUDIT_QUESTIONS: {
    SAVE_QUESTION: "/v3/audit/savequestions",
    SAVE_CATEGORY: "/v3/audit/saveauditcategory",
    GET_CATEGORY: "/v3/audit/getauditcategory",
    REARRANGE_CATEGORY: "/v3/audit/updatecategoryorder",
    LIST_VIEW: "/v3/audit_questions/get/listview",
    GET_SINGLE_QUESTION: "v3/audit/getquestions",
    DELETE_QUESTION: "/v3/audit/deletequestions",
    REARRANGE: "/v3/audit/updatequestionsorder",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/audit_questions/listviewlayout/save",
    GET_FORM_ID: "/v3/audit/getformids",
    GET_AUDIT_QUESTIONS: "/v3/audit/getquestionslist",
    SUBMIT_QUESTIONS: "/v3/audit/saveauditanswers",
    UPLOAD_ANSWER_PHOTO: "/v3/audit/saveauditanswersphoto",
  },
  AUDIT_CHECKLIST:{
    UPDATE:'/v3/audit/auditform/saveorupdate',
    LIST_VIEW:'/v3/audit_forms/get/listview',
    GET_AUDIT_CATEGORY_DATA:'v3/audit/auditform/get',
    GET_AUDIT_CHECKLIST_FOR_MOBILE:'v3/audit/auditform/getList',
  },
  AUDIT_TASK_REPORT:{
    GET_AUDIT_TASK:'/v3/tasks/taskreport',
  },
  AUDIT_WEAKNESS_REPORT: {
    GET_AUDIT_FAILED_QUESTIONS: "/v3/audit/generate/weaknessreport",
  },
  AUDIT_ANSWERS: {
    LIST_VIEW: "v3/audit_answers/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/audit_answers/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/audit_answers/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/audit_answers/setup/save",
    LIST_GET: "/v3/audit_answers/get",
    UPDATE_AUDIT_ANSWERS: "v3/audit/auditanswer/edit",
    UPDATE_AUDIT_ANSWER_PHOTO: "/v3/audit/saveauditanswersphoto",
    SEND_AUDIT_MAIL: "/v3/audit/perform/correctiveaction",
    DELETE_AUDIT_ANSWER_PHOTO: "v3/audit/auditanswer/photo/delete",
    AUDIT_GET_ENCODED_PARAMS: "/v3/audit/get/encodedata",
    AUDIT_ANWSERS_ATTACHMENTS: "/v3/audit/auditanswer/attachments",
    AUDIT_FAILURE_REPORT: "/v3/audit/weaknessreport/drilldown",
  },
  AUDITS: {
    AUDITS_LIST_VIEW: "/v3/audits/get/listview",
    AUDITS_LIST_VIEW_LAYOUT_CHANGE: "/v3/audits/listviewlayout/save",
    AUDITS_LIST_VIEW_SETUP_GET: "/v3/audits/setup/get",
    AUDITS_LIST_VIEW_SETUP_SAVE: "/v3/audits/setup/save",
    AUDITS_LIST_GET: "/v3/audits/get",
    DETAIL_VIEW: "/v3/audits",
    FIELDS: "/v3/audits/fields",
    AUDIT_GET_LIST_LISTVIEW: "/v3/audits/get/listview",
    AUDIT_MOBILE_ATTACMENT: "/v3/audit/get/images",
  },
  AUDIT_PERFORMANCE_REPORT: {
    AUDIT_REPORT_DETAILS:'v3/audit/performancereport/details',
    AUDIT_PERFORMANCE_QUESTIONS:'v3/audit/get/questionslist',
    AUDIT_PERFORMANCE_RECORDS:'v3/audit/performancereport',
  },
  REPORTED_SALES: {
    LIST_VIEW: "/v3/reportedsales/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/reportedsales/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/reportedsales/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/reportedsales/setup/save",
    LIST_GET: "/v3/reportedsales/get",
    DETAIL_VIEW: "/v3/reportedsales",
    FIELDS: "/v3/reportedsales/fields",
    SAVE: "/v3/reportedsales/save",
    EDIT_COPY: "/v3/reportedsales",
    DELETE: "/v3/reportedsales/delete",
    SAVE_RECENT_RECORD: "/v3/reportedsales/recent/save",
    RECENT_RECORD_GET: "/v3/reportedsales/recent/get",
    QUERY_FILTER: "/v3/reportedsales/queryfilter/get",
    CHILD_OBJECT_SETUP_GET: "/v3/reportedsales/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/reportedsales/childobjects/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/reportedsales/customizefields/setup/save",
    UPDATE: "v3/reportedsales/reportedsales/update",
    CLOSE: "v3/reportedsales/reportedsales/close",
    GET_REPORTED_BY: "v3/reportedsales/reportedsales/getreportedby",
  },
  COMPLIANCE: {
    LIST_VIEW: "/v3/compliance/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/compliance/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/compliance/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/compliance/setup/save",
    COMPLIANCE_LIST_GET: "/v3/compliance/get",
    DETAIL_VIEW: "/v3/compliance",
    FIELDS: "/v3/compliance/fields",
    SAVE: "/v3/compliance/save",
    EDIT_COPY: "/v3/compliance",
    RECENT_RECORD_GET: "/v3/compliance/recent/get",
    SAVE_RECENT_RECORD: "/v3/compliance/recent/save",
    QUERY_FILTER: "/v3/compliance/queryfilter/get",
    DETAIL_VIEW_TASK_APP: "/v3/compliance/detailview/taskapp/get",
    CHILD_OBJECT_SETUP_GET: "/v3/compliance/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/compliance/childobjects/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/compliance/customizefields/setup/save",
  },
  COMPLIANCE_REPORT: {
    GET_REPORT_DATA: "/v3/reports/list/get/compliancereport",
  },
  SURVEY_RESPONSE: {
    THUMB_SURVEY: "v3/surveyresponses/savesurvey",
    LIST_VIEW: "/v3/surveyresponses/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/surveyresponses/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/surveyresponses/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/surveyresponses/setup/save",
    COMPANY_LOGO: "/v3/surveyresponses/getcompanylogo",
  },
  UNIT_CRM: {
    UNIT_CRM_SETUP_GET: "v3/common/unitcrmsetup/get",
    UNIT_CRM_SETUP_SAVE: "v3/common/unitcrmsetup/save",
  },
  COMPLIANCE_SETUP: {
    SAVE: "/v3/setup/complianceSetup/save",
    GET: "/v3/setup/complianceSetup/get",
  },
  REVIEW_WEBSITE: {
    REVIEW_WEBSITE_SAVE: "v3/common/reviewwebsite/save",
    REVIEW_WEBSITE_GET: "v3/common/reviewwebsite/get",
  },
  REFUND: {
    LIST_VIEW: "/v3/refund/get/listview",
    LIST_GET: "/v3/refund/get",
    SAVE: "/v3/refund/save",
    EDIT: "/v3/refund/",
    DELETE: "/v3/refund/delete",
    FIELDS: "/v3/refund/fields",
    LIST_VIEW_SETUP_GET: "/v3/refund/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/refund/setup/save",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/refund/listviewlayout/save",
    SAVE_RECENT_RECORD: "/v3/refund/recent/save",
    EDIT_COPY: "/v3/refund/",
    ADD_TO_QUICKBOOKS: "/v3/refund/addquickbook",
    ADD_TO_XERO: "/v3/refund/addxero",
    GET_CATAGORY_LIST: "/v3/refund/category/get",
  },
  ROYALTY_PLANS: {
    LIST_VIEW: "/v3/royaltyplan/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/royaltyplan/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/royaltyplan/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/royaltyplan/setup/save",
    LIST_GET: "/v3/royaltyplan/get",
    DETAIL_VIEW: "/v3/royaltyplan",
    FIELDS: "/v3/royaltyplan/get/royaltyplanform",
    SAVE: "/v3/royaltyplan/save",
    EDIT_COPY: "/v3/royaltyplan",
    DELETE: "/v3/royaltyplan/delete",
    SAVE_RECENT_RECORD: "/v3/royaltyplan/recent/save",
    RECENT_RECORD_GET: "/v3/royaltyplan/recent/get",
    QUERY_FILTER: "/v3/royaltyplan/queryfilter/get",
    GET_PLANS: "/v3/royaltyplan/royalties/plans",
  },
  ROYALTY_RUN: {
    LIST_VIEW: "/v3/royaltyrun/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/royaltyrun/listviewlayout/save",
    LIST_VIEW_SETUP_GET: "/v3/royaltyrun/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/royaltyrun/setup/save",
    LIST_GET: "/v3/royaltyrun/get",
    DETAIL_VIEW: "/v3/royaltyrun",
    FIELDS: "/v3/royaltyrun/fields",
    SAVE: "/v3/royaltyrun/save",
    EDIT_COPY: "/v3/royaltyrun",
    DELETE: "/v3/royaltyrun/delete",
    SAVE_RECENT_RECORD: "/v3/royaltyrun/recent/save",
    RECENT_RECORD_GET: "/v3/royaltyrun/recent/get",
    QUERY_FILTER: "/v3/royaltyrun/queryfilter/get",
    REPORT_SALES: "v3/royaltyrun/reportedsales/save",
    PROCESS: "v3/royaltyrun/processroyalties",
    SUBMIT: "v3/royaltyrun/submitroyalties",
    INVOICEALL: "v3/royaltyrun/royalties/invoiceall",
    EMAIL_ALL: "v3/royaltyrun/royalties/emailall",
    POST_ALL: "v3/royaltyrun/royalties/postall",
    CHILD_OBJECT_SETUP_GET: "/v3/royaltyrun/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/royaltyrun/childobjects/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/royaltyrun/customizefields/setup/save",
    REPORT_SALES_CREATE: "v3/royaltyrun/reportedsales/create",
    REPORT_SALES_UPDATE: "v3/reportedsales/reportedsales/batchupdate",
    REPORTED_SALES_AVAILABLE: "v3/reportedsales/reportedsales/isDataAvailable",
    REPORTED_SALES_REJECTED_AVAILABLE: "v3/reportedsales/isRejectionAvailable",
    TO_QUICKBOOKS:"v3/royaltyrun/royalties/postallQB"
  },
  ROYALTY_REPORT_V2: {
    GET: "v3/royalty_report_v2/royalties/generate",
    GET_UNITS: "v3/royalty_report_v2/royalties/units/get",
    LAST_ROYALTY_RUN: "v3/royalty_report_v2/royalties/lastroyaltyrun",
  },
  AUDIT_REPORT: {
    GENERATE: "/v3/audit/generatereport",
  },
  AUDIT_CATEGORY:{
    UPDATE:'v3/audit/managecategories',
  },
  AUDIT_REPORT_DETAILS: {
    GET: "v3/audit/auditanswer/get",
  },
  SMS: {
    SEND: "v3/twilio/send/test/sms",
  },
  SALES_AND_REFUND: {
    GET: "/v3/reports/salesandrefund/get",
  },
  SALES_AND_REFUND_DETAILS: {
    GET: "/v3/reports/salesandrefund/drilldown/get",
  },
  INTAKE_FORM: {
    GET: "/v3/getformdetails",
    SAVE: "/v3/intakeform/save",
    GET_CONTACT_AUTOFILL_VALUES: "/v3/customobjectone/0",
    STATES: "/v3/get/states",
  },
  TOTAL_REPORTED_SALES_DRILLDOWN: {
    GET: "/v3/reportedsales/drilldown/get",
  },
  EXCEPTION: {
    SAVE: "/v3/royalty/save/exception",
    GET: "/v3/royalty/get/exception",
    PLAN_NAME: "/v3/royalty/get/plan_name",
  },

  INVOICE_RECIPIENTS: {
    SAVE: "v3/emailintegration/save/invoicerecipient",
    GET: "v3/emailintegration/get/invoicerecipient",
  },

  ROYALTY_SETUP: {
    GET: "v3/quotations/getTerms",
    MARKPAID_ALL_SAVE: "v3/royaltyrun/royalties/markpaidall",
  },

  AURINKO_INTEGRATION: {
    REDIRECT_URI: "v3/aurinko/integration/auth/response/get",
    CONFIG_DATA_GET: "/v3/aurinko/integration/configdetails",
    CALENDAR_LIST: "/v3/aurinko/integration/calendars/get",
    DISCONNECT: "/v3/aurinko/integration/disconnect",
    SAVE: "/v3/aurinko/integration/save",

    CONFIGURATION_GET: "/v3/emailintegration/get",
    GET_GOOGLE_SESSION_TOKEN: "/v3/emailintegration/getsessiontoken",
    CONTACT_GROUP_EXISTS: "/v3/emailintegration/checkifcontactgroupexists",
    CONTACT_FILTERS: "/v3/emailintegration/filters",
  },
  TWILIO_SMS_VERIFIACTION: {
    SAVE: "/v3/twilio/save/customerinfo",
    GET: "/v3/twilio/get/customerinfo",
    GET_USECASES: "/v3/twilio/get/campaignusecases/",
  },

  PHONE_AND_TEXT_INTEGRATION: {
    SAVE: "/v3/twilio/phonetext/integration/save",
    GET: "/v3/twilio/phonetext/integration/get",
  },

  LMS_USERS: {
    LIST_VIEW: "/v3/lms/users/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/lms/users/listviewlayout/save",
    DELETE: "/v3/lms/users/delete",
    GROUPLIST:"/v3/lms/groups",
    CREATE:"/v3/lms/user/create",
    GET:"/v3/lms/user/get",
    EDIT:"/v3/lms/user/edit"
   },
   ONBOARDING :{
    GET_WORKFLOW : "/v3/workflow/onboarding/type/wf",
    GET_REPORT:'v3/reports/onboarding/get'
   },
   MMS :{
    GET_ATTACHMENT : "v3/attachment/get/mms/url",
   },
   MENU_DESIGN :{
    SAVE_MENU_ITEMS:"/v3/home/save/new/homemenu/items",
    DELETE_MENU_ITEMS:"/v3/home/delete/homemenu",
    GET_MENU_DETAILS:"/v3/home/get/homemenu/details"
   },
   WEBHOOKS: {
    LIST_VIEW: "/v3/webhookrule/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/webhookrule/listviewlayout/save",
    DELETE: "/v3/webhookrule/delete",
    SAVE:"/v3/webhookrule/save",
    EDIT:"/v3/webhookrule/",
    IS_RULE_EXISTS: "/v3/webhookrule/isruleexists",
    GET_RULES: "/v3/webhookrule/get/rules",
    STATUS_CHANGE: "/v3/webhookrule/statuschange"
   },
   TEMPLATE_CATEGORIES: {
    LIST_VIEW: "v3/templates/categories/get/listview",
    LIST_VIEW_LAYOUT_CHANGE: "v3/templates/categories/listviewlayout/save",
    DELETE: "v3/templates/categories/delete",
    SAVE:"v3/templates/categories/save",
    EDIT:"v3/templates/categories/edit",
    GET:"v3/templates/categories/get/"
   },
   PARTNERS: {
    QUERY_FILTER: "/v3/partners/queryfilter/get",
    //MY_GROUP: "/v3/accounts/mygroup/get",
    //MY_GROUP_STATUS_SAVE: "/v3/accounts/mygroup/status/save",
    FIELDS: "/v3/partners/fields",
    LIST_VIEW: "/v3/partners/get/listview",
    LIST_GET: "/v3/partners/get",
    CONVERT_GET: "/v3/partners/convert/get",
    CONVERT: "/v3/partners/convert",
    //IS_OPEN_OPPORTUNITY_EXITS_GET: "/v3/partners/isopenopportunityexits/get",
    BATCH_CONVERT: "/v3/partners/convert/batch",
    ASSIGN: "/v3/partners/assign",
    //ADD_TO_GROUP: "/v3/partners/addtogroup",
    CAMPAIGN_FILTER: "/v3/partners/campaignfilter/get",
    SAVE: "/v3/partners/save",
    DETAIL_VIEW: "/v3/partners",
    EDIT_COPY: "/v3/partners",
    COMPANY_GET: "/v3/partners/company",
    DELETE: "/v3/partners/delete",
    LIST_VIEW_LAYOUT_CHANGE: "/v3/partners/listviewlayout/save",
    STAGE_GET: "/v3/partners/convert/workflowstages",
    TASK_GET: "/v3/partners/convert/workflowstages/task",
    APPLY_WORKFLOW: "/v3/partners/applyworkflow",
    APPLY_WORKFLOW_BATCH: "/v3/partners/applyworkflow/batch",
    AUDIT_TRAIL_GET: "/v3/partners",
    TODAYS_TASKS: "/v3/partners/todays/task",
    TODAYS_APPOINTMENT: "/v3/partners/todays/appointment",
    LIST_VIEW_SETUP_GET: "/v3/partners/setup/get",
    LIST_VIEW_SETUP_SAVE: "/v3/partners/setup/save",
    CUSTOMIZE_FIELDS_SETUP_SAVE: "/v3/partners/customizefields/setup/save",
    COLUMN_VIEW: "/v3/partners/columnview/get",
    MAP_DATA: "/v3/partners/record/get",
    SAVE_RECENT_RECORD: "/v3/partners/recent/save",
    RECENT_RECORD_GET: "/v3/partners/recent/get",
    CHILD_OBJECT_SETUP_GET: "/v3/partners/childobjects/setup/get",
    CHILD_OBJECT_SETUP_SAVE: "/v3/partners/childobjects/setup/save",
    LINK_CHILD_RECORD_GET: "/v3/partners/child/record/get",
    USER_FILTER_GET: "/v3/partners/filter/user/get",
    CHECK_DIFFRENT_WORKFLOW_ID: "/v3/partners/checkrecordwfid",
    CHECK_DIFFRENT_WORKFLOW_STATUS: "/v3/partners/checkrecordstatus",
    BATCH_EDIT: "/v3/partners/batchedit",
    MERGE_GET: "/v3/partners/merge/get",
    MERGE_SAVE: "/v3/partners/merge",
    FILTER_DELETE: "/v3/partners/filters/delete",
    //LEAD_SCORE_HISTORY: "/v3/accounts/leadscore/history",
    ADD_PHOTO: "/v3/partners/add/photo",
    RELATED_RECORD_LIST_GET: "/v3/partners/relatedrecordlist/get",
    RELATED_RECORD_SAVE: "/v3/partners/relatedrecord/save",
    //RELATIONSHIP_RECORDID_LIST_GET:"/v3/accounts/relationshipidlistbyrecordid/get",
    UNLINK_RELATED_RECORD: "/v3/partners/unlinkrelatedrecord",
    DETAIL_VIEW_TASK_APP: "/v3/partners/detailview/taskapp/get",
    CONTACTS_GET: "/v3/partners/{recordid}/contacts",
    PRIMARY_CONTACT: "/v3/partners/primarycontact",
    //UNITS_GET: "/v3/partners/units/get",
    //UNITS_WITH_MYCLIENT_ID_GET: "/v3/partners/getunits",
    AUTOMATIC_CONVERT: "/v3/partners/automatic/convert",
    //ONBOARDING_UNITS_GET: "/v3/accounts/onboardingunits/get",
    CHECK_DUPLICATE_DOMAIN: "/v3/partners/checkduplicatedomain",
    //RECYCLE_BIN_LIST_VIEW: "/v3/accounts/recyclebin/listview",
    //PERMANENT_DELETE: "/v3/accounts/recyclebin/batch/delete",
    //RESTORE: "/v3/accounts/recyclebin/restore",
    GET_MERGE_FIELDS: "/v3/partners/get/mergefields",
    //RECYCLE_BIN_DETAIL_VIEW: "/v3/accounts/recylebin",
    //FRANCHISE_INFO_GET: "/v3/accounts/franchiseinfo/get",
    //CHECK_DUPLICATE_FRANCISE: "/v3/accounts/checkduplicatefranchiseId",
    WORKFLOW_LIST_BY_STAGE: "/v3/{object}/workflow/bystage/get",
    //DOWNLOAD_LEADS_LIST_VIEW: "/v3/accounts/downloadleads/listview",
    //DOWNLOAD_LEADS: "/v3/accounts/downloadleads/download",
    //IS_FRANCHISE: "/v3/isfranchise",
    //DETAIL_VIEW_UNIT_OWNER_TASKS: "/v3/accounts/detailview/unitownertasks/get",
    //INXPRESS_CHECK_LOCATION: "/v3/check/cityzipstate",
    //TO_XMS: "/v3/accounts/pushaccounttoxms",
    //GET_XMS_FIELD_VALUE: "/v3/accounts/getxmsfields",
  },
};
