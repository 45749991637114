import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { constants } from "../../../services/constants/constants";
import {
	addTab,
	getActiveTab,
	updateActiveTabProps,
	TYPE_LIST_VIEW,
	TYPE_COLUMN_VIEW,
	TYPE_DETAIL_VIEW,
	TYPE_HOME,
} from "../../../services/helper/sfTabManager";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
	TYPE_GANTT_CHART,
} from "../../../services/helper/sfTabManager";
import {
	getObjectParam,
	isValidParam,
	getBooleanParam,
	getStringParam,
	getIntParam,
	makeValidAppUrl,
} from "../../../services/helper/parameterVerifier";
import { getCookie, setCookie } from "../../../services/helper/sfCookies";
import { openListView } from "../../../services/actions/listViewAcions";
import { Menu, Select, MenuItem, InputLabel, Icon } from "@mui/material";
import { styles } from "../../../services/constants/styles";
import Button from "@mui/material/Button";
import { getAppCustomDrawer } from "../../../services/actions/appContainerActions";
import { LinkToType } from "../../../services/constants/link";
import * as HTTPClient from "../../../services/helper/httpClient";
import { endPoints } from "../../../services/constants/endPoints";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import { getListData, refreshListView } from '../../../services/actions/listViewAcions';
import { refreshColumnView } from '../../../services/actions/listsActions';

export class KanbanToggle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultView: 1,
			isMounted: false,
			isOpenToggle: false,
		};
		this.onChangeKanban = this.onChangeKanban.bind(this);
		this.multiLangLabel = getLocalizedStrings().label;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let isEqual = _.isEqual(this.props, nextProps);
		if (!isEqual) {
			if (nextProps.currentView !== this.props.currentView) {
				this.setKanbanIcon(nextProps);
			} else if (nextProps.object !== this.props.object) {
				this.setKanbanIcon(nextProps);
			} else if (
				nextProps.app.selectedModuleName !== this.props.app.selectedModuleName
			) {
				this.setKanbanIcon(nextProps);
			}
		}
	}

	componentDidMount() {
		this.setKanbanIcon(this.props);
	}

	setKanbanIcon = (props) => {
		try {
			let cookieName = this.getCookieName(props);
			let selectedModuleName = getStringParam(props.app.selectedModuleName);
			let object =
				props.object === constants.TASKS
					? constants.APPOINTMENTS
					: props.object;
			let data = this.props.app.me.calendarViewMap;
			let validationDateMillisecs = new Date(
				constants.DEFAULT_COLUMN_VIEW_CUT_OFF_DATE
			).valueOf();
			let createdOnDateMillisecs = getIntParam(this.props.app.me.created_on);

			let selectedView = getObjectParam(JSON.parse(getCookie(cookieName)));
			if (selectedView.hasOwnProperty(object)) {
				let obj = getObjectParam(selectedView[object]);
				if (obj.hasOwnProperty("defaultView")) {
					if (object === constants.APPOINTMENTS) {
						this.state.defaultView = data[selectedModuleName];
					} else if (
						object === constants.OPPORTUNITIES_OBJECT ||
						this.props.object === constants.OPPORTUNITIES_OBJECT
					) {
						this.state.defaultView =
							obj.defaultView === "Forecast"
								? 3
								: obj.defaultView === "Column"
									? 2
									: 1;
					} else {
						this.state.defaultView = obj.defaultView === "Column" ? 2 : 1;
						if (
							obj.defaultView === "Timeline" &&
							props.currentView === constants.KANBAN
						) {
							this.state.defaultView = 3;
						}
					}
				}
			}else if (props.object === constants.OPPORTUNITIES_OBJECT) {
				this.state.defaultView = 2;
			} 
			else if (
				createdOnDateMillisecs > validationDateMillisecs &&
				(object === constants.ACCOUNTS_OBJECT ||
					object === constants.CONTACTS_OBJECT ||
					object === constants.OPPORTUNITIES_OBJECT ||
					object === constants.CUSTOM_TABLE1_OBJECT ||
					object === constants.CUSTOM_TABLE2_OBJECT ||
					object === constants.CUSTOM_TABLE3_OBJECT ||
					object === constants.CUSTOM_TABLE4_OBJECT ||
					object === constants.ISSUES_OBJECT ||
					object === constants.PROJECTS_OBJECT ||
					object === constants.CASES_OBJECT ||
					object === constants.JOBS_OBJECT)
			) {
				this.state.defaultView = 2;
			}
			else if (
				object === constants.APPOINTMENTS &&
				(selectedModuleName === constants.MODULE_MARKETING ||
					selectedModuleName === constants.MODULE_SERVICE)
			) {
				this.state.defaultView = data[selectedModuleName];
			} else if (
				object === constants.APPOINTMENTS &&
				selectedModuleName === constants.MODULE_SALES
			) {
				this.state.defaultView = data[selectedModuleName];
			} else if (
				props.currentView === "list" &&
				object !== constants.APPOINTMENTS
			) {
				this.state.defaultView = 1;
			}
			this.setState({ ...this.state, isMounted: true });
		} catch (error) {
			console.error("Error in 'kanbanToggle.js -> setKanbanIcon()':" + error);
		}
	};

	onChangeKanban = (value) => {
		let url = "#";
		let cookieName = this.getCookieName(this.props);
		let activeTab = getActiveTab();
		let type = TYPE_LIST_VIEW;
		let defaultView = this.state.defaultView;
		let data = this.props.app.me.calendarViewMap;
		let selectedModule = this.props.app.selectedModuleName;
		try {
			if (defaultView !== value) {
				if (
					(this.props.object === constants.ACCOUNTS_OBJECT ||
						this.props.object === constants.CONTACTS_OBJECT ||
						this.props.object === constants.OPPORTUNITIES_OBJECT ||
						this.props.object === constants.CASES_OBJECT ||
						this.props.object === constants.PROJECTS_OBJECT ||
						this.props.object === constants.ISSUES_OBJECT ||
						this.props.object === constants.CUSTOM_TABLE1_OBJECT ||
						this.props.object === constants.CUSTOM_TABLE2_OBJECT ||
						this.props.object === constants.CUSTOM_TABLE3_OBJECT ||
						this.props.object === constants.CUSTOM_TABLE4_OBJECT ||
						this.props.object === constants.JOBS_OBJECT) &&
					(this.props.currentView === "list" ||
						this.props.currentView === "kanban") &&
					value === 2
				) {
					url = "/" + this.props.object + "/column";
					type = TYPE_COLUMN_VIEW;
				} else if (
					this.props.object === constants.OPPORTUNITIES_OBJECT &&
					value === 3 &&
					(this.props.currentView === "list" ||
						this.props.currentView === "column")
				) {
					url = "/" + this.props.object + "/kanban";
					type = TYPE_COLUMN_VIEW;
				} else if (
					this.props.object === constants.TASKS ||
					this.props.object === constants.APPOINTMENTS
				) {
					type = TYPE_HOME;
					if (value === 1) {
						activeTab.object = constants.CALENDAR_TASK;
						if (value === 1) {
							url = "/" + constants.APPOINTMENTS + "/calendar";
							activeTab.imgName = "fa fa-calendar";
						}
						activeTab.info = {};
					} else if (value === 2) {
						url = "/" + constants.TASKS + "/taskpage";
						activeTab.object = constants.TASKS;
						activeTab.info = {};
					}
				} else if (this.props.currentView === "calendarOrTask") {
					url = "/" + this.props.object + "/queries";
				} else if (
					this.props.object === constants.APPOINTMENTS &&
					this.props.currentView === "list"
				) {
					url = "/" + this.props.object + "/calendar";
				} else if (
					this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT &&
					this.props.currentView === "list"
				) {
					url = "/" + constants.AB_CAMPAIGNS_OBJECT + "/queries";
				} else if (
					this.props.object === constants.AB_CAMPAIGNS_OBJECT &&
					this.props.currentView === "list"
				) {
					url = "/" + constants.BROADCAST_CAMPAIGNS_OBJECT + "/queries";
				} else if (
					this.props.object === constants.OPPORTUNITIES_OBJECT &&
					value === 3 &&
					(this.props.currentView === "list" ||
						this.props.currentView === "column")
				) {
					url = "/" + constants.OPPORTUNITIES_OBJECT + "/kanban";
				} else {
					url = "/" + this.props.object + "/queries";
				}

				url = url.toLowerCase();
				url = url.replace("_", "-");
				activeTab.url = url;
				activeTab.type = type;
				let selectedView = getObjectParam(JSON.parse(getCookie(cookieName)));
				if (
					this.props.object === constants.TASKS ||
					this.props.object === constants.APPOINTMENTS
				) {
					selectedView[constants.APPOINTMENTS] = {
						defaultView:
							value === 2
								? "TasksPage"
								: value === 3
									? "Calendar"
									: "CalendarTask",
					};
					data[selectedModule] = value;
					this.saveOrUpdateCalendarView(data);
				} else if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
					selectedView[this.props.object] = {
						defaultView:
							value === 3 ? "Forecast" : value === 2 ? "Column" : "List",
					};
				} else {
					selectedView[this.props.object] = {
						defaultView:
							value === 3 ? "Timeline" : value === 2 ? "Column" : "List",
					};
				}

				setCookie(cookieName, JSON.stringify(selectedView));

				let _state = this.state;
				_state.defaultView = value;
				_state.isOpenToggle = false;
				if (activeTab.info.hasOwnProperty("groupByFieldName")) {
					delete activeTab.info.groupByFieldName;
				}
				this.setState(_state);
				addTab(activeTab, true);
				if(this.props.object == constants.ACCOUNTS_OBJECT && value == 1){
					refreshListView('accounts');
				} else if(this.props.object == constants.ACCOUNTS_OBJECT && value == 2){
					refreshColumnView(constants.ACCOUNTS_OBJECT);
				}
			}
		} catch (error) {
			console.error("Error in 'kanbanToggle.js -> onChangeKanban()':" + error);
		}
	};
	saveOrUpdateCalendarView(data) {
		let params = data;
		let url = "";
		url = endPoints.USERS.CALENDAR_SETUP_VIEW;
		let promise = Promise.resolve(HTTPClient.post(url, params));
		if (isValidParam(promise)) {
			promise.then((response) => { });
		}
	}
	getCookieName = (props) => {
		let cookieName = "";
		try {
			cookieName = "view_" + props.object + "_" + props.app.me.projectId;
			let selectedModuleName = getStringParam(props.app.selectedModuleName);
			if (
				props.object === constants.TASKS ||
				props.object === constants.APPOINTMENTS
			) {
				cookieName =
					"home_view_" + selectedModuleName + "_" + props.app.me.projectId;
			}
		} catch (error) {
			console.error("Error in 'kanbanToggle.js -> getCookieName()':" + error);
		}
		return cookieName;
	};

	openViewOptions = (event) => {
		event.preventDefault();
		this.setState({ isOpenToggle: true, anchorEl: event.currentTarget });
	};

	// getToggleItems = () => {
	//     let items = [{ value: 0, text: this.multiLangLabel.COMMON.VIEW_MODE, disabled: true },]
	//     try {
	//         if (this.props.object !== constants.APPOINTMENTS || this.props.object !== constants.TASKS) {

	//             items.push({ value: 1, text: this.multiLangLabel.COMMON.LIST, disabled: false, rightIconClassName: 'fa fa-list' });
	//             items.push({ value: 2, text: this.multiLangLabel.COMMON.COLUMN, disabled: false, rightIconClassName: 'fa fa-columns' });
	//             if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
	//                 items.push({ value: 3, text: 'Forecast', disabled: false, rightIconClassName: 'fa fa-usd' });
	//             }
	//         }
	//     } catch (error) {
	//         console.error("Error in 'kanbanToggle.js -> getToggleItems()':" + error);
	//     }
	//     return items;
	// }

	onButtonClick = (buttonName, event) => {
		if (buttonName === "Appointments and Tasks") {
			this.onChangeKanban(1);
		} else if (buttonName === "Appointments Only") {
			this.onChangeKanban(3);
		} else if (buttonName === "Tasks Only") {
			this.onChangeKanban(2);
		} else if (buttonName === "List View") {
			this.onChangeKanban(1);
		} else if (buttonName === "Column View") {
			this.onChangeKanban(2);
		} else if (buttonName === "Forecast") {
			this.onChangeKanban(3);
		}
	};

	showReport = () => {
		let activeTab = this.props.tab?.tabs?.filter(f => { return f.isActive });
		let _currentView = this.props.header?.kanban?.currentView;
		let columnGroupByvalues = this.props.lists?.lists;
		let datalist = [];
		if (_currentView === constants.LIST) {
			let tempData = this.props.listView?.groupByInfo?.group_by;
			datalist = tempData.filter((ele) => ele.label !== "All");
		} else if (_currentView === constants.COLUMN) {
			columnGroupByvalues?.map((ele) => {
				let obj = {};
				obj.record_count = ele.total_count;
				obj.label = ele.columnName;
				datalist.push(obj);
			})
		} else if (_currentView === constants.KANBAN) {
			columnGroupByvalues?.map((ele) => {
				let _opportunityAmount = ele?.opportunityAmount;
				_opportunityAmount = _opportunityAmount?.slice(1);
				_opportunityAmount = parseFloat(_opportunityAmount.replace(/,/g, ''))
				_opportunityAmount = Math.round(_opportunityAmount);
				let obj = {};
				obj.record_count = _opportunityAmount;
				obj.label = ele.columnName;
				datalist.push(obj);
			})
		} else {
			columnGroupByvalues?.map((ele) => {
				let obj = {};
				obj.record_count = ele.total_count;
				obj.label = ele.columnName;
				datalist.push(obj);
			})
		}
		let tabParams = {
			"imgName": "fa fa-bar-chart",
			"index": 0,
			"info": {
				isReport: true,
				reportLabel: (activeTab[0]?.label).concat(" Report"),
				currentView: _currentView,
				list: datalist,
			},
			"isActive": false,
			"label": (activeTab[0]?.label).concat(" Report"),
			"object": constants.REPORT_DASHBOARD,
			"type": constants.REPORT_DASHBOARD,
			"url": '/' + makeValidAppUrl(constants.REPORT_DASHBOARD) + '/' + activeTab[0]?.label,

		};
		let tabs = this.props.tab?.tabs;
		let automationTab = tabs.find((tab) => {
			return tab.type === constants.REPORT_DASHBOARD && tab.object === constants.REPORT_DASHBOARD;
		});
		if (!isValidParam(automationTab)) {
			addTab(tabParams, true);
		} else {
			let tabParams = {
				"imgName": automationTab.imgName,
				"index": automationTab.index,
				"info": {
					isReport: automationTab.isReport,
					reportLabel: (activeTab[0]?.label).concat(" Report"),
					currentView: _currentView,
					list: datalist,
				},
				"isActive": automationTab.isActive,
				"label": (activeTab[0]?.label).concat(" Report"),
				"object": automationTab.object,
				"type": automationTab.type,
				"url": automationTab.url,

			};
			addTab(tabParams, true);
		}
	}
	syncCalendarNow = () => {
		try {
			let promise = Promise.resolve(
				HTTPClient.post(endPoints.APPOINTMENT.CALENDAR_SYNC)
			);
			this.props.showCustomSnackBar(
				getLocalizedStrings().message.APPOINTMENTS_TASKS.SYNC_STARTED_MSG,
				styles.snackbarBodyStyleSuccess,
				styles.snackBarStyleTop
			);

			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response)) {
						if (response.hasOwnProperty("data")) {
							let syncCal = getStringParam(response.data.sync_calendar);

							if (syncCal === "No") {
								this.props.showCustomSnackBar(
									getLocalizedStrings().message.APPOINTMENTS_TASKS
										.ENABLE_SYNC_CAL,
									styles.snackbarBodyStyleError,
									styles.snackBarStyleTop
								);
							}
						}
					}
				});
			}
		} catch (error) {
			console.error("Error in 'calendars.js -> syncCalendarNow()':" + error);
		}
	};
	openSettings = (event, type) => {
		let label = getLocalizedStrings().label.CALENDAR.MY_CALENDAR_SETTING;
		let data = {};
		type === LinkToType.TYPE_CALENDAR_SETTINGS
			? (data.type = constants.APPOINTMENTS)
			: (data.type = constants.JOB_SCHEDULER);

		let style = {};
		style.width = "36%";
		this.props.getAppCustomDrawer(true, label, type, style, data, null, false);
	};
	render() {
		if (this.state.isMounted) {
			let themeColor = this.props.app.me.background;
			let iconClassName =
				this.state.defaultView === 1 ? "fa fa-list" : "fa fa-columns";
			let icontitle =
				this.state.defaultView === 1
					? this.multiLangLabel.COMMON.LIST
					: this.multiLangLabel.COMMON.COLUMN;
			if (this.state.defaultView === 3) {
				iconClassName = "fa fa-usd";
				icontitle = "Forecast";
			}

			let listMarginLeft =
				this.props.object === constants.OPPORTUNITIES_OBJECT ? 25 : 57;
			// if (this.props.object === constants.TASKS || this.props.object === constants.APPOINTMENTS) {
			//     iconClassName = this.state.defaultView === 1 || this.state.defaultView === 3 ? 'fa fa-calendar' : 'fa fa-clock-o';
			//     iconClassName = this.state.defaultView === 1 ? 'fa fa-calendar' : this.state.defaultView === 3 ? 'far fa-calendar-check' : 'fa fa-clock-o';
			//     icontitle = this.state.defaultView === 1 ? this.multiLangLabel.APPOINTMENTS_TASKS.APPOINTMENTS_AND_TASKS : this.state.defaultView === 3 ? this.multiLangLabel.APPOINTMENTS_TASKS.APPOINTMENTS_ONLY : this.multiLangLabel.APPOINTMENTS_TASKS.TASKS_ONLY;
			// }
			// let items = this.getToggleItems();
			let toggleIconStyle = {
				display: "inline",
				background: "#f5f2eb",
				lineHeight: "2px",
				textAlign: "center",
				position: "relative",
				top: "-2px",
				width: "30px",
				height: "30px",
				borderRadius: "50%",
				padding: "3px 9px 6px 10px",
				fontSize: 17,
				marginLeft: 4,
			};
			let reportIconStyle = {
				display: "inline",
				background: "#f5f2eb",
				lineHeight: "2px",
				textAlign: "center",
				position: "relative",
				top: "-2px",
				width: "30px",
				height: "30px",
				borderRadius: "50%",
				paddingTop: '8px',
				fontSize: 17,
				marginLeft: 4,
			}
			let calendarTaskButtonStyle = toggleIconStyle;
			let calendarButtonStyle = toggleIconStyle;
			let TaskButtonStyle = {
				display: "inline",
				background: "#f5f2eb",
				lineHeight: "2px",
				textAlign: "center",
				position: "relative",
				top: "-2px",
				width: "30px",
				height: "30px",
				borderRadius: "50%",
				padding: "3px 7px 5px 8px",
				fontSize: 17,
				marginLeft: 4,
			};
			let ForecastButtonStyle = {
				display: "inline",
				background: "#f5f2eb",
				lineHeight: "2px",
				textAlign: "center",
				position: "relative",
				top: "-2px",
				width: "30px",
				height: "30px",
				borderRadius: "50%",
				padding: "2px 11px 4px 11px",
				fontSize: 17,
				marginLeft: 4,
			};
			if (
				this.props.object === constants.TASKS ||
				this.props.object === constants.APPOINTMENTS
			) {
				if (this.state.defaultView === 1) {
					calendarTaskButtonStyle = {
						...toggleIconStyle,
						color: "#fff",
						background: themeColor,
					};
				} else if (this.state.defaultView === 2) {
					TaskButtonStyle = {
						display: "inline",
						lineHeight: "2px",
						textAlign: "center",
						position: "relative",
						top: "-2px",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						padding: "3px 7px 5px 8px",
						fontSize: 17,
						marginLeft: 4,
						color: "#fff",
						background: themeColor,
					};
				} else if (this.state.defaultView === 3) {
					calendarButtonStyle = {
						...toggleIconStyle,
						color: "#fff",
						background: themeColor,
					};
				}
			} else {
				if (this.state.defaultView === 1) {
					calendarTaskButtonStyle = {
						...toggleIconStyle,
						color: "#fff",
						background: themeColor,
					};
				} else if (this.state.defaultView === 2) {
					calendarButtonStyle = {
						...toggleIconStyle,
						color: "#fff",
						background: themeColor,
					};
				} else if (this.state.defaultView === 3) {
					ForecastButtonStyle = {
						display: "inline",
						lineHeight: "2px",
						textAlign: "center",
						position: "relative",
						top: "-2px",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						padding: "2px 11px 4px 11px",
						fontSize: 17,
						marginLeft: 4,
						color: "#fff",
						background: themeColor,
					};
				}
			}
			let divStyle = { marginLeft: "42%", marginTop: "10" };
			if (this.props.object === constants.TASKS) {
				divStyle = { marginLeft: "35%", marginTop: "10" };
			}
			let isShowReportAndColumnIcon = true;
			if(this.props.object == constants.CONTACTS_OBJECT && this.props.app.me.is_franchisee && this.props.listView.data.hasOwnProperty("doNotShowAction") && this.props.listView.data?.doNotShowAction){
				isShowReportAndColumnIcon = false;
			} else if(this.props.object == constants.PARTNERS_OBJECT){
				isShowReportAndColumnIcon = false;
			}
			return (
				<div>
					{this.props.object === constants.APPOINTMENTS ||
						this.props.object === constants.TASKS  ? (
						/*<div style={divStyle}>
							<div style={{ ...calendarTaskButtonStyle }}>
								<span
									title={
										this.multiLangLabel.APPOINTMENTS_TASKS
											.APPOINTMENTS_AND_TASKS
									}
									onClick={this.onButtonClick.bind(
										this,
										"Appointments and Tasks"
									)}
								>
									<i
										className="far fa-calendar-check"
										style={{ fontSize: 14 }}
										aria-hidden="true"
									></i>
								</span>
							</div>

							<div style={TaskButtonStyle}>
								<span
									title={this.multiLangLabel.APPOINTMENTS_TASKS.TASKS_ONLY}
									onClick={this.onButtonClick.bind(this, "Tasks Only")}
								>
									<i
										className="fa fa-clock-o"
										style={{ fontSize: 18 }}
										aria-hidden="true"
									></i>
								</span>
							</div>
						</div>*/
						<div></div>
					) : this.props.object === constants.JOB_SCHEDULER ? (
						<div style={{}}>
							<IconButton
								key="settings"
								id="calendar-settings"
								onClick={(e) =>
									this.openSettings(e, LinkToType.TYPE_JOB_CALENDAR_SETTINGS)
								}
								title="Job Settings"
								style={{
									backgroundColor: "#f5f2eb",
									padding: "30px 11px 4px",
									marginBottom: "5px",
									marginLeft: "2px",
								}}
							>
								<i
									className="fas fa-cog"
									style={{ color: "#717171", marginTop: "-25px" }}
								></i>
							</IconButton>
						</div>
					) 
					: (
						<div style={{ paddingTop: "4px", display: 'flex', float: 'right', marginRight: '10px' }}>

							{this.props.object !== constants.BROADCAST_CAMPAIGNS_OBJECT && isShowReportAndColumnIcon &&(
								<div style={{ ...calendarTaskButtonStyle, paddingTop: '8px' }}>
									<span
										title={this.multiLangLabel.COMMON.LIST}
										onClick={this.onButtonClick.bind(this, "List View")}
									>
										<i
											className="fa fa-list"
											style={{ fontSize: 14 }}
											aria-hidden="true"
										></i>
									</span>
								</div>
							)}
							{this.props.object !== constants.BROADCAST_CAMPAIGNS_OBJECT && isShowReportAndColumnIcon && (
								<div style={{ ...calendarButtonStyle, paddingTop: '8px' }}>
									<span
										title={this.multiLangLabel.COMMON.COLUMN}
										onClick={this.onButtonClick.bind(this, "Column View")}
									>
										<i
											className="fa fa-columns"
											style={{ fontSize: 15 }}
											aria-hidden="true"
										></i>
									</span>
								</div>)}
							{this.props.object === constants.OPPORTUNITIES_OBJECT && (
								<div style={{ ...ForecastButtonStyle, paddingTop: '8px' }}>
									<span
										title="Forecast"
										onClick={this.onButtonClick.bind(this, "Forecast")}
									>
										<i
											className="fa fa-usd"
											style={{ fontSize: 15 }}
											aria-hidden="true"
										></i>
									</span>
								</div>
							)}
						{isShowReportAndColumnIcon &&	<div style={{ ...reportIconStyle, paddingTop: '8px', paddingLeft: '0px' }}>
								<span
									title="Reports"
									onClick={this.showReport}
								>
									<i
										className="fa fa-bar-chart"
										style={{ fontSize: '14' }}
										aria-hidden="true"
									></i>
								</span>
							</div>}
						</div>
					)}
				</div>
			);
		} else {
			return null;
		}
	}
}
const mapStateToProps = (state) => {
	return {
		app: state.app,
		tab: state.tab,
		header: state.header,
		listView: state.listView,
		lists: state.lists,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAppCustomDrawer: (
			isOpen,
			dialogName,
			actionType,
			style,
			data,
			headerProps,
			minimizable
		) => {
			dispatch(
				getAppCustomDrawer(
					isOpen,
					dialogName,
					actionType,
					style,
					data,
					headerProps,
					minimizable
				)
			);
		},
		showCustomSnackBar: (message, bodyStyle, style) => {
			dispatch(showCustomSnackBar(message, bodyStyle, style));
		},
	};
};
KanbanToggle = connect(mapStateToProps, mapDispatchToProps)(KanbanToggle);

class ToggleMenu extends React.Component {
	getMenuItems = () => {
		let items = [];
		let { defaultValue, list, onItemClick } = this.props;

		try {
			let props = {
				style: { ...styles.popoverMenuItem, margin: "4px" },
				fullWidth: false,
				labelStyle: { color: "#479ccf" },
				labelStyle: { color: "#479ccf" },
				underlinestyle: { display: "none" },
				autowidth: false,
				style: { paddingLeft: "40px" },
			};

			if (Array.isArray(list)) {
				list.map((m) => {
					let { value, text, disabled, rightIconClassName, ...others } = m;
					let itemProps = { ...props };
					itemProps.value = value;
					itemProps.primaryText = text;
					itemProps.disabled = disabled;
					if (rightIconClassName) {
						itemProps.rightIcon = (
							<div style={{ width: "15px" }}>
								<i className={rightIconClassName} />
							</div>
						);
					}
					if (disabled) {
						delete itemProps.leftIcon;
						itemProps.style = {
							textAlign: "center",
							padding: 0,
							margin: 0,
							color: "#717171",
							fontSize: 14,
						};
					} else {
						itemProps.onClick = () => {
							onItemClick(value);
						};
						itemProps.leftIcon =
							defaultValue === value ? (
								<div style={{ width: "15px" }}>
									<i className="fa fa-check" />
								</div>
							) : null;
					}
					items.push(
						<MenuItem {...itemProps} {...others}>
							{ }
						</MenuItem>
					);
				});
			}
		} catch (error) {
			console.error(error);
		}
		return items;
	};
	render() {
		return <Menu autowidth={false}>{this.getMenuItems()}</Menu>;
	}
}