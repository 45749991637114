import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getObjectParam, isUrl } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants } from '../../../services/constants/constants';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';

import Autocomplete from '@mui/material/Autocomplete';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, TextField, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton'
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import ShowCircularProgress from '../components/circularProgress';
import { ArrowDownward, ArrowRight, ArrowDropDown } from '@mui/icons-material';
import { isConstructorDeclaration } from 'typescript';
import { color } from '@mui/system';
import { connect } from 'react-redux';


const defaultRedirectValue = {
    noRedirectValue: -1,
    externalRedirectValue: -111
};

const mapStateToProps = (state, props) => {
    return {
        app: state.app,
        sfForm: state.sfForm,
        appContainer: state.appContainer,
        userlist: state.userlist
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setAppDialogActions: (actions) => {
            dispatch(setAppDialogActions(actions));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
    }
};

class LandingPageFormBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: [],
            selectedFormFields: [],
            isScrollDown: false,
            childViewMouted: false,
            isCollapse: false
        }
    }

    componentDidMount() {
        this.props.setAppDialogActions(this.getActions());
        this.generateFormFields();
    }

    componentDidUpdate() {
        if (this.state.isScrollDown) {
        }
    }

    generateFormFields = () => {
        let fields = this.props.sfForm.data.accounts.fields;
        let formFields = []
        let selectedFormFields = [];
        try {
            if (isValidParam(fields) && getArrayParam(fields).length > 0) {
                formFields = fields.filter(f => {
                    return (f.field_type != "header" && f.field_type != "" && f.field_type != 'integer'
                        && !f.is_hidden_field && !f.is_readonly && f.name != "workflow_name" && f.name != "opportunity_amount" && f.name != 'source' &&
                        f.name != "sales_rep" && f.name != "lead_score" && f.name != 'country' && f.name != 'state' && f.list_type != 'user' && f.name != "sc_source");
                });
                formFields = formFields.map(m => {
                    let fieldObj = {
                        value: m.label,
                        name: m.name,
                        label: m.label,
                        field_type: m.field_type,
                        list_values: m.list_values,
                        t_tablefldid: m.t_tablefldid,
                        is_required_field: false,
                        is_disabled: false
                    }
                    return fieldObj
                });

                selectedFormFields = formFields.filter(f => f.name == 'first_name' || f.name == 'last_name' || f.name == 'email').map(m => {
                    return { ...m, is_disabled: false, is_required_field: true }
                });
                sortArrayObjectByProperty(formFields, 'label');

                let fieldNameList = formFields.filter(f => f.name != 'first_name' && f.name != 'last_name' && f.name != 'email').map(m => m.name);
                fieldNameList.sort();
                if (isValidParam(this.props.data)) {
                    try {
                        let fieldsData = this.props.data.selectedFormFields;
                        if (isValidParam(fieldsData)) {
                            let selectedNameList = getArrayParam(fieldsData).map(m => m.name);
                            fieldNameList = formFields.filter(f => !selectedNameList.includes(f.name)).map(m => m.name);
                            selectedFormFields = selectedNameList.map(name => {
                                let fieldList = getArrayParam(formFields).filter(f => f.name == name).map(m => {
                                    let list = fieldsData.filter(f => f.name == m.name);
                                    return list.length > 0 ? { ...m, label: list[0].label, is_required_field: list[0].is_required_field } : {}

                                });
                                return fieldList.length > 0 ? fieldList[0] : {};
                            }).filter(f => f.name);
                        }
                    } catch (error) {
                        console.error("Error in 'LandingPageFormBuilder.js -> generateFormFields()':" + error);
                    }
                }

                this.setState({ formFields, selectedFormFields, fieldNameList });

                var promise = Promise.resolve(HTTPClient.get(endPoints.ACCOUNTS.MY_GROUP));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let groupList = getArrayParam(response.groups).filter(f => f.status == constants.ACTIVE);
                            this.setState({ groupList, groupListMount: true }, () => { this.generateFormItems(); });
                        }
                    });
                }

                var promise = Promise.resolve(HTTPClient.get(endPoints.LANDING_PAGE.URL_LIST));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        this.setState({ URLList: getArrayParam(response), URLListMount: true }, () => { this.generateFormItems(); });
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> generateFormFields()':" + error);
        }
    }

    generateFormItems = () => {
        let groupValue = 0;
        let groupData = {};
        let workflowValue = 0;
        let workflowData = {};
        let salesRepData = {};
        try {
            let groupListMount = getBooleanParam(this.state.groupListMount);
            let URLListMount = getBooleanParam(this.state.URLListMount);
            if (groupListMount && URLListMount) {
                let fields = getArrayParam(this.props.sfForm.data.accounts.fields);
                let salesRepList = getArrayParam(this.props.userlist.data).map(userList => {
                    return { value: userList.value, label: userList.value, id: userList.id, name: 'sales_rep' }
                });
                let list = salesRepList.filter(f => f.id == this.props.app.me.id);
                if (list.length > 0) {
                    salesRepData = list[0];
                } 
                let workflowValueList = [];
                let workflowList = fields.filter(f => f.name == 'workflow_name');
                if (workflowList.length > 0) {
                   
                    workflowValueList = getArrayParam(workflowList[0].list_values);
                    workflowValueList = workflowValueList.filter(f =>f.value != "Onboarding" && f.value != "Franchisee");
                    workflowValue = workflowValueList.length > 0 ? workflowValueList[0].id : '';
                    workflowValueList = workflowValueList.map(workflow => {
                        return { value: workflow.id, label: workflow.value, id: workflow.id, name: 'workflow' }
                    });
                    workflowData = workflowValueList[0];
                }

                let groupList = getArrayParam(this.state.groupList);
                if (groupList.length > 0) {
                    groupList = groupList.map(group => {
                        return { value: group.id, label: group.name, id: group.id, name: 'group' }
                    });
                    groupValue = groupList[0].value;
                    groupData = groupList[0];
                }
                let sourceValueList = [];
                let sourceValue = '';
                let sourceData = {};
                let sourceList = fields.filter(f => f.name == 'source');
                if (sourceList.length > 0) {
                    sourceValueList = getArrayParam(sourceList[0].list_values);
                    sourceValue = sourceValueList.length > 0 ? sourceValueList[0].value : '';
                    sourceValueList = sourceValueList.map(source => {
                        return { value: source.value, label: source.value, name: 'source' }
                    });
                    sourceData = sourceValueList[0];
                }
                let URLList = [];
                URLList = getArrayParam(this.state.URLList).map(obj => {
                    return { value: obj.id, label: obj.name };
                });
                URLList.unshift({ value: defaultRedirectValue.noRedirectValue, label: 'No redirect. Thank you message is shown.' }, { value: defaultRedirectValue.externalRedirectValue, label: 'Redirect to external page' });

                let fieldItemList = [
                    { id: 1, name: 'workflow', label: 'Workflow', placeholder: 'select workflow', value: workflowValue, data: workflowData, listItem: workflowValueList, type: 'list' },
                    { id: 2, name: 'group', label: 'Group', placeholder: 'select group name', value: groupValue, data: groupData, listItem: groupList, type: 'list' },
                    { id: 3, name: 'source', label: 'Source', placeholder: 'select a source', value: sourceValue, data: sourceData, listItem: sourceValueList, type: 'list' },
                    { id: 4, name: 'sales_rep', label: 'Sales Rep', placeholder: 'select sales rep name', value: salesRepData.value, data: salesRepData, listItem: salesRepList, type: 'list' },
                    { id: 5, name: 'submit_button', label: 'Button', placeholder: 'Submit', value: 'Submit', data: { value: 'Submit', name: 'submit_button', label: 'Submit' }, type: 'text' },
                ];
                let optInInfo = { id: 1, name: 'optIn', isChecked: false, value: '' };
                let messageInfo = { title: 'Thank you', message: 'Thank you for your message. We will get back to you if required.', button: 'Ok' };
                let redirectInfo = { websiteValue: defaultRedirectValue.noRedirectValue, externalWebsiteURL: '', URLList };

                if (isValidParam(this.props.data) && isValidParam(this.props.data.formItems)) {
                    let formItems = this.props.data.formItems;
                    if (isValidParam(formItems) && formItems.hasOwnProperty('fieldItemList')) {
                        let tempItemList = formItems.fieldItemList;
                        fieldItemList = fieldItemList.map(m => {
                            let list = tempItemList.filter(f => f.name == m.name);
                            let obj = m;
                            if (list.length > 0) {
                                obj = { ...m, value: list[0].value };
                                if (m.type == 'list') {
                                    let tempData = getArrayParam(m.listItem).filter(f => f.value == list[0].value);
                                    if (tempData.length > 0) {
                                        obj.data = tempData[0];
                                    }
                                } else if (m.type == 'text') {
                                    obj.data = list[0];
                                }
                            }
                            return obj;
                        })
                    }
                    optInInfo = { ...optInInfo, ...formItems.optInInfo };
                    messageInfo = { ...messageInfo, ...formItems.messageInfo };
                    redirectInfo = { ...redirectInfo, ...formItems.redirectInfo };
                }
                let formItems = { fieldItemList, optInInfo, messageInfo, redirectInfo }

                this.setState({ formItems, childViewMouted: true });
            }
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> generateFormItems()':" + error);
        }
    }

    getActions = () => {
        let actions = null;
        try {
            actions = [
                <Button
                    key={'update-button-key'}

                    primary={true}
                    onClick={this.handleFormSubmit}
                    style={{ ...styles.primaryButton, marginTop: '10px' }}
                >{isValidParam(this.props.data) && this.props.data.type == constants.EDIT ? getLocalizedStrings().label.COMMON.UPDATE : getLocalizedStrings().label.COMMON.ADD}</Button>,
                <Button
                    key={'cancel-button-key'}

                    primary={true}
                    onClick={this.handleClose}
                    style={{ ...styles.secondaryButton, marginTop: '10px' }}
                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
            ]
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> getActions()':" + error);
        }
        return actions;
    }

    handleFormSubmit = () => {
        try {
            let selectedFormFields = this.state.selectedFormFields;
            let tempList = selectedFormFields.filter(f => f.name == 'email');
            if (tempList.length == 0) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.EMAIL_REQUIRED_MSG, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }
            let formFields = this.state.formFields;
            selectedFormFields = selectedFormFields.map(field => {
                return { ...field, label: getStringParam(field.label).trim() };
            })
            for (let index = 0; index < selectedFormFields.length; index++) {
                let selectedFormField = selectedFormFields[index];
                if (getStringParam(selectedFormField.label).trim() == '') {
                    let fieldLabel = '';
                    let formField = formFields.filter(f => f.name == selectedFormField.name);
                    if (formField.length > 0) {
                        fieldLabel = formField[0].label;
                    }
                    this.setState({ selectedFormFields });
                    this.props.showCustomSnackBar(`"${fieldLabel}" ${getLocalizedStrings().message.LANDING_PAGE.LABEL_FIELD_CANNOT_BLANK}`, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    return false;
                }
            }
            selectedFormFields = selectedFormFields.map(field => {
                return { ...field, label: getStringParam(field.label).trim() };
            });
            let formItems = this.state.formItems;
            let redirectInfo = getObjectParam(formItems.redirectInfo);
            if (getStringParam(redirectInfo.externalWebsiteURL) != '' && !isUrl(redirectInfo.externalWebsiteURL) && redirectInfo.websiteValue == defaultRedirectValue.externalRedirectValue) {
                this.props.showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.INVALID_URL, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return false;
            }
            let fieldItemList = getArrayParam(this.state.formItems.fieldItemList).map(m => m.data);
            formItems = { ...formItems, fieldItemList }
            let fields = { selectedFormFields, formItems }
            this.props.appContainer.dialogProps.eventName(fields,false);
            this.props.getAppDialog(false, null, null, null, null, null);
            // this.handleClose();
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> handleFormSubmit()':" + error);
        }
    }

    handleClose = () => {
        this.props.appContainer.dialogProps.eventName(null, true);
        this.props.getAppDialog(false, null, null, null, null, null);
    }

    handleAction = (fieldName, type, event, option) => {
        let fieldLabel = '';
        try {
            let selectedFormFields = this.state.selectedFormFields;
            let formFields = this.state.formFields;
            let fieldNameList = this.state.fieldNameList;
            if (type == 'select') {
                let list = selectedFormFields.filter(f => f.name == option.name);
                if (getArrayParam(list).length > 0) {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.FIELD_IS_ALREADY_ADDED, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    return false;
                }
            }
            selectedFormFields = selectedFormFields.map(field => {
                if (field.name == fieldName) {
                    if (type == 'check') {
                        return { ...field, ...{ is_required_field: !field.is_required_field } };
                    } else if (type == 'field') {
                        fieldLabel = isValidParam(event) ? event.currentTarget.value : '';
                        return { ...field, ...{ label: fieldLabel } };
                    } else if (type == 'select') {
                        formFields = formFields.filter(f => f.name == option.name);
                        let obj = field;
                        if (getArrayParam(formFields).length > 0) {
                            obj = formFields[0];
                            fieldNameList = fieldNameList.filter(f => f != option.name);
                            fieldNameList.push(option.name);
                        }
                        return obj;
                    } else {
                        return field;
                    }
                } else {
                    return field;
                }
            });
            this.setState({ selectedFormFields, fieldNameList });
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> handleAction()':" + error);
        }
    }

    removeField = (fieldName) => {
        try {
            let selectedFormFields = this.state.selectedFormFields;
            if (selectedFormFields.length > 1) {
                selectedFormFields = selectedFormFields.filter(f => f.name != fieldName);
                let fieldNameList = this.state.fieldNameList;
                fieldNameList.push(getStringParam(fieldName));
                this.setState({ selectedFormFields, fieldNameList })
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.MINIMUM_ONE_FIELD, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> removeField()':" + error);
        }
    }

    addFormFields = () => {
        try {
            let fieldNameList = getArrayParam(this.state.fieldNameList);
            if (fieldNameList.length > 0) {
                let fieldName = fieldNameList[0];
                let fieldDetails = getArrayParam(this.state.formFields).filter(f => f.name == fieldName);
                if (isValidParam(fieldDetails) && fieldDetails.length > 0) {
                    let selectedFormFields = this.state.selectedFormFields
                    selectedFormFields.push(fieldDetails[0]);
                    fieldNameList = fieldNameList.filter(f => f != fieldName);
                    this.setState({ fieldNameList, selectedFormFields, isScrollDown: true }, () => { this.state.isScrollDown = false });
                }
            } else {
                this.props.showCustomSnackBar(getLocalizedStrings().message.LANDING_PAGE.NO_MORE_FIELD_AVAILABEL, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }

        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> addFormFields()':" + error);
        }
    }
    getDifference=(tempFormFields, selectedFormFields)=> {
        return tempFormFields.filter(object1 => {
        return !selectedFormFields.some(object2 => {
            return object1.value === object2.value;
        });
        });
    }
    createFormfields = () => {
        let fields = [];
        let selectedFormFields = this.state.selectedFormFields;
        let tempFormFields = this.state.formFields;
        let formFields = this.getDifference(tempFormFields,selectedFormFields);
        try {
            fields = selectedFormFields.map(field => {
                return (
                    <div key={`field-key-${field.name}`} className="row" style={{ paddingTop: '10px' }} ref={(ref) => this.scrollDownView = ref}>
                        <div className='column-sm-4' >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                {this.state.formFields.length > 0 && <Autocomplete
                                    key={`select-field-${field.name}`}
                                    defaultValue={this.state.formFields.find(v => v.value == field.value)}
                                    id="sf_auto_field"
                                    sx={{ width: 140 }}
                                    options={formFields}
                                    onChange={this.handleAction.bind(this, field.name, 'select')}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params} variant="outlined" margin="dense"  size='small' className={"sf-fields-bg"} />}
                                />
                                }
                            </FormControl>
                        </div>
                        <div className='column-sm-4' >
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    id="form-field"
                                    key={`field-${field.name}`}
                                    sx={{ width: 140 }}
                                    value={field.label}
                                    title={field.label}
                                    fullWidth={true}
                                    onChange={this.handleAction.bind(this, field.name, 'field')}
                                    inputStyle={{ color: '#333', fontSize: '15px', fontWeight: 'normal' }}
                                    autoComplete="new-password"
                                    margin="dense"
                                    size='small'
                                />
                            </FormControl>
                        </div>
                        <div className="col-sm-2">
                            <div>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="default"
                                        key={`check-box-${field.name}`}
                                        onChange={this.handleAction.bind(this, field.name, 'check')}
                                        checked={field.is_required_field}
                                    />}
                                    labelposition="right"
                                    disabled={field.is_disabled}
                                    style={{ marginLeft: '14px',marginTop:'4px' }}
                                    title={'Required'} />

                            </div>
                        </div>
                        <div className='col-sm-1' style={{paddingTop:'18px'}}>
                            <span className="badge badge-danger" style={{ backgroundColor: '#fb4444', borderRadius: '0px',fontSize:'14px',paddingBottom:'3px',marginLeft:'-10px'}}>
                                <i key={'cross_icon_'} className="fa fa-trash-o"  aria-hidden="true" style={{ cursor: 'pointer',color:'white',width:'23px',paddingLeft:'6px',fontSize:'12px',fontWeight:595 }} onClick={() => this.removeField(field.name)} title={'Delete'} ></i>
                            </span>
                        </div>
                    </div>)
            })
            fields.push(
                <div className="row">
                    <div className="col-md-18" style={{ padding:'10px 0px 10px 0px' }}>
                        <button type="button" className="btn btn-primary btn-lg btn-block add_new_field_btn" onClick={this.addFormFields}>
                            <span style={{ fontSize: 'large', fontWeight: 'bold',color:'white'}}> + </span><span style={{color:'white', fontSize:'large'}}>Add new field</span></button>
                    </div>
                </div>);
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> createFormfields()':" + error);
        }
        return fields;
    }

    handleNestedListToggle = (name, item) => {
        this.setState({ [name]: item.state.open });
    };

    updateFormFieldItem = (formItems) => {
        try {
            this.state.formItems = formItems;
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> updateFormFieldItem()':" + error);
        }
    }

    generateListFormComponent = (name, props) => {
        try {
            if (name == 'formItemField') {
                return <FormItems key={`${name}-key`} {...props} />
            } else if (name == 'formMessageField') {
                return <FormMessageField key={`${name}-key`} {...props} />
            } else if (name == 'redirectField') {
                return <RedirectField key={`${name}-key`} {...props} />
            } else {
                return <div />
            }

        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> generateListFormComponent()':" + error);
        }
    }

    handleCollapseChange = (fieldName) => {
        this.setState({
            [fieldName]: !this.state[fieldName]
        })
    }

    createFormItems = () => {
        let elements = [];
        let listFields = [{ name: 'formItemField', label: 'Form' }, { name: 'formMessageField', label: 'Thank you message' }, { name: 'redirectField', label: 'Redirect after submission' }];
        try {
            let props = { ...this.props, formItems: this.state.formItems, updateFormFieldItem: this.updateFormFieldItem };
            elements = listFields.map(data => {
                let fieldName = data.name;
                return (
                    <div>

                        <List key={`list-${fieldName}`} style={{ padding: '0px', border: '1px solid #c7c3c3', margin: '5px 0px' }} >
                            <ListItem
                                key={`list-item-${fieldName}`}
                                style={{ backgroundColor: '#e4e0e0', cursor: 'pointer' }}
                                // onClick={this.handleNestedListToggle.bind(this, fieldName)}
                                onClick={this.handleCollapseChange.bind(this, fieldName)}
                            >
                                <ListItemIcon >
                                    {this.state[fieldName] ? <ArrowDropDown /> : <ArrowRight />}
                                </ListItemIcon>
                                <ListItemText primary={data.label} />
                            </ListItem>
                            <Collapse in={this.state[fieldName]}
                                timeout="auto"
                                unmountOnExit={true}
                            >
                                <>{this.generateListFormComponent(fieldName, props)}</>
                            </Collapse>
                        </List>

                    </div>
                );
            })
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> createItems()':" + error);
        }
        return elements;
    }

    render() {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div className='clearfix' style={{ padding: '20px 30px' }}>
                <div style={{ width: '100%', height: '45%', overflow: 'hidden', overflowY: 'scroll' }}>
                    <div className="row">
                        <div className="column-sm-4">
                            <label style={{ marginLeft: '2px',fontSize:'14px',fontWeight:'700' }}>Name</label>
                        </div>
                        <div className="column-sm-4">
                            <label style={{ marginLeft: '2px',fontSize:'14px',fontWeight:'700' }}>Label</label>
                        </div>
                        <div className="column-sm-4">
                            <label style={{ marginLeft: '2px',fontSize:'14px',fontWeight:'700' }}>Required</label>
                        </div>
                    </div>
                    <div>
                        {this.createFormfields()}
                        {this.state.childViewMouted ? <div>
                            {this.createFormItems()}
                        </div> : <div style={{ width: '100%', height: contentHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}


class FormItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formItems: this.props.formItems
        }
    }

    componentDidMount = () => {
    }

    componentWillReceiveProps(nextProps) {
        this.state.formItems = nextProps.formItems;
    }

    handleAction = (name, type, event, option) => {
        try {
            let formItems = this.state.formItems;
            let fieldItemList = getArrayParam(formItems.fieldItemList);
            if (type == 'list') {
                fieldItemList = fieldItemList.map(item => {
                    return item.name == name ? { ...item, value: option.value, data: option } : item;
                });
            } else if (type == 'text') {
                let tempValue = event.currentTarget.value;
                fieldItemList = fieldItemList.map(item => {
                    return item.name == name ? { ...item, value: tempValue, data: { ...item.data, value: tempValue } } : item;
                });
            }

            formItems = { ...formItems, fieldItemList }
            this.props.updateFormFieldItem(formItems);
            this.setState({ formItems });
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> FormItems -> handleAction()':" + error);
        }
    }

    handleOptInAction = () => {
        try {
            let formItems = this.state.formItems;
            let ischecked = formItems.optInInfo.isChecked;
            formItems.optInInfo.isChecked = !ischecked;
            if (ischecked) {
                formItems.optInInfo.value = '';
            }
            this.setState({ formItems });
            this.props.updateFormFieldItem(formItems);
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> FormItems -> handleOptInAction()':" + error);
        }
    }

    handleOptInChanges = (event) => {
        try {
            let value = event.currentTarget.value;
            let formItems = this.state.formItems;
            formItems.optInInfo.value = value;
            this.setState({ formItems });
            this.props.updateFormFieldItem(formItems);
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> FormItems -> handleOptInChanges()':" + error);
        }
    }

    render() {
        let formItems = this.state.formItems;
        let optInInfo = {};
        let fieldItemList = [];
        if (isValidParam(formItems)) {
            optInInfo = formItems.optInInfo;
            fieldItemList = formItems.fieldItemList;
        }
        return (
            <div className="row" style={{ padding: '10px' }}>
                {getArrayParam(fieldItemList).map(item => {
                    return (
                        <div key={`form-item-${item.name}`}>
                            <div className="col-sm-12">
                                <label style={{ fontSize:'14px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>{item.label}</label>
                            </div>
                            <div className="col-md-12" >
                                {item.type == 'list' &&
                                    <FormControl style={{width: '100%',marginTop:'7.5px',marginBottom:'6px' }} className="test" noValidate autoComplete="off">
                                        <Autocomplete
                                            key={`select-field-${item.name}`}
                                            defaultValue={item.listItem.find(v => v.value == item.value)}
                                            options={item.listItem}
                                            id="sf_auto_field"
                                            onChange={this.handleAction.bind(this, item.name, item.type)}
                                            getOptionLabel={(option) => option.label}
                                            disableClearable={true}
                                            renderInput={(params) => <TextField {...params} variant="outlined" margin="dense" size='small' className={"sf-fields-bg"} />}
                                        />

                                    </FormControl>

                                }
                                {item.type == 'text' && <div>
                                    <input type="text" className="input_txtField" value={item.value} onChange={this.handleAction.bind(this, item.name, item.type)} id={`key-text-${item.name}`} placeholder={item.placeholder} />
                                </div>}
                            </div>
                        </div>
                    );
                })}
                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '5px 0px 0px 13px' }}>
                    <div style={{paddingTop:'8px'}}>
                        <label style={{ padding: '8px 0px 0px 5px',fontSize:'14px',fontWeight:'700' }}>Show opt-in Checkbox</label>
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                color="default"
                                key={`check-box-opt-in`}
                                className="opt_in_check_box"
                                checked={optInInfo.isChecked}
                                value={optInInfo.isChecked}
                                onChange={this.handleOptInAction.bind(this)}
                            />}
                            style={{ paddingLeft: '5px',marginTop:'-3px',color:'gray',boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)' }}
                        />
                    </div>
                </div>
                {optInInfo.isChecked && <div style={{ padding: '0px 16px' }}>
                    <input type="text" className="input_txtField" value={optInInfo.value} onChange={this.handleOptInChanges} id="subsctribe-newsletters" placeholder="Subscribe to our newsletters" />
                </div>}
            </div>
        );
    }
}


class FormMessageField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formItems: this.props.formItems
        }
    }

    componentDidMount = () => {
    }

    componentWillReceiveProps(nextProps) {
        this.state.formItems = nextProps.formItems;
    }

    handleActions = (name, event) => {
        try {
            let value = event.currentTarget.value;
            let formItems = this.state.formItems;
            let messageInfo = getObjectParam(formItems).messageInfo;
            messageInfo[name] = value;
            formItems.messageInfo = messageInfo;
            this.setState({ formItems });
            this.props.updateFormFieldItem(formItems);
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> FormMessageField -> handleActions()':" + error);
        }
    }

    render() {
        let messageInfo = getObjectParam(this.state.formItems).messageInfo;
        return (
            <div className="row" style={{ padding: '10px' }}>
                <div className="col-sm-12" style={{lineHeight:'32px'}}>
                    <label style={{fontSize:'16px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>Title</label>
                    <div>
                        <input type="text" className="input_txt_field" style={{height:'37px' ,fontSize:'15px', fontFamily:'inherit' }} value={getStringParam(messageInfo.title)} onChange={this.handleActions.bind(this, 'title')} id="message-title" placeholder="Thank you" />
                    </div>
                    <label style={{fontSize:'16px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>Message</label>
                    <div>
                        <textarea type="text" className="input_txt_field landing-page-text-area" style={{fontSize:'15px', fontFamily:'inherit'}} value={getStringParam(messageInfo.message)} onChange={this.handleActions.bind(this, 'message')} id="message" rows="3" placeholder="Thank you for your message." />
                    </div>
                    <label style={{fontSize:'16px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>Button</label>
                    <div>
                        <input type="text" className="input_txt_field" style={{height: '40px',fontSize:'15px'}} value={getStringParam(messageInfo.button)} onChange={this.handleActions.bind(this, 'button')} id="message-button" placeholder="Ok" />
                    </div>
                </div>
            </div>
        );
    }
}

class RedirectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formItems: this.props.formItems,
        }
    }

    componentDidMount = () => {
        let formItems = this.state.formItems;
        let redirectInfo = getObjectParam(formItems).redirectInfo;
        this.setState({ isExternalField: redirectInfo.websiteValue == defaultRedirectValue.externalRedirectValue ? true : false });
    }

    componentWillReceiveProps(nextProps) {
        this.state.formItems = nextProps.formItems;
    }

    handleActions = (event, newValue, name) => {
        try {
            let value = '';
            let formItems = this.state.formItems;
            let redirectInfo = getObjectParam(formItems).redirectInfo;
            if (name == 'websiteValue') {
                value = newValue.value;
                this.state.isExternalField = value == defaultRedirectValue.externalRedirectValue ? true : false;
            } else {
                value = event.currentTarget.value;
                if (name == 'externalWebsiteURL') {
                    value = value.replace(/\s/g, '');
                }
            }
            redirectInfo[name] = value;
            formItems.redirectInfo = redirectInfo;
            this.setState({ formItems });
            this.props.updateFormFieldItem(formItems);
        } catch (error) {
            console.error("Error in 'LandingPageFormBuilder.js -> RedirectField -> handleActions()':" + error);
        }
    }

    render() {
        let redirectInfo = isValidParam(this.state.formItems) ? getObjectParam(this.state.formItems.redirectInfo) : {};
        let websiteValue = redirectInfo.websiteValue;
        let list = getArrayParam(redirectInfo.URLList).find(f => f.value == redirectInfo.websiteValue);
        if (!list) {
            websiteValue = defaultRedirectValue.noRedirectValue;
            list = {
                value: websiteValue,
                label: 'No redirect, Thank you message is shown'
            }
        }



        return (
            <div className="row" style={{ padding: '10px' }}>
                <div className="col-sm-12">
                    <label style={{ fontSize:'16px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>Website</label>
                    <div>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            {getArrayParam(redirectInfo.URLList).length > 0 && <Autocomplete
                                key={`select-field`}
                                id="sf_auto_field"
                                value={list}
                                options={getArrayParam(redirectInfo.URLList)}
                                onChange={(event, newValue) => this.handleActions(event, newValue, 'websiteValue')}
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} label={'Select a website'} variant="outlined" margin='dense' size='small' className={"sf-fields-bg"} />}
                            />
                            }
                        </FormControl>
                    </div>
                    {getBooleanParam(this.state.isExternalField) && <div>
                        <label style={{fontSize:'16px',color:'rgb(51, 51, 51)',fontWeight:'700' }}>External Page URL</label>
                        <div>
                            <input type="text" className="input_txt_field" style={{height: '40px',marginTop:'10px'}} value={redirectInfo.externalWebsiteURL} onChange={(event) => this.handleActions(event, "", 'externalWebsiteURL')} id="external-url" placeholder="http://yoursite.com/thank-you-page." />
                        </div>
                    </div>}
                </div>
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageFormBuilder);

