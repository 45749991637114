import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
import { isValidParam, getStringParam, getBooleanParam } from '..//helper/parameterVerifier';
import { actions } from '..//constants/actions';

function testMail(params) {
    let url = null;
    try {
        url = endPoints.SEND_MAIL.TEST;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'sendMailActions.js -> sendMail()':" + e); }
}

function sendMail(params) {
    let url = null;
    try {
        url = endPoints.SEND_MAIL.SEND;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'sendMailActions.js -> sendMail()':" + e); }
}

function testSms(params){
    let url = null;
    try{
        url = endPoints.SMS_TEMPLATE.TEST_SMS;
        return HTTPClient.post(url, params);
    }catch (e){
        console.error("Error in 'sendSMSActions.js -> sendSms()':" + e);
    }
}

function scheduleMail(params) {
    let url = null;
    try {
        url = endPoints.SEND_MAIL.SCHEDULE;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'sendMailActions.js -> scheduleMail()':" + e); }
}

function verifyEailStatus(params) {
    let url = null;
    try {
        url = endPoints.SEND_MAIL.VERIFYSTATUS;
        return HTTPClient.post(url, params);
    } catch (e) { console.error("Error in 'sendMailActions.js -> verifyEailStatus()':" + e); }
}

function getUserList() {
    let url = null;
    try {
        url = endPoints.USERS.USERLIST_GET;
        if(isValidParam(url)){
            return HTTPClient.get(url, null);
        }
    } catch (e) { console.error("Error in 'sendMailActions.js -> getUserList()':" + e); }
}

function getTemplateCategoryList() {
    return HTTPClient.get(endPoints.SEND_MAIL.TEMPLATE_CATEGORIES_GET, null);
}

function getTemplateList(category, subcategory, selectedDataFetchType, callFrom, tableId, recordId, fddInfo) {
    let isStatusCheck = true;
    callFrom = getStringParam(callFrom);
    if(callFrom === 'sendMail'){
        isStatusCheck = false;
    }
    let params = { category_name: category, sub_category: subcategory, selectedDataFetchType:selectedDataFetchType, type_id: 2, is_status_check: isStatusCheck,tableId:tableId , recordId:recordId , fdd_info: fddInfo};
    return HTTPClient.get(endPoints.SEND_MAIL.TEMPLATE_GET, params);
}

function getLibraryTemplateList(category) {
    let params = { category_name: category };
    return HTTPClient.get(endPoints.SEND_MAIL.LIBRARY_TEMPLATE_GET, params)
}

function getUserTemplateList(category) {
    let params = { category: [category] };
    return HTTPClient.get(endPoints.MARKETING_TEMPLATE.USER_TEMPLATE, params)
}

function getTemplate(param) {
    let url = endPoints.SEND_MAIL.MAIL_TEMPLATE_GET ;
    return HTTPClient.get(url, param)
}

function getVerifiedEmais(isUserEmail) {
    let params = {};
    params.is_user_emails = getBooleanParam(isUserEmail);
    return {
        type: actions.VERIFIED_EMAILS_GET,
        payload: HTTPClient.get(endPoints.USERS.VERIFIED_EMAILS_GET, params)
    }
}

function getEmailSignature(param) {
    return HTTPClient.get(endPoints.USERS.GET_EMAIL_SIGNATURE, param);
}

function getEmails(object, params) {
    object = getStringParam(object);
    let url = endPoints.COMMON.EMAILS_GET;
    url = url.replace('{object}', object);
    return HTTPClient.get(url, params);
}

function getScheduleEmails(object, params) {
    object = getStringParam(object);
    let url = endPoints.SEND_MAIL.GET_SCHEDULE_EMAILS;
    return HTTPClient.get(url, params);
}

function storeTemplateListInRedux(data) {
    return function (dispatch) {
        dispatch({
            type: actions.UPDATE_TEMPLATE_LIST,
            payload:data
        })
    }
}

function storeTemplateCategoriesInRedux(data) {
    return function (dispatch) {
        dispatch({
            type: actions.UPDATE_TEMPLATE_CATEGORIES_LIST,
            payload:data
        })
    }

  
}

function storeSendMailDataInRedux(data) {
    return function (dispatch) {
        dispatch({
            type: actions.STORE_SEND_MAIL_DATA,
            payload:data
        })
    }
}
function resetSendMailDataInRedux() {
    return function (dispatch) {
        dispatch({
            type: actions.RESET_SEND_MAIL_DATA,
            payload:null
        })
    }
}
function updateSendMailDataInRedux(data) {
    return function (dispatch) {
        dispatch({
            type: actions.UPDATE_SEND_MAIL_DATA,
            payload:data
        })
    }
}
function updateSendMailTemplateDataInRedux(data) {
    return function (dispatch) {
        dispatch({
            type: actions.UPDATE_SEND_MAIL_TEMPLATE_DATA,
            payload:data
        })
    }
}
export {
    testMail,
    sendMail,
    testSms,
    getUserList,
    getTemplateCategoryList,
    getTemplateList,
    getLibraryTemplateList,
    getUserTemplateList,
    getTemplate,
    getVerifiedEmais,
    getEmailSignature,
    verifyEailStatus,
    getEmails,
    scheduleMail,
    getScheduleEmails,
    storeTemplateListInRedux,
    storeTemplateCategoriesInRedux,
    storeSendMailDataInRedux,
    resetSendMailDataInRedux,
    updateSendMailDataInRedux,
    updateSendMailTemplateDataInRedux
}