import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayOfString } from '../../../services/helper/utils';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Select, MenuItem, InputLabel, Icon } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { refreshListView, } from '../../../services/actions/listViewAcions';
import { useDispatch, useSelector } from "react-redux";

const SecurityLevelDetail = ({ data, recordId, object }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [description, setDescription] = useState('');
    const [accessList, setAccessList] = useState([]);
    const [adminPermission, setAdminPermission] = useState([]);
    const [operationPermission, setOperationPermission] = useState([]);
    const [isShowLoader, setIsShowLoader] = useState(true);
    const [menuItems, setMenuItems] = useState({});
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    
    


    useEffect(() => {
        getDataAndProcessData();
        getAllMenuItems();
    }, []);


   const getAllMenuItems = async () => {
    try {
        const response = await HTTPClient.get(endPoints.MENU_DESIGN.GET_MENU_DETAILS, {});
        setMenuItems(response);
        
    } catch (error) {
        console.error('Error fetching menu items:', error);
    }
   }

    const getDataAndProcessData = () => {
        if (accessList.length == 0 && adminPermission.length == 0 && operationPermission.length == 0) {
            let params = {};
            params.securityLevelId = recordId;
            var promise = Promise.resolve(HTTPClient.post(endPoints.SECURITY_LEVEL.GET_SECURITY_LEVEL_DETAILS, params));
            promise.then((response) => {
                if (response.status !== -1) {
                    let isfranchisor = app.me.is_franchisor
                    let objectData = [];
                    let tempObjectData = response.data.tableList;
                    if (isfranchisor) {
                        objectData = tempObjectData.filter(f => (f !== "Compliance" && f !== "Reported Sales"));
                    } else {
                        objectData = tempObjectData;
                    }
                    sortArrayOfString(objectData);
                    let finalData = [];
                    objectData.map((items, Index) => {
                        if (response.data.accessList.hasOwnProperty(items)) {
                            let finalObject = {};
                            let records = response.data.accessList[items];
                            finalObject.isNoRecords = false;
                            for (let i = 0; i < records.length; i++) {
                                if (records[i].selected) {
                                    finalObject.value = records[i].filterId;
                                    finalObject.create = records[i].hasOwnProperty("flag") ? records[i].flag.includes("1") : false;
                                    finalObject.edit = records[i].hasOwnProperty("flag") ? records[i].flag.includes("2") : false;
                                    finalObject.delete = records[i].hasOwnProperty("flag") ? records[i].flag.includes("3") : false;
                                    if (records[i].filterTitle === "No Records") {
                                        finalObject.isNoRecords = true;
                                    }
                                }
                            }
                            if (finalObject.value === null) {
                                finalObject.value = records[0].filterId;
                                finalObject.create = true;
                                finalObject.edit = true;
                                finalObject.delete = true;
                            }
                            finalObject.name = items;
                            finalObject.options = records;
                            finalData.push(finalObject);
                        }
                    })
                    setDescription(data.description);
                    setAccessList(finalData);
                    setAdminPermission(response.data.permission.Administration);
                    setOperationPermission(response.data.permission.Operations);
                    setIsShowLoader(false);
                    setSelectedMenuItem(response?.data?.menu[0]?.home_menu_id ?? 1);
                } else if (response.status == -1) {
                    let errorMessage = '';
                    errorMessage = getLocalizedStrings().message.SECURITY_LEVEL.ERROR_MSG_1;
                    dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    closeDrawer();
                }
            });
        }
    }

    const generateMenuItems = (objName, options) => {
        let menuItemsHtml = [];
        options.map((items, Index) => {
            let menuItem = <MenuItem
                value={items.filterId}
                key={Index}
                name={items.filterTitle}
                onClick={(e) => updateValueState(e, objName, "value", items.filterId)}
            >{items.filterTitle}</MenuItem>;
            menuItemsHtml.push(menuItem);
        })
        return menuItemsHtml;
    }

    const updateValueState = (event, objName, callFrom, data) => {
        let dataAccessList = [];
        accessList.map((items, Index) => {
            if (items.name === objName) {
                if (callFrom === "value") {
                    items.value = data;
                    items.isNoRecords = items.options.some(option => option.filterTitle === "No Records" && data === option.filterId);
                } else if (callFrom === "create") {
                    if (items.create) {
                        items.create = false;
                    } else {
                        items.create = true;
                    }
                } else if (callFrom === "edit") {
                    if (items.edit) {
                        items.edit = false;
                    } else {
                        items.edit = true;
                    }
                } else if (callFrom === "delete") {
                    if (items.delete) {
                        items.delete = false;
                    } else {
                        items.delete = true;
                    }
                }
            }
            dataAccessList.push(items);
        })
        setAccessList(dataAccessList);
    }

    const updateValuePermissionState = (event, data, callFrom) => {
        let dataList = [];
        if (callFrom === "adminstration") {
            adminPermission.map((items, Index) => {
                if (data === items.t_pk) {
                    if (items.checked) {
                        items.checked = false;
                    } else {
                        items.checked = true;
                    }
                }
                dataList.push(items);
            })
            setAdminPermission(dataList);
        } else {
            operationPermission.map((items, Index) => {
                if (data === items.t_pk) {
                    if (items.checked) {
                        items.checked = false;
                    } else {
                        items.checked = true;
                    }
                }
                dataList.push(items);
            })
            setOperationPermission(dataList);
        }
    }

    const saveSecurityLevelPermission =async () => {
        await HTTPClient.get(endPoints.MENU_DESIGN.GET_MENU_DETAILS, { homemenu_id: selectedMenuItem });
        if (description !== null && description !== "" && description.replace(/\s/g, '').length > 0) {
            setIsShowLoader(true);
            let accessListData = [];
            let adminPermissionData = [];
            let operationPermissionData = [];
            accessList.map((items, Index) => {
                let flag = null;
                if (items.create || items.edit || items.delete) {
                    if (items.create) {
                        if (flag === null) {
                            flag = "1";
                        }
                    }
                    if (items.edit) {
                        if (flag === null) {
                            flag = "2";
                        } else {
                            flag += "##2";
                        }
                    }
                    if (items.delete) {
                        if (flag === null) {
                            flag = "3";
                        } else {
                            flag += "##3";
                        }
                    }
                }
                let dataAccess = {};
                dataAccess.t_item_id = items.value;
                dataAccess.t_flag = flag;
                accessListData.push(dataAccess);

            })
            adminPermission.map((items, Index) => {
                if (items.checked) {
                    let dataAdmin = {};
                    dataAdmin.t_item_id = items.t_pk;
                    dataAdmin.t_type = 4;
                    adminPermissionData.push(dataAdmin);
                }
            })
            operationPermission.map((items, Index) => {
                if (items.checked) {
                    let dataOperation = {};
                    dataOperation.t_item_id = items.t_pk;
                    dataOperation.t_type = 3;
                    operationPermissionData.push(dataOperation);
                }
            })
            let params = {};
            params.t_temp_id = data.id;
            params.t_name = data.name;
            params.home_menu_id = menuItems.allMenus.find(item => item.homeMenuId === selectedMenuItem)?.homeMenuId;
            params.t_description = description;
            params.accessList = accessListData;
            let permission = {};
            permission.Administration = adminPermissionData;
            permission.Operations = operationPermissionData;
            params.permission = permission;
            params.menu = [{ "t_home_menu_id": selectedMenuItem }];
            if (isValidParam(params)) {
                var promise = Promise.resolve(HTTPClient.post(endPoints.SECURITY_LEVEL.SAVE_PERMISSION, params));
                promise.then((response) => {
                    if (response.status !== -1) {
                        setIsShowLoader(false);
                        let successMessage = '';
                        successMessage = getLocalizedStrings().message.SECURITY_LEVEL.SUCCESS_MSG_1;
                        dispatch(showCustomSnackBar(successMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        closeDrawer();
                        refreshListView(object);
                    } else if (response.status === -1) {
                        let errorMessage = '';
                        errorMessage = getLocalizedStrings().message.SECURITY_LEVEL.ERROR_MSG_1;
                        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        closeDrawer();
                    }
                });
            } else {
                let errorMessage = '';
                errorMessage = getLocalizedStrings().message.SECURITY_LEVEL.ERROR_MSG_1;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                closeDrawer();
            }
        } else {
            let errorMessage = '';
            errorMessage = getLocalizedStrings().message.SECURITY_LEVEL.ERROR_MSG_2;
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            document.getElementById('Access').focus();
        }
    }

    const closeDrawer = () => {
        dispatch(getAppDrawer(false, null, null, null, null));
    }

    const getAccessDetails = () => {
        let dataToProcess = {};
        if (accessList.length === 0 && adminPermission.length === 0 && operationPermission.length === 0) {
            this.getDataAndProcessData();
            if (accessList.length > 0 && adminPermission.length > 0 && operationPermission.length > 0) {
                dataToProcess.accessList = accessList;
                dataToProcess.adminPermission = adminPermission;
                dataToProcess.operationPermission = operationPermission;
            }
        } else {
            dataToProcess.accessList = accessList;
            dataToProcess.adminPermission = adminPermission;
            dataToProcess.operationPermission = operationPermission;
        }
        return dataToProcess;
    }

    const handleDescriptionChange = (value) => {
        setDescription(value);
    }

    const generateOperationToolTipChild = (data) => {
        let children = <Icon
            id={'operationicon' + data}
            style={{ cursor: 'pointer', color: '#717171' }}
        >
            info_outline
        </Icon>
        return children;
    }

    const generateAdminToolTipChild = (data) => {
        let children = <Icon
            id={'adminstrationicon' + data}
            style={{ cursor: 'pointer', color: '#717171' }}>
            info_outline
        </Icon>
        return children;
    }

    let showLoaderStatus = isShowLoader;

    if (!showLoaderStatus) {

        let dataToProcess = getAccessDetails();
        let accessLists = dataToProcess.accessList;
        let adminPermissions = dataToProcess.adminPermission;
        let operationPermissions = dataToProcess.operationPermission;
        let accessListsHtml = [];
        let adminPermissionsHtml = [];
        let operationPermissionsHtml = [];

        return (
            <div style={{ overflow: 'hidden' }}>
                <div style={{ ...styles.row, paddingRight: '13px', marginLeft: '15px' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ paddingTop: '15px', overflow: 'hidden', float: 'right' }}>
                            <div style={{ ...styles.sf_6, width: 'auto', padding: '0px' }}>
                                <Button
                                    key='close'
                                    onClick={() => closeDrawer()}
                                    style={{ ...styles.secondaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                            </div>
                        </div>
                        <div style={{ paddingTop: '15px', overflow: 'hidden', float: 'right' }}>
                            <div style={{ ...styles.sf_6, width: 'auto', padding: '0px', }}>
                                <Button
                                    key='save'
                                    onClick={() => saveSecurityLevelPermission()}
                                    style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.row, paddingTop: '20px', paddingRight: '0px' }}>

                    <div style={{ width: '55.2%', marginLeft: '38px' }}>
                        <FormControl style={{ width: '100%', marginLeft: '30px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                name="Access"
                                id="Access"
                                label={getLocalizedStrings().label.SECURITY_LEVEL.DESCRIPTION}
                                fullWidth={true}
                                value={description}
                                onChange={(e) => handleDescriptionChange(e.target.value)}
                                margin='dense'
                                size='small'
                                autoComplete="new-password" className={"sf-fields-bg"} /></FormControl>
                    </div>
                </div>
              { (app.me.is_tenant && appContainer?.drawerProps?.actionDialogName !== 'Admin') && <div style={{ ...styles.row, width: '100%',marginTop:'3rem' }}>
                    <div id={'accessListDiv1' + 'Menu Items'} style={{ ...styles.row, width: '100%', float: 'left', paddingBottom: '2px', marginBottom: '18px' }}>
                        <div id={'accessListDiv2' + 'Menu Items'} style={{ ...styles.sf_3, overflow: 'hidden', float: 'left' }}>
                            <label key={'objLevel' + 'Menu Items'} style={{ fontSize: '14px', lineHeight: '2.8em', width: '60%', color: "#00000073", marginLeft: '60px' }}>{'Menu'}</label>
                        </div>
                        <div id={'accessListDiv3' + 'Menu Items'} style={{ ...styles.sf_5, marginRight: '100px' }}>

                            <div id={'accessListDiv4' + 'Menu Items'} >
                                <FormControl className='sf-securityleveldetail-simple-select-from-control' variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="sf-securityleveldetail-simple-select-outlined-label" className="sf-securityleveldetail">{getLocalizedStrings().label.SECURITY_LEVEL.ASSIGNED_MENU}</InputLabel>
                                    <Select
                                        label={getLocalizedStrings().label.SECURITY_LEVEL.ASSIGNED_MENU}
                                        key={'objOption' + 'Menu Items'}
                                        name={'objOption' + 'Menu Items'}
                                        value={selectedMenuItem}
                                        style={{ height: '38px' }}
                                        className={"sf-fields-bg"}
                                        onChange={(e) => setSelectedMenuItem(e.target.value)}
                                    >
                                        {
                                            menuItems?.allMenus?.map((item) => (
                                                <MenuItem value={item.homeMenuId}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ ...styles.row, ...styles.sf_4, float: 'left', marginLeft: '5px', visibility: 'hidden' }}>
                            <div id={'accessListDiv5' + 'Menu Items'}>
                                <div id={'accessListDiv6' + 'Menu Items'} >

                                </div>
                            </div>
                            <div id={'accessListDiv7' + 'Menu Items'} style={{ visibility: 'hidden' }} >
                                <div id={'accessListDiv8' + 'Menu Items'} style={{ width: 'auto', padding: '0px' }}>


                                </div>
                            </div>
                            <div id={'accessListDiv9' + 'Menu Items'} style={{ visibility: 'hidden' }} >
                                <div id={'accessListDiv10' + 'Menu Items'} className="col-sm-2" style={{ width: 'auto', padding: '0px' }}>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
                <div style={{ ...styles.row, paddingTop: '20px', marginBottom: '15px', paddingRight: '13px' }}>
                    <div style={{ ...styles.sf_12, width: '250px' }}>
                        <label style={{ ...styles.sf_3, fontSize: '20px', lineHeight: '2.8em', color: "black", width: '100%', marginLeft: '70px' }}>{getLocalizedStrings().label.SECURITY_LEVEL.ACCESS}</label>
                    </div>
                </div>
                {
                    accessLists.map((items, Index) => {
                        accessListsHtml.push(<div style={{ ...styles.row, width: '100%' }}>
                            <div id={'accessListDiv1' + items.name} style={{ ...styles.row, width: '100%', float: 'left', paddingBottom: '2px', marginBottom: '18px' }}>
                                <div id={'accessListDiv2' + items.name} style={{ ...styles.sf_3, overflow: 'hidden', float: 'left' }}>
                                    <label key={'objLevel' + items.name} style={{ fontSize: '14px', lineHeight: '2.8em', width: '60%', color: "#00000073", marginLeft: '60px' }}>{items.name}</label>
                                </div>
                                <div id={'accessListDiv3' + items.name} style={{ ...styles.sf_5, marginRight: '100px' }}>

                                    <div id={'accessListDiv4' + items.name} >
                                        <FormControl className='sf-securityleveldetail-simple-select-from-control' variant="outlined" style={{ width: '100%' }}>
                                            <InputLabel id="sf-securityleveldetail-simple-select-outlined-label" className="sf-securityleveldetail">{getLocalizedStrings().label.SECURITY_LEVEL.VIEW}</InputLabel>
                                            <Select
                                                label={getLocalizedStrings().label.SECURITY_LEVEL.VIEW}
                                                key={'objOption' + items.name}
                                                name={'objOption' + items.name}
                                                value={items.value}
                                                style={{ height: '38px' }}
                                                className={"sf-fields-bg"}
                                            >
                                                {generateMenuItems(items.name, items.options)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ ...styles.row, ...styles.sf_4, float: 'left', marginLeft: '5px' }}>
                                    <div id={'accessListDiv5' + items.name}>
                                        <div id={'accessListDiv6' + items.name} >
                                            {!items.isNoRecords &&
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        color="default"
                                                        key={'create' + items.name}
                                                        name={'create' + items.name}
                                                        checked={items.create}
                                                        value={items.create}
                                                        onChange={(e) => updateValueState(e, items.name, "create", "")}
                                                    />}
                                                    label={getLocalizedStrings().label.COMMON.CREATE}
                                                    labelposition="left"
                                                    labelstyle={{ color: '#717171', padding: '0px', width: '25px', fontWeight: 'normal', fontSize: '12px' }}
                                                    style={{ height: 'auto' }}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div id={'accessListDiv7' + items.name} >
                                        <div id={'accessListDiv8' + items.name} style={{ width: 'auto', padding: '0px' }}>
                                            {!items.isNoRecords &&
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        color="default"
                                                        key={'edit' + items.name}
                                                        name={'edit' + items.name}
                                                        checked={items.edit}
                                                        value={items.edit}
                                                        onChange={(e) => updateValueState(e, items.name, "edit", "")}
                                                    />}
                                                    label={getLocalizedStrings().label.COMMON.EDIT}
                                                    labelposition="left"
                                                    labelstyle={{ color: '#717171', padding: '0px', width: '15px', fontWeight: 'normal', fontSize: '12px' }}
                                                    style={{ height: 'auto' }}
                                                />

                                            }

                                        </div>
                                    </div>
                                    <div id={'accessListDiv9' + items.name} >
                                        <div id={'accessListDiv10' + items.name} className="col-sm-2" style={{ width: 'auto', padding: '0px' }}>
                                            {!items.isNoRecords &&
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        color="default"
                                                        key={'delete' + items.name}
                                                        name={'delete' + items.name}
                                                        checked={items.delete}
                                                        value={items.delete}
                                                        onChange={(e) => updateValueState(e, items.name, "delete", "")}
                                                    />}
                                                    label={getLocalizedStrings().label.COMMON.DELETE}
                                                    labelposition="left"
                                                    labelstyle={{ color: '#717171', padding: '0px', width: '25px', fontWeight: 'normal', fontSize: '12px' }}
                                                    style={{ height: 'auto' }}
                                                />

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        );
                    })
                }
                {accessListsHtml}
                
            

                <div style={{ ...styles.row, paddingRight: '13px', marginLeft: '25px' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ paddingTop: '20px', overflow: 'hidden', float: 'left' }}>
                            <label key={'permissionsLevel'} style={{ fontSize: '18px', lineHeight: '2.8em', color: 'black' }}>{getLocalizedStrings().label.SECURITY_LEVEL.PERMISSIONS}</label>
                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: '13px', marginLeft: '25px' }}>
                    <div style={{ ...styles.sf_12, float: 'left', marginLeft: '10px' }}>
                        <div style={{}}>
                            <div style={{ width: 'auto', padding: '0px', float: 'left' }}>
                                <label key={'adminstrationLevel'} style={{ fontSize: '14px', lineHeight: '2.8em', color: "#00000073" }}>{getLocalizedStrings().label.SECURITY_LEVEL.ADMINISTRATION}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: '13px', marginLeft: '25px' }}>
                    <div style={{ ...styles.sf_12, float: 'left', marginLeft: '10px' }}>
                        <div style={{ float: 'left' }}>
                            {
                                adminPermissions.map((items, Index) => {
                                    adminPermissionsHtml.push(
                                        <div id={'adminstrationdiv2' + items.t_pk} style={{ ...styles.sf_6, float: 'left', paddingLeft: '15px' }}>
                                            <div id={'adminstrationdiv3' + items.t_pk} style={{ float: 'left' }}>
                                                <div id={'adminstrationdiv4' + items.t_pk} style={{ width: '205px', padding: '0px' }}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="default"
                                                            key={'adminstration' + items.t_pk}
                                                            name={'adminstration' + items.t_pk}
                                                            checked={items.checked}
                                                            value={items.checked}
                                                            onChange={(e) => updateValuePermissionState(e, items.t_pk, "adminstration")}
                                                        />}
                                                        label={items.t_name}
                                                        labelposition="left"
                                                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                                        style={{ height: 'auto' }}
                                                    />
                                                </div>
                                            </div>
                                            <div id={'adminstrationdiv5' + items.t_pk} style={{ float: 'left' }}>
                                                <div id={'adminstrationdiv6' + items.t_pk} style={{ width: '100%', padding: '0px', marginLeft: '14%', marginTop: '10px' }}>
                                                    <Tooltip title={items.t_message} id={'adminstrationToolTip' + items.t_pk} children={generateAdminToolTipChild(items.t_pk)} arrow placement="right-end">
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {adminPermissionsHtml}
                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: '13px', marginLeft: '25px' }}>
                    <div >
                        <div style={{ float: 'left' }}>
                            <div style={{ width: 'auto', padding: '0px' }}>
                                <label key={'operationsLevel'} style={{ fontSize: '14px', lineHeight: '2.8em', color: "#00000073" }}>{getLocalizedStrings().label.SECURITY_LEVEL.OPERATIONS}</label>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div style={{ float: 'left' }}>
                            {
                                operationPermissions.map((items, Index) => {
                                    operationPermissionsHtml.push(
                                        <div id={'operationdiv2' + items.t_pk} style={{ width: '49.8%', float: 'left', paddingLeft: '26px' }}>
                                            <div id={'operationdiv3' + items.t_pk} style={{ float: 'left' }}>
                                                <div id={'operationdiv4' + items.t_pk} style={{ width: '205px', padding: '0px' }}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            color="default"
                                                            key={'operation' + items.t_pk}
                                                            name={'adminstration' + items.t_pk}
                                                            checked={items.checked}
                                                            value={items.checked}
                                                            onChange={(e) => updateValuePermissionState(e, items.t_pk, "operation")}
                                                        />}
                                                        label={items.t_name}
                                                        labelposition="right"
                                                        labelstyle={{ color: '#717171', padding: '0px', width: 'auto', fontWeight: 'normal' }}
                                                        style={{ height: 'auto' }}
                                                    />
                                                </div>
                                            </div>
                                            <div id={'operationdiv5' + items.t_pk} style={{ float: 'left' }}>
                                                <div id={'operationdiv6' + items.t_pk} style={{ width: '100%', padding: '0px', marginLeft: '14%', marginTop: '10px' }}>
                                                    <Tooltip title={items.t_message} id={'operationToolTip' + items.t_pk} children={generateOperationToolTipChild(items.t_pk)} arrow placement="right-end">
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {operationPermissionsHtml}
                        </div>
                    </div>
                    <div style={{ paddingRight: '13px', marginLeft: '25px' }}>

                    </div>
                </div>
                <div style={{ paddingRight: '13px', marginLeft: '15px', marginBottom: '8%', float: 'right' }}>
                    <div style={{ ...styles.sf_12 }}>
                        <div style={{ paddingTop: '15px', overflow: 'hidden', float: 'left' }}>
                            <div style={{ ...styles.sf_6, width: 'auto', padding: '0px' }}>
                                <Button
                                    key='saveButtom'
                                    onClick={() => saveSecurityLevelPermission()}

                                    style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                        <div style={{ paddingTop: '15px', overflow: 'hidden', float: 'left' }}>
                            <div style={{ ...styles.sf_6, width: 'auto', padding: '0px' }}>
                                <Button
                                    key='closeButtom'
                                    onClick={() => closeDrawer()}
                                    style={{ ...styles.secondaryButton, marginRight: '12px', verticalAlign: 'top' }}
                                >{getLocalizedStrings().label.COMMON.CLOSE}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );

    } else if (showLoaderStatus) {
        return (
            <div style={{ width: '100%', overflow: 'hidden' }}>
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7, marginLeft: 500, marginTop: 280 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div >
        );
    };
};

export default SecurityLevelDetail;