import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import SfDataTable from './sfDataTable';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { LinkToType } from '../../../services/constants/link';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { sortArrayObjectByPropertyOrderType } from '../../../services/helper/utils';
import { getArrayParam, getIntParam, getObjectParam, getStringParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { getMultiColumnTitles } from '../../../services/helper/common';
import { addTab, getActiveTab, TYPE_DETAIL_VIEW, TYPE_DETAIL_VIEW_TASK_LIST } from '../../../services/helper/sfTabManager';
import { setHeader } from '../../../services/actions/headerActions';
import { setOnBoardingReportData, setOnBoardingWorkflow, setWorkflowData, setOnBoardingInitialLoad, setOnBoardingRowSort } from '../../../services/actions/onBoardingNewActions';
import { endPoints } from '../../../services/constants/endPoints';
import { updateWorkflowList } from '../../../services/actions/listViewAcions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from './circularProgress';

const OnBoardingNew = ({ object }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { workflowData, workflow, onboardingReportData, initialLoad, onboardingRowSortData } = useSelector((state) => state.header);
  const refreshOnboardingUnits = useSelector((state) => state.header.refreshOnboardingUnits);
  const [rowSort] = useState({
    onSort: (fieldName, isDescending, records) => onSortFn(fieldName, isDescending, records)
  });
  const [loading, setLoading] = useState(false);

  const getWorkflows = async () => {
    setLoading(true);
    try {
      const data = await HTTPClient.get(endPoints.ONBOARDING.GET_WORKFLOW, {});
      if (Array.isArray(data)) {
        dispatch(setWorkflowData(data));
        dispatch(setOnBoardingWorkflow(data[0]?.workflow_name || ''));
      }
      if(data?.length === 0){
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }

  }

  const handleRun = async () => {
    const findID = workflowData?.find(({ workflow_name }) => workflow_name === workflow);
    try {
      const data = await HTTPClient.get(endPoints.ONBOARDING.GET_REPORT, { workflow_id: `${findID?.id}` });
      if (Array.isArray(data?.results)) {
        let sortedData = [...data.results];
        sortArrayObjectByPropertyOrderType(sortedData, onboardingRowSortData.sortFieldName, onboardingRowSortData.isDescending ? 'desc' : 'asc');
        dispatch(setOnBoardingReportData(sortedData));
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const customEvent = (eventType, column, row, event) => {

    if (eventType === 'is_detail_link' && column.name === 'company') {
      openTab(getMultiColumnTitles(column.name, row), constants.ACCOUNTS_OBJECT, row.id);
    } else if (eventType === 'is_detail_link' && (column.name === 'Overdue' || column.name === 'Pending' || column.name === 'In-progress' || column.name === 'Completed' || column.name === 'Overdue' || column.name === 'Not Started' || column.name === 'expected_open_date')) {
      //List view logic goes here//

      const payload = {
        id: row.id,
        label: row.company,
        field: column.name === 'expected_open_date' ? 'All' : column.name
      }

      openTaskListTab(row, payload , column.name);

    }
  };


  const openTaskListTab = (row, data, columnName) => {
    const { id, label, field } = data;

    if (row[field] > 0 || columnName === 'expected_open_date') {

      dispatch(updateWorkflowList(["Onboarding"], false));

      const newTab = {
        imgName: "fa fa-clock-o",
        object: constants.TASKS,
        url: `/${constants.TASKS}/${constants.TASK_LIST}`,
        type: TYPE_DETAIL_VIEW_TASK_LIST,
        label: `Task List  - ${label}`,
        info: {
          type: "New",
          id: id,
          object: constants.TASKS,
          perent_object: constants.ACCOUNTS_OBJECT,
          label: `Task List  - ${label}`,
          filter: {
            parent_object: constants.ACCOUNTS_OBJECT,
            parent_record_id: id,
            query_id: 0,
            query_name: "All Tasks",
            query_type: "-1",
            search_text: `${field}`,
            search_type: "exact",
            search_field_name: "t_status"
          },
          getExtraFilter: true,
          filterConfig: {
            showTaskFor: true,
            showTaskStatus: true,
            showTaskWorkflow: false
          }
        },
      }

      addTab(newTab);

    } else {
      dispatch(showCustomSnackBar(`No ${field} task present`, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
    }
  }

  const openTab = (label, object, recordId) => {
    let activeTab = getObjectParam(getActiveTab());
    let activeTabIndex = getIntParam(activeTab.index);
    let url = '/' + object + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
    let tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };

    addTab(tab, true);
  }

  const openCustomize = (event, value) => {
    try {
      let token = localStorage.getItem('token');
      let redirectUrl = constants.snapshotUrl;
      redirectUrl += '&token=' + token;
      redirectUrl += '&callingfor=workflows';
      redirectUrl += '&recordId=' + workflowData[0].id;
      let tableId = OBJECT_TABLEID_MAP['accounts'];
      redirectUrl += '&type=edit';
      redirectUrl += '&tableId=' + tableId;
      window.open(redirectUrl);
    } catch (error) {
      console.error("Error in 'OnboardingUnits.js -> openCustomize()':" + error);
    }
  }


  const onSortFn = (fieldName, isDescending, recordsData) => {
    try {
      let tempRecords = [...recordsData];
      sortArrayObjectByPropertyOrderType(tempRecords, fieldName, isDescending ? 'desc' : 'asc');
      dispatch(setOnBoardingRowSort({sortFieldName: fieldName, isDescending: isDescending}));
      dispatch(setOnBoardingReportData(tempRecords));
      }
    catch (error) {
      console.error("Error in 'operationsReports.js -> onSort()':" + error);
    }
  }


  const downloadExcelReport = (e) => {
    if (e !== null && e !== undefined) {
      let beforeHeaderComponent = excelGenerateBeforeHeaders();
      let headerComponents = excelGenerateHeaders();
      let rowComponents = excelGenerateRows();

      let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
      let htmlData = htmlTable;
      if (isValidParam(htmlData) && htmlData !== '') {
        htmlData = htmlData.replace("%22", "\"");
        var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = 'ResultSet_' + new Date().getTime() + '.xls';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  }

  const excelGenerateBeforeHeaders = () => {
    let cells = '';
    let reportName = "Onboarding Report";
    let tempColumns = getArrayParam(fields);


    let columns1 = tempColumns.filter(f => !f.is_hidden);
    let columnsLength = getArrayParam(columns1).length;


    let combinedText = `${reportName}<br>Workflow Name: ${onboardingReportData[0]?.workflow_name}`;


    cells = cells + `<tr><td colspan="${columnsLength}">${combinedText}</td></tr>`;

    return cells;
  }

  const excelGenerateHeaders = () => {
    let cells = '';
    let tempColumns = getArrayParam(fields);
    let columns1 = tempColumns.filter(f => {
      let temp;
      if (!f.is_hidden) { temp = f; }
      return temp;
    });
    columns1.forEach(f => {
      cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
    });
    cells = '<tr>' + cells + '</tr>';
    return cells;
  }

  const excelGenerateRows = () => {
    let rows = getArrayParam([...onboardingReportData]);
    let columns1 = getArrayParam(fields);
    let rowData = '';
    if (rows.length !== 0) {
      let cells = '';
      rows.forEach((rowObject, rowIndex) => {
        let innerCells = '';
        columns1.forEach((columnObject, colIndex) => {
          if (!columnObject.is_hidden) {
            let value = rowObject.hasOwnProperty(columnObject.name) ? rowObject[columnObject.name] : "";
            innerCells = innerCells + '<td >' + value + ' </td>';
          }
        })
        if (rowIndex % 2 === 0) {
          cells = cells + ('<tr>' + innerCells + ' </tr>');
        } else {
          cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
        }

      });
      rowData = cells;
    } else {
      rowData = ('<tr> <td colspan="' + (columns1.length) + '"> No data Found  </td>  </tr>');
    }
    return rowData;

  }


  useEffect(() => {

    let tab = getObjectParam(getActiveTab());
    let label = tab.label;
    if (label === 'Onboarding') {
      label = getLocalizedStrings().label.HEADER['Onboarding'];
    }
    dispatch(setHeader(constants.ONBOARDING_OBJECT, label, null, false, true));

  }, [refreshOnboardingUnits]);

  useEffect(() => {
    if (initialLoad && workflowData.length === 0) {
    getWorkflows();
    dispatch(setOnBoardingInitialLoad(false));
    }
    if (onboardingReportData.length > 0) {
    sortArrayObjectByPropertyOrderType(onboardingReportData, onboardingRowSortData.sortFieldName, onboardingRowSortData.isDescending ? 'desc' : 'asc');
    }
  }, []);

  useEffect(() => {
    if (onboardingReportData.length === 0 && workflowData.length > 0) {
      handleRun();
    }
  }, [workflowData]);

  useEffect(() => {
    const table = document.getElementById('sfCustomTable');
    const headers = table?.querySelectorAll('th');
    let overdueIndex = -1;
    let completedIndex = -1;

    headers?.forEach((item) => {
      if (item.innerText.includes('Overdue')) {
        overdueIndex = item.cellIndex;
      } else if (item.innerText.includes('Completed')) {
        completedIndex = item.cellIndex;
      }
    });

    const rows = table?.querySelectorAll('tr');
    const coloredLinks = [];

    const colorLinks = (index, color) => {
      rows?.forEach((row) => {
        const cells = row.querySelectorAll('td');
        if (cells.length > index) {
          const cellValue = parseFloat(cells[index].innerText);
          if (!isNaN(cellValue)) {
            const link = cells[index].querySelector('div > div > a');
            if (link) {
              link.style.color = color;
              coloredLinks.push(link);
            }
          }
        }
      });
    };

    if (overdueIndex !== -1) {
      colorLinks(overdueIndex, 'red');
    }
    if (completedIndex !== -1) {
      colorLinks(completedIndex, 'green');
    }

    return () => {
      coloredLinks.forEach(link => {
        link.style.removeProperty('color');
      });
    };
  }, [onboardingReportData , loading]);


  return (
    <Box clasName="onboarding-new-container" style={{ margin: '0 auto', width: '98%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Select Box Container */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, width: 200 }} size='small'>
            <InputLabel id="workflow-label">Workflow</InputLabel>
            <Select
              labelId="workflow-label"
              id="select-workflow"
              value={workflow}
              label="Workflow"
              onChange={(e) => { dispatch(setOnBoardingWorkflow(e.target.value)) }}
            >
              {workflowData?.map(({ workflow_name }) => (
                <MenuItem key={workflow_name} value={workflow_name}>
                  {workflow_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="col-md-1" style={{ paddingLeft: '0px', display: 'flex', width: '15%', height: '47px', alignItems: 'center' }}>
            <Button
              onClick={() => handleRun()}
              style={{
                ...styles.primaryButton,
                minWidth: '56px',
                width: '19%'
              }}
              title={'Run'}
            > Run</Button>
          </div>
        </Box>
        {/* Button container */}
        {onboardingReportData.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            startIcon={<i className="far fa-file-excel" style={{ fontSize: '20px', color: 'green' }} />}
            onClick={(e) => downloadExcelReport(e)}
            style={{ ...styles.listViewPrimaryButton, backgroundColor: 'inherit', color: '#717171', border: '0px', minWidth: '40px', marginTop: 4, padding: "6px 5px" }}
            title={getLocalizedStrings().label.REPORT.EXCEL}
          ></Button>
          <Button
            startIcon={<i className="material-icons" style={{ fontSize: '20px', color: '#717171' }} >settings</i>}
            onClick={(event) => openCustomize(event, constants.EDIT)}
            style={{ ...styles.listViewPrimaryButton, backgroundColor: 'inherit', color: '#717171', border: '0px', minWidth: '40px', marginTop: 4, padding: "6px 5px" }}
            title={getLocalizedStrings().label.COMMON.CUSTOMIZE}
          ></Button>
        </Box>}
      </Box>

      <div ref={tableRef}>
        {onboardingReportData.length > 0 && !loading ? <SfDataTable columns={fields}
          rowSort={{...rowSort,sortFieldName:onboardingRowSortData.sortFieldName,isDescending:onboardingRowSortData.isDescending}}
          rows={onboardingReportData}
          customEvent={(eventType, column, row, event) => customEvent(eventType, column, row, event)}

        /> : (loading ? <div style={{ height: '50vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}> <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} /></div></div> : (!initialLoad ? <p style={{ color: 'red', fontWeight: '500', marginLeft: '1rem' }}> No Data available </p> : null))}
      </div>
    </Box>
  )
}

export default OnBoardingNew;

const fields = [
  { name: 'company', label: 'Unit', width: 90, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'Main_Contact', label: 'Main Contact', width: 60, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 't_status', label: 'Status', width: 60, is_hidden: false, is_email_field: false, is_detail_link: false, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'Overdue', label: 'Overdue', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'Pending', label: 'Pending', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'In-progress', label: 'In Progress', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'Completed', label: 'Completed', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'Not Started', label: 'Not Started', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
  { name: 'expected_open_date', label: 'Expected Open Date', width: 60, is_hidden: false, is_email_field: false, is_detail_link: true, is_phone_field: false, is_mobile_field: false, is_button: false, is_sortable: true, style: {} },
];
