import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormControl,
    Select as SelectField,
    MenuItem,
    InputLabel,
    Icon,
    Radio,
    RadioGroup, Chip
} from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { isValidParam, isInvalidParam, isInteger, getArrayParam, getIntParam, getObjectParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { addNode } from '../../../services/actions/automationCampaignActions';
import { removeError, getNodesByActionName } from '../../../services/helper/automationUtils';
import { sortArrayObjectByProperty, isEmailExistsInList } from '../../../services/helper/utils';
import { addCampaignTypeIntoReduxStore, getObjectLabelByObject } from '../../../services/helper/common';
import { addTab, TYPE_LIST_VIEW, getActiveTab } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { getAllCampaignTypes } from "../../../services/actions/campaignTypeActions";
import ShowCircularProgress from '../components/circularProgress';

const AutomationTouchCampaign = ({ data, closeDrawer }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const campaignTypes = useSelector((state) => state.campaignTypes);
    const automationCampaign = useSelector((state) => state.automationCampaign);

    const [stateFilterType, setFilterType] = useState('group');
    const [filter_type, setFilter_Type] = useState(0);
    const [stateFilters, setStateFilters] = useState([]);
    const [stateRestofFilters, setRestofFilters] = useState([]);
    const [stateScheduleDate, setScheduleDate] = useState(null);
    const [stateHDate, setHDate] = useState(null);
    const [stateSenderEmail, setSenderEmail] = useState(app.me.email);
    const [stateResendAllValue, setResendAllValue] = useState('No');
    const [filters_loading, setFilters_Loading] = useState(false);
    const [stateIsMulti, setIsMulti] = useState(false);
    const [stateFromName, setFromName] = useState('');
    const [stateCampaignName, setCampaignName] = useState('');
    const [stateResendAfter, setResendAfter] = useState(null);
    const [stateSendOnWeekdays, setSendOnWeekdays] = useState(null);
    const [stateIsCheckBusinessDays, setIsCheckBusinessDays] = useState(false);
    const [verifiedEmailIds, setVerifiedEmailIds] = useState(null);
    const [stateVerifiedEmailOptions, setVerifiedEmailOptions] = useState(null);
    const [stateAutoresponderId, setAutoresponderId] = useState(0);
    const [status, setStatus] = useState("");
    const [isFocusOnCampaignName, setIsFocusOnCampaignName] = useState(true);
    const [placeholder, setPlaceholder] = useState('');
    const [stateCampaignType, setCampaignType] = useState(null);
    const [campTypelistArr, setCampTypelistArr] = useState(null);
    const [campTypeMenuItems, setCampTypeMenuItems] = useState(null);
    const [stateIsDisableSenderEmail, setIsDisableSenderEmail] = useState(false);
    const [stateIsDisableRefresh, setIsDisableRefresh] = useState(false);
    const [changeLog, setchangeLog] = useState({ stateFilters: [] });
    const [mounted, setMounted] = useState(false);



useEffect(()=>{
    if (campaignTypes.hasOwnProperty('data') && campaignTypes.data == null ) {
        dispatch(getAllCampaignTypes());
    }else{
			getCampaignTypeList();
    }
},[mounted])
    useEffect(() => {
        const { fromName, fromEmail } = populateTouchCampaignFields();
        generateVerifiedEmailIds(fromEmail, fromName);

    }, []);

    useEffect(() => {
        if (isFocusOnCampaignName) {
            let touchName = document.getElementById('campaignName');
            if (touchName !== null && touchName !== undefined) {
                setTimeout(function () { touchName.focus(); }, 100);
                setIsFocusOnCampaignName(false);
            }
        }
    }, [isFocusOnCampaignName]);

    const getCampaignTypeList = () => {
        try {
            let campaign_types = getObjectParam(campaignTypes.data);
            let campTypelistArr = [];
            let objCamp = null;
            if (Object.keys(campaign_types).length > 0) {
                Object.keys(campaign_types).map((itemKey) => {
                    objCamp = {};
                    objCamp.id = parseInt(itemKey)
                    objCamp.name = campaign_types[itemKey];

                    campTypelistArr.push(objCamp);

                    objCamp = null;

                });
            }

            sortArrayObjectByProperty(campTypelistArr, "name");
            let campTypeMenuItems = getCampaignTypeMenuItems(campTypelistArr);
            setCampTypelistArr(campTypelistArr);
            setCampTypeMenuItems(campTypeMenuItems);
            setMounted(true);
        } catch (error) {
            console.log("Error in 'AutomationTouchCampaign.js -> getCampaignTypeList()': " + error);
        }
    }

    const setFilterPlaceholder = (filtertype) => {
        try {
            let placeHolder = '';
            if (filtertype === 0) {
                placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_GROUP;
            } else if (filtertype === 9) {
                placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER + getObjectLabelByObject(constants.CONTACTS_OBJECT);
            } else if (filtertype === 10) {
                placeHolder = getLocalizedStrings().label.CAMPAIGNS.SELECT_A_FILTER + getObjectLabelByObject(constants.ACCOUNTS_OBJECT);
            }
            setPlaceholder(placeHolder);
        } catch (error) {
            console.log("Error in setFilterPlaceholder() in automationTouchCampaign.js : " + error);
        }
    }

    const changeFilterType = (value) => {
        try {
            if (isValidParam(value)) {
                let filtertype = "";
                if (value === 'group') {
                    filtertype = 0;
                } else if (value === 'contact') {
                    filtertype = 9;
                } else if (value === 'account') {
                    filtertype = 10;
                }
                setFilterType(value);
                setFilterPlaceholder(filtertype);
                let filterType = null;
                let nodeObject = data;
                if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('details') && isValidParam(nodeObject['details'][0]) &&
                    nodeObject['details'][0].hasOwnProperty('filter_type')) {
                    filterType = nodeObject['details'][0]['filter_type'];
                    if (filterType === filtertype) {
                        let groupNames = "";
                        let campaign = "";
                        let groups = "";
                        let queryId = null;
                        let filters = [];
                        if (nodeObject['details'][0].hasOwnProperty('campaign')) {
                            campaign = nodeObject['details'][0]['campaign'];
                        }
                        if (nodeObject['details'][0].hasOwnProperty('groups')) {
                            groups = nodeObject['details'][0]['groups'];
                        }
                        if (nodeObject['details'][0].hasOwnProperty('query_id')) {
                            queryId = nodeObject['details'][0]['query_id'];
                        }
                        if (filtertype === 0) {
                            groupNames = campaign.split(",");
                            if (isInteger(groups)) {
                                groups = groups.toString();
                            }
                            groups = groups.split(",");
                            filters = groupNames.map((objGroupname, i) => {
                                return {
                                    value: groups[i],
                                    label: objGroupname
                                }
                            });
                            setStateFilters(filters);
                            let log = changeLog;
                            log.stateFilters = filters;
                            setchangeLog({ ...log });
                        } else {
                            let objFilter = {
                                value: queryId,
                                label: campaign
                            }
                            filters.push(objFilter);
                            filters = filters[0];
                            setStateFilters(filters);
                            let log = changeLog;
                            log.stateFilters = filters;
                            setchangeLog({ ...log });
                        }
                    } else {
                        setStateFilters([]);
                        let log = changeLog;
                        log.stateFilters = [];
                        setchangeLog({ ...log });
                    }
                } else {
                    setStateFilters([]);
                    let log = changeLog;
                    log.stateFilters = [];
                    setchangeLog({ ...log });
                }
                loadFilterOptions(filtertype);
            }
        } catch (error) {
            console.log("Error in changeFilterType() in automationTouchCampaign.js : " + error);
        }
    }

    const populateTouchCampaignFields = () => {
        let fromEmail = app.me.email;
        let fromName = '';
        try {
            if (isValidParam(data) && data.hasOwnProperty('details')) {

                let campaignName = "";
                let sendername = "";
                let senderEmail = app.me.email;
                let resendAll = "";
                let campaign = "";
                let groups = "";
                let filter_type = 0;
                let query_id = 0;
                let groupNames = "";
                let groupIds = 0;
                let filters = changeLog.stateFilters;
                let isMulti = stateIsMulti;
                let filterType = stateFilterType;
                let resendAfter = stateResendAfter;
                let sendOnWeekdays = stateSendOnWeekdays;
                let isCheckBusinessDays = stateIsCheckBusinessDays;
                let scheduleDate = stateScheduleDate;
                let hDate = stateHDate;
                let detailsArray = data.details[0];
                let status = "";
                let autoresponderId = stateAutoresponderId;
                let campaignType = stateCampaignType;
                let isDisableSenderEmail = stateIsDisableSenderEmail;
                let isDisableRefresh = stateIsDisableRefresh;

                if (isValidParam(detailsArray)) {
                    if (detailsArray.hasOwnProperty('name')) {
                        campaignName = detailsArray.name;
                    }
                    if (detailsArray.hasOwnProperty('from_name')) {
                        sendername = detailsArray.from_name;
                    }
                    if (detailsArray.hasOwnProperty('sender_email')) {
                        senderEmail = detailsArray.sender_email;
                    }
                    if (sendername === constants.CONSTANT_RECORD_OWNER && senderEmail === '') {
                        isDisableSenderEmail = true;
                        isDisableRefresh = true;
                    }
                    if (detailsArray.hasOwnProperty('resendAll')) {
                        resendAll = detailsArray.resendAll;
                    }
                    if (detailsArray.hasOwnProperty('campaign')) {
                        campaign = detailsArray.campaign;
                    }
                    if (detailsArray.hasOwnProperty('groups')) {
                        groups = detailsArray.groups;
                    }
                    if (detailsArray.hasOwnProperty('filter_type')) {
                        filter_type = detailsArray.filter_type;
                    }
                    if (detailsArray.hasOwnProperty('query_id')) {
                        query_id = detailsArray.query_id;
                    }
                    if (detailsArray.hasOwnProperty('resendAfter')) {
                        resendAfter = detailsArray.resendAfter;
                    }
                    if (detailsArray.hasOwnProperty('sendOnWeekdays')) {
                        sendOnWeekdays = detailsArray.sendOnWeekdays;
                        if (sendOnWeekdays === "Yes") {
                            isCheckBusinessDays = true;
                        } else if (sendOnWeekdays === "No") {
                            isCheckBusinessDays = false;
                        }
                    }
                    if (detailsArray.hasOwnProperty('startDate')) {
                        scheduleDate = detailsArray.startDate;
                        hDate = detailsArray.hDate;
                    }
                    if (detailsArray.hasOwnProperty('campaign_type')) {
                        campaignType = detailsArray.campaign_type === 0 ? '' : detailsArray.campaign_type;
                    }

                    if (filter_type === 0) {
                        groupNames = campaign.split(",");
                        if (isInteger(groups)) {
                            groups = groups.toString();
                        }
                        groups = groups.split(",");
                        filters = groupNames.map((objGroupname, i) => {
                            return {
                                value: parseInt(groups[i]),
                                label: objGroupname,
                                title: objGroupname
                            }
                        });
                        isMulti = true;
                        filterType = 'group';
                    } else {
                        let objFilter = {
                            value: parseInt(query_id),
                            label: campaign
                        }
                        if (filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                            objFilter.type = filter_type;
                        }
                        filters.push(objFilter);
                        isMulti = false;
                        if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                            filterType = 'contact';
                        } else if (filter_type === 10) {
                            filterType = 'account'
                        }
                        filters = filters[0];
                    }
                    if (detailsArray.hasOwnProperty('t_status')) {
                        status = detailsArray.t_status;
                    }

                    if (detailsArray.hasOwnProperty('autoresponder_id')) {
                        autoresponderId = detailsArray.autoresponder_id;
                    }
                    fromEmail = senderEmail;
                    fromName = sendername;
                    loadFilterOptions(filter_type, filters);
                    setCampaignName(campaignName);
                    setFromName(sendername);
                    // setSenderEmail(senderEmail);
                    setResendAllValue(resendAll);
                    setStateFilters(filters);
                    let log = changeLog;
                    log.stateFilters = filters;
                    setchangeLog({ ...log });
                    setIsMulti(isMulti);
                    setFilterType(filterType);
                    setFilter_Type(filter_type);
                    setResendAfter(resendAfter);
                    setIsCheckBusinessDays(isCheckBusinessDays);
                    setScheduleDate(scheduleDate);
                    setHDate(hDate);
                    setStatus(status);
                    setAutoresponderId(autoresponderId);
                    setCampaignType(campaignType);
                    setIsDisableSenderEmail(isDisableSenderEmail);
                    setIsDisableRefresh(isDisableRefresh);
                } else {
                    loadFilterOptions(0);
                    let userDetail = app.me;
                    setFromName(userDetail.name);
                    fromEmail = userDetail.email;
                    fromName = userDetail.name;
                    setStatus(constants.AUTOMATION_DESIGNER_STATUS_ACTIVE);
                }
            } else {
                loadFilterOptions(0);
                let userDetail = app.me;
                setFromName(userDetail.name);
                fromEmail = userDetail.email;
                fromName = userDetail.name;
                setStatus(constants.AUTOMATION_DESIGNER_STATUS_ACTIVE);
            }
            setFilterPlaceholder(filter_type);
        } catch (error) {
            console.log("Error in populateTouchCampaignFields() in automationTouchCampaign.js : " + error);
        }
        return { fromName, fromEmail };
    }

    const loadFilterOptions = (filter_type, filters) => {
        try {
            if (isValidParam(filter_type)) {
                setRestofFilters([]);
                setFilters_Loading(true);

                let endpoints, params, isMulti;
                if (filter_type === 0) {
                    isMulti = true;
                    endpoints = endPoints.GROUP.LIST;
                    params = {
                        search_field_name: "t_status",
                        search_text: "Active",
                        fields: ['name', 'id', 't_status']
                    };
                } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                    isMulti = false;
                    endpoints = endPoints.CONTACTS.CAMPAIGN_FILTER;
                    params = null;
                } else if (filter_type === 10) {
                    isMulti = false;
                    endpoints = endPoints.ACCOUNTS.CAMPAIGN_FILTER;
                    params = null;
                }

                let response = Promise.resolve(HTTPClient.get(endpoints, params));
                response.then((response) => {
                    let arrayRecords = null;
                    if (filter_type === 0) {
                        arrayRecords = response.records;
                        if (isValidParam(arrayRecords)) {
                            arrayRecords = arrayRecords.filter((el) => {
                                if (el.hasOwnProperty('t_status') && isValidParam(el.t_status)) {
                                    return el.t_status !== constants.INACTIVE;
                                }
                            })
                        }
                    } else {
                        arrayRecords = response.queries;
                    }
                    let filtersList = [];
                    let _filters = !!filters ? filters : [];
                    if (arrayRecords !== null) {
                        filtersList = arrayRecords.map((objRecord, i) => {
                            if (objRecord.hasOwnProperty('type')) {
                                return {
                                    value: objRecord.id,
                                    label: objRecord.name,
                                    title: objRecord.name,
                                    type: objRecord.type,
                                    isDisabled: false
                                }
                            } else {
                                return {
                                    value: objRecord.id,
                                    label: objRecord.name,
                                    title: objRecord.name,
                                    isDisabled: false
                                }
                            }
                        });
                    }
                    let tempFilters = [];
                    if (filter_type === 0 && isValidParam(_filters)) {
                        _filters = _filters.map((objFilter, i) => {
                            filtersList.map((objFilterList, i) => {
                                if (objFilterList.value === objFilter.value) {
                                    tempFilters.push(objFilter);
                                }
                            });
                        });
                    } else {
                        tempFilters = _filters;
                    }
                    setStateFilters(tempFilters);
                    let log = changeLog;
                    log.stateFilters = tempFilters;
                    setchangeLog({ ...log });
                    setRestofFilters(filtersList);
                    setIsMulti(isMulti);
                    setFilters_Loading(false);
                    setFilter_Type(filter_type);
                    selectFilter(tempFilters);
                });
            }
        } catch (error) {
            console.log("Error in loadFilterOptions() in automationTouchCampaign.js : " + error);
        }
    }

    const changeSenderName = () => {
        let sendername = document.getElementById('sendername').value;
        setFromName(sendername);
        if (sendername === constants.CONSTANT_RECORD_OWNER ||
            sendername.trim().replace(/ /g, '').toLowerCase() === constants.CONSTANT_RECORD_OWNER.replace(/ /g, '').toLowerCase()) {
            setIsDisableSenderEmail(true);
            setSenderEmail('');
            setIsDisableRefresh(true);
            setFromName(constants.CONSTANT_RECORD_OWNER);
        } else {
            setIsDisableSenderEmail(false);
            setIsDisableRefresh(false);
            if (stateSenderEmail === '') {
                if (isValidParam(data) && data.hasOwnProperty('details')) {
                    let details = data['details'][0];
                    if (details.hasOwnProperty('sender_email')) {
                        let senderEmail = details.sender_email;
                        setSenderEmail(senderEmail);
                    }
                } else {
                    setSenderEmail(app.me.email);
                }
            }
        }
    }

    const generateVerifiedEmailIds = (fromEmail, fromName, verifiedEmailIdList) => {

        let verifid_email_options = [];
        let emailIds = [];
        let verifyEmailAddress = "Verify another email address";
        verifyEmailAddress = getLocalizedStrings().label.AUTOMATION_DESIGNER.VERIFY_EMAIL_ADDRESS_OPTION;
        let tempEmail = fromEmail || stateSenderEmail;
        try {
            if (verifiedEmailIdList && verifiedEmailIdList.length > 0) {
                verifiedEmailIdList = getArrayParam(verifiedEmailIdList);
                verifiedEmailIdList.forEach((value) => {
                    if (!isEmailExistsInList(verifid_email_options, value)) {
                        verifid_email_options.push(
                            {
                                value: value,
                                label: value
                            }
                        );
                    }
                });
                if (!isEmailExistsInList(verifid_email_options, app.me.email)) {
                    verifid_email_options.unshift(
                        {
                            value: app.me.email,
                            label: app.me.email
                        }
                    );
                }
                verifid_email_options.push(
                    {
                        value: '-1',
                        label: '---' + verifyEmailAddress + '---'
                    }
                );
                emailIds = verifid_email_options.map((emailId, i) => {
                    return (
                        <MenuItem value={emailId.value} title={emailId.label} >{emailId.label}</MenuItem>
                    );
                });
                setSenderEmail(tempEmail);
                setVerifiedEmailIds(emailIds);
                setVerifiedEmailOptions(verifid_email_options);
            } else {
                let response = Promise.resolve(HTTPClient.get(endPoints.AUTOMATION_DESIGNER.GET_VERIFIED_EMAIL_IDS, null));
                response.then((response) => {
                    if (isValidParam(response)) {
                        emailIds = response.verifid_email_ids;
                        /** verify email added by Priyanka */
                        if (emailIds !== null && Array.isArray(emailIds)) {
                            emailIds.forEach((value) => {
                                if (!isEmailExistsInList(verifid_email_options, value)) {
                                    verifid_email_options.push(
                                        {
                                            value: value,
                                            label: value
                                        }
                                    );
                                }
                            });
                            if (!isEmailExistsInList(verifid_email_options, app.me.email)) {
                                verifid_email_options.unshift(
                                    {
                                        value: app.me.email,
                                        label: app.me.email
                                    }
                                );
                            }
                            verifid_email_options.push(
                                {
                                    value: '-1',
                                    label: '---' + verifyEmailAddress + '---'
                                }
                            );
                            emailIds = verifid_email_options.map((emailId, i) => {
                                return (
                                    <MenuItem value={emailId.value} title={emailId.label} >{emailId.label}</MenuItem>
                                );
                            });
                        }
                        if (fromName !== constants.CONSTANT_RECORD_OWNER) {
                            if (!isEmailExistsInList(verifid_email_options, tempEmail)) {
                                tempEmail = verifid_email_options[0].value;
                            }
                        } else {
                            tempEmail = '';
                        }
                        setVerifiedEmailIds(emailIds);
                        setVerifiedEmailOptions(verifid_email_options);
                    }
                    setSenderEmail(tempEmail);
                    setMounted(true)
                });
            }
        }
        catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> generateVerifiedEmailIds():'" + error);
        }
        setSenderEmail(tempEmail);
    }

    const changeSenderEmail = (value) => {
        try {
            if (isValidParam(value)) {
                if (value === '-1') {
                    openVerifyEmailPopup();
                }
                setSenderEmail(value);
            }
        }
        catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> changeSenderEmail():'" + error);
        }
    }

    const openVerifyEmailPopup = () => {
        let verifiedEmailOptions = getArrayParam(stateVerifiedEmailOptions);
        try {
            let data = { fromName: app.me.name, verifiedEmailOptions: verifiedEmailOptions };
            dispatch(getAppDialog(true, constants.VERIFY_EMAIL_DIALOG, getLocalizedStrings().label.BROADCAST_CAMPAIGNS.VERIFY_EMAIL, null, data, constants.AUTOMATION_DESIGNER_OBJECT));
        }
        catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> openVerifyEmailPopup():'" + error);
        }
    }

    const loadVerifiedEmailIds = () => {
        let verifid_email_ids = [];
        try {
            let url = endPoints.BROADCAST_CAMPAIGNS.GET_VERIFIED_EMAIL_IDS;
            let params = {};
            params.getUserMailIds = false;
            let promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    generateVerifiedEmailIds(stateSenderEmail, stateFromName, response.verified_email_ids);
                }
            });
        }
        catch (error) {
            console.error("Error in 'BroadcastCampaign.js -> loadVerifiedEmailIds():'" + error);
        }
    }

    const selectFilter = (event, options) => {
        if (isValidParam(options)) {
            if (options.hasOwnProperty('type')) {
                setFilter_Type(options.type);
            } else {
                if (stateFilterType === 'contact') {
                    setFilter_Type(9);
                }
            }
            setStateFilters(options);
            let log = changeLog;
            log.stateFilters = options;
            setchangeLog({ ...log });
        }
    }

    const toggleBusinessDays = () => {
        setIsCheckBusinessDays(!stateIsCheckBusinessDays)
    }

    const openDialog = (dialogData) => {
        dispatch(getAppDialog(true, constants.ALERT_DIALOG, 'Alert Dialog', null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT));
    }

    const saveTouchCampagnData = (callFrom) => {
        let dialogData = null;
        let campName = stateCampaignName;
        campName = campName.trim();

        let filters = changeLog.stateFilters;
        let selectedFilter = null;
        if (filter_type === 0) {
            selectedFilter = getLocalizedStrings().label.CAMPAIGNS.GROUP;//"Group";
        } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
            selectedFilter = getLocalizedStrings().label.CAMPAIGNS.CONTACT_FILTER;//"Contact Filter";
        } else if (filter_type === 10) {
            selectedFilter = getLocalizedStrings().label.CAMPAIGNS.ACCOUNT_FILTER;//"Account Filter";
        }
        let fromName = stateFromName;
        let senderEmail = stateSenderEmail;
        let scheduleDate = stateScheduleDate;
        let hDate = stateHDate;
        let resendAllValue = stateResendAllValue;
        let resendAfterValue = stateResendAfter;
        let tempCampaignType = 0;

        if (isValidParam(stateCampaignType) && stateCampaignType !== '') {
            tempCampaignType = stateCampaignType;
        }

        let arrNodes = null;
        let nodeDetailStrObject = data;
        if (nodeDetailStrObject !== null && nodeDetailStrObject.hasOwnProperty('actionName')) {
            let actionName = nodeDetailStrObject.actionName;
            let arrNodesArr = getNodesByActionName(actionName);
            arrNodes = arrNodesArr.filter(function (el) {
                return el.childId !== nodeDetailStrObject.childId && el.nodeStatusFlg !== 3;
            });
        }


        let filterAlertMsg = null;
        if (filter_type === 0) {
            filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
        } else if (filter_type === 9) {
            filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
        } else if (filter_type === 10) {
            filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS
        }
        if (isInvalidParam(filters) || filters.length === 0) {
            dialogData = {
                message: filterAlertMsg + " " +
                    selectedFilter,
            }
            openDialog(dialogData);
        } else if (isInvalidParam(fromName)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_NAME + " " + campName,
                fieldId: 'sendername'
            }
            openDialog(dialogData);
        } else if ((isInvalidParam(senderEmail) || senderEmail === '-1') && fromName !== constants.CONSTANT_RECORD_OWNER) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_EMAIL,
            }
            openDialog(dialogData);
        } else if (isValidParam(resendAllValue) && resendAllValue === 'Yes' &&
            (isInvalidParam(resendAfterValue) || resendAfterValue === 0 || resendAfterValue === '0')) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.RESEND_AFTER,
                fieldId: 'resend_after'
            }
            openDialog(dialogData);
        } else {
            let nodeObject = data;
            let flag = true;
            if (isValidParam(nodeObject)) {
                if (campName !== null && campName !== '' && arrNodes.length > 0) {
                    for (let i = 0; i < arrNodes.length; i++) {
                        if (arrNodes[i].hasOwnProperty('details')) {
                            let nodeDetails = arrNodes[i].details;
                            if (nodeDetails.length > 0) {
                                let existingName = nodeDetails[0].name;
                                if (campName === existingName) {
                                    flag = false;
                                    dialogData = {
                                        message: getLocalizedStrings().message.AUTOMATION_DESIGNER.DUPLICATE_TOUCH_NAME + " " + campName,
                                        fieldId: 'campaignName'
                                    }
                                    openDialog(dialogData);
                                    return false;
                                }
                            }
                        }
                    }
                }
                if (flag) {
                    let detailsArray = null;
                    if (nodeObject.hasOwnProperty('details')) {
                        detailsArray = nodeObject.details[0];
                    }
                    let campaign = "";
                    let groups = "";
                    let query_id = 0;
                    if (filter_type === 0) {
                        filters.map((objFilter, i) => {
                            if (campaign === "") {
                                campaign = objFilter.label;
                                groups = objFilter.value;
                            } else {
                                campaign = campaign + "," + objFilter.label;
                                groups = groups + "," + objFilter.value;
                            }
                        });
                    } else {
                        campaign = filters.label;
                        query_id = filters.value;
                    }

                    let sendOnWeekdays = 'No';
                    if (stateIsCheckBusinessDays) {
                        sendOnWeekdays = 'Yes';
                    }

                    if (resendAllValue === 'No') {
                        resendAfterValue = 0;
                    }

                    if (isValidParam(detailsArray)) {
                        detailsArray.name = campName;
                        detailsArray.campaign = campaign;
                        detailsArray.groups = groups;
                        detailsArray.filter_type = filter_type;
                        detailsArray.query_id = query_id;
                        detailsArray.from_name = fromName;
                        detailsArray.sender_email = senderEmail;
                        detailsArray.resendAll = resendAllValue;
                        detailsArray.resendAfter = resendAfterValue;
                        detailsArray.sendOnWeekdays = sendOnWeekdays;
                        detailsArray.hDate = hDate;
                        if (detailsArray.t_status === null || detailsArray.t_status === undefined ||
                            detailsArray.t_status === "" || detailsArray.autoresponder_id <= 0) {
                            detailsArray.t_status = 'New';
                        }
                        detailsArray.campaign_type = tempCampaignType;
                    } else {
                        nodeObject.details = [];
                        detailsArray = {
                            name: campName,
                            campaign: campaign,
                            groups: groups,
                            filter_type: filter_type,
                            query_id: query_id,
                            from_name: fromName,
                            sender_email: senderEmail,
                            resendAll: resendAllValue,
                            resendAfter: resendAfterValue,
                            sendOnWeekdays: sendOnWeekdays,
                            t_show: 0,
                            t_status: 'New',
                            autoresponder_id: 0,
                            startDate: scheduleDate,
                            hDate: hDate,
                            campaign_type: tempCampaignType
                        }
                        nodeObject.detailStatusFlg = 2;
                        nodeObject.details.push(detailsArray);
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    const saveBirthdayCampaignData = () => {
        let dialogData = null;
        let campName = stateCampaignName;
        campName = campName.trim();

        let fromName = stateFromName;
        let senderEmail = stateSenderEmail;
        let scheduleDate = stateScheduleDate;
        let hDate = stateHDate;
        let autoresponderId = stateAutoresponderId;
        let tempCampaignType = 0;

        if (isValidParam(stateCampaignType) && stateCampaignType !== '') {
            tempCampaignType = stateCampaignType;
        }

        let arrNodes = null;
        let nodeDetailStrObject = data;
        if (nodeDetailStrObject !== null && nodeDetailStrObject.hasOwnProperty('actionName')) {
            let actionName = nodeDetailStrObject.actionName;
            let arrNodesArr = getNodesByActionName(actionName);
            arrNodes = arrNodesArr.filter(function (el) {
                return el.childId !== nodeDetailStrObject.childId && el.nodeStatusFlg !== 3;
            });
        }


        if (isInvalidParam(fromName)) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_NAME + " " + campName,
                fieldId: 'sendername'
            }
            openDialog(dialogData);
        } else if ((isInvalidParam(senderEmail) || senderEmail === '-1') && fromName !== constants.CONSTANT_RECORD_OWNER) {
            dialogData = {
                message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_EMAIL,
            }
            openDialog(dialogData);
        } else {
            let nodeObject = data;
            let flag = true;
            if (isValidParam(nodeObject)) {

                if (flag) {
                    let params = {
                        name: campName,
                        tPK: autoresponderId,
                    }
                    let detailsArray = null;
                    if (nodeObject.hasOwnProperty('details')) {
                        detailsArray = nodeObject.details[0];
                    }

                    if (isValidParam(detailsArray)) {
                        detailsArray.name = campName;
                        detailsArray.from_name = fromName;
                        detailsArray.sender_email = senderEmail;
                        detailsArray.startDate = scheduleDate;
                        detailsArray.hDate = hDate;
                        if (detailsArray.t_status === null || detailsArray.t_status === undefined ||
                            detailsArray.t_status === "" || detailsArray.autoresponder_id <= 0) {
                            detailsArray.t_status = 'New';
                        }
                        detailsArray.campaign_type = tempCampaignType;
                    } else {
                        nodeObject.details = [];
                        detailsArray = {
                            name: campName,
                            from_name: fromName,
                            sender_email: senderEmail,
                            t_status: 'New',
                            autoresponder_id: 0,
                            startDate: scheduleDate,
                            hDate: hDate,
                            campaign_type: tempCampaignType
                        }
                        nodeObject.detailStatusFlg = 2;
                        nodeObject.details.push(detailsArray);
                    }
                    nodeObject = removeError(nodeObject);
                    dispatch(addNode(automationCampaign.data));
                    closeDrawer();
                }
            }
        }
    }

    function addCampaignType(obj) {
        try {
            let camObj = {
                id: obj.id,
                name: obj.name
            }

            let camTyps = getArrayParam(campTypelistArr);
            camTyps.push(camObj);
            addCampaignTypeIntoReduxStore(camObj);
            sortArrayObjectByProperty(camTyps, 'name');
            let campTypeMenuItems = getCampaignTypeMenuItems(camTyps);
            setCampTypelistArr(camTyps);
            setCampTypeMenuItems(campTypeMenuItems);
        } catch (error) {
            console.log("Error in 'automationTouchCampaign.js -> addCampaignType()': " + error);
        }
    }

    const changeCampaignType = (event) => {
        let value = event.target.value;
        try {
            if (isValidParam(value)) {
                if (value !== 'Manage') {
                    setCampaignType(value);
                } else {
                    let data = {};
                    data.object = constants.AUTOMATION_DESIGNER_OBJECT;
                    dispatch(getAppDialog(true, constants.ADD_CAMPAIGN_TYPE_DIALOG, getLocalizedStrings().label.CAMPAIGNS.ADD_CAMPAIGN_TYPE, addCampaignType, null, null));
                }
            }
        } catch (error) {
            console.log("Error in 'automationTouchCampaign.js -> changeCampaignType()': " + error);
        }
    }

    const getCampaignTypeMenuItems = (campTypelistArr) => {
        try {
            let campaignTypes = getArrayParam(campTypelistArr);
            let campTypeItems = null;
            if (campaignTypes !== null && campaignTypes.length > 0) {
                campTypeItems = campaignTypes.map((camTy, index) => {
                    return <MenuItem key={camTy.id} value={camTy.id} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '142px' }} title={camTy.name}>{camTy.name}</div>} </MenuItem>
                });
            }
            return campTypeItems;
        } catch (error) {
            console.log("Error in 'AutomationTouchCampaign.js -> getCampaignTypeMenuItems()': " + error);
        }
    }


    const handleQualifiedContacts = () => {
        try {
            if (validateFilter()) {
                const label = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS;
                const url = `/${constants.CONTACTS_OBJECT}/queries`;
                openTab(label, url);
            }
        } catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> handleQualifiedContacts()':" + error);
        }
    }

    const openTab = (label, url) => {
        try {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabIndex = getIntParam(activeTab.index);
            let filter = { id: 542, name: 'All records', type: constants.SEARCH_QUERY_TYPE_ALL }
            let tab = { label: getStringParam(label), object: constants.CONTACTS_OBJECT, type: TYPE_LIST_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
            let info = {
                isReport: true,
                reportLabel: label,
                filter: filter,
                input_param: {
                    call_from: constants.CAMPAIGN_QUALIFIED_CONTACTS,
                    filter_options: changeLog.stateFilters,
                    filter_type: getStringParam(stateFilterType)
                }
            };
            tab.info = info;
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'BroadcastCampaign.js -> openTab()':" + error);
        }
    }

    const validateFilter = () => {
        let isValid = true;
        let dialogData = null;
        let selectedFilter = null;
        let filterAlertMsg = null;
        try {
            let campName = campName;
            let filters = changeLog.stateFilters;
            if (filter_type === 0) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.GROUP;//"Group";
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A
            } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.CONTACT_FILTER;//"Contact Filter";
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS_A;
            } else if (filter_type === 10) {
                selectedFilter = getLocalizedStrings().label.CAMPAIGNS.ACCOUNT_FILTER;//"Account Filter";
                filterAlertMsg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FILTERS;
            }
            if (isInvalidParam(filters) || filters.length === 0) {
                isValid = false;
                dialogData = {
                    message: filterAlertMsg + " " + selectedFilter,
                }
                openDialog(dialogData);
            } else if (isInvalidParam(stateFromName)) {
                isValid = false;
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_NAME + " " + campName,
                    fieldId: 'sendername'
                }
                openDialog(dialogData);
            } else if ((isInvalidParam(stateSenderEmail) || stateSenderEmail === '-1') && stateFromName !== constants.CONSTANT_RECORD_OWNER) {
                isValid = false;
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.FROM_EMAIL,
                }
                openDialog(dialogData);
            }
        } catch (error) {
            console.error("Error in 'automationTouchCampaign.js -> validateFilter()':" + error);
        }
        return isValid;
    }


    let templateName = "";
    let actionName = isValidParam(data) ? getStringParam(data.actionName) : "";
    if (actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
        templateName = "Birthday Campaign";
    }
    let campaignStatus = automationCampaign.data.status;

    let selectedFilter = "";
    if (filter_type === 0) {
        selectedFilter = "group";
    } else if (filter_type === 9 || filter_type === constants.CAMPAIGN_QUERY_TYPE) {
        selectedFilter = "contact";
    } else if (filter_type === 10) {
        selectedFilter = "account";
    }
    selectedFilter = selectedFilter.toString();

    let selectedFilters = changeLog.stateFilters;
    let restofFilters = [];
    let filters = [];
    if (stateFilterType === 'group') {
        filters = stateRestofFilters.filter(function (el) { return selectedFilters.indexOf(el) < 0; });
        if(selectedFilters.length > 0 && stateFilterType === 'group' ) {
            filters = filters.filter((elem) => !selectedFilters.find(({ value }) => elem.value === value))
        }
        if (changeLog.stateFilters.length >= 3) {
            filters.map((filter, index) => {
                filter.isDisabled = true;
                restofFilters.push(filter);
            })
        } else {
            filters.map((filter, index) => {
                filter.isDisabled = false;
                restofFilters.push(filter);
            })
        }
    } else {
        filters = stateRestofFilters;
        filters.map((filter, index) => {
            filter.isDisabled = false;
            restofFilters.push(filter);
        })
    }
    sortArrayObjectByProperty(stateRestofFilters, 'label');
    console.log("stateCampaignType", stateCampaignType)
    let contentHeight = window.innerHeight - 200;
    let top = (contentHeight - 10) / 2;
    if (mounted) {

    if (templateName !== 'Birthday Campaign') {
        return (
            <div id="tcMainDiv" style={{ paddingTop: '40px', paddingLeft: '20px', paddingRight: '20px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '44%', minWidth: '44%' }}>
                        <div style={{ ...styles.customInputHolder, borderRadius: '4px', height: '44' }}>
                            <label style={{ background: 'inherit', marginTop: '-1px', fontWeight: 'normal', fontSize: 12, lineHeight: 0.1, padding: '0px 5px 0px 5px', background: "#fafafa" }}>
                                {getLocalizedStrings().label.AB_CAMPAIGNS.TARGET_BY}
                            </label>
                            <RadioGroup className="sf-data-fieldset" name="layout" style={{ width: '100%', marginTop: '-2px' }} onChange={(e) => changeFilterType(e.target.value)} value={stateFilterType} row>
                                <FormControlLabel value={"group"} control={<Radio color="default" />} label={getLocalizedStrings().label.CAMPAIGNS.GROUP} />
                                <FormControlLabel value={"contact"} control={<Radio color="default" />} label={getObjectLabelByObject(constants.CONTACTS_OBJECT)} />
                                <FormControlLabel value={"account"} control={<Radio color="default" />} label={getObjectLabelByObject(constants.ACCOUNTS_OBJECT)} />
                            </RadioGroup>
                        </div>
                    </div>
                    <div style={{ ...styles.sf_7, paddingLeft: '0px', maxWidth: '55.5%' }} >
                        <div style={{ display: 'flex', width: '94%', marginLeft: '17px', paddingBottom: '10px' }}>
                            <div style={{ width: '90%' }}>
                                {stateFilterType === 'group' &&
                                    <Autocomplete
                                        multiple
                                        id={"autocomplete_group"}
                                        name={"autocomplete_group"}
                                        key={"autocomplete_group"}
                                        className={'sf-atc-autoComplete'}
                                        value={changeLog.stateFilters}
                                        getOptionDisabled={(option) => option.isDisabled === true || changeLog.stateFilters.length >= 3}
                                        options={filters}
                                        onChange={(event, option) => selectFilter(event, option)}
                                        getOptionLabel={(option) => !!option && option.label}
                                        disableClearable={false}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    style={{ ...styles.autocompleteChip, width: '26.5%', height: '24px' }}
                                                    label={option.label}
                                                    title={option.label}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                placeholder={changeLog.stateFilters && changeLog.stateFilters.length === 0 ? placeholder : ''}
                                                className={'sf-atc-autoComplete-text-field'}
                                                variant="outlined" margin="dense" size='small'
                                            />
                                        )}
                                    />

                                }
                                {stateFilterType !== 'group' &&
                                    <Autocomplete
                                        id={"autocomplete_group"}
                                        name={"autocomplete_group"}
                                        key={"autocomplete_group"}
                                        className={'sf-atc-autoComplete'}
                                        value={changeLog.stateFilters}
                                        getOptionDisabled={(option) => option.isDisabled === true}
                                        options={restofFilters}
                                        onChange={(e, options) => selectFilter(e, options)}
                                        disableClearable={false}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                placeholder={placeholder}
                                                className={'sf-atc-autoComplete-text-field'}
                                                variant="outlined" margin="dense" size='small'
                                            />
                                        )}
                                    />
                                }
                            </div>
                            <div style={{ width: '10%' }}>
                                <i className="fa fa-eye" name={'qualifiedcontacts'} onClick={() => handleQualifiedContacts()}
                                    title={getLocalizedStrings().label.BROADCAST_CAMPAIGNS.QUALIFIED_CONTACTS} style={{ fontSize: '28px', color: '#a8a0a0', cursor: 'pointer', padding: '6px 0px 4px 10px' }}>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ ...styles.sf_5, float: 'left' }}>
                        <FormControl style={{ width: '346px' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                id="sendername"
                                value={stateFromName}
                                underlineFocusStyle={{ borderColor: '#B5B5B5' }}
                                hintStyle={{ color: '#B5B5B5' }}
                                onChange={() => changeSenderName()}
                                label={getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED}
                                inputprops={{ maxlength: '64' }}
                                autoFocus
                                autoComplete="new-password"
                                margin='dense'
                                className={'sf-atc-autoComplete-text-field'}
                            />
                        </FormControl>
                    </div>
                    <div style={{ ...styles.sf_7, float: 'left' }}>
                        <div style={{ width: '87%', marginLeft: '52px' }}>
                            <FormControl variant="outlined" style={{ width: '90%', marginTop: '7px' }}>
                                <InputLabel id="sf-automation-touch-campaign-from-result-automationtouchcampaign-simple-select-outlined-label" className='sf-automation-touch-campaign-from-result-automationtouchcampaigns-simple' style={{ marginTop: stateFromName == constants.CONSTANT_RECORD_OWNER ? '-5px' : '' }}>{getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}</InputLabel>
                                <SelectField
                                    id="senderemail"
                                    value={stateSenderEmail}
                                    onChange={(e) => changeSenderEmail(e.target.value)}
                                    label={getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}
                                    style={{ height: '44px' }}
                                    disabled={stateIsDisableSenderEmail}
                                    className={"sf-fields-bg"} >
                                    {
                                        (verifiedEmailIds !== null) &&
                                        verifiedEmailIds
                                    }
                                </SelectField>
                            </FormControl>
                            <div style={{ width: '10%', float: 'right' }}>
                                <Button
                                    endIcon={<Icon>autorenew</Icon>}
                                    onClick={() => loadVerifiedEmailIds()}
                                    disabled={stateIsDisableRefresh}
                                    style={{
                                        ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                        color: '#717171',
                                        border: '0px',
                                        minWidth: '0px',
                                        padding: '6px 2px',
                                        marginTop: '13px'
                                    }}
                                    title={getLocalizedStrings().label.REPORT.REFRESH}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ ...styles.sf_6 }}>
                        {
                            (status === constants.AUTOMATION_DESIGNER_STATUS_ACTIVE ||
                                status === "" || status === "New") &&
                            <div className='touch-date'>
                            </div>
                        }
                    </div>
                </div>

                <div >
                    <div style={{ ...styles.sf_5 }}>
                        <FormControl variant="outlined" style={{ width: '111%', marginTop: '13px' }}>
                            <InputLabel
                                shrink={stateCampaignType != ''} id="cType" className="type" style={stateCampaignType != '' ? { background: '#f5f5f5', padding: '0 4px', overflow: 'visible', height: '20px' } : {}}>
                                {getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL}</InputLabel>
                            <SelectField
                                id="campaignType"
                                value={stateCampaignType}
                                onChange={(event) => changeCampaignType(event)}
                                style={{ height: '44px', marginTop: '3px' }}
                                className={"sf-fields-bg"}
                                label={getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL}
                            >
                                <MenuItem value='' title='' style={styles.popoverMenuItem} > </MenuItem>
                                {campTypeMenuItems}
                                <MenuItem value='Manage' title='Manage' style={styles.popoverMenuItem} >---Manage---</MenuItem>
                            </SelectField>
                        </FormControl>
                    </div>
                    {templateName !== 'Birthday Campaign' &&
                        <div style={{ ...styles.sf_7, marginTop: '8px' }}>
                            <FormControlLabel
                                control={<Checkbox color="default" checked={stateIsCheckBusinessDays} value={stateIsCheckBusinessDays} onChange={toggleBusinessDays} />}
                                label={getLocalizedStrings().label.CAMPAIGNS.SEND_ON_WEEK_DAY}
                                labelposition="right"
                                title={getLocalizedStrings().label.AUTOMATION_DESIGNER.CAMPAIGN_SENDER} labelstyle={{ marginLeft: '-8px', fontWeight: 'bold', color: '#717171' }}
                            />
                        </div>
                    }
                </div>
                <div style={{}}>
                    {
                        (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                        <div style={{ ...styles.sf_5 }}>
                            <Button
                                style={styles.listViewPrimaryButton}
                                onClick={() => saveTouchCampagnData()}
                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    }
                </div>
            </div>

        );
    } else {
        return (<div id="tcMainDiv" style={{ paddingTop: '12px', paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="row">
                <div className="col-sm-12">
                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            id="sendername"
                            value={stateFromName}
                            onChange={() => changeSenderName()}
                            label={getLocalizedStrings().label.CAMPAIGNS.FROM_NAME_REQUIRED}
                            inputprops={{ maxlength: '64' }}
                            autoFocus
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12" style={{ display: 'flex' }}>
                    <FormControl variant="outlined" style={{ width: '89%', marginTop: '15px' }}>
                        <InputLabel margin={'dense'} id="sf-automationtouch-campaign-results-fromautomation-simple-select-outlined-label" className="sf-push-units-from-sfforms" style={{ marginTop: '-7px' }}>{getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}</InputLabel>
                        <SelectField
                            id="senderemail"
                            value={stateSenderEmail}
                            onChange={(e) => changeSenderEmail(e.target.value)}
                            style={{ height: '42px' }}
                            label={getLocalizedStrings().label.CAMPAIGNS.FROM_EMAIL_REQUIRED}
                            disabled={stateIsDisableSenderEmail}
                            className={"sf-fields-bg"} >
                            {
                                (verifiedEmailIds !== null) && verifiedEmailIds
                            }
                        </SelectField>
                    </FormControl>
                    <div style={{ float: 'right' }}>
                        <Button
                            endIcon={<Icon>autorenew</Icon>}
                            onClick={() => loadVerifiedEmailIds()}
                            disabled={stateIsDisableRefresh}
                            style={{
                                ...styles.listViewPrimaryButton, backgroundColor: 'inherit',
                                color: '#717171',
                                border: '0px',
                                minWidth: '0px',
                                paddingTop: '6px',
                                marginTop: '19px'
                            }}
                            title={getLocalizedStrings().label.REPORT.REFRESH}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <FormControl variant="outlined" size="small" style={{ width: '100%', marginTop: '15px' }}>
                        <InputLabel shrink={stateCampaignType != ''} id="cType" className="type" style={stateCampaignType != '' ? { background: '#f5f5f5', padding: '0 4px', overflow: 'visible', height: '17px' } : {}}>{getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL}</InputLabel>
                        <SelectField
                            id="cType"
                            value={stateCampaignType}
                            onChange={(event) => changeCampaignType(event)}
                            style={{ height: '42px' }}
                            label={getLocalizedStrings().label.CAMPAIGNS.CAMPAIGN_TYPE_OPTIONAL}
                            className={"sf-fields-bg"}
                        >
                            <MenuItem value='' title='' style={styles.popoverMenuItem} > </MenuItem>
                            {campTypeMenuItems}
                            <MenuItem value='Manage' title='Manage' style={styles.popoverMenuItem} >---Manage---</MenuItem>
                        </SelectField>
                    </FormControl>
                </div>
            </div>
            <div className="row" style={{ paddingTop: '12px' }}>
                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div className="col-sm-12">
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={() => saveBirthdayCampaignData()}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        </div>);
    }
}else {
    return (<div style={{ width: '100%', height: contentHeight }} >
        {<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }} >
                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                </div>
            </div>
        }
    </div>);
}
};

export default AutomationTouchCampaign;
