
import React, { useState, useEffect } from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Select as SelectField, MenuItem, InputLabel } from '@mui/material';
import * as sfDialogs from '../components/sfDialogs';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getArrayParam, getObjectParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getEventbriteUserInfo, disconnectEventbrite, getUserOrganisations, updateEventbriteDetails } from '../../../services/actions/eventbriteAction';
import Select from 'react-select';
import { openWindow } from '../../../services/helper/utils';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { makeTiteleCase, sortArrayOfString } from '../../../services/helper/utils';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { useDispatch, useSelector } from "react-redux";


const EventbriteIntegration = () => {

    const app = useSelector((state) => state.app);

    const [isMounted, setIsMounted] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [emailAddress, setEmailAddress] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [id, setId] = useState(0);
    const [details, setDetails] = useState({
        availableOrganisations: [], selectedOrganisations: [], eventbriteFields: [],
        eventbriteFields: '', isAddNewToggle: false, mappingFields: {}
    });
    const [selectedOrganisationIds, setSelectedOrganisationIds] = useState('');
    const [attempts, setAttempts] = useState(10);
    const [userId, setUserId] = useState(0);
    const [isDisconnect, setIsDisconnect] = useState(false);


    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        if (userId > 0 && accessToken != null && accessToken != "" && accessToken != undefined && isConnected) {
            getOrganisations();
        }
    }, [userId, accessToken, isConnected]);


    useEffect(() => {
        if (details.selectedOrganisations != null && details.selectedOrganisations != "" &&
            details.availableOrganisations != null && details.availableOrganisations != "" && !isMounted) {
            setIsMounted(true);
        }
    }, [details]);

    const connect = () => {
        try {
            setConnecting(true);
            setAttempts(10);
            let token = localStorage.getItem('token');
            let url = constants.EVENTBRITE_AUTH_API + 'client_id=' + app.eventbrite_apikey +
                '&response_type=code&state=' + token;
            let openEBWindow = openWindow({ location: url, spaces: 'scrollbars=1, location=no', width: 850, height: 650 });

            openEBWindow.location = url;

            setTimeout(() => {
                fnGetEventbriteUserInfo(openEBWindow);
            }, 20000);


        } catch (error) {
            console.error("Error in 'EventbriteIntegration.js -> connect()':" + error);
        }
    }

    const fnGetEventbriteUserInfo = (openEBWindow) => {

        let refreshIntervalId = window.setInterval(() => {
            if (attempts > 0) {
                let promise = Promise.resolve(getEventbriteUserInfo());

                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            setAttempts(attempts - 1);
                            if (response.hasOwnProperty("access_token") && isValidParam(response.access_token)) {
                                setAccessToken(response.access_token);
                                clearInterval(refreshIntervalId);

                                if (response.hasOwnProperty("email")) {
                                    setIsConnected(true);
                                    setEmailAddress(response.email);
                                    setUserId(response.id);
                                    setIsConnecting(false);
                                    setAttempts(0);
                                    setSelectedOrganisationIds(response.organisationsId);
                                    clearInterval(refreshIntervalId);
                                    //getOrganisations();
                                } else {
                                    openEBWindow.close();
                                    clearInterval(refreshIntervalId);

                                    setIsMounted(true);
                                    setAttempts(0);
                                    setIsConnecting(false);
                                }
                            }
                        }
                    });
                }
            } else if (attempts === 0) {
                openEBWindow.close();
                clearInterval(refreshIntervalId);
                if (isConnecting) {
                    setIsMounted(true);
                    setAttempts(0);
                    setIsConnecting(false);
                }

            }
        }, 5000);
    }

    const getUserInfo = () => {
        try {
            let promise = Promise.resolve(getEventbriteUserInfo());

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty("access_token") && isValidParam(response.access_token)) {
                            setAccessToken(response.access_token);
                            let tempDetails = details;
                            tempDetails['isAddNewToggle'] = (response.is_add_new_contact === "1" ? true : false);
                            setDetails(tempDetails);
                            if (response.hasOwnProperty("email")) {
                                setIsConnected(true);
                                setEmailAddress(response.email);
                                setUserId(response.id);
                                setSelectedOrganisationIds(response.organisationsId);
                                //getOrganisations();
                            } else {
                                setIsMounted(true);
                            }

                        } else {
                            setIsMounted(true);
                        }
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'eventbriteIntegration.js -> getUserInfo()':" + error);
        }
    }

    const disconnect = () => {
        try {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.MY_INTEGRATION.DISCONNECT_CONNECTION, () => handleSfDialogDisconnect(), null);
        } catch (error) {
            console.log("Error in 'eventbriteIntegration.js -> disconnect()':" + error);
        }
    }

    const handleSfDialogDisconnect = () => {
        setIsDisconnect(true);
        let promise = disconnectEventbrite();
        promise.then((response) => {
            if (isValidParam(response)) {
                if (isValidParam(response.data) && response.data) {
                    setIsConnected(false);
                    setConnecting(false);
                    setIsDisconnect(false);
                    setDetails({})
                }
            }
        });
    }

    const getOrganisations = () => {
        try {
            let param = { user_id: userId, access_token: accessToken, toggle_on: details.isAddNewToggle }
            let obj = null;
            let promise = getUserOrganisations(param);

            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        let tempDetails = details;
                        tempDetails['availableOrganisations'] = response.organisations;
                        sortArrayOfString(response.ebFields);
                        tempDetails['eventbriteFields'] = response.ebFields;
                        if (response.hasOwnProperty("mapping_fields")) {
                            tempDetails['mappingFields'] = response.mapping_fields;
                        }
                        let orgAvlObj = {};
                        if (details.availableOrganisations !== null && details.availableOrganisations != "" && details.availableOrganisations.length > 0) {

                            for (var i = 0; i < details.availableOrganisations.length; i++) {
                                orgAvlObj[details.availableOrganisations[i].value] = details.availableOrganisations[i];
                            }
                        }

                        if (selectedOrganisationIds !== null && selectedOrganisationIds.length > 0) {
                            let oArr = selectedOrganisationIds.split(",");
                            let tempSelectedCompanyArr = [];
                            for (var i = 0; i < oArr.length; i++) {
                                let orgObj = orgAvlObj[oArr[i]];
                                if (orgObj !== null) {
                                    obj = {};
                                    obj.value = orgObj?.value;
                                    obj.label = orgObj?.label;
                                    obj.title = orgObj?.label;
                                    tempSelectedCompanyArr.push(obj);
                                    orgObj = null;
                                }
                            }
                            if (tempSelectedCompanyArr != null && tempSelectedCompanyArr.length > 0) {
                                tempDetails['selectedOrganisations'] = tempSelectedCompanyArr;
                            }
                            orgAvlObj = null;
                        }
                        setDetails({...tempDetails});
                        setIsMounted(true);
                    }
                });
            }
        } catch (error) {
            console.log("Error in 'eventbriteIntegration.js -> getOrganisations()':" + error);
        }
    }


    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    return (<div style={{ marginLeft: '3%' }}>
        <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.EVENTBRITE_INTEGRATION}</span></div>
        {isMounted &&
            <div style={{ paddingTop: 10 }}>
                {!isConnected &&
                    <div>
                        <div style={{ fontSize: '14px' }}>{getLocalizedStrings().label.EVENTBRITE.EVENTBRITE_CONNECT_TITLE}</div>
                        {connecting &&
                            <div><span className="msg-import-progress" >{getLocalizedStrings().label.MY_INTEGRATION.CONNECTING}<img src="/asset/images/ajLoader2.gif" /></span></div>
                        }
                        {!connecting &&
                            <div style={{ marginTop: '10px' }}>
                                <Button style={styles.primaryButton} onClick={() => connect()}>{getLocalizedStrings().label.EVENTBRITE.CONNECT_BUTTON}</Button>
                            </div>
                        }
                    </div>
                }
                {isConnected &&
                    <ConnectedView email_address={emailAddress}
                        disconnectEventbrite={() => disconnect()}
                        details={details}
                    />}
            </div>
        }
        {!isMounted &&
            <div style={{ width: '100%', height: height }} >
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        }

    </div>);
};

export default EventbriteIntegration;



const ConnectedView = ({ email_address, disconnectEventbrite, details }) => {

    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const [mappingFields, setMappingFields] = useState({});
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const [availableOrganisations, setAvailableOrganisations] = useState([]);
    const [isAddNewToggle, setIsAddNewToggle] = useState(false);

    useEffect(() => {
        if (details !== null && details.availableOrganisations !== null && details.availableOrganisations !== ""
            && details.selectedOrganisations !== null && details.selectedOrganisations !== "" && !isLoaded) {
            setIsAddNewToggle(details.isAddNewToggle);
            setAvailableOrganisations(details.availableOrganisations);
            setSelectedOrganisations(details.selectedOrganisations);
            setMappingFields(getObjectParam(details.mappingFields));
            setIsLoaded(true);
        }
    }, [details]);

    const fnDisconnectEventbrite = () => {
        disconnectEventbrite();
    }

    const handleSelectChange = (options) => {
        try {
            let arrSlectedOrg = options.map((obj) => {
                return {
                    label: obj.label,
                    value: obj.value,
                    title: obj.label
                }
            });
            setSelectedOrganisations(arrSlectedOrg);
        } catch (e) { console.error("Error in 'EventbriteIntegration.js -> handleSelectChange()':" + e); }
    }

    const onToggle = (isChecked) => {
        try {
            setIsAddNewToggle(isChecked);
        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> onToggle()':" + error);
        }
    }

    const saveSetup = () => {
        try {
            let tempMappingFields = getObjectParam(mappingFields);

            if (!isValidParam(selectedOrganisations) || (isValidParam(selectedOrganisations) && selectedOrganisations.length === 0)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.EVENTBRITE.NO_ORGANISATION_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (isAddNewToggle && Object.keys(mappingFields).length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.EVENTBRITE.NO_MAPPING_FOUND, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                let params = {
                    is_add_new_contact: isAddNewToggle,
                    organisations_id: selectedOrganisations,
                    mapping_fields: tempMappingFields
                };
                let promise = Promise.resolve(updateEventbriteDetails(params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response) && response.status === 0 && getBooleanParam(response.data)) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> onToggle()':" + error);
        }
    }

    const updateMappingFields = (mappingFieldsValue) => {
        setMappingFields(mappingFieldsValue);
    }

console.log("availableOrganisations",availableOrganisations)
console.log("details.availableOrganisations",details.availableOrganisations)

    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    return <div style={{ marginTop: '10px' }}>
        {!isLoaded &&
            <div style={{ width: '100%', height: height }} >
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        }
        {isLoaded && <div>
            <div style={{ padding: '8px', fontSize: '15px', height: '40px', background: 'rgb(227, 230, 232)' }}>
                <span style={{ float: 'left' }}>{getLocalizedStrings().label.COMMON.CONNECTED_AS} {email_address}</span>
            </div>
            <div style={{ marginTop: 20, fontSize: "14px" }}>
                <div style={{ display: 'table' }}><span>{getLocalizedStrings().label.EVENTBRITE.SELECT_ORGANISATIONS}</span></div>
                <div style={{ marginTop: 10 }}>
                    <Select
                        isMulti
                        value={selectedOrganisations}
                        placeholder={getLocalizedStrings().label.EVENTBRITE.SELECT}
                        options={details.availableOrganisations}
                        onChange={(e) => handleSelectChange(e)}
                        clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                    />
                </div>
            </div>

            <div style={{ display: 'table', marginTop: 15 }}>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '-9px' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={isAddNewToggle} value={isAddNewToggle} onChange={(e) => onToggle(e.target.checked, 'ADD_NEW')} color="default" />}
                        />
                    </FormGroup>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <span
                        style={{
                            float: 'right',
                            paddingTop: "8px",
                            marginLeft: "-12px",
                            width: '395px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: "14px"
                        }}
                        title={getLocalizedStrings().label.EVENTBRITE.EVENTBRITE_TOGGLE_TITLE}>
                        {getLocalizedStrings().label.EVENTBRITE.EVENTBRITE_TOGGLE_TITLE}
                    </span>
                </div>
            </div>
            {isAddNewToggle && <FieldsViewContainer details={details} updateMappingFields={updateMappingFields} />}
            <div style={{ marginTop: '20px', float: 'right', marginBottom: '20px' }}>
                <Button

                    style={styles.primaryButton}
                    onClick={() => saveSetup()}
                > {getLocalizedStrings().label.COMMON.SAVE}</Button>

                <Button
                    style={{ ...styles.secondaryButton }}
                    onClick={() => fnDisconnectEventbrite()}
                >{getLocalizedStrings().label.COMMON.DISCONNECT}</Button>
            </div>
        </div>}
    </div>

};



const FieldsViewContainer = ({ details, updateMappingFields }) => {

    const sfForm = useSelector((state) => state.sfForm);

    const [isLoaded, setIsLoaded] = useState(false);
    const [fields, setFields] = useState([]);
    const [ebFields, setEbFields] = useState([]);
    const [mappingFields, setMappingFields] = useState({});
    const [isAddNewToggle, setIsAddNewToggle] = useState(false);

    useEffect(() => {
        if (details !== null && details.mappingFields !== null && details.mappingFields !== ""
            && details.eventbriteFields !== null && details.eventbriteFields !== "" && !isLoaded) {
            setIsAddNewToggle(details.isAddNewToggle);
            setEbFields(details.eventbriteFields);
            setMappingFields(details.mappingFields);
            setIsLoaded(true);
        }
    }, [details]);

    useEffect(() => {
        getFields();
    }, []);

    const getFields = () => {
        try {
            let tempSfForm = getObjectParam(sfForm.data);
            let contactsData = JSON.parse(JSON.stringify(tempSfForm[constants.CONTACTS_OBJECT]));
            if (Object.keys(contactsData).length === 0) {
                let promise = getSFFormFieldsPromise(constants.CONTACTS_OBJECT);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        sfForm.data[constants.CONTACTS_OBJECT] = Object.assign({}, response);
                        let tempFields = getArrayParam(response.fields);
                        tempFields = excludeFields(tempFields);
                        sortArrayObjectByProperty(tempFields, 'label');
                        setFields(tempFields);
                    }
                });
            } else {
                contactsData = getObjectParam(contactsData);
                let tempFields = getArrayParam(contactsData.fields);
                tempFields = excludeFields(tempFields);
                sortArrayObjectByProperty(tempFields, 'label');
                setFields(tempFields);
            }

        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> getFields()':" + error);
        }
    }

    const excludeFields = (fields) => {
        let excludeFields = ['t_status', 'workflow_name', 'workflow_id', 'created_on', 't_creater', 'updated_on', 'update_by', 'sales_rep', 'visible_to', 'lead_score', 'ol_sync', 'email', 'email2', 'email_status', 'updated_by', 'permission_date', 'permission_status', 'sc_source', 'comments', 'tags', 'category', 'source', ""];
        fields = fields.filter(f => { return f.field_type !== constants.FIELD_TYPE_HEADER });
        fields = fields.filter(f => { return excludeFields.indexOf(f.name) < 0 });
        return fields;
    }

    const addORUpdateMappingFields = (details, index) => {
        try {
            if (isValidParam(details)) {
                let _mappingFields = getObjectParam(mappingFields);
                let fieldName = getStringParam(details.crm_field_name);
                let ebFieldName = getStringParam(details.eb_field_name);
                if (ebFieldName === 'Select') {
                    delete _mappingFields[fieldName];
                } else {
                    _mappingFields[fieldName] = details;
                }
                updateMappingFields(_mappingFields);
                setMappingFields(_mappingFields);
            }
        } catch (error) {
            console.error("Error in 'quickBooksIntegration.js -> addORUpdateMappingFields()':" + error);
        }
    }

    const getFieldsElements = () => {
        let elements = [];
        try {
            let tempMappingFields = getObjectParam(mappingFields);
            let usedEBField = Object.keys(tempMappingFields).map(key => { return tempMappingFields[key].eb_field_name; });

            fields.forEach((field, index) => {
                let tempMappingField = mappingFields.hasOwnProperty(field.name) ? mappingFields[field.name] : {};
                let tempEBFields = ebFields.filter(f => { return tempMappingField.eb_field_name === f || (tempMappingField.eb_field_name !== f && usedEBField.indexOf(f) < 0) });
                elements.push(<FieldDetailsView key={'FieldDetailsView-' + index} index={index} field={field} ebFields={tempEBFields} addORUpdateMappingFields={addORUpdateMappingFields} mappingField={tempMappingField} />)

            });

        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> getFieldsElements()':" + error);
        }
        return elements;
    }



    let drawerHeight = window.innerHeight - 450;
    let top = (drawerHeight - 10) / 2
    return <div>
        <div style={{ paddingTop: 15, width: '100%' }}>
            <span style={{ fontWeight: 'bold' }}>
                {getLocalizedStrings().label.EVENTBRITE.EVENTBRITE_MAP_FIELDS}
            </span>
        </div>
        <div style={{ width: '100%', maxHeight: drawerHeight, overflowY: 'scroll' }}>
            <div style={{ display: 'table', width: '100%', marginTop: 5 }}>
                <div style={{ display: 'table-cell', width: '50%' }}>
                    <span>
                        {getLocalizedStrings().label.EVENTBRITE.CRM_FIELDS_TITLE}
                    </span>
                </div>
                <div style={{ display: 'table-cell', width: '50%' }}>
                    <span style={{ marginLeft: '-4px' }}>
                        {getLocalizedStrings().label.EVENTBRITE.EVENTBRITE_FIELDS_TITLE}
                    </span>
                </div>
            </div>
            {isLoaded && getFieldsElements()}
        </div>

    </div>;

};


const FieldDetailsView = ({ key, index, field, ebFields, addORUpdateMappingFields, mappingField }) => {

    const [ebFieldName, setEbFieldName] = useState('Select');

    useEffect(() => {
        if (isValidParam(mappingField)) {
            let tempMappingField = getObjectParam(mappingField);
            let ebFieldName = getStringParam(tempMappingField.eb_field_name);
            if (ebFieldName.length > 0) {
                setEbFieldName(ebFieldName);
            }
        }
    }, [mappingField]);

    const setEbField = (event) => {
        let value = event.target.value;
        try {
            setEbFieldName(value);
            let fieldInfo = { crm_field_id: field.id, crm_field_name: field.name, eb_field_name: value };
            addORUpdateMappingFields(fieldInfo, index);
        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> setQbField()':" + error);
        }
    }

    const getFieldDropDown = () => {
        let dropDown = null;
        try {
            let tempField = field;
            if (isValidParam(tempField)) {
                dropDown = <FormControl style={{ width: '80%' }} variant="outlined">
                    <InputLabel id="sf-eventbriteintegrations-simple-select-outlined-label" className="sf-eventbriteintegrations">Eventbrite Field</InputLabel>
                    <SelectField
                        key={tempField.name + '-map-to-eventbrite'}
                        label={'Eventbrite Field'}
                        value={ebFieldName}
                        onChange={(e) => setEbField(e)} >
                        {getFieldMenuItem()}
                    </SelectField>
                </FormControl>
            }

        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> getUserDropDown()':" + error);
        }
        return dropDown;
    }

    const getFieldMenuItem = () => {
        let fieldMenuItem = [];
        try {
            let tempEbFields = getArrayParam(ebFields);
            tempEbFields = Object.assign([], tempEbFields);
            tempEbFields.unshift('Select');
            fieldMenuItem = tempEbFields.map((field, index) => {
                return <MenuItem value={field}
                    key={'eb-setup-item-' + field + index}
                    id={index}
                    title={formatFieldName(field)}
                    style={{ ...styles.popoverMenuItem }} >{formatFieldName(field)}</MenuItem>
            })
        } catch (error) {
            console.error("Error in 'eventbriteIntegration.js -> getFieldMenuItem()':" + error);
        }
        return fieldMenuItem;
    }

    const formatFieldName = (field) => {
        field = field.replace(/_/g, " ");
        return makeTiteleCase(field);
    }


    let tempField = getObjectParam(field);
    return <div style={{ display: 'table', width: '98%', marginTop: 15 }}>
        <div style={{ display: 'table-cell', width: '50%', verticalAlign: 'middle' }}><span>{tempField.label}</span></div>
        <div style={{ display: 'table-cell', width: '50%' }}>{getFieldDropDown()}</div>
    </div>;
};