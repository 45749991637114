import React, { useState, useEffect } from 'react';
import { Select, MenuItem, InputLabel, List, ListItem, FormControl, TextField } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import * as HTTPClient from '../../../services/helper/httpClient';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { endPoints } from '../../../services/constants/endPoints';
import { constants, OBJECT_TABLEID_MAP} from '../../../services/constants/constants';
import * as sfDialogs from '../components/sfDialogs';
import Checkbox from '@mui/material/Checkbox';
import { pushcompanylist, pushwflist, pushaction,pushBasecompanylist } from '../../../services/actions/batchActions';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getStringParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getActiveTab as tabManagerActiveTab, closeTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { useDispatch, useSelector } from "react-redux";


const Push = ({ object, selectedRecords, callFrom, closeDrawer }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const listView = useSelector((state) => state.listView);

    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedWorkflow, setSelectedWorkflow] = useState(0);
    const [showHideWorkFlow, setShowHideWorkFlow] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [workflowList, setWorkflowList] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [all, setAll] = useState(true);
    const [userArray, setUserArray] = useState([]);
    const [withoutConfirm, setWithoutConfirm] = useState(true);
    const [lockStages, setLockStages] = useState(false);
    const [lockTasks, setLockTasks] = useState(false);
    const [stateObject, setStateObject] = useState('');
    const [isDisableConfirmchecked, setIsDisableConfirmchecked] = useState(false);
    const [useAssignmentRule, setUseAssignmentRule] = useState(-999999);
    const [isAssignRuleExist, setIsAssignRuleExist] = useState(false);
    const [isDisablePushButton, setIsDisablePushButton] = useState(false);
    const [companyCkboxOrDrpdn, setCompanyCkboxOrDrpdn] = useState(false);
    const [textarea, setTextArea] = useState(null);

    useEffect(() => {
        let obj = getObject();
        setStateObject(obj);
    }, []);

    useEffect(() => {
        if (stateObject !== '') {
            if(stateObject === constants.COMPLIANCE_OBJECT){
                setMounted(true);
            } else {
                getCompany();
                if (stateObject === constants.SALES_LEADS_OBJECT) {
                    fnIsAssignmentRuleExists();
                }
            }
        }
    }, [stateObject]);

    

    const getObject = () => {
        let tempObject = object;
        if (tempObject === constants.SETTING) {
            let tab = tabManagerActiveTab();
            tempObject = isValidParam(tab) ? getStringParam(tab.object) : object;
        }
        if (tempObject === constants.SEARCH_RESULTS || tempObject === constants.ADVANCE_SEARCH_RESULTS) {
            let info = getActiveTabInfo();
            if (isValidParam(info.search) || isValidParam(info.advanceSearch)) {
                tempObject = tempObject === constants.SEARCH_RESULTS ? getStringParam(info.search.object) : getStringParam(info.advanceSearch.object);
            }
        }
        return tempObject;
    }


    const getCompany = () => {
        let params = {}
        setMounted(false);
        if(app.me.industry_flag == true){
        var response = pushBasecompanylist(stateObject, params);
        }else{
        var response = pushcompanylist(stateObject, params);
        }
        
        response.then((response) => {
            if (response.records !== null) {
                if (response.records.length > 0) {
                    let companyLists = response.records;

                    if (stateObject !== constants.ACCOUNTS_OBJECT) {
                        let compObj = {};
                        compObj.company = "All";
                        compObj.myclientId = 0;
                        compObj.id = -9999;
                        if(stateObject == "sales-templates"){
                            companyLists.sort((a, b) => a.company.localeCompare(b.company));
                        }
                        companyLists.reverse();
                        companyLists.push(compObj);
                        companyLists.reverse();
                    }
                    let usersArray = [];
                    for (var i = 0; i <= companyLists.length; i++) {
                        usersArray[i] = false;
                    }
                    if (stateObject === constants.ACCOUNTS_OBJECT || stateObject === constants.SALES_LEADS_OBJECT) {
                        setCompanyList(companyLists);
                        setUserArray(usersArray);
                        setCompanyCkboxOrDrpdn(true);
                        setMounted(true);
                    } else {
                        setCompanyList(companyLists);
                        setUserArray(usersArray);
                        setCompanyCkboxOrDrpdn(false);
                        setMounted(true);
                    }
                } else {
                    let msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_6;
                    msg = stateObject === constants.LANDING_PAGES_FROALA_OBJECT ? msg + getLocalizedStrings().message.PUSH.LANDING_PAGES + "." : msg + stateObject + "."
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                    closeDrawer();
                }
            } else {
            }
        });
    }


    const getWFList = (value) => {
        let params = {
            myclient_id: value
        }
        var response = pushwflist(stateObject, params);
        response.then((response) => {
            if (response !== null) {
                if (response.length === 0) {
                    setShowHideWorkFlow(false);
                    setIsDisablePushButton(true);
                    let msg = (object === constants.SALES_LEADS_OBJECT) ? "You have to login once into the unit in order to assign" 
                                : "You have to login once into the unit in order to push";
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                } else if (response.length === 1) {
                    setSelectedWorkflow(response[0].id);
                    setShowHideWorkFlow(false);
                    setIsDisablePushButton(false);
                } else {
                    setSelectedWorkflow(response[0].id);
                    setWorkflowList(response);
                    setShowHideWorkFlow(true);
                    setIsDisablePushButton(false);
                }
            } else {
            }
        });
    }


    const generateWFList = (List) => {
        return List.map((item, i) => {
            if (item.t_name.trim() !== '') {
                return <MenuItem key={i} value={item.id} style={styles.popoverMenuItem}>{item.t_name}</MenuItem>;
            }

        })

    }


    const generate = (companymenulist) => {
        let element = []
        try {
            companymenulist.map((item, i) => {
                if (item.company.trim() !== '') {
                    if(object === constants.SALES_LEADS_OBJECT && item.company === "All" && constants.FRANNET_PROJECT_ID.includes(app.me.projectId)){
                        item.company = "Custom"
                        item.myclientId = '-555555'
                    }
                    element.push(<MenuItem key={i} value={item.myclientId} style={styles.popoverMenuItem}>{item.company}</MenuItem>);
                }
            });
            if (isValidParam(companymenulist) && getArrayParam(companymenulist).length > 0) {
                if (stateObject === constants.SALES_LEADS_OBJECT && getBooleanParam(isAssignRuleExist)) {
                    element.unshift(<MenuItem key={'useAssignment'} value={useAssignmentRule} style={styles.popoverMenuItem}>{'Use Assignment Rule'}</MenuItem>);
                }
            }
        } catch (error) {
            console.error("error in push.js -> 'generate()'" + error);
        }
        return element;
    }


    const changeCompany = (value) => {
        let obj = stateObject;
        if ((obj === constants.ACCOUNTS_OBJECT || obj === constants.SALES_LEADS_OBJECT) && value !== useAssignmentRule && value !== '-555555') { // value '-555555' for custom 
            getWFList(value);
        }
        if (value === useAssignmentRule || value === '-555555') { // value '-555555' for custom
            setShowHideWorkFlow(false);
            setIsDisableConfirmchecked(true);
            setWithoutConfirm(true);
            setIsDisablePushButton(false);
        } else {
            setIsDisableConfirmchecked(false);
            setWithoutConfirm(true);
        }
        setSelectedCompany(value);
    }


    const changeWorkflow = (value) => {
        setSelectedWorkflow(value);
    }


    const clickAll = ( val) => { 
        let usersArray = userArray;
        if (val === 0) {
            var isAllChecked = usersArray[0];
            for (var i = 0; i < companyList.length; i++) {
                usersArray[i] = !isAllChecked;
            }
        } else {
            if (usersArray[val] === true) {
                usersArray[0] = !usersArray[val];
                usersArray[val] = !usersArray[val];
            } else {
                usersArray[val] = !usersArray[val];
                var flag = true;
                for (var i = 1; i < companyList.length; i++) {
                    flag = (flag && usersArray[i]);
                    usersArray[0] = flag;
                }
            }
        }
        setMounted(true);
        setUserArray([...usersArray]);
    }

    const fnIsAssignmentRuleExists = () => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[stateObject] };
        var response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                setIsAssignRuleExist(getBooleanParam(response.isExists));
            }

        });
    }

    const dopush = () => {
        var rec = "";
        var records = [];
        var recordIds = [];
        let obj = stateObject;
        var checkboxes = document.getElementsByName('checkedUser');
        var recordsLen = 0;
        let msg = null
        var username = "";
        let confirmMsg = "";
        let objectLabel = "";
        let isEveryone = false;
        let myclientId = "";
        let isPushValid = false;
        let alertMsg = "";
        let checkedCount = 0;
        var tempLockStages = 0;
        var tempLockTasks = 0;
        if (obj === constants.WORKFLOW_OBJECT) {
            tempLockStages = lockStages ? 1 : 0;
            tempLockTasks = lockTasks ? 1 : 0;
        }

        if (obj === constants.ACCOUNTS_OBJECT) {
            let tempObj = app.objectList.filter(f => { return f.name === obj });
            objectLabel = tempObj[0].label;

            if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + " " + getLocalizedStrings().label.HEADER[objectLabel];
            } else {
                alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + " " + objectLabel;
            }

            alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + " " + objectLabel;
            objectLabel = objectLabel + '(s)';

            alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + " " + objectLabel;

        } else if (obj === constants.MARKETING_TEMPLATE_OBJECT) {
            objectLabel = "Marketing Template(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_8;
        } else if (obj === constants.SALES_TEMPLATE_OBJECT) {
            objectLabel = "Sales Template(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_8;
        } else if (obj === constants.AUTOMATION_DESIGNER_OBJECT) {
            objectLabel = "Automation Campaign(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_9;
        } else if (obj === constants.BROADCAST_CAMPAIGNS_OBJECT) {
            objectLabel = "Broadcast Campaign(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_10;
        } else if (obj === constants.SALES_LEADS_OBJECT) {
            objectLabel = "Sales Lead(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_13;
        } else if (obj === constants.WORKFLOW_OBJECT) {
            objectLabel = "Workflow(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_14;
        } else if (obj === constants.AGREEMENT_TEMPLATE_OBJECT) {
            objectLabel = "Agreement Template(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_12;
        } else if (obj === constants.LANDING_PAGES_FROALA_OBJECT) {
            objectLabel = "Landing Pages";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_13;
        } else if (obj === constants.COMPLIANCE_OBJECT) {
            objectLabel = "Compliance(s)";
            alertMsg = getLocalizedStrings().message.PUSH.PUSH_ALERT_MSG_13;
        }

        if (obj === constants.ACCOUNTS_OBJECT || obj === constants.SALES_LEADS_OBJECT) {
            myclientId = selectedCompany;
            if (myclientId > 0) {
                isPushValid = true;
                checkedCount++;
            }
            if (obj === constants.SALES_LEADS_OBJECT && selectedCompany === useAssignmentRule || selectedCompany === '-555555') { // value '-555555' for custom
                isPushValid = true;
            } 
            
        } else if (obj == constants.COMPLIANCE_OBJECT){
            isPushValid = true;
        }  else {
            for (var i = 1, n = checkboxes.length; i < n; i++) {
                if (checkboxes[i].checked) {
                    if (myclientId === "") {
                        myclientId = checkboxes[i].value;

                    } else {
                        myclientId = myclientId + "," + checkboxes[i].value;
                    }
                    checkedCount++;
                    isPushValid = true;
                }
            }
        }

        if (isPushValid) {
            if (callFrom === constants.LIST_VIEW || callFrom === constants.COLUMN_VIEW || callFrom === constants.DETAIL_VIEW) {
                records = selectedRecords;
                for (var i = 0; i < records.length; i++) {
                    recordIds.push(records[i].id);
                }
            }


            recordsLen = records.length;

            if (obj === constants.COMPLIANCE_OBJECT) {
                if (checkedCount === 1) {
                    if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                        confirmMsg = recordsLen + " " + getLocalizedStrings().label.HEADER[objectLabel] + " " + " " + getLocalizedStrings().message.PUSH.PUSH_COMPLIANCE_MSG + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.PUSH_COMPLIANCE_MSG + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    }
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;
                }
                else {
                    if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                        confirmMsg = recordsLen + " " + getLocalizedStrings().label.HEADER[objectLabel] + " " + " " + getLocalizedStrings().message.PUSH.PUSH_COMPLIANCE_MSG + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.PUSH_COMPLIANCE_MSG + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    }
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;
                }
            } else {

                if (checkedCount == 1) {
                    if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                        confirmMsg = recordsLen + " " + getLocalizedStrings().label.HEADER[objectLabel] + " " + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else if (object === constants.SALES_LEADS_OBJECT) {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.ASSIGN_SUCESS + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    }
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_12;
                }
                else {
                    if (isValidParam(getLocalizedStrings().label.HEADER[objectLabel])) {
                        confirmMsg = recordsLen + " " + getLocalizedStrings().label.HEADER[objectLabel] + " " + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else if (object === constants.SALES_LEADS_OBJECT) {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.ASSIGN_SUCESS + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    } else {
                        confirmMsg = recordsLen + " " + objectLabel + " " + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2_1 + " " +
                            getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_2 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_11_2;
                    }
                    msg = getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7 + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_3;
                }
            }

            let withoutConfirm = true;

            let snackBarStyle = styles.snackBarStyleLongMsg;

            let param = {};
            param.recordIds = recordIds
            if (obj === constants.ACCOUNTS_OBJECT) {
                param.type = "LEAD_PUSH";
            } else if (obj === constants.MARKETING_TEMPLATE_OBJECT) {
                param.type = "ET";
            } else if (obj === constants.SALES_TEMPLATE_OBJECT) {
                param.type = "SET";
            } else if (obj === constants.AGREEMENT_TEMPLATE_OBJECT) {
                param.type = "AGT";
            } else if (obj === constants.AUTOMATION_DESIGNER_OBJECT) {
                param.type = "AUTOMATION_DESIGNER_PUSH";
            } else if (obj === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                param.type = "BROADCAST_CAMPAIGN_PUSH";
            } else if (obj === constants.SALES_LEADS_OBJECT) {
                param.type = "SALES_LEADS";
            } else if (obj === constants.WORKFLOW_OBJECT) {
                param.type = "WORKFLOW_MANAGER";
            } else if (obj === constants.LANDING_PAGES_FROALA_OBJECT) {
                param.type = "LANDING_PAGES";
            } else if (obj === constants.COMPLIANCE_OBJECT) {
                param.type = "COMPLIANCE_PUSH";
            }
            else if (obj === constants.SMS_TEMPLATE_OBJECT) {
                param.type = "SMS_TEMPLATE_PUSH";
            } else if (obj === constants.CALL_SCRIPT_OBJECT) {
                param.type = "CALL_SCRIPT_PUSH";
            }
            param.selectionType = 'option2';
            param.workflowId = selectedWorkflow;

            param.withoutConfirm = withoutConfirm;

            param.clientIdList = myclientId.toString();
            param.lockStages = tempLockStages;
            param.lockTasks = tempLockTasks;
            let noteText = "";
            if (obj === constants.ACCOUNTS_OBJECT || obj === constants.SALES_LEADS_OBJECT) {
                let unitName = companyList.filter(e => e.myclientId === parseInt(myclientId));
                if(unitName!= null && unitName.length > 0){
                    noteText = "Record pushed to " + unitName[0]?.company;
                }
                if (document.getElementById('notetext').value !== "") {
                    noteText += "\n\n" + document.getElementById('notetext').value;
                }
            }
            param.noteText = noteText;

            if (param.clientIdList.length > 0 || constants.COMPLIANCE_OBJECT) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => confirmPush(param, recordIds, msg, snackBarStyle), null);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_5 + ' ' + obj, null);
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
        }
    }


    const confirmPush = (param, recordIds, msg, snackBarStyle) => {
        var response = pushaction(stateObject, param);
        setMounted(false);
        closeDrawer();

        if (withoutConfirm) {
            msg = (object === constants.SALES_LEADS_OBJECT) ? getLocalizedStrings().message.COMMON.ASSIGN :  getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_7;
            snackBarStyle = styles.snackBarStyleTop;
        }
        response.then((response) => {
            if (response.status === 0) {
                if (stateObject === constants.SALES_LEADS_OBJECT) {
                    listView.mounted = false;
                    listView.isGroupByMounted = false;
                    if (selectedCompany === useAssignmentRule) {
                        let url = endPoints.SALES_LEADS.EDIT_COPY + "/" + getStringParam(getArrayParam(recordIds)[0]);
                        let promise = Promise.resolve(HTTPClient.get(url, null));
                        if (isValidParam(promise)) {
                            promise.then((response) => {
                                if (isValidParam(response) && isValidParam(response.records) && response.records.hasOwnProperty('t_status') && response.records.t_status !== 'Deleted') {
                                    msg = getLocalizedStrings().message.PUSH.FAIL_USE_ASSIGNMENT_RULE_FOR_SALES_LEADS
                                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, snackBarStyle));
                                    if (callFrom === constants.LIST_VIEW) {
                                        refreshListView(constants.SALES_LEADS_OBJECT);
                                        }
                                } else {
                                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, snackBarStyle));
                                   if (callFrom === constants.LIST_VIEW) {
                                    refreshListView(constants.SALES_LEADS_OBJECT);
                                    }
                                    if (callFrom === constants.DETAIL_VIEW) {
                                        let activeTab = tabManagerActiveTab();
                                        if (isValidParam(activeTab)) {
                                            closeTab(activeTab.item, activeTab.index);
                                        }
                                    }
                                }
                            });
                        }
                    } else {
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, snackBarStyle));
                        if (callFrom === constants.LIST_VIEW) {
                            refreshListView(constants.SALES_LEADS_OBJECT);
                            }
                        if (callFrom === constants.DETAIL_VIEW) {
                            let activeTab = tabManagerActiveTab();
                            if (isValidParam(activeTab)) {
                                closeTab(activeTab.item, activeTab.index);
                            }
                        }
                    }
                } else {
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            } else if (response.status === -1) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.ASSIGN_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
               
            }
        });
    }

    const handleInputChange = (name, event) => {
        let checked;
        if (name == "lockStages") {
            checked = !lockStages;
            setLockStages(checked);
        } else {
            checked = !lockTasks;
            setLockTasks(checked);
        }
    }



    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (mounted) {
        let chkboxstyle = {
            fontWeight: 'normal',
            fontSize: '15px',
            marginLeft: '1px',
            padding: '16px 16px 0px 48px',
        };
        let fieldSetStyle = {
            border: '1px solid rgb(239, 239, 239)',
         }
        let obj = stateObject;
        let wfList = null;
        let wfListHtml = null;
        let tempCompanyList = companyList;
        var usrobj = companyList[0];
        let companymenulist = generate(companyList);
        /*if (usrobj !== undefined && obj !== constants.ACCOUNTS_OBJECT && obj !== constants.SALES_LEADS_OBJECT && usrobj["myclientId"] !== 0) {
            let compObj = {};
            compObj.company = "All";
            compObj.myclientId = 0;
            compObj.id = -9999;
            tempCompanyList.reverse();
            tempCompanyList.push(compObj);
            tempCompanyList.reverse();
        }*/
        if (showHideWorkFlow) {
            wfList = workflowList
            wfListHtml = generateWFList(wfList);
        }
        let isShowLockWorkflow = false;
        if (stateObject === constants.WORKFLOW_OBJECT) {
            isShowLockWorkflow = false;
        }
        return (
            <div style={{marginTop:'25px'}}>
            <div style={{ marginLeft: '15px', marginRight: '10px', height: '80%', marginTop: '1em' }} >
                {(companyCkboxOrDrpdn &&
                    <div style={{marginLeft:'0px'}}>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel className="sf-push-units-from-sfforms" style={{marginTop:'-7px'}}>{getLocalizedStrings().label.DOCUMENT_LIBRARY.UNIT_PUSH}</InputLabel>
                            <Select
                                id='companylist'
                                name='companylist'
                                value={selectedCompany !== 0 ? selectedCompany : null}
                                className={"sf-fields-bg"}
                                style={{ height: '42px' }}
                                label={getLocalizedStrings().label.DOCUMENT_LIBRARY.UNIT_PUSH}
                                labelId='sf-label-companylist'
                                onChange={(e) => changeCompany(e.target.value)}>
                                {companymenulist}
                            </Select>
                        </FormControl>

                    </div>
                )}
                {(!companyCkboxOrDrpdn && companyList.length > 0 &&
                    <div >

                        <List>
                            {companyList.map((comp, i) =>
                                <ListItem
                                    key={'userListItem' + i}
                                    className="sf-listitem-black-text"
                                    onClick={() => clickAll(i)}
                                    style={{
                                        cursor: 'pointer',padding:'0px'
                                    }}
                                >
                                    <ListItemIcon style={{height:'35px', paddingLeft:'12px'}}>
                                        <Checkbox
                                         id={'checkuser' + i}
                                            key={'checkuser' + i}
                                            name="checkedUser"
                                            color="default"
                                            value={comp.myclientId}
                                            checked={userArray[i]} 
                                            style={{marginTop:'3px'}}/>
                                           
                                       </ListItemIcon>
                                    <ListItemText primary={comp.company} />
                                </ListItem>)}
                        </List>

                    </div>
                )}
                {isShowLockWorkflow && <div style={{fontSize:"21px"}}>
                  <legend><font style={{paddingTop:'10px'}} color="black">Lock Workflows:</font></legend>
                        <div style={fieldSetStyle}>
                        <ListItem
                        style={{paddingTop:'17px',marginLeft:'9px'}}
                        className="sf-listitem-black-text"
                            disabled={isDisableConfirmchecked}
                            key='pwc1' role={undefined} dense button
                            onClick={(e) => handleInputChange("lockStages",e)} >
                            <Checkbox
                                edge="start"
                                color="default"
                                tabIndex={-1}
                                disableRipple
                                name="LOCK_STAGES"
                                id="lock_stages"
                                checked={lockStages}
                            />
                            <ListItemText id='lock-stages' primary={getLocalizedStrings().label.COMMON.LOCK_STAGES} />

                        </ListItem>
                        <ListItem
                          style={{marginLeft:'9px'}}
                            className="sf-listitem-black-text"
                            disabled={isDisableConfirmchecked}
                            key='pwc2' role={undefined} dense button 
                            onClick={(e) => handleInputChange("lockTasks",e )} >
                            <Checkbox
                                edge="start"
                                color="default"
                                tabIndex={-1}
                                disableRipple
                                name="LOCK_TASKS"
                                id="lock_tasks"
                                checked={lockTasks}
                            />
                            <ListItemText id='lock-taks' primary={getLocalizedStrings().label.COMMON.LOCK_TASKS} />

                        </ListItem>
                      </div>
                    {/* </fieldset> */}
                </div>
                }
                {(showHideWorkFlow &&
                    <div style={{ paddingTop: '20px' }}>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-push-frompush-simple-select-outlined-label" className="sf-push-frompush">Workflow:</InputLabel>
                            <Select
                                id='workflowlist'
                                name='workflowlist'
                                value={selectedWorkflow}
                                style={{ height: '42px' }}
                                label='Workflow:'
                                className={"sf-fields-bg"}
                                onChange={(e) => changeWorkflow(e.target.value)}>
                                {wfListHtml}
                            </Select>
                        </FormControl>

                    </div>
                )}
                {((obj === constants.ACCOUNTS_OBJECT || obj === constants.SALES_LEADS_OBJECT) &&
                    <div style={{ color: '#717171', marginTop: '20px' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                id='notetext'
                                multiline={true}
                                rows={4}
                                ref={(input) => { setTextArea(input) }}
                                label={getLocalizedStrings().label.APPOINTMENTS_TASKS.ADD_NOTE}
                                textareastyle={{ marginTop: '10px', height: '100%' }}
                                autoComplete="new-password"
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                )}
                {stateObject === constants.COMPLIANCE_OBJECT &&
                    <div>
                        Push Compliance records to all Franchises who are connected under the Franchisors.
                    </div>
                }
                <div style={{ float: 'right', marginTop: '10px',paddingBottom:'14px' }}>
                    <Button
                        style={styles.primaryButton}
                        onClick={(e) => dopush()}
                        disabled={isDisablePushButton}
                    > {(object === constants.SALES_LEADS_OBJECT) ? getLocalizedStrings().label.COMMON.ASSIGN : getLocalizedStrings().label.COMMON.PUSH}</Button>
                    <Button
                        onClick={(e) => closeDrawer(e)}
                        style={styles.secondaryButton}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>);
    };
};

export default Push;
