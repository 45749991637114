import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import isArray from "lodash/isArray";
import toNumber from "lodash/toNumber";
import find from "lodash/find";
import filter from "lodash/filter";
import replace from "lodash/replace";
import map from "lodash/map";
import * as SFFormActions from "../../../services/actions/sfFormActions";
import {
	isValidParam,
	getStringParam,
	getObjectParam,
	getArrayParam,
	getIntParam,
	getBooleanParam,
	getFloatParam,
	isInteger,
} from "../../../services/helper/parameterVerifier";
import {
	getLookupAutocompleteInfo,
	getContactInfoForLookup,
	getProjectDepTasksList,
	getFranchiseInfo,
	checkDuplicateFranchise,
	checkDuplicateProductName,
} from "../../../services/actions/sfFormActions";
import {
	constants,
	OBJECT_TABLEID_MAP,
} from "../../../services/constants/constants";
import { styles } from "../../../services/constants/styles";
import Autocomplete from "@mui/material/Autocomplete";
import {
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	InputLabel,
	Menu,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { setTabs } from "../../../services/actions/tabActions";
import { setHeader } from "../../../services/actions/headerActions";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import ReminderBlock from "./reminderBlock";
import * as link from "../../../services/constants/link";
import { isValidateFormField } from "../../../services/helper/formValidator";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import {
	dateTimeFormat,
	dateFormat,
	timeZone,
	phoneFormat,
	sortArrayObjectByProperty,
	dateTimeFormatFlatPicker,
	dateFormatFlatPicker,
} from "../../../services/helper/utils";
import moment from "moment";
import tz from "moment-timezone";
import {
	hasAccessPermission,
	isLinkedChildObject,
	getChildObjectInfo,
	getSelectedObjectLabel,
	addGroupIntoReduxStore,
	getMultiLingualLabel,
	getCampaignTypeNames,
	getCampaignTypeListValues,
} from "../../../services/helper/common";
import {
	updateAppointmentStore,
	deleteFromAppointmentStore,
	getCalendarRefresh,
} from "../../../services/actions/calendarActions";
import {
	refreshDetailViewListViewData,
	refreshDetailViewData,
	getDetailViewPinnedNote,
} from "../../../services/actions/detailViewActions";
import { getTasksRefresh } from "../../../services/actions/taskActions";
import {
	addTab,
	getActiveTab,
	TYPE_DETAIL_VIEW,
	TYPE_LIST_VIEW,
	TYPE_COLUMN_VIEW,
	TYPE_REPORT,
	getActiveTabInfo,
} from "../../../services/helper/sfTabManager";
import * as sfDialogs from "../components/sfDialogs";
import {
	refreshListView,
	setListViewMounted,
	getListData,
	getParentAccountContacts,
	getLinkRecords,
} from "../../../services/actions/listViewAcions";
import { setKanbanMounted } from "../../../services/actions/listsActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import Flatpickr from "react-flatpickr";
import { updateNotificationInfo } from "../../../services/actions/notificationActions";
import {
	getMyGroups,
	setGroups,
} from "../../../services/actions/filterMenuAction";
import { isAssignRuleExists } from "../../../services/actions/assignmentRuleActions";
import {
	getCookie,
	setCookie,
	deleteCookie,
} from "../../../services/helper/sfCookies";
import ShowCircularProgress from "../components/circularProgress";
import { updateActiveTab } from "../../../services/helper/sfTabManager";

const mapStateToProps = (state) => {
	return {
		appContainer: state.appContainer,
		tab: state.tab,
		app: state.app,
		sfForm: state.sfForm,
		detailView: state.detailView,
		notification: state.notification,
		userlist: state.userlist,
		groups: state.filters.groups,
		filters: state.filters,
		calendar: state.calendar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showCustomSnackBar: (message, bodyStyle, style) => {
			dispatch(showCustomSnackBar(message, bodyStyle, style));
		},
		setTabs: (tabs, activeTabIndex) => {
			dispatch(setTabs(tabs, activeTabIndex));
		},
		setHeader: (
			selectedTabObject,
			selectedHeader,
			kanban,
			showAddIcon,
			showHeader
		) => {
			dispatch(
				setHeader(
					selectedTabObject,
					selectedHeader,
					kanban,
					showAddIcon,
					showHeader
				)
			);
		},
		getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
			dispatch(
				getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom)
			);
		},
		getAppDialog: (
			isOpenDialog,
			dialogType,
			dialogTitle,
			eventName,
			data,
			callFrom
		) => {
			dispatch(
				getAppDialog(
					isOpenDialog,
					dialogType,
					dialogTitle,
					eventName,
					data,
					callFrom
				)
			);
		},
		updateAppointmentStore: (dataObject) => {
			dispatch(updateAppointmentStore(dataObject));
		},
		deleteFromAppointmentStore: (id) => {
			dispatch(deleteFromAppointmentStore(id));
		},
		refreshDetailViewListViewData: (isRefresh, id) => {
			dispatch(refreshDetailViewListViewData(isRefresh, id));
		},
		refreshDetailViewData: (isRefresh) => {
			dispatch(refreshDetailViewData(isRefresh));
		},
		getTasksRefresh: (isrefresh) => {
			dispatch(getTasksRefresh(isrefresh));
		},
		getCalendarRefresh: (isrefresh) => {
			dispatch(getCalendarRefresh(isrefresh));
		},
		updateNotificationInfo: (notificationInfo) => {
			dispatch(updateNotificationInfo(notificationInfo));
		},
		setGroups(groups) {
			dispatch(setGroups(groups));
		},
	};
};

class RequiredForms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
			openMode: constants.SF_FORM_OPEN_MODE_QUICK,
			fields: [],
			fieldProps: {},
			lookupInfo: [],
			reminder: [
				{
					t_reminder_type: "Email",
					t_time_value: "10",
					t_time_type: "minutes",
				},
				// {
				//     t_reminder_type: 'Pop-up',
				//     t_time_value: '10',
				//     t_time_type: 'minutes'
				// }
			],
			recurring_fields: {},
			recurring_enable: false,
			note_data: null,
			start_date: null,
			popoverTarget: null,
			isOpenPopover: false,
			listValueMenuItems: {},
			isWorkflowShow: false,
			redirect: false,
			redirectUrl: "",
			linkInfo: null,
			parentRecurId: 0,
			showReminder: false,
			recurringEditConfirmDialog: false,
			save_action_type: "",
			isSetActionType: false,
			opportunityStatusList: [],
			forCastVal: 0.0,
			probability: 0.0,
			forCastValAcc: 0.0,
			isNotifySalesRep: false,
			notifySalesRepLabel: "",
			isSavedDisabled: false,
			isConvert: false,
			attendees: [],
			linkInfoObject: {},
			isNoteObjectLink: false,
			flyingNoteText: "",
			record: null,
			companySearchName: null,
			autoCompleteDataSource: {},
			autoFocusFieldName: "",
			isWorkflowTask: 0,
			groups: [],
			selectedGroup: "",
			isDomainExist: false,
			accountId: 0,
			isAssignRuleExists: false,
			franchiseId: 0,
			isAddToQuickbook: true,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.tab.activeTabIndex !== this.props.tab.activeTabIndex) {
			this.setFormHeader(nextProps);
		} else if (nextProps.label !== this.props.label) {
			this.setFormHeader(nextProps);
		}

		if (
			nextProps.object !== this.props.object ||
			nextProps.openMode !== this.props.openMode
		) {
			this.getDataAndProcess(nextProps);
		}
	}

	componentDidMount() {
		this.getDataAndProcess(this.props);
	}

	isAssignmentRuleExists = (field) => {
		let activeTabInfo = getObjectParam(getActiveTabInfo());
		let paramObj = { table_id: OBJECT_TABLEID_MAP[this.props.object] };
		if (
			activeTabInfo.hasOwnProperty("isUnitsListView") &&
			activeTabInfo.isUnitsListView &&
			this.props.object === constants.ACCOUNTS_OBJECT
		) {
			let drawerPropsData = this.props.appContainer.drawerProps.data;
			if (
				isValidParam(drawerPropsData) &&
				drawerPropsData.hasOwnProperty("isUnitForm") &&
				drawerPropsData.isUnitForm
			) {
				paramObj.isUnitForm = drawerPropsData.isUnitForm;
				paramObj.unitProjectId = drawerPropsData.unitProjectId;
			}
		}
		var response = Promise.resolve(isAssignRuleExists(paramObj));
		response.then((response) => {
			if (response.hasOwnProperty("isExists")) {
				if (getBooleanParam(response.isExists)) {
					//  field.list_values.unshift({id: 'Use Assignment Rule', value: 'Use Assignment Rule'})
					this.state.isAssignRuleExists = true;
					this.setListValueMenuItems(field);
					this.setState({ isAssignRuleExists: true });
				}
			}
		});
	};

	getDataAndProcess = (props) => {
		this.state.isNoteObjectLink =
			props.app.selectedModuleName === constants.MODULE_HUB
				? false
				: isLinkedChildObject(props.object, constants.NOTES);
		let isConvertContact = isValidParam(props.appContainer.drawerProps.data)
			? getBooleanParam(props.appContainer.drawerProps.data.is_convert)
			: false;
		this.state.isConvert = isConvertContact;
		let data = Object.assign({}, props.sfForm.data[props.object]);
		if (Object.keys(data).length === 0) {
			this.getFormData();
		} else {
			data = getObjectParam(data);
			let fields = getArrayParam(data.fields);
			fields.forEach((f) => {
				if (
					(f.field_type === constants.FIELD_TYPE_DATE_TIME ||
						f.field_type === constants.FIELD_TYPE_DATE) &&
					isValidParam(f.default_value) &&
					f.default_value !== ""
				) {
					let currentTime = moment().tz(timeZone[this.props.app.me.timezone]);
					currentTime = currentTime.format(
						dateFormat[this.props.app.me.date_format]
					);
					let defaultDate = moment(f.default_value).format(
						dateFormat[this.props.app.me.date_format]
					);
					if (defaultDate === currentTime) {
						// This condition used for default value : 'CURRENT_DATE+N'
						currentTime = moment().tz(timeZone[this.props.app.me.timezone]);
						defaultDate = currentTime.format(
							dateTimeFormat[this.props.app.me.date_format]
						);
						f.default_value = defaultDate === "Invalid date" ? "" : defaultDate;
					}
				}
			});

			data.fields = fields;
			props.sfForm.data[props.object] = Object.assign({}, data);

			if (this.props.object === constants.GROUP_OBJECT) {
				fields = fields.filter(
					(f) =>
						f.name !== "created_on" &&
						f.name !== "updated_by" &&
						f.name !== "updated_on"
				);
				data.fields = fields;
			}
			this.processFormData(data, props.openMode);
		}

		if (
			props.object === constants.ACCOUNTS_OBJECT ||
			props.object === constants.CONTACTS_OBJECT
		) {
			this.getGroups(props);
		}

	};

	getGroups = (props) => {
		try {
			let groups = isValidParam(props.groups)
				? Object.assign([], props.groups)
				: [];
			if (groups.length === 0) {
				let promise = Promise.resolve(getMyGroups(props.object));
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response)) {
							this.props.setGroups(response);
							let tempGroups = response.groups.filter((f) => {
								return f.status === "Active";
							});
							tempGroups = tempGroups.map((m) => {
								return { ...m, label: m.name };
							});
							this.setState({
								groups: tempGroups,
							});
						}
					});
				}
			} else {
				groups = groups.filter((f) => {
					return f.status === "Active";
				});
				let tempGroups = groups.map((m) => {
					return { ...m, label: m.name };
				});
				this.setState({
					groups: tempGroups,
				});
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> getGroups()':" + error);
		}
	};

	setLinkInfo = () => {
		let parentDetails = getObjectParam(this.props.parentDetails);
		let parentObject = getStringParam(parentDetails.parent_object).trim();
		let parentTableId = 10;
		if (parentObject !== "") {
			parentTableId = OBJECT_TABLEID_MAP[parentObject];
		}
		let linkInfoObj = this.state.linkInfoObject;
		if (linkInfoObj) {
			var linkInfo = "";
			var value = linkInfoObj[parentTableId];
			if (isValidParam(value)) {
				linkInfo = parentTableId + "_" + value;
			}
			value = null;
			for (var linkInfoKey in linkInfoObj) {
				if (linkInfoKey === parentTableId) {
					continue;
				}
				value = linkInfoObj[linkInfoKey];
				if (isValidParam(value)) {
					if (linkInfo === "") {
						linkInfo = linkInfoKey + "_" + value;
					} else {
						linkInfo += "," + linkInfoKey + "_" + value;
					}
				}
				value = null;
			}
			if (isValidParam(linkInfo)) {
				this.state.linkInfo = linkInfo;
			}
		}
	};

	componentDidUpdate() {
		this.addFloatingLabelEvent();
	}

	addFloatingLabelEvent = () => {
		let fields = this.state.fields.filter((f) => {
			return (
				f.field_type === constants.FIELD_TYPE_DATE_TIME ||
				f.field_type === constants.FIELD_TYPE_DATE
			);
		});
		fields.map((f) => {
			if (isValidParam(this.state.fieldProps[f.name])) {
				let value = this.state.fieldProps[f.name].value;
				if (value !== "") {
					let dateTimeDiv = document.getElementsByClassName(
						"dateTimeDiv-" + f.name
					);
					if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
						dateTimeDiv[0].classList.add("label-top");
					}
				}
			}
		});
	};

	setFormHeader = (nextProps) => {
		let kanbanObject = {};
		kanbanObject.currentView = null;
		kanbanObject.show = false;
		this.props.setHeader(
			nextProps.object,
			this.props.appContainer.drawerProps.actionDialogName,
			kanbanObject,
			false,
			true
		);
	};

	getFormData = () => {
		try {
			var promise = SFFormActions.getSFFormFieldsPromise(this.props.object);
			promise.then((response) => {
				if (isValidParam(response)) {
					this.props.sfForm.data[this.props.object] = Object.assign(
						{},
						response
					);
					this.processFormData(response, this.props.openMode);
				}
			});
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> getFormData()':" + error);
		}
	};

	processFormData = (data, openMode) => {
		let taskFilter = true;
		this.state.fields = [];
		let moduleAccess = getObjectParam(this.props.app.me.module_access);
		try {
			if (isValidParam(data)) {
				if (
					this.props.callFrom === constants.GREENLIGHT ||
					this.props.callFrom === constants.CONVERT ||
					this.props.callFrom === constants.SF_FORM
				) {
					data.quickadd_fields = getArrayParam(this.props.requiredFields);
					let fields = getArrayParam(data.fields).map((m) => {
						return { ...m, is_required_field: true };
					});
					data.fields = fields;
				}
				if (this.props.object === constants.CONTACTS_OBJECT) {
					let parentDetails = getObjectParam(this.props.parentDetails);
					if (
						isValidParam(parentDetails) &&
						parentDetails.hasOwnProperty("parent_object") &&
						parentDetails.hasOwnProperty("parent_record_id")
					) {
						let parentObject = getStringParam(
							parentDetails.parent_object
						).trim();
						if (
							isValidParam(parentObject) &&
							parentObject.length > 0 &&
							parentObject === constants.ACCOUNTS_OBJECT
						) {
							let fields = data.fields.filter(
								(f) => f.name !== "workflow_name" && f.name !== "workflow_id"
							);
							data.fields = fields;
						}
					}
				}
				if (
					this.props.object === constants.CASES_OBJECT &&
					this.props.app.selectedModuleName === constants.MODULE_HUB
				) {
					let hubCasesFields = [
						"workflow_id",
						"workflow_name",
						"t_status",
						"custom_field1",
						"from_email",
						"phone",
						"severity",
						"synopsis",
						"problem_description",
					];
					hubCasesFields.forEach((fieldName) => {
						let tempField = find(data.fields, function (field) {
							return fieldName === field.name;
						});
						if (isValidParam(tempField)) {
							this.state.fields.push(tempField);
						}
					});
				} else if (openMode === constants.SF_FORM_OPEN_MODE_QUICK) {
					this.state.fields = [];
					if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
						let fields = this.rearrangeFields(data.quickadd_fields);
						data.quickadd_fields = fields;
					}
					data.quickadd_fields.forEach((fieldName) => {
						let tempField = find(data.fields, function (field) {
							return fieldName === field.name;
						});
						if (isValidParam(tempField)) {
							this.state.fields.push(tempField);
						}
					});
				} else if (openMode === constants.SF_FORM_OPEN_MODE_EXPANDED) {
					this.state.fields = filter(data.fields, function (field) {
						if (field.field_type === constants.FIELD_TYPE_HEADER) {
							return field;
						} else {
							return find(data.expanded_fields, function (expandedField) {
								return expandedField === field.name;
							});
						}
					});

					if (
						this.props.app.selectedModuleName === constants.MODULE_OPERATIONS &&
						this.props.object === constants.CASES_OBJECT &&
						getStringParam(this.state.franchiseId) !== ""
					) {
						this.state.fields.map((field) => {
							if (field.name === "custom_field3") {
								field.default_value = this.state.franchiseId;
							}
						});
					}
				} else if (openMode === constants.SF_FORM_OPEN_MODE_CUSTOM) {
					let customFields = JSON.parse(JSON.stringify(data.custom_fields));
					customFields = getArrayParam(customFields);

					if (
						this.props.object === constants.NOTE_OBJECT &&
						isValidParam(customFields) &&
						(this.props.app.selectedModuleName ===
							constants.MODULE_OPERATIONS ||
							this.props.app.selectedModuleName === constants.MODULE_HUB)
					) {
						if (
							this.props.hasOwnProperty("parentDetails") &&
							this.props.parentDetails.hasOwnProperty("parent_object")
						) {
							if (
								this.props.parentDetails.parent_object ===
								constants.CASES_OBJECT
							) {
								customFields = customFields.filter((f) => {
									return f !== "note_type";
								});
							}
						}
					}

					let tempFields = filter(data.fields, function (field) {
						return find(customFields, function (fld) {
							return fld === field.name;
						});
					});

					customFields.forEach((f) => {
						let searchFields = tempFields.filter((fld) => {
							return fld.name === f;
						});
						let searchField = searchFields.length > 0 ? searchFields[0] : null;
						if (isValidParam(searchField)) {
							this.state.fields.push(searchField);
						}
					});
					this.state.showReminder = data.show_reminder;
				}

				this.getRecord();
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> processFormData()':" + error
			);
		}
	};

	setWFMenuItemList = (workflowId, name, queryName, listType) => {
		try {
			if (workflowId > 0) {
				let params = {};
				params.object = this.props.object;
				params.id = workflowId;
				params.name = name;
				params.query_name = queryName;
				params.query_type = "customQuery";
				params.list_type = listType;

				var promise = SFFormActions.getFieldListValuesPromise(params);
				promise.then((response) => {
					if (isValidParam(response)) {
						this.setListValueMenuItems({
							name: "t_status",
							list_values: response,
							is_required_field: false,
						});

						if (getIntParam(this.props.recordId) === 0) {
							let status =
								response.length > 0
									? getStringParam(response[0].value).trim()
									: "";
							this.state.fieldProps["t_status"].value = status;
						}
						this.setState({
							listValueMenuItems: this.state.listValueMenuItems,
						});
						if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
							this.setOpportunityStatusList(workflowId);
						} else if (this.props.object === constants.CONTACTS_OBJECT) {
							let fields = this.props.sfForm.data["contacts"].fields;
							fields = fields.map((m) => {
								return m.name === "t_status"
									? { ...m, list_values: response }
									: m;
							});
							this.props.sfForm.data["contacts"].fields = fields;
						}
					}
				});
			} else {
				if (this.props.object === constants.CONTACTS_OBJECT) {
					let field = getObjectParam(this.state.fieldProps["t_status"]);
					this.setListValueMenuItems(field);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setWFMenuItemList()':" + error
			);
		}
	};

	setOpportunityStatusList = (workflowId) => {
		if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
			let params = {};
			params.workflow_id = workflowId;
			let promise = SFFormActions.getWorkflowStatusDetailsList(params);
			if (isValidParam(promise)) {
				promise.then((response) => {
					this.state.opportunityStatusList = response;
					this.calculateOpportynities();
				});
			}
		}
	};

	calculateOpportynities(fieldProps) {
		fieldProps = isValidParam(fieldProps) ? fieldProps : this.state.fieldProps;
		let opportunityStatusList = getArrayParam(this.state.opportunityStatusList);
		if (isValidParam(fieldProps)) {
			if (opportunityStatusList.length === 0) {
				let workflowId = getIntParam(fieldProps["workflow_id"].value);
				this.setWFMenuItemList(
					workflowId,
					fieldProps["t_status"].name,
					fieldProps["t_status"].queryName,
					fieldProps["t_status"].listType
				);
				opportunityStatusList = getArrayParam(this.state.opportunityStatusList);
			}

			let arrCurrentStatus = opportunityStatusList.filter((f) => {
				return f.name === fieldProps["t_status"].value;
			});
			let currentStatus =
				arrCurrentStatus.length > 0 ? arrCurrentStatus[0] : null;
			let probability = isValidParam(currentStatus)
				? getFloatParam(currentStatus.probability, 1)
				: 0;
			if (fieldProps.hasOwnProperty("probability")) {
				fieldProps["probability"].value = probability;
			} else {
				this.state.probability = probability;
			}

			let oppAmnt = 0;
			if (fieldProps.hasOwnProperty("opportunity_amount")) {
				oppAmnt = getFloatParam(
					this.state.fieldProps["opportunity_amount"].value
				);
			}

			let forCastVal = (oppAmnt * probability) / 100;
			if (fieldProps.hasOwnProperty("forecast_amount")) {
				fieldProps["forecast_amount"].value = getFloatParam(forCastVal);
			} else {
				this.state.forCastVal = getFloatParam(forCastVal);
			}
			this.state.fieldProps = fieldProps;
			this.setState({ fieldProps: this.state.fieldProps });
		}
	}

	setStateMenuItemList = (value) => {
		try {
			let params = {};
			params.object = this.props.object;
			params.name = "state";
			params.value = value;
			params.list_type = this.state.fieldProps[params.name].listType;

			var promise = SFFormActions.getFieldListValuesPromise(params);
			promise.then((response) => {
				if (isValidParam(response)) {
					let isRequired = getBooleanParam(
						this.state.fieldProps["state"].is_required_field
					);
					this.setListValueMenuItems({
						name: params.name,
						list_values: response,
						is_required_field: isRequired,
					});
					if (getIntParam(this.props.recordId) <= 0) {
						this.state.fieldProps[params.name].value = response[0].value;
					}
					this.setState({ listValueMenuItems: this.state.listValueMenuItems });
				}
			});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setStateMenuItemList()':" + error
			);
		}
	};

	getRecord = () => {
		
		let params = {};
		let parentDetails = null;
		let parentObject = null;
		let parentRecordId = 0;
		let object = null;
		let recordId = 0;
		let isDetailView = false;
		let isColumnView = false;
		let promise = null;
		try {
			parentDetails = getObjectParam(this.props.parentDetails);
			object = getStringParam(this.props.object);
			params.object = object;
			if (
				isValidParam(parentDetails) &&
				parentDetails.hasOwnProperty("parent_object") &&
				parentDetails.hasOwnProperty("parent_record_id")
			) {
				parentObject = getStringParam(parentDetails.parent_object).trim();
				parentRecordId = getIntParam(parentDetails.parent_record_id);
				if (parentObject !== "" && parentRecordId > 0) {
					params.parent_object = parentObject;
					params.parent_record_id = parentRecordId;
				}
			}

			isDetailView = getBooleanParam(this.props.isDetailView);
			if (
				isValidParam(parentDetails) &&
				parentDetails.hasOwnProperty("isColumnView")
			) {
				isColumnView = getBooleanParam(parentDetails.isColumnView);
			}
			recordId = isDetailView
				? parentRecordId
				: isColumnView
				? parentRecordId
				: this.props.recordId;
			recordId = this.props.recordId;
			let record = null;
			if (recordId > 0 || isDetailView || isColumnView) {
				record = this.props.record;
			} else if (this.state.isConvert) {
				record = this.props.record;
			}

			if (this.props.record !== null && this.props.record !== undefined) {
				if (isValidParam(this.props.record)) {
					if (isValidParam(this.props.record)) {
						this.state.record = this.props.record;
					}
					if (
						isValidParam(this.props.record) &&
						parentObject === constants.PROJECTS_OBJECT
					) {
						if (this.props.record.hasOwnProperty("dep_start_date")) {
							if (
								isValidParam(
									getStringParam(this.props.record.dep_start_date).trim()
								) &&
								this.props.record.dep_start_date !== ""
							) {
								this.state.taskStartDateFieldName =
									this.props.record.dep_start_date;
								this.projectDepTaskFields();
							}
						} else if (this.props.record.hasOwnProperty("t_workflow_task")) {
							if (
								this.props.record.t_workflow_task === 1 ||
								this.props.record.t_workflow_task === -1
							) {
								this.state.fields = [];
								let tempFieldForProjectTask =
									this.state.tempFieldForProjectTask.filter(
										(f) => f.name !== "dep_start_date" && f.name !== "duration"
									);
								this.state.tempCustomField.forEach((f) => {
									let searchFields = tempFieldForProjectTask.filter((fld) => {
										return fld.name === f;
									});
									let searchField =
										searchFields.length > 0 ? searchFields[0] : null;
									if (isValidParam(searchField)) {
										this.state.fields.push(searchField);
									}
								});
							}
						}
					}
					if (
						isValidParam(this.props.record) &&
						(object === constants.ACCOUNTS_OBJECT ||
							object === constants.CONTACTS_OBJECT)
					) {
						if (
							this.props.record.hasOwnProperty("lead_score") &&
							this.props.type === link.LinkToType.TYPE_COPY
						) {
							this.props.record.lead_score = 0;
						}
					}
					if (isColumnView) {
						isDetailView = true;
					}
					this.updateState(true, this.props.record, isDetailView);
				}
			} else {
				this.updateState(true);
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> getRecord()':" + error);
		}
	};

	setQuickAddFormValues = (fieldProps) => {
		try {
			console.log("props??", this.props)
			let data = getObjectParam(this.props.appContainer.drawerProps.data);
			if (isValidParam(data) && data.hasOwnProperty("formValues")) {
				let formValues = getObjectParam(data.formValues);

				Object.keys(formValues).forEach((fieldName) => {
					let value = getObjectParam(formValues[fieldName].value);
					if (value !== "" && fieldProps[fieldName] && fieldProps[fieldName].value !== "") {
						fieldProps[fieldName].value = formValues[fieldName].value;
					}
				});

				if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
					this.calculateOpportynities(fieldProps);
				}
				if (this.props.object === constants.ACCOUNTS_OBJECT) {
					this.calculateForcastForAccount(fieldProps);
				}
			}
			if(this.props.appContainer.drawerProps?.actionType == 'Edit' && isValidParam(this.props.allFields) && this.props.hasOwnProperty("allFields")){
				let _formValues = getObjectParam(this.props.allFields);

				Object.keys(_formValues).forEach((fieldName) => {
					let value = getObjectParam(_formValues[fieldName].value);
					if (value == "" && fieldProps[fieldName] && fieldProps[fieldName].value !== "") {
						fieldProps[fieldName].value = _formValues[fieldName].value;
					}
				});
			}
			if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
				this.setOppotunityContactName(fieldProps);
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setQuickAddFormValues()':" + error
			);
		}
	};

	getUnitsList = (fieldProps) => {
		fieldProps = isValidParam(fieldProps) ? fieldProps : this.state.fieldProps;
		try {
			let parentDetails = getObjectParam(this.props.parentDetails);
			let parentObject = getStringParam(parentDetails.parent_object).trim();
			let parentRecordId = getIntParam(parentDetails.parent_record_id);

			let allUnitListFromRedux = [];
			let selectedUnit = "";
			if (
				isValidParam(parentObject) &&
				parentObject === constants.ACCOUNTS_OBJECT &&
				parentRecordId > 0
			) {
				allUnitListFromRedux = getArrayParam(
					this.props.filters.recentRecords[parentObject]
				);
				selectedUnit = allUnitListFromRedux.filter((f) => {
					return f.id === parentRecordId;
				});
			}

			let params = {};
			params.object = this.props.object;
			params.name = fieldProps["unit"].name;
			params.query_name = fieldProps["unit"].queryName;
			params.query_type = "customQuery";
			params.list_type = fieldProps["unit"].listType;

			var promise = SFFormActions.getFieldListValuesPromise(params);
			promise.then((response) => {
				if (isValidParam(response)) {
					this.setListValueMenuItems({
						name: "unit",
						list_values: response,
						is_required_field: false,
					});

					if (
						isValidParam(selectedUnit) &&
						selectedUnit.length > 0 &&
						response.length > 0
					) {
						let unitArray = response.filter((f) => {
							return f.value === selectedUnit[0].recent;
						});
						this.state.fieldProps["unit"].value = unitArray[0].value;
					}
				}
			});
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> getUnitsList()':" + error);
		}
	};

	handlePopover = (fieldName, event) => {
		if (
			(this.props.object === constants.CONTACTS_OBJECT ||
				this.props.object === constants.GROUP_OBJECT) &&
			this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
			fieldName === "t_status"
		) {
			//bypass for Contacts object
		} else if (
			((fieldName === "workflow_name" || fieldName === "t_status") &&
				this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED) ||
			(fieldName !== "workflow_name" &&
				fieldName !== "t_status" &&
				this.state.fieldProps[fieldName].isReadonly)
		) {
			return;
		}
		event.preventDefault();
		this.state.fieldProps[fieldName].isOpenPopover = true;
		this.state.fieldProps[fieldName].popoverTarget = event.currentTarget;
		this.setState({ fieldProps: this.state.fieldProps });
	};

	handleRequestClose = (fieldName) => {
		this.state.fieldProps[fieldName].isOpenPopover = false;
		this.setState({ fieldProps: this.state.fieldProps });
	};

	setFieldValue = (fieldName, event, value) => {
		if (
			this.state.fieldProps[fieldName].fieldType ===
				constants.FIELD_TYPE_TEXT ||
			this.state.fieldProps[fieldName].fieldType ===
				constants.FIELD_TYPE_INTEGER ||
			this.state.fieldProps[fieldName].fieldType ===
				constants.FIELD_TYPE_FLOAT ||
			this.state.fieldProps[fieldName].fieldType ===
				constants.FIELD_TYPE_MEMO ||
			this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_URL
		) {
			if (fieldName === "fullname") {
				let items = getArrayParam(this.state.listValueMenuItems[fieldName]);
				let newItems = items.filter((f) => {
					return f.key === value;
				});

				if (newItems.length > 0) {
					value = getStringParam(newItems[0].props.fullName);
					this.state.fieldProps["location"].value = newItems[0].props.email;
					this.state.linkInfoObject[9] = newItems[0].key;
					this.setLinkInfo();
				}
			} else if (
				fieldName === "first_name" &&
				this.props.object === constants.CASES_OBJECT
			) {
				let listValues = getArrayParam(
					this.state.fieldProps[fieldName].list_values
				);
				let newItems = listValues.filter((f) => {
					return f.id === value;
				});
				if (newItems.length > 0) {
					let contactId = getIntParam(newItems[0].id);
					value = getStringParam(newItems[0].first_name).trim();
					this.state.fieldProps["last_name"].value = getStringParam(
						newItems[0].last_name
					).trim();
					this.state.fieldProps["from_email"].value = getStringParam(
						newItems[0].email
					).trim();
					this.state.fieldProps["phone"].value = getStringParam(
						newItems[0].phone
					).trim();

					let lookupInfo = {
						lookup_field_name: "",
						lookup_object_name: constants.CONTACTS_OBJECT,
						record_id: contactId,
					};
					this.setLookupInfo(lookupInfo);
				}
			} else if (
				(fieldName === "first_name" || fieldName === "last_name") &&
				this.props.object === constants.CONTACTS_OBJECT &&
				this.state.fieldProps.hasOwnProperty("full_name")
			) {
				let firstName =
					fieldName === "first_name"
						? value
						: this.state.fieldProps["first_name"].value;
				let lastName =
					fieldName === "last_name"
						? value
						: this.state.fieldProps["last_name"].value;
				let fullName = firstName + " " + lastName;
				fullName = fullName.trim();
				this.state.fieldProps["full_name"].value = fullName;
			}

			if (this.props.object === constants.GROUP_OBJECT) {
				if (fieldName === "name") {
					this.state.fieldProps["description"].value = value;
				}
			}

			this.state.fieldProps[fieldName].value = value;
		} else if (
			this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST
		) {
			if (
				value ===
					"---- " +
						getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST +
						" ----" ||
				value ===
					"---- " +
						getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE +
						" ----" ||
				value === "addto_" + fieldName
			) {
				this.state.fieldProps[fieldName].isAddToThisList = true;
				this.state.fieldProps[fieldName].value = "";
				this.state.autoFocusFieldName = fieldName;
			} else if (
				fieldName === "sales_rep" &&
				value === "Setup Assignment Rule"
			) {
				// let tab = {
				//     item:  this.props.object,
				//     label: 'Assignment Rules',
				//     object:  'assignment-rules',
				//     imgName: null,
				//     url: '/assignment-rules/rules',
				//     type: TYPE_LIST_VIEW,
				//     info: {
				//         isAssignRule: true,
				//         parentObject: this.props.object
				//     }
				// };

				// addTab(tab, true);

				this.props.getAppDrawer(false, null, null, null, null);

				this.openAssignmentRuleDrawer();
			} else {
				this.state.fieldProps[fieldName].value = value;

				if (fieldName === "workflow_name") {
					let items = getArrayParam(this.state.listValueMenuItems[fieldName]);
					items = items.filter((f) => {
						return f.value === value;
					});

					let tmpId = items.length > 0 ? getIntParam(items[0].props.id) : 0;
					this.state.fieldProps["workflow_id"].value = tmpId;
					this.setWFMenuItemList(
						tmpId,
						this.state.fieldProps["t_status"].name,
						this.state.fieldProps["t_status"].queryName,
						this.state.fieldProps["t_status"].listType
					);
				} else if (fieldName === "country") {
					if (this.state.fieldProps.hasOwnProperty("state")) {
						this.state.fieldProps["state"].value = "";
					}
					this.setStateMenuItemList(value);
				} else if (fieldName === "sales_rep") {
					this.state.isNotifySalesRep =
						value !== this.props.app.me.name ? true : false;
				}
			}
		} else {
			this.state.fieldProps[fieldName].value = value;
		}
		if (
			this.props.object === constants.OPPORTUNITIES_OBJECT &&
			(fieldName === "opportunity_amount" || fieldName === "t_status")
		) {
			this.calculateOpportynities();
		}
		if (
			this.props.object === constants.ACCOUNTS_OBJECT &&
			(fieldName === "opportunity_amount" || fieldName === "probability")
		) {
			this.calculateForcastForAccount(this.state.fieldProps);
		}
		if (
			this.props.object === constants.CASES_OBJECT &&
			this.props.app.selectedModuleName === constants.MODULE_HUB
		) {
			this.setHubCasesSubmitarInfo(this.state.fieldProps);
		}

		this.state.fieldProps[fieldName].helperText = "";
		this.setState({ fieldProps: this.state.fieldProps });
		this.handleRequestClose(fieldName);
		if(this.props.callFrom === constants.SF_FORM){
			this.props.getRequiredFormFieldsData(this.state.fieldProps['company'])
		}

	};

	setFieldValueOnBlur = (fieldName, event, value) => {
		try {
			let isPhoneField = getBooleanParam(
				this.state.fieldProps[fieldName].isPhoneField
			);
			if (isPhoneField && this.props.callFrom !== 'Convert') {
				this.state.fieldProps[fieldName].value = phoneFormat(
					this.state.fieldProps[fieldName].value
				);
				this.setState(this.state);
			}
			if (
				(fieldName === "first_name" || fieldName === "last_name") &&
				this.props.object === constants.CONTACTS_OBJECT &&
				this.state.fieldProps.hasOwnProperty("full_name")
			) {
				let firstName = this.state.fieldProps["first_name"].value;
				let lastName = this.state.fieldProps["last_name"].value;
				let fullName = firstName + " " + lastName;
				fullName = fullName.trim();
				this.state.fieldProps["full_name"].value = fullName;
				this.setState(this.state);
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setFieldValueOnBlur()':" + error
			);
		}
	};

	setMultiSelectValue = (fieldName, event, index, values) => {
		if (
			this.state.fieldProps[fieldName].selectedListIds.indexOf(
				event.currentTarget.id
			) > -1
		) {
			this.state.fieldProps[fieldName].selectedListIds = replace(
				this.state.fieldProps[fieldName].selectedListIds,
				event.currentTarget.id + ",",
				""
			);
		} else {
			this.state.fieldProps[fieldName].selectedListIds =
				this.state.fieldProps[fieldName].selectedListIds +
				event.currentTarget.id +
				",";
		}
		this.state.fieldProps[fieldName].selectedListValues = values;
		this.state.fieldProps[fieldName].value = getArrayParam(values).join();
		this.state.fieldProps[fieldName].helperText = "";
		this.setState({ fieldProps: this.state.fieldProps });
	};

	updateState = (isMounted, record, isDetailView) => {
		
		let fieldProps = {};
		let tempfield = null;
		let workflowId = 0;
		let defaultDate = null;
		let tempFields = null;
		let recordId = getIntParam(this.props.recordId);
		isDetailView = getBooleanParam(isDetailView);
		let parentDetails = null;
		let parentObject = null;
		let parentRecordId = 0;
		let locationValue = null;
		try {
			parentDetails = getObjectParam(this.props.parentDetails);
			parentObject = getStringParam(parentDetails.parent_object).trim();
			parentRecordId = getIntParam(parentDetails.parent_record_id);

			tempFields = this.state.fields.filter((f) => {
				return f.field_type !== constants.FIELD_TYPE_HEADER;
			});
			tempFields.map((field, index) => {
				if (this.state.autoFocusFieldName === "") {
					this.state.autoFocusFieldName = field.name;
				}
				if (field.field_type === "list") {
					if (
						(field.name !== "workflow_name" && field.name !== "t_status") ||
						(this.props.object === constants.CONTACTS_OBJECT &&
							field.name === "t_status")
					) {
						try {
							sortArrayObjectByProperty(field.list_values, "value");
						} catch (error) {
							console.error(
								"Error in 'RequiredForms.js -> updateState() -> call on sortArrayObjectByProperty()':" +
									error
							);
						}
					}
					if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
						if (
							this.props.object === constants.CONTACTS_OBJECT &&
							field.name === "campaign_type"
						) {
							if (isValidParam(record)) {
								field.list_values = getCampaignTypeListValues(
									record[field.name]
								);
							} else {
								field.list_values = [];
							}
						} else {
							field.list_values = field.list_values.filter((f) => {
								return f.id !== "" && f.value !== "";
							});
						}
					}
					this.setListValueMenuItems(field);
				}
				tempfield = {};
				tempfield.id = field.id;
				tempfield.name = field.name;
				tempfield.label = field.label;
				tempfield.field_length = field.field_length;
				tempfield.isPhoneField = field.is_phone_field;

				if (field.field_type === constants.FIELD_TYPE_DATE_TIME) {
					defaultDate = isValidParam(record)
						? getStringParam(record[field.name]).trim()
						: "";
					defaultDate = defaultDate !== "" ? defaultDate : field.default_value;

					if (isValidParam(defaultDate) && defaultDate.trim() !== "") {
						let momentObj = moment(defaultDate);
						defaultDate = momentObj.format(
							dateTimeFormat[this.props.app.me.date_format]
						);
						tempfield.value = defaultDate === "Invalid date" ? "" : defaultDate;
					}
				} else if (field.field_type === constants.FIELD_TYPE_DATE) {
					defaultDate = isValidParam(record)
						? getStringParam(record[field.name]).trim()
						: "";
					defaultDate = defaultDate !== "" ? defaultDate : field.default_value;

					if (isValidParam(defaultDate) && defaultDate.trim() !== "") {
						let momentObj = moment(defaultDate);
						defaultDate = momentObj.format(
							dateFormat[this.props.app.me.date_format]
						);
						tempfield.value = defaultDate === "Invalid date" ? "" : defaultDate;
					}
				} else if (field.field_type === constants.FIELD_TYPE_INTEGER) {
					if (recordId > 0) {
						tempfield.value =
							isValidParam(record) && getStringParam(record[field.name]) !== ""
								? record[field.name]
								: "";
					} else if (recordId === 0 || field.is_required_field) {
						tempfield.value = field.default_value;
					}

					//tempfield.value = isValidParam(record) && getStringParam(record[field.name]) !== '' ? record[field.name] : field.default_value;
				} else if (field.field_type === constants.FIELD_TYPE_LIST) {
					tempfield.value =
						isValidParam(record) && getStringParam(record[field.name]) !== ""
							? record[field.name]
							: field.default_value;

					if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
						tempfield.selectedListIds = "";
						if (
							this.props.object === constants.CONTACTS_OBJECT &&
							field.name === "campaign_type"
						) {
							if (isValidParam(record)) {
								let campaignTypeNames = getCampaignTypeNames(
									getStringParam(record[field.name]).trim()
								);
								tempfield.selectedListValues =
									getStringParam(campaignTypeNames);
								tempfield.selectedListValues =
									tempfield.selectedListValues !== ""
										? tempfield.selectedListValues.split(",")
										: "";
							} else {
								tempfield.selectedListValues = "";
							}
						} else {
							tempfield.selectedListValues = getStringParam(tempfield.value);
							tempfield.selectedListValues =
								tempfield.selectedListValues !== ""
									? tempfield.selectedListValues.split(",")
									: "";
						}
					} else if (
						isValidParam(tempfield.value) &&
						tempfield.value === "" &&
						field.list_values.length > 0
					) {
						tempfield.value = field.list_values[0].value;
					}

					if (
						field.name === "note_type" &&
						this.props.object === constants.NOTE_OBJECT
					) {
						let rcType = getStringParam(getCookie("rc_" + field.name));
						if (isValidParam(rcType) && rcType !== "") {
							let arr = getArrayParam(field.list_values).filter(
								(f) => f.value === rcType
							);
							if (arr.length > 0) {
								tempfield.value = rcType;
							} else {
								deleteCookie(rcType);
							}
						}
					}
				} else {
					if (recordId > 0) {
						tempfield.value =
							isValidParam(record) && getStringParam(record[field.name]) !== ""
								? record[field.name]
								: "";
					} else if (recordId === 0 || field.is_required_field) {
						tempfield.value = field.default_value;
					}
					//tempfield.value = isValidParam(record) && getStringParam(record[field.name]) !== '' ? record[field.name] : field.default_value;
				}

				//Rabinarayan Asha [29-06-2020]
				if (
					this.props.object === constants.PAYMENTS_OBJECT &&
					(tempfield.name === "quoteno" || tempfield.name === "paymentno")
				) {
					if (tempfield.name === "quoteno") {
						let tempActiveTabInfo = getObjectParam(getActiveTabInfo());
						if (
							isValidParam(tempActiveTabInfo) &&
							tempActiveTabInfo.hasOwnProperty("quotation_number")
						) {
							tempfield.value = tempActiveTabInfo.quotation_number;
						}
					} else if (tempfield.name === "paymentno") {
						tempfield.value = new Date().valueOf().toString();
					}
				}
				//end
				tempfield.list_values = field.list_values; //getMultilingualNoteTypes(this.props.object, field.name, field.list_values);
				tempfield.isReadonly = field.is_readonly;
				tempfield.fieldType = field.field_type;
				tempfield.listType = field.list_type;
				tempfield.queryName = field.query_name;
				tempfield.isOpenPopover = false;
				tempfield.popoverTarget = null;
				tempfield.helperText = "";

				if (
					tempfield.name === "workflow_name" &&
					tempfield.fieldType === "list" &&
					field.list_values.length > 0
				) {
					workflowId = isValidParam(record)
						? getIntParam(record["workflow_id"])
						: 0;
					workflowId = workflowId <= 0 ? field.list_values[0].id : workflowId;

					if (field.list_values.length > 1) {
						this.state.isWorkflowShow = true;
					}

					let workflow = isValidParam(record)
						? getStringParam(record["workflow_name"])
						: "";
					workflow = workflow === "" ? field.list_values[0].value : workflow;

					tempfield.value = workflow;
				}

				if (
					this.props.object === constants.GROUP_OBJECT &&
					this.props.type === link.LinkToType.TYPE_COPY &&
					field.name === "name" &&
					isValidParam(record)
				) {
					record[field.name] = "Copyof_" + getStringParam(record[field.name]);
				}

				if (
					(isDetailView && this.props.type !== link.LinkToType.TYPE_COPY) ||
					this.state.isConvert
				) {
					//let data = getObjectParam(this.props.appContainer.drawerProps.data);
					let detailView = getObjectParam(this.props.detailView.data); 

					if (
						recordId === 0 &&
						field.is_lookup_field &&
						this.props.isDetailView === true
					) {
						let lookupObject = getStringParam(field.lookup_object);
						let lookupFieldName = getStringParam(field.lookup_field_name);

						let titleFieldName = getStringParam(detailView.title_field_name);
						let title = getStringParam(detailView.title);

						if (parentObject === lookupObject) {
							if (parentObject === constants.CONTACTS_OBJECT) {
								let contactList = getArrayParam(detailView.avaliableContacts);
								title =
									contactList.length > 0
										? getStringParam(contactList[0].company)
										: "";
							}

							//let lookupInfo = { lookup_field_name: lookupFieldName, lookup_object_name: getStringParam(data.parent_object), record_id: getIntParam(data.parent_record_id), title: title }
							if (
								record !== null &&
								record.hasOwnProperty(field.name) &&
								getStringParam(record[field.name]) !== ""
							) {
								tempfield.value = record[field.name];
							}
						} else if (
							lookupFieldName !== "" &&
							titleFieldName !== "" &&
							lookupFieldName === titleFieldName
						) {
							//   tempfield.value = title;
						} else {
							
							/**START:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
							if (
								isValidParam(record) &&
								getStringParam(record[field.name]) !== ""
							) {
								var fieldValue = record[field.name];
								tempfield.value = fieldValue;
							}
							/**END:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
							if (fieldValue === "") {
								let record = getObjectParam(detailView.record);
								tempfield.value = getStringParam(record[lookupFieldName]);
							}
						}
					} else if (
						this.props.isDetailView === true &&
						field.name === "fullname" &&
						(parentObject === constants.ACCOUNTS_OBJECT ||
							parentObject === constants.CONTACTS_OBJECT)
					) {
						
						let primaryEmail = getStringParam(detailView.email);
						let contactList = getArrayParam(detailView.avaliableContacts);
						let selectedContacts =
							primaryEmail !== ""
								? contactList.filter((f) => {
										return f.email === primaryEmail;
								  })
								: contactList;
						let fullname =
							selectedContacts.length > 0
								? getStringParam(selectedContacts[0].full_name)
								: "";
						let contactId =
							selectedContacts.length > 0
								? getIntParam(selectedContacts[0].id)
								: 0;
						if (
							parentObject === constants.ACCOUNTS_OBJECT ||
							parentObject === constants.CONTACTS_OBJECT
						) {
							tempfield.value = fullname;
						}

						//this.state.linkInfo = getStringParam(this.state.linkInfo).trim();
						if (contactId > 0) {
							//let linkInfo = "9_" + contactId;
							//this.state.linkInfo += this.state.linkInfo === '' ? linkInfo : ',' + linkInfo;
							this.state.linkInfoObject[9] = contactId;
							this.setLinkInfo();
							locationValue = getStringParam(primaryEmail);
						}
					} else if (
						recordId === 0 &&
						(field.name === "first_name" ||
							field.name === "last_name" ||
							field.name === "from_email" ||
							field.name === "phone") &&
						this.props.object === constants.CASES_OBJECT &&
						this.props.app.me.projectId !== constants.FARMINPEX_PROJECT_ID
					) {
						tempfield.value = "";
					} else {
						
						/**START:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
						if (
							isValidParam(record) &&
							getStringParam(record[field.name]) !== ""
						) {
							tempfield.value = record[field.name];
						}
						/**END:   This section used for Auto-fill value mapping. Date: 2017-12-27 */
					}
				}

				if (field.name === "sales_rep") {
					let label = getStringParam(field.label);
					label = getLocalizedStrings().label.COMMON.hasOwnProperty(label)
						? getLocalizedStrings().label.COMMON[label]
						: label;
					this.state.notifySalesRepLabel =
						getLocalizedStrings().label.COMMON.NOTIFY + " " + label;
				}

				if (
					this.props.object === constants.CONTACTS_OBJECT &&
					field.name === "t_status"
				) {
					tempfield.is_modify_list = field.is_modify_list;
				}

				fieldProps[field.name] = tempfield;
			});
			if (fieldProps.hasOwnProperty("workflow_id")) {
				fieldProps["workflow_id"].value = workflowId;
			}

			if (
				isValidParam(locationValue) &&
				fieldProps.hasOwnProperty("location")
			) {
				fieldProps["location"].value = locationValue;
			}

			this.setQuickAddFormValues(fieldProps);
			this.setState({ mounted: isMounted, fieldProps: fieldProps });

			if (
				constants.APPOINTMENTS !== this.props.object &&
				constants.TASKS !== this.props.object &&
				this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK
			) {
				let drawerPropsData = getObjectParam(
					this.props.appContainer.drawerProps.data
				);
				let mode = getStringParam(drawerPropsData.mode);
				if (
					mode !== constants.SF_FORM_OPEN_MODE_EXPANDED &&
					fieldProps.hasOwnProperty("t_status")
				) {
					this.setWFMenuItemList(
						workflowId,
						fieldProps["t_status"].name,
						fieldProps["t_status"].queryName,
						fieldProps["t_status"].listType
					);
				}
			}

			if (
				getIntParam(this.props.recordId) > 0 &&
				(this.props.object === constants.ACCOUNTS_OBJECT ||
					this.props.object === constants.CONTACTS_OBJECT) &&
				fieldProps.hasOwnProperty("country") &&
				isValidParam(fieldProps["country"]) &&
				getStringParam(fieldProps["country"].value) !== ""
			) {
				this.setStateMenuItemList(getStringParam(fieldProps["country"].value));
			}

			if (
				isDetailView &&
				this.props.object === constants.CASES_OBJECT &&
				this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK
			) {
				this.getContactDetailsForCases(parentRecordId);
			}

			if (
				this.props.object === constants.ACCOUNTS_OBJECT ||
				this.props.object === constants.CASES_OBJECT ||
				this.props.object === constants.ISSUES_OBJECT
			) {
				this.isAssignmentRuleExists(fieldProps["sales_rep"]);
			}

			if (
				this.props.object === constants.CASES_OBJECT &&
				this.props.app.selectedModuleName === constants.MODULE_HUB &&
				fieldProps.hasOwnProperty("custom_field1")
			) {
				let userList = getArrayParam(this.props.userlist.data);
				let listValues = userList.map((m) => {
					return { id: m.value, value: m.value };
				});
				fieldProps["custom_field1"].list_values = listValues;
				fieldProps["custom_field1"].default_value = this.props.app.me.name;
				fieldProps["custom_field1"].value = this.props.app.me.name;
				let submitteField = fieldProps["custom_field1"];

				this.setListValueMenuItems(submitteField);
				this.setHubCasesSubmitarInfo(fieldProps);
			}

			if (this.props.object === constants.PROJECTS_OBJECT) {
				this.getUnitsList(fieldProps);
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> updateState()':" + error);
		}
	};

	getContactDetailsForCases = () => {
		let promise = null;
		let parentDetails = null;
		let parentObject = null;
		let parentRecordId = 0;
		try {
			parentDetails = getObjectParam(this.props.parentDetails);
			parentObject = getStringParam(parentDetails.parent_object).trim();
			parentRecordId = getIntParam(parentDetails.parent_record_id);

			let params = {};
			params.fields = ["company", "first_name", "last_name", "email", "phone"];
			if (parentRecordId > 0 && parentObject === constants.CONTACTS_OBJECT) {
				promise = Promise.resolve(
					getParentAccountContacts(parentRecordId, params)
				);
			} else if (
				parentRecordId > 0 &&
				parentObject === constants.ACCOUNTS_OBJECT
			) {
				params.parent_object = parentObject;
				params.parent_record_id = parentRecordId;
				promise = Promise.resolve(
					getListData(constants.CONTACTS_OBJECT, params)
				);
			}

			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response) && isValidParam(response.records)) {
						let lookupInfo = null;
						let items = [];
						let records = null;
						records = getArrayParam(response.records);
						if (records.length > 0) {
							let record = null;
							if (parentObject === constants.ACCOUNTS_OBJECT) {
								let email = this.props.detailView.mounted
									? getStringParam(this.props.detailView.data.email)
									: "";
								let tempArr = records.filter((f) => {
									return f.email === email;
								});
								record = tempArr.length > 0 ? tempArr[0] : null;
							} else if (parentObject === constants.CONTACTS_OBJECT) {
								let tempArr = records.filter((f) => {
									return f.id === parentRecordId;
								});
								record = tempArr.length > 0 ? tempArr[0] : null;
							}

							if (isValidParam(record)) {
								let id = getIntParam(record.id);
								let company = getStringParam(record.company);
								let firstName = getStringParam(record.first_name);
								let lastName = getStringParam(record.last_name);
								let fullName = (firstName + " " + lastName).trim();
								fullName = fullName === "" ? "Empty" : fullName;
								let email = getStringParam(record.email);
								let phone = getStringParam(record.phone);

								this.state.fieldProps["first_name"].value = firstName;
								this.state.fieldProps["last_name"].value = lastName;
								this.state.fieldProps["from_email"].value = email;
								this.state.fieldProps["phone"].value = phone;

								lookupInfo = {
									lookup_field_name: "",
									lookup_object_name: constants.CONTACTS_OBJECT,
									record_id: id,
								};

								records.forEach((record, index) => {
									record.name = (
										getStringParam(record.first_name) +
										" " +
										getStringParam(record.last_name)
									).trim();
									items.push(record);
								});
							}
						}

						this.state.fieldProps["first_name"].list_values = items;
						if (lookupInfo !== null) {
							this.setLookupInfo(lookupInfo);
						}
						this.setState(this.state);
					}
				});
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> getContactDetails()':" + error
			);
		}
	};

	setHubCasesSubmitarInfo = (fieldProps) => {
		try {
			let submitar = getStringParam(fieldProps["custom_field1"].value);
			let userList = getArrayParam(this.props.userlist.data);
			let tempList = userList.filter((f) => {
				return f.value === submitar;
			});
			if (tempList.length > 0) {
				fieldProps["from_email"].value = getStringParam(tempList[0].email);
				fieldProps["phone"].value = getStringParam(tempList[0].phone);
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setHubCasesSubmitarInfo()':" + error
			);
		}
	};

	getRecurringDeleteChoice = (val) => {
		this.props.getAppDialog(
			false,
			constants.RECURRING_DELETE_DIALOG,
			null,
			null,
			null,
			null
		);
		this.deleteRecord(this.props.recordId, val);
	};

	deleteRecord = (id, delete_action_type) => {
		let params = {};
		params.id = [id];
		if (delete_action_type !== null) {
			params.delete_action_type = delete_action_type;
		}
	};

	setGroup = (event, value) => {
		this.setState({ selectedGroup: value });
	};
	calculateForcastForAccount = (fieldProps) => {
		try {
			fieldProps = isValidParam(fieldProps)
				? fieldProps
				: this.state.fieldProps;
			if (isValidParam(fieldProps)) {
				let probability = 0;
				if (fieldProps.hasOwnProperty("probability")) {
					probability = fieldProps["probability"].value;
				}

				let oppAmnt = 0;
				if (fieldProps.hasOwnProperty("opportunity_amount")) {
					oppAmnt = getFloatParam(
						this.state.fieldProps["opportunity_amount"].value
					);
				}

				let forCastVal = (oppAmnt * probability) / 100;
				if (fieldProps.hasOwnProperty("forecast_amount")) {
					fieldProps["forecast_amount"].value = getFloatParam(forCastVal);
				} else {
					this.state.forCastValAcc = getFloatParam(forCastVal);
				}
				this.state.fieldProps = fieldProps;
				this.setState({ fieldProps: this.state.fieldProps });
			}
		} catch (error) {
			console.error(
				"Error in 'sfForm.js -> calculateForcastForAccount()':" + error
			);
		}
	};

	saveSFForm = (type) => {
		let tempFields = null;
		let tempField = null;
		let isValidForm = true;
		let dateValidationRequire = false;
		let start_date = null;
		let end_date = null;
		let note_type = "";
		let note_text = "";
		let isDetailView = false;
		let object = null;
		let taskDateValidationRequire = false;
		try {
			if (this.props.callFrom === constants.CONVERT && isValidParam(type)) {
				this.state.convertType = type;
			}
			object = getStringParam(this.props.object);
			isDetailView = getBooleanParam(this.props.isDetailView);
			let recordId =
				isValidParam(this.props.recordId) && toNumber(this.props.recordId) > 0
					? toNumber(this.props.recordId)
					: 0;
			let fieldProps = this.state.fieldProps;
			if (isValidParam(fieldProps)) {
				tempFields = this.state.fields.filter((f) => {
					return f.field_type !== constants.FIELD_TYPE_HEADER;
				});
				for (let i = 0; i < tempFields.length; i++) {
					let formField = tempFields[i];
					let fieldForSave = fieldProps[tempFields[i].name];
					if (this.props.callFrom === constants.CONVERT) {
						fieldForSave.callFrom = this.props.callFrom;
					}
					isValidForm = isValidateFormField(object, formField, fieldForSave);
					if (!isValidForm) {
						this.setState({ fieldProps: fieldProps });
						this.props.showCustomSnackBar(
							fieldForSave.helperText,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						);
						if (
							this.props.callFrom === constants.CONVERT &&
							isValidParam(this.props.onFieldConvert)
						) {
							this.props.onFieldConvert("InvalidForm");
						}

						this.focusElementByName(formField.name);
						break;
					}
				}

				if (dateValidationRequire) {
					if (
						dateValidationRequire &&
						start_date !== null &&
						start_date !== "Invalid date" &&
						end_date !== null &&
						end_date !== "Invalid date"
					) {
						let startDateMomentObj = moment(start_date);
						let endDateMomentObj = moment(end_date);
						if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.APPOINTMENTS_TASKS
									.INVALID_START_END_DATE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
							isValidForm = false;
						}
					} else {
						if (start_date === "Invalid date" && end_date === "Invalid date") {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.APPOINTMENTS_TASKS
									.BLANK_START_END_DATE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
						} else if (start_date === "Invalid date") {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.APPOINTMENTS_TASKS
									.BLANK_START_DATE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
						} else if (end_date === "Invalid date") {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.APPOINTMENTS_TASKS.BLANK_END_DATE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
						}
						isValidForm = false;
					}
				}

				if (taskDateValidationRequire && this.state.recurring_enable) {
					let startDateMomentObj = moment(fieldProps["t_dueby"].value);
					let recurObj = getObjectParam(this.state.recurring_fields);
					let endDateMomentObj = moment(recurObj.end_date);
					if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
						this.props.showCustomSnackBar(
							getLocalizedStrings().message.APPOINTMENTS_TASKS
								.INVALID_DUEBY_END_DATE,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						);
						isValidForm = false;
					}
				}

				if (note_type === "Note" && note_text.trim() === "") {
					this.props.showCustomSnackBar(
						getLocalizedStrings().message.BATCH_ACTION.NOTE_TXT_CANNOT_BE_BLANK,
						styles.snackbarBodyStyleError,
						styles.snackBarStyleTop
					);
					isValidForm = false;
				}

				if (
					(this.props.app.selectedModuleName === constants.MODULE_HUB ||
						this.props.app.selectedModuleName ===
							constants.MODULE_OPERATIONS) &&
					getObjectParam(this.props.parentDetails).parent_object ===
						constants.CASES_OBJECT
				) {
					if (note_text.trim() === "") {
						this.props.showCustomSnackBar(
							getLocalizedStrings().message.BATCH_ACTION
								.NOTE_TXT_CANNOT_BE_BLANK,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						);
						isValidForm = false;
					}
				}

				if (isValidForm && this.props.object === constants.CONTACTS_OBJECT) {
					let lookupinfo = getArrayParam(this.state.lookupInfo);

					if (lookupinfo !== null && lookupinfo.length > 0) {
						let lookupObj = getObjectParam(lookupinfo[0]);

						if (
							lookupObj.lookup_object_name === constants.ACCOUNTS_OBJECT &&
							lookupObj.lookup_field_name === "company"
						) {
							let workflow = getObjectParam(
								this.state.fieldProps["workflow_id"]
							);
							let id = getIntParam(workflow.value);

							/*if (id > 0) {
                                isValidForm = false;
                                this.props.showCustomSnackBar(getLocalizedStrings().message.COMMON.BOTH_COMPANY_AND_WORKFLOW_SELECTED, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            }*/
						}
					}
				}

				if (isValidForm && this.props.callFrom !== constants.SF_FORM) {
					let tempDateValue = null;
					let params = {};
					params.id = recordId;
					params.parent_object = "";
					params.parent_record_id = 0;
					params.is_convert = this.state.isConvert;
					params.is_notify_sales_rep = this.state.isNotifySalesRep;
					params.fields = [];

					if (object === constants.PAYMENTS_OBJECT) {
						let activeTabInfo = getObjectParam(getActiveTabInfo());
						params.quotation_id = getIntParam(activeTabInfo.quotation_id);
					}

					for (let key in fieldProps) {
						tempField = {};
						tempField.id = fieldProps[key].id;
						tempField.name = fieldProps[key].name;
						if (fieldProps[key].fieldType === constants.FIELD_TYPE_DATE_TIME) {
							if (typeof fieldProps[key].value === "string") {
								// for Empty value  -- Lalu
								if (getStringParam(fieldProps[key].value) === "") {
									tempDateValue = null;
								} else {
									tempDateValue = moment(fieldProps[key].value);
								}
							} else {
								// for Empty value  -- Lalu
								if (getStringParam(fieldProps[key].value) === "") {
									tempDateValue = null;
								} else {
									tempDateValue = fieldProps[key].value;
								}
							}

							if (isValidParam(tempDateValue)) {
								tempDateValue = tempDateValue.format("YYYY-MM-DD HH:mm:ss");
								tempField.value = tempDateValue;
							} else {
								// for Empty value  -- Lalu
								tempField.value = tempDateValue;
							}
						} else if (
							fieldProps[key].fieldType === constants.FIELD_TYPE_DATE
						) {
							if (typeof fieldProps[key].value === "string") {
								tempDateValue = moment(fieldProps[key].value);
							} else {
								tempDateValue = fieldProps[key].value;
							}

							if (isValidParam(tempDateValue)) {
								tempDateValue = tempDateValue.format("YYYY-MM-DD");
								tempField.value = tempDateValue;
							} else {
								tempField.value = "";
							}
						} else if (
							fieldProps[key].name === "note_text" &&
							getStringParam(fieldProps[key].value) === ""
						) {
							tempField.value = fieldProps["note_type"].value;
						} else if (
							object === constants.OPPORTUNITIES_OBJECT &&
							tempField.name === "product"
						) {
							let tempArr = getArrayParam(fieldProps[key].value);
							let recordNames = "";
							if (tempArr.length > 0) {
								tempArr.forEach((record) => {
									if (isValidParam(record)) {
										let recordName = getStringParam(record.name);
										recordNames =
											recordNames === ""
												? recordName
												: recordNames + "," + recordName;
									}
								});
							}
							tempField.value = recordNames;
						} else {
							tempField.value = fieldProps[key].value;
						}

						if (
							object === constants.ACCOUNTS_OBJECT &&
							tempField.name === "company" &&
							isValidParam(tempField.value) &&
							getStringParam(tempField.value) === ""
						) {
							let firstName = fieldProps.hasOwnProperty("first_name")
								? getStringParam(fieldProps["first_name"].value)
								: "";
							let lastName = fieldProps.hasOwnProperty("last_name")
								? getStringParam(fieldProps["last_name"].value)
								: "";
							let fullName = (firstName + " " + lastName).trim();
							tempField.value = fullName;
							//fieldProps['company'].value = fullName;
						}

						if (
							object === constants.OPPORTUNITIES_OBJECT &&
							tempField.name === "contact_name" &&
							isValidParam(tempField.value) &&
							getStringParam(tempField.value).length > 0
						) {
							let contactName = fieldProps.hasOwnProperty("contact_name")
								? getStringParam(fieldProps["contact_name"].value)
								: "";
							let exp = "(";
							if (contactName.includes(exp)) {
								contactName = contactName
									.slice(0, contactName.indexOf(exp))
									.trim();
								tempField.value = contactName;
							}
						}
						params.fields.push(tempField);
					}
					if (
						this.props.object === constants.OPPORTUNITIES_OBJECT &&
						!fieldProps.hasOwnProperty("forecast_amount")
					) {
						params.fields.push({
							id: 0,
							name: "forecast_amount",
							value: getFloatParam(this.state.forCastVal),
						});
					}
					if (
						this.props.object === constants.ACCOUNTS_OBJECT &&
						!fieldProps.hasOwnProperty("forecast_amount")
					) {
						params.fields.push({
							id: 0,
							name: "forecast_amount",
							value: getFloatParam(this.state.forCastValAcc),
						});
					}
					if (
						this.props.object === constants.OPPORTUNITIES_OBJECT &&
						!fieldProps.hasOwnProperty("probability")
					) {
						params.fields.push({
							id: 0,
							name: "probability",
							value: getFloatParam(this.state.probability, 1),
						});
					}

					if (this.props.callFrom === constants.GREENLIGHT) {
						let greenLightFieldId = 0;
						let fields = getArrayParam(this.props.sfForm.data.accounts.fields);
						let greenLightField = fields.filter(
							(f) => f.name === constants.GREENLIGHT_STATUS_FIELD
						);
						if (greenLightField.length > 0) {
							greenLightFieldId = getIntParam(greenLightField[0].id);
						}
						params.fields.push({
							id: greenLightFieldId,
							name: constants.GREENLIGHT_STATUS_FIELD,
							value: constants.GREENLIGHT,
						});
					}

					if (
						(this.props.object === constants.CASES_OBJECT ||
							this.props.object === constants.NOTE_OBJECT) &&
						(this.props.app.selectedModuleName === constants.MODULE_HUB ||
							this.props.app.selectedModuleName === constants.MODULE_OPERATIONS)
					) {
						params.call_from = this.props.app.selectedModuleName;
					}

					//START: Set Parent Info
					let parentDetails = getObjectParam(this.props.parentDetails);
					let parentObject = getStringParam(parentDetails.parent_object).trim();
					let parentRecordId = getIntParam(parentDetails.parent_record_id);
					params = { ...params, ...parentDetails };

					if (isDetailView) {
						let lookupInfo = getArrayParam(this.state.lookupInfo);
						let tempArr = lookupInfo.filter((f) => {
							return f.lookup_object_name === parentObject;
						});
						if (tempArr.length > 0) {
							params.parent_object = getStringParam(
								tempArr[0].lookup_object_name
							);
							params.parent_record_id = getIntParam(tempArr[0].record_id);
							this.state.lookupInfo = lookupInfo.filter((f) => {
								return f.lookup_object_name !== parentObject;
							});
						}
					}
					//END: Set Parent Info

					params.lookup_info = this.state.lookupInfo;

					if (this.props.type === link.LinkToType.TYPE_COPY) {
						params.id = 0;
					}

					if (
						(this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
							this.props.openMode === constants.SF_FORM_OPEN_MODE_CUSTOM) &&
						recordId === 0
					) {
						this.addDefaultValueForQuickAddForm(params.fields);
					}

					let selectedGroup = getStringParam(this.state.selectedGroup);
					if (selectedGroup !== "") {
						let groups = getArrayParam(this.state.groups);
						groups = groups.filter((f) => {
							return f.name === selectedGroup;
						});
						if (groups.length > 0) {
							params.groups = [groups[0].id];
							params.is_group_link = true;
						}
					}

					let flyingNoteText = getStringParam(this.state.flyingNoteText);
					if (this.state.isNoteObjectLink && flyingNoteText !== "") {
						params.flying_note_text = flyingNoteText;
					}

					if (
						this.props.object === constants.CASES_OBJECT &&
						recordId === 0 &&
						this.state.fieldProps.hasOwnProperty("problem_description")
					) {
						let noteText = getStringParam(
							this.state.fieldProps.problem_description.value
						);
						if (noteText !== "") {
							params.is_note_link = true;
							params.note = {
								note_type: "Note",
								note_text: noteText,
								author: this.props.app.me.name,
								creator: this.props.app.me.name,
							};
						}
					}

					if (
						this.props.app.me.projectId ===
							constants.CORPORATE_ACCOUNT_PROJECTID &&
						object === constants.ACCOUNTS_OBJECT &&
						this.state.fieldProps.hasOwnProperty("workflow_name")
					) {
						let workflowName = getStringParam(
							this.state.fieldProps.workflow_name.value
						);
						if (workflowName === constants.CORPORATE_ACCOUNT_PARTNER_WORKFLOW) {
							params.is_partner = true;
						}
					}
					if (object === constants.PAYMENTS_OBJECT) {
						params.is_addQB = getBooleanParam(this.state.isAddToQuickbook);
					}

					//  START:   Duplicate Email Checking.
					let email = this.state.fieldProps.hasOwnProperty("email")
						? getStringParam(this.state.fieldProps.email.value)
						: "";
					let existingEmail =
						recordId > 0 && isValidParam(this.state.record)
							? getStringParam(this.state.record["email"])
							: "";
					if (
						email !== "" &&
						(object === constants.ACCOUNTS_OBJECT ||
							object === constants.CONTACTS_OBJECT) &&
						(recordId === 0 ||
							(recordId > 0 && existingEmail !== "" && existingEmail !== email))
					) {
						let activeTabInfo = getObjectParam(getActiveTabInfo());
						if (
							activeTabInfo.hasOwnProperty("isUnitsListView") &&
							activeTabInfo.isUnitsListView &&
							object === constants.ACCOUNTS_OBJECT
						) {
							let drawerPropsData = this.props.appContainer.drawerProps.data;
							if (
								isValidParam(drawerPropsData) &&
								drawerPropsData.hasOwnProperty("isUnitForm") &&
								drawerPropsData.isUnitForm
							) {
								params.isUnitForm = drawerPropsData.isUnitForm;
								params.unitProjectId = drawerPropsData.unitProjectId;
							}
						}
						this.checkDuplicateEmail(object, params, recordId);
					} else if (this.props.object === constants.PRODUCTS_OBJECT) {
						this.checkDuplicateProduct(object, params, recordId);
					} else {
						this.checkDuplicateFranchiseId(object, params, recordId);
					}
					//  END:   Duplicate Email Checking.
				}
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> saveSFForm()':" + error);
		}
		return isValidForm;
	};

	checkDuplicateEmail = (object, params, recordId) => {
		try {
			let searchParams = {};
			searchParams.fields = ["company"];
			searchParams.search_field_name = "email";
			searchParams.search_text = getStringParam(
				this.state.fieldProps.email.value
			);
			searchParams.search_type = "exact";
			searchParams.start_index = 0;
			searchParams.page_size = 10;

			let promise = Promise.resolve(getListData(object, searchParams));
			promise.then((response) => {
				let records = isValidParam(response)
					? getArrayParam(response.records)
					: [];
				if (records.length > 0) {
					if (object === constants.ACCOUNTS_OBJECT) {
						sfDialogs.confirm(
							getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
							getLocalizedStrings().message.COMMON.DUPLICATE_EMAIL,
							this.checkDuplicateProduct.bind(object, params, recordId),
							null
						);
					} else if (object === constants.CONTACTS_OBJECT) {
						sfDialogs.alert(
							getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
							getLocalizedStrings().message.COMMON.DUPLICATE_CONTACT_EMAIL,
							null
						);
					}
				} else if (this.props.object === constants.PRODUCTS_OBJECT) {
					this.checkDuplicateProduct(object, params, recordId);
				} else {
					this.checkDuplicateFranchiseId(object, params, recordId);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> checkDuplicateEmail()':" + error
			);
		}
	};

	checkDuplicateFranchiseId = (object, params, recordId) => {
		try {
			let franchiseId = isValidParam(this.state.fieldProps["franchise_id"])
				? getStringParam(this.state.fieldProps["franchise_id"].value)
						.trim()
						.toLowerCase()
				: "";
			let existingFranchiseId =
				recordId > 0 && isValidParam(this.state.record)
					? getStringParam(this.state.record["franchise_id"])
							.trim()
							.toLowerCase()
					: "";
			if (
				franchiseId !== "" &&
				object === constants.ACCOUNTS_OBJECT &&
				(recordId === 0 ||
					(recordId > 0 && existingFranchiseId !== franchiseId))
			) {
				let paramObject = {};
				paramObject.franchise_id = franchiseId;
				let promise = checkDuplicateFranchise(paramObject);
				promise.then((response) => {
					if (isValidParam(response) && response.status === 0) {
						let isDuplicateFranchise = getBooleanParam(
							response.data.is_duplicate
						);
						if (isDuplicateFranchise) {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.ACCOUNTS.DUPLICATE_FRANCHISE,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
							return false;
						} else {
							this.processSaveData(object, params, recordId);
						}
					}
				});
			} else {
				this.processSaveData(object, params, recordId);
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> checkDuplicateFranchiseId()':" + error
			);
		}
	};

	copyAccountsFieldsToContactsField = (params, companyName) => {
		let copiedContactFields = [];
		try {
			let accountsFields = getArrayParam(params.fields);
			let contactFields = isValidParam(this.props.sfForm.data.contacts)
				? getArrayParam(this.props.sfForm.data.contacts.fields)
				: [];
			contactFields = contactFields.filter((f) => {
				return f.name !== "header";
			});

			contactFields.forEach((field) => {
				let name = field.name;
				let value = field.default_value;
				let accountField = accountsFields.find((f) => {
					return f.name === name;
				});
				let fieldDetails = { id: field.id, name, value };
				if (
					isValidParam(accountField) &&
					isValidParam(accountField.value) &&
					accountField.value !== "" &&
					name !== "t_status"
				) {
					fieldDetails.value = accountField.value;
				}

				if (name === "company") {
					fieldDetails.value = companyName;
				}

				copiedContactFields.push(fieldDetails);
			});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> copyAccountsFieldsToContactsField()':" +
					error
			);
		}
		return copiedContactFields;
	};

	processSaveData = (object, params, recordId) => {
		this.saveData(object, params, recordId);

		/* 
        var isDomainExist = false; 
        let accountId = "";
        let lookupInfo = [];
        let email = isValidParam(this.state.fieldProps.email) ? getStringParam(this.state.fieldProps.email.value) : '';
        if (object === constants.ACCOUNTS_OBJECT && recordId === 0 && email !== '' && email.indexOf('@') > -1) {
            let domain = email.split('@');
            let paramObj = { "domain": domain[1] };
            let promise = Promise.resolve(HTTPClient.get(endPoints.ACCOUNTS.CHECK_DUPLICATE_DOMAIN, paramObj));
            promise.then((response) => { 
                
                isDomainExist = isValidParam(response) ? getBooleanParam(response.data.is_domain_exist) : false;
                if (isDomainExist) {
                    let tempPromise = new Promise((resolve, reject) => {
                        resolve(sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.ACCOUNTS.DUPLICATE_MAIL_DOMAIN));
                    });
                    tempPromise.then((isConfirmed) => { 
                        if (isConfirmed) { 
                            let companyName = response.data.records[0].title;

                            let recordId = getStringParam(response.data.records[0].id);
                            

                            let email = getStringParam(response.data.email);
                            let lookupFieldName = "company"
                            let lookupObject = "accounts"
                            let name = getStringParam(response.data.title);
                            this.state.fieldProps["company"].value = companyName;
                            
                               // create contacts and link to existing Account 
                            let copiedContactFields = this.copyAccountsFieldsToContactsField(params,companyName);
                            params.fields = copiedContactFields;
                        
           
                      

                            lookupInfo[0] = { lookup_object_name: lookupObject, lookup_field_name: lookupFieldName, record_id: recordId, title: name };
                            params.lookup_info = lookupInfo;
                            this.setState({ isDomainExist: isDomainExist, accountId: recordId })

                          
                            this.saveData(constants.CONTACTS_OBJECT, params, recordId);
                        } else {                        
                            this.saveData(object, params, recordId);
                        }
                    }, function (error) {
                        console.error(error.message);
                    });
                }
                else {
                    this.saveData(object, params, recordId);
                }
            });
        } else {
            this.saveData(object, params, recordId);
        } */
	};

	saveData = (object, params, recordId) => {
		recordId = getIntParam(recordId);
		let isSolutionAddFromConvert = false;
		let isDetailView = getBooleanParam(this.props.isDetailView);
		let actionType = getStringParam(
			this.props.appContainer.drawerProps.actionType
		);
		try {
			this.setState({ isSavedDisabled: true, mounted: false });
			let promise = SFFormActions.saveRecordPromise(object, params);
			promise.then((response) => {
				if (
					isValidParam(response) &&
					response.hasOwnProperty("data") &&
					response.data.hasOwnProperty("id")
				) {
					let id =
						isValidParam(response.data.id) &&
						isArray(response.data.id) &&
						response.data.id.length > 0
							? response.data.id[0]
							: 0;

					id = getBooleanParam(this.state.isDomainExist)
						? getIntParam(this.state.accountId)
						: id;
					if (id > 0) {
						if (
							this.props.callFrom === constants.CONVERT &&
							isValidParam(this.props.onFieldConvert)
						) {
							this.props.onFieldConvert(this.state.convertType);
							this.setState({ mounted: true });
						}

						if (
							object === constants.SOLUTIONS_OBJECT &&
							this.props.parentDetails !== undefined &&
							this.props.parentDetails.hasOwnProperty("parent_object") &&
							this.props.parentDetails.hasOwnProperty("parent_record_id")
						) {
							let tempParentRecordId = getObjectParam(
								this.props.parentDetails
							).parent_record_id;
							if (tempParentRecordId > 0) {
								isSolutionAddFromConvert = true;
							}
						}

						if (
							(object !== constants.NOTE_OBJECT &&
								object !== constants.APPOINTMENTS &&
								object !== constants.TASKS &&
								object !== constants.PETS_OBJECT &&
								object !== constants.PAYMENTS_OBJECT &&
								!isSolutionAddFromConvert &&
								!isDetailView &&
								!this.state.isConvert) ||
							(isDetailView &&
								object === constants.PETS_OBJECT &&
								actionType === constants.COPY)
						) {
							let isNewRecord =
								recordId > 0 && object !== constants.PETS_OBJECT ? false : true;
							this.openAsDetailView(id, isNewRecord);
						}
						if (object === constants.NOTE_OBJECT) {
							let noteTypeField = getArrayParam(params.fields).filter(
								(f) => f.name === "note_type"
							);
							if (noteTypeField.length > 0) {
								setCookie(
									"rc_" + noteTypeField[0].name,
									noteTypeField[0].value
								);
							}
						}

						if (object === constants.OPPORTUNITIES_OBJECT) {
							let tabs = this.props.tab.tabs;
							let tempTabs = tabs.filter((t) => {
								return t.type === TYPE_REPORT && t.label === constants.FORECAST;
							});
							if (tempTabs !== null && tempTabs.length > 0) {
								let info = getObjectParam(tempTabs[0].info);
								info.actionType = constants.NEW;
							}
						}

						if (
							object === constants.ACCOUNTS_OBJECT &&
							this.state.isConvert === true
						) {
							this.props.refreshDetailViewData(true);
							this.props.getTasksRefresh(true);
						}

						//Detail View Listview Refresh
						if (isDetailView) {
							let detailViewParentObject = this.props.detailView.data.object;

							if (
								isValidParam(detailViewParentObject) &&
								object === detailViewParentObject.toLowerCase()
							) {
								this.props.refreshDetailViewData(true);

								if (
									object === constants.ACCOUNTS_OBJECT ||
									object === constants.CASES_OBJECT ||
									object === constants.ISSUES_OBJECT
								) {
									var fieldsArrObj = getArrayParam(params.fields);
									var fieldArr = fieldsArrObj.filter(
										(f) =>
											f.name === "sales_rep" &&
											f.value === "Use Assignment Rule"
									);

									if (fieldArr.length > 0) {
										this.props.getTasksRefresh(true);
									}
								}
							} else {
								if (detailViewParentObject === constants.GROUP_OBJECT) {
									refreshListView(this.props.object, { isDetailView: true });
									this.props.refreshDetailViewListViewData(true, recordId);
								} else {
									if (
										detailViewParentObject.toLowerCase() ===
											constants.ACCOUNTS_OBJECT &&
										object === constants.CONTACTS_OBJECT
									) {
										this.props.refreshDetailViewData(true);
									}
									this.props.refreshDetailViewListViewData(true, recordId);

									//Priyanka 14.03.19
									//refresh detailViewFields on note save and update
									if (
										object === constants.NOTE_OBJECT &&
										isValidParam(detailViewParentObject)
									) {
										this.props.refreshDetailViewData(true);
										let data = getObjectParam(
											this.props.appContainer.drawerProps.data
										);
										if (
											getBooleanParam(data.isPinnedNote) &&
											getBooleanParam(data.isDetailView)
										) {
											getDetailViewPinnedNote();
										}
									}
								}
							}
						} else if (object === constants.PETS_OBJECT) {
							let info = getActiveTab().info;
							info.getAllPets();
						} else {
							let activeTab = getObjectParam(getActiveTab());
							if (activeTab.type === TYPE_DETAIL_VIEW) {
								let tabs = this.props.tab.tabs;
								let tempTabs = tabs.filter((t) => {
									return (
										t.type === TYPE_LIST_VIEW || t.type === TYPE_COLUMN_VIEW
									);
								});
								if (tempTabs !== null && tempTabs.length > 0) {
									let tab = tempTabs[0];
									if (
										tab.type === TYPE_LIST_VIEW ||
										tab.type === TYPE_COLUMN_VIEW
									) {
										setListViewMounted(false, object);
										if (
											(tab.type === TYPE_LIST_VIEW &&
												object !== constants.SOLUTIONS_OBJECT &&
												object !== constants.SALES_LEADS_OBJECTb &&
												object !== constants.APPOINTMENTS &&
												object !== constants.TASKS) ||
											tab.type === TYPE_COLUMN_VIEW
										) {
											setKanbanMounted(false);
										}
									} /* else if (tab.type === TYPE_COLUMN_VIEW) {
                                        setKanbanMounted(false);
                                    } */
								}
							}
						}

						if (object === constants.GROUP_OBJECT) {
							addGroupIntoReduxStore(
								id,
								this.state.fieldProps["name"].value,
								this.state.fieldProps["t_status"].value
							);
						}
						if (
							this.props.callFrom !== constants.CONVERT &&
							this.props.callFrom !== constants.GREENLIGHT
						) {
							this.props.getAppDrawer(false, null, null, null, null);
						}
						this.props.showCustomSnackBar(
							getLocalizedStrings().message.COMMON.SAVE,
							styles.snackbarBodyStyleSuccess,
							styles.snackBarStyleTop
						);
					}
				} else {
					if (object === constants.GROUP_OBJECT) {
						if (response.data.exists) {
							this.props.showCustomSnackBar(
								getLocalizedStrings().message.BATCH_ACTION_VALIDATION
									.GROUP_ALREADY_EXIST,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							);
							this.setState({ isSavedDisabled: false });
						}
					}
				}
			});
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> saveData()':" + error);
		}
	};
	addDefaultValueForQuickAddForm = (fieldsForSave) => {
		try {
			if (Array.isArray(fieldsForSave)) {
				let parentDetails = getObjectParam(this.props.parentDetails);
				let parentObject = getStringParam(parentDetails.parent_object).trim();
				let record = getObjectParam(this.state.record);
				let data = Object.assign({}, this.props.sfForm.data[this.props.object]);
				let fields = getArrayParam(data.fields);
				let quickAddFields = null;
				if (this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK) {
					quickAddFields = getArrayParam(data.quickadd_fields);
				} else if (this.props.openMode === constants.SF_FORM_OPEN_MODE_CUSTOM) {
					quickAddFields = getArrayParam(data.custom_fields);
					if (
						parentObject === constants.CASES_OBJECT &&
						this.props.object === constants.NOTE_OBJECT &&
						(this.props.app.selectedModuleName ===
							constants.MODULE_OPERATIONS ||
							this.props.app.selectedModuleName === constants.MODULE_HUB)
					) {
						quickAddFields = quickAddFields.filter((f) => {
							return f !== "note_type";
						});

						fields.forEach((f) => {
							if (
								this.props.app.selectedModuleName === constants.MODULE_HUB &&
								(f.name === "author" || f.name === "t_creater")
							) {
								f.default_value = this.props.app.me.name;
							} else if (f.name === "note_type") {
								f.default_value = "Case";
							}
						});
					}
				}

				fields = fields.filter((f) => {
					if (this.props.object === constants.OPPORTUNITIES_OBJECT) {
						return (
							quickAddFields.indexOf(f.name) < 0 &&
							f.name !== "header" &&
							f.name !== "" &&
							f.name !== "probability"
						);
					} else {
						return (
							quickAddFields.indexOf(f.name) < 0 &&
							f.name !== "header" &&
							f.name !== ""
						);
					}
				});

				fields.forEach((f) => {
					let val = "";
					let valueForSave = null;

					if (
						this.props.app.selectedModuleName === constants.MODULE_OPERATIONS &&
						this.props.object === constants.CASES_OBJECT &&
						f.name === "custom_field3"
					) {
						f.default_value = this.state.franchiseId;
					}

					if (record.hasOwnProperty(f.name)) {
						val = getStringParam(record[f.name]);
						valueForSave = record[f.name];
					}

					if (val === "") {
						val = getStringParam(f.default_value).trim();
						valueForSave = f.default_value;
					}

					if (
						(f.field_type === constants.FIELD_TYPE_DATE_TIME ||
							f.field_type === constants.FIELD_TYPE_DATE) &&
						isValidParam(valueForSave)
					) {
						try {
							let momentObj = moment(valueForSave);
							valueForSave = momentObj.format(constants.INPUT_DATE_TIME_FORMAT);
							valueForSave =
								valueForSave === "Invalid date" ? "" : valueForSave;
						} catch (error) {
							console.error(
								"Error in 'RequiredForms.js -> addDefaultValueForQuickAddForm() -> Faild to parse date.':" +
									error
							);
						}
					}

					if (val !== "" && isValidParam(valueForSave)) {
						fieldsForSave.push({ id: f.id, name: f.name, value: valueForSave });
					}
				});
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> addDefaultValueForQuickAddForm()':" +
					error
			);
		}
	};

	openAsDetailView = (id, isNewRecord) => {
		try {
			let objectName = this.props.object;
			let linkToUrl = "/" + objectName + "/detailview/" + id;
			if (isNewRecord || this.props.isDetailView === false) {
				let labelName = this.getRecordTitle();
				let tab = {
					label: labelName,
					object: objectName,
					type: TYPE_DETAIL_VIEW,
					imgName: "",
					url: linkToUrl,
					info: {},
				};
				addTab(tab, true);
			} else {
				updateActiveTab({ url: linkToUrl });
			}
			this.setState({ redirect: true, redirectUrl: linkToUrl });
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> openAsDetailView()':" + error
			);
		}
	};

	openAsExpandedMode = () => {
		let objectName = getStringParam(this.props.object);
		let labelName = null;
		let itemLabel = null;
		if (this.props.isDetailView) {
			let arrChildObjectInfo = getChildObjectInfo(
				getObjectParam(this.props.parentDetails).parent_object,
				objectName
			);
			if (arrChildObjectInfo.length > 0) {
				itemLabel = arrChildObjectInfo[0].title;
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			}
		} else {
			let objectList = getArrayParam(this.props.app.objectList);
			if (objectName === constants.PETS_OBJECT) {
				itemLabel = "Pets";
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			} else {
				objectList = objectList.filter((f) => {
					return f.name === objectName;
				});
				itemLabel = objectList.length > 0 ? objectList[0].label : "";
				itemLabel = itemLabel === "" ? getSelectedObjectLabel() : itemLabel;
				labelName = getLocalizedStrings().label.COMMON.ADD + " " + itemLabel;
			}
		}

		let data = {};
		data = getObjectParam(this.props.appContainer.drawerProps.data);
		if (this.props.isDetailView) {
			data = { ...this.props.parentDetails, ...data };
		}
		data.object = objectName;
		data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
		data.formValues = this.state.fieldProps;
		this.props.appContainer.drawerProps.data = data;
		this.props.getAppDrawer(true, labelName, constants.NEW, data, null);
	};

	getRecordTitle = () => {
		let label = "";
		let fieldProps = getObjectParam(this.state.fieldProps);
		let object = getStringParam(this.props.object);
		let projectId = getIntParam(this.props.app.me.projectId);
		if (object === constants.ACCOUNTS_OBJECT) {
			label = fieldProps.hasOwnProperty("company")
				? getStringParam(fieldProps["company"].value).trim()
				: "";
		} else if (object === constants.CONTACTS_OBJECT) {
			let tempLabel = null;
			let firstNamelabel = fieldProps.hasOwnProperty("first_name")
				? getStringParam(fieldProps["first_name"].value).trim()
				: "";
			let lastNameLabel = fieldProps.hasOwnProperty("last_name")
				? getStringParam(fieldProps["last_name"].value).trim()
				: "";
			if (tempLabel === null || tempLabel === "" || tempLabel === undefined) {
				if (firstNamelabel !== null && firstNamelabel !== "") {
					label = firstNamelabel;
				}
				if (lastNameLabel !== null && lastNameLabel !== "") {
					if (label === null || label === "" || label === undefined) {
						label = lastNameLabel;
					} else {
						label += " " + lastNameLabel;
					}
				}
			}
		} else if (object === constants.OPPORTUNITIES_OBJECT) {
			label = fieldProps.hasOwnProperty("name")
				? getStringParam(fieldProps["name"].value).trim()
				: "";
		} else if (object === constants.CASES_OBJECT) {
			if (this.props.app.selectedModuleName === constants.MODULE_HUB) {
				label = fieldProps.hasOwnProperty("synopsis")
					? getStringParam(fieldProps["synopsis"].value).trim()
					: "";
			} else {
				label = fieldProps.hasOwnProperty("problem_description")
					? getStringParam(fieldProps["problem_description"].value).trim()
					: "";
			}
		} else if (
			projectId === 2430 &&
			object === constants.CUSTOM_TABLE1_OBJECT
		) {
			label = getStringParam(fieldProps["custom_field4"].value).trim();
		} else {
			let fields = this.state.fields.filter((f) => {
				return f.is_title_field;
			});
			let fieldName = fields.length > 0 ? fields[0].name : "";
			label = fieldProps.hasOwnProperty(fieldName)
				? getStringParam(fieldProps[fieldName].value).trim()
				: "";
		}

		label = label.trim();
		label = label === "" ? getLocalizedStrings().label.COMMON.EMPTY : label;
		return label;
	};

	closeDrawer = () => {
		this.props.getAppDrawer(false, null, null, null, null);
	};

	getActionButtons = () => {
		let buttons = [];
		buttons.push(
			<Button
				key="save"
				onClick={this.saveSFForm}
				style={{
					...styles.primaryButton,
					marginRight: "0px",
					verticalAlign: "top",
				}}
				disabled={this.state.isSavedDisabled}
			>
				{getLocalizedStrings().label.COMMON.SAVE}
			</Button>
		);
		buttons.push(
			<Button
				onClick={this.closeDrawer}
				style={{
					...styles.secondaryButton,
					display: "inline-grid",
					marginLeft: "6px",
					marginRight: "0px",
				}}
			>
				{" "}
				{getLocalizedStrings().label.COMMON.CANCEL}
			</Button>
		);

		return buttons;
	};

	handleReminderBlockUpdate = (data) => {
		this.setState({ reminder: data });
	};

	handleDateChange = (fieldName, moment) => {
		try {
			if (moment !== null && moment !== "") {
				if (
					this.state.fieldProps[fieldName].fieldType ===
					constants.FIELD_TYPE_DATE
				) {
					this.state.fieldProps[fieldName].value = moment.format(
						dateFormat[this.props.app.me.date_format]
					);
				} else if (
					this.state.fieldProps[fieldName].fieldType ===
					constants.FIELD_TYPE_DATE_TIME
				) {
					this.state.fieldProps[fieldName].value = moment.format(
						dateTimeFormat[this.props.app.me.date_format]
					);
				}
				this.state.fieldProps[fieldName].helperText = "";
			} else {
				this.state.fieldProps[fieldName].value = "";
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> handleDateChange()':" + error
			);
		}
		this.setState({ fieldProps: this.state.fieldProps });
	};

	handleQuickAddCheckBox = (event, isInputChecked) => {
		try {
			this.state.isAddToQuickbook = isInputChecked;
			this.setState({});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js --->handleQuickAddCheckBox()':" + error
			);
		}
	};

	setListValueMenuItems = (field) => {
		
		let hasPermission = false;
		let menuItems = [];
		let fieldName = null;
		let listValues = null;
		let isRequired = false;
		let isModifyList = false;
		try {
			if (isValidParam(field)) {
				fieldName = getStringParam(field.name);
				listValues = getArrayParam(field.list_values);
				isRequired = getBooleanParam(field.is_required_field);
				isModifyList = getBooleanParam(field.is_modify_list);
				hasPermission = hasAccessPermission(
					this.props.object,
					constants.SECURITY_LEVEL_TYPE_PERMISSION,
					constants.PERMISSION_NAME_ADD_TO_LIST
				);
				listValues.map((listValue, listValueIndex) => {
					let primaryText = isValidParam(
						getLocalizedStrings().label.DEFAULT_NOTE_TYPES[listValue.value]
					)
						? getLocalizedStrings().label.DEFAULT_NOTE_TYPES[listValue.value]
						: listValue.value;

					let tempMenuObj = {};
					tempMenuObj.key = listValue.id === "" ? "-9999" : listValue.id;
					tempMenuObj.value = listValue.value;
					tempMenuObj.id = listValue.id;
					tempMenuObj.primaryText = primaryText;
					menuItems.push(tempMenuObj);
				});
				if (
					this.props.object === constants.CONTACTS_OBJECT &&
					field.name === "t_status"
				) {
					let workflowName = this.state.fieldProps.hasOwnProperty(
						"workflow_name"
					)
						? this.state.fieldProps.workflow_name.value
						: "";
					if (workflowName !== "") {
						isModifyList = false;
					}
				}

				let userId = getIntParam(this.props.app.me.id);
				let isTenant = getBooleanParam(this.props.app.me.is_tenant);
				if (isModifyList && (userId === 1 || hasPermission)) {
					let primaryText =
						fieldName === "source"
							? "---- " +
							  getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE +
							  " ----"
							: "---- " +
							  getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST +
							  " ----";
					let tempMenuObj = {};
					tempMenuObj.key = "addto_" + fieldName;
					tempMenuObj.value = primaryText;
					tempMenuObj.id = "addto_" + fieldName;
					tempMenuObj.primaryText = primaryText;
					menuItems.push(tempMenuObj);
				}

				if (
					fieldName === "sales_rep" &&
					(this.props.object === constants.ACCOUNTS_OBJECT ||
						this.props.object === constants.CASES_OBJECT ||
						this.props.object === constants.ISSUES_OBJECT ||
						this.props.object === constants.SALES_LEADS_OBJECT)
				) {
					if (
						getStringParam(this.props.app.me.t_name) === "Admin" ||
						getBooleanParam(this.props.app.me.is_tenant)
					) {
						let primaryText = "Setup Assignment Rule";
						let tempMenuObj = {};
						tempMenuObj.key = primaryText;
						tempMenuObj.value = primaryText;
						tempMenuObj.id = primaryText;
						tempMenuObj.primaryText = primaryText;
						menuItems.push(tempMenuObj);
					}
					if (this.state.isAssignRuleExists) {
						let primaryText = "Use Assignment Rule";
						let tempMenuObj = {};
						tempMenuObj.key = primaryText;
						tempMenuObj.value = primaryText;
						tempMenuObj.id = primaryText;
						tempMenuObj.primaryText = primaryText;
						menuItems.unshift(tempMenuObj);
					}
				}

				this.state.listValueMenuItems[fieldName] = menuItems;
			}
		} catch (error) {
			console.error("Error in 'sfForm.js -> setListValueMenuItems()':" + error);
		}
		return menuItems;
	};

	openLookup = (fieldName, isLookupField, lookupFieldName, lookupObject) => {
		let value = null;
		let moduleAccess = getObjectParam(this.props.app.me.module_access);
		let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
		let drawerProps = this.props.appContainer.drawerProps;
		if (this.isProjectTasks() && isOperations === 1) {
			lookupObject = constants.UNITS_OBJECT;
		}

		if (
			this.props.object === constants.OPPORTUNITIES_OBJECT &&
			isValidParam(drawerProps)
		) {
			let info = drawerProps.data;
			if (
				info !== null &&
				info.hasOwnProperty("isDetailView") &&
				info.isDetailView &&
				(drawerProps.actionType === constants.NEW ||
					drawerProps.actionType === constants.EDIT)
			) {
				let field = null;
				if (lookupObject === constants.CONTACTS_OBJECT) {
					field = this.state.fieldProps["contact_name"];
					if (isValidParam(field)) {
						if (getStringParam(field.value).length > 0) {
							if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
								value = "";
							} else {
								let exp = "(";
								let contactName = getStringParam(field.value);
								if (contactName.includes(exp)) {
									contactName = contactName
										.slice(0, contactName.indexOf(exp))
										.trim();
									value = contactName;
								} else {
									value = contactName;
								}
							}
						} else {
							value = "";
						}
					}
				} else if (lookupObject === constants.ACCOUNTS_OBJECT) {
					
					field = this.state.fieldProps["company"];
					if (isValidParam(field)) {
						if (getStringParam(field.value).length > 0) {
							if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
								value = "";
							} else {
								value = getStringParam(field.value); //company
							}
						}
					}
				} else {
					value = this.state.fieldProps[fieldName].value;
				}
			} else {
				if (
					drawerProps != null &&
					drawerProps.hasOwnProperty("actionType") &&
					(drawerProps.actionType === constants.NEW ||
						drawerProps.actionType === constants.EDIT)
				) {
					if (lookupObject === constants.CONTACTS_OBJECT) {
						let field = this.state.fieldProps["contact_name"];
						if (getStringParam(field.value).length > 0) {
							if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
								value = "";
							} else {
								let exp = "(";
								let contactName = getStringParam(field.value);
								if (contactName.includes(exp)) {
									contactName = contactName
										.slice(0, contactName.indexOf(exp))
										.trim();
									value = contactName;
								} else {
									value = contactName;
								}
							}
						} else {
							value = "";
						}
					} else if (lookupObject === constants.ACCOUNTS_OBJECT) {
						let hasContact =
							getStringParam(this.state.fieldProps["contact_name"].value)
								.length > 0
								? true
								: false;
						let hasAccount =
							getStringParam(this.state.fieldProps["company"].value).length > 0
								? true
								: false;
						if (hasContact) {
							if (!hasAccount) {
								if (getArrayParam(this.state.lookupInfo).length > 0) {
									this.state.lookupInfo.map((m) => {
										if (m.hasOwnProperty("company")) {
											value = getStringParam(m.company);
										}
									});
								}
							} else {
								value = this.state.fieldProps["company"].value;
							}
						}
					} else {
						value = this.state.fieldProps[fieldName].value;
					}
				} else if (
					this.props.hasOwnProperty("callFrom") &&
					this.props.callFrom === "Convert" &&
					this.props.isDetailView
				) {
					value = this.state.fieldProps[fieldName].value;
				}
			}
		} else {
			value = this.state.fieldProps[fieldName].value;
		}
		let data = {};
		data.fieldName = getStringParam(fieldName);
		if (
			this.props.object === constants.OPPORTUNITIES_OBJECT &&
			fieldName === "product" &&
			lookupFieldName === "product_name"
		) {
			data.searchText = getArrayParam(value);
		} else {
			data.searchText = getStringParam(value);
		}
		data.isLookupField = getBooleanParam(isLookupField);
		data.lookupFieldName = getStringParam(lookupFieldName);
		data.lookupObject = getStringParam(lookupObject);
		this.props.getAppDialog(
			true,
			constants.LOOKUP_DIALOG,
			null,
			this.getLookupSelectedRecord,
			data,
			null
		);
	};

	getLookupSelectedRecord = (lookupInfo) => {
		try {
			if (isValidParam(lookupInfo)) {
				this.setLookupInfo(lookupInfo);

				let lookupObject = getStringParam(
					lookupInfo.lookup_object_name
				).toLowerCase();
				let lookupObjectId = getIntParam(OBJECT_TABLEID_MAP[lookupObject]);
				this.state.linkInfoObject[lookupObjectId] = getIntParam(
					lookupInfo.record_id
				);
				this.setLinkInfo();
				this.setState(this.state);
				if (this.isProjectTasks()) {
					//bypass
				} else {
					this.getContactInfoForSelectedRecord(
						getIntParam(lookupInfo.record_id)
					);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> getLookupSelectedRecord()':" + error
			);
		}
		this.props.getAppDialog(false, null, null, null, null, null);
	};

	setLookupInfo = (lookupInfo) => {
		
		try {
			if (isValidParam(lookupInfo)) {
				let lookupObject = getStringParam(lookupInfo.lookup_object_name);
				if (
					this.props.object === constants.OPPORTUNITIES_OBJECT &&
					lookupObject === constants.PRODUCTS_OBJECT &&
					lookupInfo.hasOwnProperty("arrLookupRecordInfo")
				) {
					let recordIds = "";
					let fieldValue = "";
					let fieldName = getStringParam(lookupInfo.field_name);
					let lookupText = getStringParam(lookupInfo.title);
					let lookupFieldName = getStringParam(lookupInfo.lookup_field_name);
					let isLookupField = getBooleanParam(lookupInfo.is_lookup_field);
					let arrLookupRecordInfo = getArrayParam(
						lookupInfo.arrLookupRecordInfo
					);
					arrLookupRecordInfo.forEach((record) => {
						if (isValidParam(record)) {
							let recordId = getIntParam(record.record_id);
							recordId = recordId.toString();
							recordIds =
								recordIds === "" ? recordId : recordIds + "," + recordId;
						}
					});
					if (lookupObject !== "" && recordIds !== null) {
						let lookupInfo = {
							lookup_field_name: lookupFieldName,
							lookup_object_name: lookupObject,
							record_id: recordIds,
							title: lookupText,
						};
						let arrLookupInfo = getArrayParam(this.state.lookupInfo);
						arrLookupInfo = arrLookupInfo.filter((f) => {
							return getStringParam(f.lookup_field_name) !== lookupFieldName;
						});
						arrLookupInfo.push(lookupInfo);
						this.state.lookupInfo = arrLookupInfo;
					}
					if (
						fieldName !== "" &&
						this.state.fieldProps.hasOwnProperty(fieldName) &&
						lookupFieldName !== ""
					) {
						if (isLookupField) {
							let searchParams = {};
							searchParams.fields = [lookupFieldName];
							searchParams.search_field_name = "id";
							searchParams.search_text = recordIds;
							// searchParams.start_index = 0;
							// searchParams.page_size = 1;
							searchParams.click_form = "opportunity_form";
							let searchPromise = Promise.resolve(
								getListData(lookupObject, searchParams)
							);
							searchPromise.then((response) => {
								let records = isValidParam(response)
									? getArrayParam(response.records)
									: [];
								records = records.map((f, i) => {
									let obj = {};
									obj.id = f.id;
									obj.name = f.product_name;
									return obj;
								});
								let fieldProps = this.state.fieldProps;

								fieldProps[fieldName].value = records;
								let productRecordIds = "";
								if (records.length > 0) {
									records.forEach((record) => {
										if (isValidParam(record)) {
											let productRecordId = getIntParam(record.id);
											productRecordIds =
												productRecordIds === ""
													? productRecordId
													: productRecordIds + "," + productRecordId;
										}
									});
								}
								fieldProps["product_id"].value = productRecordIds;
								this.setState({ fieldProps: fieldProps });
							});
						}
					}
				} else {
					let fieldName = getStringParam(lookupInfo.field_name);
					let lookupFieldName = getStringParam(lookupInfo.lookup_field_name);
					let lookupRecordId = getIntParam(lookupInfo.record_id);
					let lookupText = getStringParam(lookupInfo.title);
					let isLookupField = getBooleanParam(lookupInfo.is_lookup_field);
					if (
						this.isProjectTasks() &&
						lookupObject === constants.UNITS_OBJECT
					) {
						lookupObject = constants.ACCOUNTS_OBJECT;
					}
					if (lookupObject !== "" && lookupRecordId > 0) {
						let lookupInfo = {
							lookup_field_name: lookupFieldName,
							lookup_object_name: lookupObject,
							record_id: lookupRecordId,
							title: lookupText,
						};
						let arrLookupInfo = getArrayParam(this.state.lookupInfo);
						arrLookupInfo = arrLookupInfo.filter((f) => {
							return getStringParam(f.lookup_field_name) !== lookupFieldName;
						});
						arrLookupInfo.push(lookupInfo);
						this.state.lookupInfo = arrLookupInfo;
					}

					if (
						fieldName !== "" &&
						this.state.fieldProps.hasOwnProperty(fieldName) &&
						lookupFieldName !== ""
					) {
						if (isLookupField) {
							if (
								this.props.object === constants.CASES_OBJECT &&
								this.props.app.selectedModuleName ===
									constants.MODULE_OPERATIONS
							) {
								let promise = Promise.resolve(getFranchiseInfo(lookupRecordId));
								promise.then((response) => {
									if (isValidParam(response)) {
										let record = getObjectParam(response);
										this.state.fieldProps[fieldName].value = getStringParam(
											record[lookupFieldName]
										);
										this.state.franchiseId = getStringParam(
											record.franchise_id
										);
									}
								});
							} else {
								let searchParams = {};
								if (
									this.props.app.me.projectId ===
										constants.FARMINPEX_PROJECT_ID &&
									this.props.object === constants.CASES_OBJECT &&
									lookupObject === constants.CUSTOM_TABLE1_OBJECT
								) {
									searchParams.fields = [lookupFieldName, "custom_field6"];
								} else if (
									this.props.object === constants.OPPORTUNITIES_OBJECT &&
									lookupObject === constants.CONTACTS_OBJECT
								) {
									searchParams.fields = [lookupFieldName, "company"];
								} else {
									searchParams.fields = [lookupFieldName];
								}

								searchParams.search_field_name = "id";
								searchParams.search_text = lookupRecordId;
								searchParams.start_index = 0;
								searchParams.page_size = 1;

								let searchPromise = Promise.resolve(
									getListData(lookupObject, searchParams)
								);
								searchPromise.then((response) => {
									;
									let records = isValidParam(response)
										? getArrayParam(response.records)
										: [];
									let record = records.length > 0 ? records[0] : {};
									if (Object.keys(record).length > 0) {
										if (
											this.props.object === constants.OPPORTUNITIES_OBJECT &&
											lookupObject === constants.CONTACTS_OBJECT
										) {
											this.state.fieldProps[fieldName].value =
												this.getOpportunityContactName(
													lookupFieldName,
													records[0]
												);
											let tempRecord = records[0];
											let params = {}; 
											params.parent_object_id = 9;
											params.parent_record_id = lookupRecordId;
											params.object_id = 10;
											if (lookupObject === constants.CONTACTS_OBJECT) {
												let tempCompany =
													getStringParam(tempRecord["company"]).length > 0
														? getStringParam(tempRecord["company"])
														: constants.EMPTY;
												this.state.fieldProps["company"].value = tempCompany;
												let promise = Promise.resolve(getLinkRecords(params));
												promise.then((response) => {
													if (
														isValidParam(response) &&
														isValidParam(response.records)
													) {
														
														let records = null;
														records = getArrayParam(response.records);
														if (records.length > 0) {
															let record = null;

															let tempArr = records.filter((f) => {
																return f.company === tempCompany;
															});
															record = tempArr.length > 0 ? tempArr[0] : null;

															if (isValidParam(record)) {
																let id = getIntParam(record.id);
																let company = getStringParam(record.company);

																lookupInfo = {
																	lookup_field_name: "",
																	lookup_object_name: constants.ACCOUNTS_OBJECT,
																	record_id: id,
																};

																let lookupInfo = {
																	lookup_field_name: "company",
																	lookup_object_name: constants.ACCOUNTS_OBJECT,
																	record_id: id,
																	title: company,
																};
																let arrLookupInfo = getArrayParam(
																	this.state.lookupInfo
																);
																arrLookupInfo.push(lookupInfo);
																this.state.lookupInfo = arrLookupInfo;
																this.setState(this.state);
																//console.log(this.state.lookupInfo);
															}
														}
													}
												});
											}
										} else {
											this.state.fieldProps[fieldName].value = getStringParam(
												record[lookupFieldName]
											);
										}
										if (
											this.props.app.me.projectId ===
												constants.FARMINPEX_PROJECT_ID &&
											this.props.object === constants.CASES_OBJECT &&
											lookupObject === constants.CUSTOM_TABLE1_OBJECT
										) {
											this.state.fieldProps["custom_field5"].value =
												getStringParam(record["custom_field6"]);
										}
									} else {
										this.state.fieldProps[fieldName].value = lookupText;
									}
									this.setState(this.state);
								});
							}
						} else {
							this.state.fieldProps[fieldName].value = lookupText;
						}
					}
				}
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> setLookupInfo()':" + error);
		}
	};

	handleDateFocus = (fieldName, event) => {
		let dateTimeDiv = document.getElementsByClassName(
			"dateTimeDiv-" + fieldName
		);
		if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
			dateTimeDiv[0].classList.add("label-top");
		}
	};

	handleDateBlur = (fieldName, event) => {
		let value = getStringParam(this.state.fieldProps[fieldName].value);
		if (value === "") {
			let dateTimeDiv = document.getElementsByClassName(
				"dateTimeDiv-" + fieldName
			);
			if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
				dateTimeDiv[0].classList.remove("label-top");
			}
		}
	};

	onCheckedSalesRep = (event, isNotifySalesRep) => {
		this.setState({ isNotifySalesRep: isNotifySalesRep });
	};

	setFlyingNoteText = (event, value) => {
		this.setState({ flyingNoteText: value });
	};

	setAutoCompleteValue = (fieldName, lookupFieldName, lookupObject, obj) => {
		try {
			lookupObject = getStringParam(lookupObject);
			lookupFieldName = getStringParam(lookupFieldName);
			fieldName = getStringParam(fieldName);
			if (
				this.props.object === constants.OPPORTUNITIES_OBJECT &&
				lookupObject === constants.PRODUCTS_OBJECT
			) {
				this.setAutoCompleteValueForMultipleSelection(
					fieldName,
					lookupFieldName,
					lookupObject,
					obj
				);
			} else {
				obj = getObjectParam(obj);
				let id = getIntParam(obj.id);
				let name = getStringParam(obj.name);

				lookupObject =
					fieldName === "first_name" &&
					this.props.object === constants.CASES_OBJECT
						? constants.CONTACTS_OBJECT
						: lookupObject;

				let lookupInfo = {
					lookup_object_name: lookupObject,
					lookup_field_name: lookupFieldName,
					record_id: id,
					title: name,
				};
				if (
					this.props.object === constants.CASES_OBJECT &&
					this.props.app.selectedModuleName === constants.MODULE_OPERATIONS
				) {
					this.state.fieldProps[lookupFieldName].value = getStringParam(
						obj[lookupFieldName]
					);
					this.state.franchiseId = getStringParam(obj.franchise_id);
				}
				this.setLookupInfo(lookupInfo);

				if (this.props.object !== constants.CASES_OBJECT) {
					let parentTableId = OBJECT_TABLEID_MAP[lookupObject];
					this.state.linkInfoObject[parentTableId] = id;
					this.setLinkInfo();
				}
				if (
					fieldName === "first_name" &&
					this.props.object === constants.CASES_OBJECT
				) {
					this.state.fieldProps["first_name"].value = getStringParam(
						obj.first_name
					).trim();
					this.state.fieldProps["last_name"].value = getStringParam(
						obj.last_name
					).trim();
					this.state.fieldProps["from_email"].value = getStringParam(
						obj.email
					).trim();
					this.state.fieldProps["phone"].value = getStringParam(
						obj.phone
					).trim();
					this.setState(this.state);
				} else {
					this.getContactInfoForSelectedRecord(id);
				}
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setAutoCompleteValue()':" + error
			);
		}
	};
	setAutoCompleteValueForMultipleSelection = (
		fieldName,
		lookupFieldName,
		lookupObject,
		records
	) => {
		if (
			this.props.object === constants.OPPORTUNITIES_OBJECT &&
			lookupObject === constants.PRODUCTS_OBJECT &&
			lookupFieldName === "product_name"
		) {
			let arrLookupRecordInfo = getArrayParam(records);
			let recordIds = "";
			arrLookupRecordInfo.forEach((record) => {
				if (isValidParam(record)) {
					let recordId = getIntParam(record.id);
					recordId = recordId.toString();
					recordIds = recordIds === "" ? recordId : recordIds + "," + recordId;
				}
			});
			if (lookupObject !== "" && recordIds !== null) {
				let lookupInfo = {
					lookup_field_name: lookupFieldName,
					lookup_object_name: lookupObject,
					record_id: recordIds,
				};
				let arrLookupInfo = getArrayParam(this.state.lookupInfo);
				arrLookupInfo = arrLookupInfo.filter((f) => {
					return getStringParam(f.lookup_field_name) !== lookupFieldName;
				});
				arrLookupInfo.push(lookupInfo);
				this.state.lookupInfo = arrLookupInfo;
				let fieldProps = this.state.fieldProps;

				fieldProps[fieldName].value = records;
				fieldProps["product_id"].value = recordIds;
				// let autoCompleteDataSource =  this.state.autoCompleteDataSource;
				// autoCompleteDataSource[fieldName] = records;
				this.setState({ fieldProps: fieldProps });
			}
		}
	};
	filterAutoCompleteSearchText = (searchText, key) => {
		searchText = getStringParam(searchText).toLowerCase();
		key = getStringParam(key).toLowerCase();
		return key.indexOf(searchText) > -1 ? true : false;
	};

	getContactInfoForSelectedRecord(recordId) {
		try {
			if (this.props.object === constants.CASES_OBJECT) {
				let setMenuItemsAtField = "fullname";
				setMenuItemsAtField =
					this.props.object === constants.CASES_OBJECT
						? "first_name"
						: setMenuItemsAtField;

				let menuItems = [];
				let params = {};
				params.object = "accounts";
				params.record_id = recordId;
				params.child_table_id = OBJECT_TABLEID_MAP[constants.CONTACTS_OBJECT];
				if (this.props.object === constants.CASES_OBJECT) {
					params.is_get_primary_contact = true;
				}

				let contactName = "";
				let fullName = "";

				let promise = Promise.resolve(getContactInfoForLookup(params));
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response)) {
							let childRecordArr = getArrayParam(response.records);
							sortArrayObjectByProperty(childRecordArr, "value");
							if (childRecordArr.length > 0) {
								if (this.props.object === constants.CASES_OBJECT) {
									let primaryContact = getObjectParam(response.primary_contact);
									if (isValidParam(primaryContact)) {
										let childRecordId = getIntParam(primaryContact.id);
										fullName = getStringParam(primaryContact.value).trim();
										let firstName = getStringParam(
											primaryContact.first_name
										).trim();
										let lastName = getStringParam(
											primaryContact.last_name
										).trim();
										let email = getStringParam(primaryContact.email);
										let phone = getStringParam(primaryContact.phone);
										fullName = fullName === "" ? "Empty" : fullName;
										this.state.fieldProps[setMenuItemsAtField].value =
											firstName;
										this.state.fieldProps["last_name"].value = lastName;
										this.state.fieldProps["from_email"].value = email;
										this.state.fieldProps["phone"].value = phone;
										let lookupInfo = {
											lookup_field_name: "",
											lookup_object_name: constants.CONTACTS_OBJECT,
											record_id: childRecordId,
										};
										this.setLookupInfo(lookupInfo);
									}

									let options = [];
									childRecordArr.forEach((obj, i) => {
										obj.name = obj.value;
										options.push(obj);
									});
									this.state.fieldProps[setMenuItemsAtField].list_values =
										options;
									this.setState({ fieldProps: this.state.fieldProps });
								} else {
									map(childRecordArr, (childObject, index) => {
										if (isValidParam(childObject)) {
											let tempMenuObj = {};
											tempMenuObj.childRecordId = getIntParam(childObject.id);
											tempMenuObj.fullName = getStringParam(
												childObject.value
											).trim();
											tempMenuObj.firstName = getStringParam(
												childObject.first_name
											).trim();
											tempMenuObj.lastName = getStringParam(
												childObject.last_name
											).trim();
											tempMenuObj.email = getStringParam(childObject.email);
											tempMenuObj.phone = getStringParam(childObject.phone);
											tempMenuObj.key = getIntParam(childObject.id);
											tempMenuObj.value = fullName;
											tempMenuObj.id = getIntParam(childObject.id);
											tempMenuObj.primaryText = (
												<div
													style={{
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														overflow: "hidden",
														maxWidth: "300px",
													}}
													title={fullName}
												>
													{fullName}
												</div>
											);
											menuItems.push(tempMenuObj);

											if (index === 0) {
												fullName = fullName === "" ? "Empty" : fullName;
												this.state.fieldProps[setMenuItemsAtField].value =
													fullName;
											}
										}
									});
									// this.props.updateAttendee(recordId);
									this.state.attendeeRecordId = recordId;
									this.state.listValueMenuItems[setMenuItemsAtField] =
										menuItems;
									this.setState({
										listValueMenuItems: this.state.listValueMenuItems,
										attendeeRecordId: recordId,
									});
								}
							}
						}
					});
				}
			}
		} catch (error) {
			console.error(
				"Error in 'requiredForm.js -> getContactInfoForSelectedRecord()':" +
					error
			);
		}
	}

	setLookupFieldValue = (
		fieldName,
		lookupFieldName,
		lookupObject,
		option,
		input,
		params
	) => {
		input = getStringParam(input);

		if (
			fieldName === "company" &&
			this.props.object === constants.CASES_OBJECT &&
			this.state.fieldProps[fieldName].value !== input
		) {
			this.state.fieldProps["first_name"].value = "";
			this.state.fieldProps["last_name"].value = "";
			this.state.fieldProps["from_email"].value = "";
			this.state.fieldProps["phone"].value = "";
		}
		this.state.fieldProps[fieldName].value = input;
		if (input.length > 2) {
			if (
				this.props.object === constants.CASES_OBJECT &&
				this.props.app.selectedModuleName === constants.MODULE_OPERATIONS
			) {
				let searchObject = getStringParam(lookupObject).toLowerCase();
				if (
					lookupFieldName === "company" &&
					searchObject === constants.ACCOUNTS_OBJECT
				) {
					this.setAutoCompleteValuesForFranchise(input);
				}
			} else if (
				fieldName === "first_name" &&
				this.props.object === constants.CASES_OBJECT
			) {
				let listValues = getArrayParam(
					this.state.fieldProps[fieldName].list_values
				);
				let options = listValues.filter((f) => {
					return f.name.toLowerCase().indexOf(input.toLowerCase()) > -1;
				});
				this.state.autoCompleteDataSource[fieldName] = options;
				this.setState(this.state);
			} else {
				lookupFieldName = getStringParam(lookupFieldName);
				let params = {};
				params.field_name = lookupFieldName;
				params.object = getStringParam(lookupObject).toLowerCase();
				params.search_text = input;

				var promise = Promise.resolve(getLookupAutocompleteInfo(params));
				if (isValidParam(promise)) {
					promise.then((response) => {
						let options = getArrayParam(response);
						sortArrayObjectByProperty(options, "name");
						if (options.length > 0) {
							this.state.autoCompleteDataSource[fieldName] = options;
						}
						this.setState(this.state);
					});
				}
			}
		} else {
			this.state.autoCompleteDataSource[fieldName] = [];
			this.setState(this.state);
		}
	};

	setAutoCompleteValuesForFranchise = (inputText) => {
		try {
			let params = {};
			params.fields = [
				"company",
				"first_name",
				"last_name",
				"email",
				"phone",
				"franchise_id",
			];
			params.query_name = "Units";
			params.query_type = "query";
			params.search_field_name = "company";
			params.search_text = inputText;
			let searchPromise = Promise.resolve(
				getListData(constants.ACCOUNTS_OBJECT, params)
			);
			searchPromise.then((response) => {
				if (isValidParam(response)) {
					let records = getArrayParam(response.records);
					let options = records.map((m) => {
						return { ...m, name: m.company };
					});
					sortArrayObjectByProperty(options, "name");
					this.state.autoCompleteDataSource["company"] = options;
					this.setState(this.state);
				}
			});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setAutoCompleteValuesForFranchise()':" +
					error
			);
		}
	};

	handleFlatpkrDateChange = (fieldName, date) => {
		try {
			if (isValidParam(date) && date.length > 0) {
				let selectedDate = new Date(date[0]);
				let dateValue = new Date(
					selectedDate.getFullYear(),
					selectedDate.getMonth(),
					selectedDate.getDate(),
					selectedDate.getHours(),
					selectedDate.getMinutes()
				);
				const momentDate = moment(dateValue);
				if (moment !== null && moment !== "") {
					if (
						this.state.fieldProps[fieldName].fieldType ===
						constants.FIELD_TYPE_DATE
					) {
						this.state.fieldProps[fieldName].value = momentDate.format(
							dateFormat[this.props.app.me.date_format]
						);
					} else if (
						this.state.fieldProps[fieldName].fieldType ===
						constants.FIELD_TYPE_DATE_TIME
					) {
						this.state.fieldProps[fieldName].value = momentDate.format(
							dateTimeFormat[this.props.app.me.date_format]
						);
					}
					this.state.fieldProps[fieldName].helperText = "";
				} else {
					this.state.fieldProps[fieldName].value = "";
				}
			} else {
				this.state.fieldProps[fieldName].value = "";
			}
			this.state.fieldProps[fieldName].helperText = "";
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> handleFlatpkrDateChange()':" + error
			);
		}
		this.setState({ fieldProps: this.state.fieldProps });
	};

	unescapeHTML = (html) => {
		var escapeEl = document.createElement("textarea");
		escapeEl.innerHTML = html;
		return escapeEl.textContent;
	};

	addToThisList = (fieldName, event) => {
		try {
			let value = this.state.fieldProps[fieldName].value;
			value = getStringParam(value).trim();
			if (value !== "") {
				if (fieldName === "source") {
					let promise = SFFormActions.checkDuplicateSourceName(value);
					if (isValidParam(promise)) {
						promise.then((response) => {
							this.saveNewListValue(
								fieldName,
								value,
								getBooleanParam(response)
							);
						});
					}
				} else {
					let field = this.state.fields.filter((f) => {
						return f.name === fieldName;
					});
					if (field.length > 0) {
						let listValues = getArrayParam(field[0].list_values);
						let tempValues = listValues.filter((f) => {
							return f.value.toLowerCase() === value.toLowerCase();
						});
						this.saveNewListValue(
							fieldName,
							value,
							tempValues.length > 0 ? true : false
						);
					}
				}
			} else {
				let tempFields = this.state.fields.filter((f) => {
					return f.name === fieldName;
				});
				let fieldValue =
					getIntParam(this.props.recordId) > 0 &&
					isValidParam(this.state.record)
						? getStringParam(this.state.record[fieldName])
						: "";
				fieldValue =
					fieldValue === "" && tempFields.length > 0
						? getStringParam(tempFields[0].default_value)
						: fieldValue;
				this.state.fieldProps[fieldName].isAddToThisList = false;
				this.state.fieldProps[fieldName].value = fieldValue;
				this.setState(this.state);
			}
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> addToThisList()':" + error);
		}
	};

	saveNewListValue = (fieldName, value, isExist) => {
		try {
			if (getBooleanParam(isExist)) {
				this.props.showCustomSnackBar(
					"Value already exists in List",
					styles.snackbarBodyStyleError,
					styles.snackBarStyleTop
				);
			} else if (fieldName === "source" || fieldName === "note_type") {
				let promise = SFFormActions.saveListFieldName(fieldName, value);
				if (isValidParam(promise)) {
					promise.then((response) => {
						if (isValidParam(response.data)) {
							let fieldObject = [];
							if (fieldName === "source") {
								fieldObject.push(constants.CONTACTS_OBJECT);
								fieldObject.push(constants.ACCOUNTS_OBJECT);
							} else if (fieldName === "note_type") {
								fieldObject.push(constants.NOTE_OBJECT);
							}
							if (fieldObject.length > 0) {
								fieldObject.forEach((type) => {
									SFFormActions.addSourceOnRedux(type, fieldName, value);
								});
							}
							this.state.fieldProps[fieldName].isAddToThisList = false;
							this.state.fieldProps[fieldName].value = value;

							this.state.fields.forEach((field) => {
								if (field.name === fieldName) {
									field.list_values.push({ id: value, value: value });
									if (fieldName === "note_type") {
										sortArrayObjectByProperty(field.list_values, "value");
									}
									this.setListValueMenuItems(field);
								}
							});
							this.setState(this.state);
						}
					});
				}
			} else {
				let field = this.state.fields.filter((f) => {
					return f.name === fieldName;
				});
				if (field.length > 0) {
					let values = getArrayParam(field[0].list_values);
					let listValues = [];
					values.forEach((field) => {
						if (field.value !== "") {
							listValues.push(field.value);
						}
					});
					listValues.push(value);
					let promise = SFFormActions.saveListValues(
						this.props.object,
						field[0].id,
						listValues
					);
					if (isValidParam(promise)) {
						promise.then((response) => {
							this.state.fieldProps[fieldName].isAddToThisList = false;
							this.state.fieldProps[fieldName].value = value;

							this.state.fields.forEach((field) => {
								if (field.name === fieldName) {
									field.list_values.push({ id: value, value: value });
									this.setListValueMenuItems(field);
								}
							});

							this.setState(this.state);
						});
					}
				}
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> saveNewListValue()':" + error
			);
		}
	};

	getDropDownComponetForTextField = (field, fieldLabel) => {
		try {
			fieldLabel = getStringParam(fieldLabel);
			if (isValidParam(field)) {
				let searchIconpaddingRight = "-15px";
				let menuItems = getArrayParam(
					this.state.listValueMenuItems[field.name]
				);
				let object = this.props.object;
				if (object !== constants.PAYMENTS_OBJECT) {
					let tempVal =
						this.state.fieldProps[field.name].value === ""
							? field.label
							: this.state.fieldProps[field.name].value;
					menuItems = menuItems.filter((f) => {
						return f.props.fullName === tempVal;
					});
				}
				let value = menuItems.length > 0 ? menuItems[0].id : null;
				let defaultValue =
					this.state.fieldProps[field.name].value === ""
						? field.default_value === "" && field.list_values.length > 0
							? field.list_values[0].value
							: ""
						: this.state.fieldProps[field.name].value;
				defaultValue =
					object === constants.PAYMENTS_OBJECT &&
					this.state.fieldProps[field.name].value === ""
						? menuItems[0].props.id
						: defaultValue;
				this.state.fieldProps[field.name].value = defaultValue;

				if (this.props.object === constants.PAYMENTS_OBJECT) {
					searchIconpaddingRight = "-13px";
				}
				return (
					<div>
						<FormControl
							variant="outlined"
							style={{
								minWidth: "100%",
								height: "40px",
								cursor: "pointer",
								border: "0px solid red",
								marginTop: "7px",
								marginBottom: "7px",
							}}
						>
							<InputLabel id="select-label">{fieldLabel}</InputLabel>
							<Select
								labelId="select-label"
								label={fieldLabel}
								style={{
									height: "40px",
								}}
								id={field.name}
								key={field.id + field.name}
								className={"sf-fields-bg"}
								onChange={(e) =>
									this.setFieldValue(field.name, e, e.currentTarget.id)
								}
								value={defaultValue}
							>
								{this.state.listValueMenuItems[field.name]?.map(
									(listValue, listValueIndex) => {
										return (
											<MenuItem
												id={listValue.id}
												key={listValue.id}
												value={listValue.value}
												title={listValue.value}
											>
												{listValue.primaryText}
											</MenuItem>
										);
									}
								)}
							</Select>
						</FormControl>
						{field.is_lookup_field && (
							<span
								style={{
									display: "inline-block",
									border: "0px solid blue",
									marginTop: "0px",
									marginRight: searchIconpaddingRight,
								}}
								title={getLocalizedStrings().label.COMMON.SEARCH}
								className="search-field-icon fa fa-search sf-sffrom-search "
								onClick={this.openLookup.bind(
									this,
									field.name,
									field.is_lookup_field,
									field.lookup_field_name,
									field.lookup_object
								)}
							></span>
						)}
					</div>
				);
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> getDropDownComponetForTextField()':" +
					error
			);
		}
	};

	isAutoFocus = (fieldName) => {
		try {
			fieldName = getStringParam(fieldName);
			return this.state.autoFocusFieldName === fieldName ? true : false;
		} catch (error) {
			console.error("Error in 'RequiredForms.js -> isAutoFocus()':" + error);
		}
	};

	getFieldsForRender = () => {
		let fields = null;
		try {
			fields = getArrayParam(this.state.fields);
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> getFieldsForRender()':" + error
			);
		}
		return fields;
	};

	openAssignmentRuleDrawer = () => {
		let tab = {
			item: this.state.object,
			label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
			object: "assignmentrules",
			imgName: "fas fa-cog",
			url: "/setting/module",
			type: TYPE_LIST_VIEW,
		};
		tab.info = { filter: { id: 0, name: "Assignment Rule", type: "" } };
		tab.info.selectedItem = {
			id: 0,
			name: "assignmentrules",
			label: getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES,
			type: "menu-item",
			object: "assignmentrules",
		};
		addTab(tab, true);
		/*let drawerName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;

        let obj = this.props.object;
        let data = {};
        data.object = constants.ASSIGNMENT_RULES;
        data.id = null;
        data.table = obj;
        data.mode = constants.NEW;
        data.callFrom = 'Assignment Rules';

        this.props.getAppDrawer(true, drawerName, constants.NEW, data, null);
        */
	};
	handleTaskStartDateChanges = (value) => {
		try {
			//this.state.taskStartDateFieldChanged = true;
			this.state.taskStartDateFieldName = value;
		} catch (error) {
			console.error(
				"error in RequiredForms.js ->'handleTaskStartDateChanges()'" + error
			);
		}
	};

	isProjectTasks = () => {
		let isProjectTask = false;
		try {
		} catch (error) {
			console.error("error in RequiredForms.js ->'isProjectTasks()'" + error);
		}
		return isProjectTask;
	};

	getProjectDepTasksList = (parentObject) => {
		let tasksList = [];
		try {
			let params = {
				record_id: getObjectParam(this.props.parentDetails).parent_record_id,
				task_id: this.props.recordId,
			};
			var promise = Promise.resolve(
				getProjectDepTasksList(parentObject, params)
			);
			if (isValidParam(promise)) {
				promise.then((response) => {
					if (isValidParam(response) && getArrayParam(response).length > 0) {
						let tasks = getArrayParam(response);
						tasks.map((task) => {
							let taskObj = {};
							taskObj.value = task.id;
							taskObj.label = task.name;
							taskObj.title = task.name;
							tasksList.push(taskObj);
						});
						this.setState({ tasksList: tasksList });
					}
				});
			}
		} catch (error) {
			console.error(
				"error in RequiredForms.js ->'getProjectDepTasksList()'" + error
			);
		}
	};

	projectDepTaskFields = () => {
		let projectTaskField = [
			{ id: 1, name: "days_after", type: "integer" },
			{ id: 2, name: "task_start_date", type: constants.FIELD_TYPE_DATE_TIME },
			{ id: 3, name: "t_dep_task_id", type: "text" },
		];
		try {
			if (isValidParam(this.state.taskStartDateFieldName)) {
				projectTaskField = projectTaskField.filter((f) => {
					if (this.state.taskStartDateFieldName === "Task Dependent") {
						return f.name === "days_after" || f.name === "t_dep_task_id";
					} else if (this.state.taskStartDateFieldName === "Date") {
						return f.name === "task_start_date";
					}
				});
				if (isValidParam(projectTaskField) && projectTaskField.length > 0) {
					projectTaskField.forEach((field) => {
						let taskObj = {};
						if (
							field.name === "days_after" ||
							field.name === "task_start_date" ||
							field.name === "t_dep_task_id"
						) {
							taskObj.id = field.id;
							taskObj.label = getMultiLingualLabel(field.name);
							taskObj.name = field.name;
							taskObj.fieldType = field.type;
							if (field.name === "days_after") {
								taskObj.value = "";
							} else if (field.name === "task_start_date") {
								let currentTime = moment().tz(
									timeZone[this.props.app.me.timezone]
								);
								let defaultDate = currentTime.format(
									dateTimeFormat[this.props.app.me.date_format]
								);
								taskObj.value = defaultDate;
							}
						}
						if (isValidParam(taskObj) && Object.keys(taskObj).length > 0) {
							this.state.fieldProps[field.name] = taskObj;
							taskObj.field_type = field.type;
							if (
								field.name === "days_after" ||
								field.name === "t_dep_task_id"
							) {
								taskObj.is_hidden_field = false;
								taskObj.is_required_field = true;
							}
							this.state.fields.push(taskObj);
						}
					});
				}
			}
		} catch (error) {
			console.error(
				"error in RequiredForms.js ->'projectDepTaskFields()'" + error
			);
		}
	};

	createProjectDepTaskFields = (fieldName) => {
		let element = [];
		try {
			if (isValidParam(fieldName)) {
				if (fieldName === "Task Dependent") {
					if (!isValidParam(this.state.fieldProps.days_after)) {
						this.projectDepTaskFields();
					}
					if (isValidParam(this.state.fieldProps.task_start_date)) {
						delete this.state.fieldProps.task_start_date;
						this.state.fields = this.state.fields.filter(
							(f) => f.name !== "task_start_date"
						);
					}
					let tempDafFormFieldContainerStyle = {
						...styles.sfFormFieldContainer,
					};
					let selectTaskStyle = {};
					if (
						isValidParam(this.state.fieldProps.days_after) &&
						isValidParam(this.state.fieldProps["days_after"].helperText) &&
						this.state.fieldProps["days_after"].helperText !== ""
					) {
						tempDafFormFieldContainerStyle = {
							...tempDafFormFieldContainerStyle,
							border: "1px solid #f75723",
						};
					}
					if (
						isValidParam(this.state.fieldProps.t_dep_task_id) &&
						isValidParam(this.state.fieldProps["t_dep_task_id"].helperText) &&
						this.state.fieldProps["t_dep_task_id"].helperText !== ""
					) {
						selectTaskStyle = { border: "1px solid #f75723" };
					}

					element.push(
						<div
							className="col-sm-12"
							id={"days-after-field"}
							key={"days-after-field"}
						>
							<FormControl
								style={{ width: "100%" }}
								className="test"
								noValidate
								autoComplete="off"
							>
								<TextField
									variant="outlined"
									key={"days-after-field"}
									label={
										getMultiLingualLabel("days_after") +
										getLocalizedStrings().label.COMMON.REQUIRED
									}
									fullWidth={true}
									name={getMultiLingualLabel("days_after")}
									value={this.state.fieldProps["days_after"].value}
									title={this.state.fieldProps["days_after"].value}
									onChange={(e) =>
										this.setFieldValue("days_after", e, e.target.value)
									}
									style={tempDafFormFieldContainerStyle}
									className="srchico"
									autoComplete="new-password"
								/>
							</FormControl>
						</div>
					);
					element.push(
						<div className="col-sm-12" id={"task-field"} key={"task-field"}>
							<div style={{ marginBottom: "12px" }}>
								<FormControl
									style={{ width: "100%", height: "40px" }}
									className="test"
									noValidate
									autoComplete="off"
								>
									{this.state.tasksList && (
										<Autocomplete
											value={this.state.fieldProps["t_dep_task_id"].value}
											id={"multi-select"}
											key={"multi-select"}
											options={this.state.tasksList}
											onChange={(event, newValue) =>
												this.setFieldValue("t_dep_task_id", event, newValue)
											}
											getOptionLabel={(option) => option.label}
											// renderOption={(option) => {
											//     return (
											//         <div title={option.label}>
											//            {option.label}
											//         </div>
											//     );
											//   }}
											renderTags={(value, getTagProps) =>
												value.map((option, index) => (
													<Chip
														label={option.label}
														title={option.label}
														{...getTagProps({ index })}
													/>
												))
											}
											style={{ ...selectTaskStyle, width: 300 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label={
														getLocalizedStrings().label.APPOINTMENTS_TASKS
															.DEP_TASK_NAME +
														getLocalizedStrings().label.COMMON.REQUIRED
													}
													variant="outlined"
													disableClearable={false}
													className={"sf-fields-bg"}
												/>
											)}
										/>
									)}
								</FormControl>
							</div>
						</div>
					);
				} else if (fieldName === "Date") {
					let currentTime = moment().tz(timeZone[this.props.app.me.timezone]);
					let defaultDate = currentTime.format(
						dateTimeFormat[this.props.app.me.date_format]
					);
					let dateValue = null;
					if (!isValidParam(this.state.fieldProps.task_start_date)) {
						this.projectDepTaskFields();
					}
					if (
						isValidParam(this.state.fieldProps["task_start_date"].value) &&
						this.state.fieldProps["task_start_date"].value !== ""
					) {
						let momentObj = moment(
							this.state.fieldProps["task_start_date"].value
						);
						dateValue = momentObj.format(
							dateTimeFormat[this.props.app.me.date_format]
						);
					}
					if (isValidParam(this.state.fieldProps.days_after)) {
						delete this.state.fieldProps.days_after;
						this.state.fields = this.state.fields.filter(
							(f) => f.name !== "days_after" && f.name !== "t_dep_task_id"
						);
					}
					let HHFormat = new RegExp("HH");
					let datePickerOptions = {
						enableTime: true,
						noCalendar: false,
						dateFormat: dateTimeFormatFlatPicker[this.props.app.me.date_format],
						minuteIncrement: 1,
						time_24hr: HHFormat.test(this.props.app.me.date_format)
							? true
							: false,
					};
					element.push(
						<div
							className="col-sm-12"
							id={"task-date-field"}
							key={"task-date-field"}
						>
							<div className={"sf-form-field label-top"} id="dateholder">
								<label id={"start-date-datelbl"} className="date-label">
									{getLocalizedStrings().label.CALENDAR.DATE}
								</label>
								<Flatpickr
									style={{
										paddingLeft: "7px",
										marginBottom: "12px",
										height: "42px",
										width: "100%",
										border: "1px solid #dddddd",
										backgroundColor: "#ffffff",
										cursor: "default",
									}}
									onChange={this.handleFlatpkrDateChange.bind(
										this,
										"task_start_date"
									)}
									value={
										this.state.fieldProps["task_start_date"].value === ""
											? Date.parse(defaultDate)
											: Date.parse(dateValue)
									}
									options={datePickerOptions}
									className="flatpickr"
								/>
							</div>
						</div>
					);
				}
			}
		} catch (error) {
			console.error(
				"error in RequiredForms.js ->'createProjectDepTaskFields()'" + error
			);
		}
		return element;
	};

	projectTasksFilter = (fields) => {
		try {
			if (isValidParam(this.state.taskStartDateFieldName)) {
				fields = fields.filter(
					(f) =>
						f.name !== "task_start_date" &&
						f.name !== "days_after" &&
						f.name !== "t_dep_task_id"
				);
			}
		} catch (error) {
			console.error(
				"error in RequiredForms.js ->'projectTasksFilter()'" + error
			);
		}
		return fields;
	};

	focusElementByName = (fieldName) => {
		let inputElement = null;
		try {
			fieldName = getStringParam(fieldName);
			if (
				fieldName !== "" &&
				isValidParam(this.refs["field-ref-" + fieldName])
			) {
				let field = this.state.fieldProps[fieldName];
				inputElement = this.refs["field-ref-" + fieldName];
				if (field.fieldType === constants.FIELD_TYPE_LIST) {
					inputElement =
						inputElement.refs["textField-ref-" + field.id + fieldName];
				} else if (
					field.fieldType === constants.FIELD_TYPE_DATE ||
					field.fieldType === constants.FIELD_TYPE_DATE_TIME
				) {
					inputElement = inputElement.node;
				}
				if (isValidParam(inputElement)) {
					inputElement.focus();
				}
			}
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> focusElementByName()':" + error
			);
		}
	};

	getOpportunityContactName = (lookupFieldName, record) => {
		let contactName = "";
		let lookupInfo = this.state.lookupInfo;
		try {
			if (isValidParam(record) && getStringParam(lookupFieldName).length > 0) {
				let company =
					getStringParam(record["company"]).length > 0
						? getStringParam(record["company"])
						: constants.EMPTY;

				contactName = record[lookupFieldName];
				//contactName = (getStringParam(contactName).length > 0) ? contactName + " " + record['last_name'] : record['last_name'];
				contactName = contactName + " " + "(" + company + ")";
				lookupInfo.map((f, i) => {
					if (f.lookup_object_name === constants.CONTACTS_OBJECT) {
						//f.last_name = getStringParam(record['last_name']);
						f.company = getStringParam(record["company"]);
					}
				});
			}
		} catch (error) {
			console.log(
				"Error in 'RequiredForms.js -> getOpportunityContactName()':" + error
			);
		}
		return contactName;
	};

	rearrangeFields = (fields) => {
		try {
			let i = 0;
			if (isValidParam(fields) && getArrayParam(fields).length > 0) {
				if (
					fields.indexOf("workflow_name") === i &&
					fields.indexOf("t_status") === i + 1
				) {
					let workflowName = fields.splice(0, 1)[0];
					let status = fields.splice(0, 1)[0];
					fields.splice(2, 0, workflowName);
					fields.splice(3, 0, status);
				}
			}
		} catch (error) {
			console.log("Error in 'RequiredForms.js -> rearrangeFields()':" + error);
		}
		return fields;
	};

	setOppotunityContactName = (fieldProps) => {
		let value = "";
		let record = null;
		try {
			fieldProps = isValidParam(fieldProps)
				? fieldProps
				: this.state.fieldProps;
			record = this.state.record;
			if (
				isValidParam(fieldProps) &&
				isValidParam(record) &&
				(record.hasOwnProperty("first_name") ||
					record.hasOwnProperty("last_name"))
			) {
				value =
					getStringParam(record.first_name).length > 0
						? getStringParam(record.first_name)
						: "";
				if (value.length > 0) {
					if (getStringParam(record.last_name).length > 0) {
						value = value + " " + getStringParam(record.last_name);
					}
				} else {
					value =
						getStringParam(record.last_name).length > 0
							? getStringParam(record.last_name)
							: "";
				}
				if (value === "") {
					//value = "(" + constants.EMPTY + ")";
				}
				fieldProps["contact_name"].value = value;
			}
			this.state.fieldProps = fieldProps;
			this.setState({ fieldProps: this.state.fieldProps });
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> setOppotunityContactName()':" + error
			);
		}
	};

	checkDuplicateProduct = (object, params, recordId) => {
		try {
			let productName = isValidParam(this.state.fieldProps["product_name"])
				? getStringParam(this.state.fieldProps["product_name"].value)
						.trim()
						.toLowerCase()
				: "";
			let paramObject = {};
			paramObject.product_name = getStringParam(productName);
			if (this.props.appContainer.drawerProps.actionType === constants.COPY) {
				paramObject.record_id =
					getIntParam(params.id) === 0 ? getIntParam(params.id) : 0;
			} else {
				paramObject.record_id = getIntParam(recordId);
			}
			let promise = checkDuplicateProductName(paramObject);
			promise.then((response) => {
				if (isValidParam(response)) {
					let isDuplicateProduct = getBooleanParam(response.is_duplicate);
					if (isDuplicateProduct) {
						this.props.showCustomSnackBar(
							getLocalizedStrings().message.PRODUCTS.DUPLICATE_PRODUCT,
							styles.snackbarBodyStyleError,
							styles.snackBarStyleTop
						);
						return false;
					} else {
						this.processSaveData(object, params, recordId);
					}
				}
			});
		} catch (error) {
			console.error(
				"Error in 'RequiredForms.js -> checkDuplicateProduct()':" + error
			);
		}
	};
	getSelectedValueAutocompleteByFieldName = (type, field) => {
		let valueFieldProp = this.state.fieldProps[field.name];
		let value = null;
		if (!type) {
			value =
				valueFieldProp !== null && valueFieldProp !== undefined
					? valueFieldProp.value
					: {};
		} else {
			value =
				valueFieldProp !== null &&
				valueFieldProp !== undefined &&
				valueFieldProp !== ""
					? valueFieldProp.value
					: [];
		}
		return value;
	};

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirectUrl} />;
		}
		let fields = this.getFieldsForRender();
		if (this.isProjectTasks()) {
			fields = this.projectTasksFilter(fields);
		}
		let rowComponents = null;
		let component = null;
		let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
		let sfFormFieldTextAreaStyle = { marginTop: "10px", height: "90%" };
		let sfFormFieldTextAreaInputSyle = { marginTop: "10px", overflowY: "auto" };
		let sfFormFieldStyle = {
			width: "100%",
			backgroundColor: "#fff",
			border: "1px solid #dddddd",
			marginBottom: "12px",
		};

		let memoFieldRowSize = 3;
		if (this.props.object === constants.NOTE_OBJECT) {
			memoFieldRowSize = 15;
		}
		var autoFocusValue = false;
		let contentHeight = window.innerHeight - 240;
		let top = (contentHeight - 10) / 2;
		let searchIconpaddingRight = "-24px";
		return this.state.mounted ? (
			<div
				style={styles.requiredFormDialog}
				ref={(input) => {
					this.formContainer = input;
				}}
			>
				{this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
					this.props.object !== constants.APPOINTMENTS &&
					this.props.object !== constants.TASKS &&
					this.props.object !== constants.NOTE_OBJECT && (
						<div className="row">
							<div
								className="col-sm-12"
								style={{
									textAlign: "right",
									marginBottom: "12px",
									marginTop: "-10px",
								}}
							>
								{this.getActionButtons()}
							</div>
						</div>
					)}
				{fields.map((field, index) => {
					if (rowComponents === null) {
						rowComponents = [];
					}
					component = null;
					let fieldLabel = null;
					let defaultDate = null;
					fieldLabel = getMultiLingualLabel(field.label);

					if (field.is_required_field) {
						fieldLabel =
							fieldLabel + getLocalizedStrings().label.COMMON.REQUIRED;
					}

					if (field.field_type === constants.FIELD_TYPE_HEADER) {
						component = (
							<div
								style={{
									paddingLeft: "0px",
									height: "30px",
									width: "100%",
									fontSize: "24px",
									color: "#555",
									marginBottom: "12px",
								}}
								id={field.name}
								key={field.id + field.name}
							>
								{/* {fieldLabel} */}
								{getLocalizedStrings().label.COMMON.hasOwnProperty(fieldLabel)
									? getLocalizedStrings().label.COMMON[fieldLabel]
									: fieldLabel}
							</div>
						);
					}

					if (
						field.field_type === constants.FIELD_TYPE_TEXT &&
						(field.is_lookup_field ||
							(field.name === "first_name" &&
								this.props.object === constants.CASES_OBJECT))
					) {
						let autocompleteStyle = {
							backgroundColor: "#fff",
							border: "1px solid #dddddd",
							marginBottom: "12px",
							height: "42px",
						};
						if (
							isValidParam(this.state.fieldProps[field.name].helperText) &&
							this.state.fieldProps[field.name].helperText !== ""
						) {
							autocompleteStyle = {
								...autocompleteStyle,
								border: "1px solid #f75723",
							};
							this.state.fieldProps[field.name].helperText = "";
						}
						let autoDiv = {};
						if (field.is_lookup_field) {
							autoDiv.width = "100%";
						}
						let type =
							this.props.object === constants.OPPORTUNITIES_OBJECT &&
							field.name === "product"
								? true
								: false;
						let value = this.getSelectedValueAutocompleteByFieldName(
							type,
							field
						);
						component = (
							<div style={autoDiv}>
								<Autocomplete
									style={{ width: "100%" }}
									disableClearable
									freeSolo
									filterOptions={(x) => x}
									multiple={type}
									getOptionLabel={(option) =>
										typeof option === "string" ? option : option.name
									}
									disabled={this.state.isPreview ? true : false}
									options={
										isValidParam(
											this.state.autoCompleteDataSource[field.name]
										) && this.state.autoCompleteDataSource[field.name]
											? this.state.autoCompleteDataSource[field.name]
											: []
									}
									value={value}
									renderInput={(params) => (
										<TextField
											{...params}
											label={fieldLabel}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "96%",
												},
											}}
											disabled={this.state.isPreview ? true : false}
											variant="outlined"
											fullWidth
											margin="dense"
											size="small"
											className={"sf-fields-bg"}
										/>
									)}
									onInputChange={this.setLookupFieldValue.bind(
										this,
										field.name,
										field.lookup_field_name,
										field.lookup_object
									)}
									onChange={(e, newValue) =>
										this.setAutoCompleteValue(
											field.name,
											field.lookup_field_name,
											field.lookup_object,
											newValue
										)
									}
								/>
							</div>
						);
					} else if (
						(field.field_type === constants.FIELD_TYPE_TEXT &&
							!field.is_hidden_field) ||
						(field.field_type === constants.FIELD_TYPE_INTEGER &&
							field.name !== "workflow_id" &&
							!field.is_hidden_field) ||
						(field.field_type === constants.FIELD_TYPE_FLOAT &&
							!field.is_hidden_field) ||
						field.field_type === constants.FIELD_TYPE_URL ||
						(field.field_type === constants.FIELD_TYPE_LIST &&
							field.name === "workflow_name" &&
							(this.props.recordId > 0 ||
								this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED))
					) {
						let isReadOnly = field.is_readonly;
						if (
							field.field_type === constants.FIELD_TYPE_LIST &&
							field.name === "workflow_name" &&
							(this.props.recordId > 0 ||
								this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED)
						) {
							isReadOnly = true;
						}
						let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
						if (
							isValidParam(this.state.fieldProps[field.name].helperText) &&
							this.state.fieldProps[field.name].helperText !== ""
						) {
							tempsfFormFieldContainerStyle = {
								...sfFormFieldContainerStyle,
								border: "1px solid #f75723",
							};
						}
						component = (
							<FormControl
								style={{ width: "100%" }}
								className="test"
								noValidate
								autoComplete="off"
							>
								<TextField
									variant="outlined"
									key={field.id}
									disabled={isReadOnly}
									label={fieldLabel}
									InputLabelProps={{
										style: {
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											overflow: "hidden",
											width: "96%",
										},
									}}
									fullWidth={true}
									name={field.name}
									value={this.state.fieldProps[field.name].value}
									onChange={(e) =>
										this.setFieldValue(field.name, e, e.target.value)
									}
									onBlur={this.setFieldValueOnBlur.bind(this, field.name)}
									style={tempsfFormFieldContainerStyle}
									className={
										field.is_lookup_field ? "form-field-search-icon" : ""
									}
									inputprops={{
										maxLength: this.state.fieldProps[field.name].field_length,
									}}
									autoFocus={this.isAutoFocus(field.name)}
									ref={"field-ref-" + field.name}
									autoComplete="new-password"
									margin="dense"
									size="small"
								/>
							</FormControl>
						);
					} else if (field.field_type === constants.FIELD_TYPE_LIST) {
						if (
							field.list_type === constants.LIST_FIELD_TYPE_REGULAR ||
							field.list_type === constants.LIST_FIELD_TYPE_USER ||
							field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL
						) {
							if (
								getBooleanParam(
									this.state.fieldProps[field.name].isAddToThisList
								)
							) {
								let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
								if (
									isValidParam(this.state.fieldProps[field.name].helperText) &&
									this.state.fieldProps[field.name].helperText !== ""
								) {
									tempsfFormFieldContainerStyle = {
										...sfFormFieldContainerStyle,
										border: "1px solid #f75723",
									};
								}
								component = (
									<FormControl
										style={{ width: "100%" }}
										className="test"
										noValidate
										autoComplete="off"
									>
										<TextField
											variant="outlined"
											key={field.id}
											disabled={field.is_readonly}
											label={fieldLabel}
											InputLabelProps={{
												style: {
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
													width: "96%",
												},
											}}
											fullWidth={true}
											name={field.name}
											value={
												this.state.fieldProps[field.name] !== null &&
												this.state.fieldProps[field.name] !== undefined
													? this.state.fieldProps[field.name].value
													: ""
											}
											onChange={(e) =>
												this.setFieldValue(field.name, e, e.target.value)
											}
											onBlur={this.addToThisList.bind(this, field.name)}
											style={tempsfFormFieldContainerStyle}
											className={
												field.is_lookup_field ? "form-field-search-icon" : ""
											}
											inputprops={{
												maxLength:
													this.state.fieldProps[field.name] !== null &&
													this.state.fieldProps[field.name] !== undefined
														? this.state.fieldProps[field.name].field_length
														: 512,
											}}
											autoFocus={this.isAutoFocus(field.name)}
											ref={"field-ref-" + field.name}
											autoComplete="new-password"
											margin="dense"
											size="small"
										/>
									</FormControl>
								);
							} else {
								let isReadonlyField = field.is_readonly;
								if (
									this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK &&
									(field.name === "workflow_name" || field.name === "t_status")
								) {
									isReadonlyField = false;
								}
								if (
									this.props.object === constants.CONTACTS_OBJECT &&
									this.props.openMode ===
										constants.SF_FORM_OPEN_MODE_EXPANDED &&
									field.name === "t_status"
								) {
									isReadonlyField = true;
								}
								let autocompleteStyle = styles.sfFormFieldContainer;
								if (
									isValidParam(this.state.fieldProps[field.name].helperText) &&
									this.state.fieldProps[field.name].helperText !== ""
								) {
									autocompleteStyle = {
										...autocompleteStyle,
										border: "1px solid #f75723",
									};
								}

								let listValueMenuItems = isValidParam(
									this.state.listValueMenuItems[field.name]
								)
									? this.state.listValueMenuItems[field.name]
									: [];
								let autocompleteValue =
									isValidParam(this.state.fieldProps[field.name]) &&
									this.state.fieldProps[field.name] !== null &&
									this.state.fieldProps[field.name].value !== undefined
										? this.state.fieldProps[field.name].value
										: "";

								component = (
									<FormControl style={{ width: "100%" }}>
										{this.state.listValueMenuItems[field.name]?.length > 0 && (
											<Autocomplete
												style={{ width: "100%" }}
												disableClearable
												id={field.id}
												name={field.name}
												options={listValueMenuItems}
												getOptionLabel={(option) =>
													option.primaryText ? option.primaryText : ""
												}
												value={listValueMenuItems.find(
													(v) => v.value === autocompleteValue
												)}
												disabled={field.is_readonly}
												ref={"field-ref-" + field.name}
												renderInput={(params) => (
													<TextField
														id={"field-ref-id-" + field.name}
														label={fieldLabel}
														{...params}
														InputLabelProps={{
															style: {
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
																overflow: "hidden",
																width: "96%",
															},
														}}
														variant="outlined"
														fullWidth
														error={
															this.state.fieldProps[field.name] !== null &&
															this.state.fieldProps[field.name] !== undefined &&
															this.state.fieldProps[field.name].helperText !==
																null &&
															this.state.fieldProps[field.name].helperText !==
																""
																? true
																: false
														}
														inputprops={{
															style: {
																cursor: isReadonlyField
																	? "not-allowed"
																	: "pointer",
															},
															maxlength:
																this.state.fieldProps[field.name] !== null &&
																this.state.fieldProps[field.name] !== undefined
																	? this.state.fieldProps[field.name]
																			.field_length
																	: 512,
															...params.InputProps,
														}}
														margin="dense"
														size="small"
														className={"sf-fields-bg"}
													/>
												)}
												onChange={(event, value) =>
													this.setFieldValue(field.name, event, value.value)
												}
											/>
										)}
									</FormControl>
								);
								// component = <SFAutoComplete
								//     key={'sf-auto-complete' + field.id}
								//     id={field.id}
								//     name={field.name}
								//     isLookup={field.is_lookup_field}
								//     label={fieldLabel}
								//     menuItems={this.state.listValueMenuItems[field.name]}
								//     //disabled={isReadonlyField}
								//     disabled={this.state.isPreview ? true : isReadonlyField ? true : false}
								//     onChange={(event, value) => this.setFieldValue(field.name, event, value)}
								//     value={
								//         this.state.fieldProps[field.name] !== null && this.state.fieldProps[field.name] !== undefined && isValidParam(getLocalizedStrings().label.DEFAULT_NOTE_TYPES[this.state.fieldProps[field.name].value]) ?
								//             getLocalizedStrings().label.DEFAULT_NOTE_TYPES[this.state.fieldProps[field.name].value] :
								//             this.state.fieldProps[field.name] !== null && this.state.fieldProps[field.name] !== undefined ? this.state.fieldProps[field.name].value : ""
								//     }
								//     inputprops={{ maxLength: this.state.fieldProps[field.name] !== null && this.state.fieldProps[field.name] !== undefined ? this.state.fieldProps[field.name].field_length : 512 }}
								//     onBlur={this.setFieldValueOnBlur.bind(this, field.name)}
								//     underlineFocusStyle={styles.textField.underlineFocus}
								//     //floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
								//     inputStyle={styles.textField.input}
								//     menuItemStyle={styles.popoverMenuItem}
								//     style={{ ...autocompleteStyle, width: '100%', marginBottom: '-25px' }}
								//     autoFocus={this.isAutoFocus(field.name)}
								//     ref={'field-ref-' + field.name}
								//     isFullHeight={false}
								//     isModifyList={field.is_modify_list}
								//     fieldType={field.field_type}
								// />
							}
						} else if (
							field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT
						) {
							let tempsfFormFieldContainerStyle = sfFormFieldContainerStyle;
							if (
								isValidParam(this.state.fieldProps[field.name].helperText) &&
								this.state.fieldProps[field.name].helperText !== ""
							) {
								tempsfFormFieldContainerStyle = {
									...sfFormFieldContainerStyle,
									border: "1px solid #f75723",
								};
							}
							sortArrayObjectByProperty(field.list_values, "value");
							component = (
								<FormControl variant="outlined" style={{ width: "100%" }}>
									<InputLabel
										id="sf-requiredforms-simple-select-outlined-label"
										className="sf-requiredforms"
									>
										{fieldLabel}
									</InputLabel>
									<Select
										key={field.id}
										multiple={true}
										label={fieldLabel}
										value={this.state.fieldProps[field.name].selectedListValues}
										title={this.state.fieldProps[field.name].selectedListValues}
										onChange={this.setMultiSelectValue.bind(this, field.name)}
										autoFocus={this.isAutoFocus(field.name)}
										ref={"field-ref-" + field.name}
										disabled={field.is_readonly}
										className={"sf-fields-bg"}
									>
										{field.list_values.map((listValue, listValueIndex) => {
											return (
												<MenuItem
													value={listValue.value}
													id={listValue.id}
													title={listValue.value}
													key={listValue.id}
													insetChildren={true}
													checked={
														this.state.fieldProps[field.name]
															.selectedListValues &&
														this.state.fieldProps[
															field.name
														].selectedListValues.indexOf(listValue.value) > -1
															? true
															: false
													}
													style={styles.popoverMenuItem}
												>
													{listValue.value}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							);
						}
					} else if (
						field.field_type === constants.FIELD_TYPE_DATE_TIME ||
						field.field_type === constants.FIELD_TYPE_DATE
					) {
						let dateTimeFormatValue = null;
						if (field.field_type === constants.FIELD_TYPE_DATE_TIME) {
							dateTimeFormatValue =
								dateTimeFormatFlatPicker[this.props.app.me.date_format];
						} else if (field.field_type === constants.FIELD_TYPE_DATE) {
							dateTimeFormatValue =
								dateFormatFlatPicker[this.props.app.me.date_format];
						}

						let HHFormat = new RegExp("HH");

						let datePickerOptions = {
							enableTime:
								field.field_type === constants.FIELD_TYPE_DATE_TIME
									? true
									: false,
							noCalendar: field.is_readonly ? true : false,
							dateFormat: dateTimeFormatValue,
							minuteIncrement: 1,
							time_24hr: HHFormat.test(this.props.app.me.date_format)
								? true
								: false,
						};

						let flatpickerStyle = {
							paddingLeft: "7px",
							marginBottom: "12px",
							height: "42px",
							width: "100%",
							border: "1px solid #dddddd",
							backgroundColor: "#ffffff",
							cursor: field.is_readonly ? "not-allowed" : "default",
						};
						if (
							isValidParam(this.state.fieldProps[field.name].helperText) &&
							this.state.fieldProps[field.name].helperText !== ""
						) {
							flatpickerStyle = {
								...flatpickerStyle,
								border: "1px solid #f75723",
							};
						}

						component = (
							<div
								className={"sf-form-field dateTimeDiv-" + field.name}
								id="dateholder"
							>
								<label id={field.name + "-datelbl"} className="date-label">
									{fieldLabel}
								</label>

								<Flatpickr
									style={flatpickerStyle}
									onChange={this.handleFlatpkrDateChange.bind(this, field.name)}
									value={
										this.state.fieldProps[field.name].value === ""
											? Date.parse(defaultDate)
											: Date.parse(this.state.fieldProps[field.name].value)
									}
									options={datePickerOptions}
									className="flatpickr"
									disabled={field.is_readonly}
									autoFocus={this.isAutoFocus(field.name)}
									ref={"field-ref-" + field.name}
								/>
							</div>
						);
					} else if (field.field_type === constants.FIELD_TYPE_MEMO) {
						let tempsfFormFieldStyle = sfFormFieldStyle;
						if (
							isValidParam(this.state.fieldProps[field.name].helperText) &&
							this.state.fieldProps[field.name].helperText !== ""
						) {
							tempsfFormFieldStyle = {
								...sfFormFieldStyle,
								border: "1px solid #f75723",
							};
						}
						component = (
							<FormControl
								style={{ width: "100%", marginTop:this.props.object === constants.ISSUES_OBJECT? '8px':'' ,marginBottom:this.props.object === constants.ISSUES_OBJECT? '-8px':''}}
								className="test"
								noValidate
								autoComplete="off"
							>
								<TextField
									variant="outlined"
									key={field.id}
									type="textarea"
									multiline={true}
									rows={memoFieldRowSize}
									label={fieldLabel}
									InputLabelProps={{
										style: {
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											overflow: "hidden",
											width: "96%",
										},
									}}
									textareastyle={sfFormFieldTextAreaStyle}
									disabled={field.is_readonly}
									onChange={(e) =>
										this.setFieldValue(field.name, e, e.target.value)
									}
									style={tempsfFormFieldStyle}
									inputStyle={sfFormFieldTextAreaInputSyle}
									value={this.unescapeHTML(
										this.state.fieldProps[field.name].value
									)}
									inputprops={{
										maxLength: this.state.fieldProps[field.name].field_length,
									}}
									autoFocus={this.isAutoFocus(field.name)}
									ref={"field-ref-" + field.name}
									autoComplete="new-password"
								/>
							</FormControl>
						);
					}
					if (
						this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
						field.col_span === 2 &&
						field.field_type === constants.FIELD_TYPE_HEADER
					) {
						component = (
							<div className="col-sm-12" id={field.name} key={field.name}>
								{component}
							</div>
						);
					} else if (
						this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
						(this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
							field.col_span === 2)
					) {
						let divProps = {};
						if (field.is_lookup_field) {
							divProps.display = "flex";
						}
						component = (
							<div
								className="col-sm-12 "
								id={field.name}
								key={field.name}
								style={divProps}
							>
								{(field.name !== "fullname" ||
									(field.name === "fullname" &&
										!isValidParam(
											this.state.listValueMenuItems[field.name]
										))) &&
									component}
								{field.is_lookup_field && (
									<div style={{ marginLeft: "-33px", zIndex: 1 }}>
										<span
											style={{
												display: "inline-block",
												border: "0px solid blue",
												marginTop:this.props.object === constants.ISSUES_OBJECT? '20px':'',
												marginRight: searchIconpaddingRight,
											}}
											title={getLocalizedStrings().label.COMMON.SEARCH}
											className="search-field-icon fa fa-search sf-sffrom-search "
											onClick={this.openLookup.bind(
												this,
												field.name,
												field.is_lookup_field,
												field.lookup_field_name,
												field.lookup_object
											)}
										></span>
									</div>
								)}

								{/**** START:   Appoinments/Tasks fullName/ Cases first_name */}
								{field.name === "fullname" &&
									this.state.listValueMenuItems[field.name] !== null &&
									this.getDropDownComponetForTextField(field, fieldLabel)}
								{/**** END:   Appoinments/Tasks fullName */}
							</div>
						);
					} else if (
						(this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED &&
							field.col_span === 1) ||
						getStringParam(this.props.openMode).trim() ===
							constants.SF_FORM_OPEN_MODE_CUSTOM
					) {
						component = (
							<div className="col-sm-6" id={field.name} key={field.name}>
								{component}
								{field.is_lookup_field && (
									<span
										className="search-field-icon fa fa-search"
										onClick={this.openLookup.bind(
											this,
											field.name,
											field.is_lookup_field,
											field.lookup_field_name,
											field.lookup_object
										)}
									></span>
								)}
							</div>
						);
					}

					if (
						(field.name !== "workflow_id" &&
							field.name !== "workflow_name" &&
							!field.is_hidden_field) ||
						(field.name === "workflow_name" && this.state.isWorkflowShow) ||
						(field.name === "workflow_name" &&
							this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED)
					) {
						rowComponents.push(component);
					}

					if (
						this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
						this.props.openMode === constants.SF_FORM_OPEN_MODE_CUSTOM ||
						field.col_span === 2 ||
						(field.col_span === 1 &&
							(rowComponents.length === 2 ||
								this.state.fields.length === index + 1))
					) {
						component = (
							<div key={index} className="row">
								{" "}
								{rowComponents}{" "}
							</div>
						);

						rowComponents = null;
						return component;
					}
				})}

				{(this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK ||
					this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED) &&
					isValidParam(this.state.notifySalesRepLabel) &&
					this.state.notifySalesRepLabel !== "" && (
						<div className="row">
							<div className="col-sm-12">
								<FormControlLabel
									control={
										<Checkbox
											color="default"
											key="sales_rep"
											name="sales_rep"
											checked={this.state.isNotifySalesRep}
											value={this.state.isNotifySalesRep}
											onChange={this.onCheckedSalesRep}
											defaultChecked={false}
										/>
									}
									label={this.state.notifySalesRepLabel}
									labelposition="right"
									labelstyle={{
										color: "#717171",
										padding: "0px",
										width: "auto",
										fontWeight: "normal",
									}}
									style={{ height: "auto" }}
								/>
							</div>
						</div>
					)}

				{this.props.object === constants.PAYMENTS_OBJECT && (
					<div className="row" style={{ marginTop: "5px" }}>
						<div className="col-sm-12">
							<div
								className="repeatChkBox"
								style={{ width: "8%", float: "left" }}
							>
								<FormControlLabel
									control={
										<Checkbox
											color="default"
											checked={this.state.isAddToQuickbook}
											value={this.state.isAddToQuickbook}
											onChange={this.handleQuickAddCheckBox}
											defaultChecked={false}
										/>
									}
									labelstyle={{
										...styles.text,
										maxWidth: "90%",
										lineHeight: "24px",
									}}
									style={{ height: "auto" }}
								/>
							</div>
							<div style={{ width: "35%", float: "left" }}>
								{getLocalizedStrings().label.COMMON.PUSH_TO_QB}
							</div>
						</div>
					</div>
				)}
				{/*{this.props.callFrom !== constants.CONVERT && <div className="row">
                        <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '10px', marginBottom: '20px' }}>
                            <div className="row" style={{ marginRight: '0px', whiteSpace: 'nowrap' }}>{this.getActionButtons()}</div>
                        </div>
                </div>}*/}
			</div>
		) : (
			<div style={{ width: "100%", height: contentHeight }}>
				<div
					className="asset-loaderh"
					style={{ paddingTop: top, paddingLeft: "40%" }}
				>
					<div
						style={{
							...styles.assetLoaderContainer,
							height: 50,
							width: 50,
							padding: 7,
						}}
					>
						<ShowCircularProgress
							size={30}
							style={{ marginTop: "3", marginLeft: "3" }}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true,
})(RequiredForms);
