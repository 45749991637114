import { actions } from '..//constants/actions';
import { getActiveTab } from '..//helper/sfTabManager';
import { getObjectParam, getStringParam, isValidParam, makeValidObjectName } from '..//helper/parameterVerifier';
import { getObjectNameByLabel } from '..//helper/common';
import { constants } from '..//constants/constants';

const listViewInitialSate = {
    mounted: false,
    data: {
        object: '',
    },
    pages: {},
    error: null,
    actionsOnSelectedRows: [],
    selectedRows: [],
    queryName: '',
    isSetHeader: false,
    isShowGroupBy: false,
    groupByInfo: {
        object: '',
    },
    filterByInfo: {
        filterObject: '',
        filterList: [],
        filterValue: '',
    },
    isGroupByMounted: false,
    isSearchFieldInfoMounted: false,
    searchFieldInfo: {
        object: '',
    },
    isShowLeadstoreSearchFld: false,
    isRefresh: false,
    isRefreshTreeView: false,
    requiredFieldsMap: {},
    scrollPosition: {},
    isUnitBaseShow: null,
    unitPermissionList : {},
};

const listViewReducer = (state = listViewInitialSate, action) => {
    let isMount = false;
    switch (action.type) {
        case actions.LISTVIEW_GET_PENDING:
            return {
                ...state,
                mounted: false,
            };
        case actions.LISTVIEW_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    mounted: true,
                    data: action.payload,
                    actionsOnSelectedRows: [],
                    queryName: action.payload !== null ? action.payload.query_name : '',
                    isSetHeader: true,
                    isShowGroupBy: false,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.LISTVIEW_GROUPBY_INFO_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    isGroupByMounted: true,
                    groupByInfo: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.LISTVIEW_UPDATE_FILTERBY_INFO:
            return {
                ...state,
                filterByInfo: action.payload,
            };

        case actions.LISTVIEW_GET_SELECTED_RECORD:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case actions.LISTVIEW_MODIFY:
            let _state = state;
            _state.data.records = action.payload.data.records;
            return {
                ..._state,
            };
        case actions.LISTVIEW_DATA_MODIFY:
            let __state = state;
            __state.data.records = action.payload.data.records;
            __state.data.expanded = action.payload.data.expanded;
            __state.data.childData = action.payload.data.childData;
            return {
                ...__state,
            };
        case actions.LISTVIEW_SET_MOUNTED:
            return {
                ...state,
                mounted: action.payload,
                isGroupByMounted: action.payload,
            };
        case actions.LISTVIEW_REFRESH:
            return {
                ...state,
                isRefresh: action.payload,
                selectedRows: action.payload ? [] : state.selectedRows,
            };
        case actions.LISTVIEW_GROUPBY_SHOW_HIDE:
            return {
                ...state,
                isShowGroupBy: state.isShowGroupBy ? false : true,
            };
        case actions.DOWNLOAD_LEADS_SEARCH_INFO_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    isSearchFieldInfoMounted: true,
                    searchFieldInfo: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.LISTVIEW_LEADSTORE_SEARCH_SHOW_HIDE:
            return {
                ...state,
                isShowLeadstoreSearchFld: state.isShowLeadstoreSearchFld ? false : true,
            };
        case actions.TREEVIEW_INFO_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    isGroupByMounted: true,
                    mounted: true,
                    groupByInfo: action.payload,
                };
            } else {
                return {
                    ...state,
                };
            }
        case actions.TREEVIEW_GET_FULFILLED:
            isMount = isListViewMount(action.payload, action.state);
            if (isMount) {
                return {
                    ...state,
                    mounted: true,
                    data: action.payload,
                    actionsOnSelectedRows: [],
                    selectedRows: [],
                    queryName: action.payload !== null ? action.payload.query_name : '',
                    isSetHeader: true,
                    isShowGroupBy: false,
                };
            }
            break;
        case actions.LISTVIEW_SET_REQUIRED_FIELD_DATA:
            return {
                ...state,
                requiredFieldsMap: getRequiredFieldsIds(state, action.payload),
            };
        case actions.LISTVIEW_REMOVE_REQUIRED_FIELD_DATA:
            return {
                ...state,
                requiredFieldsMap: removeRequiredFieldsIds(state, action.payload),
            };
        case actions.UPDATE_SCROLL_POSITION:
            return {
                ...state,
                scrollPosition: {
                    // ...state.scrollPosition,
                    [action.payload.obj]: action.payload.pos,
                },
            };
        case actions.REMOVE_SCROLL_POSITION:
            delete state.scrollPosition[action.payload.obj];
            return {
                ...state,
                // scrollPosition: Object.filter(state.scrollPosition, (k) => k.object !== action.payload.obj)
            };
        case actions.LISTVIEW_INLINE_UPDATE:
            return {
                ...state,
                data: {
                    ...state.data,
                    records: [...action.payload]
                },
            };
        case actions.LISTVIEWHEADER_UNITBASE_UPDATE:
            return {
                ...state,
                isUnitBaseShow: action.payload

            };

        case actions.UPDATE_UNIT_PERMISSION_LIST:
            return {
                ...state,
                unitPermissionList : action.payload
            }
        default:
            return state;
    }
};

const getRequiredFieldsIds = (state, payload) => {
    let requiredFieldsMap = state.requiredFieldsMap || {};
    try {
        let list = Object.keys(getObjectParam(payload));
        if (list.length > 0) {
            Object.keys(getObjectParam(payload)).forEach((e) => {
                if (payload[e]) {
                    let tempData = { [e]: [...new Set([...(requiredFieldsMap[e] || []), ...payload[e]])] };
                    requiredFieldsMap = { ...requiredFieldsMap, ...tempData };
                }
            });
        }
    } catch (error) {
        console.error('Error in listviewReducer.js -> getRequiredFieldsIds():' + error);
    }
    return requiredFieldsMap;
};

const removeRequiredFieldsIds = (state, payload) => {
    let requiredFieldsMap = state.requiredFieldsMap || {};
    try {
        let list = Object.keys(getObjectParam(payload));
        if (list.length > 0) {
            Object.keys(getObjectParam(payload)).forEach((e) => {
                if (requiredFieldsMap[e] && requiredFieldsMap[e].length > 0 && payload[e]) {
                    let list = requiredFieldsMap[e].filter((f) => !payload[e].includes(f));
                    requiredFieldsMap = { ...requiredFieldsMap, ...{ [e]: list } };
                }
            });
        }
    } catch (error) {
        console.error('Error in listviewReducer.js -> removeRequiredFieldsIds():' + error);
    }
    return requiredFieldsMap;
};

export default listViewReducer;

const isListViewMount = (payload, state) => {
    let activeTab = getObjectParam(getActiveTab(state));
    let activeTabObject = getStringParam(activeTab.object);
    let payloadObject = '';
    activeTabObject = makeValidObjectName(activeTabObject);
    if (activeTabObject === constants.SEARCH_RESULTS && isValidParam(activeTab.info.search) && getStringParam(activeTab.info.search.object) !== '') {
        activeTabObject = getStringParam(activeTab.info.search.object);
    } else if (
        activeTabObject === constants.ADVANCE_SEARCH_RESULTS &&
        isValidParam(activeTab.info.advanceSearch) &&
        getStringParam(activeTab.info.advanceSearch.object) !== ''
    ) {
        activeTabObject = getStringParam(activeTab.info.advanceSearch.object);
    }
    if (activeTabObject === constants.RECYCLE_BIN_OBJECT) {
        activeTabObject = constants.ACCOUNTS_OBJECT;
    }
    payloadObject = getStringParam(getStringParam(payload.object));
    payloadObject = getObjectNameByLabel(payloadObject);
    payloadObject = payloadObject === constants.AB_CAMPAIGNS_OBJECT ? constants.BROADCAST_CAMPAIGNS_OBJECT : payloadObject;
    payloadObject = payloadObject === constants.TOUCH_CAMPAIGNS_OBJECT ? constants.AUTOMATION_DESIGNER_OBJECT : payloadObject;

    if (activeTab && activeTabObject.toUpperCase() === payloadObject.toUpperCase()) {
        return true;
    } else {
        return false;
    }
};
