import React, { useEffect, useState } from 'react'
import ShowCircularProgress from './circularProgress';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {endPoints} from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { Icon, IconButton } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const MobileAuditAttachments = ({data}) => {
    const { question, encodedParams } = data;
    const [isLoading, setIsLoading] = useState(false)
    const [attachFiles, setAttachFiles] = useState([])

    useEffect(()=>{
        getImagesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getImagesData = () => {
        setIsLoading(true)
        const answer_id = question.answer_id;
        const response = Promise.resolve(HTTPClient.getWithoutToken(`${endPoints.AUDITS.AUDIT_MOBILE_ATTACMENT}/${answer_id}`, {
            encodedParams: encodedParams,
        }));
        response.then((response) => {
            setAttachFiles(response)
            setIsLoading(false)
        })
    }

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        lazyLoad: true,
        arrows: true
    };

    if(isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ShowCircularProgress size={30} />
            </div>
        );
    }

    return (
        <div className="audit-slider-container">
            <Slider {...settings}>
                {Array.isArray(attachFiles) && attachFiles.map((image, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ maxWidth: '90%', margin: '0 auto' }}>
                            <img src={image.url} alt={`${image.file_name}`} height={'250'} width={'250'}/>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <IconButton title={getLocalizedStrings().label.COMMON.REMOVE}>
                                    <HighlightOffIcon/>
                                </IconButton>
                                <span style={{fontSize: '10px'}}>delete</span>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default MobileAuditAttachments;