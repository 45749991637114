import React from "react";
import { connect } from "react-redux";
import { LinkToType } from "../../../services/constants/link";
import { constants } from "../../../services/constants/constants";
import {
  isValidParam,
  getStringParam,
  getObjectParam,
  getIntParam,
  getBooleanParam,
  getArrayParam,
} from "../../../services/helper/parameterVerifier";
import ListView from "./ListView";
import KanbanView from "./KanbanView";
import ColumnView from "./ColumnView";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import ServiceReportContainer from "./ServiceReportContainer";
import MarketingTemplate from "./MarketingTemplate";
import AgreementTemplate from "./AgreementTemplate";
import Dashboard from "./Dashboard.js";
import Task from "./Task";
import Convert from "../components/convert";
import Assign from "../components/assign";
import ListViewExport from "../components/listViewExport";
import Push from "../components/push";
import Reschedule from "../components/reschedule";
import AuditTrailAndLeadScore from "../components/auditTrailAndLeadScore";
import ClientConnect from "../components/clientConnect";
import SendMail from "../components/sendMail";
import AddToGroup from "../components/addToGroup";
import Map from "../components/map";
import Complete from "../components/complete";
import { SaveNote } from "../components/saveNote";
import PreviewEmailTemplate from "./PreviewEmailTemplate";
import BatchConvert from "../components/batchConvert";
import DetailView from "./DetailView";
import SFForm from "../components/sfForm";
import BroadcastCampaign from "./BroadcastCampaign";
import FilterMenu from "../components/filterMenu";
import ABCampaign from "./ABCampaign";
import AutomationDesigner from "./AutomationDesigner";
import BatchEdit from "../components/batchEdit";
import AutomationTemplates from "../components/automationTemplates";
import AutomationLinkClick from "../components/automationLinkClick";
import AutomationScoreReached from "../components/automationScoreReached";
import AutomationWebForm from "../components/automationWebForm";
import AutomationLandingPage from "../components/automationLandingPage";
import AutomationChangeGroup from "../components/automationChangeGroup";
import AutomationNotify from "../components/automationNotify";
import AutomationFieldUpdate from "../components/automationFieldUpdate";
import AutomationConvert from "../components/automationConvert";
import AutomationAssign from "../components/automationAssign";
import AutomationAddTask from "../components/automationAddTask";
import AutomationTouchCampaign from "../components/automationTouchCampaign";
import AutomationWait from "../components/automationWait";
import AutomationAddMessage from "../components/automationAddMessage";
import AutomationAddNote from "../components/automationAddNote";
import CalendarTask from "./CalendarTask";
import WebForm from "./WebForm";
import SearchResults from "./SearchResults";
import SMSInbox from "../components/SMSInbox";
import Snackbar from "@mui/material/Snackbar";
import AdvanceSearch from "./AdvanceSearch";
import Merge from "../components/merge";
import ConvertToAccount from "../components/convertToAccount";
import AutoResponderReport from "../components/autoResponderReport";
import AutomationSMSCampaignReport from "../components/automationSMSCampaignReport";

import {
  DashBoardEmailSummery,
  DashBoardLinkCick,
} from "../components/DashBoardEmailSummery";
import MarketingReport from "./MarketingReport";
import Relationship from "../components/Relationship";
import DeleteRecordDialog from "../components/DeleteRecordDialog";
import Import from "../components/import";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import {
  getActiveTabInfo,
  getActiveTab,
  TYPE_DETAIL_VIEW,
} from "../../../services/helper/sfTabManager";
import FilterStepper from "../components/filterStepper";
import SalesPage from "../components/salesPage";
import MyIntegrations from "../components/myIntegrations";
import Email from "../components/email";
import Docusign from "../components/docusign";
import CallScript from "../components/callScript";
import SFCustomDataTable from "../components/sfCustomDataTable";
import MosaicoTemplate from "./MosaicoTemplate";
import AutomationMosaicoTemplate from "./AutomationMosaicoTemplate";
import DashboardDrilldownTable from "../components/dashboardDrilldownTable";
import BroadcastCampaignForMosico from "../containers/BroadcastCampaignForMosico";
import EmailMarketingReport from "../containers/EmailMarketingReport";
import AssetsHome from "../components/assetsHome";
import Onboarding from "../containers/Onboarding";
import OperationsReports from "../containers/OperationsReports";
import OperationsCustomReport from "../components/operationsCustomReport";
import ModuleSetting from "../components/ModuleSetting";
import SourceForm from "../components/sourceForm";
import Unsubscribe from "../components/unsubscribe";
import SendBatchSMS from "../components/sendBatchSMS";
import SMS from "../components/sms";
import AssignmentRuleSetup from "../components/assignmentRuleSetup";
import AutomationSendSMS from "../components/automationSendSMS";
import AutomationSMSReply from "../components/automationSMSReply";
import CreateCoupon from "../components/createCoupon";
import AssignmentRules from "../containers/AssignmentRules";
import CouponClaimReport from "../components/couponClaimReport";
import News from "../components/news";
import NewsContainer from "../containers/NewsContainer";
import UsefulLinksContainer from "../components/usefulLinksContainer";
import CreateUsefulLink from "../components/createUsefulLink";
import EmailIntegrationForm from "../components/emailIntegrationForm";
import AssetsReport from "../containers/AssetsReport";
import PreviewNews from "../components/previewNews";
import HubCaseClose from "../components/hubCaseClose";
import SmsTemplate from "./SmsTemplate";
import TasksPage from "../components/tasksPage";
import UserReport from "../components/userReport";
import EmailReport from "../components/emailReport";
import SMSReport from "../components/smsReport";
import TasksReport from "../components/tasksReport";
import InexpressReport from "../components/inexpressReport";
import PipelineReport from "../components/pipelineReport";
import SalesReport from "../components/salesReport";
import TabularReport from "../components/tabularReport";
import PivotReport from "../components/pivotReport";
import OrthoticReport from "../components/orthoticReport";
import LandingPage from "../components/landingPage";
import PhoneTemplate from "../components/phoneTemplate";
import ReferralReport from "../components/referralReport";
import QuoteComponent from "../components/quoteComponent";
import QuoteTemplate from "../components/quoteTemplate";
import CalendarSettings from "../components/calendarSettings";
import GreenImportReport from "../components/greenImportReport";
import AgreementComponent from "../components/agreementComponent";
import TaskAppDetailView from "../containers/TaskAppDetailView";
import AddUnits from "../components/addUnits";
import SecurityLevelDetail from "../components/SecurityLevelDetail";
import RollUpSetUp from "../components/RollUpSetUp";
import TreeView from "./TreeView";
import RoyaltyReport from "../components/royaltyReport";
import OperationDashBoard from "../components/operationDashboard";
import PurchaseOrder from "../components/purchaseOrder";
import JobManagement from "../components/JobManagement";
import PowerCalling from "../components/powerCalling";
import GanttChart from "../components/GanttChart";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import JobScheduler from "../components/jobScheduler";
import PhoneIntegration from "../components/phoneIntegration";
import SMSIntegration from "../components/smsIntegration";
import PhoneNumberSetup from "../components/phoneNumberSetup";
import TwilioNumberVerificationForm from "../components/twilioNumberVerificationForm";
import AuditQuestionTypes from "../components/AuditQuestionTypes";
import AuditAnswers from "../components/AuditAnswers";
import ComplianceReport from "../components/complianceReport";
import MiniEdit from "../components/MiniEdit";
import DrillDownReport from "../components/drillDownReport";
import SelectAccountForUnitDilog from "../components/SelectAccountForUnitDilog";
import RoyaltyReportV2 from "../components/royaltyReportV2";
import UnitPlanName from "../components/unitPlanName";
import ReportedSalesUpdate from "../components/reportedSalesUpdate";
import AuditReport from "../components/auditReport";
import SalesAndRefundReport from "../components/SalesAndRefundReport";
import SalesAndRefundDetails from "../components/SalesAndRefundDetails";
import AuditWeaknessReport from "../components/AuditWeaknessReport";
import CorrectiveActionReport from "../components/CorrectiveActionReport.js";
import UnitBase from "../components/UnitBase";
import AddBranch from "../components/addBranch";
import IntakeForm from "../components/IntakeForm";
import ListViewDashBoard from "../components/ListViewDashBoard";
import TotalReportedSalesDrillDown from "../components/TotalReportedSalesDrillDown";
import AddExceptions from "../components/addException";
import TwilioSMSVerification from "../components/TwilioSMSVerification.js";
import LmsUsers from "../components/LmsUsers.js";
import FeSalesPipeLine from "../components/FeSalesPipeLine.jsx";
import AutomationSMSCampaign from "../components/automationSMSCampaign.js";
import AutomationAddSMS from "../components/automationAddSMS";
import AuditPerformanceReport from "../components/AuditPerformanceReport.jsx";
import AuditPerformanceReportChart from "../components/AuditPerformanceReportChart";
import OnBoardingNew from "../components/OnBoardingNew.jsx";
import WebhookRuleSetup from "../components/webhookRuleSetup";
import TemplateCategories from "../components/TemplateCategories";

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null,
      drawerWidth: "0%",
      drawerComponent: null,
      isSnackbarOpen: false,
    };
    this.generateView = this.generateView.bind(this);
    this.getDrawerComponent = this.getDrawerComponent.bind(this);
  }

  UNSAFE_componentWillMount() {
    let object = "";
    let suffix = "";
    let id = null;
    try {
      object = this.props.match?.params?.object;
      suffix = this.props.match?.params?.suffix;
      id = this.props.match.params.id;
    } catch (ex) { }
    if (object === "") object = this.props.object;
    if (suffix === "") suffix = this.props.suffix;
    this.state.view = this.generateView(object, suffix, id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let id = 0;
    let object = "";
    let suffix = "";
    try {
      object = this.props.match?.params?.object;
      suffix = this.props.match?.params?.suffix;
      id = this.props.match.params.id;
    } catch (ex) { }
    try {
      if (object === "") object = this.props.object;
      if (suffix === "") suffix = this.props.suffix;
      if (id === 0) id = this.props.id;
    } catch (ex) { }

    let nextId = 0;
    let nextObject = "";
    let nextSuffix = "";
    try {
      nextObject = nextProps.match.params.object;
      nextSuffix = nextProps.match.params.suffix;
      nextId = nextProps.match.params.id;
    } catch (ex) { }
    try {
      if (nextObject === "") nextObject = nextProps.object;
      if (nextSuffix === "") nextSuffix = nextProps.suffix;
      if (nextId === 0) nextId = nextProps.id;
    } catch (ex) { }

    if (
      object !== nextObject ||
      (object === nextObject && suffix !== nextSuffix) ||
      (object === nextObject && suffix === nextSuffix && id !== nextId)
    ) {
      //id = nextProps.match.params.id !== undefined ? nextProps.match.params.id : 0;
      this.state.view = this.generateView(nextObject, nextSuffix, nextId);
    }
  }
  generateView(object, suffix, id, data) {
    try {
      if (
        object !== null &&
        object !== undefined &&
        object !== "" &&
        suffix !== null &&
        suffix !== undefined &&
        suffix !== ""
      ) {
        suffix = suffix.toLowerCase();
        suffix = suffix + "";
        if (object === constants.DASHBOARD) {
          if (id !== "" && id !== undefined) {
            return (
              <Dashboard
                key={object + "-" + suffix + "-" + id}
                object={object}
                module={suffix}
                id={id}
              />
            );
          } else {
            return <Dashboard key={object + "-" + suffix} object={object} />;
          }
        } else if (
          object === constants.REPORTED_SALES_OBJECT &&
          suffix === constants.ROYALTY_REPORTED_SALES_DRILLDOWN
        ) {
          return (
            <TotalReportedSalesDrillDown object={object} suffix={suffix} />
          );
        } else if (object === constants.ADD_TO_GROUP) {
          return <AddToGroup object={object} />;
        } else if (object === constants.ASSIGN) {
          return <Assign object={object} />;
        } else if (object === constants.RESCHEDULE) {
          return <Reschedule object={object} />;
        } else if (object === constants.SEARCH_RESULTS) {
          return <SearchResults object={object} />;
        } else if (object === constants.INBOX_OBJECT) {
          return <SMSInbox />;
        } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
          return <AdvanceSearch />;
        } else if (object === "calendar") {
          return <CalendarTask object={object} />;
        } else if (
          object === constants.MARKETING_OBJECT ||
          object === constants.OPERATIONS_CAMPAIGNS ||
          object === constants.OPERATIONS_RECENT_CAMPAIGNS
        ) {
          return (
            <EmailMarketingReport key={"report-" + object} object={object} />
          );
          /* } else if (object === constants.UNITS_OBJECT) {
            return <Units object={object} />; */
        } else if (object === constants.ASSETS_OBJECT) {
          return (
            <AssetsHome
              callFrom="menu"
              object={object}
              styleConfig={{ height: "auto", padding: true }}
            />
          );
        } else if (object === constants.ONBOARDING_OBJECT) {
          // return <Onboarding object={object} />;
          return <OnBoardingNew object={object} />;
        } else if (
          object === constants.OPERATIONS_ACTIVITIES ||
          object === constants.OPERATIONS_CONVERSIONS
        ) {
          return <OperationsReports object={object} type={suffix} />;
        } else if (object === constants.OPERATIONS_ASSETS_REPORTS) {
          return <AssetsReport object={object} type={suffix} />;
        } else if (object === constants.CUSTOM_OPERATIONS_REPORTS) {
          return <OperationsCustomReport object={object} type={suffix} />;
        } else if (object === constants.SETTING) {
          return <ModuleSetting object={object} />;
        } else if (object === constants.COUPONS_CLAIM_REPORTS) {
          return <CouponClaimReport object={object} type={suffix} />;
        } else if (object === constants.USEFUL_LINK_OBJECT) {
          return <UsefulLinksContainer object={object} type={suffix} />;
        } else if (object === constants.JOB_SCHEDULER) {
          return <JobScheduler object={object} type={suffix} />;
        }
        //  else if (object === constants.ASSIGNMENT_RULES) {
        //     return <AssignmentRules object={object} type={suffix} />;
        // }
        else if (
          object === constants.NEWS_OBJECT &&
          suffix === LinkToType.TYPE_PREVIEW_NEWS
        ) {
          return <PreviewNews object={object} type={suffix} id={id} />;
        } else if (object === constants.REPORT_DASHBOARD) {
          return <ListViewDashBoard object={object} type={suffix} id={id} />;
        } else if (object === constants.TASKS && suffix === "taskpage") {
          return <TasksPage object={object} />;
        } else if (object === constants.ROYALTY_REPORT_OBJECT) {
          return <RoyaltyReport object={object} />;
        } else if (object === constants.ROYALTY_REPORT_OBJECT_V2) {
          return <RoyaltyReportV2 object={object} />;
        } else if (object === constants.DRILL_DOWN_OBJECT) {
          return <DrillDownReport object={object} />;
        } else if (object === constants.COMPLIANCE_REPORT_OBJ) {
          return (
            <ComplianceReport object={constants.COMPLIANCE_REPORT_OBJECT} />
          );
        }
        else if (object === constants.AUDIT_REPORT) {
          return <AuditReport object={constants.AUDIT_REPORT} />;
        } else if (object === constants.SALES_REFUND) {
          return <SalesAndRefundReport object={object} />;
        } else if (object === constants.SALES_AND_REFUND_DETAILS) {
          return <SalesAndRefundDetails object={object} />;
        } else if (object === constants.AUDIT_WEAKNESS_REPORT) {
          return <AuditWeaknessReport object={object} />;
        } else if (object === 'sales-pipeline-report') {
          return <FeSalesPipeLine object={object} />
        } else if (object === 'audit-performance-report' && suffix !== "detailview") {
          return <AuditPerformanceReport object={object} />
        } else if (object === 'audit-performance-report' && suffix === "detailview") {
          return <AuditPerformanceReportChart object={object} id={id} />
        } else if (object === constants.OPERATIONS_DASHBOARD) {
          return <OperationDashBoard object={object} />;
          // return <Dashboard key={object + "-" + suffix} object={object}/>;
        } else if (object === 'corrective-action-report') {
          return <CorrectiveActionReport object={object} />;
        } else {
          if (
            suffix === LinkToType.TYPE_CALENDAR ||
            suffix === "calendaronly" ||
            suffix === "link-click"
          ) {
            return <CalendarTask object={object} type={suffix} />;
          } else if (suffix === LinkToType.TYPE_TASK) {
            return <Task object={object} />;
          } else if (
            suffix === LinkToType.TYPE_KANBAN &&
            object === constants.OPPORTUNITIES_OBJECT
          ) {
            return <KanbanView object={object} duration={11} />;
          } else if (suffix === LinkToType.TYPE_KANBAN) {
            return <KanbanView object={object} />;
          } else if (suffix === LinkToType.TYPE_COLUMN) {
            return <ColumnView object={object} />;
          } else if (
            suffix === LinkToType.TYPE_FILTERS ||
            (object === constants.SALES_OBJECT &&
              suffix === LinkToType.TYPE_REPORT)
          ) {
            return <FilterMenu object={object} />;
          } else if (suffix === LinkToType.TYPE_QUERY) {
            let info = getObjectParam(getActiveTabInfo());
            let isReport = getBooleanParam(info.isReport);
            if (
              object === constants.UNITS_OBJECT &&
              !this.props.app.isNewTree
            ) {
              return <TreeView object={object} isReport={isReport} />;
            } else {
              return <ListView object={object} isReport={isReport} />;
            }
          } else if (
            suffix === LinkToType.AUDITS_OBJECT &&
            object === constants.AUDITS_OBJECT
          ) {
            let info = getObjectParam(getActiveTabInfo());
            let isReport = getBooleanParam(info.isReport);
            return (
              <ListView
                object={object}
                isReport={isReport}
                info={info}
                suffix="auditList"
              />
            );
          } else if (object === constants.SERVICE_OBJECT) {
            if (suffix === constants.EMAIL_REPORT_TYPE) {
              return (
                <EmailReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.SMS_REPORT_TYPE) {
              return (
                <SMSReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.TASK_REPORT_TYPE) {
              return (
                <TasksReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.INXPRESS_REPORT_TYPE) {
              return (
                <InexpressReport
                  key={"report_view_" + object}
                  object={object}
                  selectedIndex={id}
                />
              );
            } else if (suffix === constants.PIPELINE_REPORT_TYPE) {
              return (
                <PipelineReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.ORTHOTIC_REPORT_TYPE) {
              return (
                <OrthoticReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.SALES_SERVICE_REPORT_TYPE) {
              return (
                <SalesReport
                  key={"report_view_" + object}
                  object={object}
                  selectedIndex={id}
                />
              );
            } else if (suffix === LinkToType.TYPE_REPORT) {
              return (
                <ServiceReportContainer
                  key={"report_view_" + object}
                  object={object}
                />
              );
            }
          } else if (object === constants.SALES_OBJECT) {
            if (
              suffix === constants.REPORT_TYPE_ACTIVITY ||
              suffix === constants.REPORT_TYPE_CONVERSION ||
              suffix === constants.REPORT_TYPE_PIPELINE ||
              suffix === constants.REPORT_TYPE_FIVE_WAYS ||
              suffix === constants.REPORT_TYPE_CALL ||
              suffix === "inxpress-report-18" ||
              suffix === constants.REPORT_TYPE_ACTIVITY_BY_SALES_REP ||
              suffix === constants.REPORT_TYPE_PIPELINE_BY_SALES_REP ||
              suffix === constants.REPORT_TYPE_CONVERSION_BY_SALES_REP ||
              suffix === constants.REPORT_TYPE_DEALS_BY_SALES_REP ||
              suffix === constants.REPORT_TYPE_SALES_SUMMARY ||
              suffix === constants.REPORT_TYPE_CONVERSION_MATRIX ||
              suffix === constants.REPORT_TYPE_ACTIVITY_MATRIX
            ) {
              console.log('yo')
              return (
                <SalesReport
                  key={"report_view_" + object + "-" + suffix}
                  object={object}
                  type={suffix}
                  id={id}
                />
              );
            }
            /*else if(suffix === LinkToType.TYPE_AUDIT_REPORT){
              return (
                <AuditReport object={object} type={suffix} />
              )
            }*/
            // else if (suffix === constants.REPORT_TYPE_SALES_SUMMARY) {
            //     return <PipelineReport key={'report_view_' + object} object={object} />;
            // }
            else if (suffix === constants.REPORT_TYPE_EMAIL) {
              return (
                <EmailReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.REPORT_TYPE_SMS) {
              return (
                <SMSReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.REPORT_TYPE_TASKS) {
              return (
                <TasksReport key={"report_view_" + object} object={object} />
              );
            } else if (suffix === constants.REPORT_TYPE_ORTHOTIC) {
              return (
                <OrthoticReport
                  key={"report_view_" + object}
                  object={object}
                  id={id}
                />
              );
            } else if (
              suffix === constants.REPORT_TYPE_RELATION ||
              suffix === constants.REPORT_TYPE_CUSTOM_RELATION
            ) {
              return (
                <ReferralReport
                  key={"report_view_" + object}
                  object={object}
                  id={id}
                  type={suffix}
                />
              );
            } else if (suffix.indexOf(constants.REPORT_TRYPE_INXPRESS) === 0) {
              return (
                <InexpressReport
                  key={"report_view_" + object + "-" + suffix}
                  object={object}
                  selectedIndex={id}
                />
              );
            } else if (suffix.indexOf(constants.REPORT_TYPE_USER + "-") === 0) {
              let reportType = getStringParam(
                suffix.replace(constants.REPORT_TYPE_USER + "-", "")
              ).trim();
              if (reportType == constants.USER_REPORT_TYPE_ID_TABULAR) {
                // eslint-disable-line
                return (
                  <TabularReport
                    key={"report_view_" + object + "-" + suffix}
                    object={object}
                    id={id}
                  />
                );
              } else if (
                reportType == constants.USER_REPORT_TYPE_ID_PIVOT || // eslint-disable-line
                reportType == constants.USER_REPORT_TYPE_ID_SUMMARY // eslint-disable-line
              ) {
                return (
                  <PivotReport
                    key={"report_view_" + object + "-" + suffix}
                    object={object}
                    id={id}
                  />
                );
              }
            } else if (suffix === constants.REPORT_TYPE_GREENFIELD_IMPORT) {
              return <GreenImportReport object={object} />;
            }
          } else if (
            suffix.toUpperCase() ===
            constants.AUTO_RESPONDER_REPORT_TYPE.toUpperCase() ||
            suffix.toUpperCase() ===
            constants.BROADCAST_REPORT_TYPE.toUpperCase() ||
            suffix.toUpperCase() === constants.AB_REPORT_TYPE.toUpperCase() ||
            suffix.toUpperCase() ===
            constants.BIRTHDAY_REPORT_TYPE.toUpperCase()
          ) {
            return <MarketingReport />;
          } else if (suffix === LinkToType.TYPE_DETAIL_VIEW) {
            if (object === constants.APPOINTMENTS) {
              return <TaskAppDetailView object={object} id={id} />;
            } else if (object === constants.TASKS) {
              return <DetailView object={object} id={id} />;
            } else {
              return <DetailView object={object} id={id} />;
            }
          } else if (
            suffix === LinkToType.TYPE_EDIT ||
            suffix === LinkToType.TYPE_COPY ||
            suffix === LinkToType.TYPE_NEW
          ) {
            if (object === constants.MARKETING_TEMPLATE_OBJECT) {
              if (suffix === LinkToType.TYPE_NEW) {
                return <PreviewEmailTemplate object={object} />;
              } else if (
                suffix === LinkToType.TYPE_EDIT ||
                suffix === LinkToType.TYPE_COPY
              ) {
                return <MarketingTemplate id={id} type={suffix} />;
              }
            }
            // else if (object === constants.SALES_TEMPLATE_OBJECT) {
            //     return <SalesTemplate id={id} type={suffix} />;
            // }
            else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
              return <AgreementTemplate id={id} type={suffix} />;
            } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
              return (
                <BroadcastCampaign id={id} type={suffix} object={object} />
              );
            } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
              return <ABCampaign id={id} type={suffix} />;
            } else if (
              object === constants.AUTOMATION_DESIGNER_OBJECT &&
              (suffix === LinkToType.TYPE_EDIT ||
                suffix === LinkToType.TYPE_COPY)
            ) {
              return <AutomationDesigner id={id} action={suffix} />;
            } else if (
              object === constants.AUTOMATION_DESIGNER_OBJECT &&
              suffix === "new"
            ) {
              return <AutomationTemplates />;
            } else if (object === constants.WEB_FORMS) {
              return <WebForm id={id} type={suffix} />;
            } else {
              if (this.props.appContainer.drawerProps.isOpenDrawer) {
                this.props.getAppDrawer(false, null, null, null, null);
              }
              return (
                <SFForm
                  object={object}
                  openMode={constants.SF_FORM_OPEN_MODE_EXPANDED}
                  recordId={id}
                  suffix={suffix}
                />
              );
            }
          } else if (suffix === LinkToType.TYPE_EMAIL) {
            return <SendMail id={id} object={object} />;
          } else if (suffix === constants.POWER_CALLING) {
            return <PowerCalling object={object} />;
          } else if (suffix === constants.GANTT_CHART) {
            return <GanttChart object={object} />;
          } else if (suffix === constants.TASK_LIST) {
            return <ListView object={object} suffix={suffix}/>;
          } else if (suffix === LinkToType.TYPE_OTHER_VIEW) {
            if (
              object === constants.SOCIAL_MARKETING ||
              object === constants.ONLINE_REPUTATION ||
              object === constants.BUSINESS_LISTINGS ||
              object === constants.MARKETING_TEMPLATE_OBJECT ||
              object === constants.AUTOMATION_DESIGNER_OBJECT ||
              object === constants.BROADCAST_CAMPAIGNS_OBJECT
            ) {
              return <SalesPage object={object} />;
            }
          } else if (suffix === LinkToType.TYPE_NEWS) {
            return <NewsContainer object={object} type={suffix} />;
          } else if (
            object === constants.QUOTATION_OBJECT &&
            suffix === constants.SPREADSHEET
          ) {
            return <QuoteComponent object={object} id={id} />;
          } else if (
            (object === constants.PURCHASE_ORDER_OBJECT ||
              object === constants.SALES_ORDER_OBJECT) &&
            suffix === constants.SPREADSHEET
          ) {
            return <PurchaseOrder object={object} id={id} />;
          } else if (suffix === constants.JOBSVIEW) {
            return <JobManagement object={object} id={id} />;
          } else if (suffix === constants.PHOTO_VIEW) {
            const activeTab = getObjectParam(getActiveTab());
            const info = getObjectParam(activeTab.info);
            return (
              <div
                style={{
                  padding: "1.5rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "20rem", objectFit: "cover" }}
                  src={info.photo_url}
                ></img>
              </div>
            );
          } else if (
            object === constants.INTAKE_FORM &&
            suffix === constants.SPREADSHEET
          ) {
            return <IntakeForm object={object} id={id} />;
          } else {
            return <div />;
          }
        }
      }
    } catch (error) {
      console.error("Error in 'AppContainer.js -> generateView()':" + error);
    }
  }

  getDrawerComponent(object, actionType) {
    try {
      if (isValidParam(actionType) && actionType !== "") {
        let data = getObjectParam(this.props.appContainer.drawerProps.data);
        let recordId = getIntParam(data.id);
        let selectedRecords = getArrayParam(data.selectedRecords);
        if (object === constants.JOB_SCHEDULER) {
          object = constants.APPOINTMENTS;
        }
        let activeTab = getActiveTab();

        let info = getObjectParam(activeTab?.info);
        let isReport = getBooleanParam(info?.isReport);
        let isDetailView = activeTab?.type === TYPE_DETAIL_VIEW ? true : false;
        let parentDetails = {};
        switch (actionType) {
          case constants.CONVERT:
            if (
              this.props.appContainer.drawerProps.callFrom ===
              constants.LIST_VIEW ||
              this.props.appContainer.drawerProps.callFrom ===
              constants.KANBAN ||
              this.props.appContainer.drawerProps.callFrom ===
              constants.COLUMN_VIEW
            ) {
              if (selectedRecords.length === 1) {
                return (
                  <Convert
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                    callFrom={this.props.appContainer.drawerProps.callFrom}
                    data={this.props.appContainer.drawerProps.data}
                    selectedRecords={selectedRecords}
                  />
                );
              } else {
                return (
                  <BatchConvert
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                    callFrom={this.props.appContainer.drawerProps.callFrom}
                    selectedRecords={selectedRecords}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              }
            } else {
              return (
                <Convert
                  object={object}
                  closeDrawer={this.handleDrawerClose}
                  callFrom={this.props.appContainer.drawerProps.callFrom}
                  data={this.props.appContainer.drawerProps.data}
                />
              );
            }
          case constants.ASSIGN:
            return (
              <Assign
                object={object}
                closeDrawer={this.handleDrawerClose}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                selectedRecords={selectedRecords}
                data={data}
              />
            );
          case constants.AUDIT_QUESTIONS_OBJECT:
            return (
              <AuditQuestionTypes
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUDIT_ANSWERS_OBJECT:
            return (
              <AuditAnswers
                object={object}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.ADD_TO_GROUP:
            return (
              <AddToGroup
                object={object}
                closeDrawer={this.handleDrawerClose}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                data={this.props.appContainer.drawerProps.data}
                selectedRecords={selectedRecords}
              />
            );
          case constants.PUSH:
            return (
              <Push
                object={object}
                closeDrawer={this.handleDrawerClose}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                selectedRecords={selectedRecords}
              />
            );
          case constants.SEND_EMAIL:
            return (
              <SendMail
                id={recordId}
                object={object}
                data={data}
                closeDrawer={this.handleDrawerClose}
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.RESCHEDULE:
            return (
              <Reschedule
                object={object}
                closeDrawer={this.handleDrawerClose}
                selectedRecords={selectedRecords}
              />
            );
          case constants.COMPLETE:
            return (
              <Complete
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                selectedRecords={selectedRecords}
              />
            );
          case constants.SAVE_NOTES:
            return (
              <SaveNote
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                selectedRecords={selectedRecords}
              />
            );
          case constants.MAP:
            return (
              <Map
                object={object}
                closeDrawer={this.handleDrawerClose}
                callFrom={this.props.appContainer.drawerProps.callFrom}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.BATCH_EDIT:
            isDetailView = false;
            parentDetails = { parent_object: "", parent_record_id: 0 };
            return (
              <BatchEdit
                object={object}
                closeDrawer={this.handleDrawerClose}
                openMode={"QUICK"}
                recordId={0}
                isDetailView={isDetailView}
                parentDetails={parentDetails}
                selectedRecords={selectedRecords}
              />
            );
          case constants.AUTOMATION_DESIGNER_LINK_CLICK:
            return (
              <AutomationLinkClick
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_WEBSITE_VISIT:
            return (
              <AutomationLinkClick
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_SCORE_REACHED:
            return (
              <AutomationScoreReached
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_WEB_FORM:
            return (
              <AutomationWebForm
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_LANDING_PAGE:
            return (
              <AutomationLandingPage
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_CHANGE_GROUP:
            return (
              <AutomationChangeGroup
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_NOTIFY:
            return (
              <AutomationNotify
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_UPDATE_FIELD:
            return (
              <AutomationFieldUpdate
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUDIT:
            return (
              <AuditTrailAndLeadScore
                object={object}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_CONVERT:
            return (
              <AutomationConvert
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_ASSIGN:
            return (
              <AutomationAssign
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_ADD_TASK:
            return (
              <AutomationAddTask
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_SEND_MAIL:
          case constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL:
            return (
              <AutomationTouchCampaign
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_WAIT:
            return (
              <AutomationWait
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_ADD_MESSAGE:
          case constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE:
            return (
              <AutomationAddMessage
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_AUTO_MAIL:
            return (
              <AutomationAddMessage
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_ADD_NOTE:
            return (
              <AutomationAddNote
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_SEND_SMS:
            return (
              <AutomationSendSMS
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_SMS_REPLY:
            return (
              <AutomationSMSReply
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN:
            return (
              <AutomationSMSCampaign
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTOMATION_DESIGNER_ADD_SMS:
            return (
              <AutomationAddSMS
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.TO_EXCEL:
            return (
              <ListViewExport isDetailView={isDetailView} isReport={isReport} />
            );
          case constants.MERGE:
            return (
              <Merge
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.AUTO_RESPONDER_REPORT:
            return (
              <AutoResponderReport
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.SMS_CAMPAIGN_REPORT:
            return (
              <AutomationSMSCampaignReport
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.LEAD_SCORE_HISTORY:
            return (
              <AuditTrailAndLeadScore
                object={object}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.DASHBOARD_EMAIL_SUMMERY_DATA:
            return (
              <DashBoardEmailSummery
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.DASHBOARD_TOP_MARKETING_EMAIL_BY_CTR:
            return (
              <DashBoardLinkCick
                callParentFunction={() => { }}
                clickStatsId={
                  this.props.appContainer.drawerProps.data.campStatId
                }
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.CONNECT:
            return <ClientConnect closeDrawer={this.handleDrawerClose} />;
          case constants.DELETE:
            return (
              <DeleteRecordDialog
                data={this.props.appContainer.drawerProps.data}
                call_from={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.IMPORT:
            return (
              <Import
                object={object}
                data={this.props.appContainer.drawerProps.data}
                call_from={this.props.appContainer.drawerProps.callFrom}
              />
            );

          case constants.FILTER:
            return (
              <FilterStepper data={this.props.appContainer.drawerProps.data} />
            );
          case constants.MY_INTEGRATIONS:
            return (
              <MyIntegrations
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.DOCUSIGN:
            return (
              <Docusign
                closeDrawer={this.handleDrawerClose}
                parentObject={data.parent_object}
                parentRecordId={data.parent_record_id}
                childRecordId={data.childId}
              />
            );
          case constants.EMAIL_DETAILS:
            return (
              <Email
                parentObject={data.parentObject}
                parentRecordId={data.parentRecordId}
                data={data.record}
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.CALL_SCRIPT:
            return <CallScript />;
          case constants.AGREEMENT_TEMPLATE:
            return <AgreementTemplate />;
          case constants.ASSETS:
            return <AssetsHome callFrom="drawer" object={object} />;
          case constants.UNSUBSCRIBE:
            return (
              <Unsubscribe
                object={object}
                data={this.props.appContainer.drawerProps.data}
                call_from={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.SEND_SMS:
            return (
              <SendBatchSMS
                object={object}
                data={this.props.appContainer.drawerProps.data}
                call_from={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.SMS_DETAILS:
            return (
              <SMS
                parentObject={data.parentObject}
                parentRecordId={data.parentRecordId}
                data={data.record}
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.USEFUL_LINK:
            return <CreateUsefulLink object={object} data={data} />;
          case constants.HUB_CASE_CLOSE:
            return (
              <HubCaseClose
                closeDrawer={this.handleDrawerClose}
                object={object}
                data={data}
                callFrom={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.AGREEMENT_OBJECT:
            return (
              <AgreementComponent
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.PLAN_NAME:
            return (
              <UnitPlanName
                object={object}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case "Update":
            return (
              <ReportedSalesUpdate
                object={object}
                data={this.props.appContainer.drawerProps.data}
              //call_from={this.props.appContainer.drawerProps.callFrom}
              />
            );
          case constants.EXCEPTION:
            return (
              <AddExceptions
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          case constants.NEEDS_APPROVAL:
            return (
              <TwilioSMSVerification
                object={object}
                closeDrawer={this.handleDrawerClose}
                data={this.props.appContainer.drawerProps.data}
              />
            );
          default:
            let suffix = actionType.toLowerCase();
            let mode = getStringParam(data.mode);
            object = data.hasOwnProperty("object")
              ? getStringParam(data.object)
              : object;

            // Detailview Change
            isDetailView = getBooleanParam(data.isDetailView);
            parentDetails = {};
            parentDetails.parent_object = getStringParam(data.parent_object);
            parentDetails.parent_record_id = getIntParam(data.parent_record_id);

            if (
              data.hasOwnProperty("callFrom") &&
              data.callFrom === "ColumnViewAddTaskApp"
            ) {
              parentDetails = { ...data };
              parentDetails.parent_object = getStringParam(data.parent_object);
              parentDetails.parent_record_id = getIntParam(
                data.parent_record_id
              );
            }

            if (
              parentDetails.parent_object !== undefined &&
              parentDetails.parent_object.toLowerCase() ===
              constants.OPPORTUNITIES_OBJECT
            ) {
              parentDetails.link_info = getStringParam(data.link_info);
            }
            if (actionType === constants.MINI_EDIT) {
              return (
                <MiniEdit
                  object={object}
                  closeDrawer={this.handleDrawerClose}
                  callFrom={this.props.appContainer.drawerProps.callFrom}
                  data={this.props.appContainer.drawerProps.data}
                  openMode={mode}
                  recordId={recordId}
                  type={suffix}
                  isDetailView={isDetailView}
                  parentDetails={parentDetails}
                />
              );
            }
            if (
              suffix === LinkToType.TYPE_NEW ||
              suffix === LinkToType.TYPE_COPY ||
              suffix === LinkToType.TYPE_EDIT ||
              suffix === constants.CONVERT_TO_ACCOUNTS.toLowerCase()
            ) {
              if (object === constants.MARKETING_TEMPLATE_OBJECT) {
                if (suffix === LinkToType.TYPE_NEW) {
                  if (
                    isValidParam(
                      this.props.appContainer.drawerProps.data.type
                    ) &&
                    (this.props.appContainer.drawerProps.data.type ===
                      constants.EDITOR_LEGACY ||
                      data.type === constants.EDITOR_BEE)
                  ) {
                    return (
                      <PreviewEmailTemplate
                        object={object}
                        data={this.props.appContainer.drawerProps.data}
                      />
                    );
                  } else if (
                    isValidParam(
                      this.props.appContainer.drawerProps.data.type
                    ) &&
                    this.props.appContainer.drawerProps.data.type ===
                    constants.EDITOR_NEW
                  ) {
                    return <MosaicoTemplate object={object} />;
                  }
                } else if (
                  suffix === LinkToType.TYPE_EDIT ||
                  suffix === LinkToType.TYPE_COPY
                ) {
                  if (
                    isValidParam(data.type) &&
                    (data.type === constants.EDITOR_NEW ||
                      data.type === constants.EDITOR_BEE)
                  ) {
                    return (
                      <MosaicoTemplate
                        id={recordId}
                        type={suffix}
                        object={object}
                      />
                    );
                  } else {
                    return <MarketingTemplate id={recordId} type={suffix} />;
                  }
                }
              }
              // else if (object === constants.SALES_TEMPLATE_OBJECT) {
              //     return <SalesTemplate id={recordId} type={suffix} closeDrawer={this.handleDrawerClose} />;
              // }
              else if (object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                return (
                  <AgreementTemplate
                    id={recordId}
                    type={suffix}
                    closeDrawer={this.handleDrawerClose}
                  />
                );
              } else if (object === constants.SMS_TEMPLATE_OBJECT) {
                return (
                  <SmsTemplate
                    id={recordId}
                    type={suffix}
                    data={this.props.appContainer.drawerProps.data}
                    closeDrawer={this.handleDrawerClose}
                  />
                );
              } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                if (suffix === LinkToType.TYPE_NEW) {
                  if (
                    isValidParam(data.type) &&
                    (data.type === constants.EDITOR_NEW ||
                      data.type === constants.EDITOR_BEE)
                  ) {
                    return (
                      <BroadcastCampaignForMosico id={recordId} type={suffix} />
                    );
                  } else {
                    return (
                      <BroadcastCampaign
                        id={recordId}
                        type={suffix}
                        object={object}
                      />
                    );
                  }
                } else if (
                  suffix === LinkToType.TYPE_EDIT ||
                  suffix === LinkToType.TYPE_COPY
                ) {
                  if (
                    isValidParam(data.type) &&
                    (data.type === constants.EDITOR_NEW ||
                      data.type === constants.EDITOR_BEE)
                  ) {
                    return (
                      <BroadcastCampaignForMosico id={recordId} type={suffix} />
                    );
                  } else {
                    return (
                      <BroadcastCampaign
                        id={recordId}
                        type={suffix}
                        object={object}
                      />
                    );
                  }
                }
              } else if (object === constants.AB_CAMPAIGNS_OBJECT) {
                return <ABCampaign id={recordId} type={suffix} />;
              } else if (
                object === constants.AUTOMATION_DESIGNER_OBJECT &&
                suffix === "edit"
              ) {
                return <AutomationDesigner id={recordId} />;
              } else if (object === constants.ASSIGNMENT_RULES) {
                return (
                  <AssignmentRules
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (object === constants.ASSIGNMENT_RULES_OBJECT) {
                return (
                  <AssignmentRuleSetup
                    object={object}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (object === constants.WEBHOOKS_OBJECT) {
                return (
                  <WebhookRuleSetup
                    object={object}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              }  else if (object === constants.TEMPLATE_CATEGORIES) {
                return (
                  <TemplateCategories
                    object={object}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (
                object === constants.AUTOMATION_DESIGNER_OBJECT &&
                suffix === "new"
              ) {
                // alert(suffix);
                // return <AutomationTemplates />
                if (
                  isValidParam(this.props.appContainer.drawerProps.data.type) &&
                  (this.props.appContainer.drawerProps.data.type ===
                    constants.EDITOR_NEW ||
                    data.type === constants.EDITOR_BEE)
                ) {
                  return (
                    <AutomationMosaicoTemplate
                      nodeObject={
                        this.props.appContainer.drawerProps.data.node_object
                      }
                    />
                  );
                }
              } else if (object === constants.WEB_FORMS) {
                return <WebForm id={recordId} type={suffix} />;
              } else if (
                object === constants.ACCOUNTS_OBJECT ||
                object === constants.CONTACTS_OBJECT ||
                object === constants.OPPORTUNITIES_OBJECT ||
                object === constants.PRODUCTS_OBJECT ||
                object === constants.PROJECTS_OBJECT ||
                object === constants.SOLUTIONS_OBJECT ||
                object === constants.ISSUES_OBJECT ||
                object === constants.CASES_OBJECT ||
                object === constants.CUSTOM_TABLE1_OBJECT ||
                object === constants.CUSTOM_TABLE2_OBJECT ||
                object === constants.CUSTOM_TABLE3_OBJECT ||
                object === constants.CUSTOM_TABLE4_OBJECT ||
                object === constants.GROUP_OBJECT ||
                object === constants.SALES_LEADS_OBJECT ||
                object === constants.PETS_OBJECT ||
                object === constants.ORDERS_OBJECTS ||
                object === constants.ORDER_ITEMS_OBJECTS ||
                object === constants.PAYMENTS_OBJECT ||
                object === constants.UNITS_OBJECT ||
                object === constants.INVENTORY_OBJECT ||
                object === constants.TIME_OBJECT ||
                object === constants.ROYALTY_OBJECT ||
                object === constants.COMPLIANCE_OBJECT ||
                object === constants.REPORTED_SALES_OBJECT ||
                object == constants.REFUND_OBJECT ||
                object === constants.ADD_UNIT_BASE ||
                object == constants.ADD_BRANCH ||
                object == constants.PARTNERS_OBJECT
              ) {
                mode = mode !== "" ? mode : constants.SF_FORM_OPEN_MODE_QUICK;
                if (
                  object === constants.ACCOUNTS_OBJECT &&
                  suffix === constants.CONVERT_TO_ACCOUNTS.toLowerCase() &&
                  selectedRecords.length > 1
                ) {
                  return (
                    <ConvertToAccount
                      object={object}
                      closeDrawer={this.handleDrawerClose}
                      data={this.props.appContainer.drawerProps.data}
                    />
                  );
                } else if (
                  object === constants.UNITS_OBJECT &&
                  suffix === LinkToType.TYPE_NEW
                ) {
                  return (
                    <AddUnits
                      object={object}
                      type={suffix}
                      closeDrawer={this.handleDrawerClose}
                      data={this.props.appContainer.drawerProps.data}
                    />
                  );
                } else if (
                  object === constants.ADD_UNIT_BASE &&
                  suffix === LinkToType.TYPE_NEW
                ) {
                  return (
                    <UnitBase
                      object={object}
                      type={suffix}
                      closeDrawer={this.handleDrawerClose}
                      data={this.props.appContainer.drawerProps.data}
                    />
                  );
                } else if (
                  object === constants.ADD_BRANCH &&
                  suffix === LinkToType.TYPE_NEW
                ) {
                  return (
                    <AddBranch
                      object={object}
                      type={suffix}
                      closeDrawer={this.handleDrawerClose}
                      data={this.props.appContainer.drawerProps.data}
                    />
                  );
                } else {
                  let paymentType = null;
                  let parentPaymentId = 0;
                  if (
                    object === constants.PAYMENTS_OBJECT &&
                    data.hasOwnProperty("paymentType") &&
                    data.paymentType === "Refund"
                  ) {
                    paymentType = data.paymentType;
                    parentPaymentId = data.parentPaymentId;
                  }
                  return (
                    <SFForm
                      object={object}
                      openMode={mode}
                      recordId={recordId}
                      type={suffix}
                      isDetailView={isDetailView}
                      parentDetails={parentDetails}
                    />
                  );
                }
              } else if (
                object === constants.NOTE_OBJECT ||
                object === constants.APPOINTMENTS ||
                object === constants.TASKS
              ) {
                // return <CustomForm object={object} openMode={constants.SF_FORM_OPEN_MODE_CUSTOM} id={recordId} isDetailView={isDetailView} parentDetails={parentDetails} />;
                let parentLinkInfo = data.parent_link_info;
                let isCompleAndNew = getBooleanParam(data.isCompleAndNew);
                let hasWorkflowAppointment = getBooleanParam(
                  data.hasWorkflowAppointment
                );
                let isPowerCalling = getBooleanParam(data.isPowerCalling);
                let isFromJobScheduler = false;
                let isDragNDrop = false;
                isFromJobScheduler = getBooleanParam(data.isFromJobScheduler);
                isDragNDrop = getBooleanParam(data.isDragNDrop);
                let appointmentSub = "";
                let callType = "";
                let columnViewTaskApp = getBooleanParam(data.columnViewTaskApp);

                let listIndex = data.listIndex;
                let cardIndex = data.cardIndex;
                let updateCards = data.updateCards;
                let isUnitOwnerTasks = false;
                let isFranchiseRecord = false;
                let tasksObjParam = {};
                if (object === constants.TASKS) {
                  tasksObjParam.isUnitOwnerTasks = getBooleanParam(
                    data.isUnitownerTasks
                  );
                  tasksObjParam.isFranchiseRecord = getBooleanParam(
                    data.isFranchiseRecord
                  );
                  tasksObjParam.selectOpt = getStringParam(data.selectedOpt);
                }

                if (
                  this.props.appContainer.drawerProps.callFrom ===
                  constants.COLUMN_VIEW &&
                  columnViewTaskApp
                ) {
                  parentDetails.listIndex = listIndex;
                  parentDetails.cardIndex = cardIndex;
                  parentDetails.updateCards = updateCards;
                }
                if (hasWorkflowAppointment) {
                  appointmentSub = data.appointmentSub;
                  hasWorkflowAppointment = data.hasWorkflowAppointment;
                  callType = data.callType;
                }

                if (isPowerCalling) {
                  callType = data.callType;
                }
                let recordDetails = null;
                if (isFromJobScheduler) {
                  callType = data.callType;
                  recordDetails = data.recordDetails;
                }

                if (isCompleAndNew) {
                  recordDetails = data.recordDetails;
                }
                return (
                  <SFForm
                    object={object}
                    openMode={constants.SF_FORM_OPEN_MODE_CUSTOM}
                    recordId={recordId}
                    isDetailView={isDetailView}
                    parentDetails={parentDetails}
                    reminderBlock={true}
                    isUnitask={data?.isUnitask || false}
                    callFrom={data.callFrom}
                    parentLinkInfo={parentLinkInfo}
                    isCompleAndNew={isCompleAndNew}
                    isFromJobScheduler={isFromJobScheduler}
                    isDragNDrop={isDragNDrop}
                    recordDetails={recordDetails}
                    hasWorkflowAppointment={hasWorkflowAppointment}
                    appointmentSub={appointmentSub}
                    callType={callType}
                    isPowerCalling={isPowerCalling}
                    columnViewTaskApp={columnViewTaskApp}
                    isUnitOwnerTasks={isUnitOwnerTasks}
                    isFranchiseRecord={isFranchiseRecord}
                    tasksObjParam={tasksObjParam}
                  />
                );
              } else if (object === constants.UNITS_OBJECT) {
                if (object === constants.SELECT_ACCOUNT) {
                  return <SelectAccountForUnitDilog />;
                }
              } else if (object === constants.COUPONS_OBJECT) {
                return (
                  <CreateCoupon
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                  />
                );
              } else if (
                object === constants.SOURCE_OBJECT ||
                object === constants.NOTE_TYPES_OBJECT ||
                object === constants.APPOINTMENT_TYPES_OBJECT ||
                object === constants.CAMPAIGN_TYPES_OBJECT ||
                object === constants.LOCATION_OBJECT ||
                object === constants.TASKSLIST_OBJECT ||
                object === constants.RELATIONS_OBJECT
              ) {
                return (
                  <SourceForm
                    object={object}
                    id={recordId}
                    type={suffix}
                    isDetailView={isDetailView}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (object === constants.ROLL_UP_OBJECT) {
                return (
                  <RollUpSetUp
                    object={object}
                    id={recordId}
                    type={suffix}
                    isDetailView={isDetailView}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (object === constants.EMAIL_INTEGRATION_OBJECT) {
                return (
                  <EmailIntegrationForm
                    object={object}
                    id={recordId}
                    type={suffix}
                    isDetailView={false}
                    data={this.props.appContainer.drawerProps.data}
                  />
                );
              } else if (object === constants.NEWS_OBJECT) {
                return (
                  <News
                    object={object}
                    id={recordId}
                    type={suffix}
                    data={this.props.appContainer.drawerProps.data}
                    closeDrawer={this.handleDrawerClose}
                  />
                );
              } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                if (suffix === LinkToType.TYPE_NEW) {
                  return <PreviewEmailTemplate object={object} />;
                } else {
                  return (
                    <LandingPage object={object} type={suffix} id={recordId} />
                  );
                }
              } else if (object === constants.CALL_SCRIPT_OBJECT) {
                return (
                  <PhoneTemplate
                    object={object}
                    id={recordId}
                    type={suffix}
                    closeDrawer={this.handleDrawerClose}
                    parentObject={data.parent_object}
                  />
                );
              } else if (object === constants.LMS_USERS_OBJECT) {
                return (
                  <LmsUsers
                    object={object}
                    data={data}
                  />
                );
              } else if (
                object === constants.SECURITY_LEVEL_OBJECT &&
                suffix === "edit"
              ) {
                return (
                  <SecurityLevelDetail
                    object={object}
                    recordId={recordId}
                    data={data}
                  />
                );
              }
            } else if (suffix === LinkToType.TYPE_EMAIL) {
              return (
                <SendMail
                  id={recordId}
                  object={object}
                  data={data}
                  closeDrawer={this.handleDrawerClose}
                />
              );
            } else if (suffix === constants.POWER_CALLING) {
              return <PowerCalling object={object} />;
            } else if (suffix === LinkToType.TYPE_RELATIONSHIP) {
              return (
                <Relationship
                  id={recordId}
                  object={object}
                  closeDrawer={this.handleDrawerClose}
                />
              );
            } else if (
              suffix === LinkToType.TYPE_CALENDAR_SETTINGS ||
              suffix === LinkToType.TYPE_JOB_CALENDAR_SETTINGS ||
              suffix === LinkToType.TYPE_TASK_SETTINGS
            ) {
              <CalendarSettings
                closeDrawer={this.handleDrawerClose}
                data={data}
              />;
            } else if (suffix === LinkToType.TYPE_SF_CUSTOM_DATA_TABLE) {
              return (
                <SFCustomDataTable
                  object={object}
                  data={data}
                  closeDrawer={this.handleDrawerClose}
                />
              );
            } else if (
              suffix === LinkToType.TYPE_SF_DASHBOARD_DRILLDOWN_DATA_TABLE
            ) {
              return (
                <DashboardDrilldownTable
                  object={object}
                  data={data}
                  closeDrawer={this.handleDrawerClose}
                />
              );
            } else if (suffix === constants.CORPORATE_CURRENCY_OBJECT) {
              return <SourceForm object={suffix} />;
            } else if (suffix === constants.USER_REPORT) {
              return <UserReport object={object} data={data} id={recordId} />;
            } else if (suffix === LinkToType.TYPE_QUOTATIONS) {
              return (
                <QuoteTemplate
                  id={recordId}
                  object={object}
                  closeDrawer={this.handleDrawerClose}
                  data={data}
                />
              );
            } else if (object === constants.SETTING) {
              if (
                suffix === constants.LEASE_NUMBER ||
                suffix === constants.IMPORT_NUMBER
              ) {
                return (
                  <SMSIntegration
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                    data={data}
                  />
                );
              } else if (suffix === constants.BRING_YOUR_OWN) {
                return (
                  <PhoneIntegration
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                    data={data}
                  />
                );
              } else if (suffix === constants.PHONE_NUMBER_SETUP) {
                return (
                  <PhoneNumberSetup
                    object={object}
                    closeDrawer={this.handleDrawerClose}
                    data={data}
                  />
                );
              } else if (suffix === constants.TWILIO_NUMBER_VERIFICATION) {
                return (
                  <TwilioNumberVerificationForm
                    closeDrawer={this.handleDrawerClose}
                    data={data}
                  />
                );
              }
            } else {
              return <div />;
            }
        }
      }
    } catch (error) {
      console.error(
        "Error in 'AppContainer.js -> getDrawerComponent()':" + error
      );
    }
  }
  getDrawerWidth = (object, actionType) => {
    let mode = null;
    try {
      let data = getObjectParam(this.props.appContainer.drawerProps.data);
      if (
        this.props.appContainer.drawerProps.callFrom === constants.OTHER ||
        data.hasOwnProperty("object")
      ) {
        object = getStringParam(data.object);
        mode = getStringParam(data.mode);
        mode = mode === "" ? constants.SF_FORM_OPEN_MODE_QUICK : mode;
      }
      // width and height for all element of container of Security level for admin
      if (isValidParam(object)) {
        if (
          (object === constants.ACCOUNTS_OBJECT ||
            object === constants.CONTACTS_OBJECT ||
            object === constants.OPPORTUNITIES_OBJECT ||
            object === constants.PRODUCTS_OBJECT ||
            object === constants.PROJECTS_OBJECT ||
            object === constants.ISSUES_OBJECT ||
            object === constants.SOLUTIONS_OBJECT ||
            object === constants.CASES_OBJECT ||
            object === constants.NOTE_OBJECT ||
            object === constants.CUSTOM_TABLE1_OBJECT ||
            object === constants.CUSTOM_TABLE2_OBJECT ||
            object === constants.CUSTOM_TABLE3_OBJECT ||
            object === constants.CUSTOM_TABLE4_OBJECT ||
            object === constants.SALES_LEADS_OBJECT ||
            object === constants.PETS_OBJECT ||
            object === constants.ORDERS_OBJECTS ||
            object === constants.ORDER_ITEMS_OBJECTS ||
            object === constants.SMS_TEMPLATE_OBJECT ||
            object === constants.CALL_SCRIPT_OBJECT ||
            object === constants.QUOTATION_OBJECT ||
            object === constants.PAYMENTS_OBJECT ||
            object === constants.ADD_UNIT_BASE ||
            object === constants.ADD_BRANCH ||
            object === constants.UNITS_OBJECT ||
            object === constants.INVENTORY_OBJECT ||
            object === constants.TIME_OBJECT ||
            object === constants.ROYALTY_OBJECT ||
            object === constants.PARTNERS_OBJECT ||
            object === constants.REPORTED_SALES_OBJECT ||
            object == constants.REFUND_OBJECT) &&
          (actionType === constants.NEW ||
            actionType === constants.EDIT ||
            actionType === constants.COPY ||
            actionType === constants.CONVERT_TO_ACCOUNTS)
        ) {
          if (mode === constants.SF_FORM_OPEN_MODE_QUICK) {
            if (object === constants.NOTE_OBJECT) {
              return "80%";
            } else if (object === constants.UNITS_OBJECT) {
              return "50%";
            } else if (object === constants.ADD_UNIT_BASE) {
              return "50%";
            } else if (object === constants.ADD_BRANCH) {
              return "50%";
            } else {
              return "32%";
            }
          } else {
            return "80%";
          }
        } else if (actionType === constants.LEASE_NUMBER || actionType === constants.BRING_YOUR_OWN || actionType === constants.IMPORT_NUMBER) {
          return "70%";
        }
        else if (
          actionType === constants.NEW ||
          getStringParam(actionType).toLowerCase() ===
          constants.EDIT.toLowerCase()
        ) {
          if (object === constants.APPOINTMENTS || object === constants.TASKS) {
            return "60%";
          } else if (object === constants.COUPONS_OBJECT) {
            return "35%";
          } else if (object === constants.ASSIGNMENT_RULES) {
            return "85%";
          } else if (object === constants.NEWS_OBJECT) {
            return "60%";
          } else if (
            object === constants.SOURCE_OBJECT ||
            object === constants.NOTE_TYPES_OBJECT ||
            object === constants.APPOINTMENT_TYPES_OBJECT ||
            object === constants.CAMPAIGN_TYPES_OBJECT ||
            object === constants.RELATIONS_OBJECT ||
            object === constants.EMAIL_INTEGRATION_OBJECT ||
            object === constants.LOCATION_OBJECT ||
            object === constants.TASKSLIST_OBJECT ||
            object === constants.ROLL_UP_OBJECT  ||
            object === constants.TEMPLATE_CATEGORIES 
          ) {
            return "35%";
          } else if (
            object === constants.CALL_SCRIPT_OBJECT ||
            object === constants.SMS_TEMPLATE_OBJECT
          ) {
            return "32%";
          } else if (object === constants.SECURITY_LEVEL_OBJECT) {
            return "100%";
          } else if (
            object === constants.LANDING_PAGES_FROALA_OBJECT ||
            object === constants.MARKETING_TEMPLATE_OBJECT
          ) {
            return "100%";
          } else if (object === constants.LMS_USERS_OBJECT) {
            return "50%";
          } else {
            return "83%";
          }
        } else if (actionType === constants.MINI_EDIT) {
          return "35%";
        } else if (
          object === constants.LANDING_PAGES_FROALA_OBJECT &&
          (actionType === constants.COPY ||
            actionType === constants.COPY.toLowerCase())
        ) {
          return "100%";
        } else if (actionType === constants.CONVERT) {
          return "40%";
        } else if (actionType == constants.TWILIO_NUMBER_VERIFICATION) {
          return "40%";
        } else if (actionType === constants.SAVE_NOTES) {
          return "32%";
        } else if (actionType === constants.ADD_TO_GROUP) {
          return "34%";
        } else if (actionType === constants.CONNECT) {
          return "32%";
        } else if (actionType === constants.SHARE) {
          return "30%";
        } else if (actionType === constants.COMPLETE) {
          return "34%";
        } else if (actionType === constants.AUDIT_QUESTIONS_OBJECT) {
          return "45%";
        } else if (actionType === constants.AUDIT_ANSWERS_OBJECT) {
          return "45%";
        } else if (actionType === constants.ASSIGN) {
          return "30%";
        } else if (actionType === constants.RESCHEDULE) {
          return "27%";
        } else if (actionType === constants.AUDIT) {
          return "70%";
        } else if (actionType === constants.PUSH) {
          return "30%";
        } else if (
          actionType === constants.AUTOMATION_DESIGNER_LINK_CLICK ||
          actionType === constants.AUTOMATION_DESIGNER_WEBSITE_VISIT ||
          actionType === constants.AUTOMATION_DESIGNER_SMS_REPLY
        ) {
          return "27%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
          return "23%";
        } else if (
          actionType === constants.AUTOMATION_DESIGNER_WEB_FORM ||
          actionType === constants.AUTOMATION_DESIGNER_LANDING_PAGE
        ) {
          return "27%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_CHANGE_GROUP) {
          return "38%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_NOTIFY) {
          return "35%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_CONVERT) {
          return "35%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_ASSIGN) {
          return "25%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_ADD_TASK) {
          return "53%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
          return "60%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
          return "40%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_WAIT) {
          return "25%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_SEND_SMS || actionType === constants.AUTOMATION_DESIGNER_ADD_SMS) {
          return "40%";
        } else if (
          actionType === constants.AUTOMATION_DESIGNER_ADD_MESSAGE ||
          actionType === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
          actionType === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE
        ) {
          if (
            isValidParam(this.props.appContainer.drawerProps.data.type) &&
            (this.props.appContainer.drawerProps.data.type ===
              constants.EDITOR_NEW ||
              this.props.appContainer.drawerProps.data.type ===
              constants.EDITOR_BEE)
          ) {
            return "100%";
          } else {
            return "83%";
          }
        } else if (actionType === constants.AUTOMATION_DESIGNER_ADD_NOTE) {
          return "35%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_UPDATE_FIELD) {
          return "40%";
        } else if (actionType === constants.BATCH_EDIT) {
          return "35%";
        } else if (actionType === constants.TO_EXCEL) {
          return "25%";
        } else if (actionType === constants.AUTO_RESPONDER_REPORT) {
          return "78%";
        }
        else if (actionType === constants.SMS_CAMPAIGN_REPORT) {
          return "60%";
        }
        else if (actionType === constants.LEAD_SCORE_HISTORY) {
          return "60%";
        } else if (actionType === LinkToType.TYPE_RELATIONSHIP) {
          return "40%";
        } else if (actionType === constants.DELETE) {
          return "35%";
        } else if (actionType === constants.PROFILE_DETAILS) {
          return "55%";
        } else if (actionType === constants.IMPORT) {
          return "68%";
        } else if (actionType === constants.FILTER) {
          return "60%";
        } else if (actionType === LinkToType.TYPE_CLICK_TO_CALL) {
          return "45%";
        } else if (
          actionType === LinkToType.TYPE_CALENDAR_SETTINGS ||
          actionType === LinkToType.TYPE_JOB_CALENDAR_SETTINGS
        ) {
          return "40%";
        } else if (actionType === constants.EMAIL_DETAILS) {
          return "60%";
        } else if (actionType === constants.DOCUSIGN) {
          return "35%";
        } else if (actionType === constants.MY_INTEGRATIONS) {
          return "60%";
        } else if (actionType === constants.CALL_SCRIPT) {
          return "50%";
        } else if (actionType === LinkToType.TYPE_SF_CUSTOM_DATA_TABLE) {
          return "70%";
        } else if (actionType === constants.CORPORATE_CURRENCY_OBJECT) {
          return "35%";
        } else if (actionType === constants.UNSUBSCRIBE) {
          return "25%";
        } else if (actionType === constants.SEND_SMS) {
          return "40%";
        } else if (actionType === constants.NOTIFICATION_REMINDERS) {
          return "60%";
        } else if (actionType === constants.SMS_DETAILS) {
          return "60%";
        } else if (actionType === constants.USEFUL_LINK) {
          return "35%";
        } else if (actionType === constants.HUB_CASE_CLOSE) {
          return "35%";
        } else if (actionType === constants.USER_REPORT) {
          return "60%";
        } else if (actionType === LinkToType.TYPE_QUOTATIONS) {
          return "75%";
        } else if (
          actionType === constants.LEASE_NUMBER ||
          actionType === constants.IMPORT_NUMBER
        ) {
          return "45%";
        } else if (actionType === constants.BRING_YOUR_OWN) {
          return "38%";
        } else if (actionType === constants.PHONE_NUMBER_SETUP) {
          return "42%";
        } else if (actionType === constants.PLAN_NAME) {
          return "35%";
        } else if (actionType === constants.EXCEPTION) {
          return "65%";
        } else if (actionType === constants.NEEDS_APPROVAL) {
          return "65%";
        } else if (actionType === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
          return "60%";
        } else {
          return "83%";
        }
      }
    } catch (error) {
      console.error("Error in 'AppContainer.js -> getDrawerWidth()':" + error);
    }
  };
  handleDrawerClose = () => {
    let activeTab = getActiveTab();
    document.documentElement.style.overflow = "auto";
    let prevScrollPosition = this.props.detailView.scrollPosition;
    this.props.detailView.prevScrollPosition = prevScrollPosition;
    let info = activeTab.info;
    if (
      isValidParam(info) &&
      info.hasOwnProperty("isUnitsAccount") &&
      info.hasOwnProperty("UnitsAccountProjectId") &&
      info.isUnitsAccount
    ) {
      delete info.isUnitsAccount;
      delete info.UnitsAccountProjectId;
    }
    this.props.getAppDrawer(false, null, null, null, null);
  };

  appContainerSnackBarAction = () => {
    this.props.appContainer.drawerProps.isOpenDrawer = true;
    this.setState({ ...this.state, isSnackbarOpen: false });
  };

  render() {
    let actionDialogName = this.props.appContainer.drawerProps.actionDialogName;
    document.documentElement.style.overflow = "auto";
    let object = "";
    let suffix = "";
    try {
      object = this.props.match?.params?.object;
      suffix = this.props.match?.params?.suffix;
    } catch (ex) { }

    if (
      isValidParam(this.props.appContainer.drawerProps.data) &&
      this.props.appContainer.drawerProps.data.hasOwnProperty("object")
    ) {
      object = this.props.appContainer.drawerProps.data.object;
    }

    let isWaiting = getBooleanParam(
      this.props.appContainer.drawerProps.isWaiting
    );
    isWaiting = false;
    try {
      if (object === "") object = this.props.object;
      if (suffix === "") suffix = this.props.suffix;

      if (!isWaiting) {
        this.state.drawerComponent = this.props.appContainer.drawerProps
          .isOpenDrawer
          ? this.getDrawerComponent(
            object,
            this.props.appContainer.drawerProps.actionType
          )
          : null;
      }
    } catch (error) {
      console.error("Error in 'AppContainer.js -> render()':" + error);
    }
    if (
      (this.props.appContainer.drawerProps.isOpenDrawer && !isWaiting) ||
      (this.props.appContainer.customDrawerProps.isOpen && !isWaiting)
    ) {
      this.state.drawerWidth = this.getDrawerWidth(
        object,
        this.props.appContainer.drawerProps.actionType
      );
      document.documentElement.style.overflow = "hidden";
    }

    var fullWidth = window.innerWidth;
    var drawerWidthP = this.state.drawerWidth;
    var dw = drawerWidthP?.split("%")[0];
    var drawerWidth = fullWidth * 0.01 * parseInt(dw);
    let dHeight = window.innerHeight - 80;
    var dialogTitleStyle = {
      color: "#fff",
      paddingLeft: "24px",
      fontWeight: 400,
      fontSize: "24px",
      textAlign: "left",
      height: "48px",
      lineHeight: "48px",
    };
    if (
      this.props.appContainer.drawerProps.actionType ===
      LinkToType.TYPE_RELATIONSHIP
    ) {
      dialogTitleStyle = {
        color: "#fff",
        paddingLeft: "24px",
        fontWeight: 400,
        fontSize: "24px",
        textAlign: "left",
        height: "48px",
        lineHeight: "48px",
        fontFamily: "sans-serif",
      };
    }

    return (
      <div style={{ marginTop: "5px" }}>
        {this.state.view}
        <Drawer
          anchor="right"
          open={this.props.appContainer.drawerProps.isOpenDrawer}
        >
          <div style={{ width: drawerWidth }}>
            <Toolbar style={{ backgroundColor: "#717171" }}>
              <Typography variant="h6" style={dialogTitleStyle}>
                <div
                  style={{
                    width: drawerWidth - 80,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {actionDialogName}
                </div>
              </Typography>
              <IconButton
                onClick={this.handleDrawerClose}
                aria-label="close"
                className="SF-float-right"
                style={{
                  color: "#fff",
                  marginRight: "1px",
                  marginLeft: "10px",
                }}
              >
                <Icon
                  fontSize="large"
                  style={{ fontSize: "24px" }}
                  className="material-icons SF-text-white"
                >
                  close
                </Icon>
              </IconButton>
            </Toolbar>
            <div
              className="drawer"
              style={{
                height: suffix === "filters" ? dHeight : "100%",
                overflowX:
                  this.props.match?.params?.object === "onboarding"
                    ? "hidden"
                    : "auto",
                overflowY: suffix === "filters" ? "auto" : "hidden",
                paddingTop:
                  this.props.appContainer.drawerProps.actionType ===
                    constants.EMAIL_OBJECT
                    ? "0px"
                    : "10px",
              }}
            >
              {this.state.drawerComponent}
            </div>
          </div>
        </Drawer>

        <Snackbar
          open={this.state.isSnackbarOpen}
          message={this.props.appContainer.drawerProps.actionDialogName}
          onClose={() => this.setState({ isSnackbarOpen: false })}
          action={
            <Icon
              style={{ marginTop: 5 }}
              title={getLocalizedStrings().label.COMMON.RESTORE}
            >
              web_asset
            </Icon>
          }
          onActionClick={this.appContainerSnackBarAction}
          style={{ left: "90%" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          transitionDuration={{ enter: 100, exit: 100 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
    appContainer: state.appContainer,
    detailView: state.detailView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
      dispatch(
        getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom)
      );
    },
  };
};

AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppContainer);

export default AppContainer;