import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import replace from 'lodash/replace';
import map from 'lodash/map';
import { isValidParam, getStringParam, getObjectParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { Menu, Select, MenuItem, InputLabel, ListItemText, Checkbox } from '@mui/material';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { setTabs } from '../../../services/actions/tabActions';
import { setHeader } from '../../../services/actions/headerActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { isValidateFormField } from '../../../services/helper/formValidator';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { dateTimeFormat, dateFormat, timeZone, dateTimeFormatFlatPicker, dateFormatFlatPicker, getMultilingualLabelName } from '../../../services/helper/utils';
import moment from 'moment';
import {updateActiveTab} from '../../../services/helper/sfTabManager';
import { addTab,  getSelectedObjectLabel, getObjectLabelByObject, hasAccessPermission } from '../../../services/helper/common';
import { updateAppointmentStore, deleteFromAppointmentStore } from '../../../services/actions/calendarActions';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { batchEditAction } from '../../../services/actions/batchActions';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import ShowCircularProgress from '../components/circularProgress';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getActiveTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import * as sfDialogs from '../components/sfDialogs';
import Flatpickr from 'react-flatpickr';
import { isAssignRuleExists } from '../../../services/actions/assignmentRuleActions';
import Autocomplete from '@mui/material/Autocomplete';
import { refreshDetailViewData, refreshDetailViewListViewData,detailViewIsMounted,isDetailViewSet } from '../../../services/actions/detailViewActions';


/**
 * 
 * @Author  : Sougata.b
 * @Date:   : 2017-10-10
 */

const mapStateToProps = (state) => {
    return {
        appContainer: state.appContainer,
        tab: state.tab,
        app: state.app,
        sfForm: state.sfForm,
        detailView: state.detailView,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        setTabs: (tabs, activeTabIndex) => {
            dispatch(setTabs(tabs, activeTabIndex));
        },
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        updateAppointmentStore: (dataObject) => {
            dispatch(updateAppointmentStore(dataObject));
        },
        deleteFromAppointmentStore: (id) => {
            dispatch(deleteFromAppointmentStore(id));
        },
        refreshDetailViewListViewData: (isRefresh) => {
            dispatch(refreshDetailViewListViewData(isRefresh));
        },
        getTasksRefresh: (isrefresh) => {
            dispatch(getTasksRefresh(isrefresh));
        },
        refreshDetailViewData: (isRefresh) => {
            dispatch(refreshDetailViewData(isRefresh));
        },
        detailViewIsMounted: (isRefresh) => {
            dispatch(detailViewIsMounted(isRefresh));
        },
        isDetailViewSet: (isRefresh) => {
            dispatch(isDetailViewSet(isRefresh));
        },
    }
};


class BatchEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldListTarget: null,
            selectedField: "",
            openFieldList: false,
            mounted: false,
            openMode: constants.SF_FORM_OPEN_MODE_QUICK,
            fields: [],
            fieldProps: {},
            lookupInfo: [],
            reminder: [
                {
                    t_reminder_type: 'Email',
                    t_time_value: '10',
                    t_time_type: 'minutes'
                },
                {
                    t_reminder_type: 'Pop-up',
                    t_time_value: '10',
                    t_time_type: 'minutes'
                }
            ],
            recurring_fields: {},
            recurring_enable: false,
            recurring_edit: false,
            note_data: null,
            start_date: null,
            popoverTarget: null,
            isOpenPopover: false,
            listValueMenuItems: {},
            isWorkflowShow: false,
            redirect: false,
            redirectUrl: '',
            linkInfo: null,
            location: null,
            parentRecurId: 0,
            showReminder: false,
            recurringEditConfirmDialog: false,
            save_action_type: '',
            isSetActionType: false,
            tempSelectedField: "",
            isAssignRuleExists: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.tab.activeTabIndex !== this.props.tab.activeTabIndex) {
            this.setFormHeader(nextProps);
        } else if (nextProps.label !== this.props.label) {
            this.setFormHeader(nextProps);
        }
        if (nextProps.object != this.props.object || nextProps.openMode != this.props.openMode) {
            this.getDataAndProcess(nextProps);
        }
    }

    componentDidMount() {
        this.getDataAndProcess(this.props);
    }


    getDataAndProcess = (props) => {
        let data = props.sfForm.data[props.object];

        if (!isValidParam(data)) {
            this.getFormData();
        } else {
            this.processFormData(data, props.openMode);
        }
    }

    componentDidUpdate() {
        this.addFloatingLabelEvent();
    }

    addFloatingLabelEvent = () => {
        let fields = this.state.fields.filter(f => {
            return (f.field_type == constants.FIELD_TYPE_DATE_TIME || f.field_type == constants.FIELD_TYPE_DATE)
        });
        fields.map(f => {
            let value = this.state.fieldProps[f.name].value;
            if (value != '') {
                let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + f.name);
                if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                    dateTimeDiv[0].classList.add('label-top');
                }
            }
        });
    }

    setFormHeader = (nextProps) => {
        let kanbanObject = {};
        kanbanObject.currentView = null;
        kanbanObject.show = false;
        this.props.setHeader(nextProps.object, this.props.appContainer.drawerProps.actionDialogName, kanbanObject, false, true);
    }

    getFormData = () => {
        try {
            var promise = SFFormActions.getSFFormFieldsPromise(this.props.object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    this.props.sfForm.data[this.props.object] = response;
                    this.processFormData(response, this.props.openMode);
                    this.setState({ mounted: true });
                }
            });
        } catch (error) {
            console.error("Error in 'sfForm.js -> getFormData()':" + error);
        }
    }

    processFormData = (data, openMode) => {
        try {
            if (isValidParam(data)) {
                data = JSON.parse(JSON.stringify(data));
                if (openMode === constants.SF_FORM_OPEN_MODE_QUICK) {
                    this.state.fields = filter(data.fields, function (field) {
                        return find(data.expanded_fields, function (expandedField) {
                            return expandedField === field.name;
                        });
                    });
                }
                if (data.expanded_fields === null) {
                    this.state.fields = data.fields;
                }

                this.state.fields = filter(data.fields, function (field) {
                    return (field.field_type !== "header" && field.name !== "" && field.label !== "" && field.name !== "parent_recordid");
                });

                if (this.props.object === constants.ACCOUNTS_OBJECT) {
                    this.state.fields = filter(this.state.fields, function (field) {
                        return (field.name !== "royalty_percent");
                    });
                }
                if(this.props.object == 'products' || this.props.object == 'contacts'){
                    this.state.fields = filter( this.state.fields, function (field) {
                        return (field.name !== "t_status");
                    });
                }
                if (this.props.object === constants.TASKS) {
                    this.state.fields = filter(this.state.fields, function (field) {
                        return (field.name !== "company" && field.name !="fullname" && 
                        field.name !== "opportunity_name" && field.name !== "start_date" &&
                        field.name !== "end_date" && field.name !== "dep_start_date" && 
                        field.name !== "task_start_date");
                    });
                }

                if (this.props.object === constants.APPOINTMENTS) {
                    this.state.fields = filter(this.state.fields, function (field) {
                        return (field.name !== "company" && field.name !="fullname" && field.name !== "opportunity_name" && field.name !== 'project_name');
                    });
                }
                
                this.getRecord();
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> processFormData()':" + error);
        }
    }

    setWFMenuItemList = (workflowId, name, queryName, listType) => {
        try {
            if (workflowId > 0) {
                let params = {};
                params.object = this.props.object;
                params.id = workflowId;
                params.name = name;
                params.query_name = queryName;
                params.query_type = 'customQuery';
                params.list_type = listType;

                var promise = SFFormActions.getFieldListValuesPromise(params);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        this.setListValueMenuItems({ name: 't_status', list_values: response, is_required_field: false });
                        this.state.fieldProps['t_status'].value = response[0].value;
                        this.setState({ listValueMenuItems: this.state.listValueMenuItems });
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> setWFMenuItemList()':" + error);
        }
    }

    setStateMenuItemList = (value) => {
        try {
            let params = new Object();
            params.object = this.props.object;
            params.name = 'state';
            params.value = value;
            params.list_type = this.state.fieldProps[params.name].listType;

            var promise = SFFormActions.getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    let isRequired = getBooleanParam(this.state.fieldProps['state'].is_required_field);
                    this.setListValueMenuItems({ name: params.name, list_values: response, is_required_field: isRequired });
                    this.state.fieldProps[params.name].value = response[0]?.value;
                    this.setState({ listValueMenuItems: this.state.listValueMenuItems });
                }
            });
        } catch (error) {
            console.error("Error in 'sfForm.js -> setStateMenuItemList()':" + error);
        }
    }

    getRecord = () => {
        let promise = null;
        try {
            if (this.props.recordId > 0) {
                promise = SFFormActions.getRecordPromise(this.props.object, this.props.recordId, null);
            }

            if (promise !== null) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        this.updateState(true, response.records);
                        if (this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS) {
                            if (response.is_repeat === true) {
                                this.setRecurringState(response.recurring_fields[0]);
                            }
                            this.setReminderData(response.reminder);
                            this.state.parentRecurId = response.records.parent_recordid
                        }

                    }
                });
            } else {
                this.updateState(true);
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> getRecord()':" + error);
        }
    }

    setQuickAddFormValues = (fieldProps) => {
        let data = getObjectParam(this.props.appContainer.drawerProps.data);
        if (isValidParam(data) && data.hasOwnProperty('formValues')) {
            let formValues = getObjectParam(data.formValues);

            Object.keys(formValues).forEach((fieldName) => {
                let value = getObjectParam(formValues[fieldName].value);
                if (value !== '') {
                    fieldProps[fieldName].value = formValues[fieldName].value;
                }
            });
        }
    }

    handlePopover = (fieldName, event) => {
        if ((fieldName === 'workflow_name' || fieldName === 't_status')
            && this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED) {
            return;
        }
        event.preventDefault();
        this.state.fieldProps[fieldName].isOpenPopover = true;
        this.state.fieldProps[fieldName].popoverTarget = event.currentTarget;
        this.setState({ fieldProps: this.state.fieldProps });
    };

    handleRequestClose = (fieldName) => {
        let _state = this.state;
        _state.fieldProps[fieldName].isOpenPopover = false;
        this.setState({ _state });
    };

    setFieldValue = (fieldName, fieldType, event, value) => {
        try {
            if (fieldType === constants.FIELD_TYPE_TEXT || fieldType === constants.FIELD_TYPE_FLOAT || fieldType === constants.FIELD_TYPE_MEMO || fieldType === constants.FIELD_TYPE_URL || fieldType === constants.FIELD_TYPE_INTEGER) {
                value = (event.target.value);
            }
            /*if (fieldType === constants.FIELD_TYPE_LIST) {
                value = value;
            }*/
            if (this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_TEXT
                || this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_FLOAT
                || this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_DATE_TIME
                || this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_MEMO) {
                if (fieldName === 'fullname') {
                    let items = getArrayParam(this.state.listValueMenuItems['fullname']);
                    let newItems = items.filter(f => {
                        return f.key === value;
                    });

                    if (newItems.length > 0) {
                        value = newItems[0].props.primaryText;
                        let linkInfo = this.state.linkInfo
                        linkInfo = linkInfo + ",9_" + newItems[0].key;
                        this.state.linkInfo = linkInfo;
                        this.state.location = newItems[0].props.email;
                    }
                }
                this.state.fieldProps[fieldName].value = value;
            } else if (this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST) {
                if (value === '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' ||
                    value === '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----') {
                    this.state.fieldProps[fieldName].isAddToThisList = true;
                    this.state.fieldProps[fieldName].value = '';
                } else if (fieldName === 'sales_rep' && value === 'Setup Assignment Rule') {

                    this.props.getAppDrawer(false, null, null, null, null);

                    this.openAssignmentRuleDrawer();

                } else {
                    this.state.fieldProps[fieldName].value = value;

                    if (fieldName === 'workflow_name') {
                        this.state.fieldProps['workflow_id'].value = event.currentTarget.id;
                        this.setWFMenuItemList(event.currentTarget.id,
                            this.state.fieldProps['t_status'].name,
                            this.state.fieldProps['t_status'].queryName,
                            this.state.fieldProps['t_status'].listType);
                    } else if (fieldName === 'country') {
                        this.setStateMenuItemList(value);
                    }
                }
            } else {
                this.state.fieldProps[fieldName].value = value;
            }
            this.state.fieldProps[fieldName].helperText = '';
            this.setState({ fieldProps: this.state.fieldProps });
            this.handleRequestClose(fieldName);

        } catch (error) {
            console.error('Error on batchEdit.js -> setFieldValue(): ' + error);
        }
    }

    setMultiSelectValue = (fieldName, event) => {
        if (this.state.fieldProps[fieldName].selectedListIds.indexOf(event.currentTarget.id) > -1) {
            this.state.fieldProps[fieldName].selectedListIds = replace(this.state.fieldProps[fieldName].selectedListIds, event.currentTarget.id + ',', '');
        } else {
            this.state.fieldProps[fieldName].selectedListIds = this.state.fieldProps[fieldName].selectedListIds + event.currentTarget.id + ',';
        }
        this.state.fieldProps[fieldName].selectedListValues = event.target.value;
        this.state.fieldProps[fieldName].value = getArrayParam(event.target.value).join();
        this.state.fieldProps[fieldName].helperText = '';
        this.setState({ fieldProps: this.state.fieldProps });
    }

    updateState = (isMounted, record) => {
        let fieldProps = new Object();
        let tempfield = null;
        let workflowId = 0;
        let defaultDate = null;
        try {
            this.state.fields.map((field, index) => {
                tempfield = new Object();
                tempfield.id = field.id;
                tempfield.name = field.name;
                tempfield.label = field.label;
                if (this.props.recordId == 0
                    && (this.props.object == constants.APPOINTMENTS)
                    && (field.name == 'start_date' || field.name == 'end_date')
                ) {
                    if (this.props.appContainer.drawerProps.data.timeSlot != null && this.props.appContainer.drawerProps.data.timeSlot != 'undefined') {
                        tempfield.value = this.props.appContainer.drawerProps.data.timeSlot[field.name];
                    } else {
                        let currentTime = moment().tz(timeZone[this.props.app.me.timezone]);
                        let remainder = currentTime.minute() % 15;
                        let start = currentTime.subtract(remainder, 'm').format(dateTimeFormat[this.props.app.me.date_format]);
                        let end = currentTime.add(15, 'm').format(dateTimeFormat[this.props.app.me.date_format]);
                        let defaultDateObj = {
                            start_date: start,
                            end_date: end
                        }
                        tempfield.value = defaultDateObj[field.name];
                    }
                } else {
                    if (field.field_type == constants.FIELD_TYPE_DATE_TIME) {
                        defaultDate = isValidParam(record) ? record[field.name] : field.default_value;
                        if (isValidParam(defaultDate) && defaultDate.trim() != '') {
                            let momentObj = moment(defaultDate);
                            defaultDate = momentObj.format(dateTimeFormat[this.props.app.me.date_format]);
                            tempfield.value = defaultDate == 'Invalid date' ? '' : defaultDate
                        }
                    } else if (field.field_type == constants.FIELD_TYPE_DATE) {
                        defaultDate = isValidParam(record) ? record[field.name] : field.default_value;
                        if (isValidParam(defaultDate) && defaultDate.trim() != '') {
                            let momentObj = moment(defaultDate);
                            defaultDate = momentObj.format(dateFormat[this.props.app.me.date_format]);
                            tempfield.value = defaultDate == 'Invalid date' ? '' : defaultDate
                        }
                    } else if (field.field_type == constants.FIELD_TYPE_INTEGER) {
                        tempfield.value = isValidParam(record) && getStringParam(record[field.name]) != '' ? record[field.name] : field.default_value;
                        tempfield.value = getIntParam(tempfield.value);
                    } else if (field.field_type == constants.FIELD_TYPE_TEXT) {
                        tempfield.value = '';
                    } else {
                        tempfield.value = '';
                    }

                }
                tempfield.fieldType = field.field_type;
                tempfield.listType = field.list_type;
                tempfield.selectedListIds = '';
                if (field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                    tempfield.selectedListValues = [];
                } else {
                    tempfield.selectedListValues = '';
                }
                tempfield.queryName = field.query_name;
                tempfield.isOpenPopover = false;
                tempfield.popoverTarget = null;
                tempfield.helperText = '';
                tempfield.list_values = field.list_values;

                if (isValidParam(tempfield.value) && tempfield.value == '' && tempfield.fieldType == 'list' && field.list_values.length > 0) {
                    tempfield.value = field.list_values[0].value;
                    if (tempfield.name == 'workflow_name') {
                        workflowId = field.list_values[0].id;
                        if (field.list_values.length > 1) {
                            this.state.isWorkflowShow = true;
                        }
                    }
                }
                fieldProps[field.name] = tempfield;
                if (tempfield.fieldType == 'list') {
                    if (field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                        field.list_values = field.list_values.filter(f => { return f.id != '' && f.value != '' });
                    }
                    this.setListValueMenuItems(field);
                }
            });
            if (fieldProps.hasOwnProperty('workflow_id')) {
                fieldProps['workflow_id'].value = workflowId;
            }


            this.setQuickAddFormValues(fieldProps);
            this.setState({ mounted: isMounted, fieldProps: fieldProps });

            if (constants.APPOINTMENTS != this.props.object && constants.TASKS != this.props.object) {
                this.setWFMenuItemList(workflowId,
                    fieldProps['t_status'].name,
                    fieldProps['t_status'].queryName,
                    fieldProps['t_status'].listType);
            }

            if (this.props.object == constants.ACCOUNTS_OBJECT
                || this.props.object == constants.CASES_OBJECT
                || this.props.object == constants.ISSUES_OBJECT) {
                this.isAssignmentRuleExists(fieldProps['sales_rep']);
            }
        } catch (error) {
            console.error("Error in 'sfForm.js -> updateState()':" + error);
        }
    }


    isAssignmentRuleExists = (field) => {
        let paramObj = { table_id: OBJECT_TABLEID_MAP[this.props.object] };
        var response = Promise.resolve(isAssignRuleExists(paramObj));
        response.then((response) => {
            if (response.hasOwnProperty('isExists')) {
                if (getBooleanParam(response.isExists)) {
                    this.state.isAssignRuleExists = true;
                    this.setListValueMenuItems(field);
                    this.setState({ isAssignRuleExists: true });
                }
            }
        });
    }

    setRecurringState = (recurringData) => {
        this.setState({ recurring_enable: true, recurring_fields: recurringData, recurring_edit: true });

    }

    setReminderData = (reminderData) => {
        this.setState({ reminder: reminderData });
    }

    getRecurringEditConfirmValue = (val) => {
        this.state.isSetActionType = true;
        this.state.save_action_type = val;
        this.props.getAppDialog(false, constants.RECURRING_EDIT_CONFIRM_DIALOG, null, null, null, null);
        this.saveSFForm();
    }

    saveSFForm = () => {

        if ((this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS)
            && this.state.recurringEditConfirmDialog
            && !this.state.isSetActionType
        ) {
            this.props.getAppDialog(true, constants.RECURRING_EDIT_CONFIRM_DIALOG, getLocalizedStrings().message.REPEAT_DIALOG.EDIT_RECUR_TITLE, this.getRecurringEditConfirmValue, null);
            return false;
        }

        let isNewRecord = false;
        let tempField = null;
        let isValidForm = true;
        let dateValidationRequire = false;
        let start_date = null;
        let end_date = null;
        let note_type = '';
        let note_text = '';
        let isDetailView = false;
        let object = null;
        let recordId = [];
        let selectedField = this.state.selectedField;
        let modifiedValue = null;
        let tempObj = "";
        let objectLabel = "";
            object = getStringParam(this.props.object);
            isDetailView = getBooleanParam(this.props.detailView.isDetailView);
            let itemLabel = null;
            let activeTab = getObjectParam(getActiveTab());
            let activeTabObject = getObjectParam(activeTab.object);
            if (activeTabObject === constants.SEARCH_RESULTS) {
                let activeTabInfo = getObjectParam(activeTab.info);
                let search = getObjectParam(activeTabInfo.search);
                object = getStringParam(search.object);
                objectLabel = getObjectLabelByObject(object);
            } else if (activeTabObject === constants.ADVANCE_SEARCH_RESULTS) {
                let activeTabInfo = getObjectParam(activeTab.info);
                let advanceSearch = getObjectParam(activeTabInfo.advanceSearch);
                object = getStringParam(advanceSearch.object);
                let objList = getArrayParam(this.props.app.objectList);
                if (objList.length > 0) {
                    let arr = objList.filter(f => f.name === object);
                    if (arr.length > 0) {
                        objectLabel = getStringParam(arr[0].label);
                    }
                }
            } else {
                if (isDetailView || this.props.object === constants.AB_CAMPAIGNS_OBJECT || this.props.object === constants.BROADCAST_CAMPAIGNS_OBJECT
                    || this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS) {
                    objectLabel = getObjectLabelByObject(object);
                } else {
                    objectLabel = getSelectedObjectLabel();
                }
            }

            if (this.props.selectedRecords !== null && this.props.selectedRecords.length > 0) {
                for (var i = 0; i < this.props.selectedRecords.length; i++) {
                    recordId.push(this.props.selectedRecords[i].id);
                }
            }

            let fieldProps = this.state.fieldProps;
            if (isValidParam(fieldProps)) {

                for (let i = 0; i < this.state.fields.length; i++) {
                    if (selectedField === this.state.fields[i].label) {
                        let formField = this.state.fields[i];
                        let fieldForSave = fieldProps[this.state.fields[i].name];
                        // code for required field alert ignore
                        let tempStatus = formField.is_required_field;
                        formField.is_required_field = false;
                        isValidForm = isValidateFormField(object, formField, fieldForSave);
                        formField.is_required_field = tempStatus;

                        if (!isValidForm) {
                            this.setState({ fieldProps: fieldProps });
                            this.props.showCustomSnackBar(fieldForSave.helperText, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                            break;
                        }
                        if ((object === constants.APPOINTMENTS)
                            && (formField.field_type === constants.FIELD_TYPE_DATE_TIME || formField.field_type === constants.FIELD_TYPE_DATE)
                        ) {
                            dateValidationRequire = true
                            if (formField.name === 'start_date') {
                                start_date = fieldForSave.value;
                            } else if (formField.name === 'end_date') {
                                end_date = fieldForSave.value;
                            }
                        } else if (object === constants.NOTES) {
                            if (formField.name === 'note_type') {
                                note_type = fieldForSave.value;
                            } else if (formField.name === 'note_text') {
                                note_text = fieldForSave.value;
                            }
                        }
                    }
                }

                if (dateValidationRequire && start_date !== null && end_date !== null) {
                    let startDateMomentObj = moment(start_date);
                    let endDateMomentObj = moment(end_date);
                    if (endDateMomentObj.diff(startDateMomentObj) <= 0) {
                        this.props.showCustomSnackBar(getLocalizedStrings().message.APPOINTMENTS_TASKS.INVALID_START_END_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                        isValidForm = false;
                    }
                }

                if (note_type !== "Note" && note_text.trim() === "" && isValidParam(fieldProps['note_text'])) {
                    fieldProps['note_text'].value = note_type;
                } else if (note_type === "Note" && note_text.trim() === "") {
                    this.props.showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.NOTE_TXT_CANNOT_BE_BLANK, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                    isValidForm = false;
                }

                if (isValidForm) {
                    let tempDateValue = null;
                    let params = {};
                    params.id = recordId;
                    params.parent_object = '';
                    params.parent_record_id = 0;
                    params.is_convert = false;
                    params.is_notify_sales_rep = false;
                    params.lookup_info = this.state.lookupInfo;



                    for (let key in fieldProps) {
                        if (fieldProps[key].label === selectedField) {
                            tempField = {};
                            tempField.id = fieldProps[key].id;
                            tempField.name = fieldProps[key].name;
                            if (fieldProps[key].fieldType === constants.FIELD_TYPE_DATE_TIME) {
                                if (typeof fieldProps[key].value === "string") {
                                    fieldProps[key].value = moment(fieldProps[key].value);
                                }
                                tempDateValue = fieldProps[key].value
                                if (isValidParam(tempDateValue)) {
                                    modifiedValue = tempDateValue.format(dateTimeFormat[this.props.app.me.date_format]);
                                    tempDateValue = tempDateValue.format("YYYY-MM-DD HH:mm:ss");
                                    tempField.value = tempDateValue;
                                }
                            } else if (fieldProps[key].fieldType === constants.FIELD_TYPE_DATE) {

                                if (typeof fieldProps[key].value === "string") {
                                    fieldProps[key].value = moment(fieldProps[key].value);
                                }
                                tempDateValue = fieldProps[key].value;
                                if (isValidParam(tempDateValue)) {
                                    modifiedValue = tempDateValue.format(dateFormat[this.props.app.me.date_format]);
                                    tempDateValue = tempDateValue.format("YYYY-MM-DD");
                                    tempField.value = tempDateValue;
                                }
                            } else if (fieldProps[key].fieldType === constants.FIELD_TYPE_INTEGER) {
                                tempField.value = getStringParam(fieldProps[key].value);
                                modifiedValue = getIntParam(fieldProps[key].value);
                            } else {
                                tempField.value = fieldProps[key].value.trim();
                                modifiedValue = fieldProps[key].value.trim();
                            }
                            params.fields = tempField;

                        }
                    };

                    if (params.fields.value !== '' && isValidParam(modifiedValue)) {
                        let msg = "'" + selectedField + "' of " + this.props.selectedRecords.length + " " + getLocalizedStrings().message.BATCH_ACTION.RECORD_WILL_BE_UPDATED + " '" + modifiedValue + "'. " + getLocalizedStrings().message.BATCH_ACTION.CONFIRM_MSG;
                        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => this.saveSFFormOnConfirm(params, selectedField, objectLabel, msg, object), null);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.BATCH_ACTION.BATCH_EDIT_BLANK, null);
                    }
                }
            }
       
    }

    saveSFFormOnConfirm = (params, selectedField, objectLabel, msg, object) => {
        this.setCookiesData(selectedField);
        let promise = batchEditAction(this.props.object, params);
        promise.then((response) => {
            if (isValidParam(response.status) && response.status === 0) {
                msg = this.props.selectedRecords.length + " " + objectLabel + " " + getLocalizedStrings().message.COMMON.HAVE_BEEN + " " + getLocalizedStrings().message.COMMON.UPDATE;
                this.props.getAppDrawer(false, null, null, null, null);
                this.props.showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                let tabInfo = getObjectParam(getActiveTabInfo());
                let params = null;
                if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                    params = {
                        search_field_name: getStringParam(tabInfo.searchFieldName),
                        search_text: getStringParam(tabInfo.searchText)
                    }
                }
                if (this.props.detailView.isDetailView) {
                    params = {
                        ...params,
                        isDetailView: true
                    }
                }
                if (this.props.detailView.isDetailView) {
                    this.props.detailViewIsMounted(false);
                    this.props.getTasksRefresh(false);
                   this.props.refreshDetailViewListViewData(true);
                   this.props.refreshDetailViewData(true);  
                } else {
                    let activeTab = getActiveTab();
                    activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
                    updateActiveTab(activeTab);
                    refreshListView(this.props.object, params);
                }
                 if(this.props.object == "products"){
                    refreshListView("products");
                 }
                if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                    this.props.getTasksRefresh(true);
                }
                if ( object === constants.TASKS && this.props.detailView.isDetailView === true) {
                    this.props.detailViewIsMounted(false);
                    this.props.isDetailViewSet(false);
                    //this.props.refreshDetailViewListViewData(true);
                    this.props.refreshDetailViewData(true);                }
            }
        });

    }

    getRecordTitle = () => {
        let label = '';
        let fieldProps = getObjectParam(this.state.fieldProps);
        let object = getStringParam(this.props.object);
        if (object === constants.ACCOUNTS_OBJECT) {
            label = getStringParam(fieldProps['company'].value).trim();
        } else if (object === constants.CONTACTS_OBJECT) {
            label = getStringParam(fieldProps['first_name'].value).trim();
            label += ' ' + getStringParam(fieldProps['last_name'].value).trim();
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            label = getStringParam(fieldProps['name'].value).trim();
        } else if (object === constants.CASES_OBJECT) {
            label = getStringParam(fieldProps['synopsis'].value).trim();
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            label = getStringParam(fieldProps['custom_field4'].value).trim();
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            label = getStringParam(fieldProps['custom_field4'].value).trim();
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            label = getStringParam(fieldProps['custom_field4'].value).trim();
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            label = getStringParam(fieldProps['custom_field4'].value).trim();
        }

        label = label.trim();
        label = label === '' ? 'Empty' : label;
        return label;
    }

    getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                key='update'
                onClick={this.saveSFForm}
                style={{ ...styles.primaryButton, marginRight: this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK ? '12px' : '0px', verticalAlign: 'top' }}
            >{getLocalizedStrings().label.COMMON.UPDATE}</Button>
        );

        return buttons;
    }

    handleFlatpkrDateChange = (fieldName, date) => {
        try {
            if (isValidParam(date) && date.length > 0) {
                let selectedDate = new Date(date[0]);
                let dateValue = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes())
                const momentDate = moment(dateValue);
                if (moment !== null && moment !== "") {
                    if (this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_DATE) {
                        this.state.fieldProps[fieldName].value = momentDate.format(dateFormat[this.props.app.me.date_format]);
                    } else if (this.state.fieldProps[fieldName].fieldType === constants.FIELD_TYPE_DATE_TIME) {
                        this.state.fieldProps[fieldName].value = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
                    }
                    this.state.fieldProps[fieldName].helperText = '';
                    if (this.props.object === constants.APPOINTMENTS) {
                        if (fieldName === 'start_date') {
                            this.state.fieldProps['end_date'].value = momentDate.add(constants.CALENDER_DEFAULT_INTERVAL, 'm').format(dateTimeFormat[this.props.app.me.date_format]);
                        }
                    }
                } else {
                    this.state.fieldProps[fieldName].value = null;
                }
            } else {
                this.state.fieldProps[fieldName].value = null;
            }
        } catch (error) {
            console.error("Error in 'batchEdit.js -> handleFlatpkrDateChange()':" + error);
        }
        this.setState({ fieldProps: this.state.fieldProps });
    }

    getRecurringData = (obj) => {

        if (this.state.recurring_edit
            && this.state.recurring_enable
            && JSON.stringify(this.state.recurring_fields) !== JSON.stringify(obj.recurring_details)
        ) {
            this.setState({ recurringEditConfirmDialog: true });
        }
        this.setState({ recurring_fields: obj.recurring_details, recurring_enable: true });
        this.props.getAppDialog(false, constants.REPEAT_DIALOG, null, null, null, null);
    }

    setListValueMenuItems = (field) => {
        let menuItems = null;
        let fieldName = null;
        let listValues = null;
        let isRequired = false;
        let isModifyList = false;
        let hasPermission = false;
        try {
            fieldName = getStringParam(field.name);
            listValues = getArrayParam(field.list_values);
            isRequired = getBooleanParam(field.is_required_field);
            isModifyList = getBooleanParam(field.is_modify_list);
            hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);

            menuItems = []
            listValues.map((listValue, listValueIndex) => {
                let tempObj = {};
                tempObj.key = listValue.id;
                tempObj.value = listValue.value;
                tempObj.label = listValue.value;
                tempObj.id = listValue.id;
                menuItems.push(tempObj);
            })
            let userId = getIntParam(this.props.app.me.id);
            let isTenant = getBooleanParam(this.props.app.me.is_tenant);
            if (isModifyList && (userId === 1 || hasPermission) && this.props.object !== constants.TASKS) {
                let primaryText = fieldName === 'source' ? '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' : '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----'
                menuItems.push(<MenuItem key={'addto_' + fieldName} value={primaryText} id={'addto_' + fieldName} style={styles.popoverMenuItem} >{primaryText}</MenuItem>);
            }

            if (fieldName === 'sales_rep' && (this.props.object === constants.ACCOUNTS_OBJECT || this.props.object === constants.CASES_OBJECT || this.props.object === constants.ISSUES_OBJECT)) {
                if (getStringParam(this.props.app.me.t_name) === 'Admin' ||
                    getBooleanParam(this.props.app.me.is_tenant)) {
                    let primaryText = "Setup Assignment Rule";
                    menuItems.push(<MenuItem key={primaryText} value={primaryText} id={primaryText} style={styles.popoverMenuItem} >{primaryText}</MenuItem>);
                }
                if (this.state.isAssignRuleExists) {
                    let primaryText = "Use Assignment Rule";
                    menuItems.unshift(<MenuItem key={primaryText} value={primaryText} id={primaryText} style={styles.popoverMenuItem} >{primaryText}</MenuItem>);

                }
            }

            this.state.listValueMenuItems[fieldName] = menuItems;
        } catch (error) {
            console.error("Error in 'sfForm.js -> setListValueMenuItems()':" + error);
        }
        return menuItems;
    }

    isProjectTasks = () => {
        let isProjectTask = false;
        try {
            if (this.props.callFrom !== 'queueView' && this.props.object === constants.TASKS && this.props.parentDetails.hasOwnProperty('parent_object')) {
                if (this.props.parentDetails.parent_object === constants.PROJECTS_OBJECT) {
                    isProjectTask = true;
                }
            }
        } catch (error) {
            console.error("error in sfForm.js ->'isProjectTasks()'" + error);
        }
        return isProjectTask;
    }

    openLookup = (fieldName, isLookupField, lookupFieldName, lookupObject) => {
        let value = null;
        let moduleAccess = getObjectParam(this.props.app.me.module_access);
        let isOperations = moduleAccess[constants.MODULE_OPERATIONS];
        let drawerProps = this.props.appContainer.drawerProps;
        if (this.isProjectTasks() && isOperations === 1) {
            lookupObject = constants.UNITS_OBJECT;
        }

        if (this.props.object === constants.OPPORTUNITIES_OBJECT && isValidParam(drawerProps)) {
            let info = drawerProps.data;
            if (info.isDetailView && (drawerProps.actionType === constants.NEW || drawerProps.actionType === constants.EDIT)) {
                let field = null;
                if (lookupObject === constants.CONTACTS_OBJECT) {
                    field = this.state.fieldProps['contact_name'];
                    if (isValidParam(field)) {
                        if (getStringParam(field.value).length > 0) {
                            if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
                                value = '';
                            } else {
                                let exp = "(";
                                let contactName = getStringParam(field.value);
                                if (contactName.includes(exp)) {
                                    contactName = contactName.slice(0, contactName.indexOf(exp)).trim();
                                    value = contactName;
                                } else {
                                    value = contactName;
                                }
                            }
                        } else {
                            value = '';
                        }
                    }
                } else if (lookupObject === constants.ACCOUNTS_OBJECT) {
                    field = this.state.fieldProps['company'];
                    if (isValidParam(field)) {
                        if (getStringParam(field.value).length > 0) {
                            if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
                                value = '';
                            } else {
                                value = getStringParam(field.value);
                            }
                        }
                    }
                } else {
                    value = this.state.fieldProps[fieldName].value;
                }
            } else {
                if (drawerProps.actionType === constants.NEW || drawerProps.actionType === constants.EDIT) {

                    if (lookupObject === constants.CONTACTS_OBJECT) {
                        let field = this.state.fieldProps['contact_name'];
                        if (getStringParam(field.value).length > 0) {
                            if (getStringParam(field.value) === "(" + constants.EMPTY + ")") {
                                value = '';
                            } else {
                                let exp = "(";
                                let contactName = getStringParam(field.value);
                                if (contactName.includes(exp)) {
                                    contactName = contactName.slice(0, contactName.indexOf(exp)).trim();
                                    value = contactName;
                                } else {
                                    value = contactName;
                                }
                            }
                        } else {
                            value = '';
                        }
                    }
                    else if (lookupObject === constants.ACCOUNTS_OBJECT) {
                        let hasContact = getStringParam(this.state.fieldProps["contact_name"].value).length > 0 ? true : false;
                        let hasAccount = getStringParam(this.state.fieldProps["company"].value).length > 0 ? true : false;
                        if (hasContact) {
                            if (!hasAccount) {
                                if (getArrayParam(this.state.lookupInfo).length > 0) {
                                    this.state.lookupInfo.map(m => {
                                        if (m.hasOwnProperty("company")) {
                                            value = getStringParam(m.company);
                                        }
                                    });
                                }
                            } else {
                                value = this.state.fieldProps['company'].value;
                            }
                        }
                    }
                    else {
                        value = this.state.fieldProps[fieldName].value;
                    }
                }
            }
        } else {
            value = this.state.fieldProps[fieldName].value;
        }
        let data = {};
        data.fieldName = getStringParam(fieldName);
        data.searchText = getStringParam(value);
        data.isLookupField = getBooleanParam(isLookupField);
        data.lookupFieldName = getStringParam(lookupFieldName);
        data.lookupObject = getStringParam(lookupObject);
        this.props.getAppDialog(true, constants.LOOKUP_DIALOG, null, this.getLookupSelectedRecord, data, null);
    }

    getLookupSelectedRecord = (lookupInfo) => {
        lookupInfo = getObjectParam(lookupInfo);

        let fields = this.state.fields.filter(f => f.is_lookup_field === true);
        let fieldName = fields.length > 0 ? fields[0].name : '';
        this.state.fieldProps[fieldName].value = lookupInfo.title == undefined ? lookupInfo?.arrLookupRecordInfo[0].title: lookupInfo.title;
        if (this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS) {
            let menuItems = [];
            let parentRecordId = lookupInfo.record_id;
            let params = {};
            params.parent_table_id = 10;
            params.child_table_id = 9;
            params.parent_record_ids = parentRecordId;
            params.child_fields = "id,email, CONCAT(COALESCE(first_name,''), ' ',COALESCE(last_name,'')) as fullname";

            var promise = SFFormActions.getLinkRecords(constants.ACCOUNTS_OBJECT, params);
            if (isValidParam(promise)) {
                promise.then((response) => {
                    let childRecordArr = response.linkRecords;
                    if (childRecordArr !== null && childRecordArr !== undefined) {
                        map(childRecordArr, (childObject, index) => {
                            let childRecordId = childObject.id;
                            let fullName = childObject.fullname;
                            let email = childObject.email;
                            menuItems.push(<MenuItem key={childRecordId} value={childRecordId} id={childRecordId} email={email} style={styles.popoverMenuItem} > {fullName}</MenuItem>);
                        });
                        let linkInfo = "10_" + parentRecordId;
                        this.state.linkInfo = linkInfo;
                        this.state.listValueMenuItems['fullname'] = menuItems;
                        this.setState({ listValueMenuItems: this.state.listValueMenuItems })
                    }
                });
            }
        }
        this.setState({ lookupInfo: [lookupInfo], fieldProps: this.state.fieldProps });
        this.props.getAppDialog(false, null, null, null, null, null);
    }

    sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    getFieldlistmenu = (object) => {
        let listValues = getArrayParam(this.state.fields);
        listValues = this.sortByKey(listValues, 'label');
        let menuItems = [];
        let itemCount = 0;
        let tempSelectedField = '';
        if(object == 'tasks'){
            listValues = listValues.filter((f)=> {
                return (f.name !== "t_attachment" && f.name !== "t_unit_owner" && f.name !== "location" )
            })   
        }
        if(object == 'cases'){
            listValues = listValues.filter((f)=> {
                return (f.name !== "cc" && f.name !== "from_email" )
            })   
        }
        if(object == constants.CONTACTS_OBJECT){
            listValues = listValues.filter((f)=> {
                return (f.name !== "shared_units" )
            })   
        }
        //removing secondary source for all object
        listValues = listValues?.filter((ele)=> ele.name !== "sc_source");

        if (!isNull(listValues) && !isUndefined(listValues)) {
            listValues.map((listValue, listValueIndex) => {
                if (object === constants.TASKS && !listValue.is_hidden_field && !listValue.is_readonly && listValue.name !== "t_status" &&
                    listValue.name !== "t_completedate") {
                    menuItems.push(<MenuItem onClick={this.setFieldList.bind(this, listValue.label)} key={listValue.id} value={listValue.label} id={listValue.id} style={styles.popoverMenuItem} >{getMultilingualLabelName(object, listValue.label)} </MenuItem>);
                } else if (object === constants.NOTES && !listValue.is_hidden_field && !listValue.is_readonly && listValue.name !== "note_category" &&
                    listValue.name !== "note_type") {
                    menuItems.push(<MenuItem onClick={this.setFieldList.bind(this, listValue.label)} key={listValue.id} value={listValue.label} id={listValue.id} style={styles.popoverMenuItem} >{listValue.label}</MenuItem>);
                } else if (object !== constants.AUTOMATION_DESIGNER_OBJECT && object !== constants.GROUP_OBJECT && object !== constants.TASKS && !listValue.is_hidden_field && !listValue.is_readonly && listValue.name !== "workflow_id" &&
                    listValue.name !== "workflow_name" && listValue.name !== "opportunity_amount" && listValue.name !== "t_editor_type") {
                    menuItems.push(<MenuItem onClick={this.setFieldList.bind(this, listValue.label)} key={listValue.id} value={listValue.label} id={listValue.id} style={styles.popoverMenuItem} >{getMultilingualLabelName(object, listValue.label)}</MenuItem>);
                } else if (object === constants.GROUP_OBJECT && !listValue.is_readonly && listValue.name !== "name") {
                    menuItems.push(<MenuItem onClick={this.setFieldList.bind(this, listValue.label)} key={listValue.id} value={listValue.label} id={listValue.id} style={styles.popoverMenuItem} >{listValue.label}</MenuItem>);
                } else if (object === constants.AUTOMATION_DESIGNER_OBJECT && listValue.name === "t_name") {
                    menuItems.push(<MenuItem onClick={this.setFieldList.bind(this, listValue.label)} key={listValue.id} value={listValue.label} id={listValue.id} style={styles.popoverMenuItem} >{listValue.label} </MenuItem>);
                }
            });
        }
        menuItems.forEach((item, index) => {
            if (isValidParam(item) && itemCount === 0) {
                tempSelectedField = getStringParam(item.props.value);
                itemCount += 1;
            }
        })

        let cookieVal = this.getCoockieData(tempSelectedField);
        this.state.selectedField = getStringParam(this.state.selectedField);
        if (isValidParam(listValues) && listValues.length > 0) {
            let arr = listValues.filter(f => f.label === cookieVal);
            this.state.tempSelectedField = arr.length > 0 ? getStringParam(arr[0].label) : getStringParam(listValues[0].label);
        }
        if (this.state.selectedField === '' && this.state.tempSelectedField !== '') {
            this.state.selectedField = this.state.tempSelectedField;
        }
        return menuItems
    }

    getCoockieData = (selectedVal) => {
        let selectionField = null;
        try {
            let projectId = this.props.app.me.projectId;
            let userId = this.props.app.me.id;
            let cookieName = 'batchedit_' + projectId + '_' + userId + "_" + this.props.object;
            let cookieValue = getCookie(cookieName);
            if (!isValidParam(cookieValue) || (isValidParam(cookieValue) && getStringParam(cookieValue) === '')) {
                selectionField = selectedVal;
            } else {
                selectionField = cookieValue;
            }
        } catch (error) {
            console.error("Error in 'batchEdit.js --> getCoockieData()':" + error);
        }
        return selectionField;
    }

    setCookiesData = (selectedField) => {
        try {
            let projectId = this.props.app.me.projectId;
            let userId = this.props.app.me.id;
            let cookieName = 'batchedit_' + projectId + '_' + userId + "_" + this.props.object;
            if (isValidParam(selectedField)) {
                setCookie(cookieName, selectedField, constants.DEFAULT_COOKIE_EXPIRES);
            }
        } catch (error) {
            console.error("Error in 'batchEdit.js --> setCookiesData()':" + error);
        }
    }

    setFieldList = (value) => {
        this.setState({ selectedField: value })
    }

    addToThisList = (fieldName, event) => {
        try {
            let value = this.state.fieldProps[fieldName].value;
            value = getStringParam(value).trim();
            if (value !== '') {
                if (fieldName === 'source') {
                    let promise = SFFormActions.checkDuplicateSourceName(value);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.saveNewListValue(fieldName, value, getBooleanParam(response));
                        });
                    }
                } else {
                    let field = this.state.fields.filter(f => { return f.name === fieldName });
                    if (field.length > 0) {
                        let listValues = getArrayParam(field[0].list_values);
                        let tempValues = listValues.filter(f => { return f.value.toLowerCase() === value.toLowerCase() });
                        this.saveNewListValue(fieldName, value, tempValues.length > 0 ? true : false);
                    }
                }
            } else {
                let tempFields = this.state.fields.filter(f => { return f.name === fieldName });
                let fieldValue = getIntParam(this.props.recordId) > 0 && isValidParam(this.state.record) ? getStringParam(this.state.record[fieldName]) : '';
                fieldValue = fieldValue === '' && tempFields.length > 0 ? getStringParam(tempFields[0].default_value) : fieldValue;
                this.state.fieldProps[fieldName].isAddToThisList = false;
                this.state.fieldProps[fieldName].value = fieldValue;
                this.setState(this.state);
            }
        } catch (error) {
            console.error("Error in 'batchEdit.js -> addToThisList()':" + error);
        }
    }

    saveNewListValue = (fieldName, value, isExist) => {
        try {
            if (getBooleanParam(isExist)) {
                this.props.showCustomSnackBar('Value already exists in List', styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (fieldName === 'source') {
                let promise = SFFormActions.saveSourceName(value);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (getBooleanParam(response.data)) {
                            SFFormActions.addSourceOnRedux(constants.ACCOUNTS_OBJECT, fieldName, value);
                            SFFormActions.addSourceOnRedux(constants.CONTACTS_OBJECT, fieldName, value);
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].value = value;

                            this.state.fields.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    this.setListValueMenuItems(field);
                                }
                            });
                            this.setState(this.state);
                        }
                    });
                }
            } else {
                let field = this.state.fields.filter(f => { return f.name === fieldName });
                if (field.length > 0) {
                    let values = getArrayParam(field[0].list_values);
                    let listValues = [];
                    values.forEach(field => {
                        if (field.value !== '') {
                            listValues.push(field.value);
                        }
                    });
                    listValues.push(value);
                    let promise = SFFormActions.saveListValues(this.props.object, field[0].id, listValues);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].value = value;

                            this.state.fields.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({ id: value, value: value });
                                    this.setListValueMenuItems(field);
                                }
                            });
                            this.setState(this.state);
                        });
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'batchEdit.js -> saveNewListValue()':" + error);
        }
    }

    openAssignmentRuleDrawer = () => {
        let drawerName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;

        let obj = this.props.object;
        let data = {};
        data.object = constants.ASSIGNMENT_RULES;
        data.id = null;
        data.table = obj;
        data.mode = constants.NEW;
        data.callFrom = 'Assignment Rules';

        this.props.getAppDrawer(true, drawerName, constants.NEW, data, null);
    }

    render() {

        let fieldlistmenu = [];
        fieldlistmenu = this.getFieldlistmenu(this.props.object);
        let rowComponents = null;
        let component = null;
        let sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
        let fieldprops = getArrayParam(this.state.fieldProps)
        if (this.state.mounted) {
            return (

                <div style={{...styles.sfFormContainer,height: this.props.object === constants.CASES_OBJECT ? '': '200px'}} >
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="sf-batch-edit-Modify_field-from-batchedit-simple-select-outlined-label">{getLocalizedStrings().label.LIST_VIEW.MODIFY_FIELD}</InputLabel>
                        <Select
                            key={"Modify_field"}
                            name={"Modify_field"}
                            label={getLocalizedStrings().label.LIST_VIEW.MODIFY_FIELD}
                            labelId={"SF-form-Modify_field"}
                            value={this.state.selectedField}
                            style={{ height: '40px' }}
                            className={"sf-fields-bg"}
                        >
                            {
                                fieldlistmenu
                            }
                        </Select>
                    </FormControl>


                    {
                        this.state.fields.map((field, index) => {

                            if (field.label === this.state.selectedField) {
                                if (rowComponents === null) {
                                    rowComponents = [];
                                }
                                component = null;
                                let fieldLabel = null;
                                let defaultDate = null;
                                fieldLabel = field.label;
                                if (this.props.object === constants.APPOINTMENTS || this.props.object === constants.TASKS) {
                                    fieldLabel = getMultilingualLabelName(this.props.object, fieldLabel);
                                }
                                if (field.is_required_field) {
                                    fieldLabel = fieldLabel + getLocalizedStrings().label.COMMON.REQUIRED;
                                }
                                if (field.field_type === constants.FIELD_TYPE_TEXT
                                    || (field.field_type === constants.FIELD_TYPE_INTEGER && field.name !== 'workflow_id')
                                    || field.field_type === constants.FIELD_TYPE_FLOAT
                                    || field.field_type === constants.FIELD_TYPE_URL
                                    || (field.field_type === constants.FIELD_TYPE_LIST && field.name === 'workflow_name' && this.props.recordId > 0)
                                    || field.field_type === constants.FIELD_TYPE_CHECKBOX) {
                                    if ((field.field_type === constants.FIELD_TYPE_LIST && field.name === 'workflow_name' && this.props.recordId > 0)) {
                                        field.is_readonly = true;
                                    }
                                    
                                    component =
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                key={field.id}
                                                disabled={field.is_readonly}
                                                label={fieldLabel}
                                                InputLabelProps={{
                                                    style: {
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '96%',
                                                    }
                                                }}
                                                fullWidth={true}
                                                name={field.name}
                                                value={
                                                    this.state.fieldProps[field.name]?.value === 0?
                                                    field.default_value:
                                                    this.state.fieldProps[field.name]?.value === "" && field.field_type !== constants.FIELD_TYPE_TEXT ? 
                                                    field.default_value :
                                                    this.state.fieldProps[field.name]?.value}
                                                onChange={this.setFieldValue.bind(this, field.name, field.field_type)}
                                                style={sfFormFieldContainerStyle}
                                                className={"sf-fields-bg"}
                                                className={field.is_lookup_field ? "form-field-search-icon" : ""}
                                                autoComplete="new-password"
                                                margin='dense'
                                                size='small'
                                            />
                                        </FormControl>;
                                } else if (field.field_type === constants.FIELD_TYPE_LIST) {
                                    if (field.list_type === constants.LIST_FIELD_TYPE_REGULAR
                                        || field.list_type === constants.LIST_FIELD_TYPE_USER
                                        || field.list_type === constants.LIST_FIELD_TYPE_EXTERNAL) {

                         if (fieldprops.length> 0 && getBooleanParam(this.state.fieldProps[field.name].isAddToThisList)) {
                                            component =
                                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                    <TextField
                                                        variant="outlined"
                                                        key={field.id}
                                                        disabled={field.is_readonly}
                                                        label={fieldLabel}
                                                        InputLabelProps={{
                                                            style: {
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                width: '96%',
                                                            }
                                                        }}
                                                        fullWidth={true}
                                                        name={field.name}
                                                        value={this.state.fieldProps[field.name]?.value}
                                                        onChange={this.setFieldValue.bind(this, field.name, field.field_type)}
                                                        onBlur={this.addToThisList.bind(this, field.name)}
                                                        style={sfFormFieldContainerStyle}
                                                        className={"sf-fields-bg"}
                                                        className={field.is_lookup_field ? "form-field-search-icon" : ""}
                                                        inputprops={{ maxlength: this.state.fieldProps[field.name].field_length }}
                                                        autoComplete="new-password"
                                                        margin='dense'
                                                        size='small'
                                                    />
                                                </FormControl>;
                                        } else {
                                            let listValueMenuItems = isValidParam(this.state.listValueMenuItems[field.name]) ? this.state.listValueMenuItems[field.name] : [];

                                            component = <FormControl style={{ width: '100%' }}>
                                                <Autocomplete
                                                    style={{ width: '100%' }}
                                                    disableClearable
                                                    options={listValueMenuItems}
                                                    getOptionLabel={(option) => option.value ? option.value : ''}
                                                    value={listValueMenuItems.find(v => v.value === this.state.fieldProps[field.name]?.value)}
                                                    disabled={field.is_readonly}
                                                    id={field.name}
                                                    name={field.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={fieldLabel}
                                                            
                                                            variant="outlined"                                                            
                                                            maxLength={fieldprops.length>0 ?this.state.fieldProps[field.name].field_length:''}
                                                            inputprops={{ maxlength:fieldprops.length>0 ? this.state.fieldProps[field.name].field_length :'', ...params.InputProps }}
                                                            disabled={field.is_readonly}
                                                            fullWidth={true}
                                                            margin='dense'
                                                            size='small'
                                                            className={"sf-fields-bg"}
                                                            InputLabelProps={{
                                                                style: {
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    width: '90%',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    onChange={(event, value) => this.setFieldValue(field.name, field.fieldType, event, value.value)}
                                                />

                                            </FormControl>

                                        }

                                    } else if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                                        component = <FormControl variant="outlined" style={{ width: '100%' }}>
                                            <InputLabel id="sf-batch-edit-from-batchedit-simple-select-outlined-label">{fieldLabel}</InputLabel>
                                            <Select
                                                label={fieldLabel}
                                                key={field.id}
                                                multiple={true}
                                                value={this.state.fieldProps[field.name].selectedListValues}
                                                onChange={this.setMultiSelectValue.bind(this, field.name)}
                                                autowidth={"true"}
                                                style={{ height: '40px' }}
                                                className={"sf-fields-bg"}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {
                                                    field.list_values.map((listValue, listValueIndex) => {
                                                        return <MenuItem value={listValue.value}
                                                            id={listValue.id}
                                                            title={listValue.value}
                                                            key={listValue.id}
                                                            insetChildren={true}
                                                            style={styles.popoverMenuItem}>
                                                            <Checkbox checked={this.state.fieldProps[field.name].selectedListValues && this.state.fieldProps[field.name].selectedListValues.indexOf(listValue.value) > -1 ? true : false} />
                                                            <ListItemText primary={listValue.value} /></MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    }

                                } else if (field.field_type === constants.FIELD_TYPE_DATE_TIME
                                    || field.field_type === constants.FIELD_TYPE_DATE) {

                                    let dateTimeFormatValue = null;
                                    if (field.field_type === constants.FIELD_TYPE_DATE_TIME) {
                                        dateTimeFormatValue = dateTimeFormatFlatPicker[this.props.app.me.date_format];
                                    } else if (field.field_type === constants.FIELD_TYPE_DATE) {
                                        dateTimeFormatValue = dateFormatFlatPicker[this.props.app.me.date_format];
                                    }

                                    let HHFormat = new RegExp("HH");
                                    let datePickerOptions = {
                                        enableTime: field.is_readonly ? false : field.field_type === constants.FIELD_TYPE_DATE_TIME ? true : false,
                                        noCalendar: field.is_readonly ? true : false,
                                        dateFormat: dateTimeFormatValue,
                                        minuteIncrement: 1,
                                        time_24hr: HHFormat.test(this.props.app.me.date_format) ? true : false
                                    };

                                    component = <div className={'sf-form-field dateTimeDiv-' + field.name} id="dateholder" style={sfFormFieldContainerStyle}>
                                        <label id={field.name + '-datelbl'} className="date-label">{getMultilingualLabelName(this.props.object, field.label)}</label>
                                        <Flatpickr style={{ paddingLeft: '7px', marginBottom: '12px', height: '42px', width: '100%', border: '1px solid #dddddd' }}
                                            onChange={this.handleFlatpkrDateChange.bind(this, field.name)}
                                            value={this.state.fieldProps[field.name]?.value === "" ? Date.parse(defaultDate) : Date.parse(this.state.fieldProps[field.name]?.value)}
                                            options={datePickerOptions}
                                            className='flatpickr'
                                        />

                                    </div>
                                } else if (field.field_type === constants.FIELD_TYPE_MEMO) {
                                    component =
                                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <TextField
                                                variant="outlined"
                                                key={field.id}
                                                type='textarea'
                                                multiline={true}
                                                rows={3}
                                                label={fieldLabel}
                                                InputLabelProps={{
                                                    style: {
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '96%',
                                                    }
                                                }}
                                                disabled={field.is_readonly}
                                                onChange={this.setFieldValue.bind(this, field.name, field.field_type)}
                                                autoComplete="new-password"
                                                value={this.state.fieldProps[field.name]?.value === "" ? field.default_value : this.state.fieldProps[field.name]?.value}
                                                margin='dense'
                                                size='small'
                                                className={"sf-fields-bg"}
                                            />
                                        </FormControl>
                                }

                                if (this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK
                                    || (this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED && field.col_span === 2)
                                    || this.props.object === constants.APPOINTMENTS
                                    || this.props.object === constants.TASKS
                                    || this.props.object === constants.NOTES) {
                                        let divProps = {};
                                        if(field.is_lookup_field){
                                            divProps.display = 'flex'
                                        }
                                    component = <div className="col-two" id={field.name} key={field.name} style={{ marginTop: '3%', ...divProps ,position:'relative'}}>
                                        {
                                            (field.name !== 'fullname' || (field.name === 'fullname' && !isValidParam(this.state.listValueMenuItems[field.name]))) &&
                                            component
                                        }
                                        {field.is_lookup_field && <div style={{ marginLeft: '-33px', zIndex: 1, marginTop: '4%' }}>
                                            <span className="search-field-icon fa fa-search" onClick={this.openLookup.bind(this, field.name, field.is_lookup_field, field.lookup_field_name, field.lookup_object)}></span>
                                            </div>}

                                        {/**** START:   Appoinments/Tasks fullName */}
                                        {
                                            field.name === 'fullname' && this.state.listValueMenuItems[field.name] !== null &&
                                            <div
                                                style={{ ...sfFormFieldContainerStyle, 'width': '100%', cursor: "pointer", fontSize: '14px', color: '#717171', paddingTop: '15px' }}
                                                onClick={this.handlePopover.bind(this, field.name)}
                                                id={field.name}
                                                key={field.id + field.name}
                                            >
                                                <div>{fieldLabel}</div>
                                                {this.state.fieldProps[field.name]?.value === "" ? (field.default_value === "" && field.list_values.length > 0 ? field.list_values[0].value : "") : this.state.fieldProps[field.name]?.value}
                                                <Menu
                                                    key={field.name}
                                                    name={field.name}
                                                    open={this.state.fieldProps[field.name].isOpenPopover}
                                                    anchorEl={this.state.fieldProps[field.name].popoverTarget}
                                                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                    onClose={this.handleRequestClose.bind(this, field.name)}
                                                    value={this.state.fieldProps[field.name]?.value === "" ? field.label : this.state.fieldProps[field.name]?.value}
                                                    onChange={this.setFieldValue.bind(this, field.name, field.field_type)}
                                                    style={{ width: '100%' }}
                                                >
                                                    {
                                                        this.state.listValueMenuItems[field.name]
                                                    }
                                                </Menu>
                                                <i className="fa fa-angle-down" aria-hidden="true" style={{ paddingLeft: '7px' }}></i>
                                            </div>
                                        }
                                        {/**** END:   Appoinments/Tasks fullName */}

                                    </div>;
                                } else if ((this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED && field.col_span === 1)
                                    || (this.props.openMode === constants.SF_FORM_OPEN_MODE_CUSTOM)) {
                                    component = <div className="col-sm-6 col-two" id={field.name} key={field.name}>
                                        {component}
                                        {field.is_lookup_field && <span className="search-field-icon fa fa-search" onClick={this.openLookup.bind(this, field.name, field.is_lookup_field, field.lookup_field_name, field.lookup_object)}></span>}
                                    </div>;
                                }
                                if (field.name !== 'workflow_name'
                                    || (field.name === 'workflow_name' && this.state.isWorkflowShow)
                                    || (field.name === 'workflow_name' && this.props.openMode === constants.SF_FORM_OPEN_MODE_EXPANDED)) {
                                    rowComponents.push(component);
                                }


                                if ((this.props.openMode === constants.SF_FORM_OPEN_MODE_QUICK) || (this.props.openMode === constants.SF_FORM_OPEN_MODE_CUSTOM) || (field.col_span === 2) || (field.col_span === 1 && (index + 1) % 2 === 0)) {

                                    component = <div key={index} className="row"> {rowComponents} </div>;

                                    rowComponents = null;
                                    return component;
                                }


                            }
                        })
                    }
                    <div className="row">
                        <div  style={{ textAlign: 'right', marginTop: '10px', marginBottom: '20px' }}>
                            {this.getActionButtons()}
                        </div>
                    </div>
                </div >
            )
        } else {
            let contentHeight = window.innerHeight - 240;
            let top = (contentHeight - 10) / 2
            return (
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchEdit);

