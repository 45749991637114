import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { SelectColumn, textEditor, } from 'react-data-grid';
import '../../../asset/styles/css/react-data-grid.css';
import 'react-data-grid/lib/styles.css';
import isArray from 'lodash/isArray';
import toNumber from 'lodash/toNumber';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import '../../../asset/styles/css/react-data-grid.css';
import {
    isValidParam,
    getBooleanParam,
    getIntParam,
    getStringParam,
    getObjectParam,
    getArrayParam,
    isEmail
} from '../../../services/helper/parameterVerifier';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { saveListViewLayoutRowWidth, fetchLinkRecords } from '../../../services/actions/listViewAcions';
import { dateTimeFormat, dateFormat, getMultilingualLabelName } from '../../../services/helper/utils';
import GridDropdown from '../components/gridDropdown';
import * as SFFormActions from '../../../services/actions/sfFormActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowLeadScore from '../components/showLeadScore';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { retriveEscapeHtml } from '../../../services/helper/common';
import { addTab, TYPE_DETAIL_VIEW, TYPE_OTHER_VIEW, getActiveTab, updateActiveTab, updateTabInfo, getActiveTabInfo, addNewFormTabs } from '../../../services/helper/sfTabManager';
import DetailLink from '../components/detailLink';
import { hasAccessPermission, getCampaignTypeNames, getUserNames } from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import {
    refreshListView,
    removeScrollPosition,
} from '../../../services/actions/listViewAcions';
import GridTextField from '../components/gridTextField';
import { styles } from '../../../services/constants/styles';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { deleteAction } from '../../../services/actions/batchActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { publishWebForm, unPublishWebForm, leasedNumberNotification } from '../../../services/actions/batchActions';
import {
    refreshDetailViewData,
    isDetailViewSet,
    detailViewIsMounted,
} from '../../../services/actions/detailViewActions';
import { Icon } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import _ from 'lodash';
import _default from 'react-data-grid';
import { setListViewInlineUpdateData } from '../../../services/actions/listViewAcions';
import { setNotifyTextForSalesRep } from '../../../services/actions/appActions';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import CloseIcon from '@mui/icons-material/Close';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from '@mui/material';

const moment = require('moment');
function rowKeyGetter(row) {
    return row?.id;
}

export function StatusFormater({ object, row, colName, id }) {
    const value = row[colName]?.trim();
    function getStatusColor(status, dueDate) {
        const colors = {
            "Pending": "Grey",
            "In-progress": "Grey",
            "Not Started": "black",
            "Completed": "Green",
            "Overdue": "Red"
        };
        if (((status == 'Overdue') || (status == 'In-progress') )&& dueDate) {
            const currentDate = new Date();
            const taskDueDate = new Date(dueDate);
            if (taskDueDate < currentDate) {
                return colors["Overdue"];
            }
        }

        return colors[status] || "Black";
    }
    return (
        <div style={{ lineHeight: '35px', color: getStatusColor(value, row["t_dueby"]) }} title={value} id={`${object}-${id}`}>{value}</div>
    );
}

export function NoteFormater({ object, value, id }) {
    const text = value.trim();
    const htmlText = text.replace(/\n/g, "<br/>");
    return (
        <div style={{ lineHeight: '35px' }} title={value} id={`${object}-${id}`} >
            <div dangerouslySetInnerHTML={{ __html: htmlText }} />
        </div>
    );
}
export function ShowPicture({ object, value, id }) {
    function onImageClick() {
        if (object === constants.AUDIT_ANSWERS_OBJECT) {
            const activeTab = getObjectParam(getActiveTab());
            const activeTabIndex = getIntParam(activeTab.index);
            const url = getStringParam(value).trim();
            const newTab = {
                index: activeTabIndex + 1,
                label: url,
                object: constants.AUDIT_ANSWERS_OBJECT,
                type: TYPE_OTHER_VIEW,
                imgName: "fas fa-calendar-week",
                url: `/${constants.AUDIT_ANSWERS_OBJECT}/photoview/${id}`,
                info: {
                    "photo_url": url
                },
                isActive: true,
                parentTabIndex: activeTabIndex
            }
            addTab(newTab);
        }
    }

    if (id < 0) {
        return null;
    }

    let url = getStringParam(value).trim();

    if (url.length === 0) {
        return (
            <div class="">
                <Icon style={{ fontSize: '30px', cursor: 'pointer', color: '#cccccc' }}>account_circle</Icon>
            </div>
        );
    } else {
        return (
            <div onClick={() => onImageClick()}>
                <img src={url} style={{ cursor: 'pointer', margin: '3px 5px', maxHeight: '24px', maxWidth: '26px' }} />
            </div>
        );
    }
}

const ShowAttachment = ({ object, isAttachmentAvailable, rowData }) => {
    const dispatch = useDispatch()
    const onImageClick = () => {
        let attachmentFiles = []
        let data = rowData
        let callFrom = constants.DETAIL_VIEW
        let label = getLocalizedStrings().label.ATTACHMENT.ATTACHMENTS;
        dispatch(getAppDialog(true, constants.TASKS, label, attachmentFiles, data, callFrom));
    }

    if (isAttachmentAvailable) {
        return (
            <div style={{ padding: '10px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => onImageClick()}>
                <span title={getLocalizedStrings().label.ATTACHMENT.ATTACHMENT} key="attachment">
                    <i
                        className="fa fa-paperclip"
                        aria-hidden="true"
                        style={{ paddingRight: "5px", cursor: "pointer" }}
                    />
                </span>
            </div>
        );
    }


}

class DateTimeFormater extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let date = '';
        try {
            let fieldType = isValidParam(this.props.column) ? getStringParam(this.props.column.field_type) : '';
            let format = getStringParam(this.props.format).trim();
            date = getStringParam(this.props.value).trim();

            if (date !== '') {
                let dateMoment = moment(date);
                if (fieldType === constants.FIELD_TYPE_DATE) {
                    date = dateMoment.format(dateFormat[format]);
                } else if (fieldType === constants.FIELD_TYPE_DATE_TIME) {
                    date = dateMoment.format(dateTimeFormat[format]);
                }
            }
        } catch (error) {
            date = '';
            console.error("Error in 'dataTable.js -> DateTimeFormater.render()':" + error);
        }
        return <span title={date}> {date}</span>;
    }
}

class ShowCampaignTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { campaignTypeNames: '' };
    }

    render() {
        let campaignTypesId = getStringParam(this.props.value).trim();
        let campaignType = getCampaignTypeNames(campaignTypesId);
        return <div title={campaignType}>{campaignType}</div>;
    }
}

class ShowUserNames extends React.Component {
    constructor(props) {
        super(props);
        this.state = { userNames: '' };
    }

    render() {
        let userIds = getStringParam(this.props.value).trim();
        let userNames = getUserNames(userIds);
        return <div title={userNames}>{userNames}</div>;
    }
}

const ShowActionButtons = (props) => {
    const { row, column } = props;
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const [actionsList, setActionsList] = useState();

    useEffect(() => {
        getActionData(props);
    }, []);

    useEffect(() => {
        if (row.t_status !== props.row.t_status) {
            getActionData(props);
        }
    }, [props.row.t_status]);

    const getActionData = (props) => {
        try {
            let data = [];
            if (props.object === constants.LANDING_PAGES_FROALA_OBJECT) {
                if (getObjectParam(props.column).name === 't_actions') {
                    data = [{ id: '1', name: 'publish', label: 'Publish', iconName: 'fas fa-play' }];
                    if (props.row.t_status === constants.PUBLISHED) {
                        data = [{ id: '1', name: 'unpublish', label: 'Unpublish', iconName: 'far fa-pause-circle' }];
                    }
                    let tempData = [
                        { id: '2', name: 'Copy', label: 'Copy', iconName: 'far fa-copy' },
                        { id: '3', name: 'Edit', label: 'Edit', iconName: 'fas fa-pencil-alt' },
                        { id: '4', name: 'Delete', label: 'Delete', iconName: 'far fa-trash-alt' },
                    ];
                    data.push(...tempData);
                    if (props.row.t_status === constants.PUBLISHED) {
                        data.splice(1, 0, { id: '5', name: 'ExternalLink', label: 'View Published URL', iconName: 'fas fa-external-link-alt' });
                    }
                }
            } else if (props.object === constants.PHONE_NUMBERS_OBJECT) {
                if (getObjectParam(props.column).name === 't_actions') {
                    data = [];
                    let tempData = [];
                    let _showNotifyIcon = false;
                    let _showDeleteIcon = true;
                    if (props.row.t_type == 'Leased' || props.row.t_type == 'Imported') {
                        tempData.push({ id: '1', name: 'Edit', label: 'Edit', iconName: 'fas fa-pencil-alt' });
                    }
                    if (app.me.is_manage_unit_phone_by_fr) {
                        _showDeleteIcon = false;
                    }
                    if (_showDeleteIcon) {
                        tempData.push({ id: '2', name: 'Delete', label: 'Delete', iconName: 'far fa-trash-alt' });
                    }
                    if (app.me.is_franchisor && props.row.used_by !== "Units") {
                        if (props.row.available_for !== 'Campaign') {
                            _showNotifyIcon = true;
                        }
                    } else if (app.me.is_franchisee) {
                        if (props.row.available_for !== 'Campaign') {
                            _showNotifyIcon = true;
                        }
                    }
                    if (_showNotifyIcon) {
                        if (props.row.t_type == 'Leased' || props.row.t_type == 'Imported') {
                            tempData.push({ id: '3', name: constants.LEASED_NUMBER_NOTIFICATION, label: props.row.is_notification && props.row.is_notification == "Yes" ? getLocalizedStrings().label.PHONE_NUMBERS.REMOVE_NOTIFICATION_TOOLTIP : getLocalizedStrings().label.PHONE_NUMBERS.USE_NOTIFICATION_TOOLTIP, iconName: props.row.is_notification && props.row.is_notification == "Yes" ? 'far fa-bell' : 'far fa-bell-slash' });
                        }
                    }
                    data.push(...tempData);
                }
            }
            setActionsList(data);
        } catch (error) {
            console.error("Error in 'dataTable.js -> ShowActionButtons -> getActionData()':" + error);
        }
    };

    const triggerAction = (type, event) => {
        try {
            let row = getObjectParam(props.row);
            let data = {};
            let objectName = props.object;
            if (type === constants.EDIT || type === constants.COPY || type === 'ExternalLink') {
                if (type === 'ExternalLink') {
                    let params = { id: row.id };
                    var promise = Promise.resolve(HTTPClient.get(endPoints.LANDING_PAGE.GET, params));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response) && response.hasOwnProperty('template')) {
                                window.open(response.template.url);
                            }
                        });
                    }
                } else if (objectName === constants.PHONE_NUMBERS_OBJECT) {
                    let _t_type = getStringParam(row['t_type']);
                    let _data = {};
                    let _label = "Lease Number";
                    let _constantVal = "";
                    if (_t_type === 'Leased') {
                        _data.action_type = 'lease';
                        _data.action_mode = 'edit';
                        _constantVal = constants.LEASE_NUMBER;
                    } else if (_t_type === 'Imported') {
                        _data.action_type = 'import';
                        _data.action_mode = 'edit';
                        _label = "Import Number";
                        _constantVal = constants.IMPORT_NUMBER;
                    }
                    dispatch(getAppDrawer(true, _label, _constantVal, _data, constants.PHONE_NUMBERS_OBJECT));
                } else {
                    let label =
                        type === constants.EDIT
                            ? getLocalizedStrings().label.LANDING_PAGE.EDIT_LANDING_PAGE
                            : getLocalizedStrings().label.LANDING_PAGE.COPY_LANDING_PAGE;
                    data.id = row.id;
                    data.object = objectName;
                    data.mode = type.toLowerCase();
                    dispatch(getAppDrawer(true, label, type.toLowerCase(), data, null));
                }
            } else if (type === constants.DELETE) {
                let param = {};
                let isDelete = true;
                if (objectName === constants.PHONE_NUMBERS_OBJECT) {
                    param.type = getStringParam(row['type']);
                    param.id = row.id;
                    let _availableToUnit = getStringParam(row['available_to_unit_id']);
                    if (_availableToUnit != null && _availableToUnit != "") {
                        isDelete = false;
                    }
                } else {
                    param = { id: [row.id] };
                }
                if (isDelete) {
                    sfDialogs.confirm(
                        getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
                        '1 ' + getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD,
                        () => triggerActionOnConfirm(objectName, param),
                        null,
                    );
                } else if (isDelete == false) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SMS_INTEGRATION.CANNOT_DELETE_UNITS_NUMBER, null);
                }

            } else if (type === 'publish' || type === 'unpublish') {
                let params = { id: [row.id] };
                if (type === 'publish') {
                    let response = publishWebForm(objectName, params);
                    response.then((response) => {
                        let message = '';
                        if (response.status === 0) {
                            message = getLocalizedStrings().message.BATCH_ACTION.PUBLISH_SUCCESSFULL;
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            refreshListView(objectName);
                        } else if (response.status === -1 && response.error.message !== '') {
                            message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_PUBLISH;
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                } else if (type === 'unpublish') {
                    let response = unPublishWebForm(objectName, params);
                    response.then((response) => {
                        let message = '';
                        if (response.status === 0) {
                            message = getLocalizedStrings().message.BATCH_ACTION.UNPUBLISH_SUCCESSFULL;
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            refreshListView(objectName);
                        } else if (response.status === -1 && response.error.message !== '') {
                            message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_UNPUBLISH;
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                }
            } else if (type === constants.LEASED_NUMBER_NOTIFICATION) {
                let isRemove = false;
                let isNotification = getStringParam(row['is_notification']);
                let notifMessage = getLocalizedStrings().message.PHONE_NUMBERS.USE_NOTIFICATION;
                if (isNotification == "Yes") {
                    notifMessage = getLocalizedStrings().message.PHONE_NUMBERS.REMOVE_NOTIFICATION;
                    isRemove = true;
                }
                let param = {};
                param.type = getStringParam(row['type']);
                param.id = row.id;
                param.isRemove = isRemove;
                sfDialogs.confirm(
                    getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
                    notifMessage,
                    () => leasedNumberNotificationOnConfirm(objectName, param),
                    null,
                );
            }
        } catch (error) {
            console.error("Error in 'dataTable.js -> ShowActionButtons -> triggerAction()':" + error);
        }
    };
    const triggerActionOnConfirm = (objectName, param) => {
        var response = deleteAction(objectName, param);
        response.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                refreshListView(objectName);
            }
        });
    };


    const leasedNumberNotificationOnConfirm = (objectName, param) => {
        var response = leasedNumberNotification(objectName, param);
        response.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.PHONE_NUMBERS.USE_FOR_NOTIFICATION_SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                dispatch(setNotifyTextForSalesRep(response.data));

                refreshListView(objectName);
            }
        });
    };

    let showDetailLink = false;
    let showActionsClass = false;
    if (getObjectParam(props.column).name === 't_name') {
        showDetailLink = true;
    } else if (getObjectParam(props.column).name === 't_actions') {
        showActionsClass = true;
    }

    return (
        <div style={{ display: 'flex' }}>
            {getArrayParam(actionsList).map((action) => {
                return (
                    <span
                        key={`action-id-${action.id}`}
                        className={showActionsClass && 'page-action'}
                        title={action.label}
                        style={{ padding: '5px', cursor: 'pointer' }}
                        onClick={() => triggerAction(action.name)}>
                        <i className={action.iconName}></i>
                    </span>
                );
            })}

            {showDetailLink && (
                <DetailLink
                    {...props}
                    value={props.value}
                    rowIdx={props.rowIdx}
                    rowData={props.row}
                    column={props.column}
                    key={`detail-link-key`}
                    format={app.me.date_format}
                    object={props.object}
                    isExpandedTaskList={props.isExpandedTaskList}
                />
            )}
        </div>
    );
};

const DataTable = (props) => {
    let { listView, isDetailView, isLookup, object, parentObject, isOperationModule, campaignTypes, getSelectedRecord, listViewCallBack, isDuplicate } = props;
    const dispatch = useDispatch();
    const gridRef = useRef(_default);
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);
    const userList = useSelector((state) => state.userlist.data);
    const childListView = useSelector((state) => state.childListView);
    const listViewRecords = useSelector((state) => state.listView);
    const [tableSmmary, setTableSmmary] = useState({ rows: [], expanded: {}, childData: {} });
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [titleFieldName, setTitleFieldName] = useState('');
    const [selectedEditCellRecord, setSelectedEditCellRecord] = useState({});
    const [columnsParams, setColumnsParams] = useState(false);
    const [resizUpdatedFieldId, setResizUpdatedFieldId] = useState(0);
    const [resizeWidth, setResizeWidth] = useState(0);
    const [resizeChildObject, setResizeChildObject] = useState([]);
    const [isOpenAuditNote, setIsOpenAuditNote] = useState(false);
    const [selectedNote, setSelectedNote] = useState('');

    const reduxDetailView = useSelector((state) => state.detailView, _.isEqual);
    let _scrollPos = 0;
    let mainObj = listViewRecords.data.object !== "" ? listViewRecords.data.object : object
    _scrollPos = mainObj in listViewRecords.scrollPosition ? listViewRecords.scrollPosition[mainObj].pos : 0
    const rowIndex = _scrollPos || 0;
    const contentHeight = document.body.clientHeight - 243;
    const dataGridRowHeight = 35;
    const DATA_GRID_HEADER_ROW_HEIGHT = 35 + 20;
    let dataGridMinHeight = isLookup ? 250 : contentHeight;
    const reduxDuplicateListView = useSelector((state) => state.duplicateListView);

    useEffect(() => {
        setColumnsData(listView);
        setRowData(listView);
    }, [listView]);

    useEffect(() => {
        let tab = getActiveTab();
        let rememberSelection = tab.info?.rememberSelection;
        if (!isDetailView && (rememberSelection && rememberSelection[object])) {
            let selectedRows = new Set();
            selectedRows = rememberSelection[object] ?? new Set();
            if (selectedRows.size > 0) {
                handleSelectedRowsChange(selectedRows, 'useEffect')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (gridRef.current) {
            setTimeout(() => {
                if (gridRef.current) {
                    gridRef.current?.scrollToRow(rowIndex)
                }
            }, 100)
        }
    }, [])

    useEffect(() => {
        if (isDetailView && object === constants.REPORTED_SALES_OBJECT && selectedRows.size === 0 && childListView.selectedRows.length > 0) {
            childListView.selectedRows = []
        }
        if (reduxDuplicateListView.queryName == "Duplicate Search" && selectedRows.size > 0 && reduxDuplicateListView.selectedRows.length === 0) {
            setSelectedRows(new Set());
        }
    }, [childListView, reduxDuplicateListView]);

    const handleMouseUp = useCallback(
        (e) => {
            if (columnsParams) {
                let promise = Promise.resolve(saveListViewLayoutRowWidth(object, columnsParams));
                if (isValidParam(promise)) {
                    promise.updateRowOnRedux = updateRowOnRedux;
                    promise.then((response) => {
                        promise.updateRowOnRedux(resizUpdatedFieldId, resizeWidth, resizeChildObject);
                    });
                }
                setColumnsParams(false)
            }
        },
        [columnsParams]
    );

    useEffect(() => {
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseUp]);

    const rowHeight = (rowData) => {
        let _dataGridRowHeight = dataGridRowHeight;
        const { row } = rowData;
        if (object === constants.ATTACHMENT_OBJECT) {
            if (row['note'] && row['note'].trim() !== '') {
                let text = row['note'];
                let countNewlines = text.split("\n").length;
                _dataGridRowHeight = countNewlines ? countNewlines * _dataGridRowHeight : _dataGridRowHeight;
            }
        }
        return _dataGridRowHeight;
    }

    const getDefaultSortable = () => {
        const info = getActiveTabInfo();
        const reportType = getStringParam(info.reportType);
        if (reportType === constants.BROADCAST_REPORT_TYPE || reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
            return false;
        }
        return true;
    };

    const openTab = (recordProjectID, label, object, type, url, isRedirect, filter) => {
        const activeTab = getObjectParam(getActiveTab());
        const activeTabIndex = getIntParam(activeTab.index);
        const info = getObjectParam(activeTab.info);
        const isInXpress = getBooleanParam(info.isInXpress);

        const _tab = { label: label, object: object, type: TYPE_DETAIL_VIEW, imgName: null, url: url, isActive: true, parentTabIndex: activeTabIndex };
        if (isInXpress) {
            _tab.info = { isInXpress: isInXpress, inXpressProjectId: getIntParam(info.inXpressProjectId) };
        }
        if(props.object === constants.AUDITS_OBJECT && props.parentObject === constants.ACCOUNTS_OBJECT){
            _tab.info = { sub_type : 'AUDIT_DETAILS'};
        }
        if (recordProjectID > 0 && !isInXpress) {
            _tab.info = { isUnitsAccount: true, UnitsAccountProjectId: getIntParam(recordProjectID) };
        }
        if (object == constants.COMPLIANCE_OBJECT && app.me.is_franchisor == true) {
            _tab.info = { isUnitsAccount: info.isUnitsAccount, UnitsAccountProjectId: info.UnitsAccountProjectId };
        }
        if (type === TYPE_DETAIL_VIEW && object === constants.AUDITS_OBJECT) {
            _tab.info = { isAuditAnswerChild: true };
        }
        if (object === constants.GROUP_OBJECT) {
            if (_tab.hasOwnProperty('info')) {
                _tab.info.filter = filter;
            } else {
                const info = { filter: filter };
                _tab.info = info;
            }
        }
        if (type === TYPE_DETAIL_VIEW && object === "appointments" && filter !== undefined) {
            _tab.columnName = { columnName: filter.columnName };
        }
        addTab(_tab, isRedirect);
    };

    const openQuotationTab = (recordId) => {
        try {
            let royaltyArr = [];
            royaltyArr.push(getIntParam(recordId));
            let object = getStringParam(props.listView.data.object).toLowerCase();
            let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);
            let params = {};
            params.parent_object_id = 280;
            params.child_record_id = getIntParam(recordId);
            params.object_id = objectId;
            let promise = Promise.resolve(fetchLinkRecords(params));
            promise.then((response) => {
                if (isValidParam(response) && getIntParam(response.record_id) > 0) {
                    let quoteId = response.record_id;
                    let info = {
                        id: quoteId,
                        title: 'Edit Quotes & Invoices',
                        type: 'Edit',
                        object: 'quotations',
                        royalty_record_id: royaltyArr,
                        callFor: constants.ROYALTY_OBJECT,
                    };
                    addNewFormTabs(info);
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }
    };

    const handleFill = ({ columnKey, sourceRow, targetRow }) => {
        return { ...targetRow, [columnKey]: sourceRow[columnKey] };
    };

    const setLogoOnRecord = (imageUrl, id, fieldName) => {
        let info = getObjectParam(getActiveTabInfo());
        let isInXpress = getBooleanParam(info.isInXpress);
        let isUnitsListView = false;
        isUnitsListView = info.isUnitsListView;
        if (!isInXpress && !isUnitsListView) {
            let records = listViewRecords?.data?.records;
            records = getArrayParam(records);
            records.forEach((row) => {
                if (row.id === id) {
                    row[fieldName] = imageUrl;
                    return;
                }
            });
            let temp = tableSmmary;
            temp.rows = records;
            setTableSmmary({ ...temp })
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    };

    const uploadLogo = (args) => {
        let info = getObjectParam(getActiveTabInfo());
        let isInXpress = getBooleanParam(info.isInXpress);
        let isUnitsListView = false;
        isUnitsListView = info.isUnitsListView;
        if (!isInXpress && !isUnitsListView) {
            let id = args.row.id;
            if (id > 0) {
                let fieldName = args.column.key;
                dispatch(
                    getAppDialog(
                        true,
                        constants.LIST_VIEW_LOGO_UPLOAD_DIALOG,
                        getLocalizedStrings().message.ATTACHMENT.SELECT_IMAGE_TO_UPLOAD,
                        null,
                        { id: id, fieldName: fieldName, setLogoOnRecord: setLogoOnRecord, object: object },
                        null,
                    ),
                );
            }
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    };

    const headerRenderer = (props) => {
        const { column } = props;
        return (
            <div
                style={{ cursor: column.sortable ? 'pointer' : 'text' }}
                className='celq7o97-0-0-beta-29'
                onClick={() => onSortColumns(column)}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    title={column.name}
                >
                    <span className="rdg-header-sort-name h1rrblhe7-0-0-beta-29" >{column.name}</span>
                    {listView.data?.sort_field_name === column.key ? (
                        <span>
                            {listView.data?.sort_type === 'asc' ? <ArrowDropUpIcon fontSize={'large'} /> : <ArrowDropDownIcon fontSize={'large'} />}
                        </span>
                    ) : null}
                </div>
            </div>
        );
    };
    const openAuditPicture = (object, row) => {
        let data = {};
        let label = getLocalizedStrings().label.AUDIT_ANSWERS.AUDIT_ANSWERS_LABEL;
        data.isDetailView = getBooleanParam(isDetailView);
        let parentRecordId = listView.groupByInfo.parent_record_id;
        let type = listView.groupByInfo.object;
        data.parent_object = listView.groupByInfo.parent_object;
        data.object = listView.groupByInfo.object;
        data.parent_record_id = parentRecordId;
        data.type = "Picture";
        let style = {};
        style.width = '45%';
        data.data = row;
        dispatch(getAppCustomDrawer(true, label, type, style, data, null, false));
    }

    const handleAuditNotes = (row) => {
        setSelectedNote(row.note);
        setIsOpenAuditNote(true);
    }
    const handleClose = () => {
        setIsOpenAuditNote(false);
    }

    const setColumnsData = (dataList, args, event) => {
        let defaultSortable = getDefaultSortable();
        let listViewData = null;
        let hasPermission = false;
        let isDetailLinkImplemented = false;
        try {
            const info = getObjectParam(getActiveTabInfo());
            const isInXpress = getBooleanParam(info.isInXpress);
            let isUnitsListView = false;
            isUnitsListView = info.isUnitsListView;
            let isUnitsAccount = false;
            if (isValidParam(info) && info.isUnitsAccount) {
                isUnitsAccount = info.isUnitsAccount;
            }
            if (
                object === constants.SALES_TEMPLATE_OBJECT ||
                object === constants.MARKETING_TEMPLATE_OBJECT ||
                object === constants.SMS_TEMPLATE_OBJECT
            ) {
                hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
            } else {
                if (
                    object === constants.ACCOUNTS_OBJECT ||
                    object === constants.CONTACTS_OBJECT ||
                    object === constants.OPPORTUNITIES_OBJECT ||
                    object === constants.PRODUCTS_OBJECT ||
                    object === constants.CASES_OBJECT ||
                    object === constants.PROJECTS_OBJECT ||
                    object === constants.ISSUES_OBJECT ||
                    object === constants.CUSTOM_TABLE1_OBJECT ||
                    object === constants.CUSTOM_TABLE2_OBJECT ||
                    object === constants.CUSTOM_TABLE3_OBJECT ||
                    object === constants.CUSTOM_TABLE4_OBJECT ||
                    object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                    object === constants.TOUCH_CAMPAIGNS_OBJECT ||
                    object === constants.AUTOMATION_DESIGNER_OBJECT ||
                    object === constants.SOLUTIONS_OBJECT ||
                    object === constants.AB_CAMPAIGNS_OBJECT ||
                    object === constants.SALES_LEADS_OBJECT ||
                    object === constants.GROUP_OBJECT ||
                    object === constants.INVENTORY_OBJECT ||
                    object === constants.APPOINTMENTS ||
                    object === constants.TASKS ||
                    object === constants.REPORTED_SALES_OBJECT
                ) {
                    hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                } else {
                    hasPermission = true;
                }
            }

            if (dataList !== null && dataList.data !== null) {
                listViewData = dataList.data;
                const parentRecordId = getIntParam(listViewData.parent_record_id);
                const queryName = listViewData.query_name;
                const queryType = listViewData.query_type;
                const __columns = [SelectColumn];
                const columnsData = listViewData.fields;

                let isShowPic = listViewData.hasOwnProperty('is_show_pic') ? listViewData.is_show_pic : false;
                isShowPic = isValidParam(isShowPic) ? isShowPic : false;
                let showPicFieldName = listViewData.hasOwnProperty('show_pic_field_name') ? listViewData.show_pic_field_name : '';
                showPicFieldName = isValidParam(showPicFieldName) ? showPicFieldName : '';

                if (isShowPic && showPicFieldName !== '') {
                    __columns.push({
                        id: -9999,
                        key: showPicFieldName,
                        name: showPicFieldName,
                        width: 20,
                        editable: false,
                        resizable: false,
                        sortable: false,
                        formatter: ({ row }) => <ShowPicture value={row[showPicFieldName]} id={row.id} />,
                        headerRenderer: () => (
                            <div style={{ cursor: 'text' }} class="rdg.HeaderCell celq7o97-0-0-beta-29">
                                {/* <Icon style={{ fontSize: '30px', color: '#cccccc' }}>account_circle</Icon> */}
                            </div>
                        ),
                    });
                }



                if (columnsData !== null && columnsData !== undefined && columnsData.length > 0) {
                    columnsData.map((col, index) => {
                        if (col !== null && !col.is_hidden) {
                            let customField = {};
                            customField.id = col.id;
                            customField.key = col.name;
                            customField.name = getMultilingualLabelName(object, col.label);
                            customField.width = col.width;
                            customField.editable = false;
                            customField.resizable = true;
                            customField.sortable = getBooleanParam(defaultSortable);
                            if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                                //bypass
                            } else {
                                customField.editor = textEditor;

                            }
                            if (object === constants.COUPONS_OBJECT) {
                                if (col.name === 'total_count') {
                                    customField.resizable = false;
                                    customField.sortable = false;
                                }
                            } else {
                                if (col.name === 'Relationships') {
                                    customField.sortable = false;
                                }
                            }

                            customField.query_name = queryName;
                            customField.query_type = queryType;
                            customField.field_type = col.field_type;
                            customField.list_type = col.list_type;
                            customField.field_length = col.field_length;
                            customField.is_currency_field = col.is_currency_field;
                            customField.is_detail_link = col.is_detail_link;
                            customField.is_email_field = col.is_email_field;
                            customField.is_click_to_call = col.is_click_to_call;
                            customField.is_ring_central = col.is_ring_central;
                            customField.is_twilio = col.is_twilio;
                            customField.is_twilio_sms = col.is_twilio_sms;
                            customField.is_sales_rep = col.is_sales_rep;
                            customField.is_required_field = col.is_required_field;
                            customField.is_lookup_field = col.is_lookup_field;
                            customField.lookup_table_id = col.lookup_table_id;
                            customField.lookup_field_id = col.lookup_field_id;
                            customField.is_phone_field = col.is_phone_field;
                            customField.is_relationship_field = col.is_relationship_field;
                            customField.is_modify_list = col.is_modify_list;
                            customField.headerRenderer = (p) => headerRenderer(p);
                            customField.formatter = ({ row }) => (
                                <DateTimeFormater key={col.id + col.name} format={app.me.date_format} rowIdx={index} row={row} value={row[col.name]} />
                            );
                            if (object === constants.AUDIT_ANSWERS_OBJECT && col.name === 'photo_url') {
                                customField.formatter = ({ row }) => {
                                    if (row?.photo_url) {
                                        return <InsertPhotoIcon style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => openAuditPicture(object, row)} />
                                    } else {
                                        return null;
                                    }
                                }
                            } else if (object === constants.AUDIT_ANSWERS_OBJECT && col.name === "toggle_value") {
                                customField.formatter = ({ row }) => {
                                    return <span title={row["toggle_value"]} className={getRowcellClass(row)} >{row["toggle_value"]}</span>
                                }
                            } else if (object === constants.AUDIT_ANSWERS_OBJECT && col.name === "note") {
                                customField.formatter = ({ row }) => {
                                    return <span title={row["note"]} style={{ cursor: 'pointer' }} onClick={() => handleAuditNotes(row)} >{row["note"]}</span>
                                }
                            } else if (object === constants.REPORTED_SALES_OBJECT && col.name == "t_status") {
                                customField.formatter = ({ row }) => {
                                    return <span title={row["t_status"]} className={getRowcellClass(row)}>{row["t_status"]}</span>
                                }
                            } 
                            else if (object === constants.TASKS && col.name == "t_status") {
                                customField.formatter = ({ row }) => {
                                    return <StatusFormater object={object} row={row} colName={col.name} id={row.id} />
                                }
                            } else if (object === constants.ATTACHMENT_OBJECT && col.name == "note") {
                                customField.formatter = ({ row }) => {
                                    if (row[col.name] && row[col.name].trim() !== '') {
                                        return <NoteFormater object={object} value={row[col.name]} id={row.id} />
                                    } else return null;
                                }
                            } else if (col.name === 'lead_score') {
                                customField.formatter = ({ row }) => (
                                    <ShowLeadScore
                                        key={col.id + col.name}
                                        rowIdx={index}
                                        row={row}
                                        value={row[col.name]}
                                        totalScore={listViewRecords.data.total_lead_score}
                                        object={object}
                                    />
                                );
                            } else if (col.name === 'campaign_type') {
                                customField.formatter = ({ row }) => (
                                    <ShowCampaignTypes
                                        key={col.id + col.name}
                                        object={object}
                                        campaignTypes={campaignTypes}
                                        rowIdx={index}
                                        row={row}
                                        value={row[col.name]}

                                    />
                                );
                            } else if (object === constants.ASSIGNMENT_RULES && col.name === 't_userid') {
                                customField.formatter = ({ row }) => (
                                    <ShowUserNames key={col.id + col.name} object={object} rowIdx={index} row={row} value={row[col.name]} />
                                );
                            } else if (
                                (object === constants.LANDING_PAGES_FROALA_OBJECT || object === constants.PHONE_NUMBERS_OBJECT) &&
                                col.name === 't_actions'
                            ) {
                                customField.formatter = ({ row }) => (
                                    <ShowActionButtons key={col.id + col.name} rowIdx={index} row={row} value={row[col.name]} column={col} {...props} />
                                );
                            } else if (object === constants.ROYALTY_OBJECT && col.name === 'unit' && parentObject !== constants.ACCOUNTS_OBJECT) {
                                customField.is_detail_link = true;
                                customField.is_email_field = false;
                                customField.is_sales_rep = false;
                                customField.is_click_to_call = false;

                                customField.formatter = ({ row }) => (
                                    <DetailLink
                                        column={col}
                                        key={col.id + col.name}
                                        format={app.me.date_format}
                                        object={object}
                                        openTab={openTab}
                                        hasPermission={hasPermission}
                                        isDetailView={isDetailView}
                                        isDuplicate={isDuplicate}
                                        parentObject={parentObject}
                                        rowIdx={index}
                                        rowData={row}
                                        value={row[col.name]}
                                        isExpandedTaskList={props.isExpandedTaskList}
                                    />
                                );
                            } else if (object === constants.REPORTED_SALES_OBJECT && (col.name === 'quoteno')) {
                                customField.is_detail_link = true;
                                customField.is_email_field = false;
                                customField.is_sales_rep = false;
                                customField.is_click_to_call = false;
                                customField.formatter = ({ row }) => (
                                    <DetailLink
                                        column={col}
                                        key={col.id + col.name}
                                        format={app.me.date_format}
                                        object={object}
                                        openQuotationTab={openQuotationTab}
                                        hasPermission={hasPermission}
                                        isDetailView={isDetailView}
                                        isDuplicate={isDuplicate}
                                        parentObject={parentObject}
                                        rowIdx={index}
                                        rowData={row}
                                        value={row[col.name]}
                                        isExpandedTaskList={props.isExpandedTaskList}
                                    />
                                );
                            } else if (object === constants.TASKS && col.name === 't_dueby') {
                                customField.is_detail_link = true;
                                customField.is_email_field = false;
                                customField.is_sales_rep = false;
                                customField.is_click_to_call = false;
                                customField.formatter = ({ row }) => (
                                    <DetailLink
                                        column={col}
                                        key={col.id + col.name}
                                        format={app.me.date_format}
                                        object={object}
                                        openTab={openTab}
                                        hasPermission={hasPermission}
                                        isDetailView={isDetailView}
                                        isDuplicate={isDuplicate}
                                        parentObject={parentObject}
                                        parentRecordId={parentRecordId}
                                        isExpandedTaskList={props.isExpandedTaskList}
                                        rowIdx={index}
                                        rowData={row}
                                        value={row[col.name]}
                                    />
                                );
                            } else if (!isLookup) {
                                if ((!isDetailLinkImplemented && !isUnitsListView && col.is_detail_link) || col.is_lookup_field) {
                                    if (object === constants.OPPORTUNITIES_OBJECT && customField.key === 'product') {
                                        //bypass
                                    }else if (listView.data.parent_object === constants.ACCOUNTS_OBJECT && object === constants.TASKS && col.name === 'company' && !props.isExpandedTaskList) {
                                        customField.is_detail_link = false;
                                        customField.is_email_field = false;
                                        customField.is_sales_rep = false;
                                        customField.is_click_to_call = false;
                                    }
                                    else if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                                        //bypass
                                    }
                                    else {
                                        customField.is_detail_link = true;
                                        customField.is_email_field = false;
                                        customField.is_sales_rep = false;
                                        customField.is_click_to_call = false;

                                        customField.formatter = ({ row }) => (
                                            <DetailLink
                                                column={col}
                                                key={col.id + col.name}
                                                format={app.me.date_format}
                                                object={object}
                                                openTab={openTab}
                                                hasPermission={hasPermission}
                                                isDetailView={isDetailView}
                                                isDuplicate={isDuplicate}
                                                parentObject={parentObject}
                                                parentRecordId={parentRecordId}
                                                rowIdx={index}
                                                rowData={row}
                                                value={row[col.name]}
                                                isDetailLink={customField.is_detail_link}
                                                tableSmmary={tableSmmary}
                                                isExpandedTaskList={props.isExpandedTaskList}
                                                setTableSmmary={(data) => { setTableSmmary({ ...data }) }}
                                            />
                                        );
                                    }
                                } else if (
                                    !isInXpress &&
                                    !isUnitsListView &&
                                    !isUnitsAccount &&
                                    (col.is_email_field || col.is_click_to_call || col.is_sales_rep || col.is_relationship_field)
                                ) {
                                    if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction && col.is_sales_rep) {
                                        //bypass
                                    } else {
                                        customField.formatter = ({ row }) => (
                                            <DetailLink
                                                column={col}
                                                key={col.id + col.name}
                                                format={app.me.date_format}
                                                object={object}
                                                openTab={openTab}
                                                hasPermission={hasPermission}
                                                isDetailView={isDetailView}
                                                isDuplicate={isDuplicate}
                                                parentObject={parentObject}
                                                parentRecordId={parentRecordId}
                                                rowIdx={index}
                                                rowData={row}
                                                value={row[col.name]}
                                                isExpandedTaskList={props.isExpandedTaskList}
                                            />
                                        );
                                    }

                                } else if (!isInXpress && !isUnitsListView && !isUnitsAccount && col.field_type === 'url') {
                                    customField.formatter = ({ row }) => (
                                        <DetailLink
                                            column={col}
                                            key={col.id + col.name}
                                            format={app.me.date_format}
                                            object={object}
                                            openTab={openTab}
                                            hasPermission={hasPermission}
                                            isDetailView={isDetailView}
                                            isDuplicate={isDuplicate}
                                            parentObject={parentObject}
                                            parentRecordId={parentRecordId}
                                            rowIdx={index}
                                            rowData={row}
                                            value={row[col.name]}
                                            isExpandedTaskList={props.isExpandedTaskList}
                                        />
                                    );
                                } else if (object === constants.TASKS && col.name === 't_name') {
                                    customField.is_detail_link = true;
                                    customField.formatter = ({ row }) => (
                                        <DetailLink
                                            column={col}
                                            key={col.id + col.name}
                                            format={app.me.date_format}
                                            object={object}
                                            openTab={openTab}
                                            hasPermission={hasPermission}
                                            isDetailView={isDetailView}
                                            isDuplicate={isDuplicate}
                                            parentObject={parentObject}
                                            parentRecordId={parentRecordId}
                                            rowIdx={index}
                                            rowData={row}
                                            value={row[col.name]}
                                            isExpandedTaskList={props.isExpandedTaskList}
                                        />
                                    );
                                }/*else if (object === constants.ROYALTY_OBJECT && col.name === 'invoicebtn') {
                                    customField.resizable = false;
                                    customField.formatter= ({ row }) => <ShowInvoiceButton key={col.id + col.name}  object={object}   rowIdx={index} rowData={row} value={row[col.name]} />;
                                }*/
                            }

                            if (object === constants.TASKS && col.name === 'has_attachments') {
                                customField.resizable = false;
                                customField.name = undefined;
                                customField.width = 20;
                                customField.formatter = ({ row }) => {
                                    let isAttachmentAvailable = row['has_attachments'] === 1 ? true : false;
                                    return <ShowAttachment isAttachmentAvailable={isAttachmentAvailable} object={object} rowIdx={index} rowData={row} value={row[col.name]} />;
                                }
                            }

                            if (col.name !== 'lead_score' && !isDetailLinkImplemented) {
                                isDetailLinkImplemented = true;
                                customField.is_detail_link = true;
                                customField.is_email_field = false;
                                customField.is_sales_rep = false;
                                customField.is_click_to_call = false;
                                customField.is_lookup_field = false;
                            }
                           
                            if (object !== constants.UNITS_OBJECT && object !== constants.TASKS && col.is_inline_edit && hasPermission && !isLookup && !isInXpress && !isUnitsListView && !isUnitsAccount) {
                                customField.editable = true;
                                if (col.field_type === 'list' || (col.field_type === 'list-integer-type' && object === constants.APPOINTMENTS)) {
                                    if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && col.name === 'country' && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                                        //bypass
                                    }else{
                                        const editor = (p) => {
                                            return <GridDropdown p={p} rowData={p.row} key={p.column.id + p.column.name} column={p.column} object={object} />;
                                        };
                                       customField.editor = editor;
                                    }
                                } else if (
                                    col.field_type === 'date' ||
                                    col.field_type === 'datetime' ||
                                    (col.field_type === 'text' && col.name === 'custom_field3' && isOperationModule)
                                ) {
                                    customField.editable = true;
                                    //customField.editor = <GridDatePicker key={col.id + col.name} column={col.name}  app={app}/>;
                                }
                                else {
                                    if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                                        //bypass
                                    }
                                    else {
                                        const editor = (p) => {
                                            return (
                                                <GridTextField p={p} rowData={p.row} key={p.column.id + p.column.name} column={p.column} object={object} />
                                            );
                                        };
                                        customField.editor = editor;
                                    }
                                }
                            }
                            if (col.name === "email_status") {
                                customField.editable = false;
                            }
                            __columns.push(customField);
                            if (col.is_detail_link) {
                                setTitleFieldName(col.name);
                            }
                        }
                    });
                    if (object === constants.NOTES) {
                        let childObjects = listViewData.child_objects;
                        if (isValidParam(childObjects)) {
                            childObjects = getArrayParam(childObjects);
                            for (let i = 0; i < childObjects.length; i++) {
                                const childObject = childObjects[i];
                                const childObjectName = childObject.object;
                                let fields = childObject.fields;
                                fields = getArrayParam(fields);
                                for (let j = 0; j < fields.length; j++) {
                                    const fieldName = `${childObjectName}.${fields[j].name}`;
                                    __columns.push({
                                        id: fields[j].id,
                                        key: fieldName,
                                        name: `${childObjectName}(${fields[j].label})`,
                                        width: 200,
                                        editable: false,
                                        resizable: true,
                                        sortable: getBooleanParam(defaultSortable),
                                        is_detail_link: true,
                                        formatter: ({ row }) => (
                                            <DetailLink
                                                column={fields[j]}
                                                key={fields[j].id + fields[j].name}
                                                object={childObjectName}
                                                openTab={openTab}
                                                hasPermission={hasPermission}
                                                isLinkChild={true}
                                                rowIdx={j}
                                                rowData={row}
                                                value={row[fieldName]}
                                                isExpandedTaskList={props.isExpandedTaskList}
                                            />
                                        ),
                                    });
                                }
                            }
                        }
                    }
                    setColumns(__columns);
                }
            }
        } catch (error) {
            console.error("Error in 'dataTable.js -> setColumnsData()':" + error);
        }
    };

    const setRowData = (listView) => {
        try {
            let records = listView !== null && listView.data !== null ? listView.data.records : [];

            let _rows = [];
            for (let i = 0; i < records.length; i++) {
                if (records[i]?.hasOwnProperty('child_objects')) {
                    for (let j = 0; j < records[i].child_objects.length; j++) {
                        const childObject = records[i].child_objects[j];
                        const childObjectName = childObject.object;
                        const childRecords = childObject.records;
                        for (let k = 0; k < childRecords.length; k++) {
                            let keys = Object.keys(childRecords[0]);
                            keys = getArrayParam(keys);
                            for (let l = 0; l < keys.length; l++) {
                                const fieldName = `${childObjectName}.${keys[l]}`;
                                records[i][fieldName] = childRecords[0][keys[l]];
                            }
                        }
                    }
                }
            }
            let columnsFields = [];
            if (listView.data?.fields?.length > 0) {
                columnsFields = listView.data.fields.filter(col => !col.is_hidden)
            }
            _rows = records;
            if (
                listView.data.is_show_opertunity_total && !isLookup
            ) {
                _rows = JSON.parse(JSON.stringify(records));
                const opertunity_sumdata = getArrayParam(listView.data.opertunity_sumdata);
                _rows.push({
                    id: -9999,
                    [columnsFields[0].name]: "Page Total",
                    ...opertunity_sumdata[0],
                });

                _rows.push({
                    id: -8888,
                    [columnsFields[0].name]: "Grand Total",
                    ...opertunity_sumdata[1],
                });
            }
            if (object === constants.NOTES) {
                _rows.forEach((obj) => {
                    if (obj.hasOwnProperty('note_text')) {
                        obj['note_text'] = retriveEscapeHtml(obj['note_text']);
                    }
                });
            }
            let __tableSmmary = tableSmmary;
            __tableSmmary.rows = _rows;
            __tableSmmary.childData = listView.data?.childData || {};
            __tableSmmary.expanded = listView.data?.expanded || {};
            setTableSmmary({ ...__tableSmmary });
        } catch (error) {
            console.error(`Error in 'dataTable.js -> setRowData()':${error}`);
        }
    };

    const getRowcellClass = (row) => {
        let rowClass = '';
        if(props.object === constants.TASKS){
            if (row?.t_status === "Pending") {
                rowClass = 'pending-row'
            }else if(row?.t_status === "Completed"){
                rowClass = 'completed-row';
            }else if(row?.t_status === "In-progress"){
                rowClass = 'in-progress-row';
            }else if(row?.t_status === "Overdue"){
                rowClass = 'overdue-row';
            }else if(row?.t_status === "Not Started"){
                rowClass = 'not-started-row';
            }
        }
        else if (props.object === constants.REPORTED_SALES_OBJECT) {
            if (row?.t_status == "Approved") {
                rowClass = 'aproved-row'
            }
            if (row?.t_status == "Rejected") {
                rowClass = 'rejected-row';
            }
        } else if (props.object === constants.AUDIT_ANSWERS_OBJECT) {
            if (row?.toggle_value === "Passed") {
                rowClass = 'aproved-row'
            } else if (row?.toggle_value === "Failed") {
                rowClass = 'rejected-row';
            }
        }
       
        return rowClass;
    }

    const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }) => {
        return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    };

    const handleCopy = ({ sourceRow, sourceColumnKey }) => {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
        }
    };

    const updateRowOnRedux = (fieldId, width, childObject) => {
        try {
            if (isValidParam(props.listView) && isValidParam(props.listView.data) && isValidParam(width)) {
                if (isValidParam(fieldId) && fieldId > 0) {
                    props.listView.data.fields.map((field) => {
                        if (field.id === fieldId) {
                            field.width = width;
                        }
                    });
                } else if (isValidParam(childObject)) {
                    props.listView.data.child_objects.map((obj) => {
                        if (obj.object === childObject) {
                            obj.fields.forEach((field) => {
                                field.width = width;
                            });
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error in 'dataTable.js -> updateRowOnRedux()':" + error);
        }
    };

    const handleColumnResize = (idx, width) => {
        let colPos = 1;
        let colIndex = idx;
        let updatedFieldId = 0;
        let params = {};
        let listViewData = null;
        let childObject = null;

        try {
            let info = getObjectParam(getActiveTabInfo());
            let isInXpress = getBooleanParam(info.isInXpress);
            width = width < 80 ? 80 : width;
            listViewData = props.listView.data;
            if (!isLookup && !isInXpress && isValidParam(listViewData)) {
                if (
                    object === constants.ACCOUNTS_OBJECT ||
                    object === constants.CONTACTS_OBJECT ||
                    object === constants.OPPORTUNITIES_OBJECT ||
                    object === constants.UNITS_OBJECT ||
                    object === constants.PROJECTS_OBJECT ||
                    object === constants.CASES_OBJECT ||
                    object === constants.SOLUTIONS_OBJECT ||
                    object === constants.ISSUES_OBJECT ||
                    object === constants.CUSTOM_TABLE1_OBJECT ||
                    object === constants.CUSTOM_TABLE2_OBJECT ||
                    object === constants.CUSTOM_TABLE3_OBJECT ||
                    object === constants.CUSTOM_TABLE4_OBJECT ||
                    object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                    object === constants.AB_CAMPAIGNS_OBJECT ||
                    object === constants.SOCIAL_CAMPAIGNS_OBJECT ||
                    object === constants.AUTOMATION_DESIGNER_OBJECT ||
                    object === constants.APPOINTMENTS ||
                    object === constants.TASKS ||
                    object === constants.INVOICES_OBJECT ||
                    object === constants.SALES_LEADS_OBJECT ||
                    object === constants.RECYCLE_BIN_OBJECT ||
                    object === constants.NEWS_OBJECT ||
                    object === constants.ATTACHMENT_OBJECT ||
                    object === constants.ORDERS_OBJECTS ||
                    object === constants.ORDER_ITEMS_OBJECTS ||
                    object === constants.QUOTATION_OBJECT ||
                    object === constants.PRODUCTS_OBJECT ||
                    object === constants.INVENTORY_OBJECT ||
                    object === constants.AUDITS_OBJECT ||
                    object === constants.AUDIT_ANSWERS_OBJECT ||
                    object === constants.REPORTED_SALES_OBJECT ||
                    object === constants.ROYALTY_PLANS_OBJECT ||
                    object === constants.ROYALTY_RUN_OBJECT
                ) {
                    params.query_name = listViewData.query_name;
                    params.query_type = listViewData.query_type;
                    params.query_id = listViewData.query_id;
                }
                params.page_size = listViewData.page_size;
                params.sort_field_name = listViewData.sort_field_name;
                params.sort_type = listViewData.sort_type;

                let isShowPic = getBooleanParam(listViewData.is_show_pic);
                params.is_show_pic = isShowPic;

                let isShowTotal = getBooleanParam(listViewData.is_show_opertunity_total);
                if (
                    (object === constants.OPPORTUNITIES_OBJECT ||
                        object === constants.ACCOUNTS_OBJECT ||
                        object === constants.REPORTED_SALES_OBJECT) &&
                    listViewData.hasOwnProperty('is_show_opertunity_total')
                ) {
                    params.is_show_total = isShowTotal;
                }

                params.field_width_info = [];

                colPos = isShowPic ? colPos + 1 : colPos;
                let fields = getArrayParam(JSON.parse(JSON.stringify(listViewData.fields)));
                fields = fields.filter((field) => {
                    return field != null && !field.is_hidden;
                });
                fields.map((field, index) => {
                    params.field_width_info.push({
                        id: field.id,
                        name: field.name,
                        width: colIndex === colPos ? width : field.width,
                    });
                    if (colIndex === colPos) {
                        updatedFieldId = field.id;
                    }
                    colPos++;
                });

                params.child_objects = [];
                let childObjects = getArrayParam(listViewData.child_objects);
                childObjects.forEach((childObj) => {
                    if (isValidParam(childObj)) {
                        let fieldCount = childObj.fields.length;
                        let avgWidth = width / fieldCount;
                        params.child_objects.push({
                            object: childObj.object,
                            field_width_info: childObj.fields.map((m) => {
                                return { id: m.id, name: m.name, width: colIndex == colPos ? avgWidth : m.width };
                            }),
                        });
                        if (colIndex === colPos) {
                            width = avgWidth;
                            childObject = childObj.object;
                        }
                        colPos++;
                    }
                });
                setColumnsParams(params)
                setResizUpdatedFieldId(updatedFieldId)
                setResizeWidth(width)
                setResizeChildObject(childObject)
            }
        } catch (error) {
            console.error("Error in 'dataTable.js -> onColumnResize()':" + error);
        }
    };

    const handleSort = (props) => { };

    const onSortColumns = (column) => {
        let sortSameColumn = false;
        if (listView.data?.sort_field_name === column.key) {
            sortSameColumn = true;
        }
        const sortObject = {
            columnKey: sortSameColumn ? listView.data?.sort_field_name : column.key,
            direction: sortSameColumn ? (listView.data?.sort_type === 'asc' ? 'desc' : 'asc') : 'asc',
        };
        if (sortObject) {
            const { columnKey, direction } = sortObject;
            let info = getObjectParam(getActiveTabInfo());
            let isInXpress = getBooleanParam(info.isInXpress);
            let isRefresh = true;
            let sortDirection = direction;

            if (object === constants.LANDING_PAGES_FROALA_OBJECT && columnKey === 't_actions') {
                isRefresh = false;
            }

            if (object === constants.PHONE_NUMBERS_OBJECT && columnKey === 't_actions') {
                isRefresh = false;
            }

            if (!isInXpress) {
                if (isRefresh) {
                    let filterObj = {};
                    filterObj.query_id = listViewRecords.data.query_id;
                    filterObj.query_name = listViewRecords.data.query_name;
                    filterObj.query_type = listViewRecords.data.query_type;
                    filterObj.sort_field_name = columnKey;
                    filterObj.sort_type = sortDirection.toLowerCase();
                    filterObj.is_list_view_update = true;

                    if (isDetailView) {
                        let listView = getObjectParam(childListView.data);
                        filterObj.parent_object = getStringParam(listView.parent_object);
                        filterObj.parent_record_id = getStringParam(listView.parent_record_id);
                        filterObj.query_name = listView.query_name;
                        filterObj.query_type = listView.query_type;

                        if (listView.parent_object === constants.ACCOUNTS_OBJECT && object === constants.ACCOUNTS_OBJECT) {
                            filterObj.relationTableId = 10;
                        }
                    }

                    let activeTab = getActiveTab();
                    activeTab = getObjectParam(activeTab);
                    let info = getObjectParam(activeTab.info);
                    delete info.selectedPageNo;
                    delete info.startPageNo;
                    delete info.startIndex;
                    updateTabInfo(info);
                    let obj = object;
                    if (getStringParam(info.is_duplicate_object)) {
                        obj = constants.FIND_DUPLICATE_OBJECT;
                    }
                    listViewCallBack(object, filterObj, true);
                }
            } else {
                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
            }
            dispatch(removeScrollPosition(object));
        }
    };

    const inlineUpdate = (object, recordId, _column, fieldValue, isCurrencyField) => {
        let tempField = null;
        let { id, key, field_type } = _column;
        try {
            object = isValidParam(object) ? object : '';
            recordId = isValidParam(recordId) && toNumber(recordId) > 0 ? toNumber(recordId) : 0;
            id = isValidParam(id) && toNumber(id) > 0 ? toNumber(id) : 0;
            key = isValidParam(key) ? key : '';
            fieldValue = isValidParam(fieldValue) ? fieldValue : '';
            if (field_type == "date" || field_type == "datetime") {
                let startEndDate = getStringParam(fieldValue);
                startEndDate = moment(startEndDate);
                let _format = 'YYYY-MM-DD';
                if (field_type == "datetime") {
                    _format = 'YYYY-MM-DD HH:mm:ss';
                }
                fieldValue = startEndDate.format(_format);
            }
            if (object !== '' && recordId > 0 && id > 0 && key !== '') {
                const params = {};
                params.id = recordId;
                params.fields = [];
                tempField = {};
                tempField.id = id;
                tempField.name = key;
                tempField.value = fieldValue;

                if (isCurrencyField) {
                    tempField.is_currency_field = true;
                }

                if (key === 'user_id' && object === constants.APPOINTMENTS) {
                    const user = userList.filter((user) => {
                        return user.value === fieldValue;
                    });
                    if (user) {
                        tempField.value = user[0].id;
                    }
                }
                params.fields.push(tempField);
                params.auditrail_action_type = constants.INLINE_UPDATE_AUDIT_TRAIL_RECORD;

                if (object == 'attachments' && key == 'description' && fieldValue.length > 128) {
                    dispatch(
                        showCustomSnackBar('Description should not more than 128 characters', styles.snackbarBodyStyleError, styles.snackBarStyleTop),
                    );
                    dispatch(detailViewIsMounted(false));
                    dispatch(isDetailViewSet(false));
                    dispatch(refreshDetailViewData(true));
                } else if (key === "email" && !isEmail(fieldValue)) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.VALIDATE_EMAIL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));

                } else {
                    const promise = SFFormActions.saveRecordPromise(object, params);
                    promise.then((response) => {
                        if (isValidParam(response) && response.hasOwnProperty('data') && response.data.hasOwnProperty('id')) {
                            const id =
                                isValidParam(response.data.id) && isArray(response.data.id) && response.data.id.length > 0 ? response.data.id[0] : 0;
                            if (id > 0) {
                                if (
                                    object === constants.CONTACTS_OBJECT &&
                                    (key === 'first_name' ||
                                        key === 'last_name' ||
                                        key === 'email' ||
                                        key === 'phone' ||
                                        key === 'mobile')
                                ) {
                                    const _isDetailView = isValidParam(isDetailView) ? isDetailView : false;
                                    const params = { isDetailView: _isDetailView };
                                    if (
                                        isDetailView &&
                                        childListView.data.hasOwnProperty('parent_object') &&
                                        childListView.data.parent_object.toLowerCase() == constants.ACCOUNTS_OBJECT
                                    ) {
                                        refreshDetailViewData(true);
                                    }
                                } else if (isCurrencyField) {
                                    const _isDetailView = isValidParam(isDetailView) ? isDetailView : false;
                                    const params = { isDetailView: _isDetailView };
                                    refreshListView(object, params);
                                }
                                let _rows = tableSmmary.rows
                                if (_rows.length > 0) {
                                    _rows = _rows.filter(r => { return r.id != -9999 && r.id != -8888 });
                                }
                                dispatch(setListViewInlineUpdateData(_rows));
                                dispatch(
                                    showCustomSnackBar(
                                        getLocalizedStrings().message.COMMON.UPDATE,
                                        styles.snackbarBodyStyleSuccess,
                                        styles.snackBarStyleTop,
                                    ),
                                );
                                if (
                                    isDetailView &&
                                    childListView.data.hasOwnProperty('parent_object') &&
                                    childListView.data.parent_object.toLowerCase() == constants.ACCOUNTS_OBJECT
                                ) {
                                    dispatch(detailViewIsMounted(false));
                                    dispatch(isDetailViewSet(false));
                                    dispatch(refreshDetailViewData(true));
                                    dispatch(refreshDetailViewListViewData(true));                           
                            }}

                        }
                    });
                }
            }
        } catch (error) {
            console.error(`Error in 'dataTable.js -> inlineUpdate()':${error}`);
        }
    };

    const handleRowsChange = (rows, field) => {
        console.log(rows, field);
        const { column } = field;
        let getInlineEditRecord = rows.filter((f) => {
            return f.id === selectedEditCellRecord.id;
        });
        if (column?.field_type == "integer" || column?.field_type == "float") {
            if (getInlineEditRecord[0][column.key] === selectedEditCellRecord[column.key]) return;

        } else {
            if (getInlineEditRecord[0][column.key].trim() === selectedEditCellRecord[column.key].trim()) return;
        }
        if (object == constants.CONTACTS_OBJECT && app.me.is_franchisee && listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
            //bypass
        } else {
            let temp = tableSmmary;
            temp.rows = rows;
            setTableSmmary({ ...temp })
            inlineUpdate(object, getInlineEditRecord[0].id, column, getInlineEditRecord[0][field.column.key]);
        }
    };

    const handleSelectedRowsChange = (selectedRows, callFrom) => {
        if (callFrom !== 'useEffect' && !props.isLookup) {
            let tab = getActiveTab();
            tab.info = { ...tab.info, rememberSelection: { ...tab.info.rememberSelection ?? {}, [props.object]: selectedRows } };
            updateActiveTab(tab);
        }

        let selectedRecords = [];
        for (const x of selectedRows?.values()) {
            tableSmmary.rows.map((row, index) => {
                if (row.id === x) {
                    let record = { ...row, rowIdx: index };
                    selectedRecords.push(record);
                }
            });
        }
        onRowsSelected(selectedRecords, selectedRows);
    };

    const onRowsSelected = (selectedRecords, selectedRows) => {
        selectedRecords = selectedRecords.filter((f) => {
            return f?.id > 0;
        });

        const lookupFilterData = getObjectParam(appContainer.dialogProps.data);
        const lookupFieldName = getStringParam(lookupFilterData.lookupFieldName);

        let selectedRecord = [];
        selectedRecords.map((row) => {
            if (isLookup) {
                let title = null;
                if (object === constants.PROJECTS_OBJECT) {
                    title = row['project_name'];
                } else if (lookupFieldName !== '') {
                    title = row[lookupFieldName];
                } else {
                    title = row['company'];
                }
                selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: title });
            } else {
                const editorType = row?.t_editor_name;
                if (object === constants.MARKETING_TEMPLATE_OBJECT && isValidParam(editorType)) {
                    selectedRecord.push({
                        rowIdx: row.rowIdx,
                        id: row.id,
                        title: row[titleFieldName],
                        type: editorType,
                        category: row.t_catagory,
                        name: row.t_name,
                        share: row.t_share,
                    });
                } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && isValidParam(editorType)) {
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName], type: editorType });
                } else if (object === constants.SALES_TEMPLATE_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName], name: row.t_name });
                } else if (object === constants.TASKS) {
                    let tempTitleFieldName =titleFieldName;
                    if(row?.t_name !=='' && row?.t_name !== undefined){
                        tempTitleFieldName = 't_name';
                    }
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[tempTitleFieldName], linkInfo: row.link_info });
                } else if (object === constants.PAYMENTS_OBJECT) {
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName], paymentType: row.payment_type });
                } else if (object === constants.ACCOUNTS_OBJECT) {
                    let _params = {
                        object: constants.ACCOUNTS_OBJECT,
                        id: row.id,
                        callFrom: constants.UNITS_OBJECT,
                    }
                    let _myClientId = '';
                    const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, _params));
                    response.then((response) => {
                        if (response.status == 0) {
                            _myClientId = response?.myClientId;
                            selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName] ,myClientId:_myClientId});
                        }else{
                            selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName] });
                        }
                    })
                } else if (object === constants.UNITS_OBJECT) {
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName], ...row });
                } else {
                    selectedRecord.push({ rowIdx: row.rowIdx, id: row.id, title: row[titleFieldName] });
                }
            }
        });
        setSelectedRows(selectedRows);

        getSelectedRecord(selectedRecord, selectedRows);
    };
    const isExpanded = (row) => {
        const rId = row.id;
        const rDir = row?.dir || `l${rId}e`;
        let isExpanded = getBooleanParam(tableSmmary?.expanded[rDir]);
        return isExpanded;
    }
    const getRowClass = (row) => {
        let rowClass = '';
        if (props.object === constants.UNITS_OBJECT) {
            if (row?.isParent) {
                rowClass = 'parent-row'
                if (isExpanded(row)) {
                    rowClass = 'expanded-row';
                }
            }
        }

        if (row.id < 1) {
            rowClass = 'bold-row';
        }

        return rowClass;
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div>
                <DataGrid
                    ref={gridRef}
                    style={{ height: isDetailView ? dataGridMinHeight - DATA_GRID_HEADER_ROW_HEIGHT + 'px' : dataGridMinHeight + 'px' }}
                    columns={columns}
                    rows={tableSmmary.rows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={handleRowsChange}
                    onColumnResize={handleColumnResize}
                    onFill={handleFill}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                    onSort={handleSort}
                    rowHeight={rowHeight}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    class="fill-grid"
                    onCellClick={(args, event) => {


                        setSelectedEditCellRecord(args.row);
                        if (args.column.name === 'show_pic_url') {
                            event.preventGridDefault();
                            args.selectCell(true);
                            uploadLogo(args);
                        }

                        if (object === constants.REPORTED_SALES_OBJECT && args.column.name === 'Total Sale' && args.row.hasOwnProperty("total_sale")) {
                            let _rowvalue = args.row?.total_sale;
                            _rowvalue = _rowvalue.split(",").join("");
                            if (args.row?.total_sale.includes("$")) {
                                _rowvalue = _rowvalue.replace(/\$/g, '');
                            }
                            args.row.total_sale = _rowvalue;
                        }
                    }}
                    rowClass={(row, index) => getRowClass(row)}
                    onCellDoubleClick={(args, event) => {
                        let hasPermission = false;
                        const info = getObjectParam(getActiveTabInfo());
                        const isInXpress = getBooleanParam(info.isInXpress);
                        let isUnitsListView = false;
                        isUnitsListView = info.isUnitsListView;
                        let isUnitsAccount = false;
                        if (isValidParam(info) && info.isUnitsAccount) {
                            isUnitsAccount = info.isUnitsAccount;
                        }

                        if (
                            object === constants.SALES_TEMPLATE_OBJECT ||
                            object === constants.MARKETING_TEMPLATE_OBJECT ||
                            object === constants.SMS_TEMPLATE_OBJECT
                        ) {
                            hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_TEMPLATES);
                        } else {
                            if (
                                object === constants.ACCOUNTS_OBJECT ||
                                object === constants.CONTACTS_OBJECT ||
                                object === constants.OPPORTUNITIES_OBJECT ||
                                object === constants.PRODUCTS_OBJECT ||
                                object === constants.CASES_OBJECT ||
                                object === constants.PROJECTS_OBJECT ||
                                object === constants.ISSUES_OBJECT ||
                                object === constants.CUSTOM_TABLE1_OBJECT ||
                                object === constants.CUSTOM_TABLE2_OBJECT ||
                                object === constants.CUSTOM_TABLE3_OBJECT ||
                                object === constants.CUSTOM_TABLE4_OBJECT ||
                                object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                                object === constants.TOUCH_CAMPAIGNS_OBJECT ||
                                object === constants.AUTOMATION_DESIGNER_OBJECT ||
                                object === constants.SOLUTIONS_OBJECT ||
                                object === constants.AB_CAMPAIGNS_OBJECT ||
                                object === constants.SALES_LEADS_OBJECT ||
                                object === constants.GROUP_OBJECT ||
                                object === constants.INVENTORY_OBJECT ||
                                object === constants.APPOINTMENTS ||
                                object === constants.TASKS ||
                                object === constants.REPORTED_SALES_OBJECT
                            ) {
                                hasPermission = hasAccessPermission(object, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                            } else {
                                hasPermission = true;
                            }
                        }

                        if (
                            object !== constants.SALES_TEMPLATE_OBJECT &&
                            object !== constants.MARKETING_TEMPLATE_OBJECT &&
                            object !== constants.SMS_TEMPLATE_OBJECT
                        ) {
                            if (!hasPermission) {
                                sfDialogs.alert(
                                    getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                                    getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                                    null,
                                );
                            }
                            if (
                                args.column.editable &&
                                hasPermission &&
                                !isLookup &&
                                (args.column.field_type === 'date' || args.column.field_type === 'datetime') &&
                                !isInXpress &&
                                !isUnitsListView &&
                                !isUnitsAccount &&
                                object !== constants.TASKS
                            ) {
                                dispatch(
                                    getAppDialog(
                                        true,
                                        constants.LIST_VIEW_DATE_PICKER,
                                        args.column.name,
                                        null,
                                        { ...args, object: object, rows: tableSmmary.rows, callBackToUpdate: handleRowsChange },
                                        null,
                                    ),
                                );
                            }
                        }
                    }}
                />
            </div>
            <div>
                <div key="sign-dialog">
                    <Dialog aria-labelledby="customized-dialog-title" onClose={handleClose} open={isOpenAuditNote}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <div style={{ display: 'flex' }}>
                                <div
                                    className="modal-header"
                                    style={{
                                        marginTop: 5,
                                        display: 'block',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                    title={getLocalizedStrings().label.COMMON.NOTE}>
                                    {getLocalizedStrings().label.COMMON.NOTE}
                                </div>
                                <div style={{ width: '50px', float: 'right', alignItems: 'right', textAlign: 'right', alignSelf: 'right', marginLeft: '20px' }}>
                                    <IconButton style={{ marginTop: '8px' }} aria-label="close" onClick={() => handleClose()}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div style={{ paddingBottom: '0px', width: '32rem', height: '18rem', overflow: 'scroll' }}>
                                <pre style={{ fontSize: '14px', fontFamily: 'Roboto', width: '100%', height: 'fit-content', whiteSpace: 'pre-wrap' }}>{selectedNote}</pre>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default DataTable;
